import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from '../../handlers/api-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataManagementService {


  constructor(private api: ApiHandlerService) { }

  getFluidFilterData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getFluidFilterData', data);
  }

  searchFluidFilterData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/searchFluidFilterData', data);
  }

  deleteFluidFilterData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/deleteFluidFilterData', data);
  }

  saveFluidFilterData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/saveFluidFilterData', data);
  }

  getCommentDropdown(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getCommentDropdown', data);
  }

  getCommentValue(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getCommentValue', data);
  }

  updateCommentValue(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/updateCommentValue', data);
  }

  getCogsDropdown(): Observable<any> {
    return this.api.post(environment.apiUrl + '/getCogsDropdown', {});
  }

  getSearchCogs(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getSearchCogs', data);
  }

  addCogs(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/addCogs', data);
  }

  getLatestCogs(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getLatestCogs', data);
  }

  searchMetadata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getDropdownOptions', data);
  }

  addMetadata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/addDropdownOption', data);
  }

  saveMetadata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/updateDropdownOption', data);
  }

  deleteMetadata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/deleteDropdownOption', data);
  }

  insertAstmData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/insertAstmData', data);
  }

  getAstmData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getAstmData', data);
  }

  getCustomerListData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/customerAccountOperations', data);
  }

  qrCodeRangeOperations(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/qrCodeRangeOperations', data);
  }

  getComponentTypeData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/componentTypeDashboard', data);
  }

  searchComponentTypedata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/componentTypeDashboard', data);
  }

  addComponentTypedata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/componentTypeOperations', data);
  }

  saveComponentTypedata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/componentTypeOperations', data);
  }

  deleteComponentTypedata(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/componentTypeOperations', data);
  }
}
