import { Component, Input, OnInit } from '@angular/core';
import { ReasonCommentHstoryService } from './reason-comment-hstory.service';

@Component({
  selector: 'app-reason-comment-history',
  templateUrl: './reason-comment-history.component.html',
  styleUrls: ['./reason-comment-history.component.css']
})
export class ReasonCommentHistoryComponent implements OnInit {
  
  reasonList: any = [];
  sampleInfo: any = [];
  constructor(private reasonServ: ReasonCommentHstoryService) { }

  ngOnInit() {
    this.sampleInfo = JSON.parse(localStorage.getItem('registerSampleObject'));
    this.getCommentHistory();
  }


  getCommentHistory() {
    let reqParam = {
      "sampleId": this.sampleInfo.sampleId
    }
    this.reasonServ.getReasonComments(reqParam).subscribe(response => {
      this.reasonList = response.reasonComments;
    });
  }
}
