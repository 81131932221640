import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { TsmaeReportsService } from '../tsm-ae-report/tsmae-reports.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { FleetSampleTotReportService } from './fleet-sample-tot-report.service';
import { DatePipe } from '@angular/common';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { MatOption } from '@angular/material';

@Component({
  selector: 'app-fleet-sample-tot-report',
  templateUrl: './fleet-sample-tot-report.component.html',
  styleUrls: ['./fleet-sample-tot-report.component.css']
})
export class FleetSampleTotReportComponent implements OnInit {  
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChildren(SortComponent) sortComponents;

  readonly USERTYPE = USERTYPE;
  userRole: string = ''; 
  isSearchDone: boolean = false;    
  reportAvgData: any = {};
  companyArray= []; 
  companyList : any[];  
  fluidTypeList : any[];  
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date; 
  scrollToTop: any;  

  inputForm = new UntypedFormGroup({
    companyId: new UntypedFormControl('', [Validators.required]),
    companyName: new UntypedFormControl(''),
    companyIdFilter: new UntypedFormControl(''),    
    fluidType: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required])
  });  

  /*--------TSM CompanyName dropdown---------*/
  companyIdTSM: any = '';
  companyNameTSM: any = '';
  tsmCustomerNameDropdown: any;
  messageDropdownTsm: any;
  isFirstTime: boolean = true;
  isSearchDisable: boolean = false;
  messageDropdownDbu: any;
  isFirstTimeDbu: boolean = true;
  behaviorObs: any;
  behaviorObsTSM: any;
  companyListNewArr: any[]=[];
  companyIdListArr: any[] = [];
  companyListExternal: any[] = [];
  companyListExtForChecked: any[];
  constructor(private alertService: AlertService,
    private tsmAEReportsService: TsmaeReportsService,
    private reportService: FleetSampleTotReportService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private router: Router) { }

  ngOnInit() { 
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    this.userRole = this.sharedService.getUserRole();  
    this.minStartDate = new Date(2021, 0, 1); 
    this.minEndDate = new Date(2021, 0, 1); 
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    if(this.userRole === USERTYPE.EXTERNAL){
      this.inputForm.controls['companyId'].disable();     
    }
    if(this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      this.isSearchDisable = true;
      this.inputForm.controls['startDate'].disable();
      this.inputForm.controls['endDate'].disable();
      this.inputForm.controls['fluidType'].disable();
    }
    this.getInitialData();

    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message=>{
      if(this.userRole == USERTYPE.TSM && this.isFirstTime == false){
        if(this.router.url == '/fleetSampleTotReport'){      
          this.messageDropdownTsm = message;
            this.recieveMessageTSM(this.messageDropdownTsm);
        }
      }
      this.isFirstTime = false;
    });

    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if((this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) && this.isFirstTimeDbu == false){
        if(this.router.url == '/fleetSampleTotReport'){      
          this.messageDropdownDbu = message;
            this.recieveMessageTSM(this.messageDropdownDbu);
        }
      }
      this.isFirstTimeDbu = false;
    }); 
  }

  ngOnDestroy () {
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
  }

  recieveMessageTSM(event){
    this.alertService.clear();
    this.inputForm.controls['startDate'].enable();
    this.inputForm.controls['endDate'].enable();
    this.inputForm.controls['fluidType'].enable();
    this.inputForm.patchValue({
      companyId: '',
    });
    this.tsmCustomerNameDropdown = event;
    this.companyNameTSM = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
    if(this.companyIdTSM == '' || this.companyIdTSM == null){
      this.isSearchDisable = true;
      this.alertService.error("Please select Company Name");
      this.inputForm.controls['startDate'].disable();
      this.inputForm.controls['endDate'].disable();
      this.inputForm.controls['fluidType'].disable();
    } else {
      this.companyList.forEach(element => {
        if (element.companyId == this.companyIdTSM && element.companyName == this.companyNameTSM) {
          this.inputForm.patchValue({
            companyId: this.companyIdTSM
          });
          this.isSearchDisable = false;
        }
      });
      if(this.inputForm.controls['companyId'].value == '') {
        this.isSearchDisable = true;
        this.alertService.error("Selected company has no reports")
        this.inputForm.controls['startDate'].disable();
        this.inputForm.controls['endDate'].disable();
        this.inputForm.controls['fluidType'].disable();
      }
    }
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getInitialData(){
    this.tsmAEReportsService.getInitialData().subscribe(response=> {
      if (response.statusCode == "1") { 
        this.companyList = response.companyList;
        this.companyList.sort(function(a, b) {
          const nameA = a.companyName.toUpperCase();
          const nameB = b.companyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.fluidTypeList = response.fluidList;
        this.fluidTypeList = this.fluidTypeList.filter(row=>{
          return row.fluidType == 1 || row.fluidType == 2 || row.fluidType == 3 || row.fluidType == 4 || row.fluidType == 5 || row.fluidType == 6
        });
        if(this.userRole == USERTYPE.TSM){
          this.inputForm.controls['companyId'].disable();
          if(localStorage.getItem("customerDropdownTSM")){
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
          }
          if(this.companyIdTSM == ''){
            this.alertService.error('Please select Company Name');
          }
        } else if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
          this.inputForm.controls['companyId'].disable();
          if(localStorage.getItem("branchCustomerDropdown")){
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
          }
          if(this.companyIdTSM == ''){
            this.alertService.error('Please select Company Name');
          }
        }
        this.companySearch('');        
      }else{
        this.alertService.error(response.statusMessage);
      }
    },err=>{
      this.alertService.error(err);
    });   

    this.companyArray = this.companyList;
  }

  search(){   
    this.getReportData();
  }

  optionSearch(){
    this.getReportData();
  }

  getReportData() {
    let inputData = this.inputForm.getRawValue(); 
    inputData['companyName'] = this.getCompanyName();
    inputData['fluidType'] = inputData['fluidType'].join();
    inputData['startDate'] = this.datePipe.transform(
      this.inputForm.controls["startDate"].value,
      'yyyy-MM-dd'
    );
    inputData['endDate'] = this.datePipe.transform(
      this.inputForm.controls["endDate"].value,
      'yyyy-MM-dd'
    );  
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;
     
    console.log(inputData); 

    this.reportService.getFleetTotReport(inputData).subscribe(response => {
      if (response.statusCode == "1") {       
        this.reportAvgData = response.reportData; 
        this.isSearchDone = true;
      } else {        
        this.reportAvgData = {};
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );

    //mock data   
    /*this.reportAvgData = {'inventoryTot': '10', 'logisticsTot': '20', 'laboratoryTot': '3', 'totalTot': '33', 'sampleCount': '5', 'fleetCount': '2'};
    
    this.isSearchDone = true; */
  }  

  exportToExcel() {
    let inputData = this.inputForm.getRawValue();
    inputData['companyName'] = this.getCompanyName();
    inputData['fluidType'] = inputData['fluidType'].join();
    inputData['startDate'] = this.datePipe.transform(
      this.inputForm.controls["startDate"].value,
      'yyyy-MM-dd'
    );
    inputData['endDate'] = this.datePipe.transform(
      this.inputForm.controls["endDate"].value,
      'yyyy-MM-dd'
    );
    inputData['action'] = "EMAIL";
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;
    inputData['userName'] = this.sharedService.getUserInformation().firstName + " " +
      this.sharedService.getUserInformation().lastName ;
    
    this.reportService.getFleetTotReport(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.alertService.success(response.statusMessage);
        this.scrollToTop = true;
      } else {        
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }
  
  companySearch(value:string){
    this.companyArray= []; 
    let filter = value.toLowerCase();
    if (this.userRole == USERTYPE.EXTERNAL) {
      this. companyListNewArr = [];
      this.companyListExternal = this.companyIdListArr;
      for(let i=0;i<this.companyList.length; i++){
        this.companyListExternal.filter(row => {
          if(row == this.companyList[i].companyId){
            this.companyListNewArr.push([{companyId:this.companyList[i].companyId.toUpperCase(),companyName:this.companyList[i].companyName.toUpperCase()}]);
          }
        });                 
      };
      const convertToArray = [].concat(...this.companyListNewArr); 
      this.companyArray = convertToArray;
      let emptyArr = [];
        this.companyArray.forEach(row => {
          emptyArr.push(row.companyId.toUpperCase());
        });
        this.inputForm.patchValue({
          companyId: emptyArr
        });       
    }
    this.companyArray = this.companyList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if(companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0){
        return true;
      }else{
        return false;
      }
    });

    if(this.companyArray.length == 0){
      this.companyArray.push({'companyName':'Select', 'companyId': ''});
    }
  }

  clearCompanySearch(){
    this.companyArray= []; 
    this.companyArray= this.companyList;
  }  

  getCompanyName():any{
    let companyNameArr=[];
    let companyName;
    let selectedcompanyDetails= this.inputForm.controls.companyId.value
    for (let i = 0; i < selectedcompanyDetails.length; i++) {
      for (let j = 0; j < this.companyList.length; j++){
      if(this.companyList[j].companyId == selectedcompanyDetails[i]){
        companyName =  this.companyList[j].companyName;
        companyNameArr.push(companyName.toUpperCase())
     }
    }
    }
    return companyNameArr;
  }

  startDateChange() {
    this.minEndDate = this.inputForm.controls.startDate.value;    
  }
  endDateChange() {    
    this.maxStartDate = this.inputForm.controls.endDate.value;
  }

  clearInput(){
    this.inputForm.reset(); 
    if(this.userRole === USERTYPE.EXTERNAL){
      this.inputForm.patchValue({
        companyId: this.sharedService.getCompanyId()    
      });      
    } else if(this.userRole === USERTYPE.TSM){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
    } else if(this.userRole === USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
    }
    this.isSearchDone = false;
    this.minStartDate = new Date(2021, 0, 1); 
    this.minEndDate = new Date(2021, 0, 1); 
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.inputForm.controls.companyId
      .patchValue([...this.companyList.map(item => item.companyId), 0]);
      this.inputForm.controls.companyName
      .patchValue([...this.companyList.map(item => item.companyName), 0]);

    } else {
      this.inputForm.controls.companyId.patchValue([]);
      this.inputForm.controls.companyName.patchValue([]);
    }
  }
}
