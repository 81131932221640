
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  ifNotProd: any;
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  state = false;
  count = 0;
  constructor() {
    if (window.location.origin.includes("dev") || window.location.origin.includes("localhost") || localStorage.getItem("overRideLogs") == "Y") {
      this.ifNotProd = true;
    }
  }
  show() {

    this.loaderSubject.next(<LoaderState>{ show: true });
  }
  hide() {


    this.loaderSubject.next(<LoaderState>{ show: false });
  }
  status(): any {
    return this.count;
  }
  add() {
    this.count++;

  }
  delete(): any {
    this.count--;

  }



  log = function (message: any) {
    if (this.ifNotProd) {
      //console.log(message);
    }
  }
}

export interface LoaderState {
  show: boolean;
}