import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ScatterPlotService {

  constructor(private api: ApiHandlerService) { }  

  getScatterPlotDataLoad(data: any) {
    return this.api.post(environment.apiUrl + '/getScatterPlotUnitsDashboard', data);
  }

  getBYOScatterPlotData(data: any) {
    return this.api.post(environment.apiUrl + '/getBYOScatterPlotData', data);
  }

  getLineGraphDataLoad(data: any) {
    return this.api.post(environment.apiUrl + '/getLineGraphDataLoad', data);
  }
}
