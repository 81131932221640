import { Injectable } from '@angular/core';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddPopulationService {

  constructor(private api: ApiHandlerService) { }

  getPageLoadData(): Observable<any> {
    return this.api.post(environment.apiUrl + '/getParameters', {});
  }

  submitData(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/addNewPopulationFromExcel', reqData);
  }

  replacePopulation(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/replacePopulation', reqData);
  }

  updatePopulation(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/uploadPopulation', reqData);
  }

  clonePopulation(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/clonePopulation', data);
  }

  getMetaDataDropdownData(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getMetaDataDropdownData', reqData);
  }

  getPopulationMetadata(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getPopulationMetadata', reqData);
  }

  updatePopulationMetadata(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/updatePopulationMetadata', reqData);
  }
}
