import { AfterViewChecked, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { TsmaeReportsService } from '../tsm-ae-report/tsmae-reports.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { DatePipe } from '@angular/common';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { OpsCountReportService } from './ops-count-report.service';
import { MatOption } from '@angular/material';
import { common, warnMessage } from 'src/app/View/common/constants/constant';

@Component({
  selector: 'app-ops-count-report',
  templateUrl: './ops-count-report.component.html',
  styleUrls: ['./ops-count-report.component.css']
})
export class OpsCountReportComponent implements OnInit,AfterViewChecked {

  @ViewChildren(SortComponent) sortComponents;

  readonly USERTYPE = USERTYPE;
  userRole: string = ''; 
  isSearchDone: boolean = false;    
  available: any = {};  
  submitted: any = {};
  received: any = {};
  companyStats: any = {};
  hold: any = {};
  analyzing: any = {};
  published: any = {};
  rerun: any = {};
  companyArray= []; 
  companyList;  
  fluidTypeList : any[];  
  testLevelList = [];
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date; 
  scrollToTop: any;
  inputForm = new UntypedFormGroup({
    companyId: new UntypedFormControl('', [Validators.required]),
    companyName: new UntypedFormControl(''),
    companyIdFilter: new UntypedFormControl(''),    
    fluidType: new UntypedFormControl('', [Validators.required]),
    testLevel:new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl({ value: new Date(2021, 0, 1), disabled: false }, Validators.required),
    endDate: new UntypedFormControl({ value: new Date(), disabled: false }, Validators.required)
  });  
  @ViewChild('allSelected') private allSelected: MatOption;
  companyDetails: any;
  cancelled: any={};
  statuscountColumn = [
    { code: 'group', name: 'Group Name' },
    { code: 'companyId', name: 'Company Id' },
    { code: 'companyName', name: 'Company Name' },
    { code: 'availableCount', name: 'Available' },
    { code: 'submittedCount', name: 'Submitted' },
    { code: 'receivedCount', name: 'Received' },
    { code: 'analyzingCount', name: 'Analyzing' },
    { code: 'holdCount', name: 'Hold' },
    { code: 'rerunCount', name: 'Rerun' },
    { code: 'cancelledCount', name: 'Cancelled' },
    { code: 'publishedCount', name: 'Published' },
    { code: 'totalCount', name: 'Line Totals' }
  ];
  sortingOrder: string;
  columnName: any;
  constructor(private alertService: AlertService,
    private tsmAEReportsService: TsmaeReportsService,
    private reportService: OpsCountReportService,
    private sharedService: SharedService,
    private datePipe: DatePipe) { }

  ngOnInit() { 
    this.userRole = this.sharedService.getUserRole();  
    this.minStartDate = new Date(2021, 0, 1); 
    this.minEndDate = new Date(2021, 0, 1); 
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    if(this.userRole === USERTYPE.EXTERNAL){
     this.inputForm.controls['companyId'].disable();
      this.inputForm.patchValue({
        companyId: this.sharedService.getCompanyId()    
      });      
    }
    this.getInitialData();
    this.clearInput();
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getInitialData(){
    this.tsmAEReportsService.getInitialData().subscribe(response=> {
      if (response.statusCode == "1") { 
        this.companyList = response.companyList;
        console.log(this.companyList)
        this.companyList.push({'maxRow': 0, 'companyId': '', 'companyName':'Unknown'});
        this.testLevelList = response.testLevelList;
        this.companyList.sort(function(a, b) {
          const nameA = a.companyName.toUpperCase();
          const nameB = b.companyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.fluidTypeList = response.fluidList;
        this.fluidTypeList = this.fluidTypeList.filter(row=>{
          return row.fluidType == 1 || row.fluidType == 2 || row.fluidType == 3 || row.fluidType == 4 || row.fluidType == 5 || row.fluidType == 6
        });
        this.companySearch('');        
      }else{
        this.alertService.error(response.statusMessage);
      }
    },err=>{
      this.alertService.error(err);
    });   
    this.companyArray = this.companyList;

  }

  search(){   
    this.getReportData();
  }

  optionSearch(){
    this.getReportData();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.inputForm.controls.companyId
      .patchValue([...this.companyList.map(item => item.companyId), 0]);
      this.inputForm.controls.companyName
      .patchValue([...this.companyList.map(item => item.companyName), 0]);

    } else {
      this.inputForm.controls.companyId.patchValue([]);
      this.inputForm.controls.companyName.patchValue([]);
    }
  }

  getReportData() {
    let companyDeatils=[];
    // companyDeatils= this.inputForm.controls.company.value;
    // console.log(companyDeatils[0].companyId);
    // console.log(this.companyList)
    let inputData = this.inputForm.getRawValue(); 
    // let companyName;
    // let companyId;
    // let companyNameArr =[];
    // let companyIdArr =[];
    // for (let i = 0; i < this.companyList.length; i++) {
    //   if(this.companyList[i].companyId == companyDeatils[i].companyId){
    //     companyName =  this.companyList[i].companyName;
    //     companyId =  this.companyList[i].companyId;
    //     companyNameArr.push(companyName);
    //     companyIdArr.push(companyId);      
    //   }
    //  }
    inputData['companyName'] = this.getCompanyName();
    // inputData['companyId'] = companyIdArr;
    inputData['fluidType'] = inputData['fluidType'].join();
    inputData['testLevel'] = inputData['testLevel'].join();
    inputData['startDate'] = this.datePipe.transform(
      this.inputForm.controls["startDate"].value,
      'yyyy-MM-dd'
    );
    inputData['endDate'] = this.datePipe.transform(
      this.inputForm.controls["endDate"].value,
      'yyyy-MM-dd'
    );  
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;
    // inputData['userName'] = this.sharedService.getUserInformation().firstName + " " +
    // this.sharedService.getUserInformation().lastName ;
    console.log(inputData); 

    this.reportService.getOpsCountReportData(inputData).subscribe(response => {
      if (response.statusCode == "1") { 
        this.available = response.available; 
        this.submitted = response.submitted; 
        this.received = response.received; 
        this.analyzing = response.analyzing; 
        this.published = response.published; 
        this.cancelled = response.cancelled; 
        this.hold = response.hold; 
        this.rerun = response.rerun; 
        this.companyStats = response.companyStats;
        this.isSearchDone = true;
      } else {        
        this.available = {}; 
        this.submitted = {}; 
        this.received = {}; 
        this.analyzing = {}; 
        this.hold = {};
        this.published = {};
        this.rerun = {};
        this.cancelled = {}; 
        this.companyStats = {};
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );

    //mock data   
    /*this.available = {'statusCount': '10', 'minDays': '2', 'maxDays': '100', 'stddevDays':'2', 'avgDays': '5'};
    this.submitted = {'statusCount': '30', 'minDays': '2', 'maxDays': '100', 'stddevDays':'2', 'avgDays': '5'};
    this.received = {'statusCount': '40', 'minDays': '2', 'maxDays': '100', 'stddevDays':'2', 'avgDays': '5'};
    this.analyzing = {'statusCount': '10', 'minDays': '2', 'maxDays': '100', 'stddevDays':'2', 'avgDays': '5'};
    this.published = {'statusCount': '100', 'minDays': '2', 'maxDays': '100', 'stddevDays':'2', 'avgDays': '5'};
    this.rerun = {'statusCount': '5', 'minDays': '5', 'maxDays': '5', 'stddevDays':'5', 'avgDays': '5'};
    
    this.isSearchDone = true; */
  }  

  exportToExcel() {
    let inputData = this.inputForm.getRawValue();
    inputData['companyName'] = this.getCompanyName();
    inputData['fluidType'] = inputData['fluidType'].join();
    inputData['testLevel'] = inputData['testLevel'].join();
    inputData['startDate'] = this.datePipe.transform(
      this.inputForm.controls["startDate"].value,
      'yyyy-MM-dd'
    );
    inputData['endDate'] = this.datePipe.transform(
      this.inputForm.controls["endDate"].value,
      'yyyy-MM-dd'
    );
    inputData['action'] = "EMAIL";
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;
    inputData['userName'] = this.sharedService.getUserInformation().firstName + " " +
      this.sharedService.getUserInformation().lastName ;
    
    this.reportService.getOpsCountReportData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.alertService.success(response.statusMessage);
        this.scrollToTop = true;
      } else {        
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }
  
  companySearch(value:string){
    this.companyArray= []; 
    let filter = value.toLowerCase();
    
    this.companyArray = this.companyList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if(companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0){
        return true;
      }else{
        return false;
      }
    });

    if(this.companyArray.length == 0){
      this.companyArray.push({'companyName':'Select', 'companyId': ''});

    }

  }

  clearCompanySearch(){
    this.companyArray= []; 
    this.companyArray= this.companyList;
  }  


  getTestLevelName(){
    let testLevelName = '';
    this.testLevelList.forEach(row => {
      if(row.testLevelName == this.inputForm.controls.testLevelName.value){
        testLevelName = row.testLevelName;
      }
    });

    return testLevelName;
  }
  getCompanyName():any{
    let companyNameArr=[];
    let companyName;
    let selectedcompanyDeatils= this.inputForm.controls.companyId.value
    for (let i = 0; i < selectedcompanyDeatils.length; i++) {
      for (let j = 0; j < this.companyList.length; j++){
      if(this.companyList[j].companyId == selectedcompanyDeatils[i]){
        companyName =  this.companyList[j].companyName;
        companyNameArr.push(companyName)
     }
    }
    }
    return companyNameArr;
  } 

  startDateChange() {
    this.minEndDate = this.inputForm.controls.startDate.value;    
  }
  endDateChange() {    
    this.maxStartDate = this.inputForm.controls.endDate.value;
  }
  sortData(columnName) { 
    // let existingsortdata= sessionStorage.getItem('analyzesortData');
    // if(existingsortdata == 'undefined' || existingsortdata == null || existingsortdata== ""){
    //   this.currentItem = undefined;
    // }else{
    //   this.currentItem = JSON.parse(existingsortdata)      
    // }
    // if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
    //   if(this.currentItem.sort == 'asc'){
    //     this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    //   }else{
    //     this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
  
    //   }
    // }
    // let sortcolumn=  this.sortingOrder != 'asc' ? 'asc' : 'desc';
    // let sort ={column : columnName, sort :sortcolumn,direction:sortcolumn};
    // sessionStorage.setItem('managefleetSortData', JSON.stringify(sort));
      if (this.columnName != columnName) {
        this.sortingOrder = "";
        this.sortComponents._results.forEach(row => row.direction = true);
      }
      this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      this.columnName = columnName;
      this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
      this.getReportData();
  }

  clearInput(){
    this.inputForm.reset();    
    this.isSearchDone = false;
    this.minStartDate = new Date(2021, 0, 1); 
    this.minEndDate = new Date(2021, 0, 1); 
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    this.inputForm.patchValue({
      startDate: new Date(2021, 0, 1),
      endDate: new Date()
    });
  }

}
