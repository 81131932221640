import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/View/service/shared.service';
import { FLUID_TYPE, USERTYPE } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AnalyzeSampleGridService } from '../analyze-sample-grid/analyze-sample-grid.service';


@Component({
  selector: 'app-view-report-home',
  templateUrl: './view-report-home.component.html',
  styleUrls: ['./view-report-home.component.css']
})
export class ViewReportHomeComponent implements OnInit,AfterViewChecked {

  readonly fluidType = FLUID_TYPE;
  readonly USERTYPE = USERTYPE;

  userRole: string = '';
  scrollToTop =  false;

  emailModal = false;
  confirmationModal = false;
  confirmationMessage = '';
  emailValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map(e=>e.trim());
    const forbidden = emails.some(email => Validators.email(new UntypedFormControl(email)));  
    const duplicate = emails.some(x => emails.indexOf(x) !== emails.lastIndexOf(x));
    if(emails.length > 150){
      return { 'emailListMaxLength': { value: control.value } };
    }
    if(emails.length > 1 && duplicate){
      return { 'emailListDuplicate': { value: control.value } };
    }       
    return forbidden ? { 'emailList': { value: control.value } } : null;
  };
  emailForm = new UntypedFormGroup({
    emailList: new UntypedFormControl(''),  
    notificationFlag: new UntypedFormControl(false)
  });

  constructor(private sharedServ: SharedService, 
    private router: Router,
    private alertService: AlertService,
    private analyzeSampleService: AnalyzeSampleGridService) { }

  ngOnInit() {
    this.userRole = this.sharedServ.getUserRole();
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  setFluidType(data: any) {
    localStorage.setItem('fluidType', data);
    this.sharedServ.setFluidType(data);
    this.router.navigate(['/analyzeSample']);

  }

  openEmailNotification(){
    this.alertService.clear();
    this.emailModal = true;

    let data = {
      companyId:this.sharedServ.getCompanyId()      
    }
    this.analyzeSampleService.fetchEmailNotification(data).subscribe(response=>{
      if (response.statusCode == "1") {         
        this.emailForm.patchValue({
          emailList: response.emailList,
          notificationFlag: response.notificationFlag
        });    
        this.onToggle();
      }else{        
        this.emailForm.patchValue({
          emailList: '',
          notificationFlag: 'N'
        });
        this.onToggle();
      }
    },err=>{
      this.alertService.error(err);
    });  
  }

  closeEmailModal(){
    this.emailModal = false;
  }

  saveEmail(){
    let data = this.emailForm.getRawValue();
    data['companyId'] = this.sharedServ.getCompanyId();    
    data['userId'] = this.sharedServ.getUserId();    
    
    this.analyzeSampleService.saveEmailNotification(data).subscribe(response=>{
      if (response.statusCode == "1") {
        this.confirmationModal = false; 
        this.emailModal = false;    
        this.alertService.success(response.statusMessage);  
        this.scrollToTop = true;
      }else{
        this.alertService.error(response.statusMessage);  
      }
    },err=>{
      this.alertService.error(err); 
    }); 
  }

  confirmEmail(){
    this.confirmationModal = true;
    if(this.emailForm.controls.notificationFlag.value == 'Y'){
      this.confirmationMessage = 'You have chosen to Turn ON emailed report notifications.';
    }else{
      this.confirmationMessage = 'You are choosing to Turn OFF of email notifications for completed sample reports. Please confirm you wish to no longer receive email notifications from FluidWatch® Sample reports.';
    }
  }

  closeConfirmEmailModal(){
    this.confirmationModal = false; 
  }

  onToggle(){
    if(this.emailForm.controls.notificationFlag.value == 'Y'){
      this.emailForm.controls.emailList.setValidators([Validators.required, this.emailValidator]);      
    }else{
      this.emailForm.controls.emailList.clearValidators();
    }
    this.emailForm.controls.emailList.updateValueAndValidity();    
  }
}
