import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generate-pdf',
  templateUrl: './generate-pdf.component.html',
  styleUrls: ['./generate-pdf.component.css']
})
export class GeneratePdfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
