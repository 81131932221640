import { AlertService } from './../common/alert/alert.service';

import { Injectable } from '@angular/core';
import { catchError, map, tap, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs';
import { errorMessage } from '../common/constants/constant';

import { SessionTimerService } from 'session-expiration-alert';
import { environment } from '../../../environments/environment';
import { ConsoleService } from '../service/console.service';


const getLoginStatusUrl = "getLoginUserDetails";


const httpOptions = {

    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
        'X-XSS-Protection': '1;mode=block',
        'X-Frame-Options': 'SAMEORIGIN',
        'Content-Security-Policy': "script-src 'self' " + environment.allowedOrigins.join(','),
        'X-Content-Type-Options': 'nosniff',
        'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
        'Access-Control-Allow-Origin': environment.allowedOrigins.join(','),
        'Access-Control-Allow-Methods': "GET, POST, DELETE, PUT",
        'Access-Control-Allow-Headers': 'Content-Type'
    }),
};

let serviceHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class ApiHandlerService {


    protected headers: HttpHeaders;
    constructor(private _http: HttpClient,
        private printOut: ConsoleService,
        private alertService: AlertService,
        private sessionTimerService: SessionTimerService) {

    }
    get(url: string): Observable<any> {
        this.alertService.clear();
        return this._http.get(url)
            .pipe(
                tap(_ => this.printOut.log('fetched datas')),
                catchError(this.handleError('get', [])),
                finalize(() => this.onEnd())
            );
    }

    getWithHeader(url: string,options:any): Observable<any> {
        this.alertService.clear();
        return this._http.get(url,options)
            .pipe(
                tap(_ => this.printOut.log('fetched datas')),
                catchError(this.handleError('get', [])),
                finalize(() => this.onEnd())
            );
    }

    post(url: string, inputparam: any): Observable<any> {
        if (url.indexOf(getLoginStatusUrl) < 0) {
            this.alertService.clear();
        }
        this.sessionTimerService.resetTimer();
        return this._http.post(url, JSON.stringify(inputparam), httpOptions)
            .pipe(
                tap(_ => this.printOut.log('posted data')),
                catchError(this.handleError('post', [])),
                finalize(() => this.onEnd())
            );
    }

    postWithHeader(url: string, inputparam: any, options:any): Observable<any> {        
        if (url.indexOf(getLoginStatusUrl) < 0) {
            this.alertService.clear();
        }
        this.sessionTimerService.resetTimer();        
        return this._http.post(url, JSON.stringify(inputparam), options)
            .pipe(
                tap(_ => this.printOut.log('posted data')),
                catchError(this.handleError('post', [])),
                finalize(() => this.onEnd())
            );
    }


    get_Promise(url: string): Promise<any> {
        this.alertService.clear();
        return this._http.get(url).toPromise();
    }

    post_Promise(url: string, inputparam: any): Promise<any> {
        if (url.indexOf(getLoginStatusUrl) < 0) {
            this.alertService.clear();
        }
        this.sessionTimerService.resetTimer();
        return this._http.post(url, JSON.stringify(inputparam), httpOptions).toPromise();
    }


    private onEnd(): void {
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            if (error.status === 0) {
                error.message = errorMessage.sessionCrashed;
            }
            if (error.status === 401) {
                error.message = errorMessage.unauthorizedAccess;
            }
            if (error.status === 440) {
                error.message = errorMessage.sessionExpired;
            }
            this.printOut.log(`${operation} failed: ${error.message}`);
            return Observable.throw(error.message || 'Server error occured.Please contact your administrator');
            // Let the app keep running by returning an empty result.

        };
    }

    getServiceHttpOptions(){
        return serviceHttpOptions;
    }
}
