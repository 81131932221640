import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../../handlers/api-handler.service';

const awsURL = "https://6mkv6e18xk.execute-api.us-east-1.amazonaws.com/DevStage";
@Injectable({
  providedIn: 'root'
})
export class SampleService {

  constructor(private http: ApiHandlerService) { }

  getname(data: any) {
    return this.http.post(awsURL, data);
  }
}
