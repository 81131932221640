import { SortModuleModule } from './View/common/sort/sortModule.module';
import { CommonModuleModule } from './View/common/CommonModule.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatDialogModule,MatCardModule, MatSelectModule, MatAutocompleteModule, MatTooltipModule, MatRadioModule,
  MatExpansionModule, DateAdapter, MAT_DATE_FORMATS, MatDatepickerModule, MatNativeDateModule
  
} from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ApproutingModule } from './approuting.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy, DatePipe, DecimalPipe } from '@angular/common';
import { AppComponent } from './app.component';
import { HeaderComponent } from './View/common/header/header.component';
import { FooterComponent } from './View/common/footer/footer.component';
import { PreloaderComponent } from './View/common/preloader/preloader.component';
import { AlertComponent } from './View/common/alert/alert.component';
import { LoaderService } from './View/common/preloader/loader.service';

import { SessionExpirationAlert } from 'session-expiration-alert';
import { PopComponent } from './View/common/pop/pop.component';
import { ModalContainerComponent } from './View/directives/modal-container/modal-container.component';
// import { BootstrapModalModule, DialogService } from 'ng2-bootstrap-modal';
import { HomeComponent } from './View/common/home/home.component';
import { AccessDeniedComponent } from './View/common/access-denied/access-denied.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiHandlerService } from './View/handlers/api-handler.service';
import { AlertModule } from './alert.module';
import { PopupComponent } from './View/directives/popup/popup.component';
import { SampleComponent } from './View/core/sample/sample.component';
import { UserTypeSelectionComponent } from './View/core/user-type-selection/user-type-selection.component';
import { ExternalUserLoginComponent } from './View/core/external-user-login/external-user-login.component';
import { ManageFleetComponent } from './View/core/manage-fleet/manage-fleet.component';
import { SharedService } from './View/service/shared.service';
import { MoreInformationComponent } from './View/core/manage-fleet/more-information/more-information.component';
import { EditVehicleComponent } from './View/core/edit-vehicle/edit-vehicle.component';
import { AuthExpiredInterceptor } from './security/auth-expired.interceptor';
import { ConsoleService } from './View/service/console.service';
import { AdassertComponent } from './assert/adassert/adassert.component';
import { IamassertComponent } from './assert/iamassert/iamassert.component';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { AnalyzeSampleGridComponent } from './View/core/analyze-sample/analyze-sample-grid/analyze-sample-grid.component';
import { AnalyzeSampleInformationComponent } from './View/core/analyze-sample/analyze-sample-information/analyze-sample-information.component';
import { PopulationGridComponent } from './View/core/population-management/population-grid/population-grid.component';
import { ShowMoreInformationComponent } from './View/core/analyze-sample/show-more-information/show-more-information.component';
import { GridComponent } from './View/core/poc/grid/grid.component';
import { FormComponent } from './View/core/poc/form/form.component';
import { GeneratePdfComponent } from './View/core/analyze-sample/generate-pdf/generate-pdf.component';
import { AddPopulationComponent } from './View/core/population-management/add-population/add-population.component';
import { AlphaNumericDirective } from './View/directives/alpha-numeric/alpha-numeric.directive';
import { LimitFlaggingComponent } from './View/core/population-management/limit-flagging/limit-flagging.component';
import { ModifyThresholdComponent } from './View/core/population-management/modify-threshold/modify-threshold.component';
import { EditKeyIssuesComponent } from './View/core/population-management/edit-key-issues/edit-key-issues.component';
import { GraphPlotComponent } from './View/core/analyze-sample/graph-plot/graph-plot.component';
import { NumberDotDirective } from './View/directives/number-dot.directive';
import { ViewReportComponent } from './View/core/analyze-sample/view-report/view-report.component';
import { SharedModule } from './View/core/shared/shared-module';
import { CookieService } from 'ngx-cookie-service';
import { ValidRankDirective } from './View/directives/valid-rank.directive';
import { UppercaseDirective } from "./View/directives/uppercase.directive";
import { ScatterPlotComponent } from './View/core/sample-dashboard/scatterplot/scatter-plot/scatter-plot.component';
import { SampleDashboardHomeComponent } from './View/core/sample-dashboard/sample-dashboard-home/sample-dashboard-home.component';
import { ScatterPlotGraphComponent } from './View/core/sample-dashboard/scatterplot/scatter-plot-graph/scatter-plot-graph.component';
import { LineGraphComponent } from './View/core/sample-dashboard/linegraph/line-graph/line-graph.component';
import { LineGraphHomeComponent } from './View/core/sample-dashboard/linegraph/line-graph-home/line-graph-home.component';
import { ScatterPlotGridComponent } from './View/core/sample-dashboard/scatterplot/scatter-plot-grid/scatter-plot-grid.component';
import { NotFoundComponent } from './View/common/not-found/not-found.component';
import { LogoutComponent } from './View/common/logout/logout.component';
import { UploadUnitComponent } from './View/core/manage-fleet/upload-unit/upload-unit.component';
import { ExternalLoginComponent } from './View/core/external-login/external-login.component';
import { TwoDigitDecimalDirective } from './View/core/population-management/modify-threshold/two-digit-decimal.directive';
import { AdminPortalComponent } from './View/core/admin-portal/admin-portal.component';
import { MasterDataManagementComponent } from './View/core/master-data-management/master-data-management.component';
import { MasterDataManagementService } from './View/core/master-data-management/master-data-management.service';
import { LineGraphFuelComponent } from './View/core/analyze-sample/line-graph/line-graph-fuel.component';
import { ReasonCommentHistoryComponent } from './View/core/analyze-sample/reason-comment-history/reason-comment-history.component';
import { ViewReportHomeComponent } from './View/core/analyze-sample/view-report-home/view-report-home.component';
import { FinanceReportComponent } from './View/core/reports/finance-report/finance-report.component';
import { ReportHomeComponent } from './View/core/reports/report-home/report-home.component';
import { TsmAeReportComponent } from './View/core/reports/tsm-ae-report/tsm-ae-report.component';
import { ReportPieChartComponent } from './View/core/reports/report-pie-chart/report-pie-chart.component';

import { MetadataInfoComponent } from './View/core/population-management/metadata-info/metadata-info.component';
import { CogsComponent } from './View/core/master-data-management/cogs/cogs.component';
import { LiveUpdateComponent } from './View/core/live-update/live-update.component';
import { CommentsComponent } from './View/core/master-data-management/comments/comments.component';
import { FluidFilterInfoComponent } from './View/core/master-data-management/fluid-filter-info/fluid-filter-info.component';
import { MetadataComponent } from './View/core/master-data-management/metadata/metadata.component';
import { OpsCountReportComponent } from './View/core/reports/ops-count-report/ops-count-report.component';
import { OpsKeyissueReportComponent } from './View/core/reports/ops-keyissue-report/ops-keyissue-report.component';
import { OpsTotReportComponent } from './View/core/reports/ops-tot-report/ops-tot-report.component';
import { OpsReportHomeComponent } from './View/core/reports/ops-report-home/ops-report-home.component';
import { FleetReportHomeComponent } from './View/core/reports/fleet-report-home/fleet-report-home.component';
import { FleetUnitsOdiReportComponent } from './View/core/reports/fleet-units-odi-report/fleet-units-odi-report.component';
import { FleetSampleTotReportComponent } from './View/core/reports/fleet-sample-tot-report/fleet-sample-tot-report.component';
import { FleetUnitIdCountReportComponent } from './View/core/reports/fleet-unit-id-count-report/fleet-unit-id-count-report.component';
import { NoCommaPipe } from './View/core/analyze-sample/no-comma/no-comma-pipe';
import { AstmComponent } from './View/core/master-data-management/astm/astm.component';
import { CalendarComponent } from './View/core/calendar/calendar.component';
import { AngularHolidayPlannerModule } from './View/core/calendar-view/angular-holiday-planner.module';
import { CustomerAccountComponent } from './View/core/customer-account/customer-account.component';
import { DbuToolComponent } from './View/core/dbu-tools/dbu-tool.component';
import { DbuBranchComponent } from './View/core/dbu-tools/dbu-branch/dbu-branch.component';
import { BranchCustomerMappingComponent } from './View/core/dbu-tools/branch-customer-mapping/branch-customer-mapping.component';
import { WwidAccessComponent } from './View/core/dbu-tools/wwid-access/wwid-access.component';
import { FleetDetailsComponent } from './View/core/master-data-management/fleet-details/fleet-details.component';
import { AdminUnitMakeComponent } from './View/core/master-data-management/admin-unit-make/admin-unit-make.component';
import { AdminApplicationTypeComponent } from './View/core/master-data-management/admin-application-type/admin-application-type.component';
import { AdminEngineMakeComponent } from './View/core/master-data-management/admin-engine-make/admin-engine-make.component';
import { CustomerAacountAccessComponent } from './View/core/customer-aacount-access/customer-aacount-access.component';
import { QrDetailsComponent } from './View/core/master-data-management/qr-details/qr-details.component';
import { HoldDateDetailsComponent } from './View/core/master-data-management/hold-date-details/hold-date-details.component';
// import { CustomerNameDropdownComponent } from './View/common/customer-name-dropdown/customer-name-dropdown.component';
import { CustomerNameDropdownModule } from './View/common/customer-name-dropdown/customerNameDropdownModule.module';
import { CustomerAacountWithAccessComponent } from './View/core/customer-aacount-with-access/customer-aacount-with-access.component';
import { CustomerAacountWithoutAccessComponent } from './View/core/customer-aacount-without-access/customer-aacount-without-access.component';
import { AccountGroupManagementComponent } from './View/core/account-group-management/account-group-management.component';
import { AddGroupComponent } from './View/core/account-group-management/add-group/add-group.component';
import { UppercaseModuleModule } from './View/directives/uppercase-module/uppercase-module.module';
import { CustomerEmailNotificationComponent } from './View/core/customer-email-notification/customer-email-notification.component';
import { AddSubscriptionComponent } from './View/core/customer-email-notification/add-subscription/add-subscription.component';
import { AdminComponentTypeComponent } from './View/core/master-data-management/admin-component-type/admin-component-type.component';
import { AddEditVehicleComponent } from './View/core/add-vehicle/add-edit-vehicle.component';

@NgModule({
    declarations: [
        AppComponent, HeaderComponent, FooterComponent, PreloaderComponent,
        PopComponent, HomeComponent, AccessDeniedComponent, PopupComponent,
        SampleComponent,
        UserTypeSelectionComponent,
        ExternalUserLoginComponent,
        ManageFleetComponent,
        EditVehicleComponent,
        AddEditVehicleComponent,
        MoreInformationComponent,
        AdassertComponent,
        IamassertComponent,
        AnalyzeSampleGridComponent,
        AnalyzeSampleInformationComponent,
        NoCommaPipe,
        PopulationGridComponent,
        ShowMoreInformationComponent,
        GridComponent,
        FormComponent,
        GeneratePdfComponent,
        AddPopulationComponent,
        AlphaNumericDirective,
        LimitFlaggingComponent,
        ModifyThresholdComponent,
        EditKeyIssuesComponent,
        GraphPlotComponent,
        NumberDotDirective,
        ViewReportComponent,
        ValidRankDirective,
        // UppercaseDirective,
        LineGraphComponent,
        ScatterPlotComponent,
        SampleDashboardHomeComponent,
        ScatterPlotGraphComponent,
        LineGraphHomeComponent,
        LineGraphFuelComponent,
        ScatterPlotGridComponent,
        NotFoundComponent,
        LogoutComponent,
        UploadUnitComponent,
        ExternalLoginComponent,
        TwoDigitDecimalDirective,
        AdminPortalComponent,
        MasterDataManagementComponent,
        ReasonCommentHistoryComponent,
        ViewReportHomeComponent,
        FinanceReportComponent,
        ReportHomeComponent,
        TsmAeReportComponent,
        ReportPieChartComponent,
        MetadataInfoComponent,
        CogsComponent,
        LiveUpdateComponent,
        CommentsComponent,
        FluidFilterInfoComponent,
        MetadataComponent,
        OpsCountReportComponent,
        OpsKeyissueReportComponent,
        OpsTotReportComponent,
        OpsReportHomeComponent,
        FleetReportHomeComponent,
        FleetUnitsOdiReportComponent,
        FleetSampleTotReportComponent,
        FleetUnitIdCountReportComponent,
        CalendarComponent,
        AstmComponent,
        CustomerAccountComponent,
        DbuToolComponent,
        DbuBranchComponent,
        BranchCustomerMappingComponent,
        WwidAccessComponent,
        FleetDetailsComponent,
        AdminUnitMakeComponent,
        AdminApplicationTypeComponent,
        AdminEngineMakeComponent,
        CustomerAacountAccessComponent,
        QrDetailsComponent,
        HoldDateDetailsComponent,
        CustomerAacountWithAccessComponent,
        CustomerAacountWithoutAccessComponent,
        AccountGroupManagementComponent,
        AddGroupComponent,
        // CustomerNameDropdownComponent,
        CustomerEmailNotificationComponent,
        AddSubscriptionComponent,
        AdminComponentTypeComponent
    ],
    imports: [
        NgbModule.forRoot(), ApproutingModule, BrowserModule, BrowserAnimationsModule,
        FormsModule, HttpClientModule, CommonModuleModule,
        UppercaseModuleModule,
        MatDialogModule, MatCardModule, SessionExpirationAlert.forRoot({ totalMinutes: 25 }),
         NoopAnimationsModule, AlertModule, MatSelectModule, ReactiveFormsModule, MatTooltipModule,
        SortModuleModule, MatAutocompleteModule, MatRadioModule, ScrollDispatchModule, SharedModule, MatDatepickerModule, MatNativeDateModule,
        MatFormFieldModule, AngularHolidayPlannerModule, CustomerNameDropdownModule
    ],
    providers: [ApiHandlerService, SharedService, LoaderService, CookieService, MasterDataManagementService,
        DatePipe, DecimalPipe,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [
                LoaderService, ConsoleService
            ]
        }
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }