import { Component, OnInit, Input } from '@angular/core';
import { ShowMoreInformationService } from './show-more-information.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';

@Component({
  selector: 'app-show-more-information',
  templateUrl: './show-more-information.component.html',
  styleUrls: ['./show-more-information.component.css']
})
export class ShowMoreInformationComponent implements OnInit {

  @Input() showMoreInformationData;

  isExpanded: boolean = false;
  customerAndUnitInfoColumns: any[] = [];
  fluidAndFilterInfoColumns: any[] = [];
  sampleInfoColumns: any[] = [];
  allColumnsData: any[] = [];
  currentItem: any = [];

  constructor(private showMoreInfoService: ShowMoreInformationService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.currentItem = JSON.parse(localStorage.getItem('registerSampleObject'));
    this.customerAndUnitInfoColumns = [
      { code: 'companyId', name: 'Company ID' },
      { code: 'companyName', name: 'Company Name' },
      { code: 'customerName', name: 'Customer Name' },
      { code: 'phoneNumber', name: 'Phone Number' },
      { code: 'componentId', name: 'Component ID' },
      { code: 'unitMake', name: 'Unit Make' },
      { code: 'unitModel', name: 'Unit Model' },
      { code: 'unitYear', name: 'Unit Year' },
      { code: 'esn', name: 'Equipment Serial No' }

    ];

    if(this.currentItem.analysisType == 'Engine Oil (Liquid Fuel)' || this.currentItem.analysisType == 'Engine Oil (Gaseous Fuel)' || this.currentItem.analysisType == 'Coolant' || this.currentItem.analysisType == 'Lubricants'){

      this.fluidAndFilterInfoColumns = [
        { code: 'fluidBrand', name: 'Fluid Brand' },
        { code: 'fluidProduct', name: 'Fluid Product' },
        { code: 'fluidGrade', name: 'Fluid Grade' },
        { code: 'filterBrand', name: 'Filter Brand' },
        { code: 'filterProduct', name: 'Filter Product' },
        { code: 'sampledLocation', name: 'Sampled Location' },
        { code: 'trackingNumber', name: 'Tracking Number' },
        { code: 'sampleRemarks', name: 'Sample Remarks' }
      ];
    }else if(this.currentItem.analysisType == 'Diesel Fuel'){
      this.fluidAndFilterInfoColumns = [
        { code: 'fluidGrade', name: 'Diesel Fuel' },
        { code: 'filterBrand', name: 'Filter Brand' },
        { code: 'filterProduct', name: 'Filter Product' },
        { code: 'sampledLocation', name: 'Sampled Location' },
        { code: 'geographicLocation', name: 'Geographic Location' },
        { code: 'trackingNumber', name: 'Tracking Number' },
        { code: 'sampleRemarks', name: 'Sample Remarks' },
        // { code: '', name: '' },
      ];
    }

    this.sampleInfoColumns = [
      { code: 'sampleId', name: 'Sample ID' },
      { code: 'qrCodeId', name: 'QR Code' },
      { code: 'sampledDate', name: 'Sample Date' },
      { code: 'receivedDate', name: 'Received Date' },
      { code: 'completedDate', name: 'Completed Date' },
      { code: 'engineMake', name: 'Component Make' },
      { code: 'engineModel', name: 'Component Model' },
      { code: 'engineYear', name: 'Component Year' },
      { code: 'gsn', name: 'Component Serial No' }
    ];
  }

  showMoreInfo() {
    //console.log(this.showMoreInformationData);
    this.allColumnsData = this.showMoreInformationData;
    this.isExpanded = true;
  }

  showLessInfo() {
    this.isExpanded = false;
    this.allColumnsData = [];
  }

}
