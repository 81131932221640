import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management.service';

@Component({
  selector: 'app-cogs',
  templateUrl: './cogs.component.html',
  styleUrls: ['./cogs.component.css']
})
export class CogsComponent implements OnInit,AfterViewChecked {

  @ViewChildren(SortComponent) sortComponents;  
  isNewData:boolean=false;
  fluidTypeList = [];
  testLevelList = [];
  testLevelList1 = [];
  cogsList = [];
  displayResult = false;
  latestCogs:any;
  scrollToTop =  false;
  addModal = false;   
  searchObj:any; 
  minDate: Date;     
  
  cogsForm=new UntypedFormGroup({
    fluidType: new UntypedFormControl('', [Validators.required]),
    testLevel: new UntypedFormControl('', [Validators.required])
  });   

  addCogsForm=new UntypedFormGroup({
    fluidType: new UntypedFormControl('', [Validators.required]),
    testLevel: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),    
    cogs: new UntypedFormControl('', [Validators.required, Validators.max(999.99)])
  });

    /* ------Pagination variables declaration--------- */
    pageSizeOptions: any[] = common.pageSizeOptions;
    pageSize = "10";
    currentPage = 1;
    showRecords = 0;
    maxPage = 0;
    totalItems: any;
    changePage: any = 1;
    rowDetails: any;
    sortingOrder: string;
    columnName: string;
    searchOption: any;  

    cogsColumn = [
      { code: 'fluidType', name: 'Fluid Type' },
      { code: 'testLevel', name: 'Test Package' },
      { code: 'startDate', name: 'Effective Start Date' },
      { code: 'endDate', name: 'End Date' },
      { code: 'cogs', name: 'COGS $' }
    ];
  testLevel: string;

  constructor( private commonHandler: CommonHandlerService,
    private sharedService: SharedService,
    private masterDataManagementService:MasterDataManagementService,
    private alertService: AlertService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.columnName = 'startDate';
    this.displayResult = false;
    this.getCogsDropdown();
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  inputChange(){
    this.testLevelList = [];
    this.cogsForm.patchValue({
      testLevel: ''
    }); 
    this.displayResult = false;
    this.isNewData = false;
    for(let i=0; i<this.testLevelList1.length; i++) {
      if(this.cogsForm.controls['fluidType'].value == '3') {
        if(this.testLevelList1[i].testLevelName.toLowerCase() == 'advanced' || this.testLevelList1[i].testLevelName.toLowerCase() == 'contaminant evaluation' || this.testLevelList1[i].testLevelName.toLowerCase() == 'fleet') {
          this.testLevelList.push(this.testLevelList1[i].testLevelName);
          console.log(this.testLevelList)
        }
      }
      else if( this.cogsForm.controls['fluidType'].value == '1' 
        || this.cogsForm.controls['fluidType'].value == '2') {
        if(this.testLevelList1[i].testLevelName.toLowerCase() == 'advanced' || this.testLevelList1[i].testLevelName.toLowerCase() == 'basic' || this.testLevelList1[i].testLevelName.toLowerCase() == 'fleet') {
          this.testLevelList.push(this.testLevelList1[i].testLevelName);
        }
      }
     else if(this.cogsForm.controls['fluidType'].value == '6'){
        if(this.testLevelList1[i].testLevelName.toLowerCase() == 'advanced'|| this.testLevelList1[i].testLevelName.toLowerCase() == 'fleet') {
          this.testLevelList.push(this.testLevelList1[i].testLevelName);
        }
      }
      else if(this.cogsForm.controls['fluidType'].value == '4'){
        if(this.testLevelList1[i].testLevelName.toLowerCase() == 'properties' || this.testLevelList1[i].testLevelName.toLowerCase() == 'cleanliness' || this.testLevelList1[i].testLevelName.toLowerCase() == 'quality') {
          this.testLevelList.push(this.testLevelList1[i].testLevelName);
        }
      }
      else {
        this.testLevelList =[];
      }
    }
  }

  getCogsDropdown(){
    this.masterDataManagementService.getCogsDropdown().subscribe(response => {
      this.fluidTypeList = response.fluidTypeList;
      this.testLevelList1 = response.testLevelList;
    },err => {
        this.alertService.error(err);        
    });
  }

  getFluidTypeName(){
    let fluidTypeName = '';
    this.fluidTypeList.forEach(row => {
      if(row.fluidType == this.cogsForm.controls.fluidType.value){
        fluidTypeName = row.fluidTypeName;
      }
    });
    return fluidTypeName;
  }

  getTestLevelName(){
    let testLevelName = '';
    this.testLevelList.forEach(row => {
      if(row.testLevel == this.cogsForm.controls.testLevel.value){
        testLevelName = row.testLevelName;
      }
    });

    return testLevelName;
  }
  
  searchCogs(){
    this.currentPage = 1;
    this.isNewData = false;
    this.addCogsForm.reset();
    this.searchCogsData();
  }
  searchCogsData(){
    let data = this.cogsForm.getRawValue();
    if(this.cogsForm.controls.testLevel.value=="ADVANCED"){
      this.testLevel = '2'

    }else if(this.cogsForm.controls.testLevel.value=="BASIC"){
      this.testLevel = '1'

    }else if(this.cogsForm.controls.testLevel.value=="FLEET"){
      this.testLevel = '3'

    }else if(this.cogsForm.controls.testLevel.value=="CONTAMINANT EVALUATION"){
      this.testLevel = '4'

    }else if(this.cogsForm.controls.testLevel.value=="PROPERTIES"){
      this.testLevel = '6'

    }else if(this.cogsForm.controls.testLevel.value=="CLEANLINESS "){
      this.testLevel = '7'

    }else if(this.cogsForm.controls.testLevel.value=="QUALITY"){
      this.testLevel = '5'

    }
    data['pageSize'] = this.pageSize.toString();
    data['currentPage'] = this.currentPage;
    data['columnName'] = this.columnName;
    data['sortingOrder'] = this.sortingOrder;
    data['testLevel'] =this.testLevel ;
    this.searchObj = data;
    console.log(data)
    this.masterDataManagementService.getSearchCogs(data).subscribe(response => {
      if (response.statusCode == "1") {
        this.cogsList = response.cogsList;
        this.displayResult = true;
        this.totalItems = response.cogsList[0].maxRow;
        this.calculateOffset();
      }else{
        this.cogsList = [];
        this.displayResult = true;
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    },err => {
        this.alertService.error(err);        
    });
  }

  clearCogs(){
    this.cogsForm.reset();
    this.displayResult = false;
  }

  addNewData(){
    this.addCogsForm.reset();
    let data = this.cogsForm.getRawValue();    
    this.masterDataManagementService.getLatestCogs(data).subscribe(response => {
      this.latestCogs = response.latestCogs;
      this.isNewData = true; 
      console.log(this.latestCogs.startDate);      
      //this.minDate = new Date(this.latestCogs.startDate);
      //this.minDate.setDate(this.minDate.getDate() + 1);
      this.minDate = new Date();
      this.minDate.setDate(this.minDate.getDate());
      this.addCogsForm.patchValue({
        fluidType: this.cogsForm.controls.fluidType.value,
        testLevel: this.cogsForm.controls.testLevel.value
      });     
    },err => {
        this.alertService.error(err);        
    });    
  }

  hideNewData(){
    this.isNewData = false;
    this.addCogsForm.reset();
  }

  openAddModal(){
    if(!this.addCogsForm.invalid){
      this.addModal = true;
    }else{
      this.addModal = false;      
      Object.keys(this.addCogsForm.controls).forEach(field => { 
        const control = this.addCogsForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }    
  }

  closeAddModal(){    
    this.addModal = false;
  }

  addCogs(){
    let data = this.addCogsForm.getRawValue();
    data["createdBy"] = this.sharedService.getUserId();
    data["updatedBy"] = this.sharedService.getUserId();
    data["startDate"] = this.datePipe.transform(
      this.addCogsForm.controls["startDate"].value,
      'yyyy-MM-dd'
    );    
    data["longStartDate"] = new Date(data.startDate).getTime();   
    delete data["startDate"];
    console.log(data);

    /*this.alertService.success("success");
    this.scrollToTop = true;
      this.addModal = false;*/

    this.masterDataManagementService.addCogs(data).subscribe(response => {       
      if (response.statusCode == "1"){ 
       this.isNewData = false;
       this.searchCogsData();     
       this.alertService.success(response.statusMessage);        
      }else {
        this.alertService.error(response.statusMessage);
      }
      this.scrollToTop = true;
      this.addModal = false;
    }, err => {
      this.alertService.error(err);
      this.addModal = false;
      this.scrollToTop = true;
    });
  }


  // Pagination
  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;  
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {   
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

    /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.searchCogsData();
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;  
    this.searchCogsData();    
  }  
}
