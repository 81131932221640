import { ValidatorFn, ValidationErrors, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

/*export const multipleEmailValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const email = control.get('contactEmail');
    const EMAIL_REGEXP: RegExp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (email) {
        if (email.value !== undefined && email.value !== '') {
            const emailsArr: string[] = email.value.split(';');
            for (const emailStr of emailsArr) {
                if (!EMAIL_REGEXP.test(emailStr)) {
                    control.get('contactEmail').setErrors({ invalidEmail: true });
                    break;
                }
            }

        } else {
            return null;
        }
    }
};*/
export const validateUnitDistance: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }
    let valid = true;
    if (!isNaN(+control.value)) {
        valid = true;
    } else {
        valid = false;
    }
    return valid ? null : { invalidUnitDistance: true };
};

// patternValidator(): ValidatorFn {
//     return (control: AbstractControl): { [key: string]: any } => {
//         if (!control.value) {
//             return null;
//         }
//         const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
//         const valid = regex.test(control.value);
//         return valid ? null : { invalidPassword: true };
//     };
// }
export const requiredFiledValidator: ValidatorFn = (formGrp: UntypedFormGroup): ValidationErrors | null => {
    const fluidBrand = formGrp.get('fluidBrand');
    const dummyBrandName = formGrp.get('dummyBrandName');
    const fluidProduct = formGrp.get('fluidProduct');
    const dummyProductName = formGrp.get('dummyProductName');
    const fluidGrade = formGrp.get('fluidGrade');
    const dummyGradeName = formGrp.get('dummyGradeName');
    if (fluidBrand.touched || dummyBrandName.touched) {
        if (fluidBrand.value === null && dummyBrandName.value === '') {
            fluidBrand.setErrors({ 'requiredFiled': true });
            fluidBrand.markAsTouched();
        } else {
            fluidBrand.setErrors(null);
        }
    } else {
        fluidBrand.setErrors(null);
    }
    if (fluidProduct.touched || dummyProductName.touched) {
        if (fluidProduct.value === null && dummyProductName.value === '') {
            fluidProduct.setErrors({ 'requiredFiled': true });
            fluidProduct.markAsTouched();
        } else {
            fluidProduct.setErrors(null);
        }
    } else {
        fluidProduct.setErrors(null);
    }
    if (fluidGrade.touched || dummyGradeName.touched) {
        if (fluidGrade.value === null && dummyGradeName.value === '') {
            fluidGrade.setErrors({ 'requiredFiled': true });
            fluidGrade.markAsTouched();
        } else {
            fluidGrade.setErrors(null);
        }
    } else {
        fluidGrade.setErrors(null);
    }
    return null;
};