export class Pop {
    type: PopType;
    title:string;
    message: string;
    popId: string;
    keepAfterRouteChange: boolean;
    constructor(init?: Partial<Pop>) {
        Object.assign(this, init);
    }
}

export enum PopType {
    Browser,
    Batch,

}
