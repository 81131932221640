import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from '../../handlers/api-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerNameDropdownService {

  constructor(private api: ApiHandlerService) { }

  getDropdownData(data: any){
    return this.api.post(environment.apiUrl + '/branchCustomerMapping', data);
  }

  getDropdownDataforTSM(data: any){
    return this.api.post(environment.apiUrl + '/wwidBranchAccess', data);
  }

  getEquipmentDropdownData(data: any){
    return this.api.post(environment.apiUrl + '/getEquipmentDropdownData', data);
  }
}
