import { Component, OnInit, ViewChildren } from '@angular/core';
import { LimitFlaggingService } from './limit-flagging.service';
import { Router } from '@angular/router';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { PopulationGridService } from '../population-grid/population-grid.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-limit-flagging',
  templateUrl: './limit-flagging.component.html',
  styleUrls: ['./limit-flagging.component.css']
})
export class LimitFlaggingComponent implements OnInit {

  //populationId: string ='';
  populationIdToDelete: string = '';
  fluidIdToDelete: any = '';
  deleteModal: boolean = false;
  records : boolean = false;
  updateSuccessModal: boolean = false

  limitFlaggingHeaders = [];
  populationData: any = [];
  populationDataList: any = [];
  limitFlagDashboardData: any = [];
  parametersList = [];
  kAlRatio = {};
  columnName: string;
  sortingOrder: string;
  searchObj: any;
  selectedTab: string = '';
  keyIssueDashboardGrid: any[] = [];
  activatePopulation: boolean = false;
  deactivatePopulation: boolean = false;
  statusMessage: string = '';
  messageModal: boolean = false;
  keyIssuesList: any = [];

  constructor(private limitFlagServ: LimitFlaggingService,
    private alertService: AlertService, private router: Router,
    private populationService: PopulationGridService,
    private sharedService: SharedService) { }

  @ViewChildren(SortComponent) sortComponents;

  ngOnInit() {
    this.selectedTab = JSON.parse(localStorage.getItem('selectedTab'));
    this.columnName = "parameter";
    this.sortingOrder = "asc";
    this.limitFlaggingHeaders = [
      { code: 'lastUpdatedDate', name: 'Last Updated Date' },
      { code: 'fluidType', name: 'Fluid Type' },
      { code: 'populationName', name: 'Population Name' },
      { code: 'itemsInPopulation', name: 'Items in Population'},
     { code: 'publishedRecords', name: 'Published Records' }
    ];

    this.populationData = JSON.parse(localStorage.getItem('populationForLimitFlag'));
    this.populationDataList.push(this.populationData);
    //console.log("status in limit flag", this.populationData.status)
    //console.log("populationData", this.populationData)
    
    if(this.populationData.publishedRecords === 0)
    {
       this.records= true;
    }
    //console.log("records", this.records);
    if (this.populationData.status == 'Active') {
      this.activatePopulation = true;
    } else {
      this.deactivatePopulation = true;
    }

  this.populationIdToDelete=this.populationData.populationId;
  this.fluidIdToDelete = this.populationData.fluidTypeId;
  //console.log("populationId",this.populationIdToDelete);
    this.getLimitFlagDashboardData();
  }

  selectedTabParameter() {
    this.selectedTab = 'parameter';
    localStorage.setItem('selectedTab', JSON.stringify(this.selectedTab));
  }

  selectedTabKeyIssue() {
    this.selectedTab = 'keyIssue';
    localStorage.setItem('selectedTab', JSON.stringify(this.selectedTab));
  }

  getLimitFlagDashboardData() {
    let reqData = {
      //populationId: "1",
      populationId: this.populationData.populationId,
      sortingOrder: this.sortingOrder,
      fluidType: this.populationData.fluidTypeId
    }

    //console.log("reqdata -", reqData);

    this.limitFlagServ.getLimitFlagDashboardData(reqData).subscribe(
      response => {
        //console.log("limit flag dashboard data", response);

        this.limitFlagDashboardData = response.limitFlaggingList;
        this.parametersList = response.parametersList;
        this.keyIssueDashboardGrid = response.keyIssueThresholdsList;
        this.keyIssuesList = response.keyIssuesList;
        this.kAlRatio = response.ratio;
      });

    this.searchObj = {
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    }
  }

  modifyThreshold(data) {
    //console.log("table row data", data);
    localStorage.setItem('populationdata', JSON.stringify(this.populationDataList));
    localStorage.setItem('currentValue', JSON.stringify(data));
    localStorage.setItem('dashboardData', JSON.stringify(this.limitFlagDashboardData));
    localStorage.setItem('parameterList', JSON.stringify(this.parametersList));
    localStorage.setItem('kAlRatio', JSON.stringify(this.kAlRatio));
    this.router.navigate(['/modifyThreshold']);

  }

  editKeyIssue(item) {
    localStorage.setItem('currentKeyIssueData', JSON.stringify(item));
    localStorage.setItem('keyIssueData', JSON.stringify(this.keyIssueDashboardGrid));
    localStorage.setItem('keyIssuesList', JSON.stringify(this.keyIssuesList));
    this.router.navigate(['/editKeyIssues']);
  }

  searchParam() {

    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("parameterSearch");
    filter = input.value.toUpperCase();
    ul = document.getElementById("parameter");
    li = ul.getElementsByTagName("tr");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("td")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */

  sortData(columnName) {
    //console.log(columnName);
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.getLimitFlagDashboardData();
  }

  setPopulationStatus() {
    var reqParam = {};
    if (this.populationData.status == 'Active') {
      reqParam = {
        userId: this.sharedService.getUserId(),
        populationId: this.populationData.populationId,
        fluidType: this.populationData.fluidTypeId,
        activate: false

      }
    } else {
      reqParam = {
        userId: this.sharedService.getUserId(),
        populationId: this.populationData.populationId,
        fluidType: this.populationData.fluidTypeId,
        activate: true

      }
    }

    this.limitFlagServ.setPopulationStatus(reqParam).subscribe(response => {
      //if (response.statusCode == 1) {
      this.statusMessage = response.statusMessage
      this.messageModal = true;

    });


  }

 /* ---------------------Delete Population------------------ */

 closePopup(){
 this.messageModal = false;
 }

 openDeleteModal() {
  this.deleteModal = true;
  //this.populationIdToDelete = id;
}

closeDeleteModal() {
  this.deleteModal = false;
}

deletePopulation() {
  this.deleteModal = false
  let req = {
    populationId: this.populationIdToDelete,
    fluidTypeId: this.fluidIdToDelete
  }
  this.populationService.deletePopulation(req).subscribe(
    response => {
      if (response.statusCode == "1") {
       // this.alertService.success(response.statusMessage);
       this.updateSuccessModal = true; 
      } else {
        this.alertService.error(response.statusMessage);
      }
    }, err => {
      this.alertService.error(err);
    }
  );
 // this.router.navigate(["/populationManagement"]);
}



}
