import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ConsoleService } from './console.service';
@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private printOut: ConsoleService) { }

  DownloadData(inputobj: any, CCmoduleName): Observable<any> {
    return new Observable(obs => {
      const oReq = new XMLHttpRequest();
      oReq.open('POST', environment.App_URL + CCmoduleName + '/export', true);
      oReq.setRequestHeader('content-type', 'application/json');
      // oReq.setRequestHeader('accept', 'application/octet-stream');
      this.printOut.log(oReq.response);
      oReq.responseType = 'arraybuffer';
      oReq.onload = function (oEvent) {
        const arrayBuffer = oReq.response;
        const byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };
      const body = JSON.stringify(inputobj);
      oReq.send(body);
    });
  }
}
