


export const errorMessage = {
  esnExist: 'Serial Number already exists',
  selectErrorType: 'Select Error Record Type',
  invalidInput: 'Invalid input please enter valid input',
  esnNotWithinRange: 'Serial Number not within range',
  invalidResponse: 'No valid response from server',
  invalidDeleteResponse: 'No valid response or delete action has been failed',
  internalError: 'Internal Server Error',
  serverError: 'Not able to reach the server',
  mandateToUpdatePI: 'Technical Configuration,Build Date & Shop Order Number will be mandatory to update any Product Information',
  failedToUpdate: 'Updating the record has been failed,This may due to invalid input or internal error occured',
  errorCrctFailed: 'Error correction got failed while updating or Error occured while processing',
  unauthorizedAccess: 'Unauthorized access.Please contact System Administrator',
  sessionCrashed: ' We\'re sorry.We\'re having some trouble completing your request or your server session got expired.Please close your browser and try again.',
  sessionExpired: 'Server session got expired.Please close your browser and try again.',
  techConfigNotFound: 'Technical Configuration is invalid',
  sorevInvalid: 'Shop Order Revision Number invalid',
  soInvalid: 'Invalid Shop Order Number',
  soInvalidFormat: 'Invalid Format for Shop Order Number',
  pageNotFound: 'Page not found, error might occured due to API url mismatch or internet connectivity',
  buildDateMandate: 'Build Date is required to get CPL Number',
  noinputRecord: 'No records found in the selected file.File cannot be empty',
  noConfigurationFound: 'No any configuration found.If required you can create new configuration',
  mandateToCreateMktg: 'All fields are mandatory.Kindly fill all the fields',
  mandateToSearchMktg: 'Techincal Configuration & Shop Order Number or Techincal Configuration & Plant Code are  mandatory for the search',
  errorCrctnFailure: 'Error correction got failed while updating the changes or Error occured while reprocessing the error record',
  deleteFailed: 'No valid response or delete action has been failed'
};
export const successMessage = {
  errorListLoaded: 'Error List loaded sucessfully',
  errorProcessed: 'Record processed sucessfully',
  recordUpdated: 'Record Updated sucessfully',
  recordCreated: 'Record  created sucessfully for the Serial Number:',
  soValid: 'Shop Order Number is valid',
  reponseValid: 'Response recived sucessfully for the Serial Number',
  reprocessedDone: 'Record processed sucessfully,will redirect to Error list page shortly'
};
export const warnMessage = {
  errorExisted: 'The record has been processed and few more errors found !!.',
  paginatorNotAvail: 'The page number which you have requested is not available',
  futureRecord: 'Selected record(s) has future start date and it will be available for update and click save if any changes made.',
  maxSelectionReached: 'Maximum of 200 records can be selected.',
  maxListSelectionReached: '"Maximum user can select upto 10 items',
  performanceWarning: 'Selecting the page size to 500 or 1000 might affects the performance.Do you still want to continue?'
};

export const infoMessage = {
  noRecordsToDisplay: 'No records found',

};

export const common = {
  actions: ['save', 'delete', 'clearSelection', 'addNew', 'pageChange'],
  actionsDesc: ['Save', 'Delete', 'Clear Selection', 'Add New', 'Page Change'],
  title: 'Confirmation',
  clearSelection: 'Are you sure you want to deselect record(s) , any changes made will be lost ?',
  saveConfirm: 'Do you  want to save the selected record(s) ?',
  cancelConfirm: 'Are you sure  you want to  cancel the edit ?',
  deleteConfirm: 'Are You Sure You Want to Delete Selected Record(s)?',
  beforeAddNew: 'Any changes made will be lost.Do you want to create new record. Click Ok to continue?',
  pageChangeConfirm: 'Any Changes made to the selected Record(s) in this page will be lost',
  pageChangeSelectConfirm: 'The selected records will be unselected',
  minDate: '01-01-1900',
  // sequence not to be changes,it has dependancy on the index
  monthName: ['Jan', 'Feb', 'Mar',
    'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep',
    'Oct', 'Nov', 'Dec'],
  pageSize: '5',
  pageSizeOptions: [10, 25, 50, 100],
  batchInformation: 'Batch proccess in progress, You will be working in read-only mode',
  batchdone: 'Batch proccess completed, You will be working in default mode',
  browserNotification: 'For better experience use Google Chrome , Mozilla Firefox (above 60.00 version)',
  cnfTitleName: 'Confirmation',
  changeConfirmation: 'Any Changes made to the selected Record(s) will be lost',
  pleaseWait: 'Your are now redirecting to login page.Please wait!'

};
export class GlobalSort {
  columnName: string;
  sortingOrder: string;

}

export const fluidType = {
  ANALYSIS_TYPE_OIL_1: 'Engine Oil (Liquid Fuel)',

  ANALYSIS_TYPE_OIL_2: 'Engine Oil (Gaseous Fuel)',

  ANALYSIS_TYPE_COOLANT: 'Coolant',

  ANALYSIS_TYPE_FUEL: 'Diesel Fuel',

  ANALYSIS_TYPE_DEF: 'Diesel Exhaust Fluid',
}

export const USERTYPE = {
  EXTERNAL: 'Sample Registration User',
  DATA_SCIENTIST: 'Data Scientist',
  LAB_ADMIN: 'Lab Admin',
  CMI_USER: 'CMI Engineer',
  TSM: 'Technical Service Manager',
  AE: 'Application Engineer',
  DBU_BRANCH: 'DBU Branch',
  DBU_FLUIDS: 'DBU Fluids',
  FLUIDWATCH_MANAGER:'FluidWatch Manager'
}

export const USERS = {
  INTERNAL: 'intUser',
  EXTERNAL: 'extUser'
}

export const DISTANCE_TYPE = {
  FLUID_DISTANCE: 'fluidDistance',
  UNIT_DISTANCE: 'unitDistance'
}

export const FLUID_TYPE = {
  DIESEL_ENGINE_OIL: 'Engine Oil (Liquid Fuel)',
  NATURAL_GAS_OIL: 'Engine Oil (Gaseous Fuel)',
  ENGINE_COOLANTS: 'Coolant',
  DIESEL_FUEL: 'Diesel Fuel',
  DIESEL_EXHAUST_FLUID: 'DEF',
  LUBRICANTS: 'Lubricants'

}