import { Component, OnInit, Input, NgZone, AfterContentChecked} from '@angular/core';
import * as d3 from 'd3';
import { GraphPlotService } from './graph-plot.service';
import html2canvas from 'html2canvas'
import { GraphData } from './graph-data';
import { SharedService } from 'src/app/View/service/shared.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { GraphCount } from './graph-count';
import { LoaderService } from 'src/app/View/common/preloader/loader.service';


@Component({
  selector: 'app-graph-plot',
  templateUrl: './graph-plot.component.html',
  styleUrls: ['./graph-plot.component.css']
})
export class GraphPlotComponent implements OnInit,AfterContentChecked {

  // oilHealthButtonList = [{ code: 'oxidation', value: 'Oxidation' },
  // { code: 'nitration', value: 'Nitration' },
  // { code: 'soot', value: 'Soot' },
  // { code: 'fuelDilution', value: 'Fuel Dilution' }];

  @Input() isReportView: boolean;
  isParameterClicked: boolean = false;
  currenButton: string = '';
  paramName: string = '';
  currentBtnIndex: number = 0;
  data: any[] = [];
  data1: any[] = [];
  data2: any[] = [];
  labelAndLegends: boolean = false;
  labelAndLegendsTAN: boolean = false;
  yAxisLabel: string = '';
  popDatList: any[] = [];
  sampleDataList = [];
  unitDataList = [];
  isSelectGraph: boolean = false;
  isParameterSelected: boolean = false;
  divIdCount: number = 1;
  nextParamBtn: string = '';
  isMaintain: boolean = false;
  maintainParamArray: any = [];
  showAllGraph: boolean = false;
  currentBtnClicked: any = '';
  canvas: any;
  divArrayList: any = [];
  btnArrayList: any = [];
  allThumbnailsList: any = [];
  canvasImgArrayList = [];
  currentItem: any = [];
  populationId: '';
  isDisabledSelectThumbnails: boolean = false;
  paramDescripton: string = '';
  parameterName: string = '';
  parameterUnit: string = '';
  noDataFound: string = '';
  isDataNotFound: boolean = false;
  isGraphThumbnailDeleted: boolean = false;
  thumbnailCount: any = 0;
  maximizeThumbnails: any;
  isGraphSelected: boolean = false;
  uomLabel:any;
  parameterfuelFTIR: any;
  parameterfd: any;
  parameterwater: any;
  parameterwaterFTIR: any;
  show_Wt_button: boolean;
  show_FD_button: boolean;  
  constructor(private graphServ: GraphPlotService, public sharedService: SharedService, private alertService: AlertService,
    private loader: LoaderService, private ngZone: NgZone) { }

  ngOnInit() {
    this.labelAndLegends = false;
    this.labelAndLegendsTAN = false;
    this.isSelectGraph = false;
    this.isParameterSelected = false;
    //this.isReportView = false;
    //alert(this.isReportView);
    this.currentItem = JSON.parse(localStorage.getItem('registerSampleObject'));
    
    //console.log("sample id in graph comp", this.currentItem);
    if(this.currentItem.uom == 'MILES'){
      this.uomLabel = '(mi)';
    }else if(this.currentItem.uom == 'KILOMETERS'){
      this.uomLabel = '(km)';
    }else if(this.currentItem.uom == 'HOURS'){
      this.uomLabel = '(hr)';
    }

    //this.sharedService.graphDataList = [];
    this.isDataNotFound = false;


    this.setDisabledSelectThumbnails();

    this.sharedService.graphData.subscribe(data => {
      let selectedGraphCount = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].imageData != '') {
          selectedGraphCount = selectedGraphCount + 1;
        }
      }
      if (selectedGraphCount > 0) {
        this.isGraphSelected = true;
      } else {
        this.isGraphSelected = false;
      }

    });


  }

  plotScatter(str) {
    this.displayGraph('');
    this.isDataNotFound = false;

    this.popDatList = [];
    this.sampleDataList = [];
    this.unitDataList = [];
    this.populationId = JSON.parse(localStorage.getItem('populationId'));

    //this.labelAndLegends = true;
    // var paramName = str;
    this.paramName = str;
    //this.currenButton = str;

    let reqParam = {
      'populationId': this.populationId.toString(),
      'equipmentId': this.currentItem.equipmentId,
      'sampleId': this.currentItem.sampleId,
      'parameter': str.toUpperCase(),
      'analysisType': this.currentItem.analysisType
    }
    //console.log("reqparam to plot graph", reqParam);
    this.graphServ.plotGraphOnParameterSelect(reqParam).subscribe(response => {
      if (response.statusCode == "1") {
        this.labelAndLegends = true;
        this.labelAndLegendsTAN = false;;
        //console.log(response);
        this.popDatList = response.populationDataList;
        this.sampleDataList = response.sampleDataList;
        this.unitDataList = response.unitDataList;
        this.paramDescripton = response.parameterDetails.parameterDesc;
        this.parameterName = response.parameterDetails.parameterName;
        this.parameterUnit = response.parameterDetails.parameterUnit;


        //console.log('all list------->', this.popDatList, this.sampleDataList, this.unitDataList);
        if (this.parameterName == 'Fe' || this.parameterName == 'Cu' || this.parameterName == 'Pb' || this.parameterName == 'Cr'
          || this.parameterName == 'Al' || this.parameterName == 'K' || this.parameterName == 'Na' || this.parameterName == 'Ca'
          || this.parameterName == 'Sn' || this.parameterName == 'Zn' || this.parameterName == 'Mg' || this.parameterName == 'P'
          || this.parameterName == 'Pb' || this.parameterName == 'Mo' || this.parameterName == 'Si' || this.parameterName == 'Mn'
          || this.parameterName == 'Ag' || this.parameterName == 'Ba' || this.parameterName == 'Bi' || this.parameterName == 'In'
          || this.parameterName == 'Ni') {
          this.yAxisLabel = this.paramDescripton + ' (' + this.parameterName + ')';
        } else if(this.parameterName=='Chloride' ||this.parameterName=='Glycolate' || this.parameterName=='Formate ' ){
          this.yAxisLabel = this.paramDescripton + ' (' + this.parameterUnit + ')';
        }else if( this.parameterName=='pH' ){
          this.yAxisLabel = this.paramDescripton;
        } else if( this.parameterName=='TAN' ){
          this.yAxisLabel = this.paramDescripton + ' (' + this.parameterUnit + ')';
        } else {
        this.yAxisLabel = this.paramDescripton + ' (' + this.parameterUnit + ')';
        }

        if (this.popDatList.length > 0) {
          var xPopDataList = d3.max((this.popDatList), function (d) { return d[0]; });

        } else {
          xPopDataList = 0;
        }
        if (this.unitDataList.length > 0) {
          var xUnitDataList = d3.max((this.unitDataList), function (d) { return d[0]; });

        } else {
          xUnitDataList = 0;
        }
        if (this.sampleDataList.length > 0) {
          var xSampleDataList = d3.max((this.sampleDataList), function (d) { return d[0]; });

        } else {
          xSampleDataList = 0;
        }

        var xMax = Math.max(xPopDataList, xUnitDataList, xSampleDataList);


        if (this.popDatList.length > 0) {
          var yPopDataList = d3.max((this.popDatList), function (d) { return d[1]; });

        } else {
          yPopDataList = 0;
        }
        if (this.unitDataList.length > 0) {
          var yUnitDataList = d3.max((this.unitDataList), function (d) { return d[1]; });

        } else {
          yUnitDataList = 0;
        }
        if (this.sampleDataList.length > 0) {
          var ySampleDataList = d3.max((this.sampleDataList), function (d) { return d[1]; });

        } else {
          ySampleDataList = 0;
        }

        // var yPopDataList = d3.max((this.popDatList), function (d) { return d[1]; });
        // var yUnitDataList = d3.max((this.unitDataList), function (d) { return d[1]; });
        // var ySampleDataList = d3.max((this.sampleDataList), function (d) { return d[1]; });

        var yMax = Math.max(yPopDataList, yUnitDataList, ySampleDataList);

        d3.selectAll('svg').remove();

        //margin = { top: 20, right: 15, bottom: 60, left: 60 }
        var margin = { top: 20, right: 15, bottom: 60, left: 60 }, width = 550 - margin.left - margin.right
          , height = 530 - margin.top - margin.bottom;


        var x = d3.scaleLinear()
          .domain([0, xMax])
          .range([0, width]);



        var y = d3.scaleLinear()
          .domain([0, yMax])
          .range([height, 0]);


        var svg = d3.select('#scatter')
          .append('svg')
          .attr('width', width + margin.right + margin.left)
          .attr('height', height + margin.top + margin.bottom);
        // Y-axis label
        svg.append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0)
          .attr('x', 0 - (height / 2) - 10)
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .text(this.yAxisLabel).style('font-weight', 'bold').style('font-size', '12px');

        //X-axis label
        svg.append('text')
          .attr('transform',
            'translate(' + (width / 2) + ' ,' +
            (height + margin.top + 20) + ')')
          .attr('x', 60)
          .attr('y', 40)
          .style('text-anchor', 'middle')
          .text('Fluid Distance ' + this.uomLabel).style('font-weight', 'bold').style('font-size', '12px');


        var main = svg.append('g')
          .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
          .attr('width', width)
          .attr('height', height)
          .attr('class', 'main');

        // draw the X-axis
        var xAxis = d3.axisBottom(x).tickSize(-height);



        main.append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .attr('class', 'main axis date').style('stroke-dasharray', '5,5').style('color', 'lightgray')
          .call(xAxis).selectAll('text').style('color', 'black')
          .attr('transform', function (d) {
            return 'rotate(-60)'
          }).attr('x', -22).attr('y', -3);

        // draw the Y-axis
        var yAxis = d3.axisLeft(y).tickSize(-width);
        // var yAxis1 = d3.axisRight(y1);

        main.append('g')
          .attr('transform', 'translate(0,0)')
          .attr('class', 'main axis date').style('stroke-dasharray', '5,5').style('color', 'lightgray')
          .call(yAxis).selectAll('text').style('color', 'black');

        var g = main.append('g');

        //scatter circle placement in graph
        g.selectAll('scatter-dots')
          .data(this.popDatList)
          .enter().append('circle')
          .attr('cx', function (d, i) { return x(d[0]); })
          .attr('cy', function (d) { return y(d[1]); })
          .attr('r', 8)
          .style('fill', '#87CEFA');// radius and color of each spot

        g.selectAll('scatter-dots')
          .data(this.unitDataList)
          .enter().append('circle')
          .attr('cx', function (d, i) { return x(d[0]); })
          .attr('cy', function (d) { return y(d[1]); })
          .attr('r', 8)
          .style('fill', 'blue');// radius and color of each spot


        g.selectAll('scatter-dots')
          .data(this.sampleDataList)
          .enter().append('circle')
          .attr('cx', function (d, i) { return x(d[0]); })
          .attr('cy', function (d) { return y(d[1]); })
          .attr('r', 8)
          .style('fill', 'red');// radius and color of each spot

      } else {
        this.isDataNotFound = true;
        this.labelAndLegends = false;
        this.labelAndLegendsTAN = false;
        this.noDataFound = response.statusMessage;

      }


    }, err => {
      this.alertService.error(err);
    });

    this.isParameterSelected = true;
    this.isSelectGraph = false;


    const graphData: GraphData = new GraphData();
    graphData.graphName = str;

    for (const item of this.sharedService.graphDataList) {
      if (item.imageData === null || item.imageData === undefined || item.imageData === '') {
        this.sharedService.setGraphData(item, false);
      }
    }

    this.sharedService.setGraphData(graphData, true);
    //console.log("plot scatter method===", this.sharedService.graphDataList);

  }

  inputValueList = [];
  thumbnailList: any = [];

  setDisabledSelectThumbnails() {
    let imageCount = 0;
    this.sharedService.graphData.subscribe(data => {
      imageCount = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i].imageData != '') {
          imageCount = imageCount + 1;
        }
      }
      if (imageCount < 4) {
        this.isDisabledSelectThumbnails = false;
      } else {
        this.isDisabledSelectThumbnails = true;
      }
      if (data.length == 0) {
        this.isParameterSelected = false;
      } else {
        this.isParameterSelected = true;
      }
    });
  }

  setThumbnail(currentparam) {
    this.loader.log("Before making api call loader is invoked:");

    this.loader.show();
    this.loader.add();
    // //alert(this.isReportView);
    let thumbnailCount = 0;
    thumbnailCount++;
    // this.thumbnailList.push(this.thumbnailCount);
    let self = this;

    let graphData: GraphData = new GraphData();

    html2canvas(document.getElementById('graphToThumbnail')).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');
      self.AddImagesResource(imgData, currentparam);
      self.loader.delete();
      self.loader.hide();

    });


    const graphCount: GraphCount = new GraphCount();
    graphCount.thumbnailCount = thumbnailCount;
    graphCount.selectedParam = currentparam;
    this.sharedService.setThumbnailCount(graphCount, true);
    // alert(this.thumbnailList);


  }
  AddImagesResource(graphImgData: any, graphName: String) {
    //console.log('graphData : ' + graphImgData);
    const graphData: GraphData = new GraphData();
    graphData.graphName = graphName;
    graphData.imageData = graphImgData;
    this.sharedService.updateGraphData(graphData, '', graphImgData);
  }
  onSnapShotDelete(item: GraphData) {
    this.thumbnailCount--;
    //console.log('Deleted Graph thumbnail : ', item);

    this.sharedService.setGraphData(item, false);


    //this.sharedService.updateGraphData(item, '', false);

    //console.log("graph data list inside delete thumbnail method------>", this.sharedService.graphDataList);
    /*let imageCount = 0;
    for(var i=0;i<this.sharedService.graphDataList.length; i++){
      if(this.sharedService.graphDataList[i].imageData !=''){
        imageCount = imageCount + 1;
      }
    }
    if (imageCount < 4) {
      this.isDisabledSelectThumbnails = false;
    } else {
      this.isDisabledSelectThumbnails = true;
    }*/


    if (item.graphName.toLowerCase().toString() == this.paramName.toLowerCase.toString()) {
      this.isGraphThumbnailDeleted = true;
    }

    const graphCount: GraphCount = new GraphCount();
    //graphCount.thumbnailCount = this.thumbnailCount;
    graphCount.selectedParam = item.graphName;

    this.sharedService.setThumbnailCount(graphCount, false);

    if (this.paramName.toLowerCase() == item.graphName.toLowerCase()) {
      this.resetGraphComponent();
    }

  }
ngAfterContentChecked(){
  this.parameterfuelFTIR = JSON.parse(localStorage.getItem('parameterfuelFTIR'));
  this.parameterfd = JSON.parse(localStorage.getItem('parameterfd'));
  this.parameterwaterFTIR = JSON.parse(localStorage.getItem('parameterwaterFTIR'));
  this.parameterwater = JSON.parse(localStorage.getItem('parameterwater'));
  //FuelDilution 
  if(this.parameterfd){
    this.ngZone.run(() => {
      this.show_FD_button=true;
    });
  }
  //Fueldilution_FTIR
  if(this.parameterfuelFTIR){
    this.ngZone.run(() => {
    this.show_FD_button=false;
  });
  }
  //water
  if(this.parameterwater){
    this.ngZone.run(() => {
    this.show_Wt_button=true;
  });
 }
  //water_FTIR
  if(this.parameterwaterFTIR){
    this.ngZone.run(() => {
    this.show_Wt_button=false;
  });
  }
}
  onTextBlur(graphData: GraphData) {

    this.sharedService._graphData.next(this.sharedService.graphDataList);

  }

  getActiveBtnDetails(str) {
    let retObj = false;
    //console.log("in graph component, after retreiving the save graph", this.sharedService.graphDataList);
    if (this.sharedService.graphDataList) {
      for (const item of this.sharedService.graphDataList) {
        if (str.toString().toLowerCase() === item.graphName.toLowerCase()) {
          retObj = true;
          break;
        }
      }
    }
    return retObj;
  }

  closeNoRecordPopup() {
    this.isDataNotFound = false;
  }
  resetGraphComponent() {

    d3.selectAll('svg').remove();
    this.labelAndLegends = false;
    this.labelAndLegendsTAN = false;
    //console.log("from graph component to analyse component", this.popDatList);
  }

  displayGraph(graphUrlStr: any) {
    //console.log(graphUrlStr);
    d3.selectAll('svg').remove();

    this.maximizeThumbnails = graphUrlStr;
  }

  plotScatterFromThumbnail(str) {
    this.isDataNotFound = false;

    this.popDatList = [];
    this.sampleDataList = [];
    this.unitDataList = [];
    this.populationId = JSON.parse(localStorage.getItem('populationId'));

    //this.labelAndLegends = true;
    // var paramName = str;
    this.paramName = str;
    //this.currenButton = str;

    let reqParam = {
      'populationId': this.populationId.toString(),
      'equipmentId': this.currentItem.equipmentId,
      'sampleId': this.currentItem.sampleId,
      'parameter': str.toUpperCase(),
      'analysisType': this.currentItem.analysisType
    }
    //console.log("reqparam to plot graph", reqParam);
    this.graphServ.plotGraphOnParameterSelect(reqParam).subscribe(response => {
      if (response.statusCode == "1") {
        this.labelAndLegends = true;
        this.labelAndLegendsTAN = false;
        //console.log(response);
        this.popDatList = response.populationDataList;
        this.sampleDataList = response.sampleDataList;
        this.unitDataList = response.unitDataList;
        this.paramDescripton = response.parameterDetails.parameterDesc;
        this.parameterName = response.parameterDetails.parameterName;
        this.parameterUnit = response.parameterDetails.parameterUnit;


        //console.log('all list------->', this.popDatList, this.sampleDataList, this.unitDataList);
        if (this.parameterName == 'Fe' || this.parameterName == 'Cu' || this.parameterName == 'Pb' || this.parameterName == 'Cr'
          || this.parameterName == 'Al' || this.parameterName == 'K' || this.parameterName == 'Na' || this.parameterName == 'Ca'
          || this.parameterName == 'Sn' || this.parameterName == 'Zn' || this.parameterName == 'Mg' || this.parameterName == 'P'
          || this.parameterName == 'Pb' || this.parameterName == 'Mo' || this.parameterName == 'Si' || this.parameterName == 'Mn'
          || this.parameterName == 'Ag' || this.parameterName == 'Ba' || this.parameterName == 'Bi' || this.parameterName == 'In'
          || this.parameterName == 'Ni') {
          this.yAxisLabel = this.paramDescripton + ' (' + this.parameterName + ')';
        } else {
          this.yAxisLabel = this.paramDescripton;

        }

        if (this.popDatList.length > 0) {
          var xPopDataList = d3.max((this.popDatList), function (d) { return d[0]; });

        } else {
          xPopDataList = 0;
        }
        if (this.unitDataList.length > 0) {
          var xUnitDataList = d3.max((this.unitDataList), function (d) { return d[0]; });

        } else {
          xUnitDataList = 0;
        }
        if (this.sampleDataList.length > 0) {
          var xSampleDataList = d3.max((this.sampleDataList), function (d) { return d[0]; });

        } else {
          xSampleDataList = 0;
        }

        var xMax = Math.max(xPopDataList, xUnitDataList, xSampleDataList);


        if (this.popDatList.length > 0) {
          var yPopDataList = d3.max((this.popDatList), function (d) { return d[1]; });

        } else {
          yPopDataList = 0;
        }
        if (this.unitDataList.length > 0) {
          var yUnitDataList = d3.max((this.unitDataList), function (d) { return d[1]; });

        } else {
          yUnitDataList = 0;
        }
        if (this.sampleDataList.length > 0) {
          var ySampleDataList = d3.max((this.sampleDataList), function (d) { return d[1]; });

        } else {
          ySampleDataList = 0;
        }

        // var yPopDataList = d3.max((this.popDatList), function (d) { return d[1]; });
        // var yUnitDataList = d3.max((this.unitDataList), function (d) { return d[1]; });
        // var ySampleDataList = d3.max((this.sampleDataList), function (d) { return d[1]; });

        var yMax = Math.max(yPopDataList, yUnitDataList, ySampleDataList);

        d3.selectAll('svg').remove();

        //margin = { top: 20, right: 15, bottom: 60, left: 60 }
        var margin = { top: 20, right: 15, bottom: 60, left: 60 }, width = 550 - margin.left - margin.right
          , height = 530 - margin.top - margin.bottom;


        var x = d3.scaleLinear()
          .domain([0, xMax])
          .range([0, width]);



        var y = d3.scaleLinear()
          .domain([0, yMax])
          .range([height, 0]);


        var svg = d3.select('#scatter')
          .append('svg')
          .attr('width', width + margin.right + margin.left)
          .attr('height', height + margin.top + margin.bottom);
        // Y-axis label
        svg.append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0)
          .attr('x', 0 - (height / 2) - 10)
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .text(this.yAxisLabel).style('font-weight', 'bold').style('font-size', '12px');

        //X-axis label
        svg.append('text')
          .attr('transform',
            'translate(' + (width / 2) + ' ,' +
            (height + margin.top + 20) + ')')
          .attr('x', 60)
          .attr('y', 40)
          .style('text-anchor', 'middle')
          .text('Fluid Distance ' + this.uomLabel).style('font-weight', 'bold').style('font-size', '12px');


        var main = svg.append('g')
          .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
          .attr('width', width)
          .attr('height', height)
          .attr('class', 'main');

        // draw the X-axis
        var xAxis = d3.axisBottom(x).tickSize(-height);



        main.append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .attr('class', 'main axis date').style('stroke-dasharray', '5,5').style('color', 'lightgray')
          .call(xAxis).selectAll('text').style('color', 'black')
          .attr('transform', function (d) {
            return 'rotate(-60)'
          }).attr('x', -22).attr('y', -3);

        // draw the Y-axis
        var yAxis = d3.axisLeft(y).tickSize(-width);
        // var yAxis1 = d3.axisRight(y1);

        main.append('g')
          .attr('transform', 'translate(0,0)')
          .attr('class', 'main axis date').style('stroke-dasharray', '5,5').style('color', 'lightgray')
          .call(yAxis).selectAll('text').style('color', 'black');

        var g = main.append('g');

        //scatter circle placement in graph
        g.selectAll('scatter-dots')
          .data(this.popDatList)
          .enter().append('circle')
          .attr('cx', function (d, i) { return x(d[0]); })
          .attr('cy', function (d) { return y(d[1]); })
          .attr('r', 8)
          .style('fill', '#87CEFA');// radius and color of each spot

        g.selectAll('scatter-dots')
          .data(this.unitDataList)
          .enter().append('circle')
          .attr('cx', function (d, i) { return x(d[0]); })
          .attr('cy', function (d) { return y(d[1]); })
          .attr('r', 8)
          .style('fill', 'blue');// radius and color of each spot


        g.selectAll('scatter-dots')
          .data(this.sampleDataList)
          .enter().append('circle')
          .attr('cx', function (d, i) { return x(d[0]); })
          .attr('cy', function (d) { return y(d[1]); })
          .attr('r', 8)
          .style('fill', 'red');// radius and color of each spot

      } else {
        this.isDataNotFound = true;
        this.labelAndLegends = false;
        this.labelAndLegendsTAN = false;
        this.noDataFound = response.statusMessage;

      }


    }, err => {
      this.alertService.error(err);
    });
  }
}
