import { Component, OnInit } from '@angular/core';
import { LiveUpdateService } from './live-update.service';

@Component({
  selector: 'app-live-update',
  templateUrl: './live-update.component.html',
  styleUrls: ['./live-update.component.css']
})
export class LiveUpdateComponent implements OnInit {
  heads=["Date","Sample Id","Order Id","Company Name","Status"];
  items=[];
  /*items=[
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Received"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Analyzing"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Rerun"},
    {date:"12/3/2020",sampleId:"FW3452526",orderId:"O-0000349494",companyName:"CROSSETT",status:"Received"}
  ]*/


  constructor(private liveUpdateService: LiveUpdateService) { }

  ngOnInit() {
    this.liveUpdateService.getWebsiteLiveDataUpdates().subscribe(response => {
      if (response.statusCode == "1") {
        this.items = response.liveDataUpdatesList;
      }
    });
  }

  isHovered = true;
  onHovering(){
    this.isHovered = false;    
  }

  onUnovering(){
    this.isHovered = true;    
  }

}
