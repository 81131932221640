import { Observable } from 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../service/shared.service';
import { ApiHandlerService } from '../../handlers/api-handler.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CustomerEmailNotificationService {

  constructor(private api: ApiHandlerService) { 
  }
  getSubscriptionData(data: any): Observable<any> { 
    return this.api.post(environment.apiUrl +'/customerEmailSubscription',data);

  }

  // saveSubscriptionDetails(req: any): Observable<any> {
  //   return this.api.post(environment.apiUrl +'/saveSubscriptionDetails',req);

  // }
 
}
