import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { TsmaeReportsService } from '../reports/tsm-ae-report/tsmae-reports.service';
import { CustomerEmailNotificationService } from './customer-email-notification.service';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'app-customer-email-notification',
  templateUrl: './customer-email-notification.component.html',
  styleUrls: ['./customer-email-notification.component.css']
})
export class CustomerEmailNotificationComponent implements OnInit {
  @ViewChildren(SortComponent) sortComponents;

  readonly USERTYPE = USERTYPE;
  userRole: string = '';  
  isSearchDone: boolean = false;  
  reportData: any = [];
  companyArray= []; 
  companyList : any[];
  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  scrollToTop: any;
  searchObj: any;
  currentItem: any = [];
  confirmModal: boolean = false;
  successMessage: string = '';  
  filterObject = {
    subscriptionName: [],
  };
  subscriptionCols = [
    { code: 'subscriptionName', name: 'Subscription Name' },
    { code: 'action', name: 'Action' },
  ];
  customerEmailSearchForm = new UntypedFormGroup({
    subscriptionName: new UntypedFormControl(''),
  });
  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map(e => e.trim());
    const forbidden = emails.some(email => Validators.email(new UntypedFormControl(email)));
    const duplicate = emails.some(x => emails.indexOf(x) !== emails.lastIndexOf(x));
    if(emails.length > 150){
      return { 'emailListMaxLength': { value: control.value } };
    }
    if(duplicate){
      return { 'emailListDuplicate': { value: control.value } };
    }
    return forbidden ? { 'emailId': { value: control.value } } : null;
    
  };
  CompanyemailsForm= new UntypedFormGroup({
    emailId: new UntypedFormControl('',[Validators.required, this.commaSepEmail]),
  });
  inputForm = new UntypedFormGroup({
    companyId: new UntypedFormControl('', [Validators.required]),
    companyIdFilter: new UntypedFormControl('')
  });
  messageDropdownTsm: any;
  isSearchDisable: boolean = false;
  behaviorObs: any;
  subscriptionData: any;
  pagevalue: any;
  customerEmailFilterObject: any;
  deleteModal: boolean;
  data;
  subscriptionId: any;
  subscriptionName: any;
  displayResult:boolean;
  subdataFormArray = new UntypedFormArray([]);
  companyId: any;
  externalUserInformation: any;
  companyemails:any;
  someArray: any;
  removeArray: any;

  constructor(private commonHandler: CommonHandlerService,
    private alertService: AlertService,
    private tsmAEReportsService: TsmaeReportsService,
    private subService: CustomerEmailNotificationService,private sharedService: SharedService,
    private router: Router) { 
      this.pagevalue = JSON.parse(localStorage.getItem('customerEmailNotificationObject'));
      if (this.pagevalue != null) {
        this.pageSize = JSON.parse(localStorage.getItem('customerEmailNotificationObject'));
      } else {
        this.pageSize = '10'
      }
    }

  ngOnInit() {
    // this.displayResult = false;
    //Default emails

    this.userRole = this.sharedService.getUserRole();
    let sortdata = sessionStorage.getItem('customerEmailNotificationSortData')
    if (sortdata == 'undefined') {
      this.currentItem = undefined;
    } else {
      this.currentItem = JSON.parse(sortdata)
    }
    if (this.currentItem != "" && this.currentItem != null && this.currentItem != undefined) {
      this.columnName = this.currentItem.column;
      this.sortingOrder = this.currentItem.sort;
    }
    else {
      this.sortingOrder = 'asc';
      this.columnName = 'companyName';
    }
    if(this.userRole === USERTYPE.LAB_ADMIN || this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.FLUIDWATCH_MANAGER){
      this.getInitialData();
      this.clearInput();
    }else{
      this.getSubscriptionData();
    }
  }

  getInitialData(){
    this.tsmAEReportsService.getInitialData().subscribe(response=> {
      if (response.statusCode == "1") { 
        this.companyList = response.companyList;
        this.companyList.push({'maxRow': 0, 'companyId': '', 'companyName':'Unknown'});
        this.companyList.sort(function(a, b) {
          const nameA = a.companyName.toUpperCase();
          const nameB = b.companyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.companySearch('');        
      }else{
        this.alertService.error(response.statusMessage);
      }
    },err=>{
      this.alertService.error(err);
    });   
    this.companyArray = this.companyList;
   console.log(this.companyArray)
  }

  ngOnDestroy () {
    localStorage.setItem('customerEmailNotificationObject', JSON.stringify(this.pageSize));
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }
    
  search(){    
    localStorage.removeItem('companyIdforsubscription');
    this.currentPage = 1;
    this.companyemails =JSON.parse(localStorage.getItem('companyEmails'));
    if(this.userRole === USERTYPE.EXTERNAL){
      this.externalUserInformation = JSON.parse(localStorage.getItem('externalUserInformation'));
      this.companyId=this.externalUserInformation.IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c;
      console.log(this.companyId + "IAM_Service_Provider_Code__c" )
    }else {
      this.companyId = this.inputForm.controls.companyId.value;
    }
    if(this.companyemails !=null ){
      if(this.companyemails[0].companyId == this.companyId){
        if(this.companyemails[0].subscriptionName = 'DEFAULT'){
          this.CompanyemailsForm.patchValue({
              emailId: this.companyemails[0].emailId
          })
        }
      }
      else{
        this.CompanyemailsForm.patchValue({
          emailId: ''
      })
      }
    }
    this.getSubscriptionData();

  }
  getSubscriptionData(){
    if(this.userRole === USERTYPE.EXTERNAL){
      this.externalUserInformation = JSON.parse(localStorage.getItem('externalUserInformation'));
      this.companyId=this.externalUserInformation.IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c;
      console.log(this.companyId)
    }else {
      this.companyId = this.inputForm.controls.companyId.value;
    } 
    this.data = {
      'userId': this.sharedService.getUserId(),
      "companyId" : this.companyId,
      "functionName" : "SEARCH",
      "subscriptionId":1,
      "subscriptionName": null,
      "unitId": null,
      "severity":null,
      // "companyEmailIds":null,
      "emailId":null
      // "currentPage": this.currentPage,
      // "pageSize": this.pageSize.toString(),
      // "sortingOrder" : this.sortingOrder
    }
    this.subService.getSubscriptionData(this.data).subscribe(response => {
      if (response.statusCode == "1") {
        this.subscriptionData = response.companyList;
        console.log(response.companyList)
        if(this.subscriptionData !=undefined && this.subscriptionData !=null &&this.subscriptionData !="" ){
            this.subscriptionData.forEach((value, index) => {
              if(value.subscriptionName == 'DEFAULT'){
                this.CompanyemailsForm.patchValue({
                  emailId: value.emailId
              })
                this.subscriptionData.splice(index, 1);
              
              }
          }); 
          this.displayResult = false; 
        } else{
          this.displayResult = true; 

        }
        this.isSearchDone = true;
        // this.totalItems = response.maxRow;
        // this.calculateOffset();  
      } else {
        this.subscriptionData = [];
        // this.displayResult = true;
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
    console.log(this.displayResult)
  }
  saveEmails(){
    if(this.userRole === USERTYPE.EXTERNAL){
      this.externalUserInformation = JSON.parse(localStorage.getItem('externalUserInformation'));
      this.companyId=this.externalUserInformation.IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c;
    }else {
      this.companyId = this.inputForm.controls.companyId.value;
    }
    this.data = {
      'userId': this.sharedService.getUserId(),
      "companyId" : this.companyId,
      "functionName" : "SAVE",
      "subscriptionId":1,
      "subscriptionName": null,
      "unitId": null,
      "severity":null,
      // "companyEmailIds":null,
      "emailId":this.CompanyemailsForm.controls["emailId"].value
    }
    this.subService.getSubscriptionData(this.data).subscribe(response => {
      if (response.statusCode == "1") {
        localStorage.setItem('companyEmails', JSON.stringify(response.companyList));
        this.getSubscriptionData();     
        // this.isSearchDone = true;
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );

  }
  closeDeleteModal(){    
    this.deleteModal = false;
  }
  openDeleteModal(item){ 
    this.deleteModal = true;
  }
  editSubscriptiondata(item) {
    localStorage.setItem('companyIdforsubscription', JSON.stringify(this.companyId));
    localStorage.setItem('Editdetailsforsubscription', JSON.stringify(item));
    // item.patchValue({
    //   isEdit:true
    // });
  }
  // cancelSubEdit(item, i) {
  //   item.patchValue({
  //     isEdit:false,
  //   });
  // }
  opensubDeleteModal(item) {    
    this.deleteModal = true;
    this.subscriptionId = item.subscriptionId;
    this.subscriptionName = item.subscriptionName;
  }
  deleteRecord(item){
    console.log(item)
    this.data = {
      'userId': this.sharedService.getUserId(),
      "companyId" : this.companyId,
      "functionName" : "DELETE",
      "subscriptionId":this.subscriptionId,
      "subscriptionName": this.subscriptionName,
      "unitId": null,
      "severity":null,
      // "companyEmailIds":null,
      "emailId":null
      // "currentPage": this.currentPage,
      // "pageSize": this.pageSize.toString(),
      // "sortingOrder" : this.sortingOrder
    }
    this.subService.getSubscriptionData(this.data).subscribe(response => {
      if (response.statusCode == "1") {         
         this.successMessage = response.statusMessage;
        //  this.successModal = true; 
         this.getSubscriptionData();     
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.deleteModal = false; 
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
        this.deleteModal = false;
      })
  }

  addSubscription(){
    localStorage.setItem('companyIdforsubscription', JSON.stringify(this.companyId));
    localStorage.removeItem('Editdetailsforsubscription');
    this.router.navigate(['/addSubscription']);
  }
  optionSearch(){
    this.currentPage = 1;
  }

  clearSearch(str) {
    if (this.customerEmailSearchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.customerEmailSearchForm.patchValue({ [str]: '' });
      this.getSubscriptionData();
    }
   
  }  

 
  clearInput(){
    this.inputForm.reset();    
    this.isSearchDone = false;
    this.displayResult =false
  }

  companySearch(value:string){
    this.companyArray= []; 
    let filter = value.toLowerCase();
    
    this.companyArray = this.companyList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if(companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0){
        return true;
      }else{
        return false;
      }
    });
    console.log(this.companyArray)

    if(this.companyArray.length == 0){
      this.companyArray.push({'companyName':'Select', 'companyId': ''});
    }
  }

  clearCompanySearch(){
    this.companyArray= []; 
    this.companyArray= this.companyList;
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.subscriptionData = [];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('customerEmailNotificationObject', JSON.stringify(this.pageSize));
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.reportData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }
  
  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.getSubscriptionData();

  }

  /**
* Calls search method to sort the data based on columnName
* @param columnName 
*/
  sortData(columnName) {
    let existingsortdata = sessionStorage.getItem('customerEmailNotificationSortData');
    if (existingsortdata == 'undefined' || existingsortdata == null || existingsortdata == "") {
      this.currentItem = undefined;
    } else {
      this.currentItem = JSON.parse(existingsortdata)
    }
    if (this.currentItem != "" && this.currentItem != null && this.currentItem != undefined) {
      if (this.currentItem.sort == 'asc') {
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      } else {
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);

      }
    }
    let sortcolumn = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    let reviewcolumnsort = { column: columnName, sort: sortcolumn, direction: sortcolumn, active: columnName };
    sessionStorage.setItem('customerEmailNotificationSortData', JSON.stringify(reviewcolumnsort));
    if (this.columnName !== columnName) {
      this.sortingOrder = '';
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder !== 'asc' ? 'asc' : 'desc';
    this.getSubscriptionData();
  }

    /* -------------------------filter value dropdown------------------------- */
    searchFilter(str) {
      let search = (this.customerEmailSearchForm.value[str]).toLowerCase();
      if (search == '') {
        this.customerEmailFilterObject[str] = [];
        this.getSubscriptionData();
      }
      sessionStorage.setItem('accountGroupManagementObject', JSON.stringify(this.customerEmailSearchForm.value));
      let _data = {
        searchColumn: str,
        searchValue: this.customerEmailSearchForm.value[str],
        functionName: "SEARCH",
        subscriptionName: this.customerEmailSearchForm.value['subscriptionName'],
      }
      if (this.customerEmailSearchForm.value[str].length >= 2) {
        this.subService.getSubscriptionData(_data).subscribe(
          response => {
            console.log(response);
  
            if (response.statusCode == "1") {
              this.customerEmailFilterObject[str] = response.distictValuesList;
            } else {
              this.alertService.error(response.statusMessage);
              this.customerEmailFilterObject[str] = [];
              this.alertService.clear();
              window.sessionStorage.removeItem('customerEmailNotificationtObject');
            }
            console.log(this.customerEmailFilterObject);
            
          }, err => {
            this.alertService.error(err);
          }
        );
      }
      else if (this.customerEmailSearchForm.value[str].length == 0) {
        this.clearSearch(str)
        this.customerEmailFilterObject[str] == [];
        this.customerEmailFilterObject[str].length = 0;
      }
    }
  
  getCompanyName():any{
    let companyName = '';
    this.companyList.forEach(row=>{
      if(row.companyId == this.inputForm.controls.companyId.value){
        companyName =  row.companyName;
      }
    });
    return companyName;
  }

}
