import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.css']
})
export class LineGraphComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
  }

}
