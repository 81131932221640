import { AfterViewChecked, Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';

import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management.service';

@Component({
  selector: 'app-astm',
  templateUrl: './astm.component.html',
  styleUrls: ['./astm.component.css']
})
export class AstmComponent implements OnInit, AfterViewChecked  {
  [x: string]: any;
  currentPage: 1;
  isNewData:boolean=false;
  displayResult = true;
  addModal = false; 
  astmList = [];
  control: UntypedFormArray;
  scrollToTop =  false;
  touchedRows: any;
  successMessage: string = '';
  astmResponseData: any;
  constructor( private commonHandler: CommonHandlerService,
    private sharedService: SharedService,
    private masterDataManagementService:MasterDataManagementService,
    private alertService: AlertService
    ) { }

    ngOnInit(): void {
      this.getAstmData();
    }

  addAstmForm=new UntypedFormGroup({
    flashPointMin: new UntypedFormControl('', [Validators.required]),
    distillationTempMin: new UntypedFormControl('', [Validators.required]),
    distillationTempMax: new UntypedFormControl('', [Validators.required]), 
    kinematicViscMin: new UntypedFormControl('', [Validators.required]),    
    kinematicViscMax: new UntypedFormControl('', [Validators.required]), 
    sulfurMax: new UntypedFormControl('', [Validators.required]),  
    cetaneNumberMin: new UntypedFormControl('', [Validators.required])
  });

 

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getAstmData(){
    let req = {
      astmId: ''
     };
     this.masterDataManagementService.getAstmData(req).subscribe(response => {
      if (response.statusCode == "1") {            
      this.astmResponseData = response.astmId;
      this.addAstmForm.patchValue({
        flashPointMin: this.astmResponseData.flashPointMin,
        distillationTempMin: this.astmResponseData.distillationTempMin,
        distillationTempMax: this.astmResponseData.distillationTempMax,
        kinematicViscMin: this.astmResponseData.kinematicViscMin,
        kinematicViscMax: this.astmResponseData.kinematicViscMax,
        sulfurMax: this.astmResponseData.sulfurMax,
        cetaneNumberMin: this.astmResponseData.cetaneNumberMin,
        
      });

        
      
      } else {
        this.alertService.error(response.statusMessage);
      }
    }, err => {
      this.alertService.error(err);
    }
  );
}

  openAddModal(){
    if(!this.addAstmForm.invalid){
      this.addModal = true;
    }else{
      this.addModal = false;      
      Object.keys(this.addAstmForm.controls).forEach(field => { 
        const control = this.addAstmForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }    
  }

  

  
onSubmit() {
  
    let data = {
      userId: this.sharedService.getUserId(),
      flashPointMin : this.addAstmForm.controls.flashPointMin.value,
      distillationTempMin : this.addAstmForm.controls.distillationTempMin.value,
      distillationTempMax : this.addAstmForm.controls.distillationTempMax.value,
      kinematicViscMin : this.addAstmForm.controls.kinematicViscMin.value,
      kinematicViscMax : this.addAstmForm.controls.kinematicViscMax.value,
      sulfurMax : this.addAstmForm.controls.sulfurMax.value,
      cetaneNumberMin : this.addAstmForm.controls.cetaneNumberMin.value
     }
  this.masterDataManagementService.insertAstmData(data).subscribe(response =>{
    if (response.statusCode == "1") {
      this.successMessage=response.statusMessage;
      this.alertService.success(response.statusMessage);
      this.scrollToTop = true;
    }else {
      this.alertService.error(response.statusMessage);
      this.scrollToTop = true;
    }    
  },err => {
    this.alertService.error(err);        
    this.scrollToTop = true;
  });
}

}
  

