import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
// import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { Input } from "@angular/core";
import { PopupHandlerService } from '../../handlers/popuphandler.service';
export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent  implements ConfirmModel {
  // export class PopupComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {

  @Input() title: string;
  @Input() cancelModal: boolean;
  @Input() ifCancel: boolean;
  @Input() message: string;
  @Output() PassParam: EventEmitter<any> = new EventEmitter();
  

  constructor( private popupHandlerService: PopupHandlerService) {
    // super(dialogService);
  }
  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code 
    // this.result = true;
    if (this.cancelModal) {
      this.PassParam.emit(false);
    }
    else {
      this.PassParam.emit(true);
    }
  }

  close() {
    this.PassParam.emit(false);
    this.popupHandlerService.openModal(false);
  }


}
