import { Injectable } from '@angular/core';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
    [x: string]: any;

  constructor(private api: ApiHandlerService) { }

  insertHolidayList(data: any): Observable<any> {
    
    return this.api.post(environment.apiUrl + '/insertHolidayCalendar', data);
  }

  getHolidayCalendarDetails(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getHolidayCalendarData', data);
  }
}
