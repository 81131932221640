import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PopupHandlerService implements OnDestroy {
  private _listners = new Subject<any>();
  listen(): Observable<any> {
    return this._listners.asObservable();
  }
  openModal(param: boolean) {
    this._listners.next(param);
  }
  getMessage(param: any) {
    this._listners.next(param);
  }
  unsubscribe() {
    this._listners.next();
    this._listners.complete();
  }
  constructor() {

  }

  ngOnDestroy() {
    this._listners.next();
    this._listners.complete();
  }
}
