// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isAuthRequired: true,  
  Req_Server: window.location.origin,
  allowedOrigins: [window.location.origin, 'https://login.microsoftonline.com',
    'https://jlr0sy5t8g.execute-api.us-east-1.amazonaws.com/dev',
    'https://97ractlewa.execute-api.us-east-1.amazonaws.com/stg',
    'https://jlr0sy5t8g.execute-api.us-east-1.amazonaws.com/dev',
    'https://localhost:4200'],
  /* ----------- For Dev Environment ---------------- */
  apiUrl: 'https://jlr0sy5t8g.execute-api.us-east-1.amazonaws.com/dev',
  /* ------------- For Stage Environment -------------- */
  // apiUrl: 'https://97ractlewa.execute-api.us-east-1.amazonaws.com/stg',
  clientId: "3avf5agkk6svjo1okmfh4rcn1h",
  internalAppUrl: "https://cummins-dev-pool.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=token&client_id=3avf5agkk6svjo1okmfh4rcn1h&redirect_uri=https://coat-dev-new.cummins.com&state=STATE&scope=openid&idp_identifier=AzureAD",	
  externalAppUrl: "https://cummins-dev-pool.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=token&client_id=3avf5agkk6svjo1okmfh4rcn1h&redirect_uri=https://fluidwatch-dev-new.cummins.com&state=STATE&scope=openid&idp_identifier=Salesforce",
  App_URL: "",
  winlimsTokenUrl: 'https://api-ue-devnf-aw-ex.cummins.com:443/api/core-security-api/v1/auth/token', 
  winlimsUpdateUrl: 'https://api-ue-devnf2-aw-ex.cummins.com:443/samples/v1/status',
  winlimsAuthClientId: '09b85ca6-bd3c-430c-9289-8cf872e28f3e',
  winlimsAuthClientSecret:'0Pi8Q~tw8trvmANzR1HJ-T.6yyDTqEi2WZL1aajK',
  winlimsAuthResource: 'https://api-ue-devnf-aw-ex.cummins.com/samples',
  winlimsUpdateClientId:'d480576aedc040548503774397dde3fe',
  winlimsUpdateClientSecret:'ad3B763F831645bcBD15A03faadC9662',
  createSNOWIncident:'https://cumminsdev.service-now.com/api/infcu/cumminsincident/createIncident',
  //coatIAMInteg:'https://api-ue-devnf3-aw-in.cummins.com/cmi-iam/v1/salesforce/users/<username>',
  IAMAuthUrl:'https://api-ue-devnf2-aw-ex.cummins.com/api/core-security-api/v1/auth/token',
  IAMAuthClientId:'09b85ca6-bd3c-430c-9289-8cf872e28f3e ',
  IAMAuthClientSecret:'0Pi8Q~tw8trvmANzR1HJ-T.6yyDTqEi2WZL1aajK',
  IAMAuthResource:'https://api-ue-devnf3-aw-in.cummins.com/cmi-iam',
  coatIAMUrl:'https://api-ue-devnf2-aw-ex.cummins.com/cmi-iam/v1/salesforce/users/<username>',
  coatIAMClientId:'d480576aedc040548503774397dde3fe',
  coatIAMClientSecret:'ad3B763F831645bcBD15A03faadC9662',
  //logout_url: "https://globallogin-dev.cummins.com/OAMlogout/logout.jsp",	
  internalLogoutUrl:'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0',	
  externalLogoutUrl: 'https://csodev-cumminscss.cs90.force.com/cw/IAM_logout',	
  internalUrl: 'https://coat-dev-new.cummins.com',	
  externalUrl: 'https://fluidwatch-dev-new.cummins.com',	
  externalUserCreateAccount: 'https://csodev-cumminscss.cs90.force.com/cw/IAM_NewRegistration?appid=a1a1F0000017LuX', 
  myInvoiceMarketPlace:'https://test-cumminscss.cs124.force.com/CMIStore/ccrz__MyAccount?viewState=myInvoices&cclcl=en_US',
  myAccountMarketPlace: 'https://test-cumminscss.cs124.force.com/CMIStore/ccrz__MyAccount?viewState=viewAccount&cclcl=en_US',
  myOrderMarketPlace:'https://test-cumminscss.cs124.force.com/CMIStore/ccrz__MyAccount?viewState=myOrders&cclcl=en_US',
  myAddressBookMarketPlace:'https://test-cumminscss.cs124.force.com/CMIStore/ccrz__MyAccount?viewState=myAddressBook&cclcl=en_US',
  purchaseSample:'https://cumminscss--qafullcopy.sandbox.lightning.force.com/',
  origin: 'coat-dev-new.cummins.com',
  publishReportEmailUrl: 'https://fluidwatch-dev-new.cummins.com/emailSampleReport/',
  holdReportEmailUrl: 'https://fluidwatch-dev-new.cummins.com/viewHoldSample/'

};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
