import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
export function getFilterData(masterList: any, fieldName: string, filterValue: string, resultFieldName: string): string[] {
    let returnList: string[] = [];
    for (const obj of masterList) {
        if (obj[fieldName] !== null) {
            if (filterValue === '') {
                if (!isValueAdded(returnList, obj[fieldName])) {
                    returnList.push(obj[resultFieldName]);
                }
            } else {
                if (obj[fieldName] === filterValue && !isValueAdded(returnList, obj[fieldName])) {
                    returnList.push(obj[resultFieldName]);
                }
            }
        }
    }
    returnList = Array.from(new Set(returnList)) ;
    return returnList;
};
export function isValueAdded(list: String[], value: string): boolean {
    let returnVal: boolean = false;
    for (const obj of list) {
        if (obj === value) {
            returnVal = true;
            break;
        }
    }
    return returnVal;
}


// export function requiredFieldValidator(): ValidatorFn {
//     if (formControl.value === '' && dummyControl.value === '') {
//         formControl.setErrors({ 'requiredFiled': true });
//     } else {
//         return null;
//     }
// };
