import { Component, OnInit, Output, ViewChildren, EventEmitter } from '@angular/core';
import { common, warnMessage } from '../../common/constants/constant';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management/master-data-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-aacount-without-access',
  templateUrl: './customer-aacount-without-access.component.html',
  styleUrls: ['./customer-aacount-without-access.component.css']
})
export class CustomerAacountWithoutAccessComponent implements OnInit {
  @ViewChildren(SortComponent) sortComponents;
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSizeMetadata = '10';
  currentPageMetadata = 1; 
  changePageMetadata: any = 1;
  openAccessModal: boolean = false;
  totalItemsMetadata: any;
  showRecordsMetadata = 0;
  maxPageMetadata = 0;
  scrollToTop =  false;
  sortingOrder: string = 'asc';
  customerTable: any = [];
  successMessage: string = '';
  successModal = false;
  columnName: string;
  searchObj:any;
  customerIdtoDelete: any;
  // displayResult = false;

  selectedCustomer: any = [];
  selectedCustomerName: any = [];
  
  actionForm: UntypedFormGroup;

  searchForm: UntypedFormGroup;
  backbutton: any = '';

  customerDataColumn = [
    { code: 'customerId', name: 'Customer ID' },        
    { code: 'customerName', name: 'Customer Name' },
    { code: 'customerState', name: 'Customer State' },
    { code: 'customerCountry', name: 'Customer Country' },
    { code: 'customerZipCode', name: 'Customer Zip Code' },
    { code: 'action', name: 'Actions' },
  ];

  filterObject = {
    customerId: [],
    customerName: [],
    customerState: [],
    customerCountry: [],
    customerZipCode: []
  };

  @Output() event = new EventEmitter<string>()

  constructor(private masterDataManagementService:MasterDataManagementService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private router: Router) {
    
  }

  ngOnInit(): void {
  this.backbutton = '';
    
    // this.displayResult = false;
    this.columnName = '';
    this.searchForm = new UntypedFormGroup({
      customerId: new UntypedFormControl(''),
      customerName: new UntypedFormControl(''),
      customerState: new UntypedFormControl(''),
      customerCountry: new UntypedFormControl(''),
      customerZipCode: new UntypedFormControl(''),
      action:new UntypedFormControl('')
    });
    
    this.actionForm = new UntypedFormGroup({
      justification: new UntypedFormControl('', [Validators.required])
    });
    this.searchMetadata();
  }

  searchMetadata() {
    this.currentPageMetadata = 1;
    this.fetchMetadata();
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;  
    this.fetchMetadata();    
  }

  fetchMetadata() {
    let data = this.searchForm.getRawValue();
    data['sortingOrder'] = this.sortingOrder;
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['functionName'] = 'SEARCH';
    data['updatedBy'] = this.sharedService.getUserId();
    data['columnName'] = this.columnName;
    this.searchObj = data;

    this.masterDataManagementService.getCustomerListData(data).subscribe(response =>{  
      console.log(response);      
      if (response.statusCode == "1") {
        this.customerTable = response.custAcctWithOutAccessList;
        // this.displayResult = true;
        this.selectedCustomer = [];
        this.selectedCustomerName = [];
        if(response.custAcctWithOutAccessList.length == 0) {
          // this.alertService.error("There is no customer without access");
          this.totalItemsMetadata = 0;
        } else { 
          this.totalItemsMetadata = response.custAcctWithOutAccessList[0].maxRow;
        }
        this.calculateOffsetMetadata();
      }else {
        // this.displayResult = true;
        this.customerTable = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
  */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.fetchMetadata();
  }

  isSearchable(column) {
    if(column == 'customerId' || column == 'customerName' || column == 'customerState' || column == 'customerCountry' || column == 'customerZipCode'){
      return true;
    }else{
      return false;
    }
  }

  searchFilter(str) {
    if(!this.searchForm.value[str]) {
      this.searchMetadata();
    }
    let inputData = this.searchForm.getRawValue();
    inputData['searchColumn'] = str;
    inputData['searchValue'] = this.searchForm.value[str];   
    inputData['functionName'] = "SEARCH";
    inputData['updatedBy'] = this.sharedService.getUserId();

    this.searchObj = {
      pageSize: this.pageSizeMetadata.toString(),
      currentPage: this.currentPageMetadata.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      updatedBy: this.sharedService.getUserId()
    };

    Object.assign(inputData, this.searchObj);
    if(inputData['searchValue'].length >= 2) {
      this.masterDataManagementService.getCustomerListData(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.filterObject[str] = response.custAcctWithOutAccessList;
        } else {
          this.filterObject[str] = [];
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    }
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.searchMetadata();
    }
  }
  
  // Pagination for Metadata

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {        
        
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  actionCheckOnChange(ev, data) {
    if(ev.target.checked) {
      if (this.selectedCustomer.findIndex(i => i == data.customerId) === -1) {
        // console.log(data.customerId);
        this.selectedCustomer.push(data.customerId);
        this.selectedCustomerName.push(data.customerName);
      }
    } else {
      const index = this.selectedCustomer.findIndex(element => element == data.customerId);
      this.selectedCustomer.splice(index, 1);
      this.selectedCustomerName.splice(index, 1);
    }
    // console.log(this.selectedCustomer);
  }

  openPopup() {
    this.openAccessModal = true;
  }

  closeModal() {
    this.openAccessModal = false;
  }

  submitModal() {
    let customerAccessList = [];
    for(let i=0; i<this.selectedCustomer.length; i++) {
      customerAccessList.push({
        updatedBy: this.sharedService.getUserId(),
        customerId: this.selectedCustomer[i],
        businessJustification: this.actionForm.controls['justification'].value
      });
    }
    this.submitAccess(customerAccessList)
  }

  submitAccess(customerAccessList: any) {
    let data = {};
    data['functionName'] = 'ADD';
    data['customerAccountDTOList'] = customerAccessList;

    this.masterDataManagementService.getCustomerListData(data).subscribe(response => {  
      // console.log(response);
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;
        this.selectedCustomer = [];
        this.selectedCustomerName = [];
        this.searchMetadata();
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.actionForm.reset();
      this.closeModal();
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  successOkay() {
    this.successModal=false
    this.event.emit('ok');
  }
}
