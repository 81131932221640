import { Observable } from 'rxjs/Rx';
import { BaseServiceService } from './BaseService.service';
import { Injectable } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';
import { SharedService } from './shared.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterSampleService extends BaseServiceService {

  constructor(public httpClient: HttpClient, private sharedService: SharedService) { 
    super(httpClient);
  }

  private tempItem = new BehaviorSubject({});
  currentTempItem = this.tempItem.asObservable();


  private urls = {
    getSampleDashboardData: '/getSampleDashboardData',
    fetchSampleData: '/fetchSampleInfo',
    getUnitInfo: '/getUnitIdDropdownData',
    saveRegisterSample: '/addSample',
    updateRegisterSample: '/updateSample',
    holdRequestData: '/holdSampleHandler',
    holdMasterData:'/holdSampleDashboard',
    getDropDownData: '/coatFluidAndFilterDropdown',
    addMasterData: '/addToMasterData',
    updateReviewFlag: '/updateReviewFlag',
    getDropdownOptions: '/getDropdownOptions',
    qrCodeValidation:'/qrCodeValidation'
  };

  qrCodeValidation(req:any):Observable<any>{
    return this.httpPost(this.urls.qrCodeValidation, req);
  }
  getSampleDashboardData(req: any): Observable<any> {
    return this.httpPost(this.urls.getSampleDashboardData, req);
    //  return of(this.registerSampleColumnsData);
  }
  fetchData(req: any): Observable<any> {
    return this.httpPost(this.urls.fetchSampleData, req);
    //  return of(this.registerSampleColumnsData);
  }

  addMasterData(req: any): Observable<any> {
    return this.httpPost(this.urls.addMasterData, req);
    //  return of(this.registerSampleColumnsData);
  }
  holdRequestData(req: any): Observable<any> {
  return this.httpPost(this.urls.holdRequestData, req);
  }
  holdMasterData(req: any): Observable<any> {
    return this.httpPost(this.urls.holdMasterData, req);
  }
  getDropdownOptions(req: any): Observable<any> {
    return this.httpPost(this.urls.getDropdownOptions, req);
  }

  getUnitList(companyId: any): Observable<any> {
    return this.httpPost(this.urls.getUnitInfo, { companyId: companyId });
  }

  getUnitListForInternal(companyId: any): Observable<any> {
    return this.httpPost(this.urls.getUnitInfo, companyId);
  }

  saveRegisterSample(req: any): Observable<any> {
    return this.httpPost(this.urls.saveRegisterSample, req);

  }
  updateRegisterSample(req: any): Observable<any> {
    return this.httpPost(this.urls.updateRegisterSample, req);
  }
  updateReviewFlag(req: any): Observable<any> {
    return this.httpPost(this.urls.updateReviewFlag, req);
  }
  setItem(obj: any) {
    this.tempItem.next(obj);
  }

  getDropDownData(analysistype: string,fluidType:string ): Observable<any> {
    // const currentItem: RegisterSampleModel = JSON.parse(localStorage.getItem('registerSampleObject'));
    const obj = {
     // 'userRole': this.sharedService.getUserRole()
     'userRole': this.sharedService.getIntOrExtUser(),
     'fluidType': fluidType
    };
    /*obj.userRole = this.sharedService.getIntOrExtUser();
    if (analysistype.toLowerCase().indexOf('oil') !== -1) {
      obj['oil'] = true;
    } else if (analysistype.toLowerCase().indexOf('coolant') !== -1) {
      obj['coolant'] = true;
    } else if (analysistype.toLowerCase().indexOf('fuel') !== -1) {
      obj['fuel'] = true;
    } else {
      obj['def'] = true;
    }*/
    return this.httpPost(this.urls.getDropDownData, obj);
  }
}
