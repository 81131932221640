import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';

@Injectable({
  providedIn: 'root'
})
export class ScatterPlotSharedService {
  unitIDs: any[] = [];
  parameter: any = null;
  distanceType: any = null;
  showScatterPlot: any = false;
  selectedFluidType: string = null;
  fluidTypeName: string = null;
  parameterListData:any = [];

  constructor() { }

  setUnitIDs(value: any) {
    this.unitIDs = value;
  }

  resetUnitIds() {
    this.unitIDs = [];
  }

  getUnitIds() {
    return this.unitIDs;
  }

  setParameter(parameter) {
    this.parameter = parameter;
  }
  setDistanceType(distanceType) {
    this.distanceType = distanceType;
  }
  getParameter() {
    return this.parameter;
  }
  getDistanceType() {
    return this.distanceType;
  }
  setShowScatterPlot(value) {
    this.showScatterPlot = value;
  }
  getShowScatterPlot() {
    return this.showScatterPlot;
  }

  setSelectedFluidType(fluidType) {
    this.selectedFluidType = fluidType;
  }

  getSelectedFluidType() {
    return this.selectedFluidType;
  }

  setParameterListData(parameterListData){
    this.parameterListData = parameterListData;
  }

  getParameterListData(){
    return this.parameterListData;
  }

  setFluidTypeName(fluidTypeName){
    this.fluidTypeName = fluidTypeName;
  }

  getFluidTypeName(){
    return this.fluidTypeName;
  }

  async export(imageData, fileName) {
    let document = {
      header: {
        margin: [40, 40, 0, 0],
        columns: [
          {
            image: await this.getBase64ImageFromURL('assets/img/graph_image.png'),
            width: 510,
            height: 40
          }
        ]
      },
      content: [
        {
          style: 'graphContent', margin: [0, 40, 0, 0],
          border: [true, false, false, false],

          table: {
            widths: [500],
            body: [

              // { fillColor: '#696969', color: '#FFFFFF', bold: true, alignment: 'center', text: graphType }],
              [
                {
                  image: imageData,
                  width: 500,
                  margin: [10, 0, 0, 15]
                }
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
            },
            hLineColor: function (i, node) {
              return 'lightGray';
            },
            vLineColor: function (i, node) {
              return 'lightGray';
            }

          }
        }
      ],

      styles: {
        graphContent: {
          fontSize: 8,
          bold: true
        }
      }

    };
    pdfMake.createPdf(document).download(fileName);
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
}
