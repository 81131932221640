import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MasterDataManagementService } from '../master-data-management.service';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { SharedService } from 'src/app/View/service/shared.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-qr-details',
  templateUrl: './qr-details.component.html',
  styleUrls: ['./qr-details.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    // {
    //   provide: DateAdapter,
      // useClass: MomentDateAdapter,
      // deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class QrDetailsComponent implements OnInit {
  @ViewChildren(SortComponent) sortComponents;  
  isNewData:boolean=false;
  scrollToTop =  false;
  displayResult = false;
  addModal = false;   
  searchObj:any; 
  fluidTypeList = [];
  testLevelList = [];
  testLevelList1 = [];
  dataListWithSKU = [];
  infoList: any = [];
  startRange: any = '';
  endRange: any = '';
  dieselTypeLevelForm=new UntypedFormGroup({
    fluidType: new UntypedFormControl('', [Validators.required]),
    testLevel: new UntypedFormControl('', [Validators.required])
  });

  addQRDetailsForm=new UntypedFormGroup({
    fluidType: new UntypedFormControl('', [Validators.required]),
    testLevel: new UntypedFormControl('', [Validators.required]),
    vendorCode: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z ]+$")]),
    year: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9 ]+$")]),
    sku: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9 ]+$")]),
    startRange: new UntypedFormControl('', [Validators.required, Validators.max(9999999), Validators.pattern("^[0-9 ]+$")]),
    endRange: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9 ]+$")]),
    // cogs: new FormControl('', [Validators.required, Validators.max(999.99)])
  });
  // "^[0-9]{7,7}$"
  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string = 'asc';
  columnName: string;
  searchOption: any;

  qrColumn = [
    { code: 'fluidType', name: 'Fluid Type' },
    { code: 'testLevel', name: 'Test Package' },
    { code: 'vendorCode', name: 'Vendor Code' },
    { code: 'year', name: 'Year' },
    { code: 'sku', name: 'SKU' },
    { code: 'startRange', name: 'Start Range' },
    { code: 'endRange', name: 'End Range' },
    { code: 'startToEndRange', name: 'Start Range to End Range' }
  ];

  // infoList = [
  //   {fluidType: 'Engine Oil (Liquid Fuel)', testLevel: 'BASIC', vendorCode: 'MS', year: '2022', sku: 10, startRange: '0000001', endRange: '0009999'},
  //   {fluidType: 'Engine Oil (Liquid Fuel)', testLevel: 'BASIC', vendorCode: 'MS', year: '2022', sku: 10, startRange: '0000001', endRange: '0009999'},
  //   {fluidType: 'Engine Oil (Liquid Fuel)', testLevel: 'BASIC', vendorCode: 'MS', year: '2022', sku: 10, startRange: '0000001', endRange: '0009999'},
  //   {fluidType: 'Engine Oil (Liquid Fuel)', testLevel: 'BASIC', vendorCode: 'MS', year: '2022', sku: 10, startRange: '0000001', endRange: '0009999'},
  //   {fluidType: 'Engine Oil (Liquid Fuel)', testLevel: 'BASIC', vendorCode: 'MS', year: '2022', sku: 10, startRange: '0000001', endRange: '0009999'}
  // ];

  // date = new FormControl(moment());
  
  
  constructor(private masterDataManagementService:MasterDataManagementService, private alertService: AlertService, private commonHandler: CommonHandlerService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.columnName = '';
    this.displayResult = false;
    this.getDropdowns();
  }

  getDropdowns(){
    // this.masterDataManagementService.getCogsDropdown().subscribe(response => {
    //   this.fluidTypeList = response.fluidTypeList;
    //   // this.testLevelList = response.testLevelList;
    //   this.testLevelList1 = response.testLevelList;
    // },err => {
    //     this.alertService.error(err);        
    // });

    let data = {
      "functionName" : "DROPDOWN"
    }
    
    this.masterDataManagementService.qrCodeRangeOperations(data).subscribe(response => {
      this.fluidTypeList = response.fluidTypeList;
      this.testLevelList1 = response.testLevelList;
      this.dataListWithSKU = response.qrCodeRangeDTOList;
    },err => {
        this.alertService.error(err);        
    });
  }

  inputChange(){
    this.testLevelList = [];
    this.dieselTypeLevelForm.patchValue({
      testLevel: ''
    }); 
    this.displayResult = false;
    this.isNewData = false;
    console.log(this.dieselTypeLevelForm.controls['fluidType'].value);
    for(let i=0; i<this.testLevelList1.length; i++) {
      console.log(this.dieselTypeLevelForm)
      if(this.dieselTypeLevelForm.controls['fluidType'].value == 'Coolant') {
        if(this.testLevelList1[i].toLowerCase() == 'advanced' || this.testLevelList1[i].toLowerCase() == 'contaminant evaluation' || this.testLevelList1[i].toLowerCase() == 'fleet') {
          this.testLevelList.push(this.testLevelList1[i]);
          this.dieselTypeLevelForm.patchValue({
            testLevel: this.testLevelList1[i-1]
          }); 
        }
      }
      else if( this.dieselTypeLevelForm.controls['fluidType'].value == 'Engine Oil (Liquid Fuel)' 
        || this.dieselTypeLevelForm.controls['fluidType'].value == 'Engine Oil (Gaseous Fuel)') {
        if(this.testLevelList1[i].toLowerCase() == 'advanced' || this.testLevelList1[i].toLowerCase() == 'basic' || this.testLevelList1[i].toLowerCase() == 'fleet') {
          this.testLevelList.push(this.testLevelList1[i]);
        }
      }
     else if(this.dieselTypeLevelForm.controls['fluidType'].value == 'Lubricants'){
      if(this.testLevelList1[i].toLowerCase() == 'advanced'|| this.testLevelList1[i].toLowerCase() == 'fleet') {
        this.testLevelList.push(this.testLevelList1[i]);
      }
    }
    else if(this.dieselTypeLevelForm.controls['fluidType'].value == 'Diesel Fuel'){
      if(this.testLevelList1[i].toLowerCase() == 'properties' || this.testLevelList1[i].toLowerCase() == 'cleanliness' || this.testLevelList1[i].toLowerCase() == 'quality') {
        this.testLevelList.push(this.testLevelList1[i]);
      }
    }
    else {
      this.testLevelList =[];
    }
  }
    
  }

  inputChange1() {

  }
  
  searchCogs(){
    this.currentPage = 1;
    this.isNewData = false;
    this.addQRDetailsForm.reset();
    this.searchQRData();
  }

  searchQRData(){
    let data = this.dieselTypeLevelForm.getRawValue();
    data['pageSize'] = this.pageSize.toString();
    data['currentPage'] = this.currentPage;
    data['columnName'] = this.columnName;
    data['sortingOrder'] = this.sortingOrder;
    data['functionName'] = 'SEARCH';
    this.searchObj = data;
    this.masterDataManagementService.qrCodeRangeOperations(data).subscribe(response => {
      console.log(response);
      if (response.statusCode == "1") {
        this.infoList = response.qrCodeRangeDTOList;
        this.displayResult = true;
        this.totalItems = response.qrCodeRangeDTOList[0].maxRow;
        this.calculateOffset();
      }else{
        this.infoList = [];
        this.displayResult = true;
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    },err => {
        this.alertService.error(err);        
    });
  }

  clearCogs(){
    this.dieselTypeLevelForm.reset();
    this.displayResult = false;
    this.testLevelList = [];
  }
  // Pagination
  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;  
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {   
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

    /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.searchQRData();
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;  
    this.searchQRData();    
  }  

  addNewData() {
    this.isNewData = true;
    this.addQRDetailsForm.patchValue({
      fluidType: this.dieselTypeLevelForm.controls['fluidType'].value,
      testLevel: this.dieselTypeLevelForm.controls['testLevel'].value
    });
    if(this.dieselTypeLevelForm.controls['fluidType'].value) {
      for(let i=0; i<this.dataListWithSKU.length; i++) {
        if(this.dieselTypeLevelForm.controls['testLevel'].value == this.dataListWithSKU[i].testLevel && this.dieselTypeLevelForm.controls['fluidType'].value == this.dataListWithSKU[i].fluidType) {
          this.addQRDetailsForm.patchValue({
            sku: this.dataListWithSKU[i].sku
          });
          console.log(this.dataListWithSKU[i].sku);
          return false;
        }
      }
    }
  }

  fluidChange() {
    this.testLevelList = [];
    this.addQRDetailsForm.patchValue({
      testLevel: '',
      sku: ''
    }); 
    console.log(this.addQRDetailsForm.controls['fluidType'].value);
      for(let i=0; i<this.testLevelList1.length; i++) {
        if(this.addQRDetailsForm.controls['fluidType'].value == 'Coolant') {
          if(this.testLevelList1[i].toLowerCase() == 'advanced' || this.testLevelList1[i].toLowerCase() == 'contaminant evaluation' || this.testLevelList1[i].toLowerCase() == 'fleet') {
            this.testLevelList.push(this.testLevelList1[i]);
            this.addQRDetailsForm.patchValue({
              testLevel: this.testLevelList1[i],
              sku: this.dataListWithSKU[i].sku
            }); 
          }
        }else if(this.addQRDetailsForm.controls['fluidType'].value == 'Engine Oil (Liquid Fuel)' || this.addQRDetailsForm.controls['fluidType'].value == 'Engine Oil (Gaseous Fuel)'){
          if(this.testLevelList1[i].toLowerCase() == 'advanced' || this.testLevelList1[i].toLowerCase() == 'basic' || this.testLevelList1[i].toLowerCase() == 'fleet') {
            this.testLevelList.push(this.testLevelList1[i]);
            this.addQRDetailsForm.patchValue({
              testLevel: this.testLevelList1[i],
              sku: this.dataListWithSKU[i].sku
            }); 
          }
        }else if(this.addQRDetailsForm.controls['fluidType'].value == 'Lubricants'){
          if(this.testLevelList1[i].toLowerCase() == 'advanced' || this.testLevelList1[i].toLowerCase() == 'fleet') {
            this.testLevelList.push(this.testLevelList1[i]);
            this.addQRDetailsForm.patchValue({
              testLevel: this.testLevelList1[i],
              sku: this.dataListWithSKU[i].sku
            }); 
          }
        }else if(this.addQRDetailsForm.controls['fluidType'].value == 'Diesel Fuel'){
          if(this.testLevelList1[i].toLowerCase() == 'properties' || this.testLevelList1[i].toLowerCase() == 'cleanliness' || this.testLevelList1[i].toLowerCase() == 'quality') {
            this.testLevelList.push(this.testLevelList1[i]);
            this.addQRDetailsForm.patchValue({
              testLevel: this.testLevelList1[i],
              sku: this.dataListWithSKU[i].sku
            }); 
          }
        }
        else{
          this.testLevelList = [];

        }
    } 

  }

  testLevelChange() {
    for(let i=0; i<this.dataListWithSKU.length; i++) {
      if(this.addQRDetailsForm.controls['testLevel'].value == this.dataListWithSKU[i].testLevel&& this.addQRDetailsForm.controls['fluidType'].value == this.dataListWithSKU[i].fluidType) {
        this.addQRDetailsForm.patchValue({
          sku: this.dataListWithSKU[i].sku
        });
        console.log(this.dataListWithSKU[i].sku);
        return false;
      }
    }
  }

  // getFluidTypeName(){
  //   let fluidTypeName = '';
  //   this.fluidTypeList.forEach(row => {
  //     if(row == this.dieselTypeLevelForm.controls.fluidType.value){
  //       fluidTypeName = row;
  //     }
  //   });
  //   return fluidTypeName;
  // }

  // getTestLevelName(){
  //   let testLevelName = '';
  //   this.testLevelList.forEach(row => {
  //     if(row == this.dieselTypeLevelForm.controls.testLevel.value){
  //       testLevelName = row;
  //     }
  //   });

  //   return testLevelName;
  // }

  hideNewData(){
    this.isNewData = false;
    this.addQRDetailsForm.reset();
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    console.log(normalizedYear);
    let year =  moment(normalizedYear).format('YYYY');
    // const ctrlValue = this.addQRDetailsForm.controls['year'].value;
    // console.log(this.addQRDetailsForm.controls['year'].value)
    // ctrlValue.year(normalizedYear.year());
    this.addQRDetailsForm.patchValue({
      year : year
    });
    // console.log(document.getElementById("dateinput").classList);
    // document.getElementById("dateinput").classList.remove('ng-invalid');
    // console.log(document.getElementById("dateinput").classList);
    datepicker.close();
  }

  openAddModal(){
    let data = this.addQRDetailsForm.getRawValue();
    if(this.addQRDetailsForm.controls['endRange'].value < this.addQRDetailsForm.controls['startRange'].value) {
      return false;
    }
    if(!this.addQRDetailsForm.invalid) {
      this.startRange = data.vendorCode.toUpperCase()+data.year.slice(-2)+'-'+data.sku+'-'+data.startRange;
      this.endRange = data.vendorCode.toUpperCase()+data.year.slice(-2)+'-'+data.sku+'-'+data.endRange;
      this.addModal = true;
    }else{
      this.addModal = false;      
      Object.keys(this.addQRDetailsForm.controls).forEach(field => { 
        const control = this.addQRDetailsForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }    
  }

  closeAddModal(){    
    this.addModal = false;
  }

  submitQRRange(){
    let data = this.addQRDetailsForm.getRawValue();
    data["updatedBy"] = this.sharedService.getUserId();
    data["year"] = data.year.slice(-2);
    data["vendorCode"] = data.vendorCode.toUpperCase();
    // data["fluidType"] = this.dieselTypeLevelForm.controls.fluidType.value;
    // data["testLevel"] = this.dieselTypeLevelForm.controls.testLevel.value;
    data["functionName"] = "ADD";

    this.masterDataManagementService.qrCodeRangeOperations(data).subscribe(response => {       
      if (response.statusCode == "1"){ 
       this.isNewData = false;
       this.searchQRData();     
       this.alertService.success(response.statusMessage);        
      }else {
        this.alertService.error(response.statusMessage);
      }
      this.scrollToTop = true;
      this.addModal = false;
    }, err => {
      this.alertService.error(err);
      this.addModal = false;
      this.scrollToTop = true;
    });
  }
}
