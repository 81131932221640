import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../common/preloader/loader.service';
import { SharedService } from '../../service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';

@Component({
  selector: 'app-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrls: ['./customer-account.component.css']
})
export class CustomerAccountComponent implements OnInit {
  userRole: string = '';
  readonly USERTYPE = USERTYPE;

  constructor(private loader: LoaderService, private sharedService: SharedService) { }

  ngOnInit() {
    this.loader.show();     
    this.sharedService.getUserInfo().subscribe((userInfo)=>{       
      console.log(userInfo);
      if(userInfo.hasOwnProperty('roleName')){        
        this.loader.hide();        
        this.userRole = userInfo.roleName;
      }  
    });
  }

}
