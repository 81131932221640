import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../common/alert/alert.service';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'app-external-user-login',
  templateUrl: './external-user-login.component.html',
  styleUrls: ['./external-user-login.component.css']
})
export class ExternalUserLoginComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private router: Router,
    private alertService: AlertService) { }

  ngOnInit() {
    this.sharedService.getErrorMessage().subscribe((data) =>{
      if(data != ''){
        this.alertService.error(data);
        this.sharedService.setErrorMessage('');
      }
    });
  }

  doRegistartion()
  {
    let link=environment.externalUserCreateAccount;
    window.open(link,'_blank');
  }

  purchaseSample()
  {
  
    let link=environment.purchaseSample;
      window.open(link,'_blank');
  }

  externalUserLogin(){
    if(environment.Req_Server.includes('localhost')){
      this.sharedService.setUserInfo(this.getMockUserData());
      this.sharedService.setExternalUserInfo(this.getMockExternalUserData());
      this.sharedService.setCompanyId('C003468565');
      this.sharedService.setCompanyName('SEARs');
      this.router.navigate(['/home']);
    }else{
      window.location.href = environment.externalAppUrl;
    }
  }

  getMockUserData(){
    var data = {email: "fw.sruser1@gmail.com",
      endDate: null,
      firstName: "Mock",
      internalUser: false,
      lastName: "External User",
      role: "1",
      roleName: USERTYPE.EXTERNAL,
      startDate: null,
      updatedBy: null,
      wwid: "mock1"
    }
      return data;
  }

  getMockExternalUserData(){
    var data =  {
      "attributes": {
        "type": "Contact",
        "url": "/services/data/v48.0/sobjects/Contact/0031F00000TCsObQAL"
      },
      "Name": "test user redirection",
      "Email": "oh611@cummins.com",
      "Username__c": "usernameredirect@gmail.com.csodev",
      "Phone": "9898989898",
      "Account": {
        "attributes": {
          "type": "Account",
          "url": "/services/data/v48.0/sobjects/Account/0011F00000bfepkQAA"
        },
        "IAM_Service_Provider_Code__c": "C003468565",
        "Name":"Company-Name"
      }
    };
     
    return data;
  }
}
