import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { MasterDataManagementService } from '../master-data-management.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-admin-component-type',
  templateUrl: './admin-component-type.component.html',
  styleUrls: ['./admin-component-type.component.css']
})
export class AdminComponentTypeComponent implements OnInit {

  showRecordsMetadata = 0;
  pageSizeMetadata = '10';
  currentPageMetadata = 1;
  changePageMetadata: any = 1;
  pageSizeOptions: any[] = common.pageSizeOptions;
  fleetVal: any = '';
  addComponentTypeInput: boolean = false;
  addMetadataModal = false;
  searchObjMetadata: any;
  columnName: string;
  columnNameMetadata: string;
  componentTypeDisplayColumns: any;
  applicationDisplayColumns: any;
  sortingOrder: string = 'asc';
  successMessage: string = '';
  successModal = false;
  scrollToTop = false;
  dropdownName: string;
  metadataSearchData = [];
  totalItemsMetadata: any;
  maxPageMetadata = 0;
  selectedMetadataGroup: UntypedFormGroup;
  saveMetadataModal = false;
  deleteMetadataModal = false;
  userRole: string = '';
  userRoleDisplayFlag: boolean = false;

  componentFilterObject = {
    componentType: [],
    abbreviation: []
  }

  addComponentTypeForm = new UntypedFormGroup({
    componentTypeName: new UntypedFormControl('', [Validators.required]),
    abbreviationName: new UntypedFormControl('', [Validators.required])
  });

  ComponentTypeSearchForm = new UntypedFormGroup({
    componentType: new UntypedFormControl(''),
    abbreviation: new UntypedFormControl('')
  });

  metadataFormArray = new UntypedFormArray([]);
  // userRoleDisplay: boolean = false;

  constructor(private masterDataManagementService: MasterDataManagementService, private alertService: AlertService,
    private commonHandler: CommonHandlerService, private sharedService: SharedService) { }


  @ViewChildren(SortComponent) sortComponents;

  ngOnInit(): void {
    this.columnName = 'componentType';
    this.searchComponentTypedata();
    this.fetchMetadata();
    this.componentTypeDisplayColumns = [
      { code: "componentType", name: "Component Type" },
      { code: "abbreviation", name: "Abbreviation" }
    ];

    this.sharedService.getUserInfo().subscribe((userInfo) => {
      if (userInfo.hasOwnProperty('roleName')) {
        this.userRole = userInfo.roleName;
      }
    });

    if (this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
      this.userRoleDisplayFlag = true;
    } else if (this.userRole === 'Lab Admin' || this.userRole === 'CMI Engineer' || this.userRole === 'Sample Registration User'
      || this.userRole === 'Technical Service Manager'
      || this.userRole === 'Application Engineer' || this.userRole === 'DBU Branch' || this.userRole === 'DBU Fluids'
    ) {
      this.userRoleDisplayFlag = false;
    }
  }

  sortDataMetadata(columnName) {
    if (columnName == 'abbreviation') {
      this.columnName = 'abbreviation';
      if (this.columnNameMetadata != columnName) {
        this.sortingOrder = "";
        this.sortComponents._results.forEach(row => row.direction = true);
      }
      this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      this.columnNameMetadata = columnName;
      this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
      this.fetchMetadata();
    } else if(this.columnName = 'componentType') {
      this.columnName = 'componentType';
      if (this.columnNameMetadata != columnName) {
        this.sortingOrder = "";
        this.sortComponents._results.forEach(row => row.direction = true);
      }
      this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      this.columnNameMetadata = columnName;
      this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
      this.fetchMetadata();
    }
  }

  intUserclearSearch(str) {
    if (this.ComponentTypeSearchForm.controls[str].value != '') {
      this.ComponentTypeSearchForm.patchValue({ [str]: '' });
      this.componentFilterObject[str] = [];
      // if (str == 'componentType') {
      //   this.searchDashboardDropDownSelect('abbreviation')
      // } else {
      //   this.searchDashboardDropDownSelect('componentType');
      // }
      this.fetchMetadata();
    } else {
      this.componentFilterObject[str] = [];
    }
  }

  addNewComponentType() {
    if (this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
      this.addComponentTypeInput = true;
      // this.userRoleDisplay = true;
    }
  }

  hideComponentTypeInput() {
    this.addComponentTypeInput = false;
  }

  openAddMetadataModal() {
    if (!this.addComponentTypeForm.invalid) {
      this.addMetadataModal = true;
    } else {
      this.addMetadataModal = false;
      Object.keys(this.addComponentTypeForm.controls).forEach(field => {
        const control = this.addComponentTypeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAddMetadataModal() {
    this.addMetadataModal = false;
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.metadataFormArray.controls = [];
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;
    this.fetchMetadata();
  }

  searchComponentTypedata() {
    this.currentPageMetadata = 1;
    this.fetchMetadata();
  }

  fetchMetadata() {
    let data = {};
    let _data = {};
    if (this.columnName == 'componentType') {
      data['componentType'] = this.ComponentTypeSearchForm.value['componentType'];
      data['abbreviation'] = this.ComponentTypeSearchForm.value['abbreviation'];
      data['columnName'] = 'componentType';
      _data['componentType'] = this.ComponentTypeSearchForm.value['componentType'];
      _data['abbreviation'] = this.ComponentTypeSearchForm.value['abbreviation'];
      _data['columnName'] = 'componentType';
    }
    else if (this.columnName == 'abbreviation') {
      data['abbreviation'] = this.ComponentTypeSearchForm.value['abbreviation'];
      data['componentType'] = this.ComponentTypeSearchForm.value['componentType'];
      data['columnName'] = 'abbreviation';
      _data['abbreviation'] = this.ComponentTypeSearchForm.value['abbreviation'];
      _data['componentType'] = this.ComponentTypeSearchForm.value['componentType'];
      _data['columnName'] = 'abbreviation';
    }
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['sortingOrder'] = this.sortingOrder;

    _data['pageSize'] = this.pageSizeMetadata.toString();
    _data['currentPage'] = this.currentPageMetadata;
    _data['sortingOrder'] = this.sortingOrder;

    this.searchObjMetadata = _data;
    this.masterDataManagementService.searchComponentTypedata(data).pipe(take(1)).subscribe(response => {
      if (response.statusCode == "1") {
        this.metadataSearchData = response.componentsList;
        this.totalItemsMetadata = response.componentsList[0].maxRow;
        this.calculateOffsetMetadata();

        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            componentType: new UntypedFormControl(row.componentType, [Validators.required]),
            isEdit: new UntypedFormControl(false),
            abbreviation: new UntypedFormControl(row.abbreviation, [Validators.required]),
          });
          this.metadataFormArray.push(formGroup);
        });

      } else {
        this.alertService.error(response.statusMessage);
        // this.metadataSearchData = [];
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  editMetadata(item) {
    if (this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
      item.patchValue({
        isEdit: true
      });
      // this.userRoleDisplay = true;
    }
  }

  openSaveMetadataModal(item) {
    if (!item.invalid) {
      this.selectedMetadataGroup = item;
      this.saveMetadataModal = true;
    } else {
      this.saveMetadataModal = false;
      Object.keys(item.controls).forEach(field => {
        const control = item.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeSaveMetadataModal() {
    this.saveMetadataModal = false;
  }

  saveMetadata() {
    let data = this.selectedMetadataGroup.getRawValue();
    data['abbreviation'] = data.abbreviation.toUpperCase();
    data['componentType'] = data.componentType.toUpperCase();
    data['functionName'] = 'EDIT';
    // console.log('data', data);

    this.masterDataManagementService.saveComponentTypedata(data).pipe(take(1)).subscribe(response => {
      if (response.statusCode == "1") {
        this.searchComponentTypedata();
        this.successMessage = response.statusMessage;
        this.successModal = true;
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.saveMetadataModal = false;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.saveMetadataModal = false;
    });
  }

  cancelMetadataEdit(item, i) {
    item.patchValue({
      isEdit: false,
      optionName: this.metadataSearchData[i].optionName
    });
    this.fetchMetadata();
  }

  openMetadataDeleteModal(item) {
    if(this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
      this.selectedMetadataGroup = item;
      this.deleteMetadataModal = true;
      // this.userRoleDisplay = true;
    }
  }

  closeDeleteMetadataModal() {
    this.deleteMetadataModal = false;
  }

  deleteMetadata() {
    let data = this.selectedMetadataGroup.getRawValue();
    data['functionName'] = 'DELETE';
    // console.log('DELETE data', data);

    this.masterDataManagementService.deleteComponentTypedata(data).pipe(take(1)).subscribe(response => {
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;
        this.searchComponentTypedata();
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.deleteMetadataModal = false;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.deleteMetadataModal = false;
    });
  }

  addMetadata() {
    let data;
    let data1 = this.addComponentTypeForm.getRawValue();
    data = {
      functionName: 'ADD',
      componentType: data1.componentTypeName,
      abbreviation: data1.abbreviationName.toUpperCase()
    }

    this.masterDataManagementService.addComponentTypedata(data).pipe(take(1)).subscribe(response => {
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;
        this.addComponentTypeInput = false;
        this.addComponentTypeForm.reset();
        this.fetchMetadata();
      } else {
        this.scrollToTop = true;
        this.alertService.error(response.statusMessage);
      }
      this.addMetadataModal = false;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addMetadataModal = false;
    });
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  intUsersearchFilter(str) {
    this.componentFilterObject[str] = [];
    let search = (this.ComponentTypeSearchForm.value[str]).toLowerCase();
    if (search == '') {
      this.componentFilterObject[str] = [];
      this.fetchMetadata();
    }
    let _data;
    if (str == 'componentType') {
      _data = {
        componentType: search,
        abbreviation: this.ComponentTypeSearchForm.value['abbreviation'],
        columnName: "componentType",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    } else if (str == 'abbreviation') {
      _data = {
        abbreviation: search,
        componentType: this.ComponentTypeSearchForm.value['componentType'],
        columnName: "abbreviation",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    }

    if (this.ComponentTypeSearchForm.value[str].length >= 2) {
      this.masterDataManagementService.searchComponentTypedata(_data).pipe(take(1)).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.componentFilterObject[str] = response.componentsList;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }
  }

  searchDashboardDropDownSelect(str) {
    let data = {};
    if (str == 'componentType') {
      data = {
        componentType: this.ComponentTypeSearchForm.value['componentType'],
        abbreviation: this.ComponentTypeSearchForm.value['abbreviation'],
        columnName: "componentType",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    } else if (str == 'abbreviation') {
      data = {
        abbreviation: this.ComponentTypeSearchForm.value['abbreviation'],
        componentType: this.ComponentTypeSearchForm.value['componentType'],
        columnName: "abbreviation",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    }
    this.masterDataManagementService.searchComponentTypedata(data).pipe(take(1)).subscribe(response => {
      if (response.statusCode == "1") {
        this.metadataSearchData = response.componentsList;
        this.totalItemsMetadata = response.componentsList[0].maxRow;
        this.calculateOffsetMetadata();

        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            componentType: new UntypedFormControl(row.componentType),
            isEdit: new UntypedFormControl(false),
            abbreviation: new UntypedFormControl(row.abbreviation),
          });
          this.metadataFormArray.push(formGroup);
        });

      } else if (response.statusCode == "-1") {
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = 0;
        this.changePageMetadata = 0;
        this.showRecordsMetadata = 0;
        this.totalItemsMetadata = 0;
        this.loadPageMetadata(1);
      } else {
        this.alertService.error(response.statusMessage);
        this.metadataSearchData = [];
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }
}
