import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerEmailNotificationService } from '../customer-email-notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { Subscription } from 'rxjs';
import { MatOption } from '@angular/material';
import { RegisterSampleService } from 'src/app/View/service/register-sample.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { Alert } from 'src/app/View/common/alert/alert';

@Component({
  selector: 'app-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.css']
})
export class AddSubscriptionComponent implements OnInit {
  IsCriteriaSeverity:boolean=true;
  IsCriteriaunit:boolean; 
  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map(e => e.trim());
    const forbidden = emails.some(email => Validators.email(new UntypedFormControl(email)));
    const duplicate = emails.some(x => emails.indexOf(x) !== emails.lastIndexOf(x));
    if(emails.length > 150){
      return { 'emailListMaxLength': { value: control.value } };
    }
    if(duplicate){
      return { 'emailListDuplicate': { value: control.value } };
    }
    return forbidden ? { 'emailId': { value: control.value } } : null;
    
  };
  addSubscriptionForm = new UntypedFormGroup(
    {
      subscriptionName: new UntypedFormControl('', [Validators.required]),
      unitId: new UntypedFormControl(''),
      severity: new UntypedFormControl(''),
      emailId:new UntypedFormControl('',[Validators.required, this.commaSepEmail]),
      Criteria: new UntypedFormControl(''),
    }
  );
  readonly USERTYPE = USERTYPE;
  dataArray: any[];
  unitIdList :any [];
  unitIdHasError:boolean;
  isFormValid:boolean;
  scrollToTop = false;
  // @ViewChild('allSelected') private allSelected: MatOption;
  successModal: boolean = false;
  statusMessage: '';

  reportFormArray: Array<any> = [];
  companyId: any;
  data:any;
  severityHasError: boolean;
  subscriptionDetails: any;
  buttonText: boolean;
  userRole: string = '';
  invalidEmails=[];
  companyList: any;
  severity: string;
  checked: boolean = false;
  reportSeverityList: any[];
  tests: any[];
  selectedunitNo: any = [];
  dataArraylist: any[];
  toppings;
  selectedUnitList: any;
  enableunitIdlist: boolean=false;
  checkedradioseverity: boolean;
  checkedradiounit: boolean;
  constructor(private subService: CustomerEmailNotificationService,  
    private alertService: AlertService,
   private router: Router, private registerSampleService: RegisterSampleService,private sharedService: SharedService,
  ) { 
    this.reportSeverityList = [
      {name: 'Watch',checked:false},
      {name: 'Caution',checked:false},
      {name: 'Warning',checked:false},
    ]

  }

  ngOnInit(): void {
    this.UnitIdDropdown();
    this.dataArraylist=[];
    this.subscriptionDetails = JSON.parse(localStorage.getItem('Editdetailsforsubscription'));   
    if(this.subscriptionDetails !=null){
      if(this.subscriptionDetails.subscriptionId != null && this.subscriptionDetails.subscriptionId != undefined && this.subscriptionDetails.subscriptionId != ""){     
            if(this.subscriptionDetails.unitId !='' && this.subscriptionDetails.unitId !=undefined && this.subscriptionDetails.unitId !=null){
              this.IsCriteriaunit= true;
              this.IsCriteriaSeverity = false;
              this.checkedradioseverity=false;
              this.checkedradiounit = true;
            } if(this.subscriptionDetails.severity !='' && this.subscriptionDetails.severity !=null && this.subscriptionDetails.severity !=undefined){
              this.checkedradioseverity=true;
              this.checkedradiounit = false;
              this.IsCriteriaSeverity = true;
              this.IsCriteriaunit= false;
            } if(this.subscriptionDetails.unitId !='' && this.subscriptionDetails.unitId !=undefined && this.subscriptionDetails.unitId !=null && this.subscriptionDetails.severity !='' && this.subscriptionDetails.severity !=null && this.subscriptionDetails.severity !=undefined){
              this.checkedradioseverity=true;
              this.checkedradiounit = true;
              this.IsCriteriaSeverity = true;
              this.IsCriteriaunit= true;
            }
           this.addSubscriptionForm.patchValue({
              subscriptionName: this.subscriptionDetails.subscriptionName,
              emailId: this.subscriptionDetails.emailId,
              severity: '',
              unitId: this.dataArraylist
            });

            this.reportSeverityList.forEach(element => {
              if(this.subscriptionDetails.severity.indexOf(element.name) > -1) {
                console.log(element.name)
                element.checked = true;
                this.reportFormArray.push(element.name);
              }
            });
          
        this.buttonText =true;
      }
    }
    if(this.subscriptionDetails == "" ||  this.subscriptionDetails == null || this.subscriptionDetails == undefined){
      this.buttonText = false;
      this.checkedradioseverity=true;
    }
  }
 

  onChange(rep:string, isChecked: boolean) {
    if(isChecked) {
      this.reportFormArray.push(rep);
    } else {
      let index = this.reportFormArray.indexOf(rep);
      this.reportFormArray.splice(index,1);
    }
    if( this.reportFormArray.length !== 0){
      this.isFormValid = true;
    }else{
      this.isFormValid = false;
    }
  }
  UnitIdDropdown() {
    this.alertService.clear();
    this.companyId = JSON.parse(localStorage.getItem('companyIdforsubscription'));
    this.data = {
      'userId': this.sharedService.getUserId(),
      "companyId" : this.companyId,
      "functionName" : "SEARCH",
      "subscriptionId":1,
      "subscriptionName": null,
      "unitId": null,
      "severity":null,
      "emailId":null
    }
    this.dataArray = [];
    this.dataArraylist=[];
    this.subService.getSubscriptionData(this.data).subscribe((response) => {
      if (response.statusCode == "1") {
        this.unitIdList = response.companyUnitList;
        this.companyList = response.companyList;
        for (let i = 0; i < response.companyUnitList.length; i++) {
          if( response.companyUnitList[i] !="" ||  response.companyUnitList[i] ==null ||  response.companyUnitList[i] ==undefined){
             this.dataArray.push(response.companyUnitList[i].unitId);
          }
        }
        for (let i = 0; i < this.companyList.length; i++) {
          if( this.subscriptionDetails.subscriptionId !=null && this.subscriptionDetails.subscriptionId !="" && this.subscriptionDetails.subscriptionId !=undefined){
            if( this.subscriptionDetails.subscriptionId == this.companyList[i].subscriptionId){
              if(this.companyList[i].unitId !=null && this.companyList[i].unitId !="" && this.companyList[i].unitId !=undefined){
              this.companyList[i].unitId.forEach(element => {
                this.dataArraylist.push(element.unitId)
                this.selectedUnitList = this.companyList[i].unitId.filter(row => {
                  return this.addSubscriptionForm.getRawValue().unitId.includes(row.unitId);
                });
                if(this.selectedUnitList.length>0 
                ){
                  this.enableunitIdlist = true;
                }else{
                  this.enableunitIdlist = false;
                }
              });
            }
          }
         }
        }
        // this.companySearch('');        
      } else {
        this.alertService.error(response.statusMessage);
      }
    }, err => {
      this.alertService.error(err);
    });
  }

  inputUnitName() {
    this.makeunitIdListTable();
  }

  makeunitIdListTable() {
    this.selectedUnitList = this.unitIdList.filter(row => {
      return this.addSubscriptionForm.getRawValue().unitId.includes(row.unitId);
    });
    if(this.selectedUnitList.length>0){
      this.enableunitIdlist = true;
    }else{
      this.enableunitIdlist = false;
    }
  }
  companySearch(value:string){
    this.dataArray= []; 
    let filter = value.toLowerCase();
    
    this.dataArray = this.unitIdList.filter((row) => {
      let unitId = row.unitId.toLowerCase();
      if(unitId.indexOf(filter) >= 0){
        return true;
      }else{
        return false;
      }
    });
    if(this.dataArray.length == 0){
      this.dataArray.push('');
    }
  }

  Criteria(e) {
    if (e.value == "severity") {
      this.IsCriteriaSeverity = true;
      this.IsCriteriaunit= false;
      // this.reportFormArray = [];
    } else {
      this.isFormValid = false;
      this.IsCriteriaunit= true;
      this.IsCriteriaSeverity = false;
      // this.addSubscriptionForm.controls.unitId.value == '';
    }
  }
  clearSearch(){
    // this.unitArray = []; 
    // this.unitArray= this.unitIdList;
  }

  onKey(value) {
    let filter = value;
    for (let i = 0; i < this.unitIdList.length; i++) {
      let option = this.unitIdList[i].unitId;
      if(option.indexOf(filter) >= 0){
      this.dataArray.push(option);
      }
    }
  }

  validateUnitId() {
    if (this.addSubscriptionForm.controls.unitId.value != null ||
      this.addSubscriptionForm.controls.unitId.value != undefined ||
      this.addSubscriptionForm.controls.unitId.value != '' ||
      this.addSubscriptionForm.controls.unitId.value != 0 ||
       this.addSubscriptionForm.controls.unitId.value.length !=0) {
      this.unitIdHasError = true;
    } 
    if(this.addSubscriptionForm.controls.unitId.value.length ==0){
      this.unitIdHasError = false;
    }
  }
 
  onUpdateClick(){
    if(this.addSubscriptionForm.controls["unitId"].value !="" && this.addSubscriptionForm.controls["unitId"].value !=null && this.addSubscriptionForm.controls["unitId"].value !=undefined){
    this.selectedunitNo = [];
    const unitValue = this.addSubscriptionForm.controls["unitId"].value;
    const unitId = (unitValue.indexOf(',') !== -1)? unitValue.split(','): unitValue;
    unitId.forEach(element => {
      //if(this.unitIdList.indexOf(element) > -1) {
        const unitNo = this.unitIdList.filter((tutor, _index) => {return tutor.unitId == element;}); 
        this.selectedunitNo.push(unitNo[0].unitNo);
     // }
    });
  }
 
    this.data = {
      'userId': this.sharedService.getUserId(),
      "companyId" : this.companyId,
      "functionName" : "EDIT",
      "subscriptionId":this.subscriptionDetails.subscriptionId,
      "subscriptionName":this.addSubscriptionForm.controls["subscriptionName"].value,
      "unitId": this.selectedunitNo,
      "severity": this.reportFormArray,
      "emailId":this.addSubscriptionForm.controls["emailId"].value
    }
    console.log(this.data)
    this.subService.getSubscriptionData(this.data).subscribe((response) => {
      if (response.statusCode === '1') {
        this.statusMessage = response.statusMessage;
        this.alertService.success(response.statusMessage);
        this.successModal = true;
        // this.router.navigate(['/customerEmailNotification']);
        // localStorage.setItem('Editdetailsforsubscription', JSON.stringify(''));
        localStorage.removeItem('Editdetailsforsubscription');
        // this.currentItem.recordAdded = true;
        // this.isEditClick = true;
        // this.buttonText = 'Edit';
        // this.fetchData(true);
        // this.setUpdatedLocalValue();
      } else {
        this.successModal = false;
        this.alertService.error(response.statusMessage);
      }
      this.scrollToTop = true;
    });

  }
  
  onSaveClick(){
  if(this.addSubscriptionForm.controls["unitId"].value !="" && this.addSubscriptionForm.controls["unitId"].value !=null && this.addSubscriptionForm.controls["unitId"].value !=undefined){
    const unitValue = this.addSubscriptionForm.controls["unitId"].value;
      this.selectedunitNo = [];
      const unitId = (unitValue.indexOf(',') !== -1)? unitValue.split(','): unitValue;
      unitId.forEach(element => {
        //if(this.unitIdList.indexOf(element) > -1) {
          const unitNo = this.unitIdList.filter((tutor, _index) => {return tutor.unitId == element;}); 
          this.selectedunitNo.push(unitNo[0].unitNo);
      // }
      });
   }
    if(this.userRole === USERTYPE.EXTERNAL){
      this.companyId = JSON.parse(localStorage.getItem('externalUserInformation'));
    }else{
      this.companyId = JSON.parse(localStorage.getItem('companyIdforsubscription'));
    }
    this.data = {
      'userId': this.sharedService.getUserId(),
      "companyId" : this.companyId,
      "functionName" : "ADD",
      "subscriptionId":null,
      "subscriptionName":this.addSubscriptionForm.controls["subscriptionName"].value,
      "unitId": this.selectedunitNo,
      "severity": this.reportFormArray,
      // "companyEmailIds":null,
      "emailId":this.addSubscriptionForm.controls["emailId"].value
    }
    console.log(this.data)
    this.subService.getSubscriptionData(this.data).subscribe((response) => {
      if (response.statusCode === '1') {
        this.statusMessage = response.statusMessage;
        this.alertService.success(response.statusMessage);
        this.successModal = true;
        // this.router.navigate(['/customerEmailNotification']);
        localStorage.removeItem('Editdetailsforsubscription');
        // this.currentItem.recordAdded = true;
        // this.isEditClick = true;
        // this.buttonText = 'Edit';
        // this.fetchData(true);
        // this.setUpdatedLocalValue();
      } else {
        this.successModal = false;
        this.alertService.error(response.statusMessage);
      }
      this.scrollToTop = true;
    });  
  }


}
