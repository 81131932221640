import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  private ifNotProd: any;
  constructor() {
    if (window.location.origin.includes("dev") || window.location.origin.includes("localhost") || localStorage.getItem("overRideLogs") == "Y") {
      this.ifNotProd = true;
    }
  }

  log = function (message: any) {
    if (this.ifNotProd) {
      //console.log(message);
    }
  }
}
