import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../../handlers/api-handler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountGroupManagementService {
  // private groupName: string | null = null;

  constructor(private api: ApiHandlerService, private http: HttpClient) { }

  // getGroupName(): string | null {
  //   return this.groupName;
  // }

  // setGroupName(name: string | null): void {
  //   this.groupName = name;
  // }

  getCompanyDetailList(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/companyGroupOperations', data);
  }
}
