import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import * as XLSX from 'xlsx';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as  pdfFonts from 'pdfmake/build/vfs_fonts';
import { FinReportService } from './fin-report.service';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SharedService } from 'src/app/View/service/shared.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-finance-report',
  templateUrl: './finance-report.component.html',
  styleUrls: ['./finance-report.component.css']
})
export class FinanceReportComponent implements OnInit {

  @ViewChildren(SortComponent) sortComponents;

  financeReportCols: any = [];
  financeReportColData: any = [];
  financeReportColDataCount: any = [];
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;

  isSearchDone = false;
  exportToExcelData: any[] = [];

  searchObj: any;
  scrollToTop: any;
  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  // sortingOrder: string;
  // columnName: string;
  // searchOption: any;

  purchaseDateChecked: boolean = false;
  publishDateChecked: boolean = false;

  dateRange = new UntypedFormGroup({
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
    purchaseDate: new UntypedFormControl(''),
    publishDate: new UntypedFormControl('')
  });

  finReportSearchForm = new UntypedFormGroup({
    sampleId: new UntypedFormControl(''),
    companyId: new UntypedFormControl(''),
    customerName: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    customerEmail: new UntypedFormControl(''),
    purchaseDate: new UntypedFormControl(''),
    orderId: new UntypedFormControl(''),
    itemPurchasePrice: new UntypedFormControl(''),
    shippedToCustomerDate: new UntypedFormControl(''),
    shipmentId: new UntypedFormControl(''),
    submittedDate: new UntypedFormControl(''),
    trackingId: new UntypedFormControl(''),
    publishedDate: new UntypedFormControl(''),
    analysisType: new UntypedFormControl(''),
    testLevel: new UntypedFormControl(''),
    couponUsed: new UntypedFormControl(''),
    couponDiscount: new UntypedFormControl(''),
    sampleIdTotal: new UntypedFormControl(''),
    cogs: new UntypedFormControl('')
  })

  filterObject = {
    sampleId: [],
    companyId: [],
    customerName: [],
    companyName: [],
    customerEmail: [],
    purchaseDate: [],
    orderId: [],
    itemPurchasePrice: [],
    shippedToCustomerDate: [],
    shipmentId: [],
    submittedDate: [],
    trackingId: [],
    publishedDate: [],
    analysisType: [],
    testLevel: [],
    couponUsed: [],
    couponDiscount: [],
    sampleIdTotal: [],
    cogs: [],
  }

  couponList = [{'couponUsedCode':'Yes', 'couponUsed':'Yes'},{'couponUsedCode': 'No', 'couponUsed': 'No'}];

  constructor(private finReportServ: FinReportService, 
    private commonHandler: CommonHandlerService, 
    private alertService: AlertService,
    private sharedService: SharedService) { }
  ngDoCheck() {
    /*this.minEndDate = this.dateRange.value.startDate;
    this.maxStartDate = this.dateRange.value.endDate;*/
  }  

  ngOnInit() {
    this.columnName = 'sampleId';
    this.sortingOrder = 'desc';
    //this.getFinanceDashboardData();
    this.financeReportCols = [
      { code: 'sampleId', value: 'Sample ID' },
      { code: 'companyId', value: 'Company ID' },
      { code: 'customerName', value: 'Customer Name' },
      { code: 'companyName', value: 'Company Name' },
      { code: 'customerEmail', value: 'Customer Email' },
      { code: 'purchaseDate', value: 'Purchased Date' },
      { code: 'orderId', value: 'Order ID' },
      { code: 'itemPurchasePrice', value: 'Item (msrp) Purchase Price ($)' },
      { code: 'shippedToCustomerDate', value: 'Shipped To Customer Date' },
      { code: 'shipmentId', value: 'Shipment ID' },
      { code: 'submittedDate', value: 'Submitted Date' },
      { code: 'trackingId', value: 'Tracking ID' },
      { code: 'publishedDate', value: 'Report Published Date' },
      { code: 'analysisType', value: 'Analysis Type' },
      { code: 'testLevel', value: 'Test Level' },
      { code: 'couponUsed', value: 'Coupon Used' },
      { code: 'couponDiscount', value: 'Coupon Discount ($)' },
      { code: 'sampleIdTotal', value: 'Line Item TOTAL ($)' },
      { code: 'cogs', value: 'COGS ($)' },

    ];

    this.purchaseDateChecked = false;
    this.publishDateChecked = false;
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
  }

  startDateChange() {
    this.minEndDate = this.dateRange.value.startDate;    
  }

  endDateChange() {    
    this.maxStartDate = this.dateRange.value.endDate;
  }

  getFinanceDashboardData() {
    if (this.dateRange.value.purchaseDate == null || this.dateRange.value.purchaseDate == false) {
      this.purchaseDateChecked = false;
    } else {
      this.purchaseDateChecked = true;
    }

    if (this.dateRange.value.publishDate == null || this.dateRange.value.publishDate == false) {

      this.publishDateChecked = false;
    } else {
      this.publishDateChecked = true;

    }

    //this.finReportSearchForm.reset();
    this.searchObj = {

      "pageSize": this.pageSize.toString(),
      "currentPage": this.currentPage.toString(),
      "columnName": this.columnName,
      "sortingOrder": this.sortingOrder,
      "startDate": this.dateRange.value.startDate.toLocaleDateString(),
      "endDate": this.dateRange.value.endDate.toLocaleDateString(),
      "purchaseSelected": this.purchaseDateChecked,
      "publishedSelected": this.publishDateChecked,




      sampleId: this.finReportSearchForm.value.sampleId,
      companyId: this.finReportSearchForm.value.companyId,
      customerName: this.finReportSearchForm.value.customerName,
      companyName: this.finReportSearchForm.value.companyName,
      customerEmail: this.finReportSearchForm.value.customerEmail,
      purchaseDate: this.finReportSearchForm.value.purchaseDate,
      orderId: this.finReportSearchForm.value.orderId,
      itemPurchasePrice: this.finReportSearchForm.value.itemPurchasePrice,
      shippedToCustomerDate: this.finReportSearchForm.value.shippedToCustomerDate,
      shipmentId: this.finReportSearchForm.value.shipmentId,
      submittedDate: this.finReportSearchForm.value.submittedDate,
      trackingId: this.finReportSearchForm.value.trackingId,
      publishedDate: this.finReportSearchForm.value.publishedDate,
      analysisType: this.finReportSearchForm.value.analysisType,
      testLevel: this.finReportSearchForm.value.testLevel,
      couponUsed: this.finReportSearchForm.value.couponUsed,
      couponDiscount: this.finReportSearchForm.value.couponDiscount,
      sampleIdTotal: this.finReportSearchForm.value.sampleIdTotal,
      cogs: this.finReportSearchForm.value.cogs,

    }

    this.finReportServ.getFinReportData(this.searchObj).subscribe(response => {
      if (response.statusCode == "1") {
        this.financeReportColData = response.financeReportList;
       /* this.financeReportColData.forEach(element => {
          
          element.cogs = parseFloat(element.cogs.substring(1));
          element.itemPurchasePrice = parseFloat(element.itemPurchasePrice.substring(1));
          element.couponDiscount = parseFloat(element.couponDiscount.substring(1));
          element.sampleIdTotal = parseFloat(element.sampleIdTotal.substring(1));

        });*/
        this.financeReportColDataCount = response.financeReportCountList;
        this.totalItems = response.maxRow;
        this.filterObject.couponUsed = this.couponList;
        this.calculateOffset();
        console.log("local array", this.financeReportColDataCount, this.financeReportColData);
        this.isSearchDone = true;
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  getColumnCode(str) {
    if (str === 'couponUsed') {
      return 'couponUsedCode';
    } else {
      return str;
    }
  }

  search() {
    this.isSearchDone = true;
    console.log(this.dateRange.value.startDate + '  ' + this.dateRange.value.endDate);
  }

  /**
* Calls search method to sort the data based on columnName
* @param columnName 
*/
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';

    this.getFinanceDashboardData();
  }


  buildTableBody(data, columns, headers) {
    var body = [];
    body.push(headers);
    data.forEach(function (row) {
      var dataRow = [];
      columns.forEach(function (column) {
        dataRow.push(row[column].toString());
      })
      body.push(dataRow);
    });
    return body;
  }

  table(data, columns, headers) {
    return {
      table: {
        headerRows: 1,
        body: this.buildTableBody(data, columns, headers),

      }
    };
  }
  DownloadPDF() {
    let pdfDoc = {
      pageOrientation: 'landscape',
      pageSize: 'A4',
      header: function (currentPage, pageCount, pageSize) {

        return [
          {
            text: 'Finance Report',
            border: [true, true, true, true], margin: [40, 10, 0, 0],
            style: 'headerExample',
          }, { canvas: [{ type: 'line', x1: 40, y1: 0, x2: 800, y2: 0, lineWidth: 0.5, lineColor: 'teal' }] }
        ]
      },
      footer: function (currentPage, pageCount) { return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, margin: [40, 10, 0, 0], border: [true, true, true, true], style: 'footerExample' }; },

      content: [
        {
          border: [false, false, false, false], margin: [0, 0, -1.5, 0],
          style: 'tableContentOilHealth',
          table: {
            body: [
              [{
                fillColor: '#696969', color: '#FFFFFF', bold: true, fontSize: 8, alignment: 'center', text: 'Finance Report',
                margin: [0, 2, 0, 2]

              }],
              [this.table(this.financeReportColData, ['sampleId', 'companyId', 'customerName', 'companyName', 'customerEmail', 'purchasedDate', 'orderId', 'itemPurchasePrice',
                'shippedDate', 'shipmentID', 'submittedDate', 'trackingId', 'reportPublishedDate', 'analysisType', 'testLevel', 'couponUsed', 'couponDiscount', 'sampleIdTotal', 'cogs'],

                [{ fillColor: '#000000', color: '#FFFFFF', borderColor: ['#000000', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Sampled ID' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Company ID' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Customer Name' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Company Name' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Customer Email' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Purchase Date' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Order ID' },

                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Item(msrp) Purchase Price ($)' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Shipped Date' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Shipment ID' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Submitted Date' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Tracking ID' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Report Published Date' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Analysis Type' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Test Level' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Coupon Used' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Coupon Discount ($)' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#ffffff', '#000000'], bold: true, alignment: 'center', text: 'Line Item Total ($)' },
                { fillColor: '#000000', color: '#FFFFFF', borderColor: ['#ffffff', '#000000', '#000000', '#000000'], bold: true, alignment: 'center', text: 'COGS ($)' }

                ]
                // ['Sample ID', 'Company ID', 'Customer Name', 'Company Name', 'Customer Email', 'Purchased Date', 'Item Purchase Price',
                //   'Shipped Date', 'Shipment ID', 'Submitted Date', 'Tracking ID', 'Report Published Date', 'Analysis Type', 'Test Level',
                //    'Coupon Used', 'Coupon Discount', 'Line Item Total', 'COGS']

              )],

            ],
          }
          , layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 0 : 0;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 0 : 0;
            },
            hLineColor: function (i, node) {
              return 'lightGray';
            },
            vLineColor: function (i, node) {
              return 'lightGray';
            },
            paddingLeft: function (i, node) { return 0; },
            paddingRight: function (i, node) { return 0; },
            paddingTop: function (i, node) { return 0; },
            paddingBottom: function (i, node) { return 0; }
          }
        }
        ,



      ],

      styles: {
        header: {
          fontSize: 8,
          bold: true,
        },
        tableContentSmi: {
          fontSize: 8
        },
        tableContentOilHealth: {
          fontSize: 6,
          alignment: 'center'

        },
        tableContentMetal: {
          fontSize: 6.35,
          alignment: 'center',
          border: true
        },
        analysisAndAction: {
          fontSize: 8,
          bold: true,

        },
        disclaimer: {
          fontSize: 6,
          bold: true
        }
      }

    };
    pdfMake.createPdf(pdfDoc).download("Finance Report");
  }


  exportToExcel() {
    let data ={
      "startDate": this.dateRange.value.startDate.toLocaleDateString(),
      "endDate": this.dateRange.value.endDate.toLocaleDateString(),
      "purchaseSelected": this.purchaseDateChecked,
      "publishedSelected": this.publishDateChecked,
      "email": this.sharedService.getUserInformation().email
      //"email": 'rv870@cummins.com'
    };
    this.finReportServ.sendFinanceReport(data).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.alertService.success(response.statusMessage);
        } else {
          this.alertService.error(response.statusMessage);         
        }     
      }, err => {
        this.alertService.error(err);
      }
    );

    //this.exportAsExcelFile(this.exportToExcelData, 'Finance_Report');

  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // this.convertHeaderToUppercase(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // this.wrapAndCenterCell(worksheet.G1);
    const workbook: XLSX.WorkBook = { Sheets: { 'Finance Report': worksheet }, SheetNames: ['Finance Report'] };
    XLSX.writeFile(workbook, `${excelFileName}_export.xlsx`);
  }






  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.financeReportColData = [];
    this.financeReportColDataCount = [];
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.financeReportColData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        // this.scrollToTop = true;
      }
    }
  }

  searchLengthInt(filterObject: any) {
    if (filterObject.companyId.length > 0 || filterObject.unitId.length > 0 || filterObject.unitMake.length > 0 ||
      filterObject.unitModel.length > 0 || filterObject.unitYear.length > 0 || filterObject.esn.length > 0 ||
      filterObject.engineMake.length > 0 || filterObject.engineModel.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  searchLengthExt(filterObject: any) {
    if (filterObject.unitId.length > 0 || filterObject.unitMake.length > 0 ||
      filterObject.unitModel.length > 0 || filterObject.unitYear.length > 0 || filterObject.esn.length > 0 ||
      filterObject.engineMake.length > 0 || filterObject.engineModel.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.getFinanceDashboardData();

  }



  getArray(): any[] {
    return Array(this.maxPage);
  }


  searchDashboardDropDownSelect() {
    this.searchObj = {
      sampleId: this.finReportSearchForm.value.sampleId,
      companyId: this.finReportSearchForm.value.companyId,
      customerName: this.finReportSearchForm.value.customerName,
      companyName: this.finReportSearchForm.value.companyName,
      customerEmail: this.finReportSearchForm.value.customerEmail,
      purchaseDate: this.finReportSearchForm.value.purchaseDate,
      orderId: this.finReportSearchForm.value.orderId,
      itemPurchasePrice: this.finReportSearchForm.value.itemPurchasePrice,
      shippedToCustomerDate: this.finReportSearchForm.value.shippedToCustomerDate,
      shipmentId: this.finReportSearchForm.value.shipmentId,
      submittedDate: this.finReportSearchForm.value.submittedDate,
      trackingId: this.finReportSearchForm.value.trackingId,
      publishedDate: this.finReportSearchForm.value.publishedDate,
      analysisType: this.finReportSearchForm.value.analysisType,
      testLevel: this.finReportSearchForm.value.testLevel,
      couponUsed: this.finReportSearchForm.value.couponUsed,
      couponDiscount: this.finReportSearchForm.value.couponDiscount,
      sampleIdTotal: this.finReportSearchForm.value.sampleIdTotal,
      cogs: this.finReportSearchForm.value.cogs,

      "pageSize": this.pageSize.toString(),
      "currentPage": this.currentPage.toString(),
      "columnName": this.columnName,
      "sortingOrder": this.sortingOrder,
      "startDate": this.dateRange.value.startDate.toLocaleDateString(),
      "endDate": this.dateRange.value.endDate.toLocaleDateString(),
      "purchaseSelected": this.purchaseDateChecked,
      "publishedSelected": this.publishDateChecked
    }


    this.finReportServ.getFinReportData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.financeReportColData = response.financeReportList;
          this.financeReportColDataCount = response.financeReportCountList;
          this.totalItems = response.maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.financeReportColData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );

  }

  searchFilter(str) {
    let search = (this.finReportSearchForm.value[str]).toLowerCase();
    let _data = {
      searchColumn: str,
      searchValue: this.finReportSearchForm.value[str],
      companyId: this.finReportSearchForm.value.companyId,
      sampleId: this.finReportSearchForm.value.sampleId,

      customerName: this.finReportSearchForm.value.customerName,
      companyName: this.finReportSearchForm.value.companyName,
      customerEmail: this.finReportSearchForm.value.customerEmail,
      purchaseDate: this.finReportSearchForm.value.purchaseDate,
      orderId: this.finReportSearchForm.value.orderId,
      itemPurchasePrice: this.finReportSearchForm.value.itemPurchasePrice,
      shippedToCustomerDate: this.finReportSearchForm.value.shippedToCustomerDate,
      shipmentId: this.finReportSearchForm.value.shipmentId,
      submittedDate: this.finReportSearchForm.value.submittedDate,
      trackingId: this.finReportSearchForm.value.trackingId,
      publishedDate: this.finReportSearchForm.value.publishedDate,
      analysisType: this.finReportSearchForm.value.analysisType,
      testLevel: this.finReportSearchForm.value.testLevel,
      couponUsed: this.finReportSearchForm.value.couponUsed,
      couponDiscount: this.finReportSearchForm.value.couponDiscount,
      sampleIdTotal: this.finReportSearchForm.value.sampleIdTotal,
      cogs: this.finReportSearchForm.value.cogs,


      "pageSize": this.pageSize.toString(),
      "currentPage": this.currentPage.toString(),
      "columnName": this.columnName,
      "sortingOrder": this.sortingOrder,
      "startDate": this.dateRange.value.startDate.toLocaleDateString(),
      "endDate": this.dateRange.value.endDate.toLocaleDateString(),
      "purchaseSelected": this.purchaseDateChecked,
      "publishedSelected": this.publishDateChecked
    }
    // _data.userRole = this.sharedService.getIntOrExtUser();


    if (this.finReportSearchForm.value[str].length >= 2) {
      this.finReportServ.getFinReportData(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.filterObject[str] = response.distinctValuesList;
            this.filterObject['couponUsed'] = this.couponList;

            // if (str === 'engineMake' || str === 'engineModel') {
            //   this.addBlank(str, search, this.filterObject);
            // }
          }
          else {
            //let value = this.addBlank(str, search, this.filterObject)

            // if (value === 'valid') {
            //   console.log('valid')
            // }
            // else if (value === 'invalid') {
            //   this.alertService.error(response.statusMessage);
            //this.scrollToTop = true;
            this.filterObject[str] = [];
            this.filterObject['couponUsed'] = this.couponList;
            this.alertService.error(response.statusMessage);

            //}
            // else {
            //   this.alertService.error(response.statusMessage);
            //   //this.scrollToTop = true;
          }

        }, err => {
          this.alertService.error(err);
          //          this.scrollToTop = true;
        }
      );
    }
  }

  clearSearch(str) {
    //console.log(this.extUsermanageFleetSearchForm.value);
    if (this.finReportSearchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.filterObject['couponUsed'] = this.couponList;
      this.finReportSearchForm.patchValue({ [str]: '' });
      this.getFinanceDashboardData();
    }
  }

  anyoneDateSelected():boolean{    
    if (this.dateRange.value.purchaseDate == null || this.dateRange.value.purchaseDate == false) {
      this.purchaseDateChecked = false;
    } else {
      this.purchaseDateChecked = true;
    }

    if (this.dateRange.value.publishDate == null || this.dateRange.value.publishDate == false) {
      this.publishDateChecked = false;
    } else {
      this.publishDateChecked = true;
    }

    if(this.purchaseDateChecked || this.publishDateChecked){
      return true;
    }else{
      return false;
    }    

  }


}

