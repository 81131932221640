import { Component, OnInit, ViewChildren} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { DbuToolService } from '../dbu-tool.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-wwid-access',
  templateUrl: './wwid-access.component.html',
  styleUrls: ['./wwid-access.component.css']
})

export class WwidAccessComponent implements OnInit {
    [x: string]: any;
    readonly USERTYPE = USERTYPE;
    @ViewChildren(SortComponent) sortComponents;  
    isNewData:boolean=false;
    displayResult = false;
    hidden = false;
    roleTypeList = [];
    wwidTypeList = [];
    wwidList = [];
    branchList = [];
    customerData=[];
    searchObj:any;
    scrollToTop =  false;
    minDate: Date;  
    deleteModal = false; 
    branchNameModal= false;  
    isSearchDone: boolean = false;
    exportToExcelDiv: boolean = false;
    exportExcelData: any[] = [];
    wwidSearchData = [];

     /* ------Pagination variables declaration--------- */
     pageSizeOptions: any[] = common.pageSizeOptions;
     pageSize = "10";
     currentPage = 1;
     showRecords = 0;
     maxPage = 0;
     totalItems: any;
     changePage: any = 1;
     rowDetails: any;
     sortingOrder: string;
     columnName: string;
     searchOption: any;  
     userRole: string = '';
     column:any;
     cuscolumn:any;
     butDisabled:boolean = false;

     filterObject = {
      branchName: [],
      locId: [],
      region: [],
      branchCity: [],
      branchState: [],
      branchCountry: [],
      branchZipCode: []
    };
    customerfilterObject = {
      customerId: [],
      customerName: [],
      customerState: [],
      customerZipCode: []
     
    };
    wwidForm=new UntypedFormGroup({
      role: new UntypedFormControl(''),
      wwidName: new UntypedFormControl('', [Validators.required])
    });
    searchForm = new UntypedFormGroup({
      branchName: new UntypedFormControl(''),
      locId: new UntypedFormControl(''),
      region: new UntypedFormControl(''),
      branchCity: new UntypedFormControl(''),
      branchState: new UntypedFormControl(''),
      branchCountry: new UntypedFormControl(''),
      branchZipCode:new UntypedFormControl(''),
    });
    customersearchForm=new UntypedFormGroup({
      customerId: new UntypedFormControl(''),
      customerName: new UntypedFormControl(''),
      customerState: new UntypedFormControl(''),
      customerZipCode:new UntypedFormControl(''),
    });
   
    roleDropdownNameList = [    
      {code: 'TECHNICAL SERVICE MANAGER', roleName: 'Technical Service Manager'},
      {code: 'DBU FLUIDS', roleName: 'DBU Fluids'},
      {code: 'DBU BRANCH', roleName: 'DBU Branch'},
    ];
    roleDropdownNameListval = [    
      {code: 'TECHNICAL SERVICE MANAGER', roleName: 'Technical Service Manager'},
      {code: 'DBU FLUIDS', roleName: 'DBU Fluids'},
      {code: 'DBU BRANCH', roleName: 'DBU Branch'},
    ];
    wwidColumn = [
      { code: 'branchName', name: 'Branch Name' },
      { code: 'locId', name: 'LOC ID' },
      { code: 'region', name: 'Region' },
      { code: 'branchCity', name: 'City Name' },
      { code: 'branchState', name: 'State/Province' },
      { code: 'branchCountry', name: 'Country' },
      { code: 'branchZipCode', name: 'Zip Code' },
      { code: 'action', name: 'Action' }
    ];
    customerColumn = [
      { code: 'customerId', name: 'Customer ID' },
      { code: 'customerName', name: 'Customer Name' },
      { code: 'customerState', name: 'Customer State' },
      { code: 'customerZipCode', name: 'Customer Zip Code' },
      { code: 'action', name: 'Action' }

    ];
    displaycustomerColumn = [
      { code: 'customerId', name: 'Customer ID' },
      { code: 'customerName', name: 'Customer Name' },
      { code: 'customerState', name: 'Customer State' },
      { code: 'customerZipCode', name: 'Customer Zip Code' },
    ];
    constructor(private commonHandler: CommonHandlerService,
        private sharedService: SharedService,
        private dbuToolService:DbuToolService,
        private alertService: AlertService) { }
    
    ngOnInit() {
        this.userRole = this.sharedService.getUserRole();
        this.loginuser();
        // if(this.userRole == USERTYPE.DBU_BRANCH){
        //   this.searchwwidData();
        // }
        this.columnName = '';
        this.sortingOrder = 'asc';
        this.displayResult = false;
        this.getwwidDropdown();
        this.registrationUser = JSON.parse(localStorage.getItem('userInformation'));
        this.wwidTypeListCopyval = this.wwidTypeList;
        if(this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.LAB_ADMIN ||  this.userRole === USERTYPE.DBU_BRANCH){
          this.wwidColumn = [
            { code: 'branchName', name: 'Branch Name' },
            { code: 'locId', name: 'LOC ID' },
            { code: 'region', name: 'Region' },
            { code: 'branchCity', name: 'City Name' },
            { code: 'branchState', name: 'State/Province' },
            { code: 'branchCountry', name: 'Country' },
            { code: 'branchZipCode', name: 'Zip Code' },
          
          ];
          this.customerColumn = [
            { code: 'customerId', name: 'Customer ID' },
            { code: 'customerName', name: 'Customer Name' },
            { code: 'customerState', name: 'Customer State' },
            { code: 'customerZipCode', name: 'Customer Zip Code' },
      
          ];
        }
        if(this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.LAB_ADMIN ||  this.userRole === USERTYPE.DBU_FLUIDS ||this.userRole === USERTYPE.FLUIDWATCH_MANAGER ){
          this.roleDropdownNameList = [    
            {code: 'TECHNICAL SERVICE MANAGER', roleName: 'Technical Service Manager'},
            {code: 'DBU FLUIDS', roleName: 'DBU Fluids'},
            {code: 'DBU BRANCH', roleName: 'DBU Branch'},
          ];
        } 
        else if(this.userRole === USERTYPE.DBU_BRANCH){
          this.searchwwidData();
            this.butDisabled = true;
            // this.wwidForm.controls['role'].disable();
            // this.wwidForm.controls['wwidName'].disable();
            this.roleDropdownNameListfluids = [    
            {code: 'DBU BRANCH', roleName: 'DBU Branch'},
            ];
            this.wwidForm.patchValue({
              role: this.roleDropdownNameListfluids[0].roleName,
              wwidName:this.wwid3

            })
           
           
        }
         
        // else if(this.userRole === USERTYPE.DBU_BRANCH){
        //   this.roleDropdownNameListfluids = [    
        //     {code: 'DBU FLUIDS', roleName: 'DBU Fluids'},
        //   ];
        //   this.wwidForm.patchValue({
        //     role: this.roleDropdownNameListfluids[0].roleName
        //   })
        //   this.wwidForm.controls['role'].disable();

          // // this.data = {
          // //   "functionName": "DROPDOWN",
          // // }
          // // this.dbuToolService.wwidBranchAccess(this.data).subscribe(response => {
          // //   this.wwidType = response.userRoleList;   
          // //   console.log(this.wwidType)

          // //  });
          //  console.log(this.wwidTypeList)

          // this.role = this.wwidForm.controls.role.value;
          // this.wwidTypeListCopyval = [];      
          // this.wwidTypeList.forEach(element => {        
          //   if(element.roleName == this.role){
          //     console.log('l')
          //     this.wwidTypeListCopyval.push(element);
          //   }
          // });  
        // }else if(this.userRole === USERTYPE.FLUIDWATCH_MANAGER){
       
        //   this.roleDropdownNameListman = [    
        //     {code: 'TECHNICAL SERVICE MANAGER', roleName: 'Technical Service Manager'},
        //   ];
        //   this.wwidForm.patchValue({
        //     role:this.roleDropdownNameListman[0].roleName
        //   })
        //   this.wwidForm.controls['role'].disable();

        // }
    }
    loginuser() {    
      this.sharedService.getUserInfo().subscribe((userInfo)=>{      
        if(userInfo.hasOwnProperty('roleName')){
          this.wwid3 = userInfo.wwid;
          console.log( this.wwid3)
        }
  
      }); 
    }
    
    ngAfterViewChecked(){    
      if(this.scrollToTop)  {
        let element = document.querySelector('#alertDiv');
        if(element != null){        
          element.scrollIntoView({behavior: 'smooth', block:'center'});
          element = null;
        }
        this.scrollToTop = false;
      }
    }

    getwwidDropdown(){
      this.data = {
        "functionName": "DROPDOWN",
      }
      this.dbuToolService.wwidBranchAccess(this.data).subscribe(response => {
        this.wwidTypeList = response.userRoleList;
        this.wwidTypeListCopy =  response.userRoleList;
        this.wwidTypeListCopyval = response.userRoleList;
      },err => {
        this.alertService.error(err);        
      });
    }

    clearwwid(){
      this.wwidForm.reset();
      this.displayResult = false;
      this.exportToExcelDiv= false;
      this.isSearchDone = false;
      this.wwidTypeListCopyval = this.wwidTypeList;
      this.roleDropdownNameList=this.roleDropdownNameListval;
      // if(this.userRole === USERTYPE.DBU_FLUIDS){
      //   this.roleDropdownNameListfluids = [    
      //     {code: 'DBU FLUIDS', roleName: 'DBU Fluids'},
      //   ];
      //   this.wwidForm.patchValue({
      //     role: this.roleDropdownNameListfluids[0].roleName
      //   })
      // }else if(this.userRole === USERTYPE.FLUIDWATCH_MANAGER){
     
      //   this.roleDropdownNameListman = [    
      //     {code: 'TECHNICAL SERVICE MANAGER', roleName: 'Technical Service Manager'},
      //   ];
      //   this.wwidForm.patchValue({
      //     role:this.roleDropdownNameListman[0].roleName
      //   })
      // }
      this.clearwwidSearch();
    }

   
    inputChange(){
      this.displayResult = false;
      this.isNewData = false;
    }

    searchwwid(){
      this.columnName = '';
      this.sortingOrder = '';
      this.currentPage = 1;
      this.isNewData = false;
      this.searchwwidData();
      this.customersearchForm.reset();
      this.searchForm.reset();
      //backspace clearing
      this.customerfilterObject = {customerId: [],customerName: [],customerState: [],customerZipCode: [] };
      this.filterObject = {branchName: [],locId: [],region: [],branchCity: [],branchState: [],branchCountry: [],branchZipCode: []};
      this.sortData('');
    }
    branchName(){
      this.branchNameModal= true;
    }
   
    roleChange(){
      this.role = this.wwidForm.controls.role.value;
      this.wwidForm.patchValue({
        wwidName:''
      })
      this.wwidTypeListCopy = [];
      this.wwidTypeListCopyval = [];      
      this.wwidTypeList.forEach(element => {        
        if(element.roleName == this.role){
          this.wwidTypeListCopy.push(element);
          this.wwidTypeListCopyval.push(element);
        }
      });  
    
//       this.role = this.wwidForm.controls.role.value;
     
//       this.wwidTypeListCopyval = [] ;
//       this.wwidTypeListCopyval1 = [] ;
//       this.wwidTypeList = []

//       this.wwidTypeListCopy.forEach(element => {     
//       if(element.roleName == this.role){        
//        this.wwidTypeListCopyval.push(element)   
//        this.wwidTypeListCopyval1.push(element)
//        this.wwidTypeList.push(element);
//          }  
//     //  else{
//     //    this.wwidTypeList=  this.wwidTypeListCopy
//     //      }
//        });
    }
    wwidChange(){
      this.wwid = this.wwidForm.controls.wwidName.value;
      this.roleDropdownNameListcopy = [];
      this.wwidTypeList.forEach(element => {        
        if(element.WWID == this.wwid){
          this.roleDropdownNameListcopy.push(element);
          this.wwidForm.patchValue({
            role:this.roleDropdownNameListcopy[0].roleName
          })
        }
      });  
    }

    wwidDataSearch(value: string){
      let filter = value.toLowerCase();
      this.wwidSearchData = [];
      this.wwidList.forEach(row => {
        if (row.wwidName.toLowerCase().indexOf(filter) >= 0) {
          this.wwidSearchData.push(row);
        }
      });
      if(this.wwidSearchData.length == 0){
        this.wwidSearchData.push({"wwidName":"Select"});
      }
    }
    
  clearwwidData(){
    this.wwidSearchData = [];
    this.wwidTypeList.forEach(row => {      
      this.wwidSearchData.push(row);     
    });
  }
    searchwwidData(){
      this.wwidTypeList.forEach(element => {   
      if(element.WWID ==this.wwidForm.controls.wwidName.value){
         this.displayRole1 = element.roleName;
         }  
       });
      this.selecteduserRole =  this.wwidForm.controls.role.value;
      this.selectedrole = this.wwidTypeList;
      if(this.selecteduserRole == 'Technical Service Manager' ||  this.displayRole1 == 'Technical Service Manager'){
        this.displayRole = true;
      }else{
        this.displayRole = false;
      }
      if(this.role == 'Technical Service Manager' || this.displayRole1 == 'Technical Service Manager'){
        this.userrole = 'TSM/AE';
      }else{
        this.userrole =this.role ;
        this.userrole=  this.displayRole1;
      }
     
      if(this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.LAB_ADMIN ||  this.userRole === USERTYPE.DBU_FLUIDS ||this.userRole === USERTYPE.FLUIDWATCH_MANAGER){
        if(this.wwidForm.controls.wwidName.value !='' && this.wwidForm.controls.wwidName.value !=null
        && this.wwidForm.controls.role.value !='' && this.wwidForm.controls.role.value !=null ){
          this.data = {
            "wwid" : this.wwidForm.controls.wwidName.value,
            "userRole" : this.wwidForm.controls.role.value,
            "functionName" : "SEARCH",
            "currentPage": this.currentPage,
            "pageSize": this.pageSize.toString(),
            "sortingOrder" : this.sortingOrder,
            'columnName':this.columnName
    
          }
        }else if((this.wwidForm.controls.wwidName.value !='' || this.wwidForm.controls.wwidName.value !=null) &&  (this.wwidForm.controls.role.value =='' || this.wwidForm.controls.role.value ==null )) {
          this.wwidTypeList.forEach(element => {   
              if(element.WWID ==this.wwidForm.controls.wwidName.value){
                 this.roleval = element.roleName;
                 }  
               });       
           this.data = {
            "wwid" : this.wwidForm.controls.wwidName.value,
            "userRole" :this.roleval,
            "functionName" : "SEARCH",
            "currentPage": this.currentPage,
            "pageSize": this.pageSize.toString(),
            "sortingOrder" : this.sortingOrder,
            'columnName':this.columnName
  
          }
        }
       
      }
      else if(this.userRole == USERTYPE.DBU_BRANCH){
        this.data = {
          "wwid": this.wwid3,
          "userRole": this.userRole,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder,
          'columnName':this.columnName
        }
      }
      this.searchObj = this.data;
      this.dbuToolService.wwidBranchAccess(this.data).subscribe(response => {
        if (response.statusCode == "1") {
          this.wwidList = response.wwidAccessDTOList;
          this.displayResult = true;
          this.exportToExcelDiv = true;
          this.totalItems = response.wwidAccessDTOList[0].maxRow;
          this.calculateOffset();
          this.searchForm.reset();
          this.customersearchForm.reset();
          this.isSearchDone = true;

        }else{
          this.wwidList = [];
          this.displayResult = false;
          this.isRoleData = true;
          this.isSearchDone = false;
          this.exportToExcelDiv = false;   
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      },err => {
          this.alertService.error(err);    
          this.scrollToTop = true;        
      });
    }

    searchFilter(str) {
      let search = (this.searchForm.value[str]).toLowerCase();
      if(search == '') {
        this.filterObject[str] = [];
        this.searchwwidData();
     }
      let inputData = this.searchForm.getRawValue();
      inputData['searchColumn'] = str;
      inputData['searchValue'] = this.searchForm.value[str];  
      inputData['wwid'] = this.wwidForm.controls.wwidName.value;
      inputData['functionName'] = "SEARCH";
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        sortingOrder: this.sortingOrder
      };
      Object.assign(inputData, this.searchObj);
      if(inputData['searchValue'].length >= 2){
        this.dbuToolService.wwidBranchAccess(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.filterObject[str] = response.wwidAccessDTOList;
        } else {
          this.filterObject[str] = [];
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    }else if(this.searchForm.value[str].length == 0){
      this.clearSearch(str)
      this.filterObject[str] == [];
      this.filterObject[str].length = 0;

    }
    }
    cussearchFilter(str) {
      let search = (this.customersearchForm.value[str]).toLowerCase();
      if(search == '') {
        this.filterObject[str] = [];
        this.searchwwidData();
      }
      let inputData = this.customersearchForm.getRawValue();
      inputData['searchColumn'] = str;
      inputData['searchValue'] = this.customersearchForm.value[str];  
      inputData['wwid'] = this.wwidForm.controls.wwidName.value;
      inputData['functionName'] = "SEARCH";
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        sortingOrder: this.sortingOrder
      };
      Object.assign(inputData, this.searchObj);
      if(inputData['searchValue'].length >= 2){
        this.dbuToolService.wwidBranchAccess(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.customerfilterObject[str] = response.wwidAccessDTOList;
        } else {
          this.customerfilterObject[str] = [];
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    }
    else if(this.customersearchForm.value[str].length == 0){
      this.clearcusSearch(str)
      this.customerfilterObject[str] == [];
      this.customerfilterObject[str].length = 0;

    }
    }
    getwwidsearchData(){
      if(this.wwidForm.controls.role.value == 'Technical Service Manager'){
        this.searchdata = this.customersearchForm.getRawValue();
      }else{
        this.searchdata = this.searchForm.getRawValue();
      }
      let data = this.searchdata;
      data['pageSize'] = this.pageSize.toString();
      data['currentPage'] = this.currentPage;
      data['columnName'] = this.columnName;
      data['sortingOrder'] = this.sortingOrder;
      data['wwid'] =this.wwidForm.controls.wwidName.value;
      data['functionName'] = "SEARCH";
      this.searchObj = data;
      this.dbuToolService.wwidBranchAccess(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.wwidList = response.wwidAccessDTOList;
          this.totalItems = response.wwidAccessDTOList[0].maxRow;
          this.displayResult = true;
          this.exportToExcelDiv = true;   
          this.isSearchDone = true;
          this.calculateOffset();       
        } else {
          this.wwidList = [];
          // this.displayResult = true;
          this.exportToExcelDiv = false;   
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
  
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    }
    getcuswwidsearchData(){
         let data = this.customersearchForm.getRawValue();
         data['pageSize'] = this.pageSize.toString();
         data['currentPage'] = this.currentPage;
         data['columnName'] = this.columnName;
         data['sortingOrder'] = this.sortingOrder;
         data['wwid'] =this.wwidForm.controls.wwidName.value;
         data['functionName'] = "SEARCH";
         this.searchObj = data;
         this.dbuToolService.wwidBranchAccess(data).subscribe(response => {
           if (response.statusCode == "1") {
             this.wwidList = response.wwidAccessDTOList;
             this.totalItems = response.wwidAccessDTOList[0].maxRow;
             this.displayResult = true;
             this.isSearchDone = true;
             this.exportToExcelDiv = true;   
             this.calculateOffset();       
           } else {
             this.wwidList = [];
             this.exportToExcelDiv = false;   
            //  this.displayResult = true;
             this.alertService.error(response.statusMessage);
             this.scrollToTop = true;
           }
         }, err => {
     
           this.alertService.error(err);
           this.scrollToTop = true;
         }
         );
       }
    closeDeleteModal(){    
      this.deleteModal = false;
    }
  
    openDeleteModal(item){ 
      this.IdtoDelete= item.id;  
      this.deleteModal = true;
    }
  
    deleteRecord(){
      let _data = {
        "functionName": "DELETE",
        "id": this.IdtoDelete,   
      }
      this.dbuToolService.wwidBranchAccess(_data).subscribe(response => {
        if (response.statusCode == "1") {         
           this.successMessage = response.statusMessage;
           this.successModal = true; 
           this.searchwwidData();     
          }else {
            this.alertService.error(response.statusMessage);
            this.scrollToTop = true;
          }
          this.deleteModal = false; 
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
          this.deleteModal = false;
        })
    }
    isSearchable(column) {
      if(column == 'branchName' || column == 'locId' || column == 'region' || 
        column == 'branchCity' || column == 'branchState' || column =='branchCountry' || column =='branchZipCode' || column == 'customerId' || column == 'customerName' || column == 'customerCountry' || column =='customerState' || column =='customerZipCode'){
        return true;
      }else{
        return false;
      }
    }
    iscustomerSearchable(column) {
      if( column == 'customerId' || column == 'customerName'  || column =='customerState' || column =='customerZipCode'){
        return true;
      }else{
        return false;
      }
    }

    closeModal(){
      this.branchNameModal= false;
    }
  
    optionSearch(){
      this.currentPage = 1;
      this.getwwidsearchData();  
    }
  
    clearSearch(str) {
      if (this.searchForm.controls[str].value != '') {
        this.filterObject[str] = [];
        this.searchForm.patchValue({ [str]: '' });
        this.getwwidsearchData();
      }
    }
    cusoptionSearch(){
      this.currentPage = 1;
      this.getwwidsearchData();  
    }
  
    clearcusSearch(str) {
      if (this.customersearchForm.controls[str].value != '') {
        this.customerfilterObject[str] = [];
        this.customersearchForm.patchValue({ [str]: '' });
        this.getwwidsearchData();
      }
    }
    onKey(value) {
      this.wwidTypeListCopyval = [];
      this.selectSearch(value);
    }
    selectSearch(value:string){
      let filter = value.toLowerCase();
      this.wwidTypeListCopy.forEach(element => {
        if(element.WWID.toLowerCase().indexOf(filter) >= 0 || element.firstName.toLowerCase().indexOf(filter) >= 0){
          this.wwidTypeListCopyval.push(element)
        }
      });
    //   let filter = value.toLowerCase();
    //   for ( let i = 0 ; i < this.wwidTypeList.length; i ++ ) {
    //     let option = this.wwidTypeList[i];
    //     if (option.WWID.toLowerCase().indexOf(filter) >= 0) {
    //       this.wwidTypeListCopyval.push( this.wwidTypeList[i] );

    //     }
    //    else if (option.firstName.toLowerCase().indexOf(filter) >= 0) {
    //       this.wwidTypeListCopyval.push( this.wwidTypeList[i] );

    //     }
     
    //  }
    //  if(value == ''){
    //     this.wwidTypeListCopyval = this.wwidTypeListCopyval1;
    //   }

    }
    clearwwidSearch(){
      this.wwidTypeListCopyval = this.wwidTypeList;
    }

    getShowMoreInfo(row, locId: string,branchName:string){
      let _data = {
        "locId": locId,
        "branchName": branchName,
        "columnName": "",
        "currentPage": "",
        "functionName": "SEARCH",
        "pageSize": "",
        "sortingOrder":"desc",
      }
          // console.log(row.expanded + "row value")
      this.customersList=[];
      if (row.expanded === false) {
        this.dbuToolService.branchCustomerMapping(_data).subscribe(response => {
            if (response.statusCode == "1") {
              this.customerData = response.branchCustMappingDTOList;
              this.customerData.forEach(element => {
                // console.log(locId + ""+ element.locId)
                if (locId== element.locId){
                      this.customersList.push(element)   
                }})
              row.expanded = !row.expanded;
            }else {
            this.alertService.error(response.statusMessage);
            this.scrollToTop = true;
            row.expanded = !row.expanded;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    } else {
      row.expanded = !row.expanded;
    }

  }
    
    hideShowMoreInfo(item){
      item.expanded = false;
    }

    exportToExcel() { 
      if((this.wwidForm.controls.wwidName.value !='' && this.wwidForm.controls.wwidName.value !=null)
      && (this.wwidForm.controls.role.value !='' && this.wwidForm.controls.role.value !=null) ){
        this.exceldata = {
          "wwid" : this.wwidForm.controls.wwidName.value,
          "userRole" : this.wwidForm.controls.role.value,
          "functionName" : "EXCEL",
          "sortingOrder" : this.sortingOrder,
          "branchName":this.searchForm.value['branchName'],
          "locId":this.searchForm.value['locId'],
          "region":this.searchForm.value['region'],
          "branchCity":this.searchForm.value['branchCity'],
          "branchState":this.searchForm.value['branchState'],
          "branchCountry":this.searchForm.value['branchCountry'],
          "branchZipCode":this.searchForm.value['branchZipCode'],
          "customerId": this.customersearchForm.value['customerId'],
          "customerName":this.customersearchForm.value['customerName'],
          "customerState":this.customersearchForm.value['customerState'],
          "customerZipCode":this.customersearchForm.value['customerZipCode']
        }
      }else if((this.wwidForm.controls.wwidName.value !='' || this.wwidForm.controls.wwidName.value !=null) &&  (this.wwidForm.controls.role.value =='' || this.wwidForm.controls.role.value ==null )) {
        this.wwidTypeList.forEach(element => {   
            if(element.WWID ==this.wwidForm.controls.wwidName.value){
               this.roleval = element.roleName;
               }  
             });       
         this.exceldata = {
          "wwid" : this.wwidForm.controls.wwidName.value,
          "userRole" :this.roleval,
          "functionName" : "EXCEL",
          "sortingOrder" : this.sortingOrder,
          "branchName":this.searchForm.value['branchName'],
          "locId":this.searchForm.value['locId'],
          "branchCity":this.searchForm.value['branchCity'],
          "branchState":this.searchForm.value['branchState'],
          "branchCountry":this.searchForm.value['branchCountry'],
          "branchZipCode":this.searchForm.value['branchZipCode'],
          "customerId": this.customersearchForm.value['customerId'],
          "customerName":this.customersearchForm.value['customerName'],
          "customerState":this.customersearchForm.value['customerState'],
          "customerZipCode":this.customersearchForm.value['customerZipCode']
        }
      }
   
        this.dbuToolService.wwidBranchAccess(this.exceldata).subscribe(response => {
        if (response.hasOwnProperty('errorMessage') && response.errorMessage == 'body size is too long') {
          this.alertService.error('There are too many records to complete this operation. Fine tune the filters and export the samples');
        } else if (response.statusCode == "1") {
          this.exportExcelData = this.DbuToolService.convertDataUppercase(response.excelDatas);
          this.exportAsExcelFile(this.exportExcelData, this.wwidForm.controls['role'].value+"_"+this.wwidForm.controls['wwidName'].value);
          //console.log(this.exportExcelData + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
  
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    
  }
    public exportAsExcelFile(json: any[], excelFileName: string): void {
      // this.convertHeaderToUppercase(json);
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      // this.wrapAndCenterCell(worksheet.G1);
      const workbook: XLSX.WorkBook = { Sheets: { 'WWID Access': worksheet }, SheetNames: ['WWID Access'] };
      XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
    }
  
    calculateOffset() {
      const result = this.commonHandler.calculatePagers(
        this.totalItems,
        Number(this.pageSize),
        this.currentPage
      );
      this.showRecords = result[0];
      this.maxPage = result[1];
    }
  
    pageSizeChange(val) {
      this.pageSize = val;  
      this.currentPage = 1;
      this.changePage = 1;
      this.loadPage(1);
    }
  
    jumpToPage() {
      if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
        if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {   
          this.currentPage = this.changePage;
          this.loadPage(this.currentPage);
        } else {
          this.changePage = this.currentPage;
          this.alertService.warn(warnMessage.paginatorNotAvail);
          this.scrollToTop = true;
        }
      }
    }
    
      /**
     * Calls search method to sort the data based on columnName
     * @param columnName 
     */
    sortData(columnName) {
      if (this.columnName != columnName) {
        this.sortingOrder = "";
        this.sortComponents._results.forEach(row => row.direction = true);
      }
      if(this.columnName==""){
        this.sortingOrder = "";
        this.sortComponents._results.forEach(row => row.direction = true);
      }
      this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      this.columnName = columnName;
      this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
      this.searchwwidData();
    }
  
    loadPage(page: any) {
      this.currentPage = page;
      this.changePage = page;  
      this.searchwwidData();    
    }  
}