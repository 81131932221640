import { Injectable } from '@angular/core';
import { LoaderService } from '../common/preloader/loader.service';

import { saveAs } from 'file-saver';
import { isNumber } from 'util';
import { SessionTimerService } from 'session-expiration-alert';
import { ConsoleService } from '../service/console.service';
import { DownloadService } from '../service/download.service';



@Injectable({
  providedIn: 'root'
})
export class CommonHandlerService {

  constructor(private loaderService: LoaderService, private download: DownloadService,
     private printOut: ConsoleService,
    private sessionTimerService: SessionTimerService
  ) { }

  downloadAsExcel(inputReqIbj: any, fname: string) {
    this.loaderService.show();
    this.loaderService.add();
    this.sessionTimerService.resetTimer();
    this.printOut.log(JSON.stringify(inputReqIbj));
    this.download.DownloadData(inputReqIbj, fname).subscribe(result => {
      this.printOut.log(result);
      const fileName = fname;
      const date = new Date();
      this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        fileName + '-' + this.convert(date.toDateString()) + '.xlsx');
    });
  }

  convert(datestring): string {
    var date = new Date(datestring),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    var formdate = [mnth, day, date.getFullYear()].join("/");
    return formdate.toString();
  }

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[0]) - 1;
      const date = Number(str[1]);
      return new Date(year, month, date);
    } else if ((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  downloadFile(result: any, type: string, filename: string) {
    const blob = new Blob([result], { type: type });
    const fileUrl = window.URL.createObjectURL(blob);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      saveAs(blob, filename);
    }
    this.loaderService.delete();
    this.loaderService.hide();
  }
/**
 * Calculate page offset for pagination , records to display and maximum records
 * @param totalItems 
 * @param pageSize 
 * @param currentPage 
 */
  calculatePagers(totalItems, pageSize, currentPage): any {
    let tupleType: [string, number];
    let showRecords = '0';
    let maxPage: number;
    let start: any = 0;
    let end: any = totalItems == null ? 0 : parseInt(totalItems, 10);
    const itemsPerPage: number = parseInt(pageSize, 10);
    if (totalItems != null && totalItems != null && totalItems > 0) {
      start = (((currentPage - 1) * (itemsPerPage)) + 1);

      if (itemsPerPage < parseInt(totalItems, 10)) {
        end = itemsPerPage * currentPage;
      }
      if (end > totalItems) {
        end = totalItems;
      }
      showRecords = start + '-' + end + ' of ' + totalItems;
    } else {
      showRecords = '0';
    }
    maxPage = Math.ceil(totalItems / pageSize);
    if (!isNumber(maxPage)) {
      maxPage = 0;
    }
    tupleType = [showRecords, maxPage];
    return tupleType;
  }

}
