import { AfterViewChecked, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AnalyzeSampleGridService } from './analyze-sample-grid.service';
import { UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import * as XLSX from 'xlsx';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE, USERS } from 'src/app/View/common/constants/constant';
import { MatAutocomplete } from '@angular/material';

@Component({
  selector: 'app-analyze-sample-grid',
  templateUrl: './analyze-sample-grid.component.html',
  styleUrls: ['./analyze-sample-grid.component.css']
})
export class AnalyzeSampleGridComponent implements OnInit,AfterViewChecked {
  userRole: string = '';
  exportToExcelDiv: boolean = false;
  scrollToTop =  false;
  readonly USERTYPE = USERTYPE;
  analyzeSampleColumns: any[] = [];
  analyzeSampleColumnsData: any[] = [];
  exportExcelData: any[] = [];
  searchObj: any;
  analysisTypeCount={
    'Engine Oil (Liquid Fuel)':0,
    'Engine Oil (Gaseous Fuel)':0,
    'Coolant':0,
    'Diesel Fuel':0,
    'Diesel Exhaust Fluid':0,
    'Lubricants': 0
  };

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize;
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;

  /* --------Grid Filter variable declaration------- */
  sampleFilterObject = {
    sampledDate: [],
    analyzingDate: [],
    completedDate: [],
    companyId: [],
    sampleId: [],
    // orderId: [],
    qrCodeId: [],
    componentId: [],
    esn: [],
    unitDistance: [],
    status: [],
    companyName: []
  };  

  analyzeSampleSearchForm = new UntypedFormGroup({
    sampledDate: new UntypedFormControl(''),
    analyzingDate: new UntypedFormControl(''),
    completedDate: new UntypedFormControl(''),
    companyId: new UntypedFormControl(''),
    sampleId: new UntypedFormControl(''),
    // orderId: new UntypedFormControl(''),
    qrCodeId: new UntypedFormControl(''),
    componentId: new UntypedFormControl(''),
    esn: new UntypedFormControl(''),
    unitDistance: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    fluidTypeName: new UntypedFormControl(''),
	fluidType: new UntypedFormControl('')
  });

  analysisType: string = '';
  /*displayedChildColumns = [
    { code: "updatedBy", name: "Last Updated By" },
    { code: "updatedDate", name: "Last Updated Date" },
    { code: "analyzingDate", name: "Analyzing Date" },
    { code: "completedDate", name: "Completed Date" }    
  ];*/ 
  tsmCustomerNameDropdown: any;
  isFirstTime: boolean = true;
  messageDropdownTsm: any;
  isFirstTimeDbu: boolean = true;
  messageDropdownDbu: any;
  sortcolumn;
  currentItem: any = [];
  behaviorObs:any;
  behaviorObsTSM:any;
  pagevalue: any;
  companyIdListArr: any[] = [];
  refreshObject: any;
  fluidType: string;
  fluidTypeName: string;
  constructor(private router: Router,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private analyzeSampleService: AnalyzeSampleGridService,
    private sharedService: SharedService) {
      this.pagevalue = JSON.parse(localStorage.getItem('analyzesamplepagesizeObject'));
    if(this.pagevalue !=null){
      this.pageSize = JSON.parse(localStorage.getItem('analyzesamplepagesizeObject'));
    }else{
      this.pageSize ='10'
    }

  }

  @ViewChildren(SortComponent) sortComponents;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  ngOnInit() {

    this.analysisType = this.sharedService.getFluidType();
    this.userRole = this.sharedService.getUserRole();
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    if(this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      this.analyzeSampleSearchForm.patchValue({
        companyName: ''
      })
    }
    if(localStorage.getItem("customerDropdownTSM") && (this.userRole == USERTYPE.TSM)){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")))      
    } else if(localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")))      
    }
    //console.log("this.userRole",this.userRole)
    let sortdata = sessionStorage.getItem('analyzesortData')
    if(sortdata == 'undefined'){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(sortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      this.columnName = this.currentItem.column;
      this.sortingOrder = this.currentItem.sort;
    }
    else{
      this.sortingOrder = 'desc';
      this.columnName = 'sampleId';
    }
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.analyzeSampleSearchForm.patchValue({ status: 'Published' });
      this.analyzeSampleColumns = [
        { code: 'completedDate', name: 'Completed Date' },
        { code: 'sampledDate', name: 'Sampled Date' },
        { code: 'sampleId', name: 'Sample ID' },
        { code: 'qrCodeId', name: 'QR Code' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'esn', name: 'Component SN' },
        { code: 'unitDistance', name: 'Unit Distance' },
        { code: 'action', name: 'Actions' }
      ];
    }
    else if (this.userRole == USERTYPE.CMI_USER || this.userRole == USERTYPE.LAB_ADMIN
     || this.userRole == USERTYPE.TSM || 
      this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.analyzeSampleSearchForm.patchValue({ status: 'Published' });
      this.analyzeSampleColumns = [
        { code: 'completedDate', name: 'Completed Date' },
        { code: 'sampledDate', name: 'Sampled Date' },
        { code: 'companyId', name: 'Company ID' },
        { code: 'companyName', name: 'Company Name' },

        { code: 'sampleId', name: 'Sample ID' },
        { code: 'qrCodeId', name: 'QR Code' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'esn', name: 'Component SN' },
        { code: 'action', name: 'Actions' }
      ];
    }
  
    else {
      let analyzeSample = JSON.parse(sessionStorage.getItem('AnalyzeSampleObject'));
      if(analyzeSample !=null){
        this.analyzeSampleSearchForm.patchValue({sampledDate:analyzeSample['sampledDate']})
        this.analyzeSampleSearchForm.patchValue({analyzingDate:analyzeSample['analyzingDate']})
        this.analyzeSampleSearchForm.patchValue({completedDate:analyzeSample['completedDate']})
        this.analyzeSampleSearchForm.patchValue({companyId:analyzeSample['companyId']})
        this.analyzeSampleSearchForm.patchValue({companyName:analyzeSample['companyName']})
        this.analyzeSampleSearchForm.patchValue({sampleId:analyzeSample['sampleId']})
        // this.analyzeSampleSearchForm.patchValue({orderId:analyzeSample['orderId']})
        this.analyzeSampleSearchForm.patchValue({qrCodeId:analyzeSample['qrCodeId']})
        this.analyzeSampleSearchForm.patchValue({componentId:analyzeSample['componentId']})
        this.analyzeSampleSearchForm.patchValue({esn:analyzeSample['esn']})
        this.analyzeSampleSearchForm.patchValue({unitDistance:analyzeSample['unitDistance']})
        this.analyzeSampleSearchForm.patchValue({status:analyzeSample['status']})
      }else{
      this.analyzeSampleSearchForm.patchValue({ status: 'Analyzing' });
      }
      this.analyzeSampleColumns = [
        { code: 'sampledDate', name: 'Sampled Date' },
        { code: 'analyzingDate', name: 'Analyzing Date' },
        { code: 'completedDate', name: 'Completed Date' },
        { code: 'companyId', name: 'Company ID' },
        { code: 'companyName', name: 'Company Name' },

        { code: 'sampleId', name: 'Sample ID' },
        { code: 'qrCodeId', name: 'QR Code' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'esn', name: 'Component SN' },
        { code: 'unitDistance', name: 'Unit Distance' },
        { code: 'status', name: 'Status' },
        { code: 'action', name: 'Actions' }
      ];
    }

    if((this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)
      && (this.analyzeSampleSearchForm.controls['companyName'].value == '' || this.analyzeSampleSearchForm.controls['companyName'].value == null)){
      this.analyzeSampleColumnsData = [];
      this.totalItems = 0;
      this.calculateOffset();
      this.alertService.error("Please select Company Name");
    } else {
      console.log('test analyze')
      this.getAnalyzeSampleDashboardData();
    }

    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message=>{
      if(this.userRole == USERTYPE.TSM){
        if(this.router.url == '/analyzeSample'){
          if(this.isFirstTime == false){
            this.messageDropdownTsm = message;
            this.recieveMessageTSM(this.messageDropdownTsm);
          }
          this.isFirstTime = false;
        }
      }
    });

    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
        if(this.router.url == '/analyzeSample'){
          if(this.isFirstTimeDbu == false){
            this.messageDropdownDbu = message;
            this.recieveMessageTSM(this.messageDropdownDbu);
          }
          this.isFirstTimeDbu = false;
        }
      }
    });
  }

  ngOnDestroy(){
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
    localStorage.setItem('analyzesamplepagesizeObject', JSON.stringify(this.pageSize));
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  recieveMessageTSM(event){
    this.tsmCustomerNameDropdown = event;
    console.log(this.tsmCustomerNameDropdown);
    
    let companyName = this.tsmCustomerNameDropdown.customerName;
    let companyId = this.tsmCustomerNameDropdown.customerId;
    if( companyId == '' || companyId == null){
      this.analyzeSampleColumnsData = [];
      this.totalItems = 0;
      this.calculateOffset();
      this.alertService.error("Please select Company Name");
      this.analyzeSampleSearchForm.reset();
    } else {
      this.analyzeSampleSearchForm.patchValue({
        companyName: companyName,
        companyId: companyId
        // companyName: 'IDAHO TRUCK SALES',
        // companyId: 'C000927428'
      })
      if((this.isFirstTime == false && this.userRole == USERTYPE.TSM) || (this.isFirstTimeDbu == false && 
        (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS))){
        this.getAnalyzeSampleDashboardData();
      }
    }
    
  }

  getAnalyzeSampleDashboardData() {
	if(this.analyzeSampleSearchForm.value['fluidTypeName'] == 'Engine Oil (Liquid Fuel)' 
    ||this.analyzeSampleSearchForm.value['fluidTypeName'] == 'Lubricants' || this.analyzeSampleSearchForm.value['fluidTypeName'] == 'Engine Oil (Gaseous Fuel)' || this.analyzeSampleSearchForm.value['fluidTypeName'] == 'Coolant' || this.analyzeSampleSearchForm.value['fluidTypeName'] == 'Diesel Fuel' || this.analyzeSampleSearchForm.value['fluidTypeName'] == 'Diesel Exhaust Fluid' || this.userRole == USERTYPE.EXTERNAL || this.userRole ==  USERTYPE.TSM || this.userRole == USERTYPE.CMI_USER || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole == USERTYPE.DBU_BRANCH){
    this.exportToExcelDiv = true;
 	 }
    if (this.userRole == USERTYPE.EXTERNAL || this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.CMI_USER || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.fluidTypeName  = localStorage.getItem("fluidType");
      localStorage.setItem('fluidType', this.analysisType);
      console.log('fluidType', this.fluidTypeName, this.analysisType)
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,

        sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
        analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
        completedDate: this.analyzeSampleSearchForm.value['completedDate'],
        // companyId: this.analyzeSampleSearchForm.value['companyId'],
        companyIdList : this.companyIdListArr,
        sampleId: this.analyzeSampleSearchForm.value['sampleId'],
        // orderId: this.analyzeSampleSearchForm.value['orderId'],
        qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
        esn: this.analyzeSampleSearchForm.value['esn'],
        componentId: this.analyzeSampleSearchForm.value['componentId'],
        unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
        status: this.analyzeSampleSearchForm.value['status'],

        companyName: this.analyzeSampleSearchForm.value['companyName'],
        // userRole: this.sharedService.getIntOrExtUser(),
        userRole: this.userRole,
        fluidType: this.fluidTypeName,
        fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']
        // fluidType: this.sharedService.getFluidType(),
      }
      if (this.userRole == USERTYPE.EXTERNAL) {
        this.searchObj['companyId'] = '';
        this.searchObj['userRole'] = USERS.EXTERNAL;
      } 
      // this.fluidType = this.sharedService.getFluidType();
      // if (this.fluidType == "") {
      //   this.fluidType = FluidTypeName;
      // }
    }
    else if (this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
        analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
        completedDate: this.analyzeSampleSearchForm.value['completedDate'],
        companyId: this.analyzeSampleSearchForm.value['companyId'],
        sampleId: this.analyzeSampleSearchForm.value['sampleId'],
        // orderId: this.analyzeSampleSearchForm.value['orderId'],
        qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
        esn: this.analyzeSampleSearchForm.value['esn'],
        componentId: this.analyzeSampleSearchForm.value['componentId'],
        unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
        status: this.analyzeSampleSearchForm.value['status'],

        companyName: this.analyzeSampleSearchForm.value['companyName'],

        userRole: this.userRole,

        //userRole: this.sharedService.getIntOrExtUser(),
        fluidType: this.sharedService.getFluidType(),
        fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']

      }

    }



    this.analyzeSampleService.getAnalyzeSampleDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.analyzeSampleColumnsData = response.analyseSamplesList;
          this.sampleFilterObject.status = response.statusList;
          this.totalItems = response.maxRow;
          if (this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
            this.analysisTypeCount = response.analysisTypeCount;
          }
          // this.totalItems = 10;
          this.calculateOffset();
        } else {
          this.sampleFilterObject.status = response.statusList;
          this.analyzeSampleColumnsData = [];
          this.totalItems = 0;
          this.calculateOffset();
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  getButtonLabel(status: string) {
    if (status.toLowerCase() === 'Available'.toLowerCase()) {
      return 'Register';
    } else if (status.toLowerCase() === 'Submitted'.toLowerCase()) {
      return 'Review';
    } else if (status.toLowerCase() === 'Analyzing'.toLowerCase()) {
      return 'Analyze';
    } else if (status.toLowerCase() === 'Rerun'.toLowerCase()) {
      return 'View'
    } else {
      return 'View / Download'
    }
  }

  onAssociateClick(item: any) {
    // this.registerSampleService.setItem(item);
    //console.log(JSON.stringify(item));
    localStorage.setItem('registerSampleObject', JSON.stringify(item));
    if (item.status === 'Analyzing') {
      this.router.navigate(['/analyzeSampleInformation']);
    } else if (item.status === 'Published') {
      localStorage.setItem('viewReportData', JSON.stringify(item));
      localStorage.setItem('fluidType', this.analysisType);
      this.router.navigate(['/viewReport']);
    }
    else if ((item.status === 'Rerun')) {
      this.router.navigate(['/analyzeSampleInformation']);
    }
    else {
      if(item.analysisType == 'Engine Oil (Liquid Fuel)'){var fluidType = 1}
      else if(item.analysisType == 'Engine Oil (Gaseous Fuel)'){var fluidType = 2}
      else if(item.analysisType == 'Coolant'){var fluidType = 3}
      else if(item.analysisType == 'Diesel Fuel'){var fluidType = 4}
      else if(item.analysisType == 'Diesel Exhaust Fluid'){var fluidType = 5}

      if(item.componentId){var componentId = true}else{var componentId = false}
      let item1 = { "maxRow":item.maxRow, "status":item.status, "sampleId":item.sampleId, "qrCodeId":item.qrCodeId,
                    "companyId":item.companyId, "companyName":item.companyName, "orderedDate":"2020-11-09",
                    "analysisType":item.analysisType, "fluidType":fluidType, "testLevel":item.testLevel, "recordAdded":componentId }
      // console.log(JSON.stringify(item1));

      localStorage.setItem('registerSampleObject', JSON.stringify(item1));
      this.router.navigate(['registerSample/addEditReviewSample']);
    }
  } 
  
  /* -------------------------ext User filter value dropdown------------------------- */
  searchFilter(str) {
    let search = (this.analyzeSampleSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.sampleFilterObject[str] = [];
      this.searchDashboardDropDownSelect();
    }
    sessionStorage.setItem('AnalyzeSampleObject', JSON.stringify(this.analyzeSampleSearchForm.value));

    let _data = {
      searchColumn: str,
      searchValue: this.analyzeSampleSearchForm.value[str],
      //userRole: this.sharedService.getIntOrExtUser(),
      sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
      analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
      completedDate: this.analyzeSampleSearchForm.value['completedDate'],
      companyId: this.analyzeSampleSearchForm.value['companyId'],
      companyIdList : this.companyIdListArr,
      sampleId: this.analyzeSampleSearchForm.value['sampleId'],
      // orderId: this.analyzeSampleSearchForm.value['orderId'],
      qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
      esn: this.analyzeSampleSearchForm.value['esn'],
      componentId: this.analyzeSampleSearchForm.value['componentId'],
      unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
      status: this.analyzeSampleSearchForm.value['status'],
      companyName: this.analyzeSampleSearchForm.value['companyName'],
      userRole: this.userRole,

      fluidType: this.sharedService.getFluidType(),
      fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']

    };
    if (this.userRole == USERTYPE.EXTERNAL) {
      _data['companyId'] = '';
      _data['userRole'] = USERS.EXTERNAL;

    }
    if (str !== 'status') {
      if (this.analyzeSampleSearchForm.value[str].length >= 2) {
        this.analyzeSampleService.getAnalyzeSampleDashboardData(_data).subscribe(
          response => {
            if (response.statusCode == "1") {
              this.sampleFilterObject[str] = response.distinctValuesList;
            } else {
              this.alertService.error(response.statusMessage);             
              this.sampleFilterObject[str] = [];
              this.alertService.clear();
              window.sessionStorage.removeItem('AnalyzeSampleObject');
            }
          }, err => {
            this.alertService.error(err);
          }
        );       
      }
      else if(this.analyzeSampleSearchForm.value[str].length == 0){
        this.clearSearch(str);
        this.sampleFilterObject[str] == [];
        this.sampleFilterObject[str].length = 0;
      }
    }  
  }

  getColumnCode(str) {
    if (str === 'status') {
      return 'statusName';
    } else {
      return str;
    }
  }

  /* -------------------------search button for filter value selected------------------------- */
  searchDashboard() {
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
      analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
      completedDate: this.analyzeSampleSearchForm.value['completedDate'],
      companyId: this.analyzeSampleSearchForm.value['companyId'],
      companyIdList : this.companyIdListArr,
      sampleId: this.analyzeSampleSearchForm.value['sampleId'],
      // orderId: this.analyzeSampleSearchForm.value['orderId'],
      qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
      esn: this.analyzeSampleSearchForm.value['esn'],
      componentId: this.analyzeSampleSearchForm.value['componentId'],
      unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
      status: this.analyzeSampleSearchForm.value['status'],
      companyName: this.analyzeSampleSearchForm.value['companyName'],

      // userRole: this.sharedService.getIntOrExtUser(),
      userRole: this.userRole,

      fluidType: this.sharedService.getFluidType(),
      fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']

    };
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj['companyId'] = '';
      this.searchObj['userRole'] = USERS.EXTERNAL;

    }

    this.analyzeSampleService.getAnalyzeSampleDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.analyzeSampleColumnsData = response.analyseSamplesList;
          this.totalItems = response.maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.analyzeSampleColumnsData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }


  searchDashboardDropDownSelect() {
    this.autocomplete.showPanel; 
    sessionStorage.setItem('AnalyzeSampleObject', JSON.stringify(this.analyzeSampleSearchForm.value));      
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: "1",
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
      analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
      completedDate: this.analyzeSampleSearchForm.value['completedDate'],
      companyId: this.analyzeSampleSearchForm.value['companyId'],
      companyIdList : this.companyIdListArr,
      sampleId: this.analyzeSampleSearchForm.value['sampleId'],
      // orderId: this.analyzeSampleSearchForm.value['orderId'],
      qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
      esn: this.analyzeSampleSearchForm.value['esn'],
      componentId: this.analyzeSampleSearchForm.value['componentId'],
      unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
      status: this.analyzeSampleSearchForm.value['status'],

      companyName: this.analyzeSampleSearchForm.value['companyName'],


      userRole: this.userRole,
      // userRole: this.sharedService.getIntOrExtUser(),
      fluidType: this.sharedService.getFluidType(),
      fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']

    };
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj['companyId'] = '';
      this.searchObj['userRole'] = USERS.EXTERNAL;
    }

    this.analyzeSampleService.getAnalyzeSampleDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.analyzeSampleColumnsData = response.analyseSamplesList;
          this.totalItems = response.maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else if (response.statusCode == "-1") {
          this.analyzeSampleColumnsData = [];
          this.currentPage = 0;
          this.changePage = 0;
          this.showRecords = 0;
          this.totalItems = 0;
          this.loadPage(1);
        } else {
          this.alertService.error(response.statusMessage);
          this.analyzeSampleColumnsData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }



  /* -------------------- Clear search------------- */
  clearSearch(str) {
    //console.log(this.analyzeSampleSearchForm.value);
    if (this.analyzeSampleSearchForm.controls[str].value != '') {

      this.analyzeSampleSearchForm.patchValue({ [str]: '' });
      if (str !== 'status') {
        this.sampleFilterObject[str] = [];
      }
      this.searchDashboard();
    }
  }
  clearSearchfilters(){
    this.alertService.clear();
    this.pageSize='10';
    window.sessionStorage.removeItem('AnalyzeSampleObject');    
    this.analyzeSampleSearchForm.patchValue({sampledDate:''})
    this.analyzeSampleSearchForm.patchValue({companyId:''})
    this.analyzeSampleSearchForm.patchValue({companyName:''})
    this.analyzeSampleSearchForm.patchValue({sampleId:''})
    // this.analyzeSampleSearchForm.patchValue({orderId:''})
    this.analyzeSampleSearchForm.patchValue({qrCodeId:''})
    this.analyzeSampleSearchForm.patchValue({status:''})
    this.analyzeSampleSearchForm.patchValue({analyzingDate:''})
    this.analyzeSampleSearchForm.patchValue({esn:''})
    this.analyzeSampleSearchForm.patchValue({componentId:''})
    this.analyzeSampleSearchForm.patchValue({unitDistance:''})
    this.analyzeSampleSearchForm.patchValue({status:'Analyzing' });    
    this.searchDashboard();
    this.loadPage(1);
  }
 
  /* ------------------------Export To Excel Service Call ---------------- */

  exportToExcel() {

    let req;
    if (this.userRole === USERTYPE.EXTERNAL) {
      req = {
        userRole: USERS.EXTERNAL,
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
        analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
        completedDate: this.analyzeSampleSearchForm.value['completedDate'],
        // companyId: this.sharedService.getCompanyId(),
        companyId: '',
        companyIdList : this.companyIdListArr,
        sampleId: this.analyzeSampleSearchForm.value['sampleId'],
        // orderId: this.analyzeSampleSearchForm.value['orderId'],
        qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
        componentId: this.analyzeSampleSearchForm.value['componentId'],
        esn: this.analyzeSampleSearchForm.value['esn'],
        unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
        status: this.analyzeSampleSearchForm.value['status'],
        companyName: this.analyzeSampleSearchForm.value['companyName'],

        fluidType: this.sharedService.getFluidType(),
		
        fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']

      }
      // req.userRole = this.sharedService.getIntOrExtUser();
    }
    else {
      req = {
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        sampledDate: this.analyzeSampleSearchForm.value['sampledDate'],
        analyzingDate: this.analyzeSampleSearchForm.value['analyzingDate'],
        completedDate: this.analyzeSampleSearchForm.value['completedDate'],
        companyId: this.analyzeSampleSearchForm.value['companyId'],
        sampleId: this.analyzeSampleSearchForm.value['sampleId'],
        // orderId: this.analyzeSampleSearchForm.value['orderId'],
        qrCodeId: this.analyzeSampleSearchForm.value['qrCodeId'],
        componentId: this.analyzeSampleSearchForm.value['componentId'],
        esn: this.analyzeSampleSearchForm.value['esn'],
        unitDistance: this.analyzeSampleSearchForm.value['unitDistance'],
        status: this.analyzeSampleSearchForm.value['status'],
        companyName: this.analyzeSampleSearchForm.value['companyName'],
		// fluidType: this.analyzeSampleSearchForm.value['fluidTypeName'],
        fluidType: this.sharedService.getFluidType(),
        userRole: this.userRole,
        fluidTypeName: this.analyzeSampleSearchForm.value['fluidTypeName']
      }
      if (this.userRole == USERTYPE.EXTERNAL) {
        req['userRole'] = USERS.EXTERNAL;
      }
    }

    if(this.userRole == USERTYPE.DATA_SCIENTIST ||  this.userRole == USERTYPE.FLUIDWATCH_MANAGER){
      req['fluidTypeName'] = this.analyzeSampleSearchForm.value['fluidTypeName'];
    }else{
      req['fluidTypeName'] = this.sharedService.getFluidType();
    }


    this.analyzeSampleService.exportToExcel(req).subscribe(
      response => {
        if (response.hasOwnProperty('errorMessage') && response.errorMessage == 'body size is too long') {
          this.alertService.error('There are too many records to complete this operation. Fine tune the filters and export the samples');
        } else if (response.statusCode == "1") {
          this.exportExcelData = this.convertDataUppercase(response.excelDatas);
          console.log(this.exportExcelData);
          
          this.exportAsExcelFile(this.exportExcelData, 'Sample_Report');
          //console.log(this.exportExcelData + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.analyzeSampleColumnsData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );

  }

  convertDataUppercase(excelData: any) {
    let upperCaseArrayData = excelData.map(obj => {
      let newObj = {};
      for(let key in obj) {
        if(key == 'Analysis Comments' || key == 'Action Comments'){
          newObj[key] = obj[key]
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
        } else {
          newObj[key] = typeof obj[key] === 'string' ? obj[key].toUpperCase() : obj[key];          
        }
      }
      return newObj;
    });
    console.log(upperCaseArrayData);
    return upperCaseArrayData;
  }

  /* ----------------------- Export To Excel----------------------- */
  // convertHeaderToUppercase(data) {
  //   for (var i = 0; i < data.length; i++) {
  //     for (var key in data[i]) {
  //       if (key.toUpperCase() !== key) {
  //         data[i][key.toUpperCase()] = data[i][key];
  //         delete data[i][key];
  //       }
  //     }
  //   }
  // }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // this.convertHeaderToUppercase(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // this.wrapAndCenterCell(worksheet.G1);
    const workbook: XLSX.WorkBook = { Sheets: { 'Sample Report': worksheet }, SheetNames: ['Sample Report'] };
    XLSX.writeFile(workbook, `${excelFileName}_export.xlsx`);
  }

  // private wrapAndCenterCell(cell: XLSX.CellObject) {
  //   const wrapAndCenterCellStyle = { alignment: { wrapText: true, vertical: 'center', horizontal: 'center' } };
  //   this.setCellStyle(cell, wrapAndCenterCellStyle);
  // }

  // private setCellStyle(cell: XLSX.CellObject, style: {}) {
  //   cell.s = style;
  // }



  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.analyzeSampleColumnsData = [];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('analyzesamplepagesizeObject', JSON.stringify(this.pageSize));
    if(this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      if(this.analyzeSampleSearchForm.value['companyName']){
        this.loadPage(1)
      }
    } else {
      this.loadPage(1);
    }    
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.analyzeSampleColumnsData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
      }
    }
  }

  searchLength(filterObject: any) {
    if (filterObject.companyId.length > 0 || filterObject.esn.length > 0 || filterObject.qrCodeId.length > 0 ||
      filterObject.sampleId.length > 0 || filterObject.sampledDate.length > 0 || filterObject.unitDistance.length > 0 ||
      filterObject.componentId.length > 0 || filterObject.analyzingDate.length > 0 || filterObject.completedDate.length > 0
      || filterObject.companyName.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;

    let val = this.searchLength(this.sampleFilterObject)

    if (val === true) {
      this.searchDashboard();
    }
    else {
      this.getAnalyzeSampleDashboardData();
    }

  }



  getArray(): any[] {
    return Array(this.maxPage);
  }

  /**
 * Calls search method to sort the data based on columnName
 * @param columnName 
 */
  sortData(columnName) {
    let existingsortdata= sessionStorage.getItem('analyzesortData');
    if(existingsortdata == 'undefined' || existingsortdata == null || existingsortdata== ""){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(existingsortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      if(this.currentItem.sort == 'asc'){
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      }else{
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);

      }
    }
    this.sortcolumn=  this.sortingOrder != 'asc' ? 'asc' : 'desc';
    let sorting ={column : columnName, sort :this.sortcolumn,direction:this.sortcolumn};
    sessionStorage.setItem('analyzesortData', JSON.stringify(sorting));
   
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.getAnalyzeSampleDashboardData();
  }

  expandRow(row) {
    row.expanded = !row.expanded;
  }

  getDynamicClass(columnName) {
    let temp = '';
    if (this.userRole == USERTYPE.DATA_SCIENTIST) {
      temp = 'DataScientist'
    } else if (this.userRole == USERTYPE.CMI_USER) {
      temp = 'Cmi'
    } else if (this.userRole == USERTYPE.LAB_ADMIN) {
      temp = 'Lab'
    } else if (this.userRole == USERTYPE.EXTERNAL) {
      temp = 'Ext'
    }
    return columnName + temp;
  }

  fluidTypeSelect(fluidTypeName){
    this.analyzeSampleSearchForm.patchValue({
      fluidTypeName: fluidTypeName,
      status: 'Analyzing'
    });
    this.getAnalyzeSampleDashboardData();
  }

  isIconSelected(fluidType){
    if(this.analyzeSampleSearchForm.controls.fluidTypeName.value == fluidType){
      return true;
    }else{
      return false;
    }    
  } 

}
