import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadUnitService {

  constructor(private api: ApiHandlerService) { }
  

  bulkUpload(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/unitIdBulkUpload', data);
  }

  unitIdTemplate(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/unitIdTemplateDownload', data);
  }

  getEquipmentDropdownData(userRole: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getEquipmentDropdownData', userRole);
  }
}
