import { Component, OnInit } from '@angular/core';
import { USERTYPE } from 'src/app/View/common/constants/constant';
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})
export class GridComponent implements OnInit {

  analyzeSampleColumns: any[] = [];
  analyzeSampleColumnsData: any[] = [];

  constructor() { }

  ngOnInit() {
    this.analyzeSampleColumns = [
      { code: 'roleName', name: 'Role Name' },
      { code: 'wwid', name: 'WWID' },
      { code: 'userName', name: 'User Name' }
    ];

    this.analyzeSampleColumnsData = [
      {
        roleName: USERTYPE.DATA_SCIENTIST,
        wwid: 'RQ090',
        userName: 'Mohit Sahu'
      },
      {
        roleName: USERTYPE.LAB_ADMIN,
        wwid: 'RW561',
        userName: 'Abhishek Kumar'
      },
      {
        roleName: USERTYPE.CMI_USER,
        wwid: 'OT475',
        userName: 'Harikrishnan'
      },
      {
        roleName: USERTYPE.DATA_SCIENTIST,
        wwid: 'PB475',
        userName: 'Selvi Shanmugam'
      }
    ]
  }

}
