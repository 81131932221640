import { Injectable } from '@angular/core';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AnalyzeSampleInformationService {

  constructor(private api: ApiHandlerService, private http: HttpClient) { }

  getOilAndMetalInfo(reqData: any) {
    return this.api.post(environment.apiUrl + '/getRecentSamplesOilAndMetalInfo', reqData);
  }

  applyPopulation(reqData) {
    return this.api.post(environment.apiUrl + '/applyPopulation', reqData);
  }

  publishReport(reqData: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/updateForPublishReport', reqData);
  }

  getAutoComments(reqData) {
    return this.api.post(environment.apiUrl + '/getAutoComments', reqData)
  }

  getShowMoreInfo(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getMoreSampleInfo', data);
  }

  setRankToGraph(data) {
    return this.api.post(environment.apiUrl + '/updateForPublishReport', data)
  }

  updateRerun(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/updateRerunStatus ', data);
  }

  saveRerunParameter(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/saveRerunParameter ', data);    
  }

  getWinlimsAuthToken(data: any, options: any): Observable<any>{
    return this.api.postWithHeader(environment.winlimsTokenUrl , data, options); 
  }

  winlimStatusUpdate(data: any, options: any): Observable<any>{
    return this.http.patch(environment.winlimsUpdateUrl , data, options); 
  }

  getAstmData(data: any): Observable<any>{
    return this.http.post(environment.apiUrl + '/getAstmData', data); 
  }

  middlewareLogs(data: any):Observable<any>{
    return this.http.post(environment.apiUrl + '/middlewareLogs', data);
  }
  
  getAuthToken(): Observable<any> {
    let authData = {
      "client_id": environment.winlimsAuthClientId,
      "client_secret": environment.winlimsAuthClientSecret,
      "resource": environment.winlimsAuthResource      
    }

    let authOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'origin':environment.origin
      })
    };

    return this.getWinlimsAuthToken(authData, authOptions);
  }


  winlimsUpdate(authRes,checkedListrerun, sampleId, orderId ,skuNumber,  qrCodeId, flag,
    fluidBrand, fluidGrade, fluidProduct
  ): Observable<any> {
    let checkedList = checkedListrerun.toString(); 
    let updateOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'client_id': environment.winlimsUpdateClientId,
        'client_secret': environment.winlimsUpdateClientSecret,
        'origin':environment.origin
      })
    };
    let updateData = {
      "Header": {
        "Sender": {
          "ServiceName": "updateSampleIdService",
          "SenderID": "COAT"
        },
        "Target": {
          "TargetID": "WINLIMS"
        }
      },
      "DataArea": {
        "SampleId": sampleId,
        "OrderId": orderId,
        "SkuNumber": skuNumber,
        "Flag": flag,
        "QrCode": qrCodeId,
        "RerunParameter": checkedList,
        "FluidBrand": fluidBrand,
        "FluidGrade": fluidGrade,
        "FluidProduct": fluidProduct
      }
    };
    updateOptions.headers = updateOptions.headers.append('Authorization', authRes.token_type + ' ' + authRes.access_token);
    return this.winlimStatusUpdate(updateData, updateOptions);

  }
}
