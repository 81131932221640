import { Component, OnInit, Input } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Observable, Subject, Subscription } from 'rxjs/Rx'; import 'rxjs/Rx';

import { OnDestroy } from '@angular/core';
import { Alert, AlertType } from './alert';
import { AlertService } from './alert.service';



@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

    @Input() id: string;
    public showMsgBox = false;
    private subscription: Subscription;
    private timer: Observable<any>;
    alerts: Alert[] = [];
    constructor(private alertService: AlertService) { }
    ngOnInit() {
        this.alertService.getAlert(this.id).subscribe((alert: Alert) => {
            if (!alert.message) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }

            // add alert to array
            this.alerts = [];
            this.alerts.push(alert);
            this.setTimer();
        });
    }
    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }
    cssClass(alert: Alert) {

        if (!alert) {
            return;
        }
        // return css class based on alert type
        switch (alert.type) {

            case AlertType.Success:
                return 'success';
            case AlertType.Error:
                return 'danger';
            case AlertType.Info:
                return 'info';
            case AlertType.Warning:
                return 'warning';
        }
    }

    public setTimer() {
        // set showloader to true to show loading div on view
        this.showMsgBox = true;
        this.timer = Observable.timer(300000); // 5000 millisecond means 5 seconds
        this.subscription = this.timer.subscribe(() => {
            // set showloader to false to hide loading div from view after 5 seconds
            this.showMsgBox = false;
        });
    }
    ngOnDestroy() {
        if (this.subscription && this.subscription instanceof Subscription) {
            this.subscription.unsubscribe();
        }
        this.alerts = [];
    }

}
