import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { DbuToolService } from '../dbu-tool.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-branch-customer-mapping',
  templateUrl: './branch-customer-mapping.component.html',
  styleUrls: ['./branch-customer-mapping.component.css']
})
export class BranchCustomerMappingComponent implements OnInit,AfterViewChecked {
  readonly USERTYPE = USERTYPE;

  @ViewChildren(SortComponent) sortComponents;  
  isSearchDone: boolean = false;    
  isNewBranchCustomerdata:boolean=false;
  dbubranchNameList = [];
  customerNameList = []; 
  displayResult = false;
  latestCogs:any;
  scrollToTop =  false;
  addModal = false;   
  searchObj:any; 
  dbuBranchCustomerSearchData:any =[];
  branchcustomerSearchData:any = [];
  deleteBranchCustomerdataModal =false;
  addBranchCustomerdataModal = false;
  selectedBranchCustomerdataGroup:UntypedFormGroup;
  successMessage: string = '';  
  successModal = false;
  errorModal=false;
  registrationUser:any;
  locId :any;
  filterObject = {
    branchName: [],
    locId: [],
    region: [],
    customerName: [],
    customerId: [],
    customerState: [],
    customerCountry: [],
    customerZipCode: []
  };

  branchcustomerdataForm=new UntypedFormGroup({
    branchName: new UntypedFormControl('', [Validators.required]),
    customerName: new UntypedFormControl('', [Validators.required]),

  });   

  addBranchCustomerdataForm=new UntypedFormGroup({
    branchName: new UntypedFormControl('', ),
    locId: new UntypedFormControl('', [Validators.required]),
    region: new UntypedFormControl(''),    
    customerId: new UntypedFormControl('', [Validators.required]),
    customerName: new UntypedFormControl(''),
    customerState: new UntypedFormControl(''),
    customerCountry: new UntypedFormControl(''),
    customerZipCode: new UntypedFormControl('')
  });
  searchForm = new UntypedFormGroup({
    branchName: new UntypedFormControl(''),
    locId: new UntypedFormControl(''),
    region: new UntypedFormControl(''),
    customerName: new UntypedFormControl(''),
    customerId: new UntypedFormControl(''),
    customerState: new UntypedFormControl(''),
    customerCountry: new UntypedFormControl(''),
    customerZipCode:new UntypedFormControl(''),
  });

    /* ------Pagination variables declaration--------- */
    pageSizeOptions: any[] = common.pageSizeOptions;
    pageSize = "10";
    currentPage = 1;
    showRecords = 0;
    maxPage = 0;
    totalItems: any;
    changePage: any = 1;
    rowDetails: any;
    sortingOrder: string;
    columnName: string;
    searchOption: any;  
    locIdtoDelete: any;
    cusIdtoDelete:any
    customerId:any;
    customerflag:string;
    branchflag:string;
    oldlocId;
    oldcusId;
    branchcustomerColumn = [
      { code: 'branchName', name: 'Branch Name' },
      { code: 'locId', name: 'Branch LOC ID' },
      { code: 'region', name: 'Region' },
      { code: 'customerId', name: 'Customer ID' },
      { code: 'customerName', name: 'Customer Name' },
      { code: 'customerState', name: 'Customer State' },
      { code: 'customerCountry', name: 'Customer Country' },
      { code: 'customerZipCode', name: 'Customer Zip Code' },
      { code: 'action', name: 'Action' }
    ];
  dataToSend: any;
  saveBranchcustomerdataModal = false;
  region;
  customerName;
  customerState;
  customerCountry;
  customerZipCode;
  branchName;
  data;
  exceldata;
  locIdHasListedError:boolean = false;
  locIdList;
  locArray = new Array();
  locIdArray = [];
  dbubranchNameListCopy:any;
  customerNameListCopy:any;
  myArray = [];
  searchdata;
  userRole: string = '';
  column:any;
  exportToExcelDiv: boolean = false;
  exportExcelData: any[] = [];
  constructor( private commonHandler: CommonHandlerService,
    private sharedService: SharedService,
    private DbuToolService:DbuToolService,
    private alertService: AlertService
    ) { }

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();
    this.columnName = '';
    this.sortingOrder = 'asc';
    this.displayResult = false;
    this.getBranchCustomerDropdown();
    this.getlocIdDropdown();
    this.registrationUser = JSON.parse(localStorage.getItem('userInformation'));
    if(this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.LAB_ADMIN ||  this.userRole === USERTYPE.DBU_BRANCH){
      this.branchcustomerColumn = [
        { code: 'branchName', name: 'Branch Name' },
        { code: 'locId', name: 'Branch LOC ID' },
        { code: 'region', name: 'Region' },
        { code: 'customerId', name: 'Customer ID' },
        { code: 'customerName', name: 'Customer Name' },
        { code: 'customerState', name: 'Customer State' },
        { code: 'customerCountry', name: 'Customer Country' },
        { code: 'customerZipCode', name: 'Customer Zip Code' },
      ];
    }
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getlocIdDropdown(){
    let _data = {
      "functionName": "DROPDOWN",
    }
    this.DbuToolService.branchCustomerMapping(_data).subscribe(response => {
      for (var i=0; i < response.dbuBranchList.length; i++) {
        this.locIdList = response.dbuBranchList[i].locId;
        this.myArray.push(this.locIdList)
      }
    },err => {
        this.alertService.error(err);        
    });
  }
  
  getBranchCustomerDropdown(){
    let _data = {
      "functionName": "DROPDOWN",
    }
    this.DbuToolService.branchCustomerMapping(_data).subscribe(response => {
      this.dbubranchNameList = response.dbuBranchList;
      this.customerNameList = response.customerList;
      this.dbubranchNameListCopy = response.dbuBranchList;
      this.customerNameListCopy = response.customerList;

    },err => {
        this.alertService.error(err);        
    });
  }
  // getBranchCustomerName(){
  //   let branchName = '';
  //   // console.log( this.dbubranchNameList)
  //   this.dbubranchNameList.forEach((row) =>{
  //     console.log( row.branchName)
  //     console.log(this.branchcustomerdataForm.controls['branchName'])

  //     if(row.branchName == this.branchcustomerdataForm.controls['branchName']){
  //       branchName = row.branchName;
  //       console.log('kkkk')
  //     }else if(row.customerId == this.branchcustomerdataForm.controls['customerId']){
  //       branchName = row.branchName;
  //     }
  //   });
  //   return branchName;
  // }
  inputChange(){    
    this.displayResult = false;
    this.isNewBranchCustomerdata = false;
  }
  inputCustomerName(){    
    // this.branchcustomerdataForm.value.branchName = '';
    //this.branchcustomerdataForm.patchValue({branchName:''});
 
    this.branchcustomerdataForm.controls['branchName'].disable();
    this.customerNameList.forEach(element => {
      if(element.customerId == this.branchcustomerdataForm.controls.customerName.value){
        this.customerName =  element.customerName;
        this.data = this.branchcustomerdataForm.getRawValue();
        this.searchObj = this.data;
        this.data = {
          "customerId" : element.customerId,
          "columnName" : this.columnName,
          "customerName" : element.customerName,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder
        }
      }
    });
  }
  inputBranchName(){
    //this.branchcustomerdataForm.patchValue({cutomerName: ''})
    this.branchcustomerdataForm.controls['customerName'].disable();
    this.dbubranchNameList.forEach(element => {
      if(element.locId == this.branchcustomerdataForm.controls.branchName.value){
        this.branchName =  element.branchName;
        this.data = this.branchcustomerdataForm.getRawValue();
        this.searchObj = this.data;
        this.data = {
          "locId" : element.locId,
          "columnName" : this.columnName,
          "branchName" : element.branchName,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder
        }
      }
    });

  }
  inputLocationId(){
    this.dbubranchNameList.forEach(element => {
      if(element.locId == this.addBranchCustomerdataForm.controls.locId.value){
          this.region = element.region;
          this.branchName = element.branchName;
      }
  })
  }
  inputCustomerId(){
    this.customerNameList.forEach(element => {
      if(element.customerId == this.addBranchCustomerdataForm.controls.customerId.value){
          this.customerName = element.customerName;
          this.customerState = element.customerState;
          this.customerCountry = element.customerCountry;
          this.customerZipCode = element.customerZipCode;
      }
  })
  }

  validatelocIdDropdown(){
    let locId;
    for (var i=0; i < this.dbuBranchCustomerSearchData.length; i++) {
      this.locIdList = this.dbuBranchCustomerSearchData[i].locId;
      this.myArray.push(this.locIdList)
    }
    locId  =  this.myArray.find(i => i == this.addBranchCustomerdataForm.controls["locId"].value.toUpperCase()) ;
    if(locId == this.addBranchCustomerdataForm.controls["locId"].value.toUpperCase() ){
      this.locIdHasListedError = true;
    }else{
      this.locIdHasListedError = false;
    }
   }
   onKeylocId(value){
    this.locArray= [];
    this.selectSearchlocId(value);

  }
  selectSearchlocId(value:string){
    let filter = value.toLowerCase();
    for(let i = 0; i < this.locIdArray.length; i++ ){
      let option = this.locIdArray[i];
      if(option.toLowerCase().indexOf(filter) >= 0){
        this.locArray.push(option)
      }
    }  

  }
  clearSearchlocId(){
    this.locArray = this.locIdList;
  }

  searchBranchcustomer(){
    this.columnName = '';
    this.sortingOrder = '';
    this.addBranchCustomerdataForm.reset();
    this.currentPage = 1;
    this.isNewBranchCustomerdata = false;
    this.searchForm.reset();
    //backspace clearing
    this.filterObject = { branchName: [], locId: [], region: [], customerName: [], customerId: [], customerState: [], customerCountry: [], customerZipCode: []
    }
    this.searchBranchCustomerData();
    this.sortData('');
  
  }
  getbranchCustomerData(){
    // let data = this.searchForm.getRawValue();
    // data['pageSize'] = this.pageSize.toString();
    // data['currentPage'] = this.currentPage;
    // data['columnName'] = this.columnName;
    // data['sortingOrder'] = this.sortingOrder;
    // data['functionName'] = "SEARCH";
    this.customerNameList.forEach(element => {
      if(element.customerId == this.branchcustomerdataForm.controls.customerName.value){
        this.branchName =  element.branchName;
        this.data = this.branchcustomerdataForm.getRawValue();
        this.searchObj = this.data;
        this.data = {
          "customerId" : element.customerId,
          "columnName" : this.columnName,
          "customerName" : element.customerName,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder
        }
      }
    });
    this.dbubranchNameList.forEach(element => {
      if(element.locId == this.branchcustomerdataForm.controls.branchName.value){
        this.branchName =  element.branchName;
        this.data = this.branchcustomerdataForm.getRawValue();
        this.searchObj = this.data;
        this.data = {
          "locId" : element.locId,
          "columnName" : this.columnName,
          "branchName" : element.branchName,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder
        }
      }
    });
    this.searchObj = this.data;
    this.DbuToolService.branchCustomerMapping(this.data).subscribe(response => {
      if (response.statusCode == "1") {
        this.dbuBranchCustomerSearchData = response.branchCustMappingDTOList;
        this.totalItems = response.branchCustMappingDTOList[0].maxRow;
        this.displayResult = true;
        this.isSearchDone = true;
        this.exportToExcelDiv = true;   
        this.calculateOffset();       
      } else {
        this.dbuBranchCustomerSearchData = [];
        // this.displayResult = true;
        this.exportToExcelDiv = false;   
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }
  searchBranchCustomerData(){   
    this.DbuToolService.branchCustomerMapping(this.data).subscribe(response => {
      if (response.statusCode == "1") {
        this.dbuBranchCustomerSearchData = response.branchCustMappingDTOList;
        this.totalItems = response.branchCustMappingDTOList[0].maxRow;
        this.displayResult = true;
        // this.isSearchDone = true;
        this.searchForm.reset();
        this.exportToExcelDiv = true;
        this.calculateOffset();
      }else{
        this.dbuBranchCustomerSearchData = [];
        this.isNewBranchCustomerdata = false;
        this.displayResult = false;
        this.exportToExcelDiv = false;
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    },err => {
        this.alertService.error(err);    
        this.scrollToTop = true;    
    });
  }


  searchbranchcustomerData(){
    // let data = this.searchForm.getRawValue();
    // data['pageSize'] = this.pageSize.toString();
    // data['currentPage'] = this.currentPage;
    // data['columnName'] = this.columnName;
    // data['sortingOrder'] = this.sortingOrder;
    // data['customerName1'] = this.customerName;
    // data['branchName'] = this.branchName;
    // data['functionName'] = "SEARCH";
    this.customerNameList.forEach(element => {
      if(element.customerId == this.branchcustomerdataForm.controls.customerName.value){
      this.searchdata = this.searchForm.getRawValue();
        this.searchdata = {
          "columnName" : this.columnName,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder,
          "branchName" : this.searchForm.controls.branchName.value,
          "locId":this.searchForm.controls.locId.value,
          "region":this.searchForm.controls.region.value,
          "customerCountry":this.searchForm.controls.customerCountry.value,
          "customerId":element.customerId,
          "customerName" : element.customerName,
          "customerState":this.searchForm.controls.customerState.value,
          "customerZipCode":this.searchForm.controls.customerZipCode.value,
        }
      }
    });
    this.dbubranchNameList.forEach(element => {
      if(element.locId == this.branchcustomerdataForm.controls.branchName.value){
        this.searchdata = this.searchForm.getRawValue();
        this.searchdata = {
          "columnName" : this.columnName,
          "branchName" : element.branchName,
          "functionName" : "SEARCH",
          "currentPage": this.currentPage,
          "pageSize": this.pageSize.toString(),
          "sortingOrder" : this.sortingOrder,
          "locId":element.locId,
          "region":this.searchForm.controls.region.value,
          "customerCountry":this.searchForm.controls.customerCountry.value,
          "customerId":this.searchForm.controls.customerId.value,
          "customerName" :this.searchForm.controls.customerName.value,
          "customerState":this.searchForm.controls.customerState.value,
          "customerZipCode":this.searchForm.controls.customerZipCode.value,
        }
      }
    });
    this.searchObj = this.searchdata;
    this.DbuToolService.branchCustomerMapping(this.searchdata).subscribe(response => {
      if (response.statusCode == "1") {
        this.dbuBranchCustomerSearchData = response.branchCustMappingDTOList;
        this.totalItems = response.branchCustMappingDTOList[0].maxRow;
        this.displayResult = true;
        this.isSearchDone = true;
        this.calculateOffset();
      }else{
        this.dbuBranchCustomerSearchData = [];
        this.displayResult = true;
        this.isNewBranchCustomerdata = false;
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    },err => {
        this.alertService.error(err);    
        this.scrollToTop = true;    
    });
  }

  clearBranchcustomer(){
    this.branchcustomerdataForm.controls['branchName'].enable();
    this.branchcustomerdataForm.controls['customerName'].enable();
    this.branchcustomerdataForm.reset();
    this.displayResult = false;
    this.exportToExcelDiv = false;
    this.isSearchDone = false;
  }

  addNewBranchCustomerdata(){
    this.dbubranchNameListCopy = this.dbubranchNameList;
    this.customerNameListCopy = this.customerNameList;
    this.addBranchCustomerdataForm.reset();
    this.isNewBranchCustomerdata = true;
    this.branchName = '';
    this.customerName = '';
    this.customerState = '';
    this.customerZipCode = '';
    this.customerCountry = '';
    this.region = '';
  }

  hideNewBranchCustomerdata(){
    this.isNewBranchCustomerdata = false;
    this.branchName = '';
    this.customerName = '';
    this.customerState = '';
    this.customerZipCode = '';
    this.customerCountry = '';
    this.region = '';
    this.addBranchCustomerdataForm.reset();
  }

  openAddBranchCustomerdataModal(){
    this.addBranchCustomerdataForm.reset;
    if(!this.addBranchCustomerdataForm.invalid){
      this.addBranchCustomerdataModal = true;
    }else{
      this.addBranchCustomerdataModal = false;      
      Object.keys(this.addBranchCustomerdataForm.controls).forEach(field => { 
        const control = this.addBranchCustomerdataForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }    
  }

  closeAddBranchCustomerdataModal(){
    this.addBranchCustomerdataModal = false;
  }

  addBranchCustomerdata(){
      let data=this.addBranchCustomerdataForm.getRawValue();
      data["functionName"] = "ADD";
      data["updatedBy"] = this.registrationUser.wwid;
      this.DbuToolService.branchCustomerMapping(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.successMessage = response.statusMessage;
          this.successModal = true;        
          this.isNewBranchCustomerdata = false;
          this.addBranchCustomerdataForm.reset();
          this.searchBranchCustomerData();
          this.branchName = '';
          this.customerName = '';
          this.customerState = '';
          this.customerZipCode = '';
          this.customerCountry = '';
          this.region = '';
        }else {
          this.successMessage = response.statusMessage;
          this.errorModal = true;        
          this.isNewBranchCustomerdata = false;
          this.addBranchCustomerdataForm.reset();
          this.searchBranchCustomerData();
          this.branchName = '';
          this.customerName = '';
          this.customerState = '';
          this.customerZipCode = '';
          this.customerCountry = '';
          this.region = '';
          // this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.addBranchCustomerdataModal = false;
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
        this.addBranchCustomerdataModal = false;
      });
    
  }
  
  closeDeleteBranchCustomerdataModal(){    
    this.deleteBranchCustomerdataModal = false;
  }

  openBranchCustomerdataDeleteModal(item){   
    this.locIdtoDelete = item.locId;
    this.cusIdtoDelete = item.customerId;
    this.selectedBranchCustomerdataGroup = item;
    this.deleteBranchCustomerdataModal = true;
  }

  deleteBranchCustomerdata(){
    let _data = {
      "functionName": "DELETE",
      "locId": this.locIdtoDelete,   
      "customerId":  this.cusIdtoDelete 
    }
    this.DbuToolService.branchCustomerMapping(_data).subscribe(response =>{       
        if (response.statusCode == "1") {         
         this.successMessage = response.statusMessage;
         this.successModal = true; 
         this.searchBranchCustomerData();     
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.deleteBranchCustomerdataModal = false; 
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
        this.deleteBranchCustomerdataModal = false;
      })
  }

  searchFilter(str) {
    let search = (this.searchForm.value[str]).toLowerCase();
     if(search == '') {
      this.filterObject[str] = [];
      this.searchBranchCustomerData();
    }
    let inputData = this.searchForm.getRawValue();
    inputData['searchColumn'] = str;
    inputData['searchValue'] = this.searchForm.value[str];   
    inputData['functionName'] = "SEARCH";
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      sortingOrder: this.sortingOrder
    };
    Object.assign(inputData, this.searchObj);
    if(inputData['searchValue'].length >= 2 ){
      this.DbuToolService.branchCustomerMapping(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.filterObject[str] = response.branchCustMappingDTOList;
      } else {
        this.filterObject[str] = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  } else if(this.searchForm.value[str].length == 0){
    this.clearSearch(str)
    this.filterObject[str] == [];
    this.filterObject[str].length = 0;

  }
  }

  isSearchable(column) {
    if(column == 'branchName' || column == 'locId' || column == 'region' || 
      column == 'customerId' || column == 'customerName' || column =='customerState' || column == 'customerCountry' || column == 'customerZipCode'){
      return true;
    }else{
      return false;
    }
  }

  optionSearch(){
    this.currentPage = 1;
    this.searchbranchcustomerData();  
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.searchBranchCustomerData();
    }
  }
  
  onKey(value) { 
    this.dbubranchNameListCopy = [];
    this.selectSearch(value);
  }
  selectSearch(value:string){
    let filter = value.toLowerCase();
    for ( let i = 0 ; i < this.dbubranchNameList.length; i ++ ) {
      let option = this.dbubranchNameList[i];
      if (option.branchName.toLowerCase().indexOf(filter) >= 0) {
        this.dbubranchNameListCopy.push( this.dbubranchNameList[i] );
      }else if (option.locId.toLowerCase().indexOf(filter) >= 0) {
        this.dbubranchNameListCopy.push( this.dbubranchNameList[i] );

      }
      
    }
  }
  clearDropdownSearch(){
    this.dbubranchNameListCopy = this.dbubranchNameList;
  }

  onKeyCustomer(value){
    this.customerNameListCopy = [];
    this.selectSearchCustomer(value);
  }
  selectSearchCustomer(value:string){
    let filter = value.toLowerCase();
    for ( let i = 0 ; i < this.customerNameList.length; i ++ ) {
      let option = this.customerNameList[i];
      if (option.customerName.toLowerCase().indexOf(filter) >= 0) {
        this.customerNameListCopy.push( this.customerNameList[i] );
      } else if(option.customerId.toLowerCase().indexOf(filter) >= 0) {
        this.customerNameListCopy.push( this.customerNameList[i] );
      }
     
    }
  }
  clearSearchCustomer(){
    this.customerNameListCopy = this.customerNameList;
  }

  exportToExcel() {     
    this.customerNameList.forEach(element => {
      if(element.customerId == this.branchcustomerdataForm.controls.customerName.value){
        this.branchName =  element.branchName;
        this.exceldata = this.branchcustomerdataForm.getRawValue();
        this.searchObj = this.exceldata;
        this.exceldata = {
          "customerId" : element.customerId,
          "columnName" : this.columnName,
          "customerName" : element.customerName,
          "functionName" : "EXCEL",
          "sortingOrder" : this.sortingOrder,
          "branchName":this.searchForm.value['branchName'],
          "locId":this.searchForm.value['locId'],
          "region":this.searchForm.value['region'],
          "customerState":this.searchForm.value['customerState'],
          "customerCountry":this.searchForm.value['customerCountry'],
          "customerZipCode":this.searchForm.value['customerZipCode']

        }
      }
    });
    this.dbubranchNameList.forEach(element => {
      if(element.locId == this.branchcustomerdataForm.controls.branchName.value){
        this.branchName =  element.branchName;
        this.exceldata = this.branchcustomerdataForm.getRawValue();
        this.searchObj = this.exceldata;
        this.exceldata = {
          "locId" : element.locId,
          "columnName" : this.columnName,
          "branchName" : element.branchName,
          "functionName" : "EXCEL",
          "sortingOrder" : this.sortingOrder,
          "region":this.searchForm.value['region'],
          "customerName":this.searchForm.value['customerName'],
          "customerId":this.searchForm.value['customerId'],
          "customerState":this.searchForm.value['customerState'],
          "customerCountry":this.searchForm.value['customerCountry'],
          "customerZipCode":this.searchForm.value['customerZipCode']

        }
      }
    });

    this.DbuToolService.branchCustomerMapping(this.exceldata).subscribe(response => {
      if (response.hasOwnProperty('errorMessage') && response.errorMessage == 'body size is too long') {
        this.alertService.error('There are too many records to complete this operation. Fine tune the filters and export the samples');
      } else if (response.statusCode == "1") {
        this.exportExcelData = this.DbuToolService.convertDataUppercase(response.excelDatas);
        this.exportAsExcelFile(this.exportExcelData, 'Branch_Customer_Mapping');
        //console.log(this.exportExcelData + "this is the response");
      } else {
        this.alertService.error(response.statusMessage);
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // this.convertHeaderToUppercase(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // this.wrapAndCenterCell(worksheet.G1);
    const workbook: XLSX.WorkBook = { Sheets: { 'Branch Customer Mapping': worksheet }, SheetNames: ['Branch Customer Mapping'] };
    XLSX.writeFile(workbook, `${excelFileName}_export.xlsx`);
  }

  // Pagination
  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;  
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {   
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

    /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    if(this.columnName==""){
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.getbranchCustomerData();
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;  
    this.getbranchCustomerData();    
  }  
}
