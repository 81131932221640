import { Component, OnInit } from '@angular/core';
import { EditKeyIssuesService } from './edit-key-issues.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-edit-key-issues',
  templateUrl: './edit-key-issues.component.html',
  styleUrls: ['./edit-key-issues.component.css']
})
export class EditKeyIssuesComponent implements OnInit {

  populationDataList: any = [];
  keyIssueData = [];
  keyIssueResponseData: any = [];
  keyIssuesList: any = [];
  currentKeyIssueData: any = [];
  selectedKeyIssue: string = '';
  confirmModal: boolean = false;
  successModal: boolean = false;
  scrollToTop = false;

  /* keyIssuesForm = new FormGroup({
    factorOneNormal: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
    factorOneWatch: new FormControl('', Validators.required),
    factorOneCaution: new FormControl('', Validators.required),
    factorOneWarning: new FormControl('', Validators.required),
    factorTwoNormal: new FormControl('', Validators.required),
    factorTwoWatch: new FormControl('', Validators.required),
    factorTwoCaution: new FormControl('', Validators.required),
    factorTwoWarning: new FormControl('', Validators.required),
    factorOneNormalNormal: new FormControl('', Validators.required),
    factorOneNormalWatch: new FormControl('', Validators.required),
    factorOneNormalCaution: new FormControl('', Validators.required),
    factorOneWatchWatch: new FormControl('', Validators.required),
    factorOneNormalWarning: new FormControl('', Validators.required),
    factorOneWatchCaution: new FormControl('', Validators.required),
    factorOneWatchWarning: new FormControl('', Validators.required),
    factorOneCautionCaution: new FormControl('', Validators.required),
    factorOneCautionWarning: new FormControl('', Validators.required),
    factorOneWarningWarning: new FormControl('', Validators.required),
    factorTwoNormalNormal: new FormControl('', Validators.required),
    factorTwoNormalWatch: new FormControl('', Validators.required),
    factorTwoNormalCaution: new FormControl('', Validators.required),
    factorTwoWatchWatch: new FormControl('', Validators.required),
    factorTwoNormalWarning: new FormControl('', Validators.required),
    factorTwoWatchCaution: new FormControl('', Validators.required),
    factorTwoWatchWarning: new FormControl('', Validators.required),
    factorTwoCautionCaution: new FormControl('', Validators.required),
    factorTwoCautionWarning: new FormControl('', Validators.required),
    factorTwoWarningWarning: new FormControl('', Validators.required),
    normalHighThreshold: new FormControl('', Validators.required),
    watchLowThreshold: new FormControl('', Validators.required),
    watchHighThreshold: new FormControl('', Validators.required),
    cautionLowThreshold: new FormControl('', Validators.required),
    cautionHighThreshold: new FormControl('', Validators.required),
    warningLowThreshold: new FormControl('', Validators.required),
    keyIssue: new FormControl(''),
    populationId: new FormControl(''),
    userId: new FormControl('USER')
  }); */

  coolantContaminationForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormal: new UntypedFormControl('', Validators.required),
    factorTwoWatch: new UntypedFormControl('', Validators.required),
    factorTwoCaution: new UntypedFormControl('', Validators.required),
    factorTwoWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    unitDistance: new UntypedFormControl('', Validators.required),
    radioSelect: new UntypedFormControl(''),
    factorThreeNormal: new UntypedFormControl('', Validators.required),
    factorThreeWatch: new UntypedFormControl('', Validators.required),
    factorThreeCaution: new UntypedFormControl('', Validators.required),
    factorThreeWarning: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  FuelOrSootContaminationForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormal: new UntypedFormControl('', Validators.required),
    factorTwoWatch: new UntypedFormControl('', Validators.required),
    factorTwoCaution: new UntypedFormControl('', Validators.required),
    factorTwoWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  DustContaminationForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormal: new UntypedFormControl('', Validators.required),
    factorTwoWatch: new UntypedFormControl('', Validators.required),
    factorTwoCaution: new UntypedFormControl('', Validators.required),
    factorTwoWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    unitDistance: new UntypedFormControl('', Validators.required),
    radioSelect: new UntypedFormControl(''),
    factorThreeNormal: new UntypedFormControl('', Validators.required),
    factorThreeWatch: new UntypedFormControl('', Validators.required),
    factorThreeCaution: new UntypedFormControl('', Validators.required),
    factorThreeWarning: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  DegradedOilForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormal: new UntypedFormControl('', Validators.required),
    factorTwoWatch: new UntypedFormControl('', Validators.required),
    factorTwoCaution: new UntypedFormControl('', Validators.required),
    factorTwoWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  engineWearForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormalNormal: new UntypedFormControl('', Validators.required),
    factorTwoNormalWatch: new UntypedFormControl('', Validators.required),
    factorTwoNormalCaution: new UntypedFormControl('', Validators.required),
    factorTwoWatchWatch: new UntypedFormControl('', Validators.required),
    factorTwoNormalWarning: new UntypedFormControl('', Validators.required),
    factorTwoWatchCaution: new UntypedFormControl('', Validators.required),
    factorTwoWatchWarning: new UntypedFormControl('', Validators.required),
    factorTwoCautionCaution: new UntypedFormControl('', Validators.required),
    factorTwoCautionWarning: new UntypedFormControl('', Validators.required),
    factorTwoWarningWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  metalWearForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  ParticleContaminationForm = new UntypedFormGroup({    
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  waterContaminationForm = new UntypedFormGroup({    
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  ExcessiveContaminationForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormal: new UntypedFormControl('', Validators.required),
    factorTwoWatch: new UntypedFormControl('', Validators.required),
    factorTwoCaution: new UntypedFormControl('', Validators.required),
    factorTwoWarning: new UntypedFormControl('', Validators.required),
    factorThreeNormalNormal: new UntypedFormControl('', Validators.required),
    factorThreeNormalWatch: new UntypedFormControl('', Validators.required),
    factorThreeNormalCaution: new UntypedFormControl('', Validators.required),
    factorThreeWatchWatch: new UntypedFormControl('', Validators.required),
    factorThreeNormalWarning: new UntypedFormControl('', Validators.required),
    factorThreeWatchCaution: new UntypedFormControl('', Validators.required),
    factorThreeWatchWarning: new UntypedFormControl('', Validators.required),
    factorThreeCautionCaution: new UntypedFormControl('', Validators.required),
    factorThreeCautionWarning: new UntypedFormControl('', Validators.required),
    factorThreeWarningWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  DegradedCoolantForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormalNormal: new UntypedFormControl('', Validators.required),
    factorTwoNormalWatch: new UntypedFormControl('', Validators.required),
    factorTwoNormalCaution: new UntypedFormControl('', Validators.required),
    factorTwoWatchWatch: new UntypedFormControl('', Validators.required),
    factorTwoNormalWarning: new UntypedFormControl('', Validators.required),
    factorTwoWatchCaution: new UntypedFormControl('', Validators.required),
    factorTwoWatchWarning: new UntypedFormControl('', Validators.required),
    factorTwoCautionCaution: new UntypedFormControl('', Validators.required),
    factorTwoCautionWarning: new UntypedFormControl('', Validators.required),
    factorTwoWarningWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
   SystemCorrosionForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    factorTwoNormalNormal: new UntypedFormControl('', Validators.required),
    factorTwoNormalWatch: new UntypedFormControl('', Validators.required),
    factorTwoNormalCaution: new UntypedFormControl('', Validators.required),
    factorTwoWatchWatch: new UntypedFormControl('', Validators.required),
    factorTwoNormalWarning: new UntypedFormControl('', Validators.required),
    factorTwoWatchCaution: new UntypedFormControl('', Validators.required),
    factorTwoWatchWarning: new UntypedFormControl('', Validators.required),
    factorTwoCautionCaution: new UntypedFormControl('', Validators.required),
    factorTwoCautionWarning: new UntypedFormControl('', Validators.required),
    factorTwoWarningWarning: new UntypedFormControl('', Validators.required),
    secondaryFactorNormal: new UntypedFormControl('', Validators.required),
    secondaryFactorWatch: new UntypedFormControl('', Validators.required),
    secondaryFactorCaution: new UntypedFormControl('', Validators.required),
    secondaryFactorWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  HardParticleForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    factorTwoSedimentNoOrNull: new UntypedFormControl('', Validators.required),
    factorTwoSedimentSuspendedDebris: new UntypedFormControl('', Validators.required),
    factorTwoSedimentYesNonMagnetic: new UntypedFormControl('', Validators.required),
    factorTwoSedimentYesMagnetic: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });  

  TraceMetalsForm = new UntypedFormGroup({
    factorOneNormalNormal: new UntypedFormControl('', Validators.required),
    factorOneNormalWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWatch: new UntypedFormControl('', Validators.required),
    factorOneNormalWarning: new UntypedFormControl('', Validators.required),
    factorOneWatchCaution: new UntypedFormControl('', Validators.required),
    factorOneWatchWarning: new UntypedFormControl('', Validators.required),
    factorOneCautionCaution: new UntypedFormControl('', Validators.required),
    factorOneCautionWarning: new UntypedFormControl('', Validators.required),
    factorOneWarningWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  DegradedFuelForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  SulfurContaminationForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  BacteriaContentForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  FuelCleanlinessForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  FuelCharacteristicsForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),    
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });

  //Electrical conductivity form fields
  ElectricalConductivityForm = new UntypedFormGroup({
    factorOneNormal: new UntypedFormControl('', Validators.required),
    factorOneWatch: new UntypedFormControl('', Validators.required),
    factorOneCaution: new UntypedFormControl('', Validators.required),
    factorOneWarning: new UntypedFormControl('', Validators.required),
 
    normalHighThreshold: new UntypedFormControl('', Validators.required),
    watchLowThreshold: new UntypedFormControl('', Validators.required),
    watchHighThreshold: new UntypedFormControl('', Validators.required),
    cautionLowThreshold: new UntypedFormControl('', Validators.required),
    cautionHighThreshold: new UntypedFormControl('', Validators.required),
    warningLowThreshold: new UntypedFormControl('', Validators.required),
    keyIssue: new UntypedFormControl(''),
    populationId: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())
  });
  populationData: any;
  fluidTypeId: any;
  dropdownval: boolean;


  constructor(private editKeyIssuesService: EditKeyIssuesService,
    private router: Router,private sharedService: SharedService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.populationDataList = JSON.parse(localStorage.getItem('populationForLimitFlag'));
    this.populationData = JSON.parse(localStorage.getItem('populationdata'));
    this.fluidTypeId = this.populationDataList.fluidTypeId;
    console.log(this.populationDataList)
    this.currentKeyIssueData = JSON.parse(localStorage.getItem('currentKeyIssueData'));
    this.keyIssueData = JSON.parse(localStorage.getItem('keyIssueData'));
    this.keyIssuesList = JSON.parse(localStorage.getItem('keyIssuesList'));
    console.log(this.selectedKeyIssue)
  
    //console.log("currentKeyIssueData", this.currentKeyIssueData, "keyIssueData", this.keyIssueData,
    //  'keyIssuesList', this.keyIssuesList, "populationDataList", this.populationDataList);
    this.selectedKeyIssue = this.currentKeyIssueData.keyIssue;
    //console.log("keyIssues Selected", this.selectedKeyIssue);

    this.getEditKeyIssuesData();
  }
  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }
  /* ---------------------------Dropdown value change----------------------------- */
  onKeyIssueChanged(selectedValue) {
    this.selectedKeyIssue = selectedValue;
    this.currentKeyIssueData = [];
    for (let i = 0; i < this.keyIssueData.length; i++) {
      if (selectedValue == this.keyIssueData[i].keyIssue) {
        this.currentKeyIssueData = this.keyIssueData[i];
        //console.log("on dropdown change key issue value --------", this.currentKeyIssueData)
        localStorage.setItem('currentKeyIssueData', JSON.stringify(this.keyIssueData[i]));
        this.getEditKeyIssuesData();
      }
    }
  }

  /* ----------------------on page load fetch the factor values------------------- */
  getEditKeyIssuesData() {
    let req = {
      populationId: this.populationDataList.populationId,
      keyIssueId: this.currentKeyIssueData.keyIssueId
    };

    this.editKeyIssuesService.getEditKeyIssuesData(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          //console.log("response------>", response);
          this.keyIssueResponseData = response.keyIssues;
          if (req.keyIssueId == 1) {
            this.coolantContaminationForm.reset();
            this.getCoolantContaminationData();
          } else if (req.keyIssueId == 2) {
            this.DustContaminationForm.reset();
            this.getDustContaminationData();
          } else if (req.keyIssueId == 3) {
            this.FuelOrSootContaminationForm.reset();
            this.getSootContaminationData();
          } else if (req.keyIssueId == 4) {
            this.DegradedOilForm.reset();
            this.getDegradedOilData();
          } else if (req.keyIssueId == 5) {
            this.FuelOrSootContaminationForm.reset();
            this.getFuelContaminationData();
          } else if (req.keyIssueId == 6) {
            this.engineWearForm.reset();
            this.getEngineWearData();
          }
           else if (req.keyIssueId == 20) {
            this.metalWearForm.reset();
            this.getMetalWearData();
          }
           else if (req.keyIssueId == 19) {
            this.ParticleContaminationForm.reset();
            this.getParticleContaminationData();
          } else if (req.keyIssueId == 7) {
            this.waterContaminationForm.reset();
            this.getWaterContaminationData();
          } else if (req.keyIssueId == 10) {
            this.ExcessiveContaminationForm.reset();
            this.getExcessiveContaminationData();
          } else if (req.keyIssueId == 9) {
            this.DegradedCoolantForm.reset();
            this.getDegradedCoolantData();
          } else if (req.keyIssueId == 8) {
            this.SystemCorrosionForm.reset();
            this.getSystemCorrosionData();
          } else if (req.keyIssueId ==11){
            this.HardParticleForm.reset();
            this.getHardParticleData();
          } else if (req.keyIssueId ==12){
            this.SulfurContaminationForm.reset();
            this.getSulfurContaminationData();
          }else if (req.keyIssueId ==13){
            this.BacteriaContentForm.reset();
            this.getBacteriaContentData();
          }else if (req.keyIssueId ==14){
            this.TraceMetalsForm.reset();
            this.getTraceMetalsData();
          }else if (req.keyIssueId ==15){
            this.DegradedFuelForm.reset();
            this.getDegradedFuelData();
          }else if (req.keyIssueId ==16){
            this.FuelCleanlinessForm.reset();
            this.getFuelCleanlinessData();
          }else if (req.keyIssueId ==17){
            this.FuelCharacteristicsForm.reset();
            this.getFuelCharacteristicsData();
          }else if (req.keyIssueId ==18){
            this.ElectricalConductivityForm.reset();
            this.getElectricalConductivityData();
          }
          // else if (req.keyIssueId ==16){
          //   this.FuelCleanlinessForm.reset();
          //   this.getFuelCleanlinessData();
          // }else if (req.keyIssueId ==17){
          //   this.FuelCharacteristicsForm.reset();
          //   this.getFuelCharacteristicsData();
          // }
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* ---------------------------------Coolant Contamination------------------------------- */
  getCoolantContaminationData() {
    this.getCoolantContaminationFactorOne();
    this.getCoolantContaminationFactorTwo();
    this.getCoolantContaminationFactorThree();
    
    this.setRadioButtonForCoolant();
    this.coolantContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  setRadioButtonForCoolant(){
    let radioSelectedValue = 'less';
    if(this.keyIssueResponseData.factorThreeNormal === "0" && this.keyIssueResponseData.factorThreeWatch === "0" &&
      this.keyIssueResponseData.factorThreeCaution === "0" && this.keyIssueResponseData.factorThreeWarning === "0"){
        radioSelectedValue = 'more';
        this.moreRadioClicked();
    }else{
      this.lessRadioClicked();
    }
    this.coolantContaminationForm.patchValue({
      radioSelect: radioSelectedValue
    });
  }

  getCoolantContaminationFactorOne() {
    this.coolantContaminationForm.patchValue({
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
    });
  }

  getCoolantContaminationFactorTwo() {
    this.coolantContaminationForm.patchValue({
      factorTwoNormal: this.keyIssueResponseData.factorTwoNormal,
      factorTwoWatch: this.keyIssueResponseData.factorTwoWatch,
      factorTwoCaution: this.keyIssueResponseData.factorTwoCaution,
      factorTwoWarning: this.keyIssueResponseData.factorTwoWarning
    });
  }

  getCoolantContaminationFactorThree() {
    this.coolantContaminationForm.patchValue({
      unitDistance: this.keyIssueResponseData.unitDistance
    });
    this.coolantContaminationForm.patchValue({
      factorThreeNormal: this.keyIssueResponseData.factorThreeNormal,
      factorThreeWatch: this.keyIssueResponseData.factorThreeWatch,
      factorThreeCaution: this.keyIssueResponseData.factorThreeCaution,
      factorThreeWarning: this.keyIssueResponseData.factorThreeWarning
    });
  }

  resetCoolantContaminationFactorOne() {
    this.getCoolantContaminationFactorOne();
    this.validateCoolant();
  }

  resetCoolantContaminationFactorTwo() {
    this.getCoolantContaminationFactorTwo();
    this.validateCoolant();
  }

  resetCoolantContaminationFactorThree() {
    this.getCoolantContaminationFactorThree();
    this.setRadioButtonForCoolant();
    this.validateCoolant();
  }

  resetCoolantContaminationEdit() {
    this.coolantContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

  moreRadioClicked() {
    this.coolantContaminationForm.patchValue({
      factorThreeNormal : '',
      factorThreeWatch : '',
      factorThreeCaution : '',
      factorThreeWarning : ''
    });

    this.coolantContaminationForm.controls.factorThreeNormal.clearValidators();
    this.coolantContaminationForm.controls.factorThreeWatch.clearValidators();
    this.coolantContaminationForm.controls.factorThreeCaution.clearValidators();
    this.coolantContaminationForm.controls.factorThreeWarning.clearValidators();

    this.coolantContaminationForm.controls.factorThreeNormal.updateValueAndValidity();
    this.coolantContaminationForm.controls.factorThreeWatch.updateValueAndValidity();
    this.coolantContaminationForm.controls.factorThreeCaution.updateValueAndValidity();
    this.coolantContaminationForm.controls.factorThreeWarning.updateValueAndValidity();
  }

  lessRadioClicked() {
    this.coolantContaminationForm.controls.factorThreeNormal.setValidators([Validators.required]);
    this.coolantContaminationForm.controls.factorThreeWatch.setValidators([Validators.required]);
    this.coolantContaminationForm.controls.factorThreeCaution.setValidators([Validators.required]);
    this.coolantContaminationForm.controls.factorThreeWarning.setValidators([Validators.required]);

    this.coolantContaminationForm.controls.factorThreeNormal.updateValueAndValidity();
    this.coolantContaminationForm.controls.factorThreeWatch.updateValueAndValidity();
    this.coolantContaminationForm.controls.factorThreeCaution.updateValueAndValidity();
    this.coolantContaminationForm.controls.factorThreeWarning.updateValueAndValidity();  
  }

  /* ---------------------------------Soot Contamination------------------------------- */
  getSootContaminationData() {
    this.getSootContaminationFactorOne();
    this.getSootContaminationFactorTwo();
    this.FuelOrSootContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  getSootContaminationFactorOne() {
    this.FuelOrSootContaminationForm.patchValue({      
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning
    });
  }

  getSootContaminationFactorTwo() {
    this.FuelOrSootContaminationForm.patchValue({
      factorTwoNormal: this.keyIssueResponseData.factorTwoNormal,
      factorTwoWatch: this.keyIssueResponseData.factorTwoWatch,
      factorTwoCaution: this.keyIssueResponseData.factorTwoCaution,
      factorTwoWarning: this.keyIssueResponseData.factorTwoWarning
    });
  }

  resetSootContaminationFactorOne() {
    this.getSootContaminationFactorOne();
    this.validateSoot();
  }

  resetSootContaminationFactorTwo() {
    this.getSootContaminationFactorTwo();
    this.validateSoot();
  }

  resetSootContaminationEdit() {
    this.FuelOrSootContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

  /* ---------------------------------Fuel Contamination------------------------------- */
  getFuelContaminationData() {
    this.getFuelContaminationFactorOne();
    this.getFuelContaminationFactorTwo();
    this.FuelOrSootContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  getFuelContaminationFactorOne() {
    this.FuelOrSootContaminationForm.patchValue({
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning
    });
  }

  getFuelContaminationFactorTwo() {
    this.FuelOrSootContaminationForm.patchValue({
      factorTwoNormal: this.keyIssueResponseData.factorTwoNormal,
      factorTwoWatch: this.keyIssueResponseData.factorTwoWatch,
      factorTwoCaution: this.keyIssueResponseData.factorTwoCaution,
      factorTwoWarning: this.keyIssueResponseData.factorTwoWarning
    });
  }

  resetFuelContaminationFactorOne() {
    this.getFuelContaminationFactorOne();
    this.validateFuel();
  }

  resetFuelContaminationFactorTwo() {
    this.getFuelContaminationFactorTwo();
    this.validateFuel();
  }

  resetFuelContaminationEdit() {
    this.FuelOrSootContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

  /* ---------------------------------Degraded Oil------------------------------- */
  getDegradedOilData() {
    this.getDegradedOilFactorOne();
    this.getDegradedOilFactorTwo();
    this.DegradedOilForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  getDegradedOilFactorOne() {
    this.DegradedOilForm.patchValue({
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
    });
  }

  getDegradedOilFactorTwo() {
    this.DegradedOilForm.patchValue({
      factorTwoNormal: this.keyIssueResponseData.factorTwoNormal,
      factorTwoWatch: this.keyIssueResponseData.factorTwoWatch,
      factorTwoCaution: this.keyIssueResponseData.factorTwoCaution,
      factorTwoWarning: this.keyIssueResponseData.factorTwoWarning
    });
  }

  resetDegradedOilFactorOne() {
    this.getDegradedOilFactorOne();
    this.validateDegradedOil();
  }

  resetDegradedOilFactorTwo() {
    this.getDegradedOilFactorTwo();
    this.validateDegradedOil();
  }

  resetDegradedOilEdit() {
    this.DegradedOilForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

  /* ---------------------------------Dust Contamination------------------------------- */
  getDustContaminationData() {
    this.getDustContaminationFactorOne();
    this.getDustContaminationFactorTwo();
    this.getDustContaminationFactorThree();
    
    this.setRadioButtonForDust();
    this.DustContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  setRadioButtonForDust(){
    let radioSelectedValue = 'less';
    if(this.keyIssueResponseData.factorThreeNormal === "0" && this.keyIssueResponseData.factorThreeWatch === "0" &&
      this.keyIssueResponseData.factorThreeCaution === "0" && this.keyIssueResponseData.factorThreeWarning === "0"){
        radioSelectedValue = 'more';
        this.dustContaminationMoreRadioClicked();
    }else{
      this.dustContaminationLessRadioClicked();
    }
    this.DustContaminationForm.patchValue({
      radioSelect: radioSelectedValue
    });
  }  

  getDustContaminationFactorOne() {
    this.DustContaminationForm.patchValue({
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
    });
  }

  getDustContaminationFactorTwo() {
    this.DustContaminationForm.patchValue({
      factorTwoNormal: this.keyIssueResponseData.factorTwoNormal,
      factorTwoWatch: this.keyIssueResponseData.factorTwoWatch,
      factorTwoCaution: this.keyIssueResponseData.factorTwoCaution,
      factorTwoWarning: this.keyIssueResponseData.factorTwoWarning
    });
  }

  getDustContaminationFactorThree() {
    this.DustContaminationForm.patchValue({
      unitDistance: this.keyIssueResponseData.unitDistance
    });
    this.DustContaminationForm.patchValue({
      factorThreeNormal: this.keyIssueResponseData.factorThreeNormal,
      factorThreeWatch: this.keyIssueResponseData.factorThreeWatch,
      factorThreeCaution: this.keyIssueResponseData.factorThreeCaution,
      factorThreeWarning: this.keyIssueResponseData.factorThreeWarning
    });
  }

  resetDustContaminationFactorOne() {
    this.getDustContaminationFactorOne();
    this.validateDust();
  }

  resetDustContaminationFactorTwo() {
    this.getDustContaminationFactorTwo();
    this.validateDust();
  }

  resetDustContaminationFactorThree() {
    this.getDustContaminationFactorThree();
    this.setRadioButtonForDust();
    this.validateDust();
  }

  resetDustContaminationEdit() {
    this.DustContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

  dustContaminationMoreRadioClicked() {    
        
    this.DustContaminationForm.patchValue({
      factorThreeNormal : '',
      factorThreeWatch : '',
      factorThreeCaution : '',
      factorThreeWarning : ''
    });

    this.DustContaminationForm.controls.factorThreeNormal.clearValidators();
    this.DustContaminationForm.controls.factorThreeWatch.clearValidators();
    this.DustContaminationForm.controls.factorThreeCaution.clearValidators();
    this.DustContaminationForm.controls.factorThreeWarning.clearValidators();

    this.DustContaminationForm.controls.factorThreeNormal.updateValueAndValidity();
    this.DustContaminationForm.controls.factorThreeWatch.updateValueAndValidity();
    this.DustContaminationForm.controls.factorThreeCaution.updateValueAndValidity();
    this.DustContaminationForm.controls.factorThreeWarning.updateValueAndValidity();
  }

  dustContaminationLessRadioClicked() {    
    this.DustContaminationForm.controls.factorThreeNormal.setValidators([Validators.required]);
    this.DustContaminationForm.controls.factorThreeWatch.setValidators([Validators.required]);
    this.DustContaminationForm.controls.factorThreeCaution.setValidators([Validators.required]);
    this.DustContaminationForm.controls.factorThreeWarning.setValidators([Validators.required]);

    this.DustContaminationForm.controls.factorThreeNormal.updateValueAndValidity();
    this.DustContaminationForm.controls.factorThreeWatch.updateValueAndValidity();
    this.DustContaminationForm.controls.factorThreeCaution.updateValueAndValidity();
    this.DustContaminationForm.controls.factorThreeWarning.updateValueAndValidity();    
  }

  /* ---------------------------------Engine Wear------------------------------- */
  getEngineWearData() {
    this.getEngineWearFactorOne();
    this.getEngineWearFactorTwo();
    this.engineWearForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  getEngineWearFactorOne() {
    this.engineWearForm.patchValue({
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
    });
  }

  getEngineWearFactorTwo() {
    this.engineWearForm.patchValue({
      factorTwoNormalNormal: this.keyIssueResponseData.factorTwoNormalNormal,
      factorTwoNormalWatch: this.keyIssueResponseData.factorTwoNormalWatch,
      factorTwoNormalCaution: this.keyIssueResponseData.factorTwoNormalCaution,
      factorTwoWatchWatch: this.keyIssueResponseData.factorTwoWatchWatch,
      factorTwoNormalWarning: this.keyIssueResponseData.factorTwoNormalWarning,
      factorTwoWatchCaution: this.keyIssueResponseData.factorTwoWatchCaution,
      factorTwoWatchWarning: this.keyIssueResponseData.factorTwoWatchWarning,
      factorTwoCautionCaution: this.keyIssueResponseData.factorTwoCautionCaution,
      factorTwoCautionWarning: this.keyIssueResponseData.factorTwoCautionWarning,
      factorTwoWarningWarning: this.keyIssueResponseData.factorTwoWarningWarning
    });
  }

  resetEngineWearFactorOne() {
    this.getEngineWearFactorOne();
    this.validateEngineWear();
  }

  resetEngineWearFactorTwo() {
    this.getEngineWearFactorTwo();
    this.validateEngineWear();
  }

  resetEngineWearEdit() {
    this.engineWearForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }
  /*------------------metal waer----------------- */
  getMetalWearData() {
    this.getMetalWearFactorOne();
    this.metalWearForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  getMetalWearFactorOne() {
    this.metalWearForm.patchValue({
      factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
      factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
      factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
      factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
      factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
      factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
      factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
      factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
      factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
      factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
    });
  }

  resetMetalWearFactorOne() {
    this.getMetalWearFactorOne();
    this.validateMetalWear();
  }

  resetMetalWearEdit() {
    this.metalWearForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }
  /* -------------------------------- Partcle Contamination------------------------------- */
  resetparticleContaminationFactorOne(){
    this.getParticleContaminationFactorOne();
    this.validateParticleContamination();
  }

  resetParticleContaminationEdit(){
    this.ParticleContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }



/* -------------------------------- Excessive Contamination------------------------------- */
  getExcessiveContaminationData() {
    this.getExcessiveContaminationFactorOne();
    this.getExcessiveContaminationFactorTwo();
    this.getExcessiveContaminationFactorThree();
    this.ExcessiveContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

  getExcessiveContaminationFactorOne() {
    this.ExcessiveContaminationForm.patchValue({
      factorOneNormal: this.keyIssueResponseData.factorOneNormal,
      factorOneWatch: this.keyIssueResponseData.factorOneWatch,
      factorOneCaution: this.keyIssueResponseData.factorOneCaution,
      factorOneWarning: this.keyIssueResponseData.factorOneWarning,
     
    });
  }

  getExcessiveContaminationFactorTwo() {
    this.ExcessiveContaminationForm.patchValue({
      factorTwoNormal: this.keyIssueResponseData.factorTwoNormal,
      factorTwoWatch: this.keyIssueResponseData.factorTwoWatch,
      factorTwoCaution: this.keyIssueResponseData.factorTwoCaution,
      factorTwoWarning: this.keyIssueResponseData.factorTwoWarning
    });
  }

  getExcessiveContaminationFactorThree() {
    this.ExcessiveContaminationForm.patchValue({
    factorThreeNormalNormal: this.keyIssueResponseData.factorThreeNormalNormal,
    factorThreeNormalWatch: this.keyIssueResponseData.factorThreeNormalWatch,
    factorThreeNormalCaution: this.keyIssueResponseData.factorThreeNormalCaution,
    factorThreeWatchWatch: this.keyIssueResponseData.factorThreeWatchWatch,
    factorThreeNormalWarning: this.keyIssueResponseData.factorThreeNormalWarning,
    factorThreeWatchCaution: this.keyIssueResponseData.factorThreeWatchCaution,
    factorThreeWatchWarning: this.keyIssueResponseData.factorThreeWatchWarning,
    factorThreeCautionCaution: this.keyIssueResponseData.factorThreeCautionCaution,
    factorThreeCautionWarning: this.keyIssueResponseData.factorThreeCautionWarning,
    factorThreeWarningWarning: this.keyIssueResponseData.factorThreeWarningWarning,
    });
  }


  resetExcessiveContaminationFactorOne() {
    this.getExcessiveContaminationFactorOne();
    this.validateExcessive();
  }
  
  resetExcessiveContaminationFactorTwo() {
    this.getExcessiveContaminationFactorTwo();
    this.validateExcessive();
  }
  
  resetExcessiveContaminationFactorThree() {
    this.getExcessiveContaminationFactorThree();
    this.validateExcessive();
  }

  resetExcessiveContaminationEdit() {
    this.ExcessiveContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

    /* ---------------------------------Degraded Coolant------------------------------- */
    getDegradedCoolantData() {
      this.getDegradedCoolantFactorOne();
      this.getDegradedCoolantFactorTwo();
      this.DegradedCoolantForm.patchValue({
        keyIssue: this.currentKeyIssueData.keyIssueId,
        populationId: this.populationDataList.populationId,
          userId: this.sharedService.getUserId()
      });
    }
  
    getDegradedCoolantFactorOne() {
      this.DegradedCoolantForm.patchValue({
        factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
        factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
        factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
        factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
        factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
        factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
        factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
        factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
        factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
        factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
      });
    }
  
    getDegradedCoolantFactorTwo() {
      this.DegradedCoolantForm.patchValue({
        factorTwoNormalNormal: this.keyIssueResponseData.factorTwoNormalNormal,
        factorTwoNormalWatch: this.keyIssueResponseData.factorTwoNormalWatch,
        factorTwoNormalCaution: this.keyIssueResponseData.factorTwoNormalCaution,
        factorTwoWatchWatch: this.keyIssueResponseData.factorTwoWatchWatch,
        factorTwoNormalWarning: this.keyIssueResponseData.factorTwoNormalWarning,
        factorTwoWatchCaution: this.keyIssueResponseData.factorTwoWatchCaution,
        factorTwoWatchWarning: this.keyIssueResponseData.factorTwoWatchWarning,
        factorTwoCautionCaution: this.keyIssueResponseData.factorTwoCautionCaution,
        factorTwoCautionWarning: this.keyIssueResponseData.factorTwoCautionWarning,
        factorTwoWarningWarning: this.keyIssueResponseData.factorTwoWarningWarning,
      });
    }
  
    resetDegradedCoolantFactorOne() {
      this.getDegradedCoolantFactorOne();
      this.validateDegradedCoolant();
    }
  
    resetDegradedCoolantFactorTwo() {
      this.getDegradedCoolantFactorTwo();
      this.validateDegradedCoolant();
    }
  
    resetDegradedCoolantEdit() {
      this.DegradedCoolantForm.patchValue({
        normalHighThreshold: '',
        watchLowThreshold: '',
        watchHighThreshold: '',
        cautionLowThreshold: '',
        cautionHighThreshold: '',
        warningLowThreshold: '',
      });
    }
  

        /* -------------------------------- System Corrosion ------------------------------- */
        getSystemCorrosionData() {
          this.getSystemCorrosionFactorOne();
          this.getSystemCorrosionFactorTwo();
          this.getSystemCorrosionSecondaryFactor();
          this.SystemCorrosionForm.patchValue({
            keyIssue: this.currentKeyIssueData.keyIssueId,
            populationId: this.populationDataList.populationId,
            userId: this.sharedService.getUserId()
          });
        }
      
        getSystemCorrosionFactorOne() {
          this.SystemCorrosionForm.patchValue({
            factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
            factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
            factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
            factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
            factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
            factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
            factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
            factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
            factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
            factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
          });
        }
      
        getSystemCorrosionFactorTwo() {
          this.SystemCorrosionForm.patchValue({
            factorTwoNormalNormal: this.keyIssueResponseData.factorTwoNormalNormal,
            factorTwoNormalWatch: this.keyIssueResponseData.factorTwoNormalWatch,
            factorTwoNormalCaution: this.keyIssueResponseData.factorTwoNormalCaution,
            factorTwoWatchWatch: this.keyIssueResponseData.factorTwoWatchWatch,
            factorTwoNormalWarning: this.keyIssueResponseData.factorTwoNormalWarning,
            factorTwoWatchCaution: this.keyIssueResponseData.factorTwoWatchCaution,
            factorTwoWatchWarning: this.keyIssueResponseData.factorTwoWatchWarning,
            factorTwoCautionCaution: this.keyIssueResponseData.factorTwoCautionCaution,
            factorTwoCautionWarning: this.keyIssueResponseData.factorTwoCautionWarning,
            factorTwoWarningWarning: this.keyIssueResponseData.factorTwoWarningWarning,
          });
        }
        getSystemCorrosionSecondaryFactor() {
          this.SystemCorrosionForm.patchValue({
            secondaryFactorNormal: this.keyIssueResponseData.secondaryFactorNormal,
            secondaryFactorWatch: this.keyIssueResponseData.secondaryFactorWatch,
            secondaryFactorCaution: this.keyIssueResponseData.secondaryFactorCaution,
            secondaryFactorWarning: this.keyIssueResponseData.secondaryFactorWarning,
          });
        }      
        resetSystemCorrosionFactorOne() {
          this.getSystemCorrosionFactorOne();
          this.validateSystemCorrosion();
        }
      
        resetSystemCorrosionFactorTwo() {
          this.getSystemCorrosionFactorTwo();
          this.validateSystemCorrosion();
        }
        resetSystemCorrosionSecondaryFactor(){
          this.getSystemCorrosionSecondaryFactor();
          this.validateSystemCorrosion();
        }
      
        resetSystemCorrosionEdit() {
          this.SystemCorrosionForm.patchValue({
            normalHighThreshold: '',
            watchLowThreshold: '',
            watchHighThreshold: '',
            cautionLowThreshold: '',
            cautionHighThreshold: '',
            warningLowThreshold: '',
          });
        }
      
 /* ---------------------------------Hard particle------------------------------- */
 getHardParticleData() {
  this.getHardParticleFactorOne();
  this.getHardParticleFactorTwo();
  this.HardParticleForm.patchValue({
    keyIssue: this.currentKeyIssueData.keyIssueId,
    populationId: this.populationDataList.populationId,
    userId: this.sharedService.getUserId()
  });
}

getHardParticleFactorOne() {
  this.HardParticleForm.patchValue({
    factorOneNormal: this.keyIssueResponseData.factorOneNormal,
    factorOneWatch: this.keyIssueResponseData.factorOneWatch,
    factorOneCaution: this.keyIssueResponseData.factorOneCaution,
    factorOneWarning: this.keyIssueResponseData.factorOneWarning,
 });
}

getHardParticleFactorTwo() {
  this.HardParticleForm.patchValue({
    factorTwoSedimentNoOrNull: this.keyIssueResponseData.factorTwoSedimentNoOrNull,
    factorTwoSedimentSuspendedDebris: this.keyIssueResponseData.factorTwoSedimentSuspendedDebris,
    factorTwoSedimentYesNonMagnetic: this.keyIssueResponseData.factorTwoSedimentYesNonMagnetic,
    factorTwoSedimentYesMagnetic: this.keyIssueResponseData.factorTwoSedimentYesMagnetic,
    
 });
}

resetHardParticleFactorOne(){
  this.getHardParticleFactorOne();
  this.validateHardParticle();
}

resetHardParticleFactorTwo(){
  this.getHardParticleFactorTwo();
  this.validateHardParticle();
}

resetHardParticleEdit() {
  this.HardParticleForm.patchValue({
    normalHighThreshold: '',
    watchLowThreshold: '',
    watchHighThreshold: '',
    cautionLowThreshold: '',
    cautionHighThreshold: '',
    warningLowThreshold: '',
  });
}
    /* ---------------------------------Trace Metals------------------------------- */
    getTraceMetalsData() {
      this.getTraceMetalsFactorOne();
      this.TraceMetalsForm.patchValue({
        keyIssue: this.currentKeyIssueData.keyIssueId,
        populationId: this.populationDataList.populationId,
          userId: this.sharedService.getUserId()
      });
    }
  
    getTraceMetalsFactorOne() {
      this.TraceMetalsForm.patchValue({
        factorOneNormalNormal: this.keyIssueResponseData.factorOneNormalNormal,
        factorOneNormalWatch: this.keyIssueResponseData.factorOneNormalWatch,
        factorOneNormalCaution: this.keyIssueResponseData.factorOneNormalCaution,
        factorOneWatchWatch: this.keyIssueResponseData.factorOneWatchWatch,
        factorOneNormalWarning: this.keyIssueResponseData.factorOneNormalWarning,
        factorOneWatchCaution: this.keyIssueResponseData.factorOneWatchCaution,
        factorOneWatchWarning: this.keyIssueResponseData.factorOneWatchWarning,
        factorOneCautionCaution: this.keyIssueResponseData.factorOneCautionCaution,
        factorOneCautionWarning: this.keyIssueResponseData.factorOneCautionWarning,
        factorOneWarningWarning: this.keyIssueResponseData.factorOneWarningWarning,
      });
    }
  
    resetTraceMetalsFactorOne() {
      this.getTraceMetalsFactorOne();
      this.alertService.clear();
      // this.validateTraceMetals();
    }
  
    resetTraceMetalsEdit() {
      this.TraceMetalsForm.patchValue({
        normalHighThreshold: '',
        watchLowThreshold: '',
        watchHighThreshold: '',
        cautionLowThreshold: '',
        cautionHighThreshold: '',
        warningLowThreshold: '',
      });
    }
 /* ---------------------------------Degraded Fuel------------------------------- */
    getDegradedFuelData() {
      this.getDegradedFuelFactorOne();
      this.DegradedFuelForm.patchValue({
        keyIssue: this.currentKeyIssueData.keyIssueId,
        populationId: this.populationDataList.populationId,
          userId: this.sharedService.getUserId()
      });
    }

    getDegradedFuelFactorOne() {
      this.DegradedFuelForm.patchValue({
        factorOneNormal: this.keyIssueResponseData.factorOneNormal,
        factorOneWatch: this.keyIssueResponseData.factorOneWatch,
        factorOneCaution: this.keyIssueResponseData.factorOneCaution,
        factorOneWarning: this.keyIssueResponseData.factorOneWarning
      });
    }

    resetDegradedFuelFactorOne() {
      this.getDegradedFuelFactorOne();
      this.alertService.clear();
      // this.validateDegradedFuel();
    }

    resetDegradedFuelEdit() {
      this.DegradedFuelForm.patchValue({
        normalHighThreshold: '',
        watchLowThreshold: '',
        watchHighThreshold: '',
        cautionLowThreshold: '',
        cautionHighThreshold: '',
        warningLowThreshold: '',
      });
    }
    /* ---------------------------------Sulfur Contamination------------------------------- */
    getSulfurContaminationData() {
      this.getSulfurContaminationFactorOne();
      this.SulfurContaminationForm.patchValue({
        keyIssue: this.currentKeyIssueData.keyIssueId,
        populationId: this.populationDataList.populationId,
          userId: this.sharedService.getUserId()
      });
    }

    getSulfurContaminationFactorOne() {
      this.SulfurContaminationForm.patchValue({
        factorOneNormal: this.keyIssueResponseData.factorOneNormal,
        factorOneWatch: this.keyIssueResponseData.factorOneWatch,
        factorOneCaution: this.keyIssueResponseData.factorOneCaution,
        factorOneWarning: this.keyIssueResponseData.factorOneWarning
      });
    }

    resetSulfurContaminationFactorOne() {
      this.getSulfurContaminationFactorOne();
      this.alertService.clear();
      // this.validateSulfurContamination();
    }

    resetSulfurContaminationEdit() {
      this.SulfurContaminationForm.patchValue({
        normalHighThreshold: '',
        watchLowThreshold: '',
        watchHighThreshold: '',
        cautionLowThreshold: '',
        cautionHighThreshold: '',
        warningLowThreshold: '',
      });
    }
        /* ---------------------------------Bacteria Content------------------------------- */
        getBacteriaContentData() {
          this.getBacteriaContentFactorOne();
          this.BacteriaContentForm.patchValue({
            keyIssue: this.currentKeyIssueData.keyIssueId,
            populationId: this.populationDataList.populationId,
              userId: this.sharedService.getUserId()
          });
        }
    
        getBacteriaContentFactorOne() {
          this.BacteriaContentForm.patchValue({
            factorOneNormal: this.keyIssueResponseData.factorOneNormal,
            factorOneWatch: this.keyIssueResponseData.factorOneWatch,
            factorOneCaution: this.keyIssueResponseData.factorOneCaution,
            factorOneWarning: this.keyIssueResponseData.factorOneWarning
          });
        }
    
        resetBacteriaContentFactorOne() {
          this.getBacteriaContentFactorOne();
          this.alertService.clear();
          // this.validateBacteriaContent();
        }
    
        resetBacteriaContentEdit() {
          this.BacteriaContentForm.patchValue({
            normalHighThreshold: '',
            watchLowThreshold: '',
            watchHighThreshold: '',
            cautionLowThreshold: '',
            cautionHighThreshold: '',
            warningLowThreshold: '',
          });
        }

         /* ---------------------------------Fuel Cleanliness------------------------------- */
         getFuelCleanlinessData() {
          this.getFuelCleanlinessFactorOne();
          this.FuelCleanlinessForm.patchValue({
            keyIssue: this.currentKeyIssueData.keyIssueId,
            populationId: this.populationDataList.populationId,
              userId: this.sharedService.getUserId()
          });
        }
    
        getFuelCleanlinessFactorOne() {
          this.FuelCleanlinessForm.patchValue({
            factorOneNormal: this.keyIssueResponseData.factorOneNormal,
            factorOneWatch: this.keyIssueResponseData.factorOneWatch,
            factorOneCaution: this.keyIssueResponseData.factorOneCaution,
            factorOneWarning: this.keyIssueResponseData.factorOneWarning
          });
        }
    
        resetFuelCleanlinessFactorOne() {
          this.getFuelCleanlinessFactorOne();
          this.alertService.clear();
          // this.validateFuelCleanliness();
        }
    
        resetFuelCleanlinessEdit() {
          this.FuelCleanlinessForm.patchValue({
            normalHighThreshold: '',
            watchLowThreshold: '',
            watchHighThreshold: '',
            cautionLowThreshold: '',
            cautionHighThreshold: '',
            warningLowThreshold: '',
          });
        }
        /* ---------------------------------Fuel Cleanliness------------------------------- */
        getFuelCharacteristicsData() {
          this.getFuelCharacteristicsFactorOne();
          this.FuelCharacteristicsForm.patchValue({
            keyIssue: this.currentKeyIssueData.keyIssueId,
            populationId: this.populationDataList.populationId,
              userId: this.sharedService.getUserId()
          });
        }
    
        getFuelCharacteristicsFactorOne() {
          this.FuelCharacteristicsForm.patchValue({
            factorOneNormal: this.keyIssueResponseData.factorOneNormal,
            factorOneWatch: this.keyIssueResponseData.factorOneWatch,
            factorOneCaution: this.keyIssueResponseData.factorOneCaution,
            factorOneWarning: this.keyIssueResponseData.factorOneWarning
          });
        }
    
        resetFuelCharacteristicsFactorOne() {
          this.getFuelCharacteristicsFactorOne();
          this.alertService.clear();
          // this.validateFuelCharacteristics();
        }
    
        resetFuelCharacteristicsEdit() {
          this.FuelCharacteristicsForm.patchValue({
            normalHighThreshold: '',
            watchLowThreshold: '',
            watchHighThreshold: '',
            cautionLowThreshold: '',
            cautionHighThreshold: '',
            warningLowThreshold: '',
          });
          this.alertService.clear();
        }
    
    
  /* ----------------------------Edit Part precision check--------------------- */
  ngDoCheck() {
    var precisionValue = 1;
    if (this.currentKeyIssueData.keyIssueId == 1) {
      if (this.coolantContaminationForm.value.normalHighThreshold != '' && this.coolantContaminationForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.coolantContaminationForm.value.normalHighThreshold);
        this.coolantContaminationForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.coolantContaminationForm.value.normalHighThreshold == null) {
        this.coolantContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.coolantContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.coolantContaminationForm.value.watchHighThreshold != '' && this.coolantContaminationForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.coolantContaminationForm.value.watchHighThreshold) + precisionValue;
        this.coolantContaminationForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.coolantContaminationForm.value.watchHighThreshold == null) {
        this.coolantContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.coolantContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.coolantContaminationForm.value.cautionHighThreshold != '' && this.coolantContaminationForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.coolantContaminationForm.value.cautionHighThreshold);
        this.coolantContaminationForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.coolantContaminationForm.value.cautionHighThreshold == null) {
        this.coolantContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.coolantContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 2) {
      if (this.DustContaminationForm.value.normalHighThreshold != '' && this.DustContaminationForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.DustContaminationForm.value.normalHighThreshold);
        this.DustContaminationForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.DustContaminationForm.value.normalHighThreshold == null) {
        this.DustContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.DustContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.DustContaminationForm.value.watchHighThreshold != '' && this.DustContaminationForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.DustContaminationForm.value.watchHighThreshold) + precisionValue;
        this.DustContaminationForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.DustContaminationForm.value.watchHighThreshold == null) {
        this.DustContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.DustContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.DustContaminationForm.value.cautionHighThreshold != '' && this.DustContaminationForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.DustContaminationForm.value.cautionHighThreshold);
        this.DustContaminationForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.DustContaminationForm.value.cautionHighThreshold == null) {
        this.DustContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.DustContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 3) {
      if (this.FuelOrSootContaminationForm.value.normalHighThreshold != '' && this.FuelOrSootContaminationForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.FuelOrSootContaminationForm.value.normalHighThreshold);
        this.FuelOrSootContaminationForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.FuelOrSootContaminationForm.value.normalHighThreshold == null) {
        this.FuelOrSootContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.FuelOrSootContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.FuelOrSootContaminationForm.value.watchHighThreshold != '' && this.FuelOrSootContaminationForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.FuelOrSootContaminationForm.value.watchHighThreshold) + precisionValue;
        this.FuelOrSootContaminationForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.FuelOrSootContaminationForm.value.watchHighThreshold == null) {
        this.FuelOrSootContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.FuelOrSootContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.FuelOrSootContaminationForm.value.cautionHighThreshold != '' && this.FuelOrSootContaminationForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.FuelOrSootContaminationForm.value.cautionHighThreshold);
        this.FuelOrSootContaminationForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.FuelOrSootContaminationForm.value.cautionHighThreshold == null) {
        this.FuelOrSootContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.FuelOrSootContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 4) {
      if (this.DegradedOilForm.value.normalHighThreshold != '' && this.DegradedOilForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.DegradedOilForm.value.normalHighThreshold);
        this.DegradedOilForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.DegradedOilForm.value.normalHighThreshold == null) {
        this.DegradedOilForm.value.watchLowThreshold = '';
      }
      else {
        this.DegradedOilForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.DegradedOilForm.value.watchHighThreshold != '' && this.DegradedOilForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.DegradedOilForm.value.watchHighThreshold) + precisionValue;
        this.DegradedOilForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.DegradedOilForm.value.watchHighThreshold == null) {
        this.DegradedOilForm.value.cautionLowThreshold = '';
      }
      else {
        this.DegradedOilForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.DegradedOilForm.value.cautionHighThreshold != '' && this.DegradedOilForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.DegradedOilForm.value.cautionHighThreshold);
        this.DegradedOilForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.DegradedOilForm.value.cautionHighThreshold == null) {
        this.DegradedOilForm.value.warningLowThreshold = '';
      }
      else {
        this.DegradedOilForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 5) {
      if (this.FuelOrSootContaminationForm.value.normalHighThreshold != '' && this.FuelOrSootContaminationForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.FuelOrSootContaminationForm.value.normalHighThreshold);
        this.FuelOrSootContaminationForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.FuelOrSootContaminationForm.value.normalHighThreshold == null) {
        this.FuelOrSootContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.FuelOrSootContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.FuelOrSootContaminationForm.value.watchHighThreshold != '' && this.FuelOrSootContaminationForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.FuelOrSootContaminationForm.value.watchHighThreshold) + precisionValue;
        this.FuelOrSootContaminationForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.FuelOrSootContaminationForm.value.watchHighThreshold == null) {
        this.FuelOrSootContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.FuelOrSootContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.FuelOrSootContaminationForm.value.cautionHighThreshold != '' && this.FuelOrSootContaminationForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.FuelOrSootContaminationForm.value.cautionHighThreshold);
        this.FuelOrSootContaminationForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.FuelOrSootContaminationForm.value.cautionHighThreshold == null) {
        this.FuelOrSootContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.FuelOrSootContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 6) {
      if (this.engineWearForm.value.normalHighThreshold != '' && this.engineWearForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.engineWearForm.value.normalHighThreshold);
        this.engineWearForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.engineWearForm.value.normalHighThreshold == null) {
        this.engineWearForm.value.watchLowThreshold = '';
      }
      else {
        this.engineWearForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.engineWearForm.value.watchHighThreshold != '' && this.engineWearForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.engineWearForm.value.watchHighThreshold) + precisionValue;
        this.engineWearForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.engineWearForm.value.watchHighThreshold == null) {
        this.engineWearForm.value.cautionLowThreshold = '';
      }
      else {
        this.engineWearForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.engineWearForm.value.cautionHighThreshold != '' && this.engineWearForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.engineWearForm.value.cautionHighThreshold);
        this.engineWearForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.engineWearForm.value.cautionHighThreshold == null) {
        this.engineWearForm.value.warningLowThreshold = '';
      }
      else {
        this.engineWearForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 20) {
      if (this.metalWearForm.value.normalHighThreshold != '' && this.metalWearForm.value.normalHighThreshold != null) {
        var sootWatchLow = parseInt(this.metalWearForm.value.normalHighThreshold);
        this.metalWearForm.controls['watchLowThreshold'].patchValue(sootWatchLow);
      }
      else if (this.metalWearForm.value.normalHighThreshold == null) {
        this.metalWearForm.value.watchLowThreshold = '';
      }
      else {
        this.metalWearForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.metalWearForm.value.watchHighThreshold != '' && this.metalWearForm.value.watchHighThreshold != null) {
        var sootCautionLow = parseInt(this.metalWearForm.value.watchHighThreshold) + precisionValue;
        this.metalWearForm.controls['cautionLowThreshold'].patchValue(sootCautionLow);
      }
      else if (this.metalWearForm.value.watchHighThreshold == null) {
        this.metalWearForm.value.cautionLowThreshold = '';
      }
      else {
        this.metalWearForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.metalWearForm.value.cautionHighThreshold != '' && this.metalWearForm.value.cautionHighThreshold != null) {
        var sootWarningLow = parseInt(this.metalWearForm.value.cautionHighThreshold);
        this.metalWearForm.controls['warningLowThreshold'].patchValue(sootWarningLow);
      }
      else if (this.metalWearForm.value.cautionHighThreshold == null) {
        this.metalWearForm.value.warningLowThreshold = '';
      }
      else {
        this.metalWearForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 7) {
      if (this.waterContaminationForm.value.normalHighThreshold != '' && this.waterContaminationForm.value.normalHighThreshold != null) {
        var waterWatchLow = parseInt(this.waterContaminationForm.value.normalHighThreshold);
        this.waterContaminationForm.controls['watchLowThreshold'].patchValue(waterWatchLow);
      }
      else if (this.waterContaminationForm.value.normalHighThreshold == null) {
        this.waterContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.waterContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.waterContaminationForm.value.watchHighThreshold != '' && this.waterContaminationForm.value.watchHighThreshold != null) {
        var waterCautionLow = parseInt(this.waterContaminationForm.value.watchHighThreshold) + precisionValue;
        this.waterContaminationForm.controls['cautionLowThreshold'].patchValue(waterCautionLow);
      }
      else if (this.waterContaminationForm.value.watchHighThreshold == null) {
        this.waterContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.waterContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.waterContaminationForm.value.cautionHighThreshold != '' && this.waterContaminationForm.value.cautionHighThreshold != null) {
        var waterWarningLow = parseInt(this.waterContaminationForm.value.cautionHighThreshold);
        this.waterContaminationForm.controls['warningLowThreshold'].patchValue(waterWarningLow);
      }
      else if (this.waterContaminationForm.value.cautionHighThreshold == null) {
        this.waterContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.waterContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 19) {
      if (this.ParticleContaminationForm.value.normalHighThreshold != '' && this.ParticleContaminationForm.value.normalHighThreshold != null) {
        var waterWatchLow = parseInt(this.ParticleContaminationForm.value.normalHighThreshold);
        this.ParticleContaminationForm.controls['watchLowThreshold'].patchValue(waterWatchLow);
      }
      else if (this.ParticleContaminationForm.value.normalHighThreshold == null) {
        this.ParticleContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.ParticleContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.ParticleContaminationForm.value.watchHighThreshold != '' && this.ParticleContaminationForm.value.watchHighThreshold != null) {
        var waterCautionLow = parseInt(this.ParticleContaminationForm.value.watchHighThreshold) + precisionValue;
        this.ParticleContaminationForm.controls['cautionLowThreshold'].patchValue(waterCautionLow);
      }
      else if (this.ParticleContaminationForm.value.watchHighThreshold == null) {
        this.ParticleContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.ParticleContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.ParticleContaminationForm.value.cautionHighThreshold != '' && this.ParticleContaminationForm.value.cautionHighThreshold != null) {
        var waterWarningLow = parseInt(this.ParticleContaminationForm.value.cautionHighThreshold);
        this.ParticleContaminationForm.controls['warningLowThreshold'].patchValue(waterWarningLow);
      }
      else if (this.ParticleContaminationForm.value.cautionHighThreshold == null) {
        this.ParticleContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.ParticleContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }

    else if (this.currentKeyIssueData.keyIssueId == 8) {
      if (this.SystemCorrosionForm.value.normalHighThreshold != '' && this.SystemCorrosionForm.value.normalHighThreshold != null) {
        var systemWarningLow = parseInt(this.SystemCorrosionForm.value.normalHighThreshold);
        this.SystemCorrosionForm.controls['watchLowThreshold'].patchValue(systemWarningLow);
      }
      else if (this.SystemCorrosionForm.value.normalHighThreshold == null) {
        this.SystemCorrosionForm.value.watchLowThreshold = '';
      }
      else {
        this.SystemCorrosionForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.SystemCorrosionForm.value.watchHighThreshold != '' && this.SystemCorrosionForm.value.watchHighThreshold != null) {
        var systemWarningLow = parseInt(this.SystemCorrosionForm.value.watchHighThreshold) + precisionValue;
        this.SystemCorrosionForm.controls['cautionLowThreshold'].patchValue(systemWarningLow);
      }
      else if (this.SystemCorrosionForm.value.watchHighThreshold == null) {
        this.SystemCorrosionForm.value.cautionLowThreshold = '';
      }
      else {
        this.SystemCorrosionForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.SystemCorrosionForm.value.cautionHighThreshold != '' && this.SystemCorrosionForm.value.cautionHighThreshold != null) {
        var systemWarningLow = parseInt(this.SystemCorrosionForm.value.cautionHighThreshold);
        this.SystemCorrosionForm.controls['warningLowThreshold'].patchValue(systemWarningLow);
      }
      else if (this.SystemCorrosionForm.value.cautionHighThreshold == null) {
        this.SystemCorrosionForm.value.warningLowThreshold = '';
      }
      else {
        this.SystemCorrosionForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    
    else if (this.currentKeyIssueData.keyIssueId == 9) {
      if (this.DegradedCoolantForm.value.normalHighThreshold != '' && this.DegradedCoolantForm.value.normalHighThreshold != null) {
        var degradedWatchLow = parseInt(this.DegradedCoolantForm.value.normalHighThreshold);
        this.DegradedCoolantForm.controls['watchLowThreshold'].patchValue(degradedWatchLow);
      }
      else if (this.DegradedCoolantForm.value.normalHighThreshold == null) {
        this.DegradedCoolantForm.value.watchLowThreshold = '';
      }
      else {
        this.DegradedCoolantForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.DegradedCoolantForm.value.watchHighThreshold != '' && this.DegradedCoolantForm.value.watchHighThreshold != null) {
        var degradedCautionLow = parseInt(this.DegradedCoolantForm.value.watchHighThreshold) + precisionValue;
        this.DegradedCoolantForm.controls['cautionLowThreshold'].patchValue(degradedCautionLow);
      }
      else if (this.DegradedCoolantForm.value.watchHighThreshold == null) {
        this.DegradedCoolantForm.value.cautionLowThreshold = '';
      }
      else {
        this.DegradedCoolantForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.DegradedCoolantForm.value.cautionHighThreshold != '' && this.DegradedCoolantForm.value.cautionHighThreshold != null) {
        var degradedWarningLow = parseInt(this.DegradedCoolantForm.value.cautionHighThreshold);
        this.DegradedCoolantForm.controls['warningLowThreshold'].patchValue(degradedWarningLow);
      }
      else if (this.DegradedCoolantForm.value.cautionHighThreshold == null) {
        this.DegradedCoolantForm.value.warningLowThreshold = '';
      }
      else {
        this.DegradedCoolantForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    
    else if (this.currentKeyIssueData.keyIssueId == 10) {
      if (this.ExcessiveContaminationForm.value.normalHighThreshold != '' && this.ExcessiveContaminationForm.value.normalHighThreshold != null) {
        var excessiveWatchLow = parseInt(this.ExcessiveContaminationForm.value.normalHighThreshold);
        this.ExcessiveContaminationForm.controls['watchLowThreshold'].patchValue(excessiveWatchLow);
      }
      else if (this.ExcessiveContaminationForm.value.normalHighThreshold == null) {
        this.ExcessiveContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.ExcessiveContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.ExcessiveContaminationForm.value.watchHighThreshold != '' && this.ExcessiveContaminationForm.value.watchHighThreshold != null) {
        var excessiveCautionLow = parseInt(this.ExcessiveContaminationForm.value.watchHighThreshold) + precisionValue;
        this.ExcessiveContaminationForm.controls['cautionLowThreshold'].patchValue(excessiveCautionLow);
      }
      else if (this.ExcessiveContaminationForm.value.watchHighThreshold == null) {
        this.ExcessiveContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.ExcessiveContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.ExcessiveContaminationForm.value.cautionHighThreshold != '' && this.ExcessiveContaminationForm.value.cautionHighThreshold != null) {
        var excessiveWarningLow = parseInt(this.ExcessiveContaminationForm.value.cautionHighThreshold);
        this.ExcessiveContaminationForm.controls['warningLowThreshold'].patchValue(excessiveWarningLow);
      }
      else if (this.ExcessiveContaminationForm.value.cautionHighThreshold == null) {
        this.ExcessiveContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.ExcessiveContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 11) {
      if (this.HardParticleForm.value.normalHighThreshold != '' && this.HardParticleForm.value.normalHighThreshold != null) {
        var hardParticleWatchLow = parseInt(this.HardParticleForm.value.normalHighThreshold);
        this.HardParticleForm.controls['watchLowThreshold'].patchValue(hardParticleWatchLow);
      }
      else if (this.HardParticleForm.value.normalHighThreshold == null) {
        this.HardParticleForm.value.watchLowThreshold = '';
      }
      else {
        this.HardParticleForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.HardParticleForm.value.watchHighThreshold != '' && this.HardParticleForm.value.watchHighThreshold != null) {
        var hardParticleCautionLow = parseInt(this.HardParticleForm.value.watchHighThreshold) + precisionValue;
        this.HardParticleForm.controls['cautionLowThreshold'].patchValue(hardParticleCautionLow);
      }
      else if (this.HardParticleForm.value.watchHighThreshold == null) {
        this.HardParticleForm.value.cautionLowThreshold = '';
      }
      else {
        this.HardParticleForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.HardParticleForm.value.cautionHighThreshold != '' && this.HardParticleForm.value.cautionHighThreshold != null) {
        var hardParticleWarningLow = parseInt(this.HardParticleForm.value.cautionHighThreshold);
        this.HardParticleForm.controls['warningLowThreshold'].patchValue(hardParticleWarningLow);
      }
      else if (this.HardParticleForm.value.cautionHighThreshold == null) {
        this.HardParticleForm.value.warningLowThreshold = '';
      }
      else {
        this.HardParticleForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 14) {
      if (this.TraceMetalsForm.value.normalHighThreshold != '' && this.TraceMetalsForm.value.normalHighThreshold != null) {
        var traceMetalsWatchLow = parseInt(this.TraceMetalsForm.value.normalHighThreshold);
        this.TraceMetalsForm.controls['watchLowThreshold'].patchValue(traceMetalsWatchLow);
      }
      else if (this.TraceMetalsForm.value.normalHighThreshold == null) {
        this.TraceMetalsForm.value.watchLowThreshold = '';
      }
      else {
        this.TraceMetalsForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.TraceMetalsForm.value.watchHighThreshold != '' && this.TraceMetalsForm.value.watchHighThreshold != null) {
        var traceMetalsCautionLow = parseInt(this.TraceMetalsForm.value.watchHighThreshold) + precisionValue;
        this.TraceMetalsForm.controls['cautionLowThreshold'].patchValue(traceMetalsCautionLow);
      }
      else if (this.TraceMetalsForm.value.watchHighThreshold == null) {
        this.TraceMetalsForm.value.cautionLowThreshold = '';
      }
      else {
        this.TraceMetalsForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.TraceMetalsForm.value.cautionHighThreshold != '' && this.TraceMetalsForm.value.cautionHighThreshold != null) {
        var traceMetalsWarningLow = parseInt(this.TraceMetalsForm.value.cautionHighThreshold);
        this.TraceMetalsForm.controls['warningLowThreshold'].patchValue(traceMetalsWarningLow);
      }
      else if (this.TraceMetalsForm.value.cautionHighThreshold == null) {
        this.TraceMetalsForm.value.warningLowThreshold = '';
      }
      else {
        this.TraceMetalsForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 15) {
      if (this.DegradedFuelForm.value.normalHighThreshold != '' && this.DegradedFuelForm.value.normalHighThreshold != null) {
        var traceMetalsWatchLow = parseInt(this.DegradedFuelForm.value.normalHighThreshold);
        this.DegradedFuelForm.controls['watchLowThreshold'].patchValue(traceMetalsWatchLow);
      }
      else if (this.DegradedFuelForm.value.normalHighThreshold == null) {
        this.DegradedFuelForm.value.watchLowThreshold = '';
      }
      else {
        this.DegradedFuelForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.DegradedFuelForm.value.watchHighThreshold != '' && this.DegradedFuelForm.value.watchHighThreshold != null) {
        var traceMetalsCautionLow = parseInt(this.DegradedFuelForm.value.watchHighThreshold) + precisionValue;
        this.DegradedFuelForm.controls['cautionLowThreshold'].patchValue(traceMetalsCautionLow);
      }
      else if (this.DegradedFuelForm.value.watchHighThreshold == null) {
        this.DegradedFuelForm.value.cautionLowThreshold = '';
      }
      else {
        this.DegradedFuelForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.DegradedFuelForm.value.cautionHighThreshold != '' && this.DegradedFuelForm.value.cautionHighThreshold != null) {
        var traceMetalsWarningLow = parseInt(this.DegradedFuelForm.value.cautionHighThreshold);
        this.DegradedFuelForm.controls['warningLowThreshold'].patchValue(traceMetalsWarningLow);
      }
      else if (this.DegradedFuelForm.value.cautionHighThreshold == null) {
        this.DegradedFuelForm.value.warningLowThreshold = '';
      }
      else {
        this.DegradedFuelForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 12) {
      if (this.SulfurContaminationForm.value.normalHighThreshold != '' && this.SulfurContaminationForm.value.normalHighThreshold != null) {
        var sulfurContaminationWatchLow = parseInt(this.SulfurContaminationForm.value.normalHighThreshold);
        this.SulfurContaminationForm.controls['watchLowThreshold'].patchValue(sulfurContaminationWatchLow);
      }
      else if (this.SulfurContaminationForm.value.normalHighThreshold == null) {
        this.SulfurContaminationForm.value.watchLowThreshold = '';
      }
      else {
        this.SulfurContaminationForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.SulfurContaminationForm.value.watchHighThreshold != '' && this.SulfurContaminationForm.value.watchHighThreshold != null) {
        var sulfurContaminationCautionLow = parseInt(this.SulfurContaminationForm.value.watchHighThreshold) + precisionValue;
        this.SulfurContaminationForm.controls['cautionLowThreshold'].patchValue(sulfurContaminationCautionLow);
      }
      else if (this.SulfurContaminationForm.value.watchHighThreshold == null) {
        this.SulfurContaminationForm.value.cautionLowThreshold = '';
      }
      else {
        this.SulfurContaminationForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.SulfurContaminationForm.value.cautionHighThreshold != '' && this.SulfurContaminationForm.value.cautionHighThreshold != null) {
        var sulfurContaminationWarningLow = parseInt(this.SulfurContaminationForm.value.cautionHighThreshold);
        this.SulfurContaminationForm.controls['warningLowThreshold'].patchValue(sulfurContaminationWarningLow);
      }
      else if (this.SulfurContaminationForm.value.cautionHighThreshold == null) {
        this.SulfurContaminationForm.value.warningLowThreshold = '';
      }
      else {
        this.SulfurContaminationForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 13) {
      if (this.BacteriaContentForm.value.normalHighThreshold != '' && this.BacteriaContentForm.value.normalHighThreshold != null) {
        var bacteriaContentWatchLow = parseInt(this.BacteriaContentForm.value.normalHighThreshold);
        this.BacteriaContentForm.controls['watchLowThreshold'].patchValue(bacteriaContentWatchLow);
      }
      else if (this.BacteriaContentForm.value.normalHighThreshold == null) {
        this.BacteriaContentForm.value.watchLowThreshold = '';
      }
      else {
        this.BacteriaContentForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.BacteriaContentForm.value.watchHighThreshold != '' && this.BacteriaContentForm.value.watchHighThreshold != null) {
        var bacteriaContentCautionLow = parseInt(this.BacteriaContentForm.value.watchHighThreshold) + precisionValue;
        this.BacteriaContentForm.controls['cautionLowThreshold'].patchValue(bacteriaContentCautionLow);
      }
      else if (this.BacteriaContentForm.value.watchHighThreshold == null) {
        this.BacteriaContentForm.value.cautionLowThreshold = '';
      }
      else {
        this.BacteriaContentForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.BacteriaContentForm.value.cautionHighThreshold != '' && this.BacteriaContentForm.value.cautionHighThreshold != null) {
        var bacteriaContentWarningLow = parseInt(this.BacteriaContentForm.value.cautionHighThreshold);
        this.BacteriaContentForm.controls['warningLowThreshold'].patchValue(bacteriaContentWarningLow);
      }
      else if (this.BacteriaContentForm.value.cautionHighThreshold == null) {
        this.BacteriaContentForm.value.warningLowThreshold = '';
      }
      else {
        this.BacteriaContentForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 16) {
      if (this.FuelCleanlinessForm.value.normalHighThreshold != '' && this.FuelCleanlinessForm.value.normalHighThreshold != null) {
        var fuelCleanlinessWatchLow = parseInt(this.FuelCleanlinessForm.value.normalHighThreshold);
        this.FuelCleanlinessForm.controls['watchLowThreshold'].patchValue(fuelCleanlinessWatchLow);
      }
      else if (this.FuelCleanlinessForm.value.normalHighThreshold == null) {
        this.FuelCleanlinessForm.value.watchLowThreshold = '';
      }
      else {
        this.FuelCleanlinessForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.FuelCleanlinessForm.value.watchHighThreshold != '' && this.FuelCleanlinessForm.value.watchHighThreshold != null) {
        var fuelCleanlinessCautionLow = parseInt(this.FuelCleanlinessForm.value.watchHighThreshold) + precisionValue;
        this.FuelCleanlinessForm.controls['cautionLowThreshold'].patchValue(fuelCleanlinessCautionLow);
      }
      else if (this.FuelCleanlinessForm.value.watchHighThreshold == null) {
        this.FuelCleanlinessForm.value.cautionLowThreshold = '';
      }
      else {
        this.FuelCleanlinessForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.FuelCleanlinessForm.value.cautionHighThreshold != '' && this.FuelCleanlinessForm.value.cautionHighThreshold != null) {
        var fuelCleanlinessWarningLow = parseInt(this.FuelCleanlinessForm.value.cautionHighThreshold);
        this.FuelCleanlinessForm.controls['warningLowThreshold'].patchValue(fuelCleanlinessWarningLow);
      }
      else if (this.FuelCleanlinessForm.value.cautionHighThreshold == null) {
        this.FuelCleanlinessForm.value.warningLowThreshold = '';
      }
      else {
        this.FuelCleanlinessForm.controls['warningLowThreshold'].patchValue('');
      }
    }
    else if (this.currentKeyIssueData.keyIssueId == 17) {
      if (this.FuelCharacteristicsForm.value.normalHighThreshold != '' && this.FuelCharacteristicsForm.value.normalHighThreshold != null) {
        var fuelCleanlinessWatchLow = parseInt(this.FuelCharacteristicsForm.value.normalHighThreshold);
        this.FuelCharacteristicsForm.controls['watchLowThreshold'].patchValue(fuelCleanlinessWatchLow);
      }
      else if (this.FuelCharacteristicsForm.value.normalHighThreshold == null) {
        this.FuelCharacteristicsForm.value.watchLowThreshold = '';
      }
      else {
        this.FuelCharacteristicsForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.FuelCharacteristicsForm.value.watchHighThreshold != '' && this.FuelCharacteristicsForm.value.watchHighThreshold != null) {
        var fuelCharacteristicsCautionLow = parseInt(this.FuelCharacteristicsForm.value.watchHighThreshold) + precisionValue;
        this.FuelCharacteristicsForm.controls['cautionLowThreshold'].patchValue(fuelCharacteristicsCautionLow);
      }
      else if (this.FuelCharacteristicsForm.value.watchHighThreshold == null) {
        this.FuelCharacteristicsForm.value.cautionLowThreshold = '';
      }
      else {
        this.FuelCharacteristicsForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.FuelCharacteristicsForm.value.cautionHighThreshold != '' && this.FuelCharacteristicsForm.value.cautionHighThreshold != null) {
        var fuelCharacteristicsWarningLow = parseInt(this.FuelCharacteristicsForm.value.cautionHighThreshold);
        this.FuelCharacteristicsForm.controls['warningLowThreshold'].patchValue(fuelCharacteristicsWarningLow);
      }
      else if (this.FuelCharacteristicsForm.value.cautionHighThreshold == null) {
        this.FuelCharacteristicsForm.value.warningLowThreshold = '';
      }
      else {
        this.FuelCharacteristicsForm.controls['warningLowThreshold'].patchValue('');
      }
    }

    else if (this.currentKeyIssueData.keyIssueId == 18) {
      if (this.ElectricalConductivityForm.value.normalHighThreshold != '' && this.ElectricalConductivityForm.value.normalHighThreshold != null) {
        var electricalConductivityWatchLow = parseInt(this.ElectricalConductivityForm.value.normalHighThreshold);
        this.ElectricalConductivityForm.controls['watchLowThreshold'].patchValue(electricalConductivityWatchLow);
      }
      else if (this.ElectricalConductivityForm.value.normalHighThreshold == null) {
        this.ElectricalConductivityForm.value.watchLowThreshold = '';
      }
      else {
        this.ElectricalConductivityForm.controls['watchLowThreshold'].patchValue('');
      }

      if (this.ElectricalConductivityForm.value.watchHighThreshold != '' && this.ElectricalConductivityForm.value.watchHighThreshold != null) {
        var electricalConductivityCautionLow = parseInt(this.ElectricalConductivityForm.value.watchHighThreshold) + precisionValue;
        this.ElectricalConductivityForm.controls['cautionLowThreshold'].patchValue(electricalConductivityCautionLow);
      }
      else if (this.ElectricalConductivityForm.value.watchHighThreshold == null) {
        this.ElectricalConductivityForm.value.cautionLowThreshold = '';
      }
      else {
        this.ElectricalConductivityForm.controls['cautionLowThreshold'].patchValue('');
      }

      if (this.ElectricalConductivityForm.value.cautionHighThreshold != '' && this.ElectricalConductivityForm.value.cautionHighThreshold != null) {
        var electricalConductivityWarningLow = parseInt(this.ElectricalConductivityForm.value.cautionHighThreshold);
        this.ElectricalConductivityForm.controls['warningLowThreshold'].patchValue(electricalConductivityWarningLow);
      }
      else if (this.ElectricalConductivityForm.value.cautionHighThreshold == null) {
        this.ElectricalConductivityForm.value.warningLowThreshold = '';
      }
      else {
        this.ElectricalConductivityForm.controls['warningLowThreshold'].patchValue('');
      }
    }
  }

  openConfirmModal() {
    this.confirmModal = true;
  }

  closeConfirmModal() {
    this.confirmModal = false;
  }

  /* ------------------------Apply Key Issues Button----------------------- */
  applyProposedValues() {
    let req = {};
    if (this.currentKeyIssueData.keyIssueId == 1) {
      req = this.coolantContaminationForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 2) {
      req = this.DustContaminationForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 3) {
      req = this.FuelOrSootContaminationForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 4) {
      req = this.DegradedOilForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 5) {
      req = this.FuelOrSootContaminationForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 6) {
      req = this.engineWearForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 7) {
      req = this.waterContaminationForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 10){
      req = this.ExcessiveContaminationForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 9) {
      req = this.DegradedCoolantForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 8) {
      req = this.SystemCorrosionForm.value;
    } else if (this.currentKeyIssueData.keyIssueId == 11){
      req = this.HardParticleForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 12){
      req = this.SulfurContaminationForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 13){
      req = this.BacteriaContentForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 14){
      req = this.TraceMetalsForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 15){
      req = this.DegradedFuelForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 16){
      req = this.FuelCleanlinessForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 17){
      req = this.FuelCharacteristicsForm.value;
    }else if (this.currentKeyIssueData.keyIssueId == 18){
      req = this.ElectricalConductivityForm.value;
      console.log('EC data', req);
    }else if (this.currentKeyIssueData.keyIssueId == 19){
      req = this.ParticleContaminationForm.value;
    }
    else if (this.currentKeyIssueData.keyIssueId == 20){
      req = this.metalWearForm.value;
    }
    this.closeConfirmModal();
    this.editKeyIssuesService.updateKeyIssueData(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          console.log('response data for EC', response);
          this.successModal = true;
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  validateSoot(){
    /*if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalNormal.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoNormal.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoWarning.setErrors(null); 
    }*/

    this.alertService.clear();
    if(Number(this.FuelOrSootContaminationForm.controls.normalHighThreshold.value) >= Number(this.FuelOrSootContaminationForm.controls.watchHighThreshold.value)){
      this.FuelOrSootContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.FuelOrSootContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.cautionLowThreshold.value) >= Number(this.FuelOrSootContaminationForm.controls.cautionHighThreshold.value)){
      this.FuelOrSootContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true});
      this.alertService.error('Kindly enter values greater than the previous one'); 
    }else{
      this.FuelOrSootContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  validateFuel(){
    /*if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalNormal.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoNormal.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoWarning.setErrors(null); 
    }*/

    this.alertService.clear();
    if(Number(this.FuelOrSootContaminationForm.controls.normalHighThreshold.value) >= Number(this.FuelOrSootContaminationForm.controls.watchHighThreshold.value)){
      this.FuelOrSootContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.FuelOrSootContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.cautionLowThreshold.value) >= Number(this.FuelOrSootContaminationForm.controls.cautionHighThreshold.value)){
      this.FuelOrSootContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true});
      this.alertService.error('Kindly enter values greater than the previous one'); 
    }else{
      this.FuelOrSootContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }
  validateDegradedCoolant(){
    this.alertService.clear();
      if(Number(this.DegradedCoolantForm.controls.normalHighThreshold.value) >= Number(this.DegradedCoolantForm.controls.watchHighThreshold.value)){
        this.DegradedCoolantForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
      }else{
        this.DegradedCoolantForm.controls.watchHighThreshold.setErrors(null); 
      }
  
      if(Number(this.DegradedCoolantForm.controls.cautionLowThreshold.value) >= Number(this.DegradedCoolantForm.controls.cautionHighThreshold.value)){
        this.DegradedCoolantForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
      }else{
        this.DegradedCoolantForm.controls.cautionHighThreshold.setErrors(null); 
      }
    }
    validateBacteriaContent(){
      this.alertService.clear();
        if(Number(this.BacteriaContentForm.controls.normalHighThreshold.value) >= Number(this.BacteriaContentForm.controls.watchHighThreshold.value)){
          this.BacteriaContentForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
          this.alertService.error('Kindly enter values greater than the previous one');
        }else{
          this.BacteriaContentForm.controls.watchHighThreshold.setErrors(null); 
        }
    
        if(Number(this.BacteriaContentForm.controls.cautionLowThreshold.value) >= Number(this.BacteriaContentForm.controls.cautionHighThreshold.value)){
          this.BacteriaContentForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
          this.alertService.error('Kindly enter values greater than the previous one');
        }else{
          this.BacteriaContentForm.controls.cautionHighThreshold.setErrors(null); 
        }
      }
      validateFuelCleanliness(){
        this.alertService.clear();
          if(Number(this.FuelCleanlinessForm.controls.normalHighThreshold.value) >= Number(this.FuelCleanlinessForm.controls.watchHighThreshold.value)){
            this.FuelCleanlinessForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
            this.alertService.error('Kindly enter values greater than the previous one');
          }else{
            this.FuelCleanlinessForm.controls.watchHighThreshold.setErrors(null); 
          }
      
          if(Number(this.FuelCleanlinessForm.controls.cautionLowThreshold.value) >= Number(this.FuelCleanlinessForm.controls.cautionHighThreshold.value)){
            this.FuelCleanlinessForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
            this.alertService.error('Kindly enter values greater than the previous one');
          }else{
            this.FuelCleanlinessForm.controls.cautionHighThreshold.setErrors(null); 
          }
        }
        validateFuelCharacteristics(){
          this.alertService.clear();
            if(Number(this.FuelCharacteristicsForm.controls.normalHighThreshold.value) >= Number(this.FuelCharacteristicsForm.controls.watchHighThreshold.value)){
              this.FuelCharacteristicsForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
              this.alertService.error('Kindly enter values greater than the previous one');
            }else{
              this.FuelCharacteristicsForm.controls.watchHighThreshold.setErrors(null); 
            }
        
            if(Number(this.FuelCharacteristicsForm.controls.cautionLowThreshold.value) >= Number(this.FuelCharacteristicsForm.controls.cautionHighThreshold.value)){
              this.FuelCharacteristicsForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
              this.alertService.error('Kindly enter values greater than the previous one');
            }else{
              this.FuelCharacteristicsForm.controls.cautionHighThreshold.setErrors(null); 
            }
          }
      
      validateSulfurContamination(){
        this.alertService.clear();
          if(Number(this.SulfurContaminationForm.controls.normalHighThreshold.value) >= Number(this.SulfurContaminationForm.controls.watchHighThreshold.value)){
            this.SulfurContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
            this.alertService.error('Kindly enter values greater than the previous one');
          }else{
            this.SulfurContaminationForm.controls.watchHighThreshold.setErrors(null); 
          }
      
          if(Number(this.SulfurContaminationForm.controls.cautionLowThreshold.value) >= Number(this.SulfurContaminationForm.controls.cautionHighThreshold.value)){
            this.SulfurContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
            this.alertService.error('Kindly enter values greater than the previous one');
          }else{
            this.SulfurContaminationForm.controls.cautionHighThreshold.setErrors(null); 
          }
        }
      
    validateSystemCorrosion(){
      this.alertService.clear();
      if(Number(this.SystemCorrosionForm.controls.normalHighThreshold.value) >= Number(this.SystemCorrosionForm.controls.watchHighThreshold.value)){
        this.SystemCorrosionForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
        this.scrollToTop = true;
      }
      else{
        this.SystemCorrosionForm.controls.watchHighThreshold.setErrors(null); 
      }
  
      if(Number(this.SystemCorrosionForm.controls.cautionLowThreshold.value) >= Number(this.SystemCorrosionForm.controls.cautionHighThreshold.value)){
        this.SystemCorrosionForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
        this.scrollToTop = true;
      }else{
        this.SystemCorrosionForm.controls.cautionHighThreshold.setErrors(null); 
      }
    }
    validateTraceMetals(){
      this.alertService.clear();
      if(Number(this.TraceMetalsForm.controls.normalHighThreshold.value) >= Number(this.TraceMetalsForm.controls.watchHighThreshold.value)){
        this.TraceMetalsForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
        //this.scrollToTop = true;
      }
      else{
        this.TraceMetalsForm.controls.watchHighThreshold.setErrors(null); 
      }
  
      if(Number(this.TraceMetalsForm.controls.cautionLowThreshold.value) >= Number(this.TraceMetalsForm.controls.cautionHighThreshold.value)){
        this.TraceMetalsForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
        //this.scrollToTop = true;
      }else{
        this.TraceMetalsForm.controls.cautionHighThreshold.setErrors(null); 
      }
    }
    validateDegradedFuel(){
      this.alertService.clear();
      if(Number(this.DegradedFuelForm.controls.normalHighThreshold.value) >= Number(this.DegradedFuelForm.controls.watchHighThreshold.value)){
        this.DegradedFuelForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
       
      }
      else{
        this.DegradedFuelForm.controls.watchHighThreshold.setErrors(null); 
      }
  
      if(Number(this.DegradedFuelForm.controls.cautionLowThreshold.value) >= Number(this.DegradedFuelForm.controls.cautionHighThreshold.value)){
        this.DegradedFuelForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
       // this.scrollToTop = true;
      }else{
        this.DegradedFuelForm.controls.cautionHighThreshold.setErrors(null); 
      }
    }
  
  validateCoolant(){
    /*if(Number(this.coolantContaminationForm.controls.factorOneNormalNormal.value) >= Number(this.coolantContaminationForm.controls.factorOneNormalWatch.value)){
      this.coolantContaminationForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneNormalWatch.value) >= Number(this.coolantContaminationForm.controls.factorOneNormalCaution.value)){
      this.coolantContaminationForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneNormalCaution.value) >= Number(this.coolantContaminationForm.controls.factorOneWatchWatch.value)){
      this.coolantContaminationForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneWatchWatch.value) >= Number(this.coolantContaminationForm.controls.factorOneNormalWarning.value)){
      this.coolantContaminationForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneNormalWarning.value) >= Number(this.coolantContaminationForm.controls.factorOneWatchCaution.value)){
      this.coolantContaminationForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneWatchCaution.value) >= Number(this.coolantContaminationForm.controls.factorOneWatchWarning.value)){
      this.coolantContaminationForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneWatchWarning.value) >= Number(this.coolantContaminationForm.controls.factorOneCautionCaution.value)){
      this.coolantContaminationForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneCautionCaution.value) >= Number(this.coolantContaminationForm.controls.factorOneCautionWarning.value)){
      this.coolantContaminationForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneCautionWarning.value) >= Number(this.coolantContaminationForm.controls.factorOneWarningWarning.value)){
      this.coolantContaminationForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorTwoNormal.value) >= Number(this.coolantContaminationForm.controls.factorTwoWatch.value)){
      this.coolantContaminationForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorTwoWatch.value) >= Number(this.coolantContaminationForm.controls.factorTwoCaution.value)){
      this.coolantContaminationForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorTwoCaution.value) >= Number(this.coolantContaminationForm.controls.factorTwoWarning.value)){
      this.coolantContaminationForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorTwoWarning.setErrors(null); 
    }

    if(this.coolantContaminationForm.controls.radioSelect.value == 'less'){
      if(Number(this.coolantContaminationForm.controls.factorThreeNormal.value) >= Number(this.coolantContaminationForm.controls.factorThreeWatch.value)){
        this.coolantContaminationForm.controls.factorThreeWatch.setErrors({'invalidNumber': true}); 
      }else{
        this.coolantContaminationForm.controls.factorThreeWatch.setErrors(null); 
      }
      if(Number(this.coolantContaminationForm.controls.factorThreeWatch.value) >= Number(this.coolantContaminationForm.controls.factorThreeCaution.value)){
        this.coolantContaminationForm.controls.factorThreeCaution.setErrors({'invalidNumber': true}); 
      }else{
        this.coolantContaminationForm.controls.factorThreeCaution.setErrors(null); 
      }
      if(Number(this.coolantContaminationForm.controls.factorThreeCaution.value) >= Number(this.coolantContaminationForm.controls.factorThreeWarning.value)){
        this.coolantContaminationForm.controls.factorThreeWarning.setErrors({'invalidNumber': true}); 
      }else{
        this.coolantContaminationForm.controls.factorThreeWarning.setErrors(null); 
      }
    }*/

    this.alertService.clear();
    if(Number(this.coolantContaminationForm.controls.normalHighThreshold.value) >= Number(this.coolantContaminationForm.controls.watchHighThreshold.value)){
      this.coolantContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.coolantContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.cautionLowThreshold.value) >= Number(this.coolantContaminationForm.controls.cautionHighThreshold.value)){
      this.coolantContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.coolantContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  validateDust(){
        
    /*if(Number(this.DustContaminationForm.controls.factorOneNormalNormal.value) >= Number(this.DustContaminationForm.controls.factorOneNormalWatch.value)){
      this.DustContaminationForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneNormalWatch.value) >= Number(this.DustContaminationForm.controls.factorOneNormalCaution.value)){
      this.DustContaminationForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneNormalCaution.value) >= Number(this.DustContaminationForm.controls.factorOneWatchWatch.value)){
      this.DustContaminationForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneWatchWatch.value) >= Number(this.DustContaminationForm.controls.factorOneNormalWarning.value)){
      this.DustContaminationForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneNormalWarning.value) >= Number(this.DustContaminationForm.controls.factorOneWatchCaution.value)){
      this.DustContaminationForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneWatchCaution.value) >= Number(this.DustContaminationForm.controls.factorOneWatchWarning.value)){
      this.DustContaminationForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneWatchWarning.value) >= Number(this.DustContaminationForm.controls.factorOneCautionCaution.value)){
      this.DustContaminationForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneCautionCaution.value) >= Number(this.DustContaminationForm.controls.factorOneCautionWarning.value)){
      this.DustContaminationForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorOneCautionWarning.value) >= Number(this.DustContaminationForm.controls.factorOneWarningWarning.value)){
      this.DustContaminationForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorTwoNormal.value) >= Number(this.DustContaminationForm.controls.factorTwoWatch.value)){
      this.DustContaminationForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorTwoWatch.value) >= Number(this.DustContaminationForm.controls.factorTwoCaution.value)){
      this.DustContaminationForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.factorTwoCaution.value) >= Number(this.DustContaminationForm.controls.factorTwoWarning.value)){
      this.DustContaminationForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DustContaminationForm.controls.factorTwoWarning.setErrors(null); 
    }

    if(this.DustContaminationForm.controls.radioSelect.value == 'less'){
      if(Number(this.DustContaminationForm.controls.factorThreeNormal.value) >= Number(this.DustContaminationForm.controls.factorThreeWatch.value)){
        this.DustContaminationForm.controls.factorThreeWatch.setErrors({'invalidNumber': true}); 
      }else{
        this.DustContaminationForm.controls.factorThreeWatch.setErrors(null); 
      }
      if(Number(this.DustContaminationForm.controls.factorThreeWatch.value) >= Number(this.DustContaminationForm.controls.factorThreeCaution.value)){
        this.DustContaminationForm.controls.factorThreeCaution.setErrors({'invalidNumber': true}); 
      }else{
        this.DustContaminationForm.controls.factorThreeCaution.setErrors(null); 
      }
      if(Number(this.DustContaminationForm.controls.factorThreeCaution.value) >= Number(this.DustContaminationForm.controls.factorThreeWarning.value)){
        this.DustContaminationForm.controls.factorThreeWarning.setErrors({'invalidNumber': true}); 
      }else{
        this.DustContaminationForm.controls.factorThreeWarning.setErrors(null); 
      }
    }*/

    this.alertService.clear();
    if(Number(this.DustContaminationForm.controls.normalHighThreshold.value) >= Number(this.DustContaminationForm.controls.watchHighThreshold.value)){
      this.DustContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.DustContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.DustContaminationForm.controls.cautionLowThreshold.value) >= Number(this.DustContaminationForm.controls.cautionHighThreshold.value)){
      this.DustContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.DustContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  validateEngineWear(){
    /*if(Number(this.engineWearForm.controls.factorOneNormalNormal.value) >= Number(this.engineWearForm.controls.factorOneNormalWatch.value)){
      this.engineWearForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneNormalWatch.value) >= Number(this.engineWearForm.controls.factorOneNormalCaution.value)){
      this.engineWearForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneNormalCaution.value) >= Number(this.engineWearForm.controls.factorOneWatchWatch.value)){
      this.engineWearForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneWatchWatch.value) >= Number(this.engineWearForm.controls.factorOneNormalWarning.value)){
      this.engineWearForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneNormalWarning.value) >= Number(this.engineWearForm.controls.factorOneWatchCaution.value)){
      this.engineWearForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneWatchCaution.value) >= Number(this.engineWearForm.controls.factorOneWatchWarning.value)){
      this.engineWearForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneWatchWarning.value) >= Number(this.engineWearForm.controls.factorOneCautionCaution.value)){
      this.engineWearForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneCautionCaution.value) >= Number(this.engineWearForm.controls.factorOneCautionWarning.value)){
      this.engineWearForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.factorOneCautionWarning.value) >= Number(this.engineWearForm.controls.factorOneWarningWarning.value)){
      this.engineWearForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorOneWarningWarning.setErrors(null); 
    }
	
	//factor two
    if(Number(this.engineWearForm.controls.factorTwoNormalNormal.value) >= Number(this.engineWearForm.controls.factorTwoNormalWatch.value)){
      this.engineWearForm.controls.factorTwoNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoNormalWatch.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoNormalWatch.value) >= Number(this.engineWearForm.controls.factorTwoNormalCaution.value)){
      this.engineWearForm.controls.factorTwoNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoNormalCaution.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoNormalCaution.value) >= Number(this.engineWearForm.controls.factorTwoWatchWatch.value)){
      this.engineWearForm.controls.factorTwoWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoWatchWatch.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoWatchWatch.value) >= Number(this.engineWearForm.controls.factorTwoNormalWarning.value)){
      this.engineWearForm.controls.factorTwoNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoNormalWarning.setErrors(null); 
    }
    if(Number(this.engineWearForm.controls.factorTwoNormalWarning.value) >= Number(this.engineWearForm.controls.factorTwoWatchCaution.value)){
      this.engineWearForm.controls.factorTwoWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoWatchCaution.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoWatchCaution.value) >= Number(this.engineWearForm.controls.factorTwoWatchWarning.value)){
      this.engineWearForm.controls.factorTwoWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoWatchWarning.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoWatchWarning.value) >= Number(this.engineWearForm.controls.factorTwoCautionCaution.value)){
      this.engineWearForm.controls.factorTwoCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoCautionCaution.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoCautionCaution.value) >= Number(this.engineWearForm.controls.factorTwoCautionWarning.value)){
      this.engineWearForm.controls.factorTwoCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoCautionWarning.setErrors(null); 
    }
	  if(Number(this.engineWearForm.controls.factorTwoCautionWarning.value) >= Number(this.engineWearForm.controls.factorTwoWarningWarning.value)){
      this.engineWearForm.controls.factorTwoWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.engineWearForm.controls.factorTwoWarningWarning.setErrors(null); 
    }*/
    //

    this.alertService.clear();
    if(Number(this.engineWearForm.controls.normalHighThreshold.value) >= Number(this.engineWearForm.controls.watchHighThreshold.value)){
      this.engineWearForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.engineWearForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.engineWearForm.controls.cautionLowThreshold.value) >= Number(this.engineWearForm.controls.cautionHighThreshold.value)){
      this.engineWearForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.engineWearForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  validateMetalWear(){
    this.alertService.clear();
    if(Number(this.metalWearForm.controls.normalHighThreshold.value) >= Number(this.metalWearForm.controls.watchHighThreshold.value)){
      this.metalWearForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.metalWearForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.metalWearForm.controls.cautionLowThreshold.value) >= Number(this.metalWearForm.controls.cautionHighThreshold.value)){
      this.metalWearForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.metalWearForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }


  validateDegradedOil(){
    /*if(Number(this.DegradedOilForm.controls.factorOneNormalNormal.value) >= Number(this.DegradedOilForm.controls.factorOneNormalWatch.value)){
      this.DegradedOilForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneNormalWatch.value) >= Number(this.DegradedOilForm.controls.factorOneNormalCaution.value)){
      this.DegradedOilForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneNormalCaution.value) >= Number(this.DegradedOilForm.controls.factorOneWatchWatch.value)){
      this.DegradedOilForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneWatchWatch.value) >= Number(this.DegradedOilForm.controls.factorOneNormalWarning.value)){
      this.DegradedOilForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneNormalWarning.value) >= Number(this.DegradedOilForm.controls.factorOneWatchCaution.value)){
      this.DegradedOilForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneWatchCaution.value) >= Number(this.DegradedOilForm.controls.factorOneWatchWarning.value)){
      this.DegradedOilForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneWatchWarning.value) >= Number(this.DegradedOilForm.controls.factorOneCautionCaution.value)){
      this.DegradedOilForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneCautionCaution.value) >= Number(this.DegradedOilForm.controls.factorOneCautionWarning.value)){
      this.DegradedOilForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorOneCautionWarning.value) >= Number(this.DegradedOilForm.controls.factorOneWarningWarning.value)){
      this.DegradedOilForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorTwoNormal.value) >= Number(this.DegradedOilForm.controls.factorTwoWatch.value)){
      this.DegradedOilForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorTwoWatch.value) >= Number(this.DegradedOilForm.controls.factorTwoCaution.value)){
      this.DegradedOilForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.factorTwoCaution.value) >= Number(this.DegradedOilForm.controls.factorTwoWarning.value)){
      this.DegradedOilForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.DegradedOilForm.controls.factorTwoWarning.setErrors(null); 
    }*/

    this.alertService.clear();
    if(Number(this.DegradedOilForm.controls.normalHighThreshold.value) >= Number(this.DegradedOilForm.controls.watchHighThreshold.value)){
      this.DegradedOilForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.DegradedOilForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.DegradedOilForm.controls.cautionLowThreshold.value) >= Number(this.DegradedOilForm.controls.cautionHighThreshold.value)){
      this.DegradedOilForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.DegradedOilForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  validateExcessive(){
    /*if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalNormal.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneNormalWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneWatchWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneCautionCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorOneCautionWarning.value) >= Number(this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoNormal.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoWatch.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoWatch.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoCaution.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.FuelOrSootContaminationForm.controls.factorTwoCaution.value) >= Number(this.FuelOrSootContaminationForm.controls.factorTwoWarning.value)){
      this.FuelOrSootContaminationForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.FuelOrSootContaminationForm.controls.factorTwoWarning.setErrors(null); 
    }*/

    this.alertService.clear();
    if(Number(this.ExcessiveContaminationForm.controls.normalHighThreshold.value) >= Number(this.ExcessiveContaminationForm.controls.watchHighThreshold.value)){
      this.ExcessiveContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.ExcessiveContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.ExcessiveContaminationForm.controls.cautionLowThreshold.value) >= Number(this.ExcessiveContaminationForm.controls.cautionHighThreshold.value)){
      this.ExcessiveContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true});
      this.alertService.error('Kindly enter values greater than the previous one'); 
    }else{
      this.ExcessiveContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }
  
  validateHardParticle(){
    /*if(Number(this.coolantContaminationForm.controls.factorOneNormalNormal.value) >= Number(this.coolantContaminationForm.controls.factorOneNormalWatch.value)){
      this.coolantContaminationForm.controls.factorOneNormalWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneNormalWatch.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneNormalWatch.value) >= Number(this.coolantContaminationForm.controls.factorOneNormalCaution.value)){
      this.coolantContaminationForm.controls.factorOneNormalCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneNormalCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneNormalCaution.value) >= Number(this.coolantContaminationForm.controls.factorOneWatchWatch.value)){
      this.coolantContaminationForm.controls.factorOneWatchWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWatchWatch.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneWatchWatch.value) >= Number(this.coolantContaminationForm.controls.factorOneNormalWarning.value)){
      this.coolantContaminationForm.controls.factorOneNormalWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneNormalWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneNormalWarning.value) >= Number(this.coolantContaminationForm.controls.factorOneWatchCaution.value)){
      this.coolantContaminationForm.controls.factorOneWatchCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWatchCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneWatchCaution.value) >= Number(this.coolantContaminationForm.controls.factorOneWatchWarning.value)){
      this.coolantContaminationForm.controls.factorOneWatchWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWatchWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneWatchWarning.value) >= Number(this.coolantContaminationForm.controls.factorOneCautionCaution.value)){
      this.coolantContaminationForm.controls.factorOneCautionCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneCautionCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneCautionCaution.value) >= Number(this.coolantContaminationForm.controls.factorOneCautionWarning.value)){
      this.coolantContaminationForm.controls.factorOneCautionWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneCautionWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorOneCautionWarning.value) >= Number(this.coolantContaminationForm.controls.factorOneWarningWarning.value)){
      this.coolantContaminationForm.controls.factorOneWarningWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorOneWarningWarning.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorTwoNormal.value) >= Number(this.coolantContaminationForm.controls.factorTwoWatch.value)){
      this.coolantContaminationForm.controls.factorTwoWatch.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorTwoWatch.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorTwoWatch.value) >= Number(this.coolantContaminationForm.controls.factorTwoCaution.value)){
      this.coolantContaminationForm.controls.factorTwoCaution.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorTwoCaution.setErrors(null); 
    }

    if(Number(this.coolantContaminationForm.controls.factorTwoCaution.value) >= Number(this.coolantContaminationForm.controls.factorTwoWarning.value)){
      this.coolantContaminationForm.controls.factorTwoWarning.setErrors({'invalidNumber': true}); 
    }else{
      this.coolantContaminationForm.controls.factorTwoWarning.setErrors(null); 
    }

    if(this.coolantContaminationForm.controls.radioSelect.value == 'less'){
      if(Number(this.coolantContaminationForm.controls.factorThreeNormal.value) >= Number(this.coolantContaminationForm.controls.factorThreeWatch.value)){
        this.coolantContaminationForm.controls.factorThreeWatch.setErrors({'invalidNumber': true}); 
      }else{
        this.coolantContaminationForm.controls.factorThreeWatch.setErrors(null); 
      }
      if(Number(this.coolantContaminationForm.controls.factorThreeWatch.value) >= Number(this.coolantContaminationForm.controls.factorThreeCaution.value)){
        this.coolantContaminationForm.controls.factorThreeCaution.setErrors({'invalidNumber': true}); 
      }else{
        this.coolantContaminationForm.controls.factorThreeCaution.setErrors(null); 
      }
      if(Number(this.coolantContaminationForm.controls.factorThreeCaution.value) >= Number(this.coolantContaminationForm.controls.factorThreeWarning.value)){
        this.coolantContaminationForm.controls.factorThreeWarning.setErrors({'invalidNumber': true}); 
      }else{
        this.coolantContaminationForm.controls.factorThreeWarning.setErrors(null); 
      }
    }*/

    this.alertService.clear();
    if(Number(this.HardParticleForm.controls.normalHighThreshold.value) >= Number(this.HardParticleForm.controls.watchHighThreshold.value)){
      this.HardParticleForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.HardParticleForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.HardParticleForm.controls.cautionLowThreshold.value) >= Number(this.HardParticleForm.controls.cautionHighThreshold.value)){
      this.HardParticleForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.HardParticleForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  resetWaterContaminationFactorOne(){
    this.getWaterContaminationFactorOne();
    this.validateWaterContamination();
  }

  resetWaterContaminationEdit(){
    this.waterContaminationForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }

  validateWaterContamination(){
    this.alertService.clear();
    if(Number(this.waterContaminationForm.controls.normalHighThreshold.value) >= Number(this.waterContaminationForm.controls.watchHighThreshold.value)){
      this.waterContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.waterContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.waterContaminationForm.controls.cautionLowThreshold.value) >= Number(this.waterContaminationForm.controls.cautionHighThreshold.value)){
      this.waterContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.waterContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  getWaterContaminationFactorOne(){
    this.waterContaminationForm.patchValue({
      factorOneNormal: this.keyIssueResponseData.factorOneNormal,
      factorOneWatch: this.keyIssueResponseData.factorOneWatch,
      factorOneCaution: this.keyIssueResponseData.factorOneCaution,
      factorOneWarning: this.keyIssueResponseData.factorOneWarning      
    });
  }

  getWaterContaminationData() {
    this.getWaterContaminationFactorOne();    
    this.waterContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }

   /* ---------------------------------Electrical Conductivity------------------------------- */
   getElectricalConductivityData() {
    this.getElectricalConductivityFactorOne();
    this.ElectricalConductivityForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
        userId: this.sharedService.getUserId()
    });
  }

  validateElectricalConductivity(){
    this.alertService.clear();
      if(Number(this.ElectricalConductivityForm.controls.normalHighThreshold.value) >= Number(this.ElectricalConductivityForm.controls.watchHighThreshold.value)){
        this.ElectricalConductivityForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
      }else{
        this.ElectricalConductivityForm.controls.watchHighThreshold.setErrors(null); 
      }
  
      if(Number(this.ElectricalConductivityForm.controls.cautionLowThreshold.value) >= Number(this.ElectricalConductivityForm.controls.cautionHighThreshold.value)){
        this.ElectricalConductivityForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
        this.alertService.error('Kindly enter values greater than the previous one');
      }else{
        this.ElectricalConductivityForm.controls.cautionHighThreshold.setErrors(null); 
      }
    }

  getElectricalConductivityFactorOne() {
    this.ElectricalConductivityForm.patchValue({
      factorOneNormal: this.keyIssueResponseData.factorOneNormal,
      factorOneWatch: this.keyIssueResponseData.factorOneWatch,
      factorOneCaution: this.keyIssueResponseData.factorOneCaution,
      factorOneWarning: this.keyIssueResponseData.factorOneWarning
    });
    console.log('ElectricalConductivityForm', this.ElectricalConductivityForm);
  }

  resetElectricalConductivityFactorOne() {
    this.getElectricalConductivityFactorOne();
    this.validateElectricalConductivity();
  }

  resetElectricalConductivityEdit() {
    this.ElectricalConductivityForm.patchValue({
      normalHighThreshold: '',
      watchLowThreshold: '',
      watchHighThreshold: '',
      cautionLowThreshold: '',
      cautionHighThreshold: '',
      warningLowThreshold: '',
    });
  }
  
  validateParticleContamination(){
    this.alertService.clear();
    if(Number(this.ParticleContaminationForm.controls.normalHighThreshold.value) >= Number(this.ParticleContaminationForm.controls.watchHighThreshold.value)){
      this.ParticleContaminationForm.controls.watchHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.ParticleContaminationForm.controls.watchHighThreshold.setErrors(null); 
    }

    if(Number(this.ParticleContaminationForm.controls.cautionLowThreshold.value) >= Number(this.ParticleContaminationForm.controls.cautionHighThreshold.value)){
      this.ParticleContaminationForm.controls.cautionHighThreshold.setErrors({'invalidNumber': true}); 
      this.alertService.error('Kindly enter values greater than the previous one');
    }else{
      this.ParticleContaminationForm.controls.cautionHighThreshold.setErrors(null); 
    }
  }

  getParticleContaminationData() {
    this.getParticleContaminationFactorOne();    
    this.ParticleContaminationForm.patchValue({
      keyIssue: this.currentKeyIssueData.keyIssueId,
      populationId: this.populationDataList.populationId,
      userId: this.sharedService.getUserId()
    });
  }
  getParticleContaminationFactorOne(){
    this.ParticleContaminationForm.patchValue({
      factorOneNormal: this.keyIssueResponseData.factorOneNormal,
      factorOneWatch: this.keyIssueResponseData.factorOneWatch,
      factorOneCaution: this.keyIssueResponseData.factorOneCaution,
      factorOneWarning: this.keyIssueResponseData.factorOneWarning      
    });
  }
}
