import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { ScatterPlotGridService } from './scatter-plot-grid.service';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { Router } from '@angular/router';
import { ScatterPlotSharedService } from './scatter-plot-shared.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE, USERS } from 'src/app/View/common/constants/constant';

@Component({
  selector: 'app-scatter-plot-grid',
  templateUrl: './scatter-plot-grid.component.html',
  styleUrls: ['./scatter-plot-grid.component.css']
})
export class ScatterPlotGridComponent implements OnInit,AfterViewChecked {

  constructor(private scatterService : ScatterPlotGridService,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private router:Router,private sharedScatterService : ScatterPlotSharedService,
    private sharedService : SharedService) { }


  extUserDisplayedColumns: any[] = [];
  extUserDisplayedColumnsData: any[] = [];
  searchObj:any;
  unitIdSelectedValues:any[] = [];

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string = 'desc';
  columnName: string = 'unitId';
  searchOption: any;
  scrollToTop = false;

  extUserFilterObject = {
    unitId: [],
    unitMake: [],
    esn: [],
    engineModel: [],
    unitYear: [],
    distanceUOM:[]
  }

  extUserScatterPlotSearchForm = new UntypedFormGroup({
    checkbox: new UntypedFormControl(''),
    unitId: new UntypedFormControl(''),
    unitMake: new UntypedFormControl(''),
    esn: new UntypedFormControl(''),
    engineModel: new UntypedFormControl(''),
    unitYear: new UntypedFormControl(''),
    distanceUOM: new UntypedFormControl(''),
  });

  /* ------- TSM oe=r DBU Company ID dropdown ---------*/
  userRole: string = '';
  companyIdTSM: any = '';
  tsmCustomerNameDropdown: any;
  companyIdDBU: any = '';
  dbuCustomerNameDropdown: any;

  @ViewChildren(SortComponent) sortComponents;

  ngOnInit(){
    this.userRole = this.sharedService.getUserRole();
    if(localStorage.getItem("customerDropdownTSM") && (this.userRole == USERTYPE.TSM)){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")))      
    } else if(localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_FLUIDS)){
      this.recieveMessageDBU(JSON.parse(localStorage.getItem("branchCustomerDropdown")))      
    }
    this.sortingOrder = "desc";
    this.extUserDisplayedColumns = [
      { code: "checkbox", name: "" },
      { code: "unitId", name: "Unit ID" },
      { code: "unitMake", name: "Unit Make" },
      { code: "esn", name: "Component SN" },
      { code: "engineModel", name: "Engine Model" },
      { code: "unitYear", name: "Year" },
      { code: "distanceUOM", name: "Unit of Measure" }
    ];
    this.unitIdSelectedValues = Object.assign([], this.sharedScatterService.getUnitIds());
    this.searchDashboard();
  }

  recieveMessageTSM(event){
    this.tsmCustomerNameDropdown = event;
    // companyName = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
  }

  recieveMessageDBU(event){
    this.dbuCustomerNameDropdown = event;
    this.companyIdDBU = this.dbuCustomerNameDropdown.customerId;
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  setData(response){
    this.extUserDisplayedColumnsData = response.unitsList;
    this.extUserFilterObject.distanceUOM = response.distanceUOMList.map((row) =>{
      row['distanceUOM'] = row.uom;
      return row;
    });
    if(this.unitIdSelectedValues.length > 0){
      this.unitIdSelectedValues.forEach((item) =>{
        this.extUserDisplayedColumnsData.forEach((unit)=>{
          if(unit.equipId == item.equipId){
            unit['isChecked'] = true;
          }
        });
      });
      this.extUserDisplayedColumnsData.forEach((unit)=>{
        if(unit.isChecked == undefined){
          unit['isChecked'] = false;
        }                    
      });
    }else{
      this.extUserDisplayedColumnsData.forEach((unit)=>{
        unit['isChecked'] = false;
      });
    }
    this.checkboxEnableDisable(); 
    this.totalItems = response.unitsList[0].maxRow;                
    this.calculateOffset();
  }

  /* -------------------------ext User filter value dropdown------------------------- */
  searchFilter(str) {
    let _data = {
      companyId: this.sharedService.getCompanyId(),
      searchColumn: str,
      searchValue: this.extUserScatterPlotSearchForm.value[str],
      unitId: this.extUserScatterPlotSearchForm.value['unitId'],
      unitMake: this.extUserScatterPlotSearchForm.value['unitMake'],
      esn: this.extUserScatterPlotSearchForm.value['esn'],
      engineModel: this.extUserScatterPlotSearchForm.value['engineModel'],
      unitYear: this.extUserScatterPlotSearchForm.value['unitYear'],
      distanceUOM: this.extUserScatterPlotSearchForm.value['distanceUOM'],
      fluidType: this.sharedScatterService.getSelectedFluidType(),
      fluidTypeName: this.sharedScatterService.getFluidTypeName()   
    };
     
    if (str !== 'distanceUOM') {
      if (this.extUserScatterPlotSearchForm.value[str].length >= 2) {
        this.scatterService.getScatterPlotUnitsDashboard(_data).subscribe(
          response => {
            if (response.statusCode == "1") {
              this.extUserFilterObject[str] = response.unitsList;
            } else {
              this.alertService.error(response.statusMessage);
              this.extUserFilterObject[str] = [];
            }
          }, err => {
            this.alertService.error(err);
          }
        );
      }
    }
  }

  filterChange(){
    this.currentPage = 1;
    this.changePage = 1;
    this.searchDashboard();
  }

  /* -------------------------search button for filter value selected------------------------- */
  searchDashboard() {    
    this.searchObj = {
      companyId: this.sharedService.getCompanyId(),
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      updatedDate: "",
      unitId: this.extUserScatterPlotSearchForm.value['unitId'],
      unitMake: this.extUserScatterPlotSearchForm.value['unitMake'],
      esn: this.extUserScatterPlotSearchForm.value['esn'],
      engineModel: this.extUserScatterPlotSearchForm.value['engineModel'],
      unitYear: this.extUserScatterPlotSearchForm.value['unitYear'],
      distanceUOM: this.extUserScatterPlotSearchForm.value['distanceUOM'],
      fluidType: this.sharedScatterService.getSelectedFluidType(),
      fluidTypeName: this.sharedScatterService.getFluidTypeName()   
    };
    if(this.userRole == USERTYPE.TSM){
      this.searchObj.companyId = this.companyIdTSM;
    } else if(this.userRole == USERTYPE.DBU_FLUIDS){
      this.searchObj.companyId = this.companyIdDBU;
    }

    this.scatterService.getScatterPlotUnitsDashboard(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.setData(response);
        } else {
          this.alertService.error(response.statusMessage);
          this.extUserDisplayedColumnsData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* -------------------- Clear search------------- */
  clearSearch(str) {
    //console.log(this.extUserScatterPlotSearchForm.value);
    if( this.extUserScatterPlotSearchForm.controls[str].value != ''){
    this.extUserScatterPlotSearchForm.patchValue({ [str]: '' });
    if (str !== 'distanceUOM') {
      this.extUserFilterObject[str] = [];
      }
    this.searchDashboard();
    }
  }


  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }
  
  pageSizeChange(val) {
    this.pageSize = val;
    this.extUserDisplayedColumnsData = [];
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }
  
  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.extUserDisplayedColumnsData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
      }
    }
  }
  
  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.searchDashboard();
  }

  getArray(): any[] {
    return Array(this.maxPage);
  }
  
  /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.searchDashboard();
  }
  
  getSelectedValues(item ,isChecked:boolean){
      if(isChecked === true){
        item['isChecked'] = true;              
        this.unitIdSelectedValues.push(item);
      }
      if(isChecked === false){            
        this.unitIdSelectedValues = this.unitIdSelectedValues.filter((row)=>{
          if(row.equipId != item.equipId){
            return row;
          }
        });            
      } 
      this.checkboxEnableDisable();       
  }

  selectUnitId(){   
    let hoursCount=0;
    this.unitIdSelectedValues.forEach((item)=>{
      if(item.distanceUOM === "HOURS"){
        hoursCount++;
      }
    }); 
    if(hoursCount > 0){
      if(hoursCount != this.unitIdSelectedValues.length){
        this.alertService.error("Kindly select all the units which have uom in Miles/Kilometer combination or in Hours");
        this.scrollToTop = true;
        return false;
      }
    }  
    this.sharedScatterService.setUnitIDs(this.unitIdSelectedValues);
    this.router.navigate(['/scatterPlot']);
  }

  cancel(){
    this.router.navigate(['/scatterPlot']);
  }

  checkboxEnableDisable(){
    this.alertService.clear();
    let uom ='';
    this.extUserDisplayedColumnsData.forEach((row)=>{
      row['isDisabled'] = false;
      if(row.distanceUOM == 'HOURS'){
        row['isHours'] = true;
      }else{
        row['isHours'] = false;
      }
    });
    if(this.unitIdSelectedValues.length > 0){
      uom = this.unitIdSelectedValues[0].distanceUOM;
      if(uom == 'HOURS'){
        this.extUserDisplayedColumnsData.forEach((row)=>{
          if(!row.isHours){
            row['isDisabled'] = true;
          }
        });
      }else{
        this.extUserDisplayedColumnsData.forEach((row)=>{
          if(row.isHours){
            row['isDisabled'] = true;
          }
        });
      }
    }
    //Disable remaining if 10 units are selected
    if(this.unitIdSelectedValues.length >= 10){
      this.extUserDisplayedColumnsData.forEach((row)=>{
        if(row.isChecked == true){
          row['isDisabled'] = false;
        }else{
          row['isDisabled'] = true;
        }          
      }); 
      this.alertService.warn('You have selected the maximum of 10 units');
      this.scrollToTop = true;
    }
  }

}
