import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { LoaderService } from 'src/app/View/common/preloader/loader.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { USERTYPE, USERS } from 'src/app/View/common/constants/constant';
import { SharedService } from 'src/app/View/service/shared.service';
import { AnalyzeSampleGridService } from '../../analyze-sample/analyze-sample-grid/analyze-sample-grid.service';
import { TsmaeReportsService } from './tsmae-reports.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatOption } from '@angular/material';

@Component({
  selector: 'app-tsm-ae-report',
  templateUrl: './tsm-ae-report.component.html',
  styleUrls: ['./tsm-ae-report.component.css']
})
export class TsmAeReportComponent implements OnInit {
  @ViewChild('allSelected') private allSelected: MatOption;
  @Output("viewReport") viewReport: EventEmitter<any> = new EventEmitter();

  displayResult = false;  
  userRole: string = '';
  hideViewReport = true;
  oilHealthList: any[];
  metalList: any[];
  customerName:any;
  customerPhone:any;
  countOfUnits:any;
  countOfSamples:any;
  engineCountByModel:any;
  severityPieChartMap:any;

  
  tsmAeReportForm = new UntypedFormGroup({
    fluidType: new UntypedFormControl({value:'Engine Oil (Liquid Fuel)', disabled: true}, [Validators.required]),
    companyId: new UntypedFormControl('', [Validators.required]),
    companyName: new UntypedFormControl(''),
    engineModel: new UntypedFormControl('', [Validators.required]),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
    companyIdFilter: new UntypedFormControl(''),
    engineModelFilter: new UntypedFormControl('')
  });
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;

  companyArray= []; 
  engineModelArray= []; 
  engineModelList: any[];
  companyList : any[];
  engineModelMap:any; 
  fluidList: any[];
  engineModelDropResult = [];
  /*--------TSM & DBU CompanyName dropdown---------*/
  companyIdTSM: any = '';
  companyNameTSM: any = '';
  tsmCustomerNameDropdown: any;
  messageDropdownTsm: any;
  isFirstTime: boolean = true;
  isSearchDisable: boolean = false;
  messageDropdowndbu: any;
  isFirstTimeDBU: boolean = true;
  behaviorObsTSM: any;
  behaviorObs: any;
  isChecked: boolean;
  companyNameArr: any[];
  companyNameForExcel: any;  
  constructor(private loaderService: LoaderService,
    private sharedService: SharedService,
    private analyzeSampleService: AnalyzeSampleGridService,
    private reportsService: TsmaeReportsService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private router: Router) { }

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();
    if(this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      this.isSearchDisable = true;
      this.tsmAeReportForm.controls['engineModel'].disable();
      this.tsmAeReportForm.controls['startDate'].disable();
      this.tsmAeReportForm.controls['endDate'].disable();  
    }
    this.getInitialData();

    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    
    // if(this.userRole == USERTYPE.TSM){
    //   this.tsmAeReportForm.controls['companyId'].disable();
    //   if(localStorage.getItem("customerDropdownTSM")){
    //     // this.tsmAeReportForm.controls['engineModel'].disable();
    //     this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
    //     // setTimeout(()=>{ 
    //     //   console.log("Ready");
    //     //   this.tsmAeReportForm.controls['engineModel'].enable();
    //     //   this.onCompanyChange();
    //     // }, 5000);      
    //   }
    //   if(this.companyIdTSM == ''){
    //     this.alertService.error('Please select Company Name');
    //   }
    // }

    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message=>{
      if(this.userRole == USERTYPE.TSM && this.isFirstTime == false){
        if(this.router.url == '/tsmAeReport'){      
          this.messageDropdownTsm = message;
            this.recieveMessageTSM(this.messageDropdownTsm);
            // this.reset();
        }
      }
      this.isFirstTime = false;
    });
    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if((this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) && this.isFirstTimeDBU == false){
        if(this.router.url == '/tsmAeReport'){      
          this.messageDropdowndbu = message;
          this.recieveMessageTSM(this.messageDropdowndbu);
        }
      }
      this.isFirstTimeDBU = false;
    });
  }
  
  ngOnDestroy () {
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
  }

  recieveMessageTSM(event){
    this.alertService.clear();
    this.tsmAeReportForm.controls['engineModel'].enable();
    this.tsmAeReportForm.controls['startDate'].enable();
    this.tsmAeReportForm.controls['endDate'].enable(); 
    this.tsmAeReportForm.patchValue({
      companyId: '',
      engineModel:[]
    });
    // this.engineModelList = []
    this.engineModelArray= []; 

    this.tsmCustomerNameDropdown = event;
    this.companyNameTSM = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
    if(this.companyIdTSM == '' || this.companyIdTSM == null){
      this.isSearchDisable = true;
      this.alertService.error("Please select Company Name")
      this.tsmAeReportForm.controls['engineModel'].disable();
      this.tsmAeReportForm.controls['startDate'].disable();
      this.tsmAeReportForm.controls['endDate'].disable();    
    } else {
      this.companyList.forEach(element => { 
        if (element.companyId == this.companyIdTSM && element.companyName == this.companyNameTSM) {
          this.tsmAeReportForm.patchValue({
              companyId: [this.companyIdTSM]
            });
          this.onCompanyChange();
          this.isSearchDisable = false;
        }
      });

      if(this.tsmAeReportForm.controls['companyId'].value == '') {
        this.isSearchDisable = true;
        this.alertService.error("Selected company has no reports")
        this.tsmAeReportForm.controls['engineModel'].disable();
        this.tsmAeReportForm.controls['startDate'].disable();
        this.tsmAeReportForm.controls['endDate'].disable();      
      }
    }
  }

  startDateChange() {
    this.minEndDate = this.tsmAeReportForm.controls.startDate.value;    
  }
  endDateChange() {    
    this.maxStartDate = this.tsmAeReportForm.controls.endDate.value;
  }

  getInitialData(){
    this.reportsService.getInitialData().subscribe(response=> {
      if (response.statusCode == "1") {
        this.engineModelMap = response.engineModelMap;
        this.engineModelMap = Object.keys(this.engineModelMap).map(key => ({type: key, value: this.engineModelMap[key]}));        
        this.companyList = response.companyList;
        this.companyList.sort(function(a, b) {
          const nameA = a.companyName.toUpperCase();
          const nameB = b.companyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.fluidList = response.fluidList;
        if(this.userRole == USERTYPE.TSM){
          this.tsmAeReportForm.controls['companyId'].disable();
          if(localStorage.getItem("customerDropdownTSM")){
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
          }
          if(this.companyIdTSM == '' || this.companyIdTSM == null){
            this.alertService.error('Please select Company Name');
          }
        } else if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
          this.tsmAeReportForm.controls['companyId'].disable();
          if(localStorage.getItem("branchCustomerDropdown")){
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
          }
          if(this.companyIdTSM == '' || this.companyIdTSM == null){
            this.alertService.error('Please select Company Name');
          }
        }
        this.companySearch('');        
      }else{
        this.alertService.error(response.statusMessage);
      }
    },err=>{
      this.alertService.error(err);
    });
  }  
  onCompanyChange(){
    this.engineModelDropResult = [];
    this.tsmAeReportForm.patchValue({
      engineModel:[]
    });
   
    this.engineModelList = this.engineModelMap[this.tsmAeReportForm.controls.companyId.value];
    if(this.tsmAeReportForm.controls.companyId.value != '0'){
    this.tsmAeReportForm.controls.companyId.value.forEach(key => {    
      const engineModelFilter = this.engineModelMap.filter(row => {
        return (row.type == key);
      });
      if(engineModelFilter !=null && engineModelFilter!='' && engineModelFilter!=undefined){
        this.engineModelDropResult.push(engineModelFilter[0].value);
      }
   
    });   
    const convertToArray = [].concat(...this.engineModelDropResult);    
    this.engineModelList = convertToArray;    
    this.engineModelSearch('');
  }
  }
  getSeverity(value){
    var className = '';
    if(value == 1){
      className = 'normalSeverity';
    }else if(value == 2){
      className = 'watchSeverity';
    }else if(value == 3){
      className = 'cautionSeverity'
    }else if(value == 4){
      className = 'warningSeverity';
    }
    return className;
  }

  getFlagStatusSeverity(value){
    var className = '';
    if(value == 'Normal'){
      className = 'normalSeverity';
    }else if(value == 'Watch'){
      className = 'watchSeverity';
    }else if(value == 'Caution'){
      className = 'cautionSeverity'
    }else if(value == 'Warning'){
      className = 'warningSeverity';
    }
    return className;
  }

  backButton(){
    this.displayResult = false;
  }

  downloadExcel(){ 
    this.companyNameForExcel = this.getCompanyName().toString();   
    let inputData = this.tsmAeReportForm.getRawValue();
    inputData['companyId'] = inputData['companyId'].toString();
    inputData['engineModel'] = inputData['engineModel'].toString();
    inputData['startDate'] = inputData['startDate'].toLocaleDateString("en-US");
    inputData['endDate'] = inputData['endDate'].toLocaleDateString("en-US");
    inputData['oilHealthList'] = JSON.parse(JSON.stringify(this.oilHealthList));
    inputData['metalList'] = JSON.parse(JSON.stringify(this.metalList));  
    inputData['companyName'] =this.companyNameForExcel;
    inputData['customerName'] = this.customerName;
    inputData['customerPhone'] = this.customerPhone;
    inputData['engineCountByModel'] = this.engineCountByModel;
    inputData['countOfUnits'] = this.countOfUnits;
    inputData['countOfSamples'] = this.countOfSamples;

    this.reportsService.generateExcel(inputData);
  } 

  downloadPDF(){
    let inputData = {};    
    inputData['oilHealthList'] = JSON.parse(JSON.stringify(this.oilHealthList));
    inputData['metalList'] = JSON.parse(JSON.stringify(this.metalList));    

    this.loaderService.show();
    let self = this;
    document.getElementById('exportReport').style.width = '1250px';
    //document.getElementById('exportReport').style.height = '990px';
    html2canvas(document.getElementById('exportReport')).then(function (canvas) {
      document.getElementById('exportReport').style.width = 'auto';
      document.getElementById('exportReport').style.height = 'auto';
      const imgData = canvas.toDataURL('image/png');  
      self.loaderService.hide();    
      self.reportsService.downloadPDF(imgData, 'TSM/AE_Report', inputData);      
    });    
  } 
  search(){    
    let data = this.tsmAeReportForm.getRawValue();
    data['startDate'] = this.datePipe.transform(data['startDate'],'yyyy-MM-dd');
    data['endDate'] = this.datePipe.transform(data['endDate'],'yyyy-MM-dd');
    data['engineModelList'] = data['engineModel'];
    data['companyName'] = this.getCompanyName();
    this.reportsService.getTsmAeReport(data).subscribe(response=>{
      if(response.statusCode == "1") {
        this.oilHealthList = response.tsmAeReport.oilHealthInfoList;
        this.metalList = response.tsmAeReport.allMetalsInfoList;
        this.customerName = response.tsmAeReport.customerName;
        this.customerPhone = response.tsmAeReport.customerPhone;
        this.countOfUnits = response.tsmAeReport.countOfUnits;
        this.countOfSamples = response.tsmAeReport.countOfSamples;
        this.engineCountByModel = response.tsmAeReport.engineCountByModel;
        this.severityPieChartMap = response.tsmAeReport.severityPieChartMap; 
        this.reportsService.setPieChartData(this.severityPieChartMap);
        this.displayResult = true;
      }else{
        this.displayResult = false;
        this.alertService.error(response.statusMessage);
      }
    }, err=>{
      this.alertService.error(err);
    });
  }
  reset(){
    this.displayResult = false;
    this.tsmAeReportForm.reset();
    this.tsmAeReportForm.patchValue({
      fluidType: 'Engine Oil (Liquid Fuel)'
    });
    this.companyArray= this.companyList;
    this.clearCompanySearch();
    this.clearEngineModelSearch();
    if(this.userRole === USERTYPE.TSM){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
    } else if(this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
    }
  }

  companySearch(value:string){
    this.companyArray= []; 
    let filter = value.toLowerCase();
    
    this.companyArray = this.companyList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if(companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0){
        return true;
      }else{
        return false;
      }
    });

    if(this.companyArray.length == 0){
      this.companyArray.push({'companyName':'Select', 'companyId': ''});
    }
  }

  clearCompanySearch(){
    this.companyArray= []; 
    this.companyArray= this.companyList;
  }
  engineModelSearch(value:string){
    this.engineModelArray= []; 
    let filter = value.toLowerCase();
    
    this.engineModelArray = this.engineModelList.filter((row) => {      
      if(row.toLowerCase().indexOf(filter) >= 0 && row !== ''){
        return true;
      }else{
        return false;
      }
    });
  }

  clearEngineModelSearch(){
    this.engineModelArray= []; 
    //this.engineModelArray= this.engineModelList;
  }
 
  getCompanyName():any{
    let companyNameArr=[];
    let companyName;
    let selectedcompanyDetails= this.tsmAeReportForm.controls.companyId.value
    for (let i = 0; i < selectedcompanyDetails.length; i++) {
      for (let j = 0; j < this.companyList.length; j++){
      if(this.companyList[j].companyId == selectedcompanyDetails[i]){
        companyName =  this.companyList[j].companyName;
        companyNameArr.push(companyName.toUpperCase())
     }
    }
    }
    return companyNameArr;
  } 

viewGraph(){
  this.hideViewReport = true;
}

navigateToViewReport(sampleId) {
  var data = {
    columnName: 'sampleId',
    companyId: '',
    currentPage: '1',
    pageSize: '10',
    sampleId: sampleId,
    sortingOrder: 'desc',
    status: 'Published',

    userRole: this.userRole,

    fluidType: this.tsmAeReportForm.controls.fluidType.value
  }
  if (this.userRole == USERTYPE.EXTERNAL) {
    data['userRole'] = USERS.EXTERNAL;
  }
  this.analyzeSampleService.getAnalyzeSampleDashboardData(data).subscribe(
    response => {
      if (response.statusCode == "1") {
        let sampleData = response.analyseSamplesList;
        if (sampleData.length > 0) {
          localStorage.setItem('viewReportData', JSON.stringify(sampleData[0]));
          //this.router.navigate(['/viewReport']);
          //this.viewReport.emit();
          this.hideViewReport = false;
        }
      }
    }
  );
}
toggleAllSelection() {
  if (this.allSelected.selected) {
    this.engineModelDropResult =[];
    this.tsmAeReportForm.controls.companyId
    .patchValue([...this.companyList.map(item => item.companyId), 0]);
    this.tsmAeReportForm.controls.companyName
    .patchValue([...this.companyList.map(item => item.companyName), 0]);   
        this.engineModelMap.filter(row => {
          this.engineModelDropResult.push(row.value);        
    
        });
        const convertToArray = [].concat(...this.engineModelDropResult);    
        this.engineModelList = this.removeDuplicates(convertToArray);        
        this.engineModelSearch('');        
  } else {
    this.engineModelArray =[];
    this.tsmAeReportForm.controls.companyId.patchValue([]);
    this.tsmAeReportForm.controls.companyName.patchValue([]);
  }
}
  removeDuplicates<T>(array: T[]): T[] {
    const seen = new Set<T>();
    return array.filter(item => {
      if (seen.has(item)) {
        return false;
      } else {
        seen.add(item);
        return true;
      }
    });
  }
}
