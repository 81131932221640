// import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.css']
})
export class ModalContainerComponent  implements OnInit {
  @Input() title: string;
  @Input() cancelModal: boolean;
  @Input() message: string;

  constructor() {
  }

  ngOnInit() {
  }

}
// export class ModalContainerComponent extends DialogComponent<any, boolean> implements OnInit {
//   @Input() title: string;
//   @Input() cancelModal: boolean;
//   @Input() message: string;

//   constructor(dialogService: DialogService) {
//     super(dialogService);
//   }

//   ngOnInit() {
//   }

// }
