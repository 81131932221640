import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { TsmaeReportsService } from '../tsm-ae-report/tsmae-reports.service';

@Component({
  selector: 'app-report-pie-chart',
  templateUrl: './report-pie-chart.component.html',
  styleUrls: ['./report-pie-chart.component.css']
})
export class ReportPieChartComponent implements OnInit,AfterViewInit {

  constructor(private cdr: ChangeDetectorRef, 
    private reportService: TsmaeReportsService) { }

  @ViewChild('pieIdentifier', { static: true })
  pieIdentifier: ElementRef;

  @Input('keyIssue') keyIssue: string;  

  private svg:any; 
  private mainContainer:any;
  private color:any;
  private arc:any;
  private pie:any;
  private slices:any;
  radius:number;
  private tooltip: any;
  total:number;
  pieChartId:any;
  data = [];
  severityList = ['Normal', 'Watch', 'Caution', 'Warning'];
  

  ngOnInit(){  
    this.pieChartId = this.keyIssue.replace(' ', '');   
    this.reportService.getPieChartData().subscribe(severityPieChartMap=>{
      let obj = severityPieChartMap[this.keyIssue];
      let dataArray = [];
      this.severityList.forEach(severity =>{
        if(obj.hasOwnProperty(severity)){
          dataArray.push({'severity':severity, 'value':obj[severity]});
        }else{
          dataArray.push({'severity':severity, 'value':0});
        }
      });      
      this.data = dataArray;
      
      this.fetchData();
    });    
  }

  ngAfterViewInit(){     
    this.fetchData();    
  }

  fetchData(){
       
    //make service call to get the data
    /*this.data=[{severity:'Normal',value:75},
    {severity:'Watch',value:10},
    {severity:'Caution',value:5},
    {severity:'Warning',value:2}];*/

    this.createChart();
    this.cdr.detectChanges();
  }

  createChart() {
    d3.select('#'+this.pieChartId).selectAll('svg').remove();

    var margin = { top: 20, right: 15, bottom: 60, left: 60 }
      , width = this.pieIdentifier.nativeElement.offsetWidth
      , height = 270- margin.top - margin.bottom;
    
    this.svg = d3.select('#'+this.pieChartId)
    .append('svg')
    .attr('width', width + margin.right + margin.left)
    .attr('height', height + margin.top + margin.bottom)
    .style('margin-left', '10px'); 
                   
    this.radius = Math.min(width, height) / 2;
    //this.svg.attr('width', 2 * this.radius).attr('height', 2 * this.radius);
    //this.svg.select('g').attr('transform', 'translate('+this.radius+','+this.radius+')');
    
    this.mainContainer = this.svg.append("g")
                   .attr("transform", "translate(" + (this.radius + 15) + "," + (this.radius * 1.4) + ")");
    //this.color = d3.scaleOrdinal(['Green','Yellow','Orange','Red']);  
    this.color = {'Normal':'Green', 'Watch':'Yellow', 'Caution':'Orange', 'Warning':'Red'};
    
    this.pie = d3.pie().sort(null).value((d:any) => d.value);  
    this.arc = d3.arc().outerRadius(this.radius).innerRadius(0); 
    this.slices = this.mainContainer.selectAll('path').remove().exit()
      .data(this.pie(this.data))
      .enter().append('g').append('path')
      .attr('d', this.arc);

    this.total = 0;
    this.data.forEach(row => {
      this.total = this.total + row.value;
    });  

    //this.slices.attr('fill', (d,i) =>this.color(i))
    this.slices.attr('fill', (d,i) =>{return this.color[d.data.severity]})
    .on('mouseover', function (s) {      
      this.tooltip = d3.select('#'+this.pieChartId)
        .append("div")
        .attr("id", "tool"+this.pieChartId)      
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "4px")   
        .style("font-size", "10px")    
        .style("opacity", 1);
    }.bind(this))
    .on('mousemove', function (s) {
      const percent = (Math.abs(s.data.value / this.total) * 100).toFixed(0) + '%';
      this.tooltip
        .style('top', (d3.event.layerY + 15) + 'px')
        .style('left', (d3.event.layerX) + 'px')
        .html(`Severity: ${s.data.severity}<br>value: ${s.data.value}<br>share: ${percent}`);
    }.bind(this))
    .on('mouseleave', function () {
      d3.select('#'+this.pieChartId).selectAll('#tool'+this.pieChartId).remove();
    }.bind(this));
    /*.on('click', function (s) {      
      let routeData = s.data;
      routeData['keyIssue'] =  this.keyIssue;
      if(this.titleHeader != 'Report Distribution'){
        this.sampleDashboardService.setKeyIssueSeverityTableInput(routeData);
        this.router.navigate(['/keyIssueSeverityTable']);
      }         
    }.bind(this));

    this.svg.append("g")
      .attr("transform", "translate(" + (width * 0.1) + "," + 10 + ")")
      .append("text")
      .attr('font-size', '12')
      .text("Total Sample Reports Available")
      .attr("class", "title");

    this.svg.append("g")
      .attr("transform", "translate(" + Math.min(width, height) / 2 + "," + 30 + ")")
      .append("text")
      .attr('font-size', '12')
      .text(this.total)
      .attr("class", "title"); */ 
             
  }

  onResize() {
    this.createChart();
  }

  getPercentage(item){
    this.total = 0;
    this.data.forEach(row => {
      this.total = this.total + row.value;
    });
    return (Math.abs(item.value / this.total) * 100).toFixed(0) + '%';
  }

}
