import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material';
import { AccountGroupManagementService } from '../account-group-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  scrollToTop = false;
  customerList: any;
  customerListCopy: any;
  selectedCompanyList: any = [];
  groupedCompanyList: any = [];
  groupAvailable: boolean = false;
  isEditMode = false;
  groupName: string | null = null;

  @ViewChild('allSelected') private allSelected: MatOption;
  accountgroupdataForm = new UntypedFormGroup({
    groupName: new UntypedFormControl(''),
    companyId: new UntypedFormControl('')
  });
  constructor(
    private alertService: AlertService,
    private accountGroupManagementService: AccountGroupManagementService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let groupNameObj = (JSON.parse(localStorage.getItem('createOrUpdateGroupName')));
    this.groupName = groupNameObj.groupName;
    
    this.isEditMode = this.groupName ? true : false;
    if(this.isEditMode) {
      this.accountgroupdataForm.patchValue({
        groupName: this.groupName
      });
      this.accountgroupdataForm.controls['groupName'].disable();
    }
    this.getCompanyDetails();
  }

  closeAddEditPage() {
    localStorage.removeItem('createOrUpdateGroupName');
    this.router.navigate(['/accountGroupManagement']);
  }

  validateGroupName() {
    let _data = {
      functionName: "GROUP_AVAILABILITY",
      groupName: this.accountgroupdataForm.getRawValue().groupName.trim()
    };

    this.accountGroupManagementService.getCompanyDetailList(_data).subscribe(response => {
      this.groupAvailable = response.groupAvailable;
      if(!response.groupAvailable) {
        this.alertService.error(this.accountgroupdataForm.getRawValue().groupName + " is already created!");
      } else {
        this.accountgroupdataForm.controls['groupName'].disable();
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  getCompanyDetails() {
    let _data = {
      functionName: "UNGROUPED_COMPANIES"
    };

    this.accountGroupManagementService.getCompanyDetailList(_data).subscribe(response => {
      this.customerList = response.companyList.filter(obj => {
        return obj.companyId
      });
      this.customerListCopy = this.customerList;
      if(this.isEditMode) {
        this.getCompanyDetailsByGroup();
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  getCompanyDetailsByGroup() {
    let _data = {
      functionName: "DASHBOARD",
      groupName: this.groupName,
      currentPage: "1",
      pageSize: "100",
      sortingOrder: "ASC",
      columnName: "companyId"
    };

    this.accountGroupManagementService.getCompanyDetailList(_data).subscribe(response => {
      if(response.statusCode == '1' && response.companyList.length > 0) {
        this.groupedCompanyList = response.companyList;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  onKeyCompany(value) {
    this.customerListCopy = [];
    this.selectSearch(value);
  }

  selectSearch(value: string) {
    let filter = value.toLowerCase();
    this.customerListCopy = this.customerList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if (companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0) {
        return true;
      } else {
        return false;
      }
    });

    if (this.customerListCopy.length == 0) {
      this.customerListCopy.push({ 'companyName': 'Select', 'companyId': '' });

    }

  }

  clearaccountGroup() {
    if(!this.isEditMode) {
      this.accountgroupdataForm.patchValue({
        groupName: ''
      });
      this.groupAvailable = false;
      this.accountgroupdataForm.controls['groupName'].enable();
    }
    this.accountgroupdataForm.patchValue({
      companyId: ''
    });
    this.selectedCompanyList = [];
  }

  ngOnDestroy() {
    localStorage.removeItem('createOrUpdateGroupName');
    // this.accountgroupdataForm.patchValue({
    //   companyId: '',
    //   groupName: ''
    // });
    // this.groupAvailable = false;
    // this.selectedCompanyList = [];
  }

  clearCompanySearch() {
    this.customerListCopy = [];
    this.customerListCopy = this.customerList;
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.accountgroupdataForm.controls.companyId
        .patchValue([...this.customerList.map(item => item.companyId), 0]);
      // this.accountgroupdataForm.controls.companyName
      //   .patchValue([...this.customerList.map(item => item.companyName), 0]);

    } else {
      this.accountgroupdataForm.controls.companyId.patchValue([]);
      // this.accountgroupdataForm.controls.companyName.patchValue([]);
    }
    this.makeCompanyListTable();
  }

  inputCompanyName() {
    this.makeCompanyListTable();

  }

  makeCompanyListTable() {
    this.selectedCompanyList = this.customerList.filter(row => {
      return this.accountgroupdataForm.getRawValue().companyId.includes(row.companyId);
    });
  }

  updateGroup() {
    let filteredComapnyList = this.selectedCompanyList.map(({maxRow, ...rest}) => rest);
    let _data = {
      functionName: "REGROUP",
      companyList: filteredComapnyList,
      groupName: this.accountgroupdataForm.getRawValue().groupName.trim()
    };
    
    this.accountGroupManagementService.getCompanyDetailList(_data).subscribe(response => {
      this.scrollToTop = true;
      if (response.statusCode == "1") {
        if (this.isEditMode) {
          this.alertService.success(this.accountgroupdataForm.getRawValue().groupName.trim() + " group updated successfully");
        } else {
          this.alertService.success(this.accountgroupdataForm.getRawValue().groupName.trim() + " group created successfully");
        }
        setTimeout(() => {
          this.closeAddEditPage();
        }, 2500)
      } else {
        if (this.isEditMode) {
          this.alertService.error("There is some issue while updating group! Try after few minutes!");
        } else {
          this.alertService.error("There is some issue while creating group! Try after few minutes!");
        }
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }
}
