import { Component, OnInit, ViewChildren } from '@angular/core';
import { common, warnMessage } from '../../../common/constants/constant';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SortComponent } from '../../../common/sort/sort.component';
import { MasterDataManagementService } from '../master-data-management.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';

@Component({
  selector: 'app-admin-engine-make',
  templateUrl: './admin-engine-make.component.html',
  styleUrls: ['./admin-engine-make.component.css']
})
export class AdminEngineMakeComponent implements OnInit {
  showRecordsMetadata = 0;
  pageSizeMetadata = '10';  
  currentPageMetadata = 1; 
  changePageMetadata: any = 1;
  pageSizeOptions: any[] = common.pageSizeOptions;
  fleetVal: any = '';
  addEngineMakeInput: boolean = false;
  addMetadataModal = false;
  searchObjMetadata: any;
  columnName: string;
  columnNameMetadata: string;
  engineDisplayColumns: any;
  applicationDisplayColumns: any;
  sortingOrder: string = 'asc';
  successMessage: string = '';
  successModal = false;
  scrollToTop =  false;
  dropdownName: string;
  metadataSearchData = [];
  totalItemsMetadata: any;
  maxPageMetadata = 0;
  selectedMetadataGroup:UntypedFormGroup;
  saveMetadataModal = false;
  deleteMetadataModal = false;

  engineFilterObject = {
    engineMake: [],
    engineModel: []
  }

  addEngineMakeForm = new UntypedFormGroup({
    engineMakeName: new UntypedFormControl('',[Validators.required]),
    engineModelName: new UntypedFormControl('',[Validators.required])
  });

  engineSearchForm = new UntypedFormGroup({
    engineMake: new UntypedFormControl(''),
    engineModel: new UntypedFormControl('')
  });

  metadataFormArray = new UntypedFormArray([]);

  constructor(private masterDataManagementService:MasterDataManagementService, private alertService: AlertService, private commonHandler: CommonHandlerService) { }

  
  @ViewChildren(SortComponent) sortComponents;
  
  ngOnInit(): void {
    this.columnName = 'engine_make';
    this.dropdownName = 'ENGINEMAKEMODEL';
    this.searchMetadata();
    this.fetchMetadata();
    this.engineDisplayColumns = [
      { code: "engineMake", name: "Engine Make" },
      { code: "engineModel", name: "Engine Model" }
    ];
  }
    
  sortDataMetadata(columnName) {
    if(columnName == 'engineModel') {
      this.columnName = 'engine_model';
    } else {
      this.columnName = 'engine_make';
    }
    if (this.columnNameMetadata != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnNameMetadata = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.fetchMetadata();
  }
  
  intUserclearSearch(str) {
    if( this.engineSearchForm.controls[str].value != ''){
      this.engineSearchForm.patchValue({ [str]: '' });
      this.engineFilterObject[str] = [];
      if(str == 'engineMake'){
        this.searchDashboardDropDownSelect('engineModel')
      } else {
        this.searchDashboardDropDownSelect('engineMake');
      }
      
    } else {
      this.engineFilterObject[str] = [];
    }
  }

  addNewEngineMake() {
    this.addEngineMakeInput = true;
  }

  hideEngineMakeInput() {
    this.addEngineMakeInput = false;
  }

  openAddMetadataModal(){
    if(!this.addEngineMakeForm.invalid){
      this.addMetadataModal = true;
    }else{
      this.addMetadataModal = false;      
      Object.keys(this.addEngineMakeForm.controls).forEach(field => { 
        const control = this.addEngineMakeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAddMetadataModal(){
    this.addMetadataModal = false;
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.metadataFormArray.controls = [];
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;  
    this.fetchMetadata();    
  }

  searchMetadata(){
    this.currentPageMetadata = 1;
    this.fetchMetadata();
  }

  fetchMetadata(){
    let data = {};
    let _data = {};
    if(this.columnName == 'engine_make'){
      data['engineMake'] = this.engineSearchForm.value['engineMake'];
      data['engineModel'] = this.engineSearchForm.value['engineModel'];
      data['columnName'] = 'engine_make';
      _data['engineMake'] = this.engineSearchForm.value['engineMake'];
      _data['engineModel'] = this.engineSearchForm.value['engineModel'];
      _data['columnName'] = 'engineMake';
    }
    else if(this.columnName == 'engine_model'){
      data['engineModel'] = this.engineSearchForm.value['engineModel'];
      data['engineMake'] = this.engineSearchForm.value['engineMake'];
      data['columnName'] = 'engine_model';
      _data['engineModel'] = this.engineSearchForm.value['engineModel'];
      _data['engineMake'] = this.engineSearchForm.value['engineMake'];
      _data['columnName'] = 'engineModel';
    }
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['dropdownName'] = this.dropdownName;
    data['sortingOrder'] = this.sortingOrder;

    _data['pageSize'] = this.pageSizeMetadata.toString();
    _data['currentPage'] = this.currentPageMetadata;
    _data['dropdownName'] = this.dropdownName;
    _data['sortingOrder'] = this.sortingOrder;

    this.searchObjMetadata = _data;
    this.masterDataManagementService.searchMetadata(data).subscribe(response =>{ 
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;   
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();

        //form Array creation
        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {         
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            engineMake: new UntypedFormControl(row.engineMake, [Validators.required]),
            isEdit: new UntypedFormControl(false),
            engineModel: new UntypedFormControl(row.engineModel, [Validators.required]),
          });
          this.metadataFormArray.push(formGroup);
        });
        
      } else {
        this.alertService.error(response.statusMessage);
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  // Pagination for Metadata

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  editMetadata(item) {
    item.patchValue({
      isEdit:true
    });
  }

  openSaveMetadataModal(item){
    if(!item.invalid){
      this.selectedMetadataGroup = item;
      this.saveMetadataModal = true;
    }else{
      this.saveMetadataModal = false;   
      Object.keys(item.controls).forEach(field => { 
        const control = item.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeSaveMetadataModal(){
    this.saveMetadataModal = false;
  }

  saveMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    data['dropdownName'] = 'ENGINEMAKEMODEL';
    data['engineModel'] = data.engineModel;
    data['optionName'] = '';

    this.masterDataManagementService.saveMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {  
        this.searchMetadata();        
        this.successMessage = response.statusMessage;
        this.successModal = true; 
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.saveMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.saveMetadataModal = false;
    });
  }

  cancelMetadataEdit(item, i){
    item.patchValue({
      isEdit:false,
      optionName: this.metadataSearchData[i].optionName
    });
    this.fetchMetadata();
  }

  openMetadataDeleteModal(item){    
    this.selectedMetadataGroup = item;
    this.deleteMetadataModal = true;
  }

  closeDeleteMetadataModal(){
    this.deleteMetadataModal = false;
  }

  deleteMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    data['dropdownName'] = 'ENGINEMAKEMODEL';
    
    this.masterDataManagementService.deleteMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {         
        this.successMessage = response.statusMessage;
        this.successModal = true; 
        this.searchMetadata();     
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.deleteMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.deleteMetadataModal = false;
    });
  }  

  addMetadata() {
    let data;
    let data1=this.addEngineMakeForm.getRawValue();
    data = {
      dropdownName: 'ENGINEMAKEMODEL',
      engineMake: data1.engineMakeName.toUpperCase(),
      engineModel: data1.engineModelName.toUpperCase(),
      activeStatus: 'Y',
      engModStatus: 'Y'
    }

    this.masterDataManagementService.addMetadata(data).subscribe(response =>{
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;
        this.addEngineMakeInput = false;
        this.addEngineMakeForm.reset();
        this.fetchMetadata();
      } else {
        this.scrollToTop = true;
        this.alertService.error(response.statusMessage);
      }
      this.addMetadataModal = false;
    },err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addMetadataModal = false;
    });
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {        
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  // =================== Code for Search Engine Make & Modal ==========================---- */

  intUsersearchFilter(str) {
    this.engineFilterObject[str] = [];
    let search = (this.engineSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.engineFilterObject[str] = [];
      this.fetchMetadata();
    }
    let _data;
    if(str == 'engineMake') {
      _data = {
        engineMake: search,
        engineModel: this.engineSearchForm.value['engineModel'],
        columnName: "engine_make",
        dropdownName: "ENGINEMAKEMODEL",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    } else if(str == 'engineModel') {
      _data = {
        engineModel: search,
        engineMake: this.engineSearchForm.value['engineMake'],
        columnName: "engine_model",
        dropdownName: "ENGINEMAKEMODEL",
        sortingOrder: "asc",
        pageSize: '',
        currentPage: '',
      }
    }
    
    if (this.engineSearchForm.value[str].length >= 2) {
      this.masterDataManagementService.searchMetadata(_data).subscribe(
        response =>{
          if (response.statusCode == "1") {
            if(str == 'engineMake') {
              response.engineMakeList.forEach((item) => {
                if(item.toLowerCase().includes(search)) {
                  if(this.engineFilterObject[str].findIndex(i => i.engineMake == item) === -1) {
                    this.engineFilterObject[str].push({'engineMake': item});
                  }
                }
              });
            } else {
              this.engineFilterObject[str] = response.dropdownOptionList;
            }
            
            // if(str === 'engineMake' || str === 'engineModel')
            // {
            //   this.addBlank(str,search,this.engineFilterObject);
            // }
            // console.log("this.engineFilterObject",this.engineFilterObject);
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }
  }

  // addBlank(searchColumn,searchValue,searchList) { 
  //   let countMake =0;
  //   let countModel=0;
 
  //   if((searchColumn === 'engineMake')) {
  //     if((searchValue == 'bl' || searchValue == 'bla' || searchValue == 'blan' || searchValue =='blank') === true) {
  //       // countMake=0;
  //       for(let val of searchList.engineMake)
  //       {
  //             if(val.engineMake === 'Blank')
  //             {
  //               countMake++;
  //             }
  //       }
  //       if(countMake === 0)
  //       {
  //         searchList.engineMake.push({maxRow: 0, engineMake: "Blank", expanded: false});
          
  //       }
  //       return 'valid'
  //     }   
  //     return 'invalid'        
  //   }
  //   if((searchColumn === 'engineModel')) {
  //     if((searchValue == 'bl' || searchValue == 'bla' || searchValue == 'blan' || searchValue =='blank')) {
  //       for(let val of searchList.engineModel) {
  //         if(val.engineModel === 'Blank') {
  //           countModel++;
  //         }
  //       }
  //       if(countModel === 0) {
  //         searchList.engineModel.push({maxRow: 0, engineModel: "Blank", expanded: false});
  //       }
  //       return 'valid';
  //    }    
  //     return 'invalid'       
  //   }
  // }

  searchDashboardDropDownSelect(str) {
    let data = {};
    if(str == 'engineMake') {
      data = {
        engineMake: this.engineSearchForm.value['engineMake'],
        engineModel: this.engineSearchForm.value['engineModel'],
        columnName: "engine_make",
        dropdownName: "ENGINEMAKEMODEL",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    } else if(str == 'engineModel') {
      data = {
        engineModel: this.engineSearchForm.value['engineModel'],
        engineMake: this.engineSearchForm.value['engineMake'],
        columnName: "engine_model",
        dropdownName: "ENGINEMAKEMODEL",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    }
    this.masterDataManagementService.searchMetadata(data).subscribe(response =>{ 
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;   
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();

        //form Array creation
        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {         
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            engineMake: new UntypedFormControl(row.engineMake),
            isEdit: new UntypedFormControl(false),
            engineModel: new UntypedFormControl(row.engineModel),
          });
          this.metadataFormArray.push(formGroup);
        });   
      } else if (response.statusCode == "-1") {
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = 0;
        this.changePageMetadata = 0;
        this.showRecordsMetadata = 0;
        this.totalItemsMetadata = 0;
        this.loadPageMetadata(1);
      } else {
        this.alertService.error(response.statusMessage);
        this.metadataSearchData = [];
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }
}
