import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sort-header-icon',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.css']
})
export class SortComponent implements OnInit {
  @Input() searchObj: any;
  @Input() column: string;
  @Output() sort: EventEmitter<any> = new EventEmitter();
  direction: boolean = true;
  activeColumn: boolean = false;
  currentItem: any = [];
  url : string
  sorting: any;
  sortcolumn;
  sortingOrder;
  columnname;
  constructor( private router : Router ) { }

  ngOnInit() {
    this.url = this.router.url;
    if(this.url == '/analyzeSample'){
      this.sorting = sessionStorage.getItem('analyzesortData')
    }
     else if( this.url == '/registerSample/reviewSampleList'){
      this.sorting = sessionStorage.getItem('registerSortData')
    }
    else if( this.url == '/manageFleet'){
      this.sorting = sessionStorage.getItem('managefleetSortData')
    }
    else if( this.url == '/populationManagement'){
      this.sorting = sessionStorage.getItem('populationSortData')
    }
    else if( this.url == '/accountGroupManagement'){
      this.sorting = sessionStorage.getItem('accountGroupManagementSortData')
    }
    if(this.sorting == undefined){
      this.currentItem = undefined;
    }
    else{
      this.currentItem = JSON.parse(this.sorting);      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      this.searchObj.columnName = this.column;
      if(this.searchObj.columnName ==  this.currentItem.column){
        this.searchObj.sortingOrder =  this.currentItem.sort;
        this.searchObj.sortingOrder != 'asc'? this.direction = false: this.direction = true;
        this.activeColumn = true;
      }
    }
  }

  reset(){
    this.direction = false;
  }

  onClick(){
    if (this.searchObj.columnName != this.column){
      this.searchObj.sortingOrder = "";
    }
   let existingsortdata= sessionStorage.getItem('analyzesortData');
    if(existingsortdata == 'undefined' || existingsortdata == null || existingsortdata== ""){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(existingsortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      if(this.currentItem.sort == 'asc'){
        this.searchObj.columnName = this.currentItem.column;
        this.searchObj.sortingOrder != 'desc'? this.direction = false: this.direction = true;
      }else{
        this.searchObj.columnName = this.currentItem.column;
        this.searchObj.sortingOrder != 'asc'? this.direction = true: this.direction = false;
      }
    }
    else
    this.searchObj.sortingOrder != 'asc'? this.direction = true: this.direction = false;
    
    sessionStorage.setItem('searchOBJ', JSON.stringify(this.searchObj));
    this.sort.emit();
  }

}
