import { Injectable } from '@angular/core';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinReportService {

  constructor(private api: ApiHandlerService) { }

  getFinReportData(data: any) {
    return this.api.post(environment.apiUrl + '/getFinanceReportData', data);
  }

  sendFinanceReport(data: any) {
    return this.api.post(environment.apiUrl + '/sendFinanceReport', data);
  }
}
