import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-master-data-management',
  templateUrl: './master-data-management.component.html',
  styleUrls: ['./master-data-management.component.css']
})

export class MasterDataManagementComponent implements OnInit {

  selectedTab: string = '';
  scrollToTop =  false; 

  constructor() { }

  ngOnInit() {
    this.selectedTab = 'fluidAndFilter';
  } 

  selectedTabFluid() {
    this.selectedTab = 'fluidAndFilter';
  }

  selectedTabComments() {    
    this.selectedTab = 'comments'; 
  }

  selectedTabCogs() {    
    this.selectedTab = 'cogs'; 
  }

  selectedTabMetadata() {    
    this.selectedTab = 'metadata'; 
  }

  selectedTabAstm() {    
    this.selectedTab = 'astm'; 
  }

  selectedTabFleetDetails() {    
    this.selectedTab = 'fleetDetails'; 
  }

  selectedTabQR() {
    this.selectedTab = 'qr';
  }
  selectedTabHoldDate(){
    this.selectedTab = 'hold';
  }
}