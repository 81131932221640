import { Component, Input, OnInit} from '@angular/core';
import { LineGraphFuelService } from './line-graph-fuel.service';
import * as d3 from 'd3';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as  pdfFonts from 'pdfmake/build/vfs_fonts';
import { AnalyzeSampleInformationService } from '../analyze-sample-information/analyze-sample-information.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-line-graph-fuel',
  templateUrl: './line-graph-fuel.component.html',
  styleUrls: ['./line-graph-fuel.component.css']
})
export class LineGraphFuelComponent implements OnInit {

  constructor(private analyzeSampleInfoServ: AnalyzeSampleInformationService) { }
  @Input('idName') idName: string; 

  currentSampleData = [];
  remSampleData = [];
  astmData= {
    flashPointMin: '',
    distillationTempMin: '',
    distillationTempMax: '',
    kinematicViscMin: '',
    kinematicViscMax: '',
    sulfurMax: '',
    cetaneNumberMin:''
  }; 
  min;
  max;
  maxOfYScale: number;
  minOfYScale: number;
  ngOnInit() {    
  }
  generateGraph(firstRow, remRow, max, min) {
    this.analyzeSampleInfoServ.getAstmData({}).subscribe(res => {
      this.astmData = res.astmId;
      this.min = this.astmData['distillationTempMin'];
      this.max = this.astmData['distillationTempMax'];
    });
  let unitArray = [];

  this.currentSampleData =  [
    [0,  parseInt(firstRow.ibp?.value, 10)],
    [10, parseInt(firstRow.percent10?.value, 10)],
    [20, parseInt(firstRow.percent20?.value, 10)],
    [30, parseInt(firstRow.percent30?.value, 10)],
    [50, parseInt(firstRow.percent50?.value, 10)],
    [70, parseInt(firstRow.percent70?.value, 10)],
    [80, parseInt(firstRow.percent80?.value, 10)],
    [90, parseInt(firstRow.percent90?.value, 10)],
    [100,parseInt(firstRow.fbp?.value, 10)]
  ];

  unitArray = unitArray.concat(this.currentSampleData);  
  
  this.remSampleData=[];
  if(remRow?.length > 0){
    remRow?.forEach(row => {
      let rowData = [
        [0,  parseInt(row.ibp?.value, 10)],
        [10, parseInt(row.percent10?.value, 10)],
        [20, parseInt(row.percent20?.value, 10)],
        [30, parseInt(row.percent30?.value, 10)],
        [50, parseInt(row.percent50?.value, 10)],
        [70, parseInt(row.percent70?.value, 10)],
        [80, parseInt(row.percent80?.value, 10)],
        [90, parseInt(row.percent90?.value, 10)],
        [100,parseInt(row.fbp?.value, 10)]
      ];
      this.remSampleData?.push(rowData);
      unitArray = unitArray.concat(rowData);      
    });
  }   

  console.log(d3.max((unitArray), function (d) { return d[1]; })+20)
  console.log(d3.min((unitArray), function (d) { return d[1]; })-20)
  
  var margin = { top: 70, right: 60, bottom: 55, left: 75 }
  , width = 410  - margin.left - margin.right
  , height = 400- margin.top - margin.bottom;    

  var xScale = d3.scaleLinear().domain([0, 100]).range([0, width])
  // var yScale = d3.scaleLinear().domain([d3.min((unitArray), function (d) { return d[1]; }) - 20, d3.max((unitArray), function (d) { return d[1]; }) + 20]).range([height, 0]);

  this.maxOfYScale = Math.max(d3.max((unitArray), function (d) { return d[1]; }), parseInt(max)); 
  this.minOfYScale = Math.min(d3.min((unitArray), function (d) { return d[1]; }), parseInt(min));
  var yScale = d3.scaleLinear().domain([(this.minOfYScale - 20), (this.maxOfYScale + 20)]).range([height, 0]);



   // define the line
    var line = d3.line()
    .x(function(d) { return xScale(d[0]); })
    .y(function(d) { return yScale(d[1]); })
    .curve(d3.curveMonotoneX);

    d3.select('#'+ this.idName).selectAll('svg').remove();

    var svg = d3.select("#" + this.idName).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .style('margin-top', '-34px')   
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");    
   
    // draw the X-axis
    var xAxis = d3.axisBottom(xScale).tickSize(-height).ticks(6);

    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale)).style('stroke-dasharray', '5,5').style('color', 'lightgray')
      .call(xAxis);
      

    // draw the Y-axis
    var yAxis = d3.axisLeft(yScale).tickSize(-width).ticks(6) ;

    svg.append("g")
      .attr("class", "y axis")
      .call(d3.axisLeft(yScale))
      .style('stroke-dasharray', '5,5').style('color', 'lightgray')
      .attr('class', 'main axis date').style('stroke-dasharray', '5,5').style('color', 'lightgray')
      .call(yAxis)
      
    //Y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -49)
      .attr('x', -130)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Temperature (°C)').style('font-weight', 'bold').style('font-size', '12px').style('text-align', 'center');

    //X-axis label
    svg.append('text')
      .attr('transform',
        'translate(' + (width / 2) + ' ,' +
        (height + margin.top + 8) + ')')
      .attr('x', 5)
      .attr('y', -35)
      .style('text-anchor', 'middle')
      .text('Distillation (%)').style('font-weight', 'bold').style('font-size', '12px').style('margin-top','-15px').style('text-align', 'center');
    
          
    //show dot data
    svg.append("g")      
      .append("text")
      .attr("class", "axis-title")      
      .attr("x", xScale(62))
      .attr("y", yScale(min - 25))      
      .attr('width', 50)
      .attr('height', 70)
      .text("D975 Spec  Range @ 90%")
      .style('font-weight', 'bold')
      .style('font-size', '8px');

    svg.append("g")      
      .append("text")
      .attr("class", "axis-title")      
      .attr("x", xScale(55))
      .attr("y", yScale(min - 35))      
      .attr('width', 50)
      .attr('height', 70)
      .text("Value should stay inside the box")
      .style('font-weight', 'bold')
      .style('font-size', '8px');  

     //rectangular box     
     svg.append('g')
     .append('rect')
     .attr("x", xScale(85))
     .attr("y", yScale(max))
     .attr('width', xScale(95) - xScale(85))
     .attr('height', yScale(min) - yScale(max))
     .attr('stroke', '#9FE2BF')
     .attr('fill', '#9FE2BF');     

      if(this.remSampleData.length > 0){
        this.remSampleData.forEach(row => {
          svg.append("path")
          .datum(row) 
          .attr("class", "line") 
          .attr("d", line)
          .style("fill", "none")
          .style("stroke", "blue")
          .style("stroke-width", "2");
    
          svg.append('g')
          .selectAll("dot")
          .data(row)
          .enter()
          .append("circle")
          .attr("cx", function (d) { return xScale(d[0]); } )
          .attr("cy", function (d) { return yScale(d[1]); } )
          .attr("r", 5)
          .style("fill", "blue");
        });
      }

      svg.append("path")
      .datum(this.currentSampleData) 
      .attr("class", "line") 
      .attr("d", line)
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", "2");

      svg.append('g')
      .selectAll("dot")
      .data(this.currentSampleData)
      .enter()
      .append("circle")
      .attr("cx", function (d) { return xScale(d[0]); } )
      .attr("cy", function (d) { return yScale(d[1]); } )
      .attr("r", 5)
      .style("fill", "red");

      //Add the values in Y axis
      svg.append('g')
      .selectAll("circle")
      .data(this.currentSampleData)
      .enter()
      .append("text")
      .attr("class", "line")
      .style("font-size", "8px")    
      .attr("x", function(d) { return xScale(d[0]-9); })
      .attr("y", function(d) { return yScale(d[1]); })    
      .text(function(d) { return d[1]; });
  }
}
