import { AddRegisterSampleResolverService } from './../../service/resolvers/add-register-sample-resolver.service';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterSampleListComponent } from './register-sample-list/register-sample-list.component';
import { AddEditRegisterSampleComponent } from './add-edit-register-sample/add-edit-register-sample.component';
import { RegisterSampleListResolverService } from '../../service/resolvers/register-sample-list-resolver.service';

const routes: Routes = [
  { path: '', redirectTo: 'registerSampleList', pathMatch: 'full' },
  { path: 'registerSampleList', component: RegisterSampleListComponent, resolve: { data: RegisterSampleListResolverService } },
  { path: 'addEditRegisterSample', component: AddEditRegisterSampleComponent, resolve: { data: AddRegisterSampleResolverService } },
  { path: 'reviewSampleList', component: RegisterSampleListComponent, resolve: { data: RegisterSampleListResolverService } },
  { path: 'addEditReviewSample', component: AddEditRegisterSampleComponent, resolve: { data: AddRegisterSampleResolverService } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class RegisterSampleRoutingModule { }



