import { SortComponent } from './sort.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SortComponent],
  exports: [SortComponent]
})
export class SortModuleModule { }
