import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GraphData } from '../core/analyze-sample/graph-plot/graph-data';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from '../handlers/api-handler.service';
import { DataService } from '../common/data.service';
import { GraphCount } from '../core/analyze-sample/graph-plot/graph-count';
import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders } from '@angular/common/http';
import { USERS } from 'src/app/View/common/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private urls = {
    coatLogin: '/coatLogin'
  };

  public _graphData: BehaviorSubject<GraphData[]> = new BehaviorSubject<GraphData[]>([]);
  public graphData: Observable<GraphData[]> = this._graphData.asObservable();

  public _userInfo: BehaviorSubject<GraphData[]> = new BehaviorSubject<any>([]);
  public userInfo: Observable<any> = this._userInfo.asObservable();

  public _externalUserInfo: BehaviorSubject<GraphData[]> = new BehaviorSubject<any>([]);
  public externalUserInfo: Observable<any> = this._externalUserInfo.asObservable();

  public _thumbnailCount: BehaviorSubject<GraphCount[]> = new BehaviorSubject<any>(0);
  public thumbnailCount: Observable<GraphCount[]> = this._thumbnailCount.asObservable();



  public graphDataList: GraphData[] = [];
  screenName: string;
  graphCount: any = [];

  // shareData: string;

  companyId: string;
  companyName: string;

  selectedFluidType: string;
  public _errorMessage: BehaviorSubject<GraphCount[]> = new BehaviorSubject<any>('');
  public errorMessage: Observable<GraphCount[]> = this._errorMessage.asObservable();
  customerName: any;


  constructor(public dataService: DataService, private api: ApiHandlerService,
    private cookieService: CookieService) {
    this.screenName = '';
  }

  setScreenName(name): any {
    this.screenName = '';
    this.screenName = name;
  }

  getScreenName(): any {
    return this.screenName;
  }

  setInformation(data: any) {
    localStorage.setItem('information', JSON.stringify(data))
  }


  getInformation(): any {
    return JSON.parse(localStorage.getItem('information'));
  }

  setAddOrEdit(data: any) {
    localStorage.setItem('addOrEdit', JSON.stringify(data))
  }


  getAddOrEdit(): any {
    return JSON.parse(localStorage.getItem('addOrEdit'));
  }

  setUserInfo(data: any) {
    this._userInfo.next(data);
    localStorage.setItem('userInformation', JSON.stringify(data));
  }
  getUserInfo(): Observable<any> {
    return this.userInfo;
  }
  setExternalUserInfo(data: any) {
    this._externalUserInfo.next(data);
    localStorage.setItem('externalUserInformation', JSON.stringify(data))
  }
  getExternalUserInfo(): Observable<any> {
    return this.externalUserInfo;
  }
  getUserRole() {
    if (localStorage.getItem('userInformation') != null) {
      return JSON.parse(localStorage.getItem('userInformation')).roleName;
    }
  }
  getUserId() {
    if (localStorage.getItem('userInformation') != null) {
      return JSON.parse(localStorage.getItem('userInformation')).wwid;
    }
  }
  getUserInformation() {
    if (localStorage.getItem('userInformation') != null) {
      return JSON.parse(localStorage.getItem('userInformation'));
    }
  }
  getIntOrExtUser(): string {
    let isInternalUser = JSON.parse(localStorage.getItem('userInformation')).internalUser;
    if (isInternalUser) {
      return USERS.INTERNAL;
    } else {
      return USERS.EXTERNAL;
    }
  }

  getUserDetails(inputobj: any): Observable<any> {
    //console.log(this.dataService.getAuthorization());	
    var options = this.api.getServiceHttpOptions();
    if (this.dataService.getAuthorization() == null || this.dataService.getAuthorization() == '' ||
      this.dataService.getAuthorization() == undefined) {
      if (environment.isAuthRequired) {
        if (environment.Req_Server.includes(environment.internalUrl)) {
          window.location.href = environment.internalAppUrl;
        } else if (environment.Req_Server.includes(environment.externalUrl)) {
          window.location.href = environment.externalAppUrl;
        }
      }
    }
    options.headers = options.headers.append('Authorization', this.dataService.getAuthorization());
    return this.api.postWithHeader(environment.apiUrl + this.urls.coatLogin, inputobj, options);
  }

  getExternalUser(userInfo: any, token): Observable<any> {
    let url = environment.coatIAMUrl;
    url = url.replace('<username>', userInfo.wwid);
    let options = {
      headers: new HttpHeaders({
        'sourcesystem': 'COAT',
        'Authorization': 'Bearer ' + token,
        'client-id': environment.coatIAMClientId,
        'client-secret': environment.coatIAMClientSecret,
        'origin': environment.origin
      }),
    };
    return this.api.getWithHeader(url, options);
  }

  getIAMAuthToken(): Observable<any> {
    let authData = {
      "client_id": environment.IAMAuthClientId,
      "client_secret": environment.IAMAuthClientSecret,
      "resource": environment.IAMAuthResource
    }

    let authOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'origin': environment.origin
      })
    };

    return this.api.postWithHeader(environment.IAMAuthUrl, authData, authOptions);
  }

  setGraphData(data: GraphData, isAdded: boolean) {
    //this.graphDataList = [];
    // this.shareData = undefined;
    if (isAdded === true) {
      let avaliableCount = 0;
      for (let i = 0; i < this.graphDataList.length; i++) {
        if (this.graphDataList[i].graphName.replace(/ +/g, "").toLowerCase() === data.graphName.replace(/ +/g, "").toLowerCase()) {
        //if (this.graphDataList[i].graphName.toLowerCase() === data.graphName.toLowerCase()) {
          avaliableCount = avaliableCount + 1;
        }
      }
      if (avaliableCount == 0) {
        this.graphDataList.push(data);
      }

    } else {


      for (let i = 0; i < this.graphDataList.length; i++) {
        if (this.graphDataList[i].graphName.toLowerCase() === data.graphName.toLowerCase()) {
          this.graphDataList.splice(i, 1);
        }
      }


    }
    this._graphData.next(this.graphDataList);
  }

  set_graphData(data) {
    this._graphData.next(data);
  }
  updateGraphData(graphData: GraphData, inputValue: String, graphImgData: any) {
    for (let i = 0; i < this.graphDataList.length; i++) {
      if (this.graphDataList[i].graphName.toLowerCase() === graphData.graphName.toLowerCase()) {
        this.graphDataList[i].inputValue = inputValue;
        this.graphDataList[i].imageData = graphImgData;
        break;
      }
    }
    this._graphData.next(this.graphDataList);
  }

  getGraphData(): any {
    if (this.graphData) {
      return this.graphData;
    }
    return false;
  }
  resetGraphCount(value) {
    this.graphCount = value;
    this._thumbnailCount.next([]);

  }
  resetGraphData() {
    //this.graphData = value;
    this._graphData.next([]);
    this.graphDataList = [];
  }
  setThumbnailCount(selectedGraphCount: GraphCount, isCount: boolean) {

    if (isCount === true) {
      let addFlag: boolean = true;
      for (let i = 0; i < this.graphCount.length; i++) {
        if (this.graphCount[i].selectedParam == selectedGraphCount.selectedParam) {
          addFlag = false;
        }
      }
      if (addFlag === true) {
        this.graphCount.push(selectedGraphCount);

      }

    } else {


      this.graphCount = this.graphCount.filter((item) => {
        if (item.selectedParam != selectedGraphCount.selectedParam) {
          return item;
        }
      });

    }
    this._thumbnailCount.next(this.graphCount);
  }
  getThumbnailCount() {
    return this.thumbnailCount;
  }

  createSNOWIncident(sampleId): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*'
      })
    };
    var data = {
      "short_description": "COAT - Winlims Integration error",
      "customer": "rx682",
      "ci": "Oil Guard",
      "category": "Application",
      "subcategory": "Repair/Fix",
      "impact": "3",
      "urgency": "3",
      "contact": "",
      "preferred_contact_number": "",
      "description": "COAT - Winlims Integration error occured for sample id " + sampleId,
      "assignment_group": "Corporate IT - Engineering Apps - Ais Support",
      "vendor_name": "",
      "vendor_ticket_number": "",
      "work_group": ""
    };
    return this.api.postWithHeader(environment.createSNOWIncident, data, options);
  }

  setCompanyId(companyId) {
    this.companyId = companyId;
  }

  getCompanyId() {
    if (this.companyId == undefined) {
      if (localStorage.getItem('externalUserInformation') != null) {
        return JSON.parse(localStorage.getItem('externalUserInformation')).IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c;
      } else {
        return this.companyId;
      }
    } else {
      return this.companyId;
    }
  }

  setCompanyName(companyName){
    this.companyName = companyName;
  }
  getCompanyName() {
    if (this.companyName == undefined) {
      if (localStorage.getItem('externalUserInformation') != null) {
        return JSON.parse(localStorage.getItem('externalUserInformation')).IAM_Account_Application__r.IAM_Account_Name__r.Name;
      } else {
        return this.companyName;
      }
    } else {
      return this.companyName;
    }
  }

  setErrorMessage(errorMessage) {
    this._errorMessage.next(errorMessage);
  }

  getErrorMessage(): Observable<any> {
    return this.errorMessage;
  }

  setFluidType(fluidType: any) {
    this.selectedFluidType = fluidType;
  }
  getFluidType() {
    return this.selectedFluidType;
  }

  analysisScreenCheck = new BehaviorSubject("");

  setAnalysisScreenCheck(data){
    this.analysisScreenCheck.next(data);
 }

  getAnalysisScreenCheck(): Observable<any> {
    return this.analysisScreenCheck.asObservable();
 }

 private dropdowndata = new BehaviorSubject("");
 currentChange = this.dropdowndata.asObservable();
 dropDownFunction(message: any){
   this.dropdowndata.next(message);
 }

 private dropdowndataTSM = new BehaviorSubject("");
 currentChangeTSM = this.dropdowndataTSM.asObservable();
 dropDownFunctionTSM(message: any){
   this.dropdowndataTSM.next(message);
 }

 private dropdowndataInternal = new BehaviorSubject("");
 currentChangeInternal = this.dropdowndataInternal.asObservable();
  dropDownFunctionInternal(message: any){
   this.dropdowndataInternal.next(message);
 }
}
