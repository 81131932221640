import { Component, OnInit } from '@angular/core';
import { ModifyThresholdService } from './modify-threshold.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { parseFloat } from 'core-js/fn/number';
import { disableDebugTools } from '@angular/platform-browser';
import { parse } from 'path';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-modify-threshold',
  templateUrl: './modify-threshold.component.html',
  styleUrls: ['./modify-threshold.component.css']
})
export class ModifyThresholdComponent implements OnInit {

  isCalc : boolean=false;
  modifyThresholdHeaders = [];
  populationData : any;
  populationDataList = [];
  currentPopulationValue = [];
  currentPopulationValueList = [];
  dashboardData = [];
  parameterDropdownValues = [];
  parameterList = [];
  kAlRatioObj = {};
  selectedParamName = '';
  selectedParamId = '';
  limitFlagCalcList = [];
  limitFlagCalculated = [];
  proposedCalcList = [];
  successModal: boolean = false;
  applyThresholdSuccessModal: boolean = false;
  statusMessage = '';
  applyThresholdConfirmMessage = 'Please confirm you wish to apply the proposed NEW threshold values for this population dataset.';
  precision = '';
  warningHighHigherThreshold = '';
  calculateButton: boolean = false;
  applyProposedButton: boolean = false;
  isKalRatio: boolean = false;
  itemsForNormalFlag: '';
  isKalRatioSpan: boolean = false;
  isAlKRatioSpan: boolean = false;
  disableSaveBtnOnReset: boolean = false;
  parameterData=[];
  valid:any
  noOfdecimal:any;
  noOfdecimalforcloudpoint:any;
  result: '';
  isFuelSelected: boolean = false;
  scrollToTop = false;
  proposedFTIRThresholdForm = new UntypedFormGroup({
    warningLowLower: new UntypedFormControl({ value: '', disabled: true }),
    warningLowHigher: new UntypedFormControl({ value: '', disabled: true }),
    cautionLowLower: new UntypedFormControl({ value: '', disabled: true }),
    cautionLowHigher: new UntypedFormControl({ value: '', disabled: true }),
    watchLowLower: new UntypedFormControl({ value: '', disabled: true }),
    watchLowHigher: new UntypedFormControl({ value: '', disabled: true }),
    normalLower: new UntypedFormControl(''),
    normalHigher: new UntypedFormControl(''),
    watchHighLower: new UntypedFormControl({ value: 0, disabled: true }),
    watchHighHigher: new UntypedFormControl({ value: '', disabled: true }),
    cautionHighLower: new UntypedFormControl({ value: '', disabled: true }),
    cautionHighHigher: new UntypedFormControl({ value: '', disabled: true }),
    warningHighLower: new UntypedFormControl({ value: '', disabled: true }),
    lowRangeCheckBox: new UntypedFormControl(''),
    highRangeCheckBox: new UntypedFormControl(''),
    lowRangeCheckBoxWarning: new UntypedFormControl(''),
    lowRangeCheckBoxCaution: new UntypedFormControl(''),
    lowRangeCheckBoxWatch: new UntypedFormControl(''),
    highRangeCheckBoxWatch: new UntypedFormControl(''),
    highRangeCheckBoxCaution: new UntypedFormControl(''),
    highRangeCheckBoxWarning: new UntypedFormControl(''),
    kAlRatioCheck: new UntypedFormControl(''),
    kAlRatioInput: new UntypedFormControl({ value: '', disabled: true }),
    countIfItems: new UntypedFormControl({ value: '', disabled: true }),
    // warningHighHigher: new FormControl({ value: '', disabled: true }),
    normalFTIR: new UntypedFormControl('', Validators.required),
    watchFTIR: new UntypedFormControl('', Validators.required),
    cautionFTIR: new UntypedFormControl('', Validators.required),
    warningFTIR: new UntypedFormControl( '', Validators.required),
  });
  proposedThresholdForm = new UntypedFormGroup({
    warningLowLower: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    warningLowHigher: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    cautionLowLower: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    cautionLowHigher: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    watchLowLower: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    watchLowHigher: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    normalLower: new UntypedFormControl('', Validators.required),
    normalHigher: new UntypedFormControl('', Validators.required),
    watchHighLower: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    watchHighHigher: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    cautionHighLower: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    cautionHighHigher: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    warningHighLower: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    lowRangeCheckBox: new UntypedFormControl(''),
    highRangeCheckBox: new UntypedFormControl(''),
    lowRangeCheckBoxWarning: new UntypedFormControl(''),
    lowRangeCheckBoxCaution: new UntypedFormControl(''),
    lowRangeCheckBoxWatch: new UntypedFormControl(''),
    highRangeCheckBoxWatch: new UntypedFormControl(''),
    highRangeCheckBoxCaution: new UntypedFormControl(''),
    highRangeCheckBoxWarning: new UntypedFormControl(''),
    kAlRatioCheck: new UntypedFormControl(''),
    kAlRatioInput: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
    countIfItems: new UntypedFormControl({ value: '', disabled: true }),
    // warningHighHigher: new FormControl({ value: '', disabled: true }),
  });
  parameterFTIR: boolean ;
  enablecalculatebutton: boolean=false;
  enableapplybutton: boolean =false;
  normalLower: any;
  normalHigh: any;
  showcloudpointtable: boolean;
  constructor(private modifyThresholdServ: ModifyThresholdService, private alertService: AlertService, 
    private fb: UntypedFormBuilder, private sharedService: SharedService) { }

  ngOnInit() {
    this.modifyThresholdHeaders = [
      { code: 'lastUpdatedDate', name: 'Last Updated Date' },
      { code: 'fluidType', name: 'Fluid Type' },
      { code: 'populationName', name: 'Population Name' },
      { code: 'itemsInPopulation', name: 'Items in Population' },
      { code: 'validItems', name: 'Valid Items in Population' }
    ];
    this.populationData = JSON.parse(localStorage.getItem('populationForLimitFlag'));
    this.populationDataList.push(this.populationData);
    let fluidTypeCoolant = this.populationData.fluidType;
    this.dashboardData = JSON.parse(localStorage.getItem('dashboardData'));
    this.parameterList = JSON.parse(localStorage.getItem('parameterList'));
    this.currentPopulationValue = JSON.parse(localStorage.getItem('currentValue'));
    this.currentPopulationValueList.push(this.currentPopulationValue);
    this.kAlRatioObj = JSON.parse(localStorage.getItem('kAlRatio'));
    this.parameterData= this.currentPopulationValue;
    this.valid=this.currentPopulationValue['validItems'];  
    // console.log("validItems",this.parameterData['validItems']);
    //console.log("currentPopulationValue", this.currentPopulationValue, "populationData", this.currentPopulationValueList,
    //  'dashboardData', this.dashboardData, "parameterList", this.parameterList, "populationDataList", this.currentPopulationValueList,
    //  this.kAlRatioObj["AL_K"]);
    this.calculateCurrentValue();
    //this.calculateButton = false;
    this.applyProposedButton = true;
    console.log(this.parameterData)
    console.log(this.selectedParamName)
    if(this.selectedParamName=='CLOUD_POINT'){
     this.showcloudpointtable = true;
    }else{
      this.showcloudpointtable = false;

    }
    if(this.populationData.fluidTypeId == 4 ){
      this.isFuelSelected = true;
    }else{
      this.isFuelSelected = false;
    }
    if (this.selectedParamName == 'K' || this.selectedParamName == 'Al') {
      this.isKalRatio = true;
    }
    else {
      this.isKalRatio = false;
    }

    if (this.selectedParamName == 'K') {
      this.isKalRatioSpan = true;
      this.isAlKRatioSpan = false;
    }

    if (this.selectedParamName == 'Al') {
      this.isAlKRatioSpan = true;
      this.isKalRatioSpan = false;
    }
    
    /* Coolan Al span remove */
    if (this.selectedParamName == 'Al' && fluidTypeCoolant == 'Coolant' ) {
      this.isAlKRatioSpan = false;
      this.isKalRatioSpan = false;
      this.isKalRatio = false;
    }

    if (this.selectedParamName == 'Al' && fluidTypeCoolant == 'Diesel Fuel' ) {
      this.isAlKRatioSpan = false;
      this.isKalRatioSpan = false;
      this.isKalRatio = false;
    }
    if (this.selectedParamName == 'K' && fluidTypeCoolant == 'Diesel Fuel' ) {
      this.isAlKRatioSpan = false;
      this.isKalRatioSpan = false;
      this.isKalRatio = false;
    }
    this.setNoOfdecimal();
  }

  setNoOfdecimal(){
   if(this.selectedParamName == 'Soot' || this.selectedParamName == 'Nitration' || 
       this.selectedParamName == 'Oxidation' || this.selectedParamName == 'Vis100' ||
       this.selectedParamName == 'Vis40' || this.selectedParamName == 'TBN' || 
       this.selectedParamName == 'TAN' || this.selectedParamName == 'Fluoride' 
      || this.selectedParamName == 'WATER_SEDIMENT'){
      this.noOfdecimal = 2;
    }else if(this.selectedParamName == 'CETANE_INDEX' || this.selectedParamName == 'SP_GRAVITY'){
      this.noOfdecimal = 3;
    }
    else if(this.selectedParamName == 'FuelDilution' || this.selectedParamName == 'PERCENT_GLYCOL' || this.selectedParamName == 'pH' || this.selectedParamName == 'Glycol' ||  this.selectedParamName == 'SCA' || this.selectedParamName == 'SULFUR' 
      || this.selectedParamName == 'Conductivity' || this.selectedParamName == 'OXIDATION_STABILITY'
    ){
      this.noOfdecimal = 1;
    }else if(this.selectedParamName == 'CLOUD_POINT'){
      this.noOfdecimalforcloudpoint = 1;

    }
    else{
      this.noOfdecimal = 0;
    }
  }
  roundNumber(number, decimals) {
    var newnumber = new Number(number+'').toFixed(parseInt(decimals));
    return parseFloat(newnumber); 
  }
  ngDoCheck() {
    var precisionCheck = this.precision;
   //for high range changes
   if( this.populationData.fluidTypeId != 4 ){
    if (this.proposedThresholdForm.value.highRangeCheckBox == true ) {
      if (this.proposedThresholdForm.value.normalHigher !== '' && this.proposedThresholdForm.value.normalHigher !== null) {
        var watchHighLowerAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.normalHigher) + parseFloat(precisionCheck), 12);
        this.proposedThresholdForm.controls['watchHighLower'].patchValue(watchHighLowerAuto);
      }
      else if (this.proposedThresholdForm.value.normalHigher == null) {
        this.proposedThresholdForm.value.watchHighLower = '';

      }
      else {
        this.proposedThresholdForm.controls['watchHighLower'].patchValue('');
      }
      if (this.proposedThresholdForm.value.watchHighHigher !== '' && this.proposedThresholdForm.value.watchHighHigher !== null) {
        var cautionHighLowerAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.watchHighHigher) + parseFloat(precisionCheck), 12);
        
        this.proposedThresholdForm.controls['cautionHighLower'].patchValue(cautionHighLowerAuto);
      } else {
        this.proposedThresholdForm.controls['cautionHighLower'].patchValue('');

      }
      if (this.proposedThresholdForm.value.cautionHighHigher !== '' && this.proposedThresholdForm.value.cautionHighHigher !== null) {
        var warningHighLowerAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.cautionHighHigher), 12);
        this.proposedThresholdForm.controls['warningHighLower'].patchValue(warningHighLowerAuto);
      } else {
        this.proposedThresholdForm.controls['warningHighLower'].patchValue('');
      }
    }
    else {
      this.proposedThresholdForm.controls['watchHighLower'].patchValue('');
      this.proposedThresholdForm.controls['cautionHighLower'].patchValue('');
      this.proposedThresholdForm.controls['warningHighLower'].patchValue('');
    }
    }else{
        //for Fuel changes
        if(this.populationData.fluidTypeId == 4 ){
          if (this.proposedThresholdForm.value.highRangeCheckBoxWatch == true) {
            if (this.proposedThresholdForm.value.normalHigher !== '' && this.proposedThresholdForm.value.normalHigher !== undefined && this.proposedThresholdForm.value.normalHigher !== null) {
              var watchHighLower =  this.roundNumber(parseFloat(this.proposedThresholdForm.value.normalHigher)+ parseFloat(precisionCheck), 12);
              this.proposedThresholdForm.controls['watchHighLower'].patchValue(watchHighLower);
            }
            else if (this.proposedThresholdForm.value.normalHigher == null) {
              this.proposedThresholdForm.value.watchHighLower = '';
            }
            else {
              this.proposedThresholdForm.controls['watchHighLower'].patchValue('');
            }
          }
          
        if (this.proposedThresholdForm.value.highRangeCheckBoxCaution == true) {
          if (this.proposedThresholdForm.value.watchHighHigher !== '' && this.proposedThresholdForm.value.watchHighHigher !== undefined && this.proposedThresholdForm.value.watchHighHigher !== null) {
            var cautionHighLower =  this.roundNumber(parseFloat(this.proposedThresholdForm.value.watchHighHigher)+ parseFloat(precisionCheck), 12);
            this.proposedThresholdForm.controls['cautionHighLower'].patchValue(cautionHighLower);
          }
          else if (this.proposedThresholdForm.value.watchHighHigher == null) {
            this.proposedThresholdForm.value.cautionHighLower = '';
          }
          else {
            this.proposedThresholdForm.controls['cautionHighLower'].patchValue('');
          }
        } 
        if (this.proposedThresholdForm.value.highRangeCheckBoxWarning == true) {
          if (this.proposedThresholdForm.value.cautionHighHigher !== '' && this.proposedThresholdForm.value.cautionHighHigher !== undefined && this.proposedThresholdForm.value.cautionHighHigher !== null) {
            var warningHighLower = this.roundNumber(parseFloat(this.proposedThresholdForm.value.cautionHighHigher), 12);
            this.proposedThresholdForm.controls['warningHighLower'].patchValue(warningHighLower);
          }
          else if(this.proposedThresholdForm.value.cautionHighHigher == null){
            this.proposedThresholdForm.value.warningHighLower = '';
          }
          else {
            this.proposedThresholdForm.controls['warningHighLower'].patchValue('');
          }
        } 
    
        }
      }   
    // var warningHighLowerAuto = parseInt(this.proposedThresholdForm.value.cautionHighHigher) + parseFloat(precisionCheck);
    // this.proposedThresholdForm.controls['warningHighLower'].patchValue(warningHighLowerAuto);
    //========================================================lower ranges=====================================================================
  if( this.populationData.fluidTypeId != 4 ){
    if (this.proposedThresholdForm.value.lowRangeCheckBox == true ) {
      if (this.proposedThresholdForm.value.normalLower !== '' && this.proposedThresholdForm.value.normalLower !== null) {
        var watchLowhigherAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.normalLower) - parseFloat(precisionCheck), 12);
        this.proposedThresholdForm.controls['watchLowHigher'].patchValue(watchLowhigherAuto);
      } else {
        this.proposedThresholdForm.controls['watchLowHigher'].patchValue('');
      }

      if (this.proposedThresholdForm.value.watchLowLower !== '' && this.proposedThresholdForm.value.watchLowLower !== null) {
        var cautionLowhigherAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.watchLowLower) - parseFloat(precisionCheck), 12);
        this.proposedThresholdForm.controls['cautionLowHigher'].patchValue(cautionLowhigherAuto);
      } else {
        this.proposedThresholdForm.controls['cautionLowHigher'].patchValue('');

      }
      if (this.proposedThresholdForm.value.cautionLowLower !== '' && this.proposedThresholdForm.value.cautionLowLower !== null) {
        var warningLowhigherAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.cautionLowLower) - parseFloat(precisionCheck), 12);
        this.proposedThresholdForm.controls['warningLowHigher'].patchValue(warningLowhigherAuto);
      }
      else {
        this.proposedThresholdForm.controls['warningLowHigher'].patchValue('');
      }
    }
    else {
      this.proposedThresholdForm.controls['watchLowHigher'].patchValue('');
      this.proposedThresholdForm.controls['cautionLowHigher'].patchValue('');
      this.proposedThresholdForm.controls['warningLowHigher'].patchValue('');
    }
   }else{
       //for Fuel changes
       if(this.populationData.fluidTypeId == 4 ){
        if (this.proposedThresholdForm.value.lowRangeCheckBoxWarning == true) {
          if (this.proposedThresholdForm.value.cautionLowLower !== '' && this.proposedThresholdForm.value.cautionLowLower !== null) {
            var warningLowLower = this.roundNumber(parseFloat(this.proposedThresholdForm.value.cautionLowLower) - parseFloat(precisionCheck), 12);
            // var warningLowLower = this.proposedThresholdForm.controls.warningLowLower.value;
            this.proposedThresholdForm.controls['warningLowHigher'].patchValue(warningLowLower);
          }
          else {
            this.proposedThresholdForm.controls['warningLowHigher'].patchValue('');
          }
        } 
  
        if (this.proposedThresholdForm.value.lowRangeCheckBoxCaution == true) {
          if (this.proposedThresholdForm.value.watchLowLower !== '' && this.proposedThresholdForm.value.watchLowLower !== null) {
            var cautionLowLower = this.roundNumber(parseFloat(this.proposedThresholdForm.value.watchLowLower) - parseFloat(precisionCheck), 12);
            this.proposedThresholdForm.controls['cautionLowHigher'].patchValue(cautionLowLower);
          }
          else {
            this.proposedThresholdForm.controls['cautionLowHigher'].patchValue('');
          }
        } 
        if (this.proposedThresholdForm.value.lowRangeCheckBoxWatch == true) {
          if (this.proposedThresholdForm.value.normalLower !== '' && this.proposedThresholdForm.value.normalLower !== null) {
            var watchLowLower = this.roundNumber(parseFloat(this.proposedThresholdForm.value.normalLower) - parseFloat(precisionCheck), 12);
            this.proposedThresholdForm.controls['watchLowHigher'].patchValue(watchLowLower);
          }
          else {
            this.proposedThresholdForm.controls['watchLowHigher'].patchValue('');
         }
        } 
      }
    } 
   /* if (this.proposedThresholdForm.value.lowRangeCheckBoxCaution == true) {
      if (this.proposedThresholdForm.value.watchLowLower !== '' && this.proposedThresholdForm.value.watchLowLower !== null) {
        var cautionLowhigherAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.watchLowLower), 12);
        this.proposedThresholdForm.controls['cautionLowHigher'].patchValue(cautionLowhigherAuto);
      } else {
        this.proposedThresholdForm.controls['cautionLowHigher'].patchValue('');

      }
      if (this.proposedThresholdForm.value.cautionLowLower !== '' && this.proposedThresholdForm.value.cautionLowLower !== null) {
        var warningLowhigherAuto = this.roundNumber(parseFloat(this.proposedThresholdForm.value.cautionLowLower), 12);
        this.proposedThresholdForm.controls['warningLowHigher'].patchValue(warningLowhigherAuto);
      }
      else {
        this.proposedThresholdForm.controls['warningLowHigher'].patchValue('');

      }
    }
    else {
      this.proposedThresholdForm.controls['watchLowHigher'].patchValue('');
      this.proposedThresholdForm.controls['cautionLowHigher'].patchValue('');
      this.proposedThresholdForm.controls['warningLowHigher'].patchValue('');

    }*/

    if (this.proposedThresholdForm.value.kAlRatioCheck == true) {
      this.proposedThresholdForm.controls.kAlRatioInput.enable();
    } else {
      this.proposedThresholdForm.controls.kAlRatioInput.disable();
      this.proposedThresholdForm.controls['kAlRatioInput'].reset();

    }
  }

  //to get the current value on change of dropdown parameter
  onChangeParameter(param) {
    console.log("param",param); 
    
    if(param=='CLOUD_POINT'){
      this.showcloudpointtable = true;
    }else{
       this.showcloudpointtable = false;
    }
    this.dashboardData.forEach(item =>
      {
         if(item.parameter === param)
         {
           this.valid=item.validItems;
         }
      });

    this.proposedCalcList = [];
    this.itemsForNormalFlag = '';
    this.selectedParamName = param;
    this.setNoOfdecimal();
    //console.log("parametr selected", this.selectedParamName);
    this.currentPopulationValueList = [];
    for (let i = 0; i < this.dashboardData.length; i++) {
      if (param == this.dashboardData[i].parameter) {
          if(param.toUpperCase() == 'FUELDILUTION_FTIR' || param.toUpperCase() == 'WATER_FTIR' ){
            this.parameterFTIR = true;
          }else{
            this.parameterFTIR = false;
          }
          if(this.proposedFTIRThresholdForm.controls.normalFTIR.value !=="" && this.proposedFTIRThresholdForm.controls.watchFTIR.value !=="" && 
          this.proposedFTIRThresholdForm.controls.cautionFTIR.value !=="" && this.proposedFTIRThresholdForm.controls.warningFTIR.value !==""){
           this.enablecalculatebutton=true;
          }else{
            this.enablecalculatebutton=false;

          }
        this.currentPopulationValueList.push(this.dashboardData[i]);
        // localStorage.setItem('currentValue', JSON.parse(this.currentPopulationValueList));
        this.precision = this.dashboardData[i].precision;
        this.warningHighHigherThreshold = this.dashboardData[i].warningHighHigherThreshold;
      }
    }
    //console.log("current value of changed parameter", this.currentPopulationValueList, "precision--->", this.precision);
    for (let i = 0; i < this.parameterList.length; i++) {
      if (this.selectedParamName == this.parameterList[i].parameterName) {
        this.selectedParamId = this.parameterList[i].parameter;
      }
    }
    this.calculateCurrentValue();
    if (this.proposedThresholdForm.value.highRangeCheckBox == true) {
      this.proposedThresholdForm.controls['watchHighLower'].patchValue('');
      this.proposedThresholdForm.controls['cautionHighLower'].patchValue('');
    }
    this.proposedThresholdForm.controls.warningLowLower.disable();
    this.proposedThresholdForm.controls.warningLowHigher.disable();
    this.proposedThresholdForm.controls.cautionLowLower.disable();
    this.proposedThresholdForm.controls.cautionLowHigher.disable();
    this.proposedThresholdForm.controls.watchLowLower.disable();
    this.proposedThresholdForm.controls.watchLowHigher.disable();
    this.proposedThresholdForm.controls.warningHighLower.disable();
    //this.proposedThresholdForm.controls.warningHighHigher.disable();
    this.proposedThresholdForm.controls.cautionHighLower.disable();
    this.proposedThresholdForm.controls.cautionHighHigher.disable();
    this.proposedThresholdForm.controls.watchHighLower.disable();
    this.proposedThresholdForm.controls.watchHighHigher.disable();
    //this.proposedThresholdForm.controls['watchHighLower'].patchValue('');
    //this.proposedThresholdForm.controls['cautionHighLower'].patchValue('');
    this.resetThreshold();
    this.applyProposedButton = true;
    if (this.selectedParamName == 'K' || this.selectedParamName == 'Al') {
      this.isKalRatio = true;
    } else {
      this.isKalRatio = false;
    }
    if (this.selectedParamName == 'K') {
      this.isKalRatioSpan = true;
      this.isAlKRatioSpan = false;
    }

    if (this.selectedParamName == 'Al') {
      this.isAlKRatioSpan = true;
      this.isKalRatioSpan = false;
    }
    if (this.selectedParamName == 'Al' ) {
      this.isAlKRatioSpan = true;
      this.isKalRatioSpan = false;
    }
     /* Coolan Al span remove */
     if (this.selectedParamName == 'Al' && this.populationData.fluidType == 'Coolant' ) {
      this.isAlKRatioSpan = false;
      this.isKalRatioSpan = false;
      this.isKalRatio = false;
    }

    if (this.selectedParamName == 'Al' && this.populationData.fluidType == 'Diesel Fuel' ) {
      this.isAlKRatioSpan = false;
      this.isKalRatioSpan = false;
      this.isKalRatio = false;
    }
    if (this.selectedParamName == 'K' && this.populationData.fluidType == 'Diesel Fuel' ) {
      this.isAlKRatioSpan = false;
      this.isKalRatioSpan = false;
      this.isKalRatio = false;
    }

  }
  validateFTIRInput(){
    if(this.proposedFTIRThresholdForm.controls.normalFTIR.value !== "" && this.proposedFTIRThresholdForm.controls.watchFTIR.value !=="" && 
          this.proposedFTIRThresholdForm.controls.cautionFTIR.value !== "" && this.proposedFTIRThresholdForm.controls.warningFTIR.value !==""){
          this.enablecalculatebutton=true;
          this.enableapplybutton=true;
          this.alertService.clear();
    }else{
          this.enablecalculatebutton=false;
          this.enableapplybutton=false;
          this.alertService.error('Please fill all mandatory columns');
    }
  }

  calculateCurrentValue() {
    let kAlChecked:boolean = false;
    this.limitFlagCalcList = [];
    for (let i = 0; i < this.populationDataList.length; i++) {
      var totalItemsInPopulation = this.populationDataList[i].itemsInPopulation;
      var populationId = this.populationDataList[i].populationId;
      
    }
    for (let i = 0; i < this.currentPopulationValueList.length; i++) {
      this.precision = this.currentPopulationValueList[i].precision;

    }
    for (let i = 0; i < this.currentPopulationValueList.length; i++) {
      this.selectedParamName = this.currentPopulationValueList[i].parameter;
      var watchLowLowerThreshold = this.currentPopulationValueList[i].watchlowLowerThreshold;
      var watchLowHigherThreshold = this.currentPopulationValueList[i].watchLowHigherThreshold;
      var cautionLowLowerThreshold = this.currentPopulationValueList[i].cautionLowLowerThreshold;
      var cautionLowHigherThreshold = this.currentPopulationValueList[i].cautionLowHigherThreshold;
      var warningLowLowerThreshold = this.currentPopulationValueList[i].warningLowLowerThreshold;
      var warningLowHigherThreshold = this.currentPopulationValueList[i].warningLowHigherThreshold;
      var normalLowThreshold = this.currentPopulationValueList[i].normalLowThreshold;
      var normalHighThreshold = this.currentPopulationValueList[i].normalHighThreshold;
      var watchHighLowerThreshold = this.currentPopulationValueList[i].watchHighLowerThreshold;
      var watchHighHigherThreshold = this.currentPopulationValueList[i].watchHighHigherThreshold;
      var cautionHighLowerThreshold = this.currentPopulationValueList[i].cautionHighLowerThreshold;
      var cautionHighHigherThreshold = this.currentPopulationValueList[i].cautionHighHigherThreshold;
      var warningHighLowerThreshold = this.currentPopulationValueList[i].warningHighLowerThreshold;
      var warningHighHigherThreshold = this.currentPopulationValueList[i].warningHighHigherThreshold;
      var normalFTIRThreshold = this.currentPopulationValueList[i].normalFTIRThreshold;
      var warningFTIRThreshold = this.currentPopulationValueList[i].warningFTIRThreshold;
      var watchFTIRThreshold = this.currentPopulationValueList[i].watchFTIRThreshold;
      var cautionFTIRThreshold = this.currentPopulationValueList[i].cautionFTIRThreshold;
    }
    for (let i = 0; i < this.parameterList.length; i++) {
      if (this.selectedParamName == this.parameterList[i].parameterName) {
        this.selectedParamId = this.parameterList[i].parameter;
      }
    }

    if (this.selectedParamName == 'Al' && this.kAlRatioObj["AL_K"] >= 0) {
      var kAlRatioOnLoad = this.kAlRatioObj["AL_K"];
      kAlChecked = true;
    } else if (this.selectedParamName == 'K' && this.kAlRatioObj["K_AL"] >= 0) {
      kAlRatioOnLoad = this.kAlRatioObj["K_AL"]
      kAlChecked = true;

    } else {
      kAlRatioOnLoad = ''
      kAlChecked = false;
    }
    for (let i = 0; i < this.populationDataList.length; i++) {
      var populationId = this.populationDataList[i].populationId;
      var fluidTypeId = this.populationDataList[i].fluidTypeId;
      if((this.selectedParamName == 'Water_FTIR' || this.selectedParamName == 'FuelDilution_FTIR') && (fluidTypeId == '1' || fluidTypeId == '2' || fluidTypeId == '6')){
        this.parameterFTIR = true;
      }else{
        this.parameterFTIR = false;
      }
    }
    let reqParam = {
      fluidType: this.populationData.fluidTypeId,
      populationId: populationId,
      parameter: this.selectedParamId,
      totalItemsPopulation: totalItemsInPopulation,
      watchLowLowerThreshold: watchLowLowerThreshold,
      watchLowHigherThreshold: watchLowHigherThreshold,
      cautionLowLowerThreshold: cautionLowLowerThreshold,
      cautionLowHigherThreshold: cautionLowHigherThreshold,
      warningLowLowerThreshold: warningLowLowerThreshold,
      warningLowHigherThreshold: warningLowHigherThreshold,
      normalLowThreshold: normalLowThreshold,
      normalHighThreshold: normalHighThreshold,
      watchHighLowerThreshold: watchHighLowerThreshold,
      watchHighHigherThreshold: watchHighHigherThreshold,
      cautionHighLowerThreshold: cautionHighLowerThreshold,
      cautionHighHigherThreshold: cautionHighHigherThreshold,
      warningHighLowerThreshold: warningHighLowerThreshold,
      warningHighHigherThreshold: warningHighHigherThreshold,
      normalFTIRThreshold: normalFTIRThreshold,
      watchFTIRThreshold: watchFTIRThreshold,
      warningFTIRThreshold: warningFTIRThreshold,
      cautionFTIRThreshold: cautionFTIRThreshold
    }
    console.log(reqParam, 'fluidtype3');
    let reqParamWithKalRatio = {
      populationId: populationId,
      parameter: this.selectedParamId,
      kAlRatio: kAlRatioOnLoad,
      includeRatio: kAlChecked,
      totalItemsPopulation: totalItemsInPopulation,
      watchLowLowerThreshold: watchLowLowerThreshold,
      watchLowHigherThreshold: watchLowHigherThreshold,
      cautionLowLowerThreshold: cautionLowLowerThreshold,
      cautionLowHigherThreshold: cautionLowHigherThreshold,
      warningLowLowerThreshold: warningLowLowerThreshold,
      warningLowHigherThreshold: warningLowHigherThreshold,
      normalLowThreshold: normalLowThreshold,
      normalHighThreshold: normalHighThreshold,
      watchHighLowerThreshold: watchHighLowerThreshold,
      watchHighHigherThreshold: watchHighHigherThreshold,
      cautionHighLowerThreshold: cautionHighLowerThreshold,
      cautionHighHigherThreshold: cautionHighHigherThreshold,
      warningHighLowerThreshold: warningHighLowerThreshold,
      warningHighHigherThreshold: warningHighHigherThreshold,
    }
    if (this.selectedParamName == 'K' || this.selectedParamName == 'Al') {
      //console.log("calc req param", reqParamWithKalRatio, "precision-->", this.precision);
      this.modifyThresholdServ.calculateThreshold(reqParamWithKalRatio).subscribe(response => {
        console.log("current value calculation response", response);
        this.limitFlagCalcList.push(response.limitFlaggingCalculation);
      });
    } else {
      this.modifyThresholdServ.calculateThreshold(reqParam).subscribe(response => {
        console.log("current value calculation response1", response);
        this.limitFlagCalcList.push(response.limitFlaggingCalculation);
      });
    }

  }

  calculateProposedThreshold() {
    this.proposedCalcList = [];
    for (let i = 0; i < this.populationDataList.length; i++) {
      var totalItemsInPopulation = this.populationDataList[i].itemsInPopulation;
      var populationId = this.populationDataList[i].populationId;
    }
    for (let i = 0; i < this.currentPopulationValueList.length; i++) {
      var warningHighHigherThreshold = this.currentPopulationValueList[i].warningHighHigherThreshold;
    }
  
    let reqParam = {
      fluidType:this.populationData.fluidTypeId,
      populationId: populationId,
      parameter: this.selectedParamId,
      totalItemsPopulation: this.valid,
      includeRatio: false,
      watchLowLowerThreshold: this.proposedThresholdForm.value.watchLowLower,
      watchLowHigherThreshold: this.proposedThresholdForm.value.watchLowHigher,
      cautionLowLowerThreshold: this.proposedThresholdForm.value.cautionLowLower,
      cautionLowHigherThreshold: this.proposedThresholdForm.value.cautionLowHigher,
      warningLowLowerThreshold: this.proposedThresholdForm.value.warningLowLower,
      warningLowHigherThreshold: this.proposedThresholdForm.value.warningLowHigher,
      normalLowThreshold: this.proposedThresholdForm.value.normalLower,
      normalHighThreshold: this.proposedThresholdForm.value.normalHigher,
      watchHighLowerThreshold: this.proposedThresholdForm.value.watchHighLower,
      watchHighHigherThreshold: this.proposedThresholdForm.value.watchHighHigher,
      cautionHighLowerThreshold: this.proposedThresholdForm.value.cautionHighLower,
      cautionHighHigherThreshold: this.proposedThresholdForm.value.cautionHighHigher,
      warningHighLowerThreshold: this.proposedThresholdForm.value.warningHighLower,
      warningHighHigherThreshold: warningHighHigherThreshold,
      normalFTIRThreshold: this.proposedFTIRThresholdForm.value.normalFTIR,
      watchFTIRThreshold: this.proposedFTIRThresholdForm.value.watchFTIR,
      warningFTIRThreshold: this.proposedFTIRThresholdForm.value.warningFTIR,
      cautionFTIRThreshold: this.proposedFTIRThresholdForm.value.cautionFTIR,

    }
    let reqParamWithKalRatio = {
      populationId: populationId,
      parameter: this.selectedParamId,
      totalItemsPopulation: this.valid,
      includeRatio: this.proposedThresholdForm.value.kAlRatioCheck,
      kAlRatio: this.proposedThresholdForm.value.kAlRatioInput,
      watchLowLowerThreshold: this.proposedThresholdForm.value.watchLowLower,
      watchLowHigherThreshold: this.proposedThresholdForm.value.watchLowHigher,
      cautionLowLowerThreshold: this.proposedThresholdForm.value.cautionLowLower,
      cautionLowHigherThreshold: this.proposedThresholdForm.value.cautionLowHigher,
      warningLowLowerThreshold: this.proposedThresholdForm.value.warningLowLower,
      warningLowHigherThreshold: this.proposedThresholdForm.value.warningLowHigher,
      normalLowThreshold: this.proposedThresholdForm.value.normalLower,
      normalHighThreshold: this.proposedThresholdForm.value.normalHigher,
      watchHighLowerThreshold: this.proposedThresholdForm.value.watchHighLower,
      watchHighHigherThreshold: this.proposedThresholdForm.value.watchHighHigher,
      cautionHighLowerThreshold: this.proposedThresholdForm.value.cautionHighLower,
      cautionHighHigherThreshold: this.proposedThresholdForm.value.cautionHighHigher,
      warningHighLowerThreshold: this.proposedThresholdForm.value.warningHighLower,
      warningHighHigherThreshold: warningHighHigherThreshold,
    }
    if ((this.selectedParamName == 'K' || this.selectedParamName == 'Al') && this.proposedThresholdForm.value.kAlRatioCheck == true) {

      this.modifyThresholdServ.calculateThreshold(reqParamWithKalRatio).subscribe(response => {
       // console.log("response.limitFlaggingCalculation", response.limitFlaggingCalculation);
        this.itemsForNormalFlag = response.limitFlaggingCalculation.itemsForNormalFlag
        this.proposedCalcList.push(response.limitFlaggingCalculation);
        this.proposedThresholdForm.patchValue({
          countIfItems: response.limitFlaggingCalculation.countOfItemsImpacted
        });
        //console.log("proposed threshold calculation response", reqParamWithKalRatio, this.proposedCalcList);
        this.isCalc= true;
      });
    }else {
      this.modifyThresholdServ.calculateThreshold(reqParam).subscribe(response => {
      this.itemsForNormalFlag = response.limitFlaggingCalculation.itemsForNormalFlag
        this.proposedCalcList.push(response.limitFlaggingCalculation);        
       // console.log("proposed threshold calculation response", reqParam, this.proposedCalcList);
       this.isCalc= true;
      });
    }
    this.applyProposedButton = false;
  }

  applyproposedValues() {
    for (let i = 0; i < this.populationDataList.length; i++) {
      var totalItemsInPopulation = this.populationDataList[i].itemsInPopulation;
      var populationId = this.populationDataList[i].populationId;
    }

    for (let i = 0; i < this.currentPopulationValueList.length; i++) {
      var warningHighHigherThreshold = this.currentPopulationValueList[i].warningHighHigherThreshold;
    }
  if(this.populationData.fluidTypeId != 4){
    if (this.proposedThresholdForm.value.watchLowLower == '' || this.proposedThresholdForm.value.watchLowLower == undefined || this.proposedThresholdForm.value.watchLowLower == null || this.proposedThresholdForm.value.lowRangeCheckBox == false) {
      var watchLowLowerReqData = "-1";
    } else {
      watchLowLowerReqData = this.proposedThresholdForm.value.watchLowLower;
    }

    if (this.proposedThresholdForm.value.watchLowHigher == '' || this.proposedThresholdForm.value.watchLowHigher == null || this.proposedThresholdForm.value.watchLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBox == false) {
      var watchLowHigherReqData = "-1";
    } else {
      watchLowHigherReqData = this.proposedThresholdForm.value.watchLowHigher;
    }


    if (this.proposedThresholdForm.value.cautionLowLower == '' || this.proposedThresholdForm.value.cautionLowLower == null || this.proposedThresholdForm.value.cautionLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBox == false) {
      var cautionLowLowerReqData = "-1";
    } else {
      cautionLowLowerReqData = this.proposedThresholdForm.value.cautionLowLower;
    }

    if (this.proposedThresholdForm.value.cautionLowHigher == '' || this.proposedThresholdForm.value.cautionLowHigher == null || this.proposedThresholdForm.value.cautionLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBox == false) {
      var cautionLowHigherReqData = "-1";
    } else {
      cautionLowHigherReqData = this.proposedThresholdForm.value.cautionLowHigher;
    }

    if (this.proposedThresholdForm.value.warningLowLower == '' || this.proposedThresholdForm.value.warningLowLower == null || this.proposedThresholdForm.value.warningLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBox == false) {
      var warningLowLowerReqData = "-1";
    } else {
      warningLowLowerReqData = this.proposedThresholdForm.value.warningLowLower;
    }
    if (this.proposedThresholdForm.value.warningLowHigher == '' || this.proposedThresholdForm.value.warningLowHigher == null || this.proposedThresholdForm.value.warningLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBox == false) {
      var warningLowHigherReqData = "-1";
    } else {
      warningLowHigherReqData = this.proposedThresholdForm.value.warningLowHigher;
    }
    if (this.proposedThresholdForm.value.watchHighLower == '' || this.proposedThresholdForm.value.watchHighLower == null || this.proposedThresholdForm.value.watchHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBox == false) {
      var watchHighLowerReqData = "-1";
    } else {
      watchHighLowerReqData = this.proposedThresholdForm.value.watchHighLower;
    }
    if (this.proposedThresholdForm.value.watchHighHigher == '' || this.proposedThresholdForm.value.watchHighHigher == null || this.proposedThresholdForm.value.watchHighHigher == undefined || this.proposedThresholdForm.value.highRangeCheckBox == false) {
      var watchHighHigherReqData = "-1";
    } else {
      watchHighHigherReqData = this.proposedThresholdForm.value.watchHighHigher;
    }

    if (this.proposedThresholdForm.value.cautionHighLower == '' || this.proposedThresholdForm.value.cautionHighLower == null || this.proposedThresholdForm.value.cautionHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBox == false) {
      var cautionHighLowerReqData = "-1";
    } else {
      cautionHighLowerReqData = this.proposedThresholdForm.value.cautionHighLower;
    }

    if (this.proposedThresholdForm.value.warningHighLower == '' || this.proposedThresholdForm.value.warningHighLower == null || this.proposedThresholdForm.value.warningHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBox == false) {
      var cautionHighHigherReqData = "-1";
    } else {
      cautionHighHigherReqData = this.proposedThresholdForm.value.warningHighLower;
    }
    if (this.proposedThresholdForm.value.warningHighLower == '' || this.proposedThresholdForm.value.warningHighLower == null || this.proposedThresholdForm.value.warningHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBox == false) {
      var warningHighLowerReqData = "-1";
    } else {
      warningHighLowerReqData = this.proposedThresholdForm.value.warningHighLower;
    }
  }
  
  else{
    // fuel changes
    if(this.populationData.fluidTypeId = 4){ 
      console.log(this.selectedParamName) 
      if(this.selectedParamId == '44' || this.selectedParamName == 'CLOUD_POINT'){
        if (this.proposedThresholdForm.value.warningLowLower == '' || this.proposedThresholdForm.value.warningLowLower == null || this.proposedThresholdForm.value.warningLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWarning == false) {
          var warningLowLowerReqData = "999.9";
        } else {
          warningLowLowerReqData = this.proposedThresholdForm.value.warningLowLower;
        }
        if (this.proposedThresholdForm.value.warningLowHigher == '' || this.proposedThresholdForm.value.warningLowHigher == null || this.proposedThresholdForm.value.warningLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWarning == false) {
          var warningLowHigherReqData = "999.9";
        } else {
          warningLowHigherReqData = this.proposedThresholdForm.value.warningLowHigher;
        }
  
        if (this.proposedThresholdForm.value.cautionLowLower == '' || this.proposedThresholdForm.value.cautionLowLower == null || this.proposedThresholdForm.value.cautionLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxCaution == false) {
          var cautionLowLowerReqData = "999.9";
        } else {
          cautionLowLowerReqData = this.proposedThresholdForm.value.cautionLowLower;
        }
        if (this.proposedThresholdForm.value.cautionLowHigher == '' || this.proposedThresholdForm.value.cautionLowHigher == null || this.proposedThresholdForm.value.cautionLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxCaution == false) {
          var cautionLowHigherReqData = "999.9";
        } else {
          cautionLowHigherReqData = this.proposedThresholdForm.value.cautionLowHigher;
        }
  
        if (this.proposedThresholdForm.value.watchLowLower == '' || this.proposedThresholdForm.value.watchLowLower == null || this.proposedThresholdForm.value.watchLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWatch == false) {
          var watchLowLowerReqData = "999.9";
        } else {
          watchLowLowerReqData = this.proposedThresholdForm.value.watchLowLower;
        }
        if (this.proposedThresholdForm.value.watchLowHigher == '' || this.proposedThresholdForm.value.watchLowHigher == null || this.proposedThresholdForm.value.watchLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWatch == false) {
          var watchLowHigherReqData = "999.9";
        } else {
          watchLowHigherReqData = this.proposedThresholdForm.value.watchLowHigher;
        }
  
  
        if (this.proposedThresholdForm.value.watchHighLower == '' || this.proposedThresholdForm.value.watchHighLower == null || this.proposedThresholdForm.value.watchHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBoxWatch == false) {
          var watchHighLowerReqData = "999.9";
        } else {
          watchHighLowerReqData = this.proposedThresholdForm.value.watchHighLower;
        }
        if (this.proposedThresholdForm.value.watchHighHigher == '' || this.proposedThresholdForm.value.watchHighHigher == null || this.proposedThresholdForm.value.watchHighHigher == undefined || this.proposedThresholdForm.value.highRangeCheckBoxWatch == false) {
          var watchHighHigherReqData = "999.9";
        } else {
          watchHighHigherReqData = this.proposedThresholdForm.value.watchHighHigher;
        }
  
  
        if (this.proposedThresholdForm.value.cautionHighLower == '' || this.proposedThresholdForm.value.cautionHighLower == null || this.proposedThresholdForm.value.cautionHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBoxCaution == false) {
          var cautionHighLowerReqData = "999.9";
        } else {
          cautionHighLowerReqData = this.proposedThresholdForm.value.cautionHighLower;
        }
        if (this.proposedThresholdForm.value.cautionHighHigher == '' || this.proposedThresholdForm.value.cautionHighHigher == null || this.proposedThresholdForm.value.cautionHighHigher == undefined || this.proposedThresholdForm.value.highRangeCheckBoxCaution == false) {
          var cautionHighHigherReqData = "999.9";
        } else {
          cautionHighHigherReqData = this.proposedThresholdForm.value.cautionHighHigher;
        }
        
        if (this.proposedThresholdForm.value.warningHighLower == '' || this.proposedThresholdForm.value.warningHighLower == null || this.proposedThresholdForm.value.warningHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBoxWarning == false) {
          var warningHighLowerReqData = "999.9";
        } else {
          warningHighLowerReqData = this.proposedThresholdForm.value.warningHighLower;
        }
      }else{
        if (this.proposedThresholdForm.value.warningLowLower == '' || this.proposedThresholdForm.value.warningLowLower == null || this.proposedThresholdForm.value.warningLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWarning == false) {
          var warningLowLowerReqData = "-1";
        } else {
          warningLowLowerReqData = this.proposedThresholdForm.value.warningLowLower;
        }
        if (this.proposedThresholdForm.value.warningLowHigher == '' || this.proposedThresholdForm.value.warningLowHigher == null || this.proposedThresholdForm.value.warningLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWarning == false) {
          var warningLowHigherReqData = "-1";
        } else {
          warningLowHigherReqData = this.proposedThresholdForm.value.warningLowHigher;
        }
  
        if (this.proposedThresholdForm.value.cautionLowLower == '' || this.proposedThresholdForm.value.cautionLowLower == null || this.proposedThresholdForm.value.cautionLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxCaution == false) {
          var cautionLowLowerReqData = "-1";
        } else {
          cautionLowLowerReqData = this.proposedThresholdForm.value.cautionLowLower;
        }
        if (this.proposedThresholdForm.value.cautionLowHigher == '' || this.proposedThresholdForm.value.cautionLowHigher == null || this.proposedThresholdForm.value.cautionLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxCaution == false) {
          var cautionLowHigherReqData = "-1";
        } else {
          cautionLowHigherReqData = this.proposedThresholdForm.value.cautionLowHigher;
        }
  
        if (this.proposedThresholdForm.value.watchLowLower == '' || this.proposedThresholdForm.value.watchLowLower == null || this.proposedThresholdForm.value.watchLowLower == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWatch == false) {
          var watchLowLowerReqData = "-1";
        } else {
          watchLowLowerReqData = this.proposedThresholdForm.value.watchLowLower;
        }
        if (this.proposedThresholdForm.value.watchLowHigher == '' || this.proposedThresholdForm.value.watchLowHigher == null || this.proposedThresholdForm.value.watchLowHigher == undefined || this.proposedThresholdForm.value.lowRangeCheckBoxWatch == false) {
          var watchLowHigherReqData = "-1";
        } else {
          watchLowHigherReqData = this.proposedThresholdForm.value.watchLowHigher;
        }
  
  
        if (this.proposedThresholdForm.value.watchHighLower == '' || this.proposedThresholdForm.value.watchHighLower == null || this.proposedThresholdForm.value.watchHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBoxWatch == false) {
          var watchHighLowerReqData = "-1";
        } else {
          watchHighLowerReqData = this.proposedThresholdForm.value.watchHighLower;
        }
        if (this.proposedThresholdForm.value.watchHighHigher == '' || this.proposedThresholdForm.value.watchHighHigher == null || this.proposedThresholdForm.value.watchHighHigher == undefined || this.proposedThresholdForm.value.highRangeCheckBoxWatch == false) {
          var watchHighHigherReqData = "-1";
        } else {
          watchHighHigherReqData = this.proposedThresholdForm.value.watchHighHigher;
        }
  
  
        if (this.proposedThresholdForm.value.cautionHighLower == '' || this.proposedThresholdForm.value.cautionHighLower == null || this.proposedThresholdForm.value.cautionHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBoxCaution == false) {
          var cautionHighLowerReqData = "-1";
        } else {
          cautionHighLowerReqData = this.proposedThresholdForm.value.cautionHighLower;
        }
        if (this.proposedThresholdForm.value.cautionHighHigher == '' || this.proposedThresholdForm.value.cautionHighHigher == null || this.proposedThresholdForm.value.cautionHighHigher == undefined || this.proposedThresholdForm.value.highRangeCheckBoxCaution == false) {
          var cautionHighHigherReqData = "-1";
        } else {
          cautionHighHigherReqData = this.proposedThresholdForm.value.cautionHighHigher;
        }
        
        if (this.proposedThresholdForm.value.warningHighLower == '' || this.proposedThresholdForm.value.warningHighLower == null || this.proposedThresholdForm.value.warningHighLower == undefined || this.proposedThresholdForm.value.highRangeCheckBoxWarning == false) {
          var warningHighLowerReqData = "-1";
        } else {
          warningHighLowerReqData = this.proposedThresholdForm.value.warningHighLower;
        }
      }

    }
  }
  // end fuel changes
  //console.log(this.populationData.fluidTypeId != 4, 'hhh')
    let reqParamWithKalRatio = {
      userId: this.sharedService.getUserId(),
      populationId: populationId,
      parameter: this.selectedParamId,
      includeRatio: this.proposedThresholdForm.value.kAlRatioCheck,
      kAlRatio: this.proposedThresholdForm.value.kAlRatioInput,
      watchLowLowerThreshold: watchLowLowerReqData,
      watchLowHigherThreshold: watchLowHigherReqData,
      cautionLowLowerThreshold: cautionLowLowerReqData,
      cautionLowHigherThreshold: cautionLowHigherReqData,
      warningLowLowerThreshold: warningLowLowerReqData,
      warningLowHigherThreshold: warningLowHigherReqData,
      normalLowThreshold: this.proposedThresholdForm.value.normalLower,
      normalHighThreshold: this.proposedThresholdForm.value.normalHigher,
      watchHighLowerThreshold: watchHighLowerReqData,
      watchHighHigherThreshold: watchHighHigherReqData,
      cautionHighLowerThreshold: cautionHighLowerReqData,
      cautionHighHigherThreshold: cautionHighHigherReqData,
      warningHighLowerThreshold: warningHighLowerReqData,
      warningHighHigherThreshold: warningHighHigherThreshold,

    }
    if(this.parameterFTIR == true && this.proposedFTIRThresholdForm.value.normalFTIR !="" &&  this.proposedFTIRThresholdForm.value.watchFTIR !="" &&  this.proposedFTIRThresholdForm.value.warningFTIR !=""  && this.proposedFTIRThresholdForm.value.warningFTIR !=""){
      this.normalLower = "-1";
      this.normalHigh= "-1";
    }else{
      this.normalLower = this.proposedThresholdForm.value.normalLower;
      this.normalHigh= this.proposedThresholdForm.value.normalHigher;
    }
    let reqParam = {
      userId: this.sharedService.getUserId(),
      populationId: populationId,
      parameter: this.selectedParamId,
      includeRatio: false,
      watchLowLowerThreshold: watchLowLowerReqData,
      watchLowHigherThreshold: watchLowHigherReqData,
      cautionLowLowerThreshold: cautionLowLowerReqData,
      cautionLowHigherThreshold: cautionLowHigherReqData,
      warningLowLowerThreshold: warningLowLowerReqData,
      warningLowHigherThreshold: warningLowHigherReqData,
      normalLowThreshold: this.normalLower,
      normalHighThreshold: this.normalHigh,
      watchHighLowerThreshold: watchHighLowerReqData,
      watchHighHigherThreshold: watchHighHigherReqData,
      cautionHighLowerThreshold: cautionHighLowerReqData,
      cautionHighHigherThreshold: cautionHighHigherReqData,
      warningHighLowerThreshold: warningHighLowerReqData,
      warningHighHigherThreshold: warningHighHigherThreshold,
      normalFTIRThreshold: this.proposedFTIRThresholdForm.value.normalFTIR,
      watchFTIRThreshold: this.proposedFTIRThresholdForm.value.watchFTIR,
      warningFTIRThreshold: this.proposedFTIRThresholdForm.value.warningFTIR,
      cautionFTIRThreshold: this.proposedFTIRThresholdForm.value.cautionFTIR,
    }
    this.applyThresholdSuccessModal = false;
    if ((this.selectedParamName == 'K' || this.selectedParamName == 'Al') && this.proposedThresholdForm.value.kAlRatioCheck == true) {
      //console.log("apply propose req param with k al ratio----", reqParamWithKalRatio);
      this.modifyThresholdServ.applyProposedThreshold(reqParamWithKalRatio).subscribe(response => {
        //console.log("apply proposed response", response);
        if (response.statusCode == 1) {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
          this.scrollToTop = true;
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    } else {
      //console.log("apply propose req param", reqParam);

      this.modifyThresholdServ.applyProposedThreshold(reqParam).subscribe(response => {
        //console.log("apply proposed response", response);
        if (response.statusCode == 1) {
          this.statusMessage = response.statusMessage;
          this.successModal = true;

        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    }
  }

  closePopup() {
    this.successModal = false;
    this.applyThresholdSuccessModal = false;
  }
  //================================================checkbox validation---start=========================================
  setLowRange(e) {
    //alert(this.proposedThresholdForm.value.lowRangeCheckBox);
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.warningLowLower.enable();
      this.proposedThresholdForm.controls.warningLowHigher.enable();
      this.proposedThresholdForm.controls.cautionLowLower.enable();
      this.proposedThresholdForm.controls.cautionLowHigher.enable();
      this.proposedThresholdForm.controls.watchLowLower.enable();
      this.proposedThresholdForm.controls.watchLowHigher.enable();
    }
    else {
      this.proposedThresholdForm.controls.warningLowLower.disable();
      this.proposedThresholdForm.controls.warningLowHigher.disable();
      this.proposedThresholdForm.controls.cautionLowLower.disable();
      this.proposedThresholdForm.controls.cautionLowHigher.disable();
      this.proposedThresholdForm.controls.watchLowLower.disable();
      this.proposedThresholdForm.controls.watchLowHigher.disable();
      this.proposedThresholdForm.controls['watchLowLower'].reset();
      this.proposedThresholdForm.controls['cautionLowLower'].reset();
      this.proposedThresholdForm.controls['warningLowLower'].reset();
      this.proposedCalcList = [];
    }
    this.validateInput();
  }
  
  setHighRange(e) {
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.warningHighLower.enable();
      //this.proposedThresholdForm.controls.warningHighHigher.enable();
      this.proposedThresholdForm.controls.cautionHighLower.enable();
      this.proposedThresholdForm.controls.cautionHighHigher.enable();
      this.proposedThresholdForm.controls.watchHighLower.enable();
      this.proposedThresholdForm.controls.watchHighHigher.enable();

    }
    else {
      this.proposedThresholdForm.controls.warningHighLower.disable();
      //this.proposedThresholdForm.controls.warningHighHigher.disable();
      this.proposedThresholdForm.controls.cautionHighLower.disable();
      this.proposedThresholdForm.controls.cautionHighHigher.disable();
      this.proposedThresholdForm.controls.watchHighLower.disable();
      this.proposedThresholdForm.controls.watchHighHigher.disable();

      this.proposedThresholdForm.controls['watchHighHigher'].reset();
      this.proposedThresholdForm.controls['cautionHighHigher'].reset();

      this.proposedCalcList = [];
    }
    this.validateInput();
  }

  setLowRangeWarning(e){
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.warningLowLower.enable();
      this.proposedThresholdForm.controls.warningLowHigher.enable();
    }else{
      this.proposedThresholdForm.controls.warningLowLower.disable();
      this.proposedThresholdForm.controls.warningLowHigher.disable();
      this.proposedThresholdForm.controls['warningLowHigher'].reset();
      this.proposedCalcList = [];

    }
    this.validateFuelInput();
  }

  setLowRangeCaution(e){
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.cautionLowLower.enable();
      this.proposedThresholdForm.controls.cautionLowHigher.enable();
    }else{
      this.proposedThresholdForm.controls.cautionLowLower.disable();
      this.proposedThresholdForm.controls.cautionLowHigher.disable();
      this.proposedThresholdForm.controls['cautionLowHigher'].reset();
      this.proposedCalcList = [];

    }
    this.validateFuelInput();
  }

  setLowRangeWatch(e){
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.watchLowLower.enable();
      this.proposedThresholdForm.controls.watchLowHigher.enable();
    }else{
      this.proposedThresholdForm.controls.watchLowLower.disable();
      this.proposedThresholdForm.controls.watchLowHigher.disable();
      this.proposedThresholdForm.controls['watchLowHigher'].reset();
      this.proposedCalcList = [];
}
   this.validateFuelInput();
  }

  setHighRangeWatch(e){
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.watchHighLower.enable();
      this.proposedThresholdForm.controls.watchHighHigher.enable();
    }else{
      this.proposedThresholdForm.controls.watchHighLower.disable();
      this.proposedThresholdForm.controls.watchHighHigher.disable();
      this.proposedThresholdForm.controls['watchHighHigher'].reset();
      this.proposedCalcList = [];
    }
    this.validateFuelInput();
  }  

  setHighRangeCaution(e){
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.cautionHighLower.enable();
      this.proposedThresholdForm.controls.cautionHighHigher.enable();
    }else{
      this.proposedThresholdForm.controls.cautionHighLower.disable();
      this.proposedThresholdForm.controls.cautionHighHigher.disable();
      this.proposedThresholdForm.controls['cautionHighHigher'].reset();
      this.proposedCalcList = [];
    }
    this.validateFuelInput();
  } 

  setHighRangeWarning(e){
    if (e.target.checked == true) {
      this.proposedThresholdForm.controls.warningHighLower.enable();
    }else{
      this.proposedThresholdForm.controls.warningHighLower.disable();
      this.proposedThresholdForm.controls['warningHighLower'].reset();
      this.proposedCalcList = [];

    }
    this.validateFuelInput();
    
  } 
  numbercharOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  //================================================checkbox validation--end=========================================

  resetThreshold() {
    this.alertService.clear();
    this.proposedCalcList = [];
    this.proposedThresholdForm.reset();
    this.proposedFTIRThresholdForm.reset();
    this.proposedThresholdForm.controls.warningHighLower.disable();
    this.proposedThresholdForm.controls.cautionHighLower.disable();
    this.proposedThresholdForm.controls.cautionHighHigher.disable();
    this.proposedThresholdForm.controls.watchHighLower.disable();
    this.proposedThresholdForm.controls.watchHighHigher.disable();
    this.proposedThresholdForm.controls.warningLowLower.disable();
    this.proposedThresholdForm.controls.warningLowHigher.disable();
    this.proposedThresholdForm.controls.cautionLowLower.disable();
    this.proposedThresholdForm.controls.cautionLowHigher.disable();
    this.proposedThresholdForm.controls.watchLowLower.disable();
    this.proposedThresholdForm.controls.watchLowHigher.disable(); 
    this.enablecalculatebutton =false;
    this.enableapplybutton =false;
    //this.disableSaveBtnOnReset = true;
    //this.proposedThresholdForm.controls['watchHighLower'].setValue('');
    // this.proposedThresholdForm.patchValue({
    //   warningHighLower: '',
    //   cautionHighLower: '',
    //   cautionHighHigher: '',
    //   watchHighLower: '',
    //   watchHighHigher: ''
    // });
  }

  proposedValuesConfirmation() {
    this.applyThresholdSuccessModal = true;
  }

  validateFuelInput(){
    if(this.populationData.fluidTypeId = 4){ 
    if(Number(this.proposedThresholdForm.controls.normalLower.value) >= Number(this.proposedThresholdForm.controls.normalHigher.value)){
        this.proposedThresholdForm.controls.normalHigher.setErrors({'invalidNumber': true});       
      }else{
        this.proposedThresholdForm.controls.normalLower.setErrors(null); 
      }

      if (this.proposedThresholdForm.value.lowRangeCheckBoxWarning == true) { 
        if(Number(this.proposedThresholdForm.controls.warningLowLower.value) >= Number(this.proposedThresholdForm.controls.warningLowHigher.value)){
          this.proposedThresholdForm.controls.warningLowHigher.setErrors({'invalidNumber': true});       
        }else{
          this.proposedThresholdForm.controls.warningLowLower.setErrors(null); 
        } 
      }else{
          this.proposedThresholdForm.controls.warningLowLower.setErrors(null); 
       }
      if (this.proposedThresholdForm.value.lowRangeCheckBoxCaution == true) { 
      if((Number(this.proposedThresholdForm.controls.cautionLowLower.value) >= Number(this.proposedThresholdForm.controls.cautionLowHigher.value))){
        this.proposedThresholdForm.controls.cautionLowHigher.setErrors({'invalidNumber': true});       
      }else{
        this.proposedThresholdForm.controls.cautionLowLower.setErrors(null); 
      }
    }else{
      this.proposedThresholdForm.controls.cautionLowLower.setErrors(null); 
   }
      if (this.proposedThresholdForm.value.lowRangeCheckBoxWatch== true) { 
        if((Number(this.proposedThresholdForm.controls.watchLowLower.value) >= Number(this.proposedThresholdForm.controls.watchLowHigher.value))){
          this.proposedThresholdForm.controls.watchLowHigher.setErrors({'invalidNumber': true});       
        }else{
          this.proposedThresholdForm.controls.watchLowLower.setErrors(null); 
        }
      }else{
      this.proposedThresholdForm.controls.watchLowLower.setErrors(null); 
   }
    if (this.proposedThresholdForm.value.highRangeCheckBoxWatch== true) { 
      if((Number(this.proposedThresholdForm.controls.watchHighLower.value) >= Number(this.proposedThresholdForm.controls.watchHighHigher.value))){
        this.proposedThresholdForm.controls.watchHighHigher.setErrors({'invalidNumber': true});       
      }else{
        this.proposedThresholdForm.controls.watchHighLower.setErrors(null); 
      }
    }else{
      this.proposedThresholdForm.controls.watchHighLower.setErrors(null); 
    }

  if (this.proposedThresholdForm.value.highRangeCheckBoxCaution== true) { 
    if((Number(this.proposedThresholdForm.controls.cautionHighLower.value) >= Number(this.proposedThresholdForm.controls.cautionHighHigher.value))){
      this.proposedThresholdForm.controls.cautionHighHigher.setErrors({'invalidNumber': true});       
    }else{
      this.proposedThresholdForm.controls.cautionHighLower.setErrors(null); 
    }
    }else{
      this.proposedThresholdForm.controls.cautionHighLower.setErrors(null); 
    }
    if(!this.proposedThresholdForm.valid){
      this.alertService.error('Kindly enter values greater than the previous one');
      this.scrollToTop = true;
    }else{
      this.alertService.clear();
    }
  }
}

  validateInput(){   
  //  if(this.populationData.fluidTypeId != 4){  
  //   if (this.proposedThresholdForm.value.lowRangeCheckBox == true) { 
  //     if(Number(this.proposedThresholdForm.controls.warningLowLower.value) >= Number(this.proposedThresholdForm.controls.warningLowHigher.value)){
  //       this.proposedThresholdForm.controls.warningLowLower.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.warningLowLower.setErrors(null); 
  //     }    

  //     if((Number(this.proposedThresholdForm.controls.warningLowHigher.value) >= Number(this.proposedThresholdForm.controls.cautionLowLower.value)) ||
  //     (Number(this.proposedThresholdForm.controls.cautionLowLower.value) >= Number(this.proposedThresholdForm.controls.cautionLowHigher.value))){
  //       this.proposedThresholdForm.controls.cautionLowLower.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.cautionLowLower.setErrors(null); 
  //     }

  //     if((Number(this.proposedThresholdForm.controls.cautionLowHigher.value) >= Number(this.proposedThresholdForm.controls.watchLowLower.value)) ||
  //     (Number(this.proposedThresholdForm.controls.watchLowLower.value) >= Number(this.proposedThresholdForm.controls.watchLowHigher.value))){
  //       this.proposedThresholdForm.controls.watchLowLower.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.watchLowLower.setErrors(null); 
  //     }

  //     if(Number(this.proposedThresholdForm.controls.watchLowHigher.value) >= Number(this.proposedThresholdForm.controls.normalLower.value)){
  //       this.proposedThresholdForm.controls.normalLower.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.normalLower.setErrors(null); 
  //     }
  //   }else{
  //     this.proposedThresholdForm.controls.warningLowLower.setErrors(null); 
  //     this.proposedThresholdForm.controls.cautionLowLower.setErrors(null); 
  //     this.proposedThresholdForm.controls.watchLowLower.setErrors(null);
  //     this.proposedThresholdForm.controls.normalLower.setErrors(null);

  //   }

  //   if(Number(this.proposedThresholdForm.controls.normalLower.value) >= Number(this.proposedThresholdForm.controls.normalHigher.value)){
  //     this.proposedThresholdForm.controls.normalHigher.setErrors({'invalidNumber': true});       
  //   }else{
  //     this.proposedThresholdForm.controls.normalHigher.setErrors(null); 
  //   }


  //   if (this.proposedThresholdForm.value.highRangeCheckBox == true) {
  //     if(Number(this.proposedThresholdForm.controls.normalHigher.value) >= Number(this.proposedThresholdForm.controls.watchHighLower.value)){
  //       this.proposedThresholdForm.controls.watchHighLower.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.watchHighLower.setErrors(null); 
  //     }

  //     if((Number(this.proposedThresholdForm.controls.watchHighLower.value) >= Number(this.proposedThresholdForm.controls.watchHighHigher.value)) ||
  //     (Number(this.proposedThresholdForm.controls.watchHighHigher.value) >= Number(this.proposedThresholdForm.controls.cautionHighLower.value))){
  //       this.proposedThresholdForm.controls.watchHighHigher.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.watchHighHigher.setErrors(null); 
  //     }

  //     if(Number(this.proposedThresholdForm.controls.cautionHighLower.value) >= Number(this.proposedThresholdForm.controls.cautionHighHigher.value)){
  //       this.proposedThresholdForm.controls.cautionHighHigher.setErrors({'invalidNumber': true});       
  //     }else{
  //       this.proposedThresholdForm.controls.cautionHighHigher.setErrors(null); 
  //     }
  //   }else{
  //     this.proposedThresholdForm.controls.watchHighLower.setErrors(null); 
  //     this.proposedThresholdForm.controls.watchHighHigher.setErrors(null); 
  //     this.proposedThresholdForm.controls.cautionHighHigher.setErrors(null); 
  //     this.proposedThresholdForm.controls.warningHighLower.setErrors(null); 
  //   }
  //   //FTIR 
  //   if(Number(this.proposedThresholdForm.controls.normalFTIR.value) >= Number(this.proposedThresholdForm.controls.normalHigher.value)){
  //     this.proposedThresholdForm.controls.watchFTIR.setErrors({'invalidNumber': true});       
  //   }else{
  //     this.proposedThresholdForm.controls.watchFTIR.setErrors(null); 
  //   }
  //   if(Number(this.proposedThresholdForm.controls.normalFTIR.value) >= Number(this.proposedThresholdForm.controls.normalHigher.value)){
  //     this.proposedThresholdForm.controls.cautionFTIR.setErrors({'invalidNumber': true});       
  //   }else{
  //     this.proposedThresholdForm.controls.cautionFTIR.setErrors(null); 
  //   }
  //   if(Number(this.proposedThresholdForm.controls.normalFTIR.value) >= Number(this.proposedThresholdForm.controls.normalHigher.value)){
  //     this.proposedThresholdForm.controls.warningFTIR.setErrors({'invalidNumber': true});       
  //   }else{
  //     this.proposedThresholdForm.controls.warningFTIR.setErrors(null); 
  //   }

    // this.proposedThresholdForm.controls.normalFTIR.setErrors(null); 
    // this.proposedThresholdForm.controls.watchFTIR.setErrors(null); 
    // this.proposedThresholdForm.controls.cautionFTIR.setErrors(null); 
    // this.proposedThresholdForm.controls.warningFTIR.setErrors(null); 

  
//     if(!this.proposedThresholdForm.valid){
//       this.alertService.error('Kindly enter values greater than the previous one');
//       this.scrollToTop = true;
//     }else{
//       console.log("oooooo")

//       this.alertService.clear();
//     }
//  }
 /* else{
    if(this.populationData.fluidTypeId = 4){  
      if(Number(this.proposedThresholdForm.controls.normalLower.value) >= Number(this.proposedThresholdForm.controls.normalHigher.value)){
        this.proposedThresholdForm.controls.normalHigher.setErrors({'invalidNumber': true});       
      }else{
        this.proposedThresholdForm.controls.normalHigher.setErrors(null); 
      }

      if (this.proposedThresholdForm.value.lowRangeCheckBoxWarning == true) { 
        if(Number(this.proposedThresholdForm.controls.warningLowLower.value) >= Number(this.proposedThresholdForm.controls.warningLowHigher.value)){
          this.proposedThresholdForm.controls.warningLowHigher.setErrors({'invalidNumber': true});       
        }else{
          this.proposedThresholdForm.controls.warningLowHigher.setErrors(null); 
        } 
      }
      if (this.proposedThresholdForm.value.lowRangeCheckBoxCaution == true) { 
      if((Number(this.proposedThresholdForm.controls.cautionLowLower.value) >= Number(this.proposedThresholdForm.controls.cautionLowHigher.value))){
        this.proposedThresholdForm.controls.cautionLowHigher.setErrors({'invalidNumber': true});       
      }else{
        this.proposedThresholdForm.controls.cautionLowHigher.setErrors(null); 
      }
    }
    if (this.proposedThresholdForm.value.lowRangeCheckBoxWatch== true) { 
      if((Number(this.proposedThresholdForm.controls.watchLowLower.value) >= Number(this.proposedThresholdForm.controls.watchLowHigher.value))){
        this.proposedThresholdForm.controls.watchLowHigher.setErrors({'invalidNumber': true});       
      }else{
        this.proposedThresholdForm.controls.watchLowHigher.setErrors(null); 
      }
    }

      if(!this.proposedThresholdForm.valid){
        this.alertService.error('Kindly enter values greater than the previous one');
        this.scrollToTop = true;
      }else{
        this.alertService.clear();
      }
  }*/

}
  // if(this.proposedThresholdForm.value.normalLower == '' ||  this.proposedThresholdForm.value.normalLower == null || this.proposedThresholdForm.value.normalLower == undefined){
  //   this.proposedThresholdForm.controls.normalLower.setErrors({'invalidNumber': true});       
  // }else{
  //   this.proposedThresholdForm.controls.normalLower.setErrors(null); 
  // }
}

