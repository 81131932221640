import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { AccountGroupManagementService } from './account-group-management.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-account-group-management',
  templateUrl: './account-group-management.component.html',
  styleUrls: ['./account-group-management.component.css']
})
export class AccountGroupManagementComponent implements OnInit {
  readonly USERTYPE = USERTYPE;
  userRole: string = '';
  searchObj: any;
  infoDataforSearch: any;
  searchObjUserRole: string = '';
  status: string = '';
  scanResult: any = '';
  constraints: any = { width: { min: 640, ideal: 1920, max: 1920 }, height: { min: 400, ideal: 1080 }, aspectRatio: 1.777777778, frameRate: { max: 60 } };
  confirmModal: boolean = false;
  dataArray = new Array();
  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = '10';
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  sortingOrder: string;
  scrollToTop = false;
  currentItem: any = [];
  columnName: string = '';
  detachCompanyId: any = '';
  detachCompanyIdIndx: number;
  detachCompanyName: any = '';
  detachGroupName: any = '';
  accountgroupFilterObject = {
    companyName: [],
    companyId: [],
    group: [],
  }
  accountGroupDisplayedColumns = [
    { code: "companyName", name: "Company Name" },
    { code: "companyId", name: "Company ID" },
    { code: "group", name: "Group Name" },
    { code: 'action', name: 'Actions' }
  ];
  accountGroupSearchForm = new UntypedFormGroup({
    companyId: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    group: new UntypedFormControl(''),
  });
  openModal: boolean;

  accountGroupListData: any = [];
  pagevalue: any;
  @ViewChildren(SortComponent) sortComponents;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  constructor(
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private accountGroupManagementService: AccountGroupManagementService,
    private router: Router
  ) {
    this.pagevalue = JSON.parse(localStorage.getItem('accountGroupManagementObject'));
    if (this.pagevalue != null) {
      this.pageSize = JSON.parse(localStorage.getItem('accountGroupManagementObject'));
    } else {
      this.pageSize = '10'
    }
  }

  ngOnInit(): void {
    let sortdata = sessionStorage.getItem('accountGroupManagementSortData')
    if (sortdata == 'undefined') {
      this.currentItem = undefined;
    } else {
      this.currentItem = JSON.parse(sortdata)
    }

    if (this.currentItem != "" && this.currentItem != null && this.currentItem != undefined) {
      this.columnName = this.currentItem.column;
      this.sortingOrder = this.currentItem.sort;
    }
    else {
      this.sortingOrder = 'asc';
      this.columnName = 'companyName';
    }
    this.getAccountGroupGridData();
  }

  ngOnDestroy() {
    localStorage.setItem('accountGroupManagementObject', JSON.stringify(this.pageSize));
  }

  getAccountGroupGridData() {
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      functionName: "DASHBOARD"
    }

    this.accountGroupManagementService.getCompanyDetailList(this.searchObj).subscribe(response => {

      if (response.statusCode == "1") {
        this.accountGroupListData = response.companyList;
        this.totalItems = response.companyList[0].maxRow;
        this.calculateOffset();
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  detachPopup(item: any, indx: number) {
    this.detachCompanyName = item.companyName;
    this.detachGroupName = item.group;
    this.detachCompanyId = item.companyId;
    this.detachCompanyIdIndx = indx;
    this.openModal = true;
  }

  closeModal() {
    this.openModal = false;
  }

  detach() {
    this.openModal = false;
    let _data = {
      functionName: "DETACH",
      companyId: this.detachCompanyId
    };

    this.accountGroupManagementService.getCompanyDetailList(_data).subscribe(response => {
      this.scrollToTop = true;
      if (response.statusCode) {
        this.alertService.success(this.detachCompanyName + (this.detachCompanyName ? ' - ' : '') + this.detachCompanyId + " has been detached from " + this.detachGroupName + " group successfully!");
        this.accountGroupListData[this.detachCompanyIdIndx].group = '';
      } else {
        this.alertService.error("There is some issue to detac the company. Please try again!")
      }
      this.detachCompanyId = '';
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  groupCompany() {
    this.router.navigate(['/addEditAccountGroup']);
    let groupNameObj: any = { groupName: '' };
    localStorage.setItem('createOrUpdateGroupName', JSON.stringify(groupNameObj));
  }

  reGroupCompany(group: any) {
    this.router.navigate(['/addEditAccountGroup']);
    let groupNameObj: any = { groupName: group };
    localStorage.setItem('createOrUpdateGroupName', JSON.stringify(groupNameObj));
  }

  getColumnCode(str) {
    if (str === 'status') {
      return 'statusName';
    } else if (str === 'analysisType') {
      return 'analysisTypeName';
    } else if (str === 'testLevel') {
      return 'testLevelName';
    } else {
      return str;
    }
  }

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.accountGroupListData = [];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('accountGroupManagementObject', JSON.stringify(this.pageSize));
    this.loadPage(1);
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    let val = this.searchLength(this.accountgroupFilterObject)

    if (val === true) {
      this.searchDashboard();
    }
    else {
      this.getAccountGroupGridData();
    }
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  searchLength(filterObject: any) {
    if (filterObject.companyName.length > 0 || filterObject.companyId.length > 0 || filterObject.group.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  sortData(columnName) {
    let existingsortdata = sessionStorage.getItem('accountGroupManagementSortData');
    if (existingsortdata == 'undefined' || existingsortdata == null || existingsortdata == "") {
      this.currentItem = undefined;
    } else {
      this.currentItem = JSON.parse(existingsortdata)
    }
    if (this.currentItem != "" && this.currentItem != null && this.currentItem != undefined) {
      if (this.currentItem.sort == 'asc') {
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      } else {
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);

      }
    }
    let sortcolumn = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    let reviewcolumnsort = { column: columnName, sort: sortcolumn, direction: sortcolumn, active: columnName };
    sessionStorage.setItem('accountGroupManagementSortData', JSON.stringify(reviewcolumnsort));
    if (this.columnName !== columnName) {
      this.sortingOrder = '';
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder !== 'asc' ? 'asc' : 'desc';
    this.getAccountGroupGridData();
  }

  resetFilters() {
    this.sortComponents._results.forEach(row => {
      row.activeColumn = false
      row.direction = true;
    });
    this.currentItem = undefined
    this.alertService.clear();
    this.sortingOrder = 'asc';
    this.columnName = 'companyName';
    this.pageSize = '10';
    window.sessionStorage.removeItem('accountGroupManagementSortData');
    window.sessionStorage.removeItem('accountGroupManagementObject');
    this.accountGroupSearchForm.patchValue({ companyId: '' });
    this.accountGroupSearchForm.patchValue({ companyName: '' });
    this.accountGroupSearchForm.patchValue({ group: '' });
    this.searchDashboard();
    this.loadPage(1);
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  /* -------------------------filter value dropdown------------------------- */
  searchFilter(str) {
    let search = (this.accountGroupSearchForm.value[str]).toLowerCase();
    if (search == '') {
      this.accountgroupFilterObject[str] = [];
      this.searchDashboardDropDownSelect();
    }
    sessionStorage.setItem('accountGroupManagementObject', JSON.stringify(this.accountGroupSearchForm.value));
    let _data = {
      searchColumn: str,
      searchValue: this.accountGroupSearchForm.value[str],
      functionName: "DASHBOARD",
      companyId: this.accountGroupSearchForm.value['companyId'],
      companyName: this.accountGroupSearchForm.value['companyName'],
      group: this.accountGroupSearchForm.value['group']
    }
    if (this.accountGroupSearchForm.value[str].length >= 2) {
      this.accountGroupManagementService.getCompanyDetailList(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.accountgroupFilterObject[str] = response.distictValuesList;
          } else {
            this.alertService.error(response.statusMessage);
            this.accountgroupFilterObject[str] = [];
            this.alertService.clear();
            window.sessionStorage.removeItem('accountGroupManagementObject');
          }
        }, err => {
          this.alertService.error(err);
        }
      );
    }
    else if (this.accountGroupSearchForm.value[str].length == 0) {
      this.clearSearch(str)
      this.accountgroupFilterObject[str] == [];
      this.accountgroupFilterObject[str].length = 0;
    }
  }
  /* -------------------------search button for filter value selected------------------------- */
  searchDashboard() {
    this.searchObj = {
      functionName: "DASHBOARD",
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      companyId: this.accountGroupSearchForm.value['companyId'],
      companyName: this.accountGroupSearchForm.value['companyName'],
      group: this.accountGroupSearchForm.value['group']
    }

    this.accountGroupManagementService.getCompanyDetailList(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1" && response.companyList.length > 0) {
          this.accountGroupListData = response.companyList;
          this.totalItems = response.companyList[0].maxRow;
          // this.totalItems = response.maxRow;
          this.calculateOffset();
        } else {
          this.alertService.error(response.statusMessage);
          this.accountGroupListData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  searchDashboardDropDownSelect() {
    this.autocomplete.showPanel;
    sessionStorage.setItem('accountGroupManagementObject', JSON.stringify(this.accountGroupSearchForm.value));
    this.searchObj = {
      functionName: "DASHBOARD",
      pageSize: this.pageSize.toString(),
      currentPage: "1",
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      companyId: this.accountGroupSearchForm.value['companyId'],
      companyName: this.accountGroupSearchForm.value['companyName'],
      group: this.accountGroupSearchForm.value['group']
    }

    this.accountGroupManagementService.getCompanyDetailList(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1" && response.companyList.length > 0) {
          this.accountGroupListData = response.companyList;
          this.totalItems = response.companyList[0].maxRow;
          this.calculateOffset();
          return false;
        } else {
          this.alertService.error(response.statusMessage);
          this.accountGroupListData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* -------------------- Clear search------------- */
  clearSearch(str) {
    if (this.accountGroupSearchForm.controls[str].value != '') {
      this.accountgroupFilterObject[str] = [];
      this.accountGroupSearchForm.patchValue({ [str]: '' });
      this.searchDashboard();
    }
  }
}
