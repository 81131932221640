import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class  OpsKeyissueReportService {

  constructor(private api: ApiHandlerService) { }

  getOpsKeyIssueReportData(data: any): Observable<any> { 
    return this.api.post(environment.apiUrl + '/opsKeyIssueReport', data);
  }
}
