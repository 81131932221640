import { parallel } from 'async';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RegisterSampleService } from '../register-sample.service';
import { Subject } from 'rxjs';
import { RegisterSampleModel } from '../../model/registerSampleModel';
import { SharedService } from '../shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AddRegisterSampleResolverService implements Resolve<any> {
  companyIdListArr: any[] = [];
  constructor(private router: Router, private registerSampleService: RegisterSampleService,
    private sharedService : SharedService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const obj: RegisterSampleModel = JSON.parse(localStorage.getItem('registerSampleObject'));
    obj.userRole = this.sharedService.getUserRole();
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    if (obj.userRole === USERTYPE.EXTERNAL) {    
      obj.companyId = this.companyIdListArr;
    } 
    const observer = new Subject<any>();
    //const companyId: string = this.sharedService.getCompanyId();
    parallel(
      {

        unitList: (ul) => {
          this.registerSampleService.getUnitList(obj.companyId).subscribe(res => {
            ul(null, res.equipmentList);
          }, (error) => {
            ul(error, null);
          });
        },
        dropDownData: (dd) => {
          this.registerSampleService.getDropDownData(obj.analysisType, obj.fluidType).subscribe(res => {
            dd(null, res);
          }, (error) => {
            dd(error, null);
          })
        }
      },
      (err: string, results: any) => {
        if (err && err.indexOf('401 OK') !== -1) {
          this.router.navigate(['pages/login']);
          observer.next(results);
          observer.complete();
        }
        observer.next(results);
        observer.complete();
      });
    return observer.asObservable();
  }

}
