import { Injectable } from '@angular/core';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PopulationGridService {

  selectedPopulation:any;

  setSelectedPopulation(population){
    this.selectedPopulation = population;
  }

  getSelectedPopulation(){
    return this.selectedPopulation;
  }

  constructor(private api: ApiHandlerService) { }

  getPopulationDashboardData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getPopulation', data);
  }

  deletePopulation(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/deletePopulation', data);
  }

  downloadPopulation(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/downloadPopulation', data);
  }

}
