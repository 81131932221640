import { Component, OnInit, ViewChildren } from '@angular/core';
import { common, warnMessage } from '../../../common/constants/constant';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SortComponent } from '../../../common/sort/sort.component';
import { MasterDataManagementService } from '../master-data-management.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-admin-application-type',
  templateUrl: './admin-application-type.component.html',
  styleUrls: ['./admin-application-type.component.css']
})
export class AdminApplicationTypeComponent implements OnInit {
  showRecordsMetadata = 0;
  pageSizeMetadata = '10';  
  currentPageMetadata = 1; 
  changePageMetadata: any = 1;
  pageSizeOptions: any[] = common.pageSizeOptions;
  fleetVal: any = '';
  addApplicationTypeInput: boolean = false;
  addMetadataModal = false;
  searchObjMetadata: any;
  columnName: string;
  columnNameMetadata: string;
  applicationDisplayColumns: any;
  sortingOrder: string = 'asc';
  successMessage: string = '';
  successModal = false;
  scrollToTop =  false;
  dropdownName: string;
  metadataSearchData = [];
  totalItemsMetadata: any;
  maxPageMetadata = 0;
  selectedMetadataGroup:UntypedFormGroup;
  saveMetadataModal = false;
  deleteMetadataModal = false;
  userRole: string = '';
  userRoleDisplayFlag: boolean = false;

  applicationFilterObject = {
    applicationType: []
  }

  addApplicationTypeForm = new UntypedFormGroup({
    applicationTypeName: new UntypedFormControl('',[Validators.required])
  });
  
  applicatioSearchForm = new UntypedFormGroup({
    applicationType: new UntypedFormControl('')
  });

  metadataFormArray = new UntypedFormArray([]);

  constructor(private masterDataManagementService:MasterDataManagementService, private alertService: AlertService, private commonHandler: CommonHandlerService,
    private sharedService: SharedService
  ) { }

  
  @ViewChildren(SortComponent) sortComponents;
  
  ngOnInit(): void {
    this.columnName = 'applicationType';
    this.dropdownName = 'APPLICATIONTYPE';
    // this.columnNameMetadata = 'applicationType'
    // this.fleetVal = 'unit';
    this.searchMetadata();
    this.fetchMetadata();
    this.applicationDisplayColumns = [
      { code: "applicationType", name: "Application Type" }
    ];

    this.sharedService.getUserInfo().subscribe((userInfo) => {
      if (userInfo.hasOwnProperty('roleName')) {
        this.userRole = userInfo.roleName;
      }
    });

    if (this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
      this.userRoleDisplayFlag = true;
    } else if (this.userRole === 'Lab Admin' || this.userRole === 'CMI Engineer' || this.userRole === 'Sample Registration User'
      || this.userRole === 'Technical Service Manager'
      || this.userRole === 'Application Engineer' || this.userRole === 'DBU Branch' || this.userRole === 'DBU Fluids'
    ) {
      this.userRoleDisplayFlag = false;
    }
  }
  
  /**
 * Calls search method to sort the data based on columnName
 * @param columnName 
 */
  // sortData(columnName) {
  //   if (this.columnName != columnName) {
  //     this.sortingOrder = "";
  //     this.sortComponents._results.forEach(row => row.direction = true);
  //   }
  //   this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
  //   this.columnName = columnName;
  //   this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
  //   // this.getManageFleetDashboardData();
  // }
    
  sortDataMetadata(columnName) {
    console.log(columnName);
    console.log(this.columnNameMetadata);
    if (this.columnNameMetadata !== columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnNameMetadata = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.fetchMetadata();
  }
  
  intUserclearSearch(str) {
    console.log(str);
    if( this.applicatioSearchForm.controls[str].value != ''){
      this.applicatioSearchForm.patchValue({ [str]: '' });
      this.applicationFilterObject[str] = [];
      this.fetchMetadata();
    } else {
      this.applicationFilterObject[str] = [];
    }
  }

  addNewApplicationType() {
    if (this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
      this.addApplicationTypeInput = true;
    }
  }

  hideApplicationTypeInput() {
    this.addApplicationTypeInput = false;
  }

  openAddMetadataModal(){
    if(!this.addApplicationTypeForm.invalid){
      this.addMetadataModal = true;
    }else{
      this.addMetadataModal = false;      
      Object.keys(this.addApplicationTypeForm.controls).forEach(field => { 
        const control = this.addApplicationTypeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAddMetadataModal(){
    this.addMetadataModal = false;
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.metadataFormArray.controls = [];
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;  
    this.fetchMetadata();    
  }

  searchMetadata(){
    this.currentPageMetadata = 1;
    // this.addUnitMakeInput = false;
    this.fetchMetadata();
  }

  fetchMetadata(){
    // let data = this.addUnitMakeForm.getRawValue();
    let data = {};
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['columnName'] = this.columnName;
    data['dropdownName'] = this.dropdownName;
    data['sortingOrder'] = this.sortingOrder;
    data['applicationType'] = this.applicatioSearchForm.value['applicationType']

    let _data = {};
    _data['pageSize'] = this.pageSizeMetadata.toString();
    _data['currentPage'] = this.currentPageMetadata;
    _data['columnName'] = this.columnName;
    _data['dropdownName'] = this.dropdownName;
    _data['sortingOrder'] = this.sortingOrder;
    _data['applicationType'] = this.applicatioSearchForm.value['applicationType'],
    this.searchObjMetadata = _data;
    console.log(this.searchObjMetadata);
    this.masterDataManagementService.searchMetadata(data).subscribe(response =>{ 
      console.log(response);       
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;   
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();
        // this.fleetVal = 'unit';

        //form Array creation
        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {         
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            isEdit: new UntypedFormControl(false),
            applicationType: new UntypedFormControl(row.applicationType, [Validators.required]),
          });
          // if(this.fleetVal == 'unit') {
          //   formGroup.controls['unitMake'].setValidators([Validators.required]);
          // } else if(this.fleetVal == 'engine') {
          //   formGroup.controls['engineMake'].setValidators([Validators.required]);
          //   formGroup.controls['engineModel'].setValidators([Validators.required]);
          // } else if(this.fleetVal == 'application') {
          //   formGroup.controls['applicationType'].setValidators([Validators.required]);
          // }
          this.metadataFormArray.push(formGroup);
        });
        console.log(this.metadataFormArray);
        console.log(this.metadataFormArray.controls)
        
      } else {
        this.alertService.error(response.statusMessage);
        // this.metadataSearchData = [];
        // this.fleetVal = 'unit';
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  // Pagination for Metadata

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  editMetadata(item){
    console.log(item);
    if (this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {
    item.patchValue({
      isEdit:true
    });
   }
  }

  openSaveMetadataModal(item){
    console.log(item);
    if(!item.invalid){
      this.selectedMetadataGroup = item;
      this.saveMetadataModal = true;
    }else{
      this.saveMetadataModal = false;   
      Object.keys(item.controls).forEach(field => { 
        const control = item.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeSaveMetadataModal(){
    this.saveMetadataModal = false;
  }

  saveMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    console.log('data', data);
    data['dropdownName'] = 'APPLICATIONTYPE';
    data['optionName'] = data.applicationType.toUpperCase();
    console.log(data);
    // this.metadataDropdownNameList.forEach(row =>{
    //   if(row.name == data.dropdownName){
    //     data['dropdownName'] = row.code;
    //   }
    // });

    this.masterDataManagementService.saveMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {  
        this.searchMetadata();        
        this.successMessage = response.statusMessage;
        this.successModal = true; 
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.saveMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.saveMetadataModal = false;
    });
  }

  cancelMetadataEdit(item, i){
    item.patchValue({
      isEdit: false,
      optionName: this.metadataSearchData[i].optionName
    });
    this.fetchMetadata();
  }

  openMetadataDeleteModal(item){ 
    if(this.userRole === 'FluidWatch Manager' || this.userRole === 'Data Scientist') {   
    this.selectedMetadataGroup = item;
    this.deleteMetadataModal = true;
    }
  }

  closeDeleteMetadataModal(){
    this.deleteMetadataModal = false;
  }

  deleteMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    data['dropdownName'] = 'APPLICATIONTYPE';
    console.log(data);
    // this.metadataDropdownNameList.forEach(row =>{
    //   if(row.name == data.dropdownName){
    //     data['dropdownName'] = row.code;
    //   }
    // });
    this.masterDataManagementService.deleteMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {         
        this.successMessage = response.statusMessage;
        this.successModal = true; 
        this.searchMetadata();     
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.deleteMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.deleteMetadataModal = false;
    });
  }  

  addMetadata() {
    let data;
    let data1=this.addApplicationTypeForm.getRawValue();
    console.log(data1);
    data = {
      dropdownName: 'APPLICATIONTYPE',
      optionName: data1.applicationTypeName.toUpperCase(),
      activeStatus: 'Y'
    }
    console.log(data);

    this.masterDataManagementService.addMetadata(data).subscribe(response =>{
      console.log(response);
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;
        this.addApplicationTypeInput = false;
        this.addApplicationTypeForm.reset();
        this.fetchMetadata();
      } else {
        this.scrollToTop = true;
        this.alertService.error(response.statusMessage);
      }
      this.addMetadataModal = false;
    },err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addMetadataModal = false;
    });
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {        
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  
  // ===================== Code for Search Application Make ===========================---- */
  intUsersearchFilter(str) {
    let search = (this.applicatioSearchForm.value[str]).toLowerCase();
    console.log(search);
    if(search == '') {
      this.applicationFilterObject[str] = [];
      this.fetchMetadata();
    }
    let _data = {
      applicationType: search,
      columnName: "applicationType",
      dropdownName: "APPLICATIONTYPE",
      sortingOrder: "asc",
      pageSize: this.pageSizeMetadata.toString(),
      currentPage: 1,
    }
    console.log(_data);
    if (this.applicatioSearchForm.value[str].length >= 2) {
      this.masterDataManagementService.searchMetadata(_data).subscribe(
        response =>{
          if (response.statusCode == "1") {
            this.applicationFilterObject[str] = response.dropdownOptionList;
            console.log(this.applicationFilterObject[str]);
            // console.log(this.unitDisplayColumns)
            
            // if(str === 'engineMake' || str === 'engineModel')
            // {
            //   this.addBlank(str,search,this.applicationFilterObject);
            // }
            console.log("this.applicationFilterObject",this.applicationFilterObject);
          } 
          // else {
          //   let  value = this.addBlank(str,search,this.applicationFilterObject)
        
          //   if(value === 'valid')
          //   {
          //   console.log('valid')
          //   }
          //   else if(value === 'invalid')
          //   {          
          //     this.alertService.error(response.statusMessage);
          //     this.scrollToTop = true;
          //     this.applicationFilterObject[str] = [];
          //   }
          //   else{
          //     this.alertService.error(response.statusMessage);
          //     this.scrollToTop = true;
          //   }     
          // }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }
  }

  addBlank(searchColumn,searchValue,searchList) { 
    let countMake =0;
    let countModel=0;
 
    if((searchColumn === 'engineMake')) {
      if((searchValue == 'bl' || searchValue == 'bla' || searchValue == 'blan' || searchValue =='blank') === true) {
        // countMake=0;
        for(let val of searchList.engineMake)
        {
              if(val.engineMake === 'Blank')
              {
                countMake++;
              }
        }
        if(countMake === 0)
        {
          searchList.engineMake.push({maxRow: 0, engineMake: "Blank", expanded: false});
          
        }
        return 'valid'
      }   
      return 'invalid'        
    }
    if((searchColumn === 'engineModel')) {
      if((searchValue == 'bl' || searchValue == 'bla' || searchValue == 'blan' || searchValue =='blank')) {
        for(let val of searchList.engineModel) {
          if(val.engineModel === 'Blank') {
            countModel++;
          }
        }
        if(countModel === 0) {
          searchList.engineModel.push({maxRow: 0, engineModel: "Blank", expanded: false});
        }
        return 'valid';
     }    
      return 'invalid'       
    }
  }

  searchDashboardDropDownSelect(str) {
    console.log(str);
    let data = {};
    data = {
      applicationType: this.applicatioSearchForm.value['applicationType'],
      columnName: "applicationType",
      dropdownName: "APPLICATIONTYPE",
      sortingOrder: "asc",
      pageSize: this.pageSizeMetadata.toString(),
      currentPage: 1,
    }
    console.log(data);
    this.masterDataManagementService.searchMetadata(data).subscribe(response =>{ 
      console.log(response);       
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;   
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();

        //form Array creation
        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {         
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            isEdit: new UntypedFormControl(false),
            applicationType: new UntypedFormControl(row.applicationType),
          });
          // if(this.fleetVal == 'unit') {
          //   formGroup.controls['unitMake'].setValidators([Validators.required]);
          // } else if(this.fleetVal == 'engine') {
          //   formGroup.controls['engineMake'].setValidators([Validators.required]);
          //   formGroup.controls['engineModel'].setValidators([Validators.required]);
          // } else if(this.fleetVal == 'application') {
          //   formGroup.controls['applicationType'].setValidators([Validators.required]);
          // }
          this.metadataFormArray.push(formGroup);
        });
        console.log(this.metadataFormArray);
        console.log(this.metadataFormArray.controls)
        
      } else if (response.statusCode == "-1") {
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = 0;
        this.changePageMetadata = 0;
        this.showRecordsMetadata = 0;
        this.totalItemsMetadata = 0;
        this.loadPageMetadata(1);
      } else {
        this.alertService.error(response.statusMessage);
        this.metadataSearchData = [];
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }
}
