import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ScatterPlotGridService {

  constructor(private api: ApiHandlerService) { }

  getScatterPlotUnitsDashboard(data: any) {
    return this.api.post(environment.apiUrl + '/getScatterPlotUnitsDashboard', data);
  }
}
