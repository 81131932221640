import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/Rx';
import 'rxjs/add/operator/do';
import { tap, finalize } from "rxjs/operators";
import { LoaderService } from '../View/common/preloader/loader.service';
import { environment } from '../../environments/environment';

export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(
        private loader: LoaderService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var cors = "";
        var allowedOrigins = environment.allowedOrigins;

        let modHeader = request.headers.set('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload');
        modHeader = modHeader.set('X-XSS-Protection', '1;mode=block');
        modHeader = modHeader.set('X-Frame-Options', 'SAMEORIGIN');
        modHeader = modHeader.set('Content-Security-Policy', "script-src 'self' " + environment.allowedOrigins);
        modHeader = modHeader.set('X-Content-Type-Options', 'nosniff');
        modHeader = modHeader.set('Cache-Control', 'no-cache,no-store,max-age=0,must-revalidate');
        //modHeader = modHeader.set('Connection', 'keep-alive');
        modHeader = modHeader.set('Access-Control-Allow-Origin', allowedOrigins.join(',') + window.location.origin);
        modHeader = modHeader.set('Access-Control-Allow-Methods', 'GET, DELETE, PUT,POST, OPTIONS');
        modHeader = modHeader.set('Access-Control-Allow-Headers', 'Content-Type');
        //modHeader = modHeader.set('origin', environment.origin);
        const updatedRequest = request.clone({
            headers: modHeader
        }); 
        //logging the updated Parameters to browser's console
        this.loader.log("Before making api call loader is invoked:");
        this.loader.show();
        this.loader.add();
        //this.loader.log("Before making api call : " + updatedRequest);
        return next.handle(updatedRequest).pipe(
            tap(
                event => {
                    //logging the http response to browser's console in case of a success
                    if (event instanceof HttpResponse) {
                        this.loader.log("api call success ::> " + event);
                    }
                },
                error => {
                    //logging the http response to browser's console in case of a failuer



                    if (error instanceof HttpErrorResponse) {
                        this.loader.log("status ::> " + error.status);
                    }

                }
            ),
            finalize(() => this.checkStatus())
        );
    }
    checkStatus() {
        this.loader.delete()
        var count = this.loader.status();
        if (count == 0 || count < -1) {
            this.loader.hide();
        }
        else {
            this.loader.show();
        }
    }
}
