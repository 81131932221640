import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LineGraphHomeService {

  constructor(private api: ApiHandlerService) { }

  getLineGraphDataLoad(data: any) {
    return this.api.post(environment.apiUrl + '/getLineGraphDataLoad', data);
  }
  getDataForLineGraph(reqData) {
    return this.api.post(environment.apiUrl + '/getBYOLineGraphData', reqData)
  }
  getFluidType(): Observable<any> {
    return this.api.post(environment.apiUrl + '/getParameters', {});
  }
}
