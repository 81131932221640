import { Component, OnInit, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.css']
})
export class FleetDetailsComponent implements OnInit {
  fleetVal: any = '';

  constructor() { }

  ngOnInit(): void {
    this.fleetVal = 'unit';
  }

  onChangeFleet() {
  }
}