import { Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { common, fluidType, warnMessage } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { PopulationGridService } from './population-grid.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { SharedService } from 'src/app/View/service/shared.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-population-grid',
  templateUrl: './population-grid.component.html',
  styleUrls: ['./population-grid.component.css']
})
export class PopulationGridComponent implements OnInit, OnDestroy {

  populationColumns: any[] = [];
  populationColumnsData: any[] = [];
  exportExcelData: any[] = [];
  cloneModal: boolean = false;
  deleteModal: boolean = false;
  searchObj: any;
  populationIdToDelete: string = '';


  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize;
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  Populationmanagement:any;
  currentItem: any = [];
  pageValue: any;
  /* --------Grid Filter variable declaration------- */
  populationFilterObject = {
    fluidType: [],
    populationName: [],
    status: []
  }

  populationSearchForm = new UntypedFormGroup({
    fluidType: new UntypedFormControl(''),
    populationName: new UntypedFormControl(''),
    status: new UntypedFormControl('')
  });

  constructor(private router: Router,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private populationService: PopulationGridService,	
    private sharedService: SharedService
    ) { 
    this.pageValue = JSON.parse(localStorage.getItem('populationmanagementObject'));
    if(this.pageValue !=null){
      this.pageSize = JSON.parse(localStorage.getItem('populationmanagementObject'));
    }else{
      this.pageSize ='10'
    }
    }

  @ViewChildren(SortComponent) sortComponents;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

  ngOnInit() {
    let sortdata = JSON.parse(sessionStorage.getItem('populationSortData'));
    if(sortdata == 'undefined'){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(sessionStorage.getItem('populationSortData'))      
    }
    if(this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
       this.columnName =  this.currentItem.column;
       this.sortingOrder =  this.currentItem.sort;
     }
     else{
       this.sortingOrder = 'desc';
       this.columnName = 'lastUpdatedDate';
     }
   // this.populationSearchForm.patchValue({ fluidType: 'Engine Oil (Liquid Fuel)' });
    let Populationmanagement = JSON.parse(sessionStorage.getItem('populationmanagementObject'));
    if(Populationmanagement !=null){
      this.populationSearchForm.patchValue({fluidType:Populationmanagement['fluidType']})
      this.populationSearchForm.patchValue({populationName:Populationmanagement['populationName']})
      this.populationSearchForm.patchValue({status:Populationmanagement['status']})
    }
    this.populationColumns = [
      { code: 'lastUpdatedDate', name: 'Last Updated' },
      { code: 'fluidType', name: 'Fluid Type' },
      { code: 'populationName', name: 'Population Name' },
      { code: 'itemsInPopulation', name: 'Items in Population' },
      { code: 'status', name: 'Status' },
      { code: 'action', name: 'Actions' }
    ];
    this.getPopulationDashboardData();
  }

  getPopulationDashboardData() {
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      fluidType: this.populationSearchForm.value['fluidType'],
      populationName: this.populationSearchForm.value['populationName'],
      status: this.populationSearchForm.value['status']
    }

    this.populationService.getPopulationDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.populationColumnsData = response.populationDTOList;
          this.populationFilterObject.fluidType = response.fluidTypeList;
          this.populationFilterObject.status = response.populationStatusList;
          this.totalItems = response.populationDTOList[0].maxRow;
          this.calculateOffset();
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* -------------------------filter value dropdown------------------------- */
  searchFilter(str) {
    let search = (this.populationSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.populationFilterObject[str] = [];
      this.searchDashboardDropDownSelect();
    }
    sessionStorage.setItem('populationmanagementObject', JSON.stringify(this.populationSearchForm.value));
    let _data = {
      searchColumn: str,
      searchValue: this.populationSearchForm.value[str],
      fluidType: this.populationSearchForm.value['fluidType'],
      populationName: this.populationSearchForm.value['populationName'],
      status: this.populationSearchForm.value['status']
    }
    if (str !== 'fluidType' && str !== 'status') {
      if (this.populationSearchForm.value[str].length >= 2) {
        this.populationService.getPopulationDashboardData(_data).subscribe(
          response => {
            if (response.statusCode == "1") {
              this.populationFilterObject[str] = response.distinctValuesList;
            } else {
              this.alertService.error(response.statusMessage);
              this.populationFilterObject[str] = [];
              this.alertService.clear();
              window.sessionStorage.removeItem('populationmanagementObject');
            }
          }, err => {
            this.alertService.error(err);
          }
        );
      }
      else if(this.populationSearchForm.value[str].length == 0){
        this.clearSearch(str)
        this.populationFilterObject[str] == [];
        this.populationFilterObject[str].length = 0;

      }
    }
  }

  getColumnCode(str) {
    if (str === 'fluidType') {
      return 'fluidTypeName';
    } else if (str === 'status') {
      return 'statusName';
    }
    else {
      return str;
    }
  }
  /* -------------------------search button for filter value selected------------------------- */
  searchDashboard() {
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      fluidType: this.populationSearchForm.value['fluidType'],
      populationName: this.populationSearchForm.value['populationName'],
      status: this.populationSearchForm.value['status']
    }

    this.populationService.getPopulationDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.populationColumnsData = response.populationDTOList;
          this.totalItems = response.populationDTOList[0].maxRow;
          // this.totalItems = response.maxRow;
          this.calculateOffset();
        } else {
          this.alertService.error(response.statusMessage);
          this.populationColumnsData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  searchDashboardDropDownSelect() {
    this.autocomplete.showPanel;
    sessionStorage.setItem('populationmanagementObject', JSON.stringify(this.populationSearchForm.value));
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: "1",
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      fluidType: this.populationSearchForm.value['fluidType'],
      populationName: this.populationSearchForm.value['populationName'],
      status: this.populationSearchForm.value['status']
    }

    this.populationService.getPopulationDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.populationColumnsData = response.populationDTOList;
          this.totalItems = response.populationDTOList[0].maxRow;
          // this.totalItems = response.maxRow;
          this.calculateOffset();
        } else {
          this.alertService.error(response.statusMessage);
          this.populationColumnsData = [];
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* -------------------- Clear search------------- */
  clearSearch(str) {
    //console.log(this.populationSearchForm.value);
    if( this.populationSearchForm.controls[str].value != ''){

    this.populationSearchForm.patchValue({ [str]: '' });
    if (str !== 'fluidType' && str !== 'status') {
      this.populationFilterObject[str] = [];
    }
    this.searchDashboard();
  }
  }
  clearSearchfilters(){    
    this.alertService.clear();
    this.pageSize='10';
    window.sessionStorage.removeItem('populationmanagementObject');    
    this.populationSearchForm.patchValue({fluidType:''})
    this.populationSearchForm.patchValue({populationName:''})
    this.populationSearchForm.patchValue({status:''})    
    this.searchDashboard();
    this.loadPage(1);
  }
  /* ---------------------Add Population--------------------- */
  addPopulation() {
    localStorage.setItem('populationType', JSON.stringify('add'));
    this.router.navigate(['/addPopulation']);
  }

  /* ---------------------Replace Population------------------- */
  replacePopulation(data) {
    //console.log("set pop data", data);
    localStorage.setItem('populationType', JSON.stringify('replace'));
    localStorage.setItem('populationData', JSON.stringify(data));
    this.router.navigate(['/addPopulation']);
  }

 /* ---------------------Update Population------------------- */
 updatePopulation(data) {
  //console.log("set pop data", data);
  localStorage.setItem('populationType', JSON.stringify('update'));
  localStorage.setItem('populationData', JSON.stringify(data));
  this.router.navigate(['/addPopulation']);
}

  /* ------------------------Clone Population --------------------------- */
  clonePopulation(data) {
    localStorage.setItem('populationType', JSON.stringify('clone'));
    localStorage.setItem('populationData', JSON.stringify(data));
    this.router.navigate(['/addPopulation']);
  }

  /* ---------------------Delete Population------------------ */
  openDeleteModal(id) {
    this.deleteModal = true;
    this.populationIdToDelete = id;
  }

  closeDeleteModal() {
    this.deleteModal = false;
  }

  deletePopulation() {
    this.closeDeleteModal();
    let req = {
      populationId: this.populationIdToDelete,
      userId: this.sharedService.getUserId()
    }
    this.populationService.deletePopulation(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.getPopulationDashboardData();
          this.alertService.success(response.statusMessage);
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }


  /* ------------------------Download Population --------------------------- */
  downloadPopulation(item) {
    let req = {
      populationId: item.populationId,
      fluidTypeId:  item.fluidTypeId
    }
    this.populationService.downloadPopulation(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.exportExcelData = response.populationDataList;
          this.exportAsExcelFile(this.exportExcelData, item.populationName);
          // this.alertService.success(response.statusMessage);
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* ----------------------- Export To Excel----------------------- */

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // this.wrapAndCenterCell(worksheet.G1);
    const workbook: XLSX.WorkBook = { Sheets: { 'Population Data': worksheet }, SheetNames: ['Population Data'] };
    XLSX.writeFile(workbook, `Population_${excelFileName}.xlsx`);
  }

  // private wrapAndCenterCell(cell: XLSX.CellObject) {
  //   const wrapAndCenterCellStyle = { alignment: { wrapText: true, vertical: 'center', horizontal: 'center' } };
  //   this.setCellStyle(cell, wrapAndCenterCellStyle);
  // }

  // private setCellStyle(cell: XLSX.CellObject, style: {}) {
  //   cell.s = style;
  // }


  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.populationColumnsData = [];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('populationmanagementObject', JSON.stringify(this.pageSize));
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.populationColumnsData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
      }
    }
  }

  searchLength(filterObject:any)
  {
    if(filterObject.populationName.length >0 || filterObject.fluidType.length >0||
      filterObject.status.length >0)
    {
        return true
    }
    else
    {
      return false
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
   
   let val =this.searchLength(this.populationFilterObject)
   
    if(val === true)
    {
      this.searchDashboard();
    }
    else
    {
      this.getPopulationDashboardData();
    }
    
  }



  getArray(): any[] {
    return Array(this.maxPage);
  }

  /**
 * Calls search method to sort the data based on columnName
 * @param columnName 
 */
  sortData(columnName) {
    let existingsortdata= sessionStorage.getItem('analyzesortData');
    if(existingsortdata == 'undefined' || existingsortdata == null || existingsortdata== ""){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(existingsortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      if(this.currentItem.sort == 'asc'){
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      }else{
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);

      }
    }
    let sortcolumn=  this.sortingOrder != 'asc' ? 'asc' : 'desc';
    let sort ={column : columnName, sort :sortcolumn,direction:sortcolumn};
    sessionStorage.setItem('populationSortData', JSON.stringify(sort));
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.getPopulationDashboardData();
  }


  setPopulationData(data) {
    //console.log("set pop data", data);
    localStorage.setItem('selectedTab', JSON.stringify("parameter"));
    localStorage.setItem('populationForLimitFlag', JSON.stringify(data));
    this.router.navigate(['/limitFlag']);
  }

  metadataInfo(item){
    this.populationService.setSelectedPopulation(item);
    this.router.navigate(['/metadataInfo']);
  }
  ngOnDestroy(){
    localStorage.setItem('populationmanagementObject', JSON.stringify(this.pageSize));
  }
}
