import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';


@Injectable({
  providedIn: 'root'
})
export class LimitFlaggingService {

  constructor(private api: ApiHandlerService) { }

  getLimitFlagDashboardData(data: any) {
    return this.api.post(environment.apiUrl + '/getLimitFlaggingData', data);
  }

  setPopulationStatus(data) {
    return this.api.post(environment.apiUrl + '/activatePopulation', data);
  }
}
