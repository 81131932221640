import { common } from './constants/constant';
import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../handlers/api-handler.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const commonModule = 'commonapi/';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public storage: any;
  public changeReasonList :any[];
  public restore: any;
  public isBackToParent: boolean;
  public authorization: any;
  constructor(private api: ApiHandlerService, private http: HttpClient) { }
  setrestoreData(data?: any) {
    this.restore = data;
  }
  getrestoreData() {
    return this.restore;
  }
  setStorageData(data?: any) {
    this.storage = data;
  }
  getStorageData() {
    return this.storage;
  }
  clear() {
    this.storage = undefined;
    this.restore = undefined;
    this.isBackToParent = false;
  }
  setAllData(storage?: any, restore?: any, isBackToParent?: any) {
    if (storage) {
      this.storage = storage;
    }
    if (storage) {
      this.restore = restore;
    }
    if (isBackToParent) {
      this.isBackToParent = isBackToParent;
    }
  }

  setChangeReason(changeReasonList){
    this.changeReasonList = [];
    this.changeReasonList = changeReasonList;
  }

  getChangeReasonList():any{
    return this.api.post(
      environment.App_URL + commonModule + 'fetchChangeReason',{}
    );
  }

  setAuthorization(data:any){
    this.authorization=undefined;
    this.authorization=data;
  }
  getAuthorization(){
    if(this.authorization!=undefined){
      return this.authorization;
    }
    return false;
  }

}

