import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../preloader/loader.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {


  constructor(private route: ActivatedRoute, private loader: LoaderService) {   

  }
  ngOnInit(): void {    
    this.loader.hide();
  }

}
