import { AfterViewChecked, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { TsmaeReportsService } from '../tsm-ae-report/tsmae-reports.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { FleetUnitsOdiReportService } from './fleet-units-odi-report.service';
import { DatePipe } from '@angular/common';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { MatOption } from '@angular/material';

@Component({
  selector: 'app-fleet-units-odi-report',
  templateUrl: './fleet-units-odi-report.component.html',
  styleUrls: ['./fleet-units-odi-report.component.css']
})
export class FleetUnitsOdiReportComponent implements OnInit, AfterViewChecked {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChildren(SortComponent) sortComponents;

  readonly USERTYPE = USERTYPE;
  userRole: string = '';
  isSearchDone: boolean = false;
  reportData: any = [];
  reportCountData: any = [];
  reportAvgData: any = [];
  companyArray = [];
  companyList: any[];
  engineModelMap: any;
  engineModelList: any[];
  engineModelArray = [];
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  displayUOM: any;

  uomList = [{ uom: 'MILES', uomName: 'Miles' },
  { uom: 'KILOMETERS', uomName: 'Kilometers' },
  { uom: 'HOURS', uomName: 'Hours' }];

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  scrollToTop: any;
  searchObj: any;
  engineModelDropResult = [];
  filterObject = {
    unitId: [],
    unitMake: [],
    unitModel: [],
    unitYear: [],
    esn: [],
    engineMake: [],
    engineModel: [],
    engineYear: [],
    lastSampledDate: [],
    lastSampleUnitDistance: [],
    lastSampleFluidDistance: [],
    dateUnitCreated: []
  };

  reportCols = [
    { code: 'sampleId', value: 'Sample ID' },
    { code: 'unitId', value: 'Unit ID' },
    { code: 'unitModel', value: 'Unit Model' },
    { code: 'unitYear', value: 'Unit Year' },
    { code: 'esn', value: 'ESN' },
    { code: 'engineModel', value: 'Engine Model' },
    { code: 'engineYear', value: 'Engine Year' },
    { code: 'lastSampledDate', value: 'Last Sampled Date' },
    { code: 'sampleUnitDistance', value: 'Sample Unit Distance' },
    { code: 'sampleFluidDistance', value: 'Sample Fluid Distance' },
    { code: 'actualDaysBetweenSamples', value: 'Actual Days Between Samples' },
    { code: 'actualUnitDistanceBetweenSamples', value: 'Actual Unit Distance Between Samples' }
  ];

  inputForm = new UntypedFormGroup({
    companyId: new UntypedFormControl('', [Validators.required]),
    companyName: new UntypedFormControl(''),
    companyIdFilter: new UntypedFormControl(''),
    engineModel: new UntypedFormControl('', [Validators.required]),
    engineModelFilter: new UntypedFormControl(''),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
    uom: new UntypedFormControl('', [Validators.required])
  });

  searchForm = new UntypedFormGroup({
    sampleId: new UntypedFormControl(''),
    unitId: new UntypedFormControl(''),
    unitMake: new UntypedFormControl(''),
    unitModel: new UntypedFormControl(''),
    unitYear: new UntypedFormControl(''),
    esn: new UntypedFormControl(''),
    engineMake: new UntypedFormControl(''),
    engineModel: new UntypedFormControl(''),
    engineYear: new UntypedFormControl(''),
    lastSampledDate: new UntypedFormControl(''),
    lastSampleUnitDistance: new UntypedFormControl(''),
    lastSampleFluidDistance: new UntypedFormControl(''),
    dateUnitCreated: new UntypedFormControl('')
  });

  /*--------TSM & DBU CompanyName dropdown---------*/
  companyIdTSM: any = '';
  companyNameTSM: any = '';
  tsmCustomerNameDropdown: any;
  messageDropdownTsm: any;
  isFirstTime: boolean = true;
  isSearchDisable: boolean = false;
  messageDropdownDBU: any;
  isFirstTimeDBU: boolean = true;
  behaviorObs: any;
  behaviorObsTSM: any;
  companyListNewArr: any[]=[];
  companyIdListArr: any[] = [];
  companyListExternal: any[] = [];
  companyListExtChecked: any[];
  constructor(private commonHandler: CommonHandlerService,
    private alertService: AlertService,
    private tsmAEReportsService: TsmaeReportsService,
    private reportService: FleetUnitsOdiReportService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private router: Router) { }

  ngOnInit() {
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    this.userRole = this.sharedService.getUserRole();    
    this.columnName = 'unitId';
    this.sortingOrder = 'asc';
    this.minStartDate = new Date(2021, 0, 1);
    this.minEndDate = new Date(2021, 0, 1);
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.inputForm.controls['companyId'].disable();   
    }
    if (this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.isSearchDisable = true;
      this.inputForm.controls['engineModel'].disable();
      this.inputForm.controls['startDate'].disable();
      this.inputForm.controls['endDate'].disable();
      this.inputForm.controls['uom'].disable();
    }
    this.getInitialData();
    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message => {
      if (this.userRole == USERTYPE.TSM && this.isFirstTime == false) {
        if (this.router.url == '/fleetUnitsOdiReport') {
          this.messageDropdownTsm = message;
          this.recieveMessageTSM(this.messageDropdownTsm);
        }
      }
      this.isFirstTime = false;
    });

    this.behaviorObs = this.sharedService.currentChange.subscribe(message => {
      if ((this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) && this.isFirstTimeDBU == false) {
        if (this.router.url == '/fleetUnitsOdiReport') {
          this.messageDropdownDBU = message;
          this.recieveMessageTSM(this.messageDropdownDBU);
        }
      }
      this.isFirstTimeDBU = false;
    });
  }

  ngOnDestroy() {
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
  }

  recieveMessageTSM(event) {
    this.alertService.clear();
    this.inputForm.controls['engineModel'].enable();
    this.inputForm.controls['startDate'].enable();
    this.inputForm.controls['endDate'].enable();
    this.inputForm.controls['uom'].enable();
    this.inputForm.patchValue({
      companyId: '',
      engineModel: []
    });
    // this.engineModelList = []
    this.engineModelArray = [];

    this.tsmCustomerNameDropdown = event;
    this.companyNameTSM = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
    if (this.companyIdTSM == '') {
      this.isSearchDisable = true;
      this.alertService.error("Please select Company Name");
      this.inputForm.controls['engineModel'].disable();
      this.inputForm.controls['startDate'].disable();
      this.inputForm.controls['endDate'].disable();
      this.inputForm.controls['uom'].disable();
    } else {
      this.companyList.forEach(element => {
        if (element.companyId == this.companyIdTSM && element.companyName == this.companyNameTSM) {
          // console.log(element);
          this.inputForm.patchValue({
            companyId: this.companyIdTSM
          });
          this.onCompanyChange();
          this.isSearchDisable = false;
        }
      });
      if (this.inputForm.controls['companyId'].value == '') {
        this.isSearchDisable = true;
        this.alertService.error("Selected company has no reports")
        this.inputForm.controls['engineModel'].disable();
        this.inputForm.controls['startDate'].disable();
        this.inputForm.controls['endDate'].disable();
        this.inputForm.controls['uom'].disable();
      }
    }
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getInitialData() {
    this.tsmAEReportsService.getInitialData().subscribe(response => {
      if (response.statusCode == "1") {
        this.engineModelMap = response.engineModelMap;
        this.engineModelMap = Object.keys(this.engineModelMap).map(key => ({type: key, value: this.engineModelMap[key]}));        
        this.companyList = response.companyList;
        this.companyListNewArr=[];
     
        this.companyList.sort(function (a, b) {
          const nameA = a.companyName.toUpperCase();
          const nameB = b.companyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (this.userRole == USERTYPE.TSM) {
          this.inputForm.controls['companyId'].disable();
          if (localStorage.getItem("customerDropdownTSM")) {
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
          }
          if (this.companyIdTSM == '') {
            this.alertService.error('Please select Company Name');
          }
        } else if (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
          this.inputForm.controls['companyId'].disable();
          if (localStorage.getItem("branchCustomerDropdown")) {
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
          }
          if (this.companyIdTSM == '') {
            this.alertService.error('Please select Company Name');
          }
        }
        // if (this.userRole == USERTYPE.EXTERNAL){
        this.companySearch('');
        //}
        if (this.userRole === USERTYPE.EXTERNAL) {
         // this.engineModelList = this.engineModelMap[this.inputForm.controls.companyIdList.value];
          //this.engineModelSearch('');
        }
      } else {
        this.alertService.error(response.statusMessage);
      }
    }, err => {
      this.alertService.error(err);
    });
   
      this.companyArray = this.companyList;
    
    this.engineModelArray = this.engineModelMap;

  }

  search() {
    this.currentPage = 1;
    this.searchForm.patchValue({
      unitId: '',
      unitMake: '',
      unitModel: '',
      unitYear: '',
      esn: '',
      engineMake: '',
      engineModel: '',
      engineYear: ''
    });
    if (this.inputForm.controls["uom"].value == 'MILES') {
      this.displayUOM = '(mi)';
    } else if (this.inputForm.controls["uom"].value == 'KILOMETERS') {
      this.displayUOM = '(Km)';
    } else if (this.inputForm.controls["uom"].value == 'HOURS') {
      this.displayUOM = '(hr)';
    }
    this.getReportData();
  }

  optionSearch() {
    this.currentPage = 1;
    this.getReportData();
  }

  getInputData(): any {
    let inputData = this.searchForm.getRawValue();
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };
    inputData['engineModelGrid'] = inputData['engineModel'];
    delete inputData.engineModel;

    Object.assign(inputData, this.searchObj);

    inputData['companyId'] = this.inputForm.controls.companyId.value;
    inputData['companyName'] = this.getCompanyName();
    inputData['uom'] = this.inputForm.controls["uom"].value.toUpperCase();
    inputData['engineModel'] = this.inputForm.controls["engineModel"].value.join();
    inputData['columnName'] = this.getSortColumn(inputData['columnName']);
    inputData['startDate'] = this.datePipe.transform(
      this.inputForm.controls["startDate"].value,
      'yyyy-MM-dd'
    );
    inputData['endDate'] = this.datePipe.transform(
      this.inputForm.controls["endDate"].value,
      'yyyy-MM-dd'
    );
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;
    return inputData;
  }

  getReportData() {
    let inputData = this.getInputData();

    this.reportService.getFleetODIReport(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.reportData = response.reportList;
        this.reportCountData = response.reportCountList;
        this.reportAvgData = response.reportAvgList;
        this.totalItems = response.maxRow;
        this.calculateOffset();
        this.isSearchDone = true;
      } else {
        this.reportData = [];
        this.reportCountData = [];
        this.reportAvgData = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );

    //mock data   
    /*this.reportAvgData = [{'unitDistance': '1000', 'fluidDistance': '2000', 'daysBetweenSample': '30', 'unitDistanceBetweenSample': '500', 'fluidDistanceBetweenSample': '200'}];
    this.reportCountData = [{'sampleId': '10', 'unitId':'5', 'unitModel': '5', 'unitYear': '5', 'esn': '5', 'engineModel': '5', 'engineYear': '5'}];
    this.reportData = [{'sampleId': 'FW2021061501', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061502', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061503', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061504', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061505', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061506', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061507', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061508', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061509', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061510', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'},
    {'sampleId': 'FW2021061511', 'unitId':'Unit1', 'unitModel': 'model', 'unitYear': '2022', 'esn': 'esn', 'engineModel': 'model', 'engineYear': 'year', 'lastSampledDate':'6/16/2021', 'sampleUnitDistance':'100', 'sampleFluidDistance':'100', 'actualDaysBetweenSamples':'0', 'actualUnitDistanceBetweenSamples':'100', 'actualFluidDistanceBetweenSamples':'100'}];

    this.isSearchDone = true;
    this.totalItems = 5;
    this.calculateOffset();*/
  }

  searchFilter(str) {
    let inputData = this.getInputData();
    inputData['searchColumn'] = this.getSortColumn(str);
    inputData['searchValue'] = this.searchForm.value[str];

    if (inputData['searchValue'].length >= 2) {
      this.reportService.getFleetODIReport(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.filterObject[str] = response.distinctValuesList;
        } else {
          this.filterObject[str] = [];
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    }
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.getReportData();
    }
  }

  exportToExcel() {
    let inputData = this.getInputData();
    inputData['action'] = "EMAIL";
    inputData['pageSize'] = 5000;
    inputData['userName'] = this.sharedService.getUserInformation().firstName + " " +
      this.sharedService.getUserInformation().lastName;

    this.reportService.getFleetODIReport(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.alertService.success(response.statusMessage);
        this.scrollToTop = true;
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  clearInput() {
    this.inputForm.reset();
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.inputForm.patchValue({
        companyId: this.sharedService.getCompanyId()
      });
    } else if (this.userRole === USERTYPE.TSM) {
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
    } else if (this.userRole === USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
    }
    this.isSearchDone = false;
    this.minStartDate = new Date(2021, 0, 1);
    this.minEndDate = new Date(2021, 0, 1);
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
  }

  companySearch(value: string) {
    this.companyArray = [];
    let filter = value.toLowerCase();
    if (this.userRole == USERTYPE.EXTERNAL) {
      this. companyListNewArr = [];
      this.companyListExternal = this.companyIdListArr;
      for(let i=0;i<this.companyList.length; i++){
        this.companyListExternal.filter(row => {
          if(row == this.companyList[i].companyId){
            this.companyListNewArr.push([{companyId:this.companyList[i].companyId.toUpperCase(),companyName:this.companyList[i].companyName.toUpperCase()}]);
          }
        });                 
      };
      const convertToArray = [].concat(...this.companyListNewArr);
      this.companyListExtChecked = convertToArray;
      this.companyArray = convertToArray;
      let emptyArr = [];
        this.companyArray.forEach(row => {
          emptyArr.push(row.companyId.toUpperCase());
        });    
        this.inputForm.patchValue({
          companyId: emptyArr
        });       
        this.toggleAllSelection();
    }
    this.companyArray = this.companyList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if (companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0) {
        return true;
      } else {
        return false;
      }
    });

    if (this.companyArray.length == 0) {
      this.companyArray.push({ 'companyName': 'Select', 'companyId': '' });
    }
  }

  clearCompanySearch() {
    this.companyArray = [];
    this.companyArray = this.companyList;
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.reportData = [];
    this.reportCountData = [];
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.reportData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        // this.scrollToTop = true;
      }
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.getReportData();
  }

  /**
* Calls search method to sort the data based on columnName
* @param columnName 
*/
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';

    this.getReportData();
  }

  isSearchable(column) {
    if (column == 'sampleId' || column == 'unitId' || column == 'unitModel' ||
      column == 'unitYear' || column == 'esn' || column == 'engineModel' ||
      column == 'engineYear') {
      return true;
    } else {
      return false;
    }
  }

  getCompanyName():any{
    let companyNameArr=[];
    let companyName;
    let selectedcompanyDetails= this.inputForm.controls.companyId.value
    for (let i = 0; i < selectedcompanyDetails.length; i++) {
      for (let j = 0; j < this.companyList.length; j++){
      if(this.companyList[j].companyId == selectedcompanyDetails[i]){
        companyName =  this.companyList[j].companyName;
        companyNameArr.push(companyName.toUpperCase())
     }
    }
    }
    return companyNameArr;
  } 
  
  onCompanyChange() {
    this.engineModelDropResult = [];
    this.inputForm.patchValue({
      engineModel: []
    });
    this.engineModelList = this.engineModelMap[this.inputForm.controls.companyId.value];
    this.inputForm.controls.companyId.value.forEach(key => {
      const engineModelFilter = this.engineModelMap.filter(row => {
        return (row.type == key);
      }); 
      
      this.engineModelDropResult.push(engineModelFilter[0].value);
    });
        
    const convertToArray = [].concat(...this.engineModelDropResult);    
    this.engineModelList = convertToArray;    
    this.engineModelSearch('');
  }
 
  engineModelSearch(value: string) {
    this.engineModelArray = [];
    let filter = value.toLowerCase();

    this.engineModelArray = this.engineModelList.filter((row) => {
      if (row.toLowerCase().indexOf(filter) >= 0 && row !== '') {
        return true;
      } else {
        return false;
      }
    });
  }

  clearEngineModelSearch() {
    this.engineModelArray = [];
    this.engineModelSearch('');
  }

  startDateChange() {
    this.minEndDate = this.inputForm.controls.startDate.value;
  }
  endDateChange() {
    this.maxStartDate = this.inputForm.controls.endDate.value;
  }

  getSortColumn(column): any {
    let columnName = "unit_id";
    if (column == 'sampleId') {
      columnName = "sample_id";
    } else if (column == 'unitId') {
      columnName = "unit_id";
    } else if (column == 'unitModel') {
      columnName = "unit_model";
    } else if (column == 'unitYear') {
      columnName = "unit_year";
    } else if (column == 'esn') {
      columnName = "esn";
    } else if (column == 'engineModel') {
      columnName = "engine_model";
    } else if (column == 'engineYear') {
      columnName = "engine_year";
    } else if (column == 'lastSampledDate') {
      columnName = "last_sampled_date";
    } else if (column == 'sampleUnitDistance') {
      columnName = "unit_distance";
    } else if (column == 'sampleFluidDistance') {
      columnName = "fluid_distance";
    } else if (column == 'actualDaysBetweenSamples') {
      columnName = "days_between_sample";
    } else if (column == 'actualUnitDistanceBetweenSamples') {
      columnName = "unit_distance_between_sample";
    }
    return columnName;
  }
  toggleAllSelection() {
    if(this.userRole === USERTYPE.EXTERNAL){
      this.inputForm.controls.companyId
        .patchValue([...this.companyListExtChecked.map(item => item.companyId.toUpperCase()), 0]);
        this.inputForm.controls.companyName
        .patchValue([...this.companyListExtChecked.map(item => item.companyName.toUpperCase()), 0]);
        this.engineModelMap.filter(row => {
          this.engineModelDropResult.push(row.value);        
    
        });
        const convertToArray = [].concat(...this.engineModelDropResult);    
        this.engineModelList = this.removeDuplicates(convertToArray);        
        this.engineModelSearch('');   
    } 
    if((this.userRole !== USERTYPE.EXTERNAL)){
        if (this.allSelected.selected) {
          this.inputForm.controls.companyId
          .patchValue([...this.companyList.map(item => item.companyId), 0]);
          this.inputForm.controls.companyName
          .patchValue([...this.companyList.map(item => item.companyName), 0]);
          this.engineModelMap.filter(row => {
          this.engineModelDropResult.push(row.value); 
        });
          const convertToArray = [].concat(...this.engineModelDropResult);    
          this.engineModelList = this.removeDuplicates(convertToArray);        
          this.engineModelSearch('');
        } else {
          this.engineModelArray =[];
          this.inputForm.controls.companyId.patchValue([]);
          this.inputForm.controls.companyName.patchValue([]);   
        }
      }
    }
    removeDuplicates<T>(array: T[]): T[] {
      const seen = new Set<T>();
      return array.filter(item => {
        if (seen.has(item)) {
          return false;
        } else {
          seen.add(item);
          return true;
        }
      });
    }
}