
import {
  // multipleEmailValidator,
  requiredFiledValidator
} from './../../../common/constants/customValidator';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterSampleService } from './../../../service/register-sample.service';
import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { RegisterSampleModel } from 'src/app/View/model/registerSampleModel';
import { DatePipe } from '@angular/common';
import { getFilterData } from '../../../common/constants/utils';
import { mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AnalyzeSampleInformationService } from '../../analyze-sample/analyze-sample-information/analyze-sample-information.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-register-sample',
  templateUrl: './add-edit-register-sample.component.html',
  styleUrls: ['./add-edit-register-sample.component.css'],
})
export class AddEditRegisterSampleComponent implements OnInit, AfterViewChecked, OnDestroy {
  readonly USERTYPE = USERTYPE;
  buttonText = 'Save';
  openModal: boolean = false;
  analysisScreenData: any;
  minDate: Date;
  maxDate: Date;
  userRole: string;
  isEditClick: boolean = false;
  analysisScreenSubscription: Subscription;
  // isEditAndApproveClick: boolean = false;
  isDisplayAddToFluidMasterData: boolean = false;
  isDisplayAddToFilterMasterData: boolean = false;
  registerSampleColumns = [];
  title: string = '';
  unitIdList = [];
  brandList = [];
  cancelHoldButton = false;
  sampleFluidBrand: any = '';
  brandListArr = [];
  productList = [];
  productListArr = [];
  sampleFluidProduct: any;
  filterProductistArr = [];
  gradeList = [];
  notListed: string = 'Not Listed#';
  filterBrandList = [];
  filterProductist = [];
  sampleFilterProduct: any;
  equipInfo: any;
  recentSample: any;
  sampleInfo: any;
  registerInfo: any;
  yesNoList = [
    { id: 1, value: 'Yes', code: 'Y' },
    { id: 2, value: 'No', code: 'N' },
  ];

  isSubmitted = false;
  dropDownData: any;
  filterDropDownData: any;
  regexPattern = '/\-?\d*\.?\d{1,2}/';

  fluidBrandHasError = false;
  fluidProductHasError = false;
  fluidGradeHasError = false;
  filterBrandHasError = false;
  filterProductHasError = false;
  unitIdHasError = false;
  isFormValid = false;
  scrollToTop = false;
  editQRCode = false;
  unitMakeValueCaptured: any;
  engineMakeValueCaptured: any;
  engineModelValueCaptured: any;
  applicationTypeValueCaptured: any;


  dataArray = new Array();
  registerOrReview = 'Register';
  disabled: boolean = true;
  isChecked;
  isSerRelatedSample: boolean;
  isFluidBaseline: boolean;
  bmsWorkOrderHasError = false;
  isDisplayAddToUnitMasterData: boolean = false;
  unitMakeHasError = false;
  engineMakeHasError = false;
  engineModelHasError = false;
  applicationTypeHasError = false;
  FluidDistanceHasZeroError = false;
  /* Received date issue fix - 14/11/22*/
  recievedDateLabAdmin: any = "";

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(';').map(e => e.trim());
    const forbidden = emails.some(email => Validators.email(new UntypedFormControl(email)));
    return forbidden ? { 'contactEmail': { value: control.value } } : null;
  };
  holdForm = new UntypedFormGroup({
    unitDistanceCheckBox: new UntypedFormControl(''),
    unitCheckBox: new UntypedFormControl(''),
    fluidDistanceCheckBox: new UntypedFormControl(''),
    fluidinfoCheckBox: new UntypedFormControl(''),
    filterinfoCheckBox: new UntypedFormControl(''),
    note: new UntypedFormControl('', [Validators.required])
  });
  associateWithEquipmentForm = new UntypedFormGroup(
    {
      //  customerName:new FormControl(''),
      unitId: new UntypedFormControl('', [Validators.required]),
      secondaryUnitId: new UntypedFormControl(''),
      gsn: new UntypedFormControl(''),
      equipmentId: new UntypedFormControl(''),
      esn: new UntypedFormControl(''),
      distanceUOM: new UntypedFormControl(''),
      lastUpdatedDate: new UntypedFormControl(''),
      fluidBrand: new UntypedFormControl(''),
      fluidProduct: new UntypedFormControl(''),
      fluidGrade: new UntypedFormControl(''),
      sampledLocation: new UntypedFormControl(''),
      geographicLocation: new UntypedFormControl(''),
      filterBrand: new UntypedFormControl(''),
      filterProduct: new UntypedFormControl(''),
      contactName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z. ]*$')]),
      contactPhoneNumber: new UntypedFormControl('', [Validators.required]),
      contactEmail: new UntypedFormControl('', [Validators.required, this.commaSepEmail]),
      remark: new UntypedFormControl(''),
      reasonComment: new UntypedFormControl(''),
      sampleId: new UntypedFormControl(''),
      fluidDistance: new UntypedFormControl(''),
      unitDistance: new UntypedFormControl('')
    }
    //,    { validators: multipleEmailValidator }
  );
  registerSampleForm = new UntypedFormGroup(
    {
      sampledDate: new UntypedFormControl('', [Validators.required]),
      unitDistance: new UntypedFormControl('', [Validators.required, Validators.maxLength(9), Validators.pattern('^[0-9]+$')]),
      fluidDistance: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(9), Validators.pattern('^[0-9]+$')
      ]),
      fluidBrand: new UntypedFormControl(''),
      brandCheckBox: new UntypedFormControl(false),
      dummyBrandName: new UntypedFormControl(''),
      fluidProduct: new UntypedFormControl(''),
      productCheckBox: new UntypedFormControl(false),
      dummyProductName: new UntypedFormControl(''),
      fluidGrade: new UntypedFormControl(''),
      gradeCheckBox: new UntypedFormControl(false),
      dummyGradeName: new UntypedFormControl(''),
      bmsWorkOrder: new UntypedFormControl(''),
      jobNumber: new UntypedFormControl(''),
      isSerRelatedSample: new UntypedFormControl(false),
      isFluidBaseline: new UntypedFormControl(false),
      sampledLocation: new UntypedFormControl(''),
      geographicLocation: new UntypedFormControl(''),
      filterBrand: new UntypedFormControl(''),
      filterBrandCheckBox: new UntypedFormControl(false),
      dummyfilterBrandName: new UntypedFormControl(''),
      filterProduct: new UntypedFormControl(''),
      filterProductCheckBox: new UntypedFormControl(false),
      dummyfilterProductName: new UntypedFormControl(''),
      scaAdded: new UntypedFormControl(''),
      fluidChanged: new UntypedFormControl(''),
      fluidTopOff: new UntypedFormControl('', [Validators.maxLength(20)]),
      topOffAmt: new UntypedFormControl(''),
      mailerTraker: new UntypedFormControl(''),
      unitModel: new UntypedFormControl(''),
      unitMake: new UntypedFormControl('', [Validators.required]),
      engineMake: new UntypedFormControl(''),
      engineModel: new UntypedFormControl(''),
      applicationType: new UntypedFormControl('', [Validators.required]),
      unitMakeStatus: new UntypedFormControl(''),
      appTypeStatus: new UntypedFormControl(''),
      engMakeStatus: new UntypedFormControl(''),
      engModStatus: new UntypedFormControl(''),
      unitMakeId: new UntypedFormControl(''),
      engMakeModId: new UntypedFormControl(''),
      appTypeId: new UntypedFormControl(''),
      endOfDrainSample: new UntypedFormControl('')
      // rushThisFlag: new FormControl(false),
    },
    { validators: requiredFiledValidator }
  );
  registerSampleColumnsData: any[] = [];
  currentItem: RegisterSampleModel = new RegisterSampleModel();
  showMoreInfoItem: any;
  analysisType: string;
  qrCodeId: string;
  companyName: string;
  openRushedSampleModal: boolean = false;
  rushcheckbox: boolean = false;
  registrationUser: any = {};
  isDisplayApprove: boolean = false;
  QrInformationData: any;
  qrCodehasValue: any;
  qrCode: any;
  unitDistanceHasError: boolean = false;
  FluidDistanceHasError: boolean = false;
  FluidDistanceHasErrorneg: boolean = false;
  lastUnitDistance: boolean = false;
  fluidDistanceval: any;
  lastUnitDistanceval: any;
  IsDrainVal;
  group = new UntypedFormGroup({
    isEdit: new UntypedFormControl(false),
    qrCodeIdEditValue: new UntypedFormControl(''),
    qrCodeIdCompleteEditValue: new UntypedFormControl(''),
  });
  QRSampleData: string[];
  serialNumber: string;
  qrCodeSave: string;
  reasonComment: string;
  isOnHold: boolean = false;
  errorMessageForFluidDistance: any;
  messageDropdownInternal: any;
  isFirstTimeInternal: boolean = true;
  compnyIdforInternal: any;
  companyIdName: any;
  companyIdValue: any;
  companycomment: string;
  displaytextMsg: boolean = false;
  statusflag: boolean;
  currentstatus: string;
  objData: {
    // multipleEmailValidator,
    functionName: string; updatedBy: any; qrCodeId: string; companyId: any; companyName: string; analysisType: any; testLevel: any; prevQRCodeId: any;prevAnalysisType: string;
  };
  qrCodeValue: any;
  isInputShown: boolean;
  isInputShownForQrcode: boolean;
  testLevel: string;
  testLevelNew: string;
  //prevQRCodeId: any;
  prevQRCodeIdNew: any;
  behaviorObs: any;
  behaviorObsInternal: any;
  sampleId: string;
  orderId: any;
  componentId: any;
  sku: any;
  fluidBrand: any;
  fluidProduct: any;
  fluidGrade: any;
  isSerRelatedSamplecheck: any;
  prevAnalysisTypeNew: any;
  equipId: any;
  companyNameval: any;
  companyIdval: any;
  constructor(
    private registerSampleService: RegisterSampleService,
    private activatedRouter: ActivatedRoute,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private router: Router,
    private analyzeSampleInfoServ: AnalyzeSampleInformationService,
    private sharedService: SharedService
  ) {
    this.userRole = this.sharedService.getUserRole();
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.title = 'Register Sample';
      this.registerSampleColumns = [
        { code: 'qrCodeId', name: 'QR Code' },
        { code: 'sampleId', name: 'Sample ID' },
        { code: 'orderId', name: 'Order ID' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'companyId', name: 'Company ID' },
        { code: 'analysisType', name: 'Analysis Type' },
        { code: 'testLevel', name: 'Test Level' },
        { code: 'status', name: 'Status' },
      ];
    } else if (
      this.userRole === USERTYPE.LAB_ADMIN ||
      this.userRole === USERTYPE.DATA_SCIENTIST ||
      this.userRole == USERTYPE.DBU_BRANCH ||
      this.userRole == USERTYPE.DBU_FLUIDS ||
      this.userRole === USERTYPE.FLUIDWATCH_MANAGER
    ) {
      this.title = 'Review Sample';
      this.registerSampleColumns = [
        { code: 'qrCodeId', name: 'QR Code' },
        { code: 'sampleId', name: 'Sample ID' },
        { code: 'orderId', name: 'Order ID' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'companyId', name: 'Company ID' },
        { code: 'analysisType', name: 'Analysis Type' },
        { code: 'testLevel', name: 'Test Level' },
        { code: 'status', name: 'Status' },
      ];
    }
    //const currentYear = new Date().getFullYear();
    this.minDate = new Date(2021, 0, 1);
    this.maxDate = new Date();
    this.activatedRouter.data.subscribe((data) => {
      this.initData(data.data);
    });
  }

  initData(data: any) {
    //console.log('data', data);
    this.unitIdList = data.unitList;

    this.unitIdList.forEach((value) => {
      this.dataArray.push(value.componentId)
    });
    // console.log('this.dataArray', this.dataArray);
    // console.log('this.unitIdList', this.unitIdList);
    this.populateDropDown(data.dropDownData);
  }
  populateDropDown(data: any) {
    this.dropDownData = data.fluidCombination;
    this.filterDropDownData = data.filterCombination;
    this.setFluidDropDown();
    this.setFilterDropDown();
  }
  setFilterDropDown() {
    this.filterProductist = getFilterData(this.filterDropDownData, 'filterProduct', '', 'filterProduct');
    this.filterBrandList = getFilterData(this.filterDropDownData, 'filterBrand', '', 'filterBrand');
    this.filterProductistArr = this.filterProductist;
  }

  setFluidDropDown() {
    this.brandList = getFilterData(this.dropDownData, 'fluidBrand', '', 'fluidBrand');
    this.brandListArr = this.brandList;
    this.productList = getFilterData(this.dropDownData, 'fluidProduct', '', 'fluidProduct');
    this.productListArr = this.productList;
    this.gradeList = getFilterData(this.dropDownData, 'fluidGrade', '', 'fluidGrade');
  }

  onKeyFluidBrand(value) {
    this.brandListArr= [];
    this.selectSearchFluidBrand(value);
  }

  clearSearchFluidBrand() {
    this.brandListArr = this.brandList;
    this.sampleFluidBrand = '';
  }

  selectSearchFluidBrand(value:string){
    let filter = value.toLowerCase();
    for(let i = 0; i < this.brandList.length; i++ ){
      let option = this.brandList[i];
      if(option.toLowerCase().indexOf(filter) >= 0){
        this.brandListArr.push(option)
      }
    }
  }

  onKeyFluidProduct(value) {
    this.productListArr= [];
    this.selectSearchFluidProduct(value);
  }

  clearSearchFluidProduct() {
    this.productListArr = this.productList;
    this.sampleFluidProduct = '';
  }

  selectSearchFluidProduct(value:string){
    let filter = value.toLowerCase();
    for(let i = 0; i < this.productList.length; i++ ){
      let option = this.productList[i];
      if(option.toLowerCase().indexOf(filter) >= 0){
        this.productListArr.push(option)
      }
    }
  }

  onKeyFilterProduct(value) {
    this.filterProductistArr= [];
    this.selectSearchFilterProduct(value);
  }

  clearSearchFilterProduct() {
    this.filterProductistArr = this.filterProductist;
    this.sampleFilterProduct = '';
  }

  selectSearchFilterProduct(value:string){
    let filter = value.toLowerCase();
    for(let i = 0; i < this.filterProductist.length; i++ ){
      let option = this.filterProductist[i];
      if(option.toLowerCase().indexOf(filter) >= 0){
        this.filterProductistArr.push(option)
      }
    }
  } 
  ngOnInit() {
    this.checkAnalysisScreen();
    this.currentItem = JSON.parse(localStorage.getItem('registerSampleObject'));
    this.showMoreInfoItem = JSON.parse(localStorage.getItem('showMoreInfoDetails'));
    if (this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER
      || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      if (this.currentItem['isSampleReg'] == true) {
        if (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
          if (this.currentItem.companyId = []) {
            this.dataArray = [];
            this.companycomment = "Please select the Company Name."
          }
        } else {
          this.dataArray = [];
          this.companycomment = "Please select the Company Name."
        }
      }
      if (this.currentItem.status == 'Available') {
        this.editQRCode = true;
      }
    }

    if (this.userRole == USERTYPE.EXTERNAL) {
      if (this.currentItem.status == 'Available') {
        this.editQRCode = true;
      }
    }

    this.registrationUser = JSON.parse(localStorage.getItem('userInformation'));
    this.registerSampleColumnsData.push(this.currentItem);
    this.analysisType = this.currentItem.analysisType;
    this.qrCodeId = this.currentItem.qrCodeId;
    this.sampleId = this.currentItem.sampleId;
    this.companyName = this.currentItem.companyName;
    console.log('test data', this.currentItem);
    let isSerRelatedSamplecheck = ""
    if (this.userRole == USERTYPE.DBU_BRANCH) {
      this.isSerRelatedSamplecheck = this.registerSampleForm.controls.isSerRelatedSample.value ? 'Y' : '';
      this.registerSampleForm.patchValue({
        isSerRelatedSample: true,
      });
    }
    //  console.log('single data : ' + JSON.stringify(this.currentItem.analysisType));
    if (this.currentItem.recordAdded === true) {
      this.fetchData(true);

    } else {
      this.setFormEnableDisable();
    }
    if(this.currentItem.status == 'Hold' && this.currentItem.recordAdded === false){
      this.fetchData(true);
    }
    this.behaviorObsInternal = this.sharedService.currentChangeInternal.subscribe(message => {
      if (this.router.url == '/registerSample/addEditReviewSample') {
        if (this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
          this.messageDropdownInternal = message;
          if (this.isFirstTimeInternal == false) {
            this.recieveMessage(this.messageDropdownInternal)

          }
          //console.log(this.messageDropdownInternal);
          this.isFirstTimeInternal = false;
        }
      }
    })

    this.behaviorObs = this.sharedService.currentChange.subscribe(message => {
      if (this.router.url == '/registerSample/addEditReviewSample') {
        if (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
          this.messageDropdownInternal = message;
          if (this.isFirstTimeInternal == false) {
            this.recieveMessageForDBU(this.messageDropdownInternal)

          }
          //console.log(this.messageDropdownInternal);
          this.isFirstTimeInternal = false;
        }
      }
    })
  }

  recieveMessageForDBU(event) {
    this.alertService.clear();
    console.log(this.messageDropdownInternal);
    this.companyIdValue = this.messageDropdownInternal.customerId;
    this.companyIdName = this.messageDropdownInternal.customerName;
    const data = {
      companyId: this.companyIdValue,
    };
    this.currentItem.companyId = this.companyIdValue;
    this.currentItem.companyName = this.companyIdName;
    this.dataArray = [];
    this.unitIdList = [];
    console.log(this.companyIdName, this.companyIdValue);
    if (this.companyIdValue != '') {
      this.registerSampleService.getUnitListForInternal(data).subscribe((response) => {
        if (response.statusCode == "1") {
          this.unitIdList = response.equipmentList;
          for (let i = 0; i < response.equipmentList.length; i++) {
            this.dataArray.push(response.equipmentList[i].componentId);
          }
          this.companycomment = '';
        } else {
          this.alertService.error(response.statusMessage);
          this.companycomment = "Please select the Company Name."
        }
      }, err => {
        this.alertService.error(err);
      });
    }

  }
  recieveMessage(event) {
    // console.log(this.messageDropdownInternal);
    this.alertService.clear();
    this.companyIdValue = this.messageDropdownInternal.companyId;
    this.companyIdName = this.messageDropdownInternal.companyName;
    const data = {
      companyId: this.companyIdValue,
    };
    this.currentItem.companyId = this.companyIdValue;
    this.currentItem.companyName = this.companyIdName;

    this.dataArray = [];
    this.unitIdList = [];
    this.registerSampleService.getUnitListForInternal(data).subscribe((response) => {
      if (response.statusCode == "1") {
        this.unitIdList = response.equipmentList;
        for (let i = 0; i < response.equipmentList.length; i++) {
          this.dataArray.push(response.equipmentList[i].componentId);
        }
        this.companycomment = '';
      } else {
        this.alertService.error(response.statusMessage);
        this.companycomment = "Please select the Company Name."
      }
    }, err => {
      this.alertService.error(err);
    });
  }

  onKey(value) {
    this.dataArray = [];
    this.selectSearch(value);

  }

  selectSearch(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.unitIdList.length; i++) {
      let option = this.unitIdList[i].componentId;
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.dataArray.push(option);
      }
    }

    // console.log(this.dataArray,"dataarray")
  }

  clearSearch() {
    this.dataArray = [];
    this.selectSearch("");
  }


  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }
  setFormEnableDisable() {
    if (this.userRole === USERTYPE.EXTERNAL) {
      if ((this.currentItem.recordAdded === true) ||
        (this.currentItem.recordAdded === false && this.currentItem.status === "Hold")
      ) {
        this.buttonText = 'Edit';
        this.registerSampleForm.disable();
        this.associateWithEquipmentForm.disable();
      } else {
        this.buttonText = 'Save';
        this.sharedService.getExternalUserInfo().subscribe((extData) => {
          this.associateWithEquipmentForm.patchValue({
            contactName: extData.Name,
            contactPhoneNumber: extData.Phone,
            contactEmail: extData.Email
          });
        });

      }
    } else if (
      this.userRole === USERTYPE.LAB_ADMIN ||
      this.userRole === USERTYPE.DATA_SCIENTIST ||
      this.userRole == USERTYPE.FLUIDWATCH_MANAGER
    ) {
      if (this.currentItem.status === 'Available') {
        if (this.isEditClick == true) {
          this.registerSampleForm.disable();
          this.associateWithEquipmentForm.disable();
        } else {
          this.registerSampleForm.enable();
          this.associateWithEquipmentForm.enable();
        }
      } else {
        this.isEditClick = true;
        this.registerSampleForm.disable();
        this.associateWithEquipmentForm.disable();
      }
    }
  }
  fetchData(isSampleIdProvided: boolean) {
    let obj;
    if (isSampleIdProvided === true) {
      obj = {
        analysisType: this.currentItem.analysisType,
        sampleId: this.currentItem.sampleId,
        userRole: this.userRole,
        status: this.currentItem.status,
        qrCodeId: this.currentItem.qrCodeId,
      };
      obj.userRole = this.sharedService.getIntOrExtUser();
    } else {
      obj = {
        analysisType: this.currentItem.analysisType,
        unitId: this.associateWithEquipmentForm.value.unitId,
        userRole: this.userRole,
        qrCodeId: this.currentItem.qrCodeId,
      };
      obj.userRole = this.sharedService.getIntOrExtUser();
    }
    this.registerSampleService.fetchData(obj).subscribe((data) => {
      console.log('fetchdata respose 1 ');
      console.log(data["sampleInfo"]["recievedDate"]);
      this.recievedDateLabAdmin = data["sampleInfo"]["recievedDate"];
      this.equipInfo = data.equipInfo;
      this.recentSample = data.recentSample;
      this.analysisType = this.currentItem.analysisType;
      if(this.currentItem.status == "Hold"){
      this.reasonComment = data.reasonComment; 
      this.isDisplayApprove = false;
      this.getRequestObject(true).submit = true;
      }
      this.lastUnitDistanceval = this.recentSample.unitDistance;
      this.sampleInfo = data.sampleInfo;
      this.populateDetails(data.equipInfo, data.recentSample, data.sampleInfo);
      if (isSampleIdProvided === true) {
        this.associateWithEquipmentForm.patchValue({
          unitId: data.equipInfo.componentId,
        });
      }
    });
    if (this.lastUnitDistanceval != "" || this.lastUnitDistanceval == "") {
      this.lastUnitDistance = true;
      this.displaytextMsg = false;

    }
  }
  setAddToMasterDatabaseButton() {
    this.isDisplayAddToFluidMasterData = false;
    this.isDisplayAddToFilterMasterData = false;
    if (this.userRole !== USERTYPE.EXTERNAL) {
      for (const name in this.registerSampleForm.controls) {
        if (
          name.toLowerCase() === 'brandCheckBox'.toLowerCase() ||
          name.toLowerCase() === 'productCheckBox'.toLowerCase() ||
          name.toLowerCase() === 'gradeCheckBox'.toLowerCase()
        ) {
          if (this.registerSampleForm.controls[name].value === true) {
            this.isDisplayAddToFluidMasterData = true;
          }
        }

        if (
          name.toLowerCase() === 'filterBrandCheckBox'.toLowerCase() ||
          name.toLowerCase() === 'filterProductCheckBox'.toLowerCase()
        ) {
          if (this.registerSampleForm.controls[name].value === true) {
            this.isDisplayAddToFilterMasterData = true;
          }
        }
      }
    }
  }
  isServiceCheckBoxChanged(event, controlName: string) {
    if (event.target.checked === true) {
      this.registerSampleForm.patchValue({
        [controlName]: this.registerSampleForm.controls[controlName].value,
      });
      this.registerSampleForm.controls[controlName].setValidators([Validators.required]);
      this.registerSampleForm.controls[controlName].touched;
      this.registerSampleForm.controls[controlName].updateValueAndValidity();
    } else {
      this.registerSampleForm.controls[controlName].clearValidators();
      this.registerSampleForm.controls[controlName].updateValueAndValidity();
    }
  }

  onCheckBoxChanged(event, controlName: string, dummyControlName: string, isFluid: boolean) {
    //console.log('event : ' + event);
    if (event.target.checked === true) {
      this.registerSampleForm.patchValue({
        [controlName]: '',
      });
      this.registerSampleForm.controls[dummyControlName].setValidators([Validators.required]);
      this.registerSampleForm.controls[dummyControlName].updateValueAndValidity();
      if (isFluid) {
        this.isDisplayAddToFluidMasterData = true;
      } else {
        this.isDisplayAddToFilterMasterData = true;
      }
      this.registerSampleForm.controls[controlName].disable();
    } else {
      this.registerSampleForm.controls[dummyControlName].clearValidators();
      this.registerSampleForm.controls[dummyControlName].updateValueAndValidity();

      this.setAddToMasterDatabaseButton();
      this.registerSampleForm.patchValue({
        [dummyControlName]: '',
      });
      this.registerSampleForm.controls[controlName].enable();
    }
    /*if (controlName === 'fluidBrand') {
      this.productList = getFilterData(this.dropDownData, 'fluidProduct', '', 'fluidProduct');
      this.gradeList = getFilterData(this.dropDownData, 'fluidGrade', '', 'fluidGrade');
    }
    if (controlName === 'fluidProduct') {
      this.gradeList = getFilterData(this.dropDownData, 'fluidGrade', '', 'fluidGrade');
    }*/
    if (controlName === 'filterBrand') {
      this.filterProductist = getFilterData(this.filterDropDownData, 'filterProduct', '', 'filterProduct');
      this.filterProductistArr = this.filterProductist;
    }
  }

  onFluidDropDownSelect(inputField) {
    let result = [];
    let fluidBrand = [];
    let fluidProduct = [];
    let fluidGrade = [];
    for (let i = 0; i < this.dropDownData.length; i++) {
      if (this.registerSampleForm.controls.fluidBrand.value == '' || this.registerSampleForm.controls.fluidBrand.value == null ||
        this.registerSampleForm.controls.fluidBrand.value == undefined || this.registerSampleForm.controls.fluidBrand.value == this.dropDownData[i].fluidBrand) {
        if (this.registerSampleForm.controls.fluidProduct.value == '' || this.registerSampleForm.controls.fluidProduct.value == null ||
          this.registerSampleForm.controls.fluidProduct.value == undefined || this.registerSampleForm.controls.fluidProduct.value == this.dropDownData[i].fluidProduct) {
          if (this.registerSampleForm.controls.fluidGrade.value == '' || this.registerSampleForm.controls.fluidGrade.value == null ||
            this.registerSampleForm.controls.fluidGrade.value == undefined || this.registerSampleForm.controls.fluidGrade.value == this.dropDownData[i].fluidGrade) {
            result.push(this.dropDownData[i]);
          }
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      fluidBrand.push(result[i].fluidBrand);
    }
    this.brandList = Array.from(new Set(fluidBrand));
    this.brandListArr = this.brandList;

    for (let i = 0; i < result.length; i++) {
      fluidProduct.push(result[i].fluidProduct);
    }
    this.productList = Array.from(new Set(fluidProduct));
    this.productListArr = this.productList;

    for (let i = 0; i < result.length; i++) {
      fluidGrade.push(result[i].fluidGrade);
    }
    this.gradeList = Array.from(new Set(fluidGrade));

    if (this.gradeList.length == 0 || this.gradeList.length == 0 || this.gradeList.length == 0) {
      if (inputField == 'fluidBrand') {
        this.registerSampleForm.patchValue({
          fluidProduct: '',
          fluidGrade: ''
        });
      } else if (inputField == 'fluidProduct') {
        this.registerSampleForm.patchValue({
          fluidBrand: '',
          fluidGrade: ''
        });
      } else if (inputField == 'fluidGrade') {
        this.registerSampleForm.patchValue({
          fluidBrand: '',
          fluidProduct: ''
        });
      }
      this.onFluidDropDownSelect('none');
    }
  }

  onBrandSelected() {
    this.onFluidDropDownSelect('fluidBrand');
    //this.productList = getFilterData(this.dropDownData, 'fluidBrand', this.registerSampleForm.value.fluidBrand, 'fluidProduct');
    //this.gradeList = getFilterData(this.dropDownData, 'fluidBrand', this.registerSampleForm.value.fluidBrand, 'fluidGrade');
    this.registerSampleForm.controls.fluidBrand.disable();
    this.registerSampleForm.controls.brandCheckBox.disable();

  }

  onProductSelected() {
    this.onFluidDropDownSelect('fluidProduct');
    //this.gradeList = getFilterData(this.dropDownData, 'fluidProduct', this.registerSampleForm.value.fluidProduct, 'fluidGrade');
    //this.brandList = getFilterData(this.dropDownData, 'fluidProduct', this.registerSampleForm.value.fluidProduct, 'fluidBrand');
    this.registerSampleForm.controls.fluidProduct.disable();
    this.registerSampleForm.controls.productCheckBox.disable();
  }

  onGradeSelected() {
    this.onFluidDropDownSelect('fluidGrade');
    //this.productList = getFilterData(this.dropDownData, 'fluidGrade', this.registerSampleForm.value.fluidGrade, 'fluidProduct');
    //this.brandList = getFilterData(this.dropDownData, 'fluidGrade', this.registerSampleForm.value.fluidGrade, 'fluidBrand');
    this.registerSampleForm.controls.fluidGrade.disable();
    this.registerSampleForm.controls.gradeCheckBox.disable();
  }
  onFilterBrand() {

    this.filterProductist = getFilterData(this.filterDropDownData, 'filterBrand', this.registerSampleForm.value.filterBrand, 'filterProduct');
    this.filterProductistArr = this.filterProductist;
    this.registerSampleForm.controls.filterBrand.disable();
    this.registerSampleForm.controls.filterBrandCheckBox.disable();
  }

  onFilterProduct() {

    this.filterBrandList = getFilterData(this.filterDropDownData, 'filterProduct', this.registerSampleForm.value.filterProduct, 'filterBrand');
    this.registerSampleForm.controls.filterProduct.disable();
    this.registerSampleForm.controls.filterProductCheckBox.disable();
  }

  onCopyDataClick() {
    let items = ['fluidBrand', 'fluidProduct', 'fluidGrade', 'filterBrand', 'filterProduct'];
    let cbItems = ['brandCheckBox', 'productCheckBox', 'gradeCheckBox', 'filterBrandCheckBox', 'filterProductCheckBox'];
    for (let item of items) {
      if (!this.associateWithEquipmentForm.controls[item].value.includes(this.notListed)) {
        this.registerSampleForm.patchValue({
          [item]: this.associateWithEquipmentForm.controls[item].value
        });
      } else {
        this.registerSampleForm.patchValue({
          [item]: ''
        });
      }
    }



    this.registerSampleForm.patchValue({
      // sampledDate: this.associateWithEquipmentForm.value.lastUpdatedDate,
      //fluidBrand: this.associateWithEquipmentForm.value.fluidBrand,
      //fluidProduct: this.associateWithEquipmentForm.value.fluidProduct,
      //fluidGrade: this.associateWithEquipmentForm.value.fluidGrade,      
      //filterBrand: this.associateWithEquipmentForm.value.filterBrand,
      //filterProduct: this.associateWithEquipmentForm.value.filterProduct,
      sampledLocation: this.associateWithEquipmentForm.value.sampledLocation,
      filterBrandCheckBox: false,
      filterProductCheckBox: false,
      dummyfilterBrandName: '',
      dummyfilterProductName: '',
      brandCheckBox: false,
      productCheckBox: false,
      gradeCheckBox: false,
      dummyBrandName: '',
      dummyProductName: '',
      dummyGradeName: ''
    });

    this.setFluidDropDown();
    this.setFilterDropDown();
    this.setDropDownValidation();
    this.validateFluidDropDown();

    for (let ind in items) {
      this.registerSampleForm.controls[items[ind]].enable();
      this.registerSampleForm.controls[cbItems[ind]].enable();
    }
  }



  populateDetails(equipInfo: any, recentSample: any, sampleInfo: any) {
    if (sampleInfo.isFluidBaseline == 'Y') {
      this.isFluidBaseline = true;
    } else {
      this.isFluidBaseline = false;
    }
    if (sampleInfo.isSerRelatedSample == 'Y') {
      this.isSerRelatedSample = true;
    } else {
      this.isSerRelatedSample = false;
    }
    if (sampleInfo.endOfDrainSample == 'Y') {
      var endOfDrainSample = "IsDrainYes";
    } else {
      var endOfDrainSample = "IsDrainNo";
    }
    this.associateWithEquipmentForm.patchValue({
      // unitId: equipInfo.unitId,
      equipmentId: equipInfo.equipId,
      esn: equipInfo.esn,
      gsn: equipInfo.gsn,
      //  primaryunitId: equipInfo.primaryunitId,
      registrationUser: sampleInfo.registrationUser,
      secondaryUnitId: equipInfo.secondaryUnitId,
      distanceUOM: equipInfo.distanceUOM,
      lastUpdatedDate: recentSample.lastSampledDate,
      fluidBrand: recentSample.fluidBrand ? recentSample.fluidBrand : '',
      fluidProduct: recentSample.fluidProduct ? recentSample.fluidProduct : '',
      fluidGrade: recentSample.fluidGrade ? recentSample.fluidGrade : '',
      sampledLocation: recentSample.sampledLocation,
      geographicLocation: recentSample.geographicLocation,
      filterBrand: recentSample.filterBrand,
      filterProduct: recentSample.filterProduct,
      sampleId: recentSample.sampleId,
      fluidDistance: recentSample.fluidDistance,
      unitDistance: recentSample.unitDistance,
      contactName: sampleInfo.contactName,
      contactPhoneNumber: sampleInfo.phNumber,
      contactEmail: sampleInfo.email,
      remark: sampleInfo.remarks,
    });
    this.registerSampleForm.patchValue({
      sampledDate: new Date(sampleInfo.lastSampledDate),
      unitDistance: sampleInfo.unitDistance,
      fluidDistance: sampleInfo.fluidDistance,
      bmsWorkOrder: sampleInfo.bmsWorkOrder,
      jobNumber: sampleInfo.jobNumber,
      sampledLocation: sampleInfo.sampledLocation,
      geographicLocation: sampleInfo.geographicLocation,
      scaAdded: sampleInfo.isSCAAdded,
      fluidChanged: sampleInfo.isFluidChanged,
      fluidTopOff: sampleInfo.isFluidTopOff,
      topOffAmt: sampleInfo.topOffAmt,
      mailerTraker: sampleInfo.mailTrackingNo,
      isFluidBaseline: this.isFluidBaseline,
      isSerRelatedSample: this.isSerRelatedSample,
      unitModel: equipInfo.unitModel,
      unitMake: equipInfo.unitMake,
      applicationType: equipInfo.applicationType,
      engineMake: equipInfo.engineMake,
      engineModel: equipInfo.engineModel,
      unitMakeStatus: equipInfo.unitMakeStatus,
      appTypeStatus: equipInfo.appTypeStatus,
      engMakeModStatus: equipInfo.engMakeModStatus,
      unitMakeId: equipInfo.unitMakeId,
      engMakeModId: equipInfo.engMakeModId,
      appTypeId: equipInfo.appTypeId,
      endOfDrainSample: endOfDrainSample,
    });
    this.unitMakeValueCaptured = equipInfo.unitMake;
    this.applicationTypeValueCaptured = equipInfo.applicationType;
    this.engineMakeValueCaptured = equipInfo.engineMake;
    this.engineModelValueCaptured = equipInfo.engineModel;
    this.setDropDowns(true, true);
    this.setAddToMasterDatabaseButton();
    this.setFormEnableDisable();
    this.OnloadNotlistedEnableDisable();
  }
  setDropDownFormControl(fieldValue: string, controlName: string, dummyControlName: string, fieldCheckBoxName: string) {
    let checkBox = false;
    let dummyValue = '';
    this.registerSampleForm.controls[fieldCheckBoxName].enable();
    if (this.sampleInfo[fieldValue].toString().indexOf(this.notListed) !== -1) {
      checkBox = true;
      dummyValue = this.sampleInfo[fieldValue].toString().replace(this.notListed, '');
      this.registerSampleForm.controls[dummyControlName].enable();
      this.registerSampleForm.patchValue({
        [fieldCheckBoxName]: checkBox,
        [dummyControlName]: dummyValue
      });
      this.registerSampleForm.controls[dummyControlName].setValidators([Validators.required]);
      this.registerSampleForm.controls[dummyControlName].updateValueAndValidity();
      this.registerSampleForm.controls[controlName].disable();
    } else {
      this.registerSampleForm.controls[fieldValue].enable();
      this.registerSampleForm.patchValue({
        [controlName]: this.sampleInfo[fieldValue]
      });
      this.registerSampleForm.controls[dummyControlName].clearValidators();
      this.registerSampleForm.controls[dummyControlName].updateValueAndValidity();
    }
  }
  setDropDowns(isFluidReset: boolean, isFilterReset: boolean) {
    if (isFluidReset === true) {
      this.setDropDownFormControl('fluidBrand', 'fluidBrand', 'dummyBrandName', 'brandCheckBox');
      this.setDropDownFormControl('fluidProduct', 'fluidProduct', 'dummyProductName', 'productCheckBox');
      this.setDropDownFormControl('fluidGrade', 'fluidGrade', 'dummyGradeName', 'gradeCheckBox');
    }
    if (isFilterReset === true) {
      this.setDropDownFormControl('filterBrand', 'filterBrand', 'dummyfilterBrandName', 'filterBrandCheckBox');
      this.setDropDownFormControl('filterProduct', 'filterProduct', 'dummyfilterProductName', 'filterProductCheckBox');
    }
  }

  onUnitIdSelected() {
    //this.fetchData(false);
    this.engineMakeHasError = false;
    this.engineModelHasError = false;
    let obj = {
      analysisType: this.currentItem.analysisType,
      unitId: this.associateWithEquipmentForm.value.unitId,
      userRole: this.userRole,
      qrCodeId: this.currentItem.qrCodeId,
      sampleId: this.currentItem.sampleId,
      equipmentId: ''
    };

    this.unitIdList.forEach((value) => {
      if (value.componentId == this.associateWithEquipmentForm.controls['unitId'].value) {
        obj.equipmentId = value.equipmentId;
      }
    });

    obj.userRole = this.sharedService.getIntOrExtUser();
    this.registerSampleService.fetchData(obj).subscribe((data) => {
      this.equipInfo = data.equipInfo;
      let recentSample = data.recentSample;
      this.associateWithEquipmentForm.patchValue({
        unitId: this.equipInfo.componentId,
        equipmentId: this.equipInfo.equipId,
        secondaryUnitId: this.equipInfo.secondaryUnitId,
        gsn: this.equipInfo.gsn,
        esn: this.equipInfo.esn,
        distanceUOM: this.equipInfo.distanceUOM,
        sampleId: recentSample.sampleId,
        fluidDistance: recentSample.fluidDistance,
        unitDistance: recentSample.unitDistance,
        sampledLocation: recentSample.sampledLocation,
        geographicLocation: recentSample.geographicLocation,
        fluidBrand: recentSample.fluidBrand ? recentSample.fluidBrand : '',
        fluidProduct: recentSample.fluidProduct ? recentSample.fluidProduct : '',
        fluidGrade: recentSample.fluidGrade ? recentSample.fluidGrade : '',
        filterBrand: recentSample.filterBrand,
        filterProduct: recentSample.filterProduct,
      });
      this.registerSampleForm.patchValue({
        unitModel: this.equipInfo.unitModel,
        unitMake: this.equipInfo.unitMake,
        applicationType: this.equipInfo.applicationType,
        engineMake: this.equipInfo.engineMake,
        engineModel: this.equipInfo.engineModel,
        unitMakeStatus: this.equipInfo.unitMakeStatus,
        appTypeStatus: this.equipInfo.appTypeStatus,
        engMakeStatus: this.equipInfo.engMakeStatus,
        engModStatus: this.equipInfo.engModStatus,
        equipId: this.equipInfo.equipId
      });
      this.lastUnitDistanceval = recentSample.unitDistance;
      if (this.lastUnitDistanceval != "" || this.lastUnitDistanceval == "") {
        this.lastUnitDistance = true;
        this.displaytextMsg = false;

      }
      if (this.equipInfo.unitMakeStatus !== 'N' || this.registerSampleForm.controls['unitMake'].value == "") {
        this.registerSampleForm.controls['unitMake'].disable();
      } else {
        this.registerSampleForm.controls['unitMake'].enable();
      }

      if (this.equipInfo.appTypeStatus !== 'N' || this.registerSampleForm.controls['applicationType'].value == '') {
        this.registerSampleForm.controls['applicationType'].disable();
      }
      else {
        this.registerSampleForm.controls['applicationType'].enable();
      }

      if (this.registerSampleForm.controls['engineModel'].value !== '' && this.registerSampleForm.controls['engineMake'].value !== '' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y') {
        this.registerSampleForm.controls['engineModel'].disable();
        this.registerSampleForm.controls['engineMake'].disable();
      }
      else if (this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N' && this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '') {
        this.registerSampleForm.controls['engineModel'].enable();
        this.registerSampleForm.controls['engineMake'].enable();
      } else if (this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N' && this.registerSampleForm.controls['engineModel'].value == '' && this.registerSampleForm.controls['engineMake'].value == '') {
        this.registerSampleForm.controls['engineModel'].enable();
        this.registerSampleForm.controls['engineMake'].enable();
      } else if ((this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
        this.registerSampleForm.controls['engineModel'].disable();
        this.registerSampleForm.controls['engineMake'].enable();
      } else if ((this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
        this.registerSampleForm.controls['engineModel'].enable();
        this.registerSampleForm.controls['engineMake'].disable();
      }

      this.registerSampleForm.controls['unitModel'].disable();
      if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y')) {
        this.isDisplayAddToUnitMasterData = false;
      }
      else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = false;
      }

      else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
        this.isDisplayAddToUnitMasterData = true;
      } else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      // else if((this.equipInfo.unitMake != "" || this.equipInfo.applicationType == "" || this.equipInfo.engineMake != "" || this.equipInfo.engineModel != "") && (this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'N' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y')){
      //   this.isDisplayAddToUnitMasterData = false;
      // }
      // else if((this.equipInfo.unitMake = "" || this.equipInfo.applicationType != "" || this.equipInfo.engineMake != "" || this.equipInfo.engineModel != "") && (this.equipInfo.unitMakeStatus == 'N' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y')){
      //   this.isDisplayAddToUnitMasterData = false;
      // }
      // else if((this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') || (this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y') || (this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')){
      //   this.isDisplayAddToUnitMasterData = true;
      // }
      // else if((this.equipInfo.unitMake == "" || this.equipInfo.applicationType == "" || this.equipInfo.engineMake == "" || this.equipInfo.engineModel =="" ) || (this.equipInfo.unitMakeStatus == 'N' && this.equipInfo.appTypeStatus == 'N' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')){
      //   this.isDisplayAddToUnitMasterData = true;
      // }
      else {
        this.isDisplayAddToUnitMasterData = true;
      }
    });

  }

  onReceivedClick(){
    this.statusflag = true;
    this.isFormValid = true;
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.registerSampleForm.controls['bmsWorkOrder'].disable();
    }
    for (var i in this.registerSampleForm.controls) {
      this.registerSampleForm.controls[i].markAsTouched();
    }
    for (var i in this.associateWithEquipmentForm.controls) {
      this.associateWithEquipmentForm.controls[i].markAsTouched();
    }
    this.isSubmitted = true;
    const associateFormInvalid = this.checkIsFormValid(this.associateWithEquipmentForm);
    const registerSampleInvalid = this.checkIsRegSampleFormValid(this.registerSampleForm);
    if (associateFormInvalid.length > 0 || registerSampleInvalid.length > 0) {
      this.isFormValid = false;
    }
    this.validateFluidDropDown();
    this.validateUnitId();
    if (this.analysisType != 'Diesel Fuel') {
      this.validatefluiddistanceval();
    }
     if(this.statusflag == true  && this.isFormValid === true) { 
      if (this.currentItem.recordAdded === false) {
        this.registerSampleService
          .saveRegisterSample(this.getRequestObject(true))
          .subscribe((response) => {
            if (response.statusCode === '1') {
              this.alertService.success(response.statusMessage);
            //  this.openClosePopup(true);
              this.currentItem.recordAdded = true;
              this.onReceivedClick();
            } else {
              this.alertService.error(response.statusMessage);
            }
          });
      } else { 
      this.currentstatus= 'Submitted';    
      this.registerSampleService
      .updateRegisterSample(this.getRequestObject(true))
      .subscribe((response) => {
        if (response.statusCode === '1') {      
                 this.orderId = response.orderId;
                 this.componentId = response.componentId;
                 this.sampleId = response.sampleId;
                 this.qrCodeId = response.qrCodeId;
                 this.sku= response.sku;
                 this.fluidBrand = this.registerSampleForm.controls.fluidBrand.value;
                 this.fluidProduct = this.registerSampleForm.controls.fluidProduct.value;
                 this.fluidGrade = this.registerSampleForm.controls.fluidGrade.value;
                 console.log('res1', response);
                 console.log('res2', this.fluidBrand, this.fluidGrade, this.fluidProduct);
                 if(this.currentstatus == 'Submitted'){
                   this.registerOrReview = 'Review';
               let winRespObservable = this.analyzeSampleInfoServ.getAuthToken()
               .pipe(mergeMap(authRes => this.analyzeSampleInfoServ.winlimsUpdate(authRes,'', this.sampleId, this.orderId,this.sku, this.qrCodeId, 'Received',
                 this.fluidBrand, this.fluidGrade, this.fluidProduct
               )));
               
               winRespObservable.subscribe((winResp) => {
                 console.log('pppp'+this.sampleId)
                 this.updateLogs(environment.winlimsUpdateUrl, this.sampleId, this.orderId, this.qrCodeId, this.sku, 'Received', '', winResp, false, 
                   this.fluidBrand, this.fluidGrade, this.fluidProduct
                 ); 
                 this.openClosePopup(true);
                 if (winResp.Header.Status == 'SUCCESS') {
                   this.alertService.success(response.statusMessage);
                 } else {
                   this.alertService.error('Error occured while updating to winlims');
                 }
               }, (error) => {
                 this.updateLogs(environment.winlimsUpdateUrl, this.sampleId, this.orderId, this.qrCodeId, this.sku, 'Received', '', error, false,
                   this.fluidBrand, this.fluidGrade, this.fluidProduct
                 ); 
                 this.alertService.error('Error occured while updating to winlims');
                 this.scrollToTop = true;
               });
             }else{
               this.alertService.success(response.statusMessage);
               this.openClosePopup(true);
             }              
           } else {
             this.alertService.error(response.statusMessage);
           }
         });
      }
     }else{
      this.alertService.error('Form is invalid');
    }
    this.scrollToTop = true;
   }

  onSubmitClick() {
    this.isFormValid = true;
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.registerSampleForm.controls['bmsWorkOrder'].disable();
    }
    for (var i in this.registerSampleForm.controls) {
      this.registerSampleForm.controls[i].markAsTouched();
    }
    for (var i in this.associateWithEquipmentForm.controls) {
      this.associateWithEquipmentForm.controls[i].markAsTouched();
    }
    this.isSubmitted = true;
    const associateFormInvalid = this.checkIsFormValid(this.associateWithEquipmentForm);
    const registerSampleInvalid = this.checkIsRegSampleFormValid(this.registerSampleForm);
    if (associateFormInvalid.length > 0 || registerSampleInvalid.length > 0) {
      this.isFormValid = false;
    }
    this.validateFluidDropDown();
    this.validateUnitId();
    if (this.analysisType != 'Diesel Fuel') {
      this.validatefluiddistanceval();
     }
     if (this.isFormValid === true) {      
       if (this.currentItem.recordAdded === false) {
         this.registerSampleService
           .saveRegisterSample(this.getRequestObject(true))
           .subscribe((response) => {
             if (response.statusCode === '1') {
               this.alertService.success(response.statusMessage);
             //  this.openClosePopup(true);
               this.currentItem.recordAdded = true;
               this.onSubmitClick();
             } else {
               this.alertService.error(response.statusMessage);
             }
           });
       } else {
         this.registerSampleService
           .updateRegisterSample(this.getRequestObject(true))
           .subscribe((response) => {
             if (response.statusCode === '1') {      
                      this.orderId = response.orderId;
                      this.componentId = response.componentId;
                      this.sampleId = response.sampleId;
                      this.qrCodeId = response.qrCodeId;
                      this.sku= response.sku;
                      this.fluidBrand = this.registerSampleForm.controls.fluidBrand.value;
                      this.fluidProduct = this.registerSampleForm.controls.fluidProduct.value;
                      this.fluidGrade = this.registerSampleForm.controls.fluidGrade.value;
                      console.log('res', response);
                      if(this.currentItem.status == 'Submitted' || this.currentItem.status == 'Hold'){
                        this.registerOrReview = 'Review';
                    let winRespObservable = this.analyzeSampleInfoServ.getAuthToken()
                    .pipe(mergeMap(authRes => this.analyzeSampleInfoServ.winlimsUpdate(authRes,'', this.sampleId, this.orderId,this.sku, this.qrCodeId, 'Received',
                      this.fluidBrand, this.fluidGrade, this.fluidProduct
                    )));
                    
                    winRespObservable.subscribe((winResp) => {
                      this.updateLogs(environment.winlimsUpdateUrl, this.sampleId, this.orderId, this.qrCodeId, this.sku, 'Received', '', winResp, false, 
                        this.fluidBrand, this.fluidGrade, this.fluidProduct
                      ); 
                      //console.log(winResp);
                      this.openClosePopup(true);
                      if (winResp.Header.Status == 'SUCCESS') {
                        this.alertService.success(response.statusMessage);
                      } else {
                        this.alertService.error('Error occured while updating to winlims');
                      }
                    }, (error) => {
                      this.updateLogs(environment.winlimsUpdateUrl, this.sampleId, this.orderId, this.qrCodeId, this.sku, 'Received', '', error, false,
                        this.fluidBrand, this.fluidGrade, this.fluidProduct
                      ); 
                    
                      //console.log(error);
                      // this.openClosePopup(true);
                      this.alertService.error('Error occured while updating to winlims');
                      this.scrollToTop = true;
                      /*this.sharedService.createSNOWIncident(this.currentItem.sampleId).subscribe((snowResp) =>{	
                          //console.log('Service now incident created successfully');	
                      });*/
                    });
                  }else{
                    this.alertService.success(response.statusMessage);
                    this.openClosePopup(true);
                  }              
                } else {
                  this.alertService.error(response.statusMessage);
                }
              });
       }
     }else{
       this.alertService.error('Form is invalid');
     }
     this.scrollToTop = true;
   }
  
   updateLogs(url, sampleId, orderId, qrCodeId, sku, flag, checkedList, resp,errorflag,
    fluidBrand, fluidGrade, fluidProduct
   ){
    let req = {
      "Header": {
        "Sender": {
          "ServiceName": "updateSampleIdService",
          "SenderID": "COAT"
        },
        "Target": {
          "TargetID": "WINLIMS"
        }
      },
      "DataArea": {
        "SampleId": sampleId,
        "OrderId": orderId,
        "SkuNumber": sku,
        "Flag": flag,
        "QrCode": qrCodeId,
        "RerunParameter": checkedList,
        "FluidBrand": fluidBrand,
        "FluidGrade": fluidGrade,
        "FluidProduct": fluidProduct
      }
    };
    let reqLog= {
    
      "requestUrl": url,
      "requestPayload": JSON.stringify(req),
      "responseGenerated": JSON.stringify(resp),
      "errorflag":errorflag

    }

    this.analyzeSampleInfoServ.middlewareLogs(reqLog).subscribe(response => {
      console.log('this.recentSample', response)
    });
  }

  getRequestObject(isSubmit: boolean): any {
    if(this.statusflag == true){
      this.currentstatus= 'Submitted';
    }else{
      this.currentstatus= this.currentItem.status;
    }

    //let fluidBrand = this.registerSampleForm.getRawValue().fluidBrand ? this.registerSampleForm.getRawValue().fluidBrand : this.notListed + this.registerSampleForm.getRawValue().dummyBrandName;
    let fluidBrand = '';
    if (this.registerSampleForm.controls.brandCheckBox.value) {
      fluidBrand = this.notListed + this.registerSampleForm.getRawValue().dummyBrandName;
    } else {
      fluidBrand = this.registerSampleForm.getRawValue().fluidBrand;
    }
    // if (this.registerSampleForm.getRawValue().fluidBrand && this.registerSampleForm.getRawValue().fluidBrand === '') {
    //   fluidBrand = this.notListed + this.registerSampleForm.getRawValue().dummyBrandName;
    // } else {
    //   fluidBrand = this.registerSampleForm.getRawValue().fluidBrand;
    // }
    //let fluidProduct = this.registerSampleForm.getRawValue().fluidProduct ? this.registerSampleForm.getRawValue().fluidProduct : this.notListed + this.registerSampleForm.getRawValue().dummyProductName;
    let fluidProduct = '';
    if (this.registerSampleForm.controls.productCheckBox.value) {
      fluidProduct = this.notListed + this.registerSampleForm.getRawValue().dummyProductName;
    } else {
      fluidProduct = this.registerSampleForm.getRawValue().fluidProduct;
    }
    // if (this.registerSampleForm.getRawValue().fluidProduct && this.registerSampleForm.getRawValue().fluidProduct === '') {

    //   fluidProduct =
    //     this.notListed + this.registerSampleForm.getRawValue().dummyProductName;
    // } else {
    //   fluidProduct = this.registerSampleForm.getRawValue().fluidProduct;
    // }
    //let fluidGrade = this.registerSampleForm.getRawValue().fluidGrade ? this.registerSampleForm.getRawValue().fluidGrade : this.notListed + this.registerSampleForm.getRawValue().dummyGradeName;
    let fluidGrade = '';
    if (this.registerSampleForm.controls.gradeCheckBox.value) {
      fluidGrade = this.notListed + this.registerSampleForm.getRawValue().dummyGradeName;
    } else {
      fluidGrade = this.registerSampleForm.getRawValue().fluidGrade;
    }
    // if (
    //   this.registerSampleForm.getRawValue().fluidGrade &&
    //   this.registerSampleForm.getRawValue().fluidGrade === ''
    // ) {
    //   fluidGrade =
    //     this.notListed + this.registerSampleForm.getRawValue().dummyGradeName;
    // } else {
    //   fluidGrade = this.registerSampleForm.getRawValue().fluidGrade;
    // }
    //let filterBrand = this.registerSampleForm.getRawValue().filterBrand ? this.registerSampleForm.getRawValue().filterBrand : this.notListed + this.registerSampleForm.getRawValue().dummyfilterBrandName;
    let filterBrand = '';
    if (this.registerSampleForm.controls.filterBrandCheckBox.value) {
      filterBrand = this.notListed + this.registerSampleForm.getRawValue().dummyfilterBrandName;
    } else {
      filterBrand = this.registerSampleForm.getRawValue().filterBrand;
    }

    // if (
    //   this.registerSampleForm.getRawValue().filterBrand &&
    //   this.registerSampleForm.getRawValue().filterBrand === ''
    // ) {
    //   filterBrand =
    //     this.notListed + this.registerSampleForm.getRawValue().dummyfilterBrandName;
    // } else {
    //   filterBrand = this.registerSampleForm.getRawValue().filterBrand;
    // }
    //let filterProduct = this.registerSampleForm.getRawValue().filterProduct ? this.registerSampleForm.getRawValue().filterProduct : this.notListed + this.registerSampleForm.getRawValue().dummyfilterProductName;
    let filterProduct = '';
    if (this.registerSampleForm.controls.filterProductCheckBox.value) {
      filterProduct = this.notListed + this.registerSampleForm.getRawValue().dummyfilterProductName;
    } else {
      filterProduct = this.registerSampleForm.getRawValue().filterProduct;
    }

    // if (
    //   this.registerSampleForm.getRawValue().filterProduct &&
    //   this.registerSampleForm.getRawValue().filterProduct === ''
    // ) {
    //   filterProduct =
    //     this.notListed + this.registerSampleForm.getRawValue().dummyfilterProductName;
    // } else {
    //   filterProduct = this.registerSampleForm.getRawValue().filterProduct;
    // }
    let userRoleTmp = this.sharedService.getIntOrExtUser();
    let registrationUser = "";
    if (this.userRole != USERTYPE.EXTERNAL) {
      registrationUser = this.registrationUser.wwid;
    }
    let isSerRelatedSample = "";
    if (this.userRole != USERTYPE.EXTERNAL) {
      isSerRelatedSample = this.registerSampleForm.controls["isSerRelatedSample"].value ? 'Y' : 'N';
    }
    else if (this.userRole == USERTYPE.DBU_BRANCH) {
      isSerRelatedSample = this.registerSampleForm.controls["isSerRelatedSample"].value ? 'Y' : 'N';
    }
    let jobNumber = "";
    if (this.userRole != USERTYPE.EXTERNAL) {
      jobNumber = this.registerSampleForm.controls["jobNumber"].value;
    }
    let bmsWorkOrder = "";
    if (this.userRole != USERTYPE.EXTERNAL) {
      bmsWorkOrder = this.registerSampleForm.controls["bmsWorkOrder"].value;
    }
    let unitMake = "";
    let engineMake = "";
    let engineModel = "";
    let applicationType = "";
    let unitModel = "";

    if (this.userRole != USERTYPE.EXTERNAL) {
      unitMake = this.registerSampleForm.controls["unitMake"].value;
      engineMake = this.registerSampleForm.controls["engineMake"].value;
      engineModel = this.registerSampleForm.controls["engineModel"].value;
      applicationType = this.registerSampleForm.controls["applicationType"].value;
      unitModel = this.registerSampleForm.controls["unitModel"].value;

    }
    if(this.associateWithEquipmentForm.value.equipmentId !=null && this.associateWithEquipmentForm.value.equipmentId !="" && this.associateWithEquipmentForm.value.equipmentId !=undefined){
      this.equipId= +this.associateWithEquipmentForm.value.equipmentId;
    }else{
      this.equipId = "";
    }
    this.unitIdList.forEach((value) => {
      if(this.associateWithEquipmentForm.controls['unitId'].value !=null &&  this.associateWithEquipmentForm.controls['unitId'].value !="" && this.associateWithEquipmentForm.controls['unitId'].value != undefined){
        if(value.componentId == this.associateWithEquipmentForm.controls['unitId'].value) {
          this.companyNameval=value.companyName;
          this.companyIdval = value.companyId;
        }
      }else{
        this.companyNameval = '';
        this.companyIdval = '';
      }
    });
    const req = {
      userId: this.sharedService.getUserId(),
      userRole: userRoleTmp,
      qrCodeId: this.currentItem.qrCodeId,
      prevQRCodeId: this.prevQRCodeIdNew,
      prevAnalysisType: this.prevAnalysisTypeNew,
      companyId: this.companyIdval,
      companyName: this.companyNameval,
      testLevel: this.currentItem.testLevel,
      analysisType: this.currentItem.analysisType,
      sampleId: this.currentItem.sampleId,
      orderId: this.currentItem.orderId,
      componentId: this.componentId,
      fluidType: this.currentItem.fluidType,
      equipmentId: this.equipId,
      sampledDate: this.datePipe.transform(
        this.registerSampleForm.controls["sampledDate"].value,
        'yyyy-MM-dd'
      ),
      unitDistance: this.registerSampleForm.controls["unitDistance"].value,
      fluidDistance: this.registerSampleForm.controls["fluidDistance"].value,
      /*unitHours: '65',
      fluidHours: '75',
      fluidId: '11',
      filterId: '5',*/
      fluidBrand: fluidBrand,
      fluidProduct: fluidProduct,
      fluidGrade: fluidGrade,
      filterBrand: filterBrand,
      filterProduct: filterProduct,
      registrationUser: registrationUser,
      isSerRelatedSample: isSerRelatedSample,
      isFluidBaseline: this.registerSampleForm.controls["isFluidBaseline"].value ? 'Y' : 'N',
      bmsWorkOrder: bmsWorkOrder,
      jobNumber: jobNumber,
      unitMake: unitMake,
      engineMake: engineMake,
      engineModel: engineModel,
      applicationType: applicationType,
      unitModel: unitModel,
      endOfDrainSample: this.IsDrainVal,
      sampledLocation: this.registerSampleForm.controls["sampledLocation"].value,
      geographicLocation: this.registerSampleForm.controls["geographicLocation"].value,
      isSCAAdded: this.registerSampleForm.controls["scaAdded"].value,
      isFluidChanged: this.registerSampleForm.controls["fluidChanged"].value,
      isFluidTopOff: this.registerSampleForm.controls["fluidTopOff"].value,
      fluidTopoffAmt: this.registerSampleForm.controls["topOffAmt"].value,
      mailerTrackingNumber: this.registerSampleForm.controls["mailerTraker"].value,
      remarks: this.associateWithEquipmentForm.value.remark,
      submitByCustomer: this.associateWithEquipmentForm.value.contactName,
      submitByCustPhone: this.associateWithEquipmentForm.value
        .contactPhoneNumber,
      submitByCustEmails: this.associateWithEquipmentForm.value.contactEmail,
      isUnderReview: 'N',
      submit: isSubmit,
      prevStatus: this.currentstatus,
      recievedDate: this.recievedDateLabAdmin
    };

    //  console.log('request', req);

    if (this.userRole === USERTYPE.EXTERNAL) {
      //req['companyId'] = this.sharedService.getCompanyId();
      //req['companyName'] = this.sharedService.getCompanyName();
      req['email'] = this.sharedService.getUserInformation().email;
    }
    return req;
  }

  validateFluidDropDown() {
    if (this.analysisType != 'Diesel Fuel') {
      //fluid Brand   
      if (!this.registerSampleForm.controls.brandCheckBox.value) {
        if (this.registerSampleForm.controls.fluidBrand.value == null ||
          this.registerSampleForm.controls.fluidBrand.value == undefined ||
          this.registerSampleForm.controls.fluidBrand.value == '') {
          this.fluidBrandHasError = true;
          this.isFormValid = false;
        } else {
          this.fluidBrandHasError = false;
        }
      } else {
        this.fluidBrandHasError = false;
        if (this.registerSampleForm.controls.dummyBrandName.invalid) {
          this.fluidBrandHasError = true;
        } else {
          this.fluidBrandHasError = false;
        }
      }

      //fluid Product
      if (!this.registerSampleForm.controls.productCheckBox.value) {
        if (this.registerSampleForm.controls.fluidProduct.value == null ||
          this.registerSampleForm.controls.fluidProduct.value == undefined ||
          this.registerSampleForm.controls.fluidProduct.value == '') {
          this.fluidProductHasError = true;
          this.isFormValid = false;
        } else {
          this.fluidProductHasError = false;
        }
      } else {
        this.fluidProductHasError = false;
        if (this.registerSampleForm.controls.dummyProductName.invalid) {
          this.fluidProductHasError = true;
        } else {
          this.fluidProductHasError = false;
        }
      }
    }
    //fluid Grade
    if (!this.registerSampleForm.controls.gradeCheckBox.value) {
      if (this.registerSampleForm.controls.fluidGrade.value == null ||
        this.registerSampleForm.controls.fluidGrade.value == undefined ||
        this.registerSampleForm.controls.fluidGrade.value == '') {
        this.fluidGradeHasError = true;
        this.isFormValid = false;
      } else {
        this.fluidGradeHasError = false;
      }
    } else {
      this.fluidGradeHasError = false;
      if (this.registerSampleForm.controls.dummyGradeName.invalid) {
        this.fluidGradeHasError = true;
      } else {
        this.fluidGradeHasError = false;
      }
    }

    //Filter Brand
    if (!this.registerSampleForm.controls.filterBrandCheckBox.value) {
      if (this.registerSampleForm.controls.filterBrand.value == null ||
        this.registerSampleForm.controls.filterBrand.value == undefined ||
        this.registerSampleForm.controls.filterBrand.value == '') {
        this.filterBrandHasError = true;
      } else {
        this.filterBrandHasError = false;
      }
    } else {
      this.filterBrandHasError = false;
      if (this.registerSampleForm.controls.dummyfilterBrandName.invalid) {
        this.filterBrandHasError = true;
      } else {
        this.filterBrandHasError = false;
      }
    }

    //Filter Product
    if (!this.registerSampleForm.controls.filterProductCheckBox.value) {
      if (this.registerSampleForm.controls.filterProduct.value == null ||
        this.registerSampleForm.controls.filterProduct.value == undefined ||
        this.registerSampleForm.controls.filterProduct.value == '') {
        this.filterProductHasError = true;
      } else {
        this.filterProductHasError = false;
      }
    } else {
      this.filterProductHasError = false;
      if (this.registerSampleForm.controls.dummyfilterProductName.invalid) {
        this.filterProductHasError = true;
      } else {
        this.filterProductHasError = false;
      }
    }
  }

  validateUnitId() {
    if (this.associateWithEquipmentForm.controls.unitId.value == null ||
      this.associateWithEquipmentForm.controls.unitId.value == undefined ||
      this.associateWithEquipmentForm.controls.unitId.value == '' ||
      this.associateWithEquipmentForm.controls.unitId.value == 0) {
      this.unitIdHasError = true;
      this.isFormValid = false;
    } else {
      this.unitIdHasError = false;
    }
  }

  onSaveClick() {
    if(this.currentItem.status !='Hold'){
    this.isFormValid = true;
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.registerSampleForm.controls['bmsWorkOrder'].disable();
    }
    for (var i in this.registerSampleForm.controls) {
      this.registerSampleForm.controls[i].markAsTouched();
    }
    for (var i in this.associateWithEquipmentForm.controls) {
      this.associateWithEquipmentForm.controls[i].markAsTouched();
    }
    if (this.buttonText === 'Edit') {
      this.registerSampleForm.enable();
      this.associateWithEquipmentForm.enable();
      this.buttonText = 'Save';
      this.setDropDowns(true, true);
      return;
    }
    const associateFormInvalid = this.checkIsFormValid(this.associateWithEquipmentForm);
    const registerSampleInvalid = this.checkIsRegSampleFormValid(this.registerSampleForm);
    if (associateFormInvalid.length > 0 || registerSampleInvalid.length > 0) {
      this.isFormValid = false;
    }
    this.validateFluidDropDown();
    this.validateUnitId();
    if (this.analysisType != 'Diesel Fuel') {
      this.validatefluiddistanceval();
    }
  }else if(this.currentItem.status =='Hold'){
    this.registerSampleForm.controls['sampledDate'].markAsTouched();
    for (var i in this.associateWithEquipmentForm.controls) {
      this.associateWithEquipmentForm.controls[i].markAsTouched();
    }    
    if (this.associateWithEquipmentForm.controls.contactEmail.value == null 
      || this.associateWithEquipmentForm.controls.contactName.value == null || this.associateWithEquipmentForm.controls.contactPhoneNumber.value == null || this.associateWithEquipmentForm.controls.contactEmail.value == undefined 
      || this.associateWithEquipmentForm.controls.contactName.value == undefined || this.associateWithEquipmentForm.controls.contactPhoneNumber.value == undefined  || this.associateWithEquipmentForm.controls.contactEmail.value == "" 
      || this.associateWithEquipmentForm.controls.contactName.value == "" || 
      this.associateWithEquipmentForm.controls.contactPhoneNumber.value == ""  
      || this.registerSampleForm.controls.sampledDate.value == null 
      || this.registerSampleForm.controls.sampledDate.value == undefined 
      || this.registerSampleForm.controls.sampledDate.value == "") {
        this.isOnHold = false;
        this.isFormValid = false;
    }else {
      this.isFormValid = true;
    }
  }
    if (this.isFormValid === true) {
      if (this.currentItem.recordAdded === false) {
        this.registerSampleService
          .saveRegisterSample(this.getRequestObject(false))
          .subscribe((response) => {
            if (response.statusCode === '1') {
              this.alertService.success(response.statusMessage);
              this.currentItem.recordAdded = true;
              this.isEditClick = true;
              this.buttonText = 'Edit';
              this.registerSampleForm.disable();
              this.associateWithEquipmentForm.disable();
              this.fetchData(true);
              this.setUpdatedLocalValue();
            } else {
              this.alertService.error(response.statusMessage);
            }
            this.scrollToTop = true;
          });
      } else {
        this.registerSampleService
          .updateRegisterSample(this.getRequestObject(false))
          .subscribe((response) => {
            if (response.statusCode === '1') {
              this.alertService.success(response.statusMessage);
              this.isEditClick = true;
              this.buttonText = 'Edit';
              this.registerSampleForm.disable();
              this.associateWithEquipmentForm.disable();
              this.fetchData(true);
              this.setUpdatedLocalValue();
              this.router.navigate(['/registerSample/registerSampleList']);
            } else {
              this.alertService.error(response.statusMessage);
            }
            this.scrollToTop = true;
          });
      }
    } else {
      if (this.FluidDistanceHasError || this.FluidDistanceHasErrorneg || this.FluidDistanceHasZeroError) {
        this.alertService.error(this.errorMessageForFluidDistance)
      } else {
        this.alertService.error('Form is invalid. Please enter all the mandatory values and Save');
      }
    }
    this.scrollToTop = true;
    if ((this.group.controls["qrCodeIdCompleteEditValue"].value != '' && this.currentItem.qrCodeId != this.group.controls["qrCodeIdCompleteEditValue"].value)) {
      this.isFormValid = false;

      this.alertService.error('Form is invalid. Please save the QR Code');
      this.scrollToTop = true;
    }

  }

  validatefluiddistanceval() {
    if (-this.registerSampleForm.controls.fluidDistance.value > +this.registerSampleForm.controls.unitDistance.value) { //Neg val
      this.FluidDistanceHasErrorneg = true;
      this.isFormValid = false;
      this.errorMessageForFluidDistance = "Current unit distance should be greater than previous unit distance";
    } else {
      this.FluidDistanceHasErrorneg = false;
    }

    if (+this.registerSampleForm.controls.fluidDistance.value > +this.registerSampleForm.controls.unitDistance.value) { //Pos val
      this.FluidDistanceHasError = true;
      this.isFormValid = false;
      this.errorMessageForFluidDistance = "Fluid Distance cannot be greater than Unit Distance";
    } else {
      this.FluidDistanceHasError = false;
    }

    if (this.registerSampleForm.controls.fluidDistance.value == 0) { // Zero case
      this.FluidDistanceHasZeroError = true;
      this.isFormValid = false;
      this.errorMessageForFluidDistance = "Fluid Distance can not be 0";
    } else {
      this.FluidDistanceHasZeroError = false;
    }
  }

  setUpdatedLocalValue() {
    this.registerInfo = JSON.parse(localStorage.getItem('registerSampleObject'));
    if (this.registerInfo.hasOwnProperty('fluidDistance')) {
      // Change value
      this.registerInfo.fluidDistance = this.registerSampleForm.controls['fluidDistance'].value;
      this.registerInfo.unitDistance = this.registerSampleForm.controls['unitDistance'].value;
      this.registerInfo.sampledDate = this.datePipe.transform(
        this.registerSampleForm.controls["sampledDate"].value,
        'MM/dd/yyyy');
      // Save the new item with updated value
      localStorage.setItem("registerSampleObject", JSON.stringify(this.registerInfo));
    }
  }

  onFluidResetClick() {
    this.sampleFluidBrand = '';
    this.fluidBrandHasError = false;
    this.fluidProductHasError = false;
    this.fluidGradeHasError = false;
    this.setFluidDropDown();
    this.registerSampleForm.patchValue({
      fluidBrand: '',
      fluidProduct: '',
      fluidGrade: '',
      brandCheckBox: false,
      productCheckBox: false,
      gradeCheckBox: false,
      dummyBrandName: '',
      dummyProductName: '',
      dummyGradeName: ''
    });
    for (const name in this.registerSampleForm.controls) {
      if (
        name.toLowerCase() === 'fluidBrand'.toLowerCase() ||
        name.toLowerCase() === 'fluidProduct'.toLowerCase() ||
        name.toLowerCase() === 'fluidGrade'.toLowerCase() ||
        name.toLowerCase() === 'dummyBrandName'.toLowerCase() ||
        name.toLowerCase() === 'dummyProductName'.toLowerCase() ||
        name.toLowerCase() === 'dummyGradeName'.toLowerCase() ||
        name.toLowerCase() === 'brandCheckBox'.toLowerCase() ||
        name.toLowerCase() === 'productCheckBox'.toLowerCase() ||
        name.toLowerCase() === 'gradeCheckBox'.toLowerCase()
      ) {
        this.resetControl(this.registerSampleForm.controls[name]);
      }
    }
    if (this.equipInfo !== undefined && this.recentSample !== undefined && this.sampleInfo !== undefined) {
      this.setDropDowns(true, false);
    }
    this.setAddToMasterDatabaseButton();
  }
  resetControl(cntrl: AbstractControl) {
    cntrl.enable();
    cntrl.reset();
    cntrl.markAsPristine();
    cntrl.markAsUntouched();
    cntrl.updateValueAndValidity();
    cntrl.setErrors(null);
  }
  onFilterResetClick() {
    this.sampleFilterProduct = '';
    this.setFilterDropDown();
    this.registerSampleForm.patchValue({
      filterBrand: '',
      filterProduct: '',
      filterBrandCheckBox: false,
      filterProductCheckBox: false,
      dummyfilterBrandName: '',
      dummyfilterProductName: ''
    });
    for (const name in this.registerSampleForm.controls) {
      if (
        name.toLowerCase() === 'filterBrand'.toLowerCase() ||
        name.toLowerCase() === 'filterProduct'.toLowerCase() ||
        name.toLowerCase() === 'filterBrandCheckBox'.toLowerCase() ||
        name.toLowerCase() === 'filterProductCheckBox'.toLowerCase() ||
        name.toLowerCase() === 'dummyfilterBrandName'.toLowerCase() ||
        name.toLowerCase() === 'dummyfilterProductName'.toLowerCase()

      ) {
        this.resetControl(this.registerSampleForm.controls[name]);
      }
    }
    if (this.equipInfo !== undefined && this.recentSample !== undefined && this.sampleInfo !== undefined) {
      this.setDropDowns(false, true);
    }
    this.setAddToMasterDatabaseButton();
  }


  addToMasterClick(fluidFlag: boolean, filterFlag: boolean) {
    this.scrollToTop = true;
    let fluidBrand;
    if (this.registerSampleForm.controls['dummyBrandName'].value === undefined || this.registerSampleForm.controls['dummyBrandName'].value === ''
      || this.registerSampleForm.controls['dummyBrandName'].value == null) {
      fluidBrand = this.registerSampleForm.controls['fluidBrand'].value;
    } else {
      fluidBrand = this.registerSampleForm.controls['dummyBrandName'].value.replace(
        this.notListed,
        ''
      );
    }
    let fluidProduct;
    if (this.registerSampleForm.controls['dummyProductName'].value === undefined || this.registerSampleForm.controls['dummyProductName'].value === ''
      || this.registerSampleForm.controls['dummyProductName'].value == null) {
      fluidProduct = this.registerSampleForm.controls['fluidProduct'].value;
    } else {
      fluidProduct = this.registerSampleForm.controls['dummyProductName'].value.replace(
        this.notListed,
        ''
      );
    }
    let fluidGrade;
    if (this.registerSampleForm.controls['dummyGradeName'].value === undefined || this.registerSampleForm.controls['dummyGradeName'].value === ''
      || this.registerSampleForm.controls['dummyGradeName'].value == null) {
      fluidGrade = this.registerSampleForm.controls['fluidGrade'].value;
    } else {
      fluidGrade = this.registerSampleForm.controls['dummyGradeName'].value.replace(
        this.notListed,
        ''
      );

    }
    let filterBrand;
    if (this.registerSampleForm.controls['dummyfilterBrandName'].value === undefined || this.registerSampleForm.controls['dummyfilterBrandName'].value === ''
      || this.registerSampleForm.controls['dummyfilterBrandName'].value == null) {
      filterBrand = this.registerSampleForm.controls['filterBrand'].value;
    } else {

      filterBrand = this.registerSampleForm.controls['dummyfilterBrandName'].value.replace(
        this.notListed,
        ''
      );
    }
    let filterProduct;
    if (this.registerSampleForm.controls['dummyfilterProductName'].value === undefined || this.registerSampleForm.controls['dummyfilterProductName'].value === ''
      || this.registerSampleForm.controls['dummyfilterProductName'].value == null) {
      filterProduct = this.registerSampleForm.controls['filterProduct'].value;
    } else {

      filterProduct = this.registerSampleForm.controls['dummyfilterProductName'].value.replace(
        this.notListed,
        ''
      );
    }

    for (var i in this.registerSampleForm.controls) {
      this.registerSampleForm.controls[i].markAsTouched();
    }
    this.validateFluidDropDown();
    if (fluidFlag) {
      if (this.fluidBrandHasError || this.registerSampleForm.controls['dummyBrandName'].invalid ||
        this.fluidProductHasError || this.registerSampleForm.controls['dummyProductName'].invalid ||
        this.fluidGradeHasError || this.registerSampleForm.controls['dummyGradeName'].invalid
      ) {
        this.alertService.error('Add to Fluid Master Dashboard Form is invalid');
        return false;
      }
    } else if (filterFlag) {
      if (this.filterBrandHasError || this.registerSampleForm.controls['dummyfilterBrandName'].invalid ||
        this.filterProductHasError || this.registerSampleForm.controls['dummyfilterProductName'].invalid
      ) {
        this.alertService.error('Add to Filter Master Dashboard Form is invalid');
        return false;
      }
    }

    const obj = {
      userId: this.registrationUser.wwid,
      analysisType: this.currentItem.analysisType,
      sampleId: this.currentItem.sampleId,
      fluidBrand: fluidBrand,
      fluidProduct: fluidProduct,
      fluidGrade: fluidGrade,
      filterBrand: filterBrand,
      filterProduct: filterProduct,
      filter: filterFlag,
      fluid: fluidFlag
    };
    this.registerSampleService.addMasterData(obj).subscribe((data) => {
      if (data.statusCode !== -1) {
        this.registerSampleService
          .getDropDownData(this.currentItem.analysisType, this.currentItem.fluidType)
          .subscribe((res) => {
            this.populateDropDown(res);
            /*if (this.currentItem.recordAdded === true) {
              this.fetchData(true);
            } else {
              this.fetchData(false);
            }*/
            if (fluidFlag) {
              this.registerSampleForm.patchValue({
                fluidBrand: fluidBrand ? fluidBrand : '',
                fluidProduct: fluidProduct ? fluidProduct : '',
                fluidGrade: fluidGrade ? fluidGrade : ''
              });
            } else if (filterFlag) {
              this.registerSampleForm.patchValue({
                filterBrand: filterBrand,
                filterProduct: filterProduct,
              });
            }
            //this.setDropDowns(true, true);
            this.setDropDownValidation();
            this.setAddToMasterDatabaseButton();
          });
        this.resetFilterCheckBox(fluidFlag, filterFlag);
        if (fluidFlag) {
          this.alertService.success('Added to Fluid Master Successfully');
        } else if (filterFlag) {
          this.alertService.success('Added to Filter Master Successfully');
        }
      } else {
        this.alertService.error('Error occured while adding data to Master');
      }
    });
  }
  addToNotlistedMasterClick() {
    this.isFormValid = true;
    for (var i in this.registerSampleForm.controls) {
      this.registerSampleForm.controls[i].markAsTouched();
    }
    const registerSampleInvalid = this.checkIsRegSampleFormValid(this.registerSampleForm);
    if (registerSampleInvalid.length > 0) {
      this.isFormValid = false;
    }
    if (this.registerSampleForm.controls.engineModel.value != "") {
      if (this.registerSampleForm.controls.engineMake.value == null ||
        this.registerSampleForm.controls.engineMake.value == undefined ||
        this.registerSampleForm.controls.engineMake.value == '') {
        this.engineMakeHasError = true;
        this.isFormValid = false;
        return false;
      }
    } else {
      this.engineMakeHasError = false;
    }

    if (this.registerSampleForm.controls.engineMake.value != "") {
      if (this.registerSampleForm.controls.engineModel.value == null ||
        this.registerSampleForm.controls.engineModel.value == undefined ||
        this.registerSampleForm.controls.engineModel.value == '') {
        this.engineModelHasError = true;
        this.isFormValid = false;
        return false;
      }
    } else {
      this.engineModelHasError = false;
    }

    let unitMake;
    let applicationType
    let engineModel;
    let engineMake;
    unitMake = this.registerSampleForm.controls["unitMake"].value;
    engineModel = this.registerSampleForm.controls["engineModel"].value;
    engineMake = this.registerSampleForm.controls["engineMake"].value;
    applicationType = this.registerSampleForm.controls["applicationType"].value;
    const obj = {
      equipId: this.associateWithEquipmentForm.controls.equipmentId.value,
      userId: this.sharedService.getUserId(),
      unitMake: unitMake,
      applicationType: applicationType,
      engineModel: engineModel,
      engineMake: engineMake,
      dropdownName: "SAMPLE"
    };
    if (this.isFormValid === true) {
      this.registerSampleService.getDropdownOptions(obj).subscribe((data) => {
        if (data.statusCode == 1) {
          this.engineMakeHasError = false;
          this.engineModelHasError = false;
          this.populateNotlistedValues();
          this.alertService.success('Added to Master Data Successfully');
        } else {
          this.alertService.error('Error occured while adding data to Master');
        }
      });
    }
  }
  populateNotlistedValues() {
    let obj = {
      analysisType: this.currentItem.analysisType,
      unitId: this.associateWithEquipmentForm.value.unitId,
      userRole: this.userRole,
      qrCodeId: this.currentItem.qrCodeId,
      sampleId: this.currentItem.sampleId,
      equipmentId: ''
    };

    this.unitIdList.forEach((value) => {
      if (value.componentId == this.associateWithEquipmentForm.controls['unitId'].value) {
        obj.equipmentId = value.equipmentId;
      }
    });

    obj.userRole = this.sharedService.getIntOrExtUser();

    this.registerSampleService.fetchData(obj).subscribe((data) => {
      this.equipInfo = data.equipInfo;
      this.recentSample = data.recentSample;
      this.sampleInfo = data.sampleInfo;
      if (this.equipInfo.unitMakeStatus !== 'N' || this.registerSampleForm.controls['unitMake'].value == "") {
        this.registerSampleForm.controls['unitMake'].disable();
      } else {
        this.registerSampleForm.controls['unitMake'].enable();
      }

      if (this.equipInfo.appTypeStatus !== 'N' || this.registerSampleForm.controls['applicationType'].value == '') {
        this.registerSampleForm.controls['applicationType'].disable();
      }
      else {
        this.registerSampleForm.controls['applicationType'].enable();
      }

      if (this.registerSampleForm.controls['engineModel'].value !== '' && this.registerSampleForm.controls['engineMake'].value !== '' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y') {
        this.registerSampleForm.controls['engineModel'].disable();
        this.registerSampleForm.controls['engineMake'].disable();
      } else if (this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N' && this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '') {
        this.registerSampleForm.controls['engineModel'].enable();
        this.registerSampleForm.controls['engineMake'].enable();
      }
      else if (this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N' && this.registerSampleForm.controls['engineModel'].value == '' && this.registerSampleForm.controls['engineMake'].value == '') {
        this.registerSampleForm.controls['engineModel'].enable();
        this.registerSampleForm.controls['engineMake'].enable();
      } else if ((this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
        this.registerSampleForm.controls['engineModel'].disable();
        this.registerSampleForm.controls['engineMake'].enable();
      } else if ((this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
        this.registerSampleForm.controls['engineModel'].enable();
        this.registerSampleForm.controls['engineMake'].disable();
      }
      this.registerSampleForm.controls['unitModel'].disable();
      if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y')) {
        this.isDisplayAddToUnitMasterData = false;
      }
      else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = false;

      }
      else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;

      }

      else if ((this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
        this.isDisplayAddToUnitMasterData = true;
      } else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
        this.isDisplayAddToUnitMasterData = true;
      }
      else {
        this.isDisplayAddToUnitMasterData = true;
      }

    });
  }


  setDropDownValidation() {
    if (this.registerSampleForm.controls.brandCheckBox.value) {
      this.registerSampleForm.controls.dummyBrandName.setValidators([Validators.required]);
      this.registerSampleForm.controls.dummyBrandName.updateValueAndValidity();
    } else {
      this.registerSampleForm.controls.dummyBrandName.clearValidators();
      this.registerSampleForm.controls.dummyBrandName.updateValueAndValidity();
    }

    if (this.registerSampleForm.controls.productCheckBox.value) {
      this.registerSampleForm.controls.dummyProductName.setValidators([Validators.required]);
      this.registerSampleForm.controls.dummyProductName.updateValueAndValidity();
    } else {
      this.registerSampleForm.controls.dummyProductName.clearValidators();
      this.registerSampleForm.controls.dummyProductName.updateValueAndValidity();
    }

    if (this.registerSampleForm.controls.gradeCheckBox.value) {
      this.registerSampleForm.controls.dummyGradeName.setValidators([Validators.required]);
      this.registerSampleForm.controls.dummyGradeName.updateValueAndValidity();
    } else {
      this.registerSampleForm.controls.dummyGradeName.clearValidators();
      this.registerSampleForm.controls.dummyGradeName.updateValueAndValidity();
    }

    if (this.registerSampleForm.controls.filterBrandCheckBox.value) {
      this.registerSampleForm.controls.dummyfilterBrandName.setValidators([Validators.required]);
      this.registerSampleForm.controls.dummyfilterBrandName.updateValueAndValidity();
    } else {
      this.registerSampleForm.controls.dummyfilterBrandName.clearValidators();
      this.registerSampleForm.controls.dummyfilterBrandName.updateValueAndValidity();
    }

    if (this.registerSampleForm.controls.filterProductCheckBox.value) {
      this.registerSampleForm.controls.dummyfilterProductName.setValidators([Validators.required]);
      this.registerSampleForm.controls.dummyfilterProductName.updateValueAndValidity();
    } else {
      this.registerSampleForm.controls.dummyfilterProductName.clearValidators();
      this.registerSampleForm.controls.dummyfilterProductName.updateValueAndValidity();
    }
  }

  resetFilterCheckBox(fluidFlag: boolean, filterFlag: boolean) {
    if (fluidFlag) {
      this.registerSampleForm.patchValue({
        brandCheckBox: false,
        productCheckBox: false,
        gradeCheckBox: false,
        dummyBrandName: '',
        dummyProductName: '',
        dummyGradeName: ''
      });
      this.isDisplayAddToFluidMasterData = false;
      this.registerSampleForm.controls['fluidBrand'].enable();
      this.registerSampleForm.controls['fluidProduct'].enable();
      this.registerSampleForm.controls['fluidGrade'].enable();
    } else if (filterFlag) {
      this.registerSampleForm.patchValue({
        filterBrandCheckBox: false,
        filterProductCheckBox: false,
        dummyFilterBrandName: '',
        dummyfilterProductName: ''
      });
      this.isDisplayAddToFilterMasterData = false;
      this.registerSampleForm.controls['filterBrand'].enable();
      this.registerSampleForm.controls['filterProduct'].enable();
    }

  }
  validateBmsWoDropdown() {
    if (this.registerSampleForm.controls["isSerRelatedSample"].value == true) {

      if (this.registerSampleForm.controls["bmsWorkOrder"].value == "" || this.registerSampleForm.controls["bmsWorkOrder"].value == undefined
        || this.registerSampleForm.controls["bmsWorkOrder"].value == null)
        this.bmsWorkOrderHasError = true
    }
    else {
      this.bmsWorkOrderHasError = false

    }
  }


  checkValidationMessage(controlName: string) { };
  onEditClick() {
    if (this.isEditClick === true) {
      this.isEditClick = false;
      this.lastUnitDistance = true;
      this.displaytextMsg = false;
      this.buttonText = 'Save';
      this.registerSampleForm.enable();
      this.setNotlistedEnableDisable();
      this.registerSampleForm.controls['fluidBrand'].disable();
      if (this.userRole === USERTYPE.LAB_ADMIN ||
        this.userRole === USERTYPE.DATA_SCIENTIST
        || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
        this.editQRCode = true;
      }


      if (this.analysisType == 'Diesel Fuel') {
        this.registerSampleForm.controls['unitDistance'].disable();
        this.registerSampleForm.controls['fluidDistance'].disable();
        this.registerSampleForm.controls['fluidBrand'].disable();
        this.registerSampleForm.controls['fluidProduct'].disable();
        this.registerSampleForm.controls['dummyBrandName'].disable();
        this.registerSampleForm.controls['dummyProductName'].disable();
        this.registerSampleForm.controls['brandCheckBox'].disable();
      }
      this.associateWithEquipmentForm.enable();
      this.setDropDowns(true, true);
    } else if (this.isEditClick === false) {
      this.onSaveClick();
    }
  }
  onUpdateClick() {
    if (this.isEditClick === true) {
      console.log('jjjj')
      this.isEditClick = false;
      this.lastUnitDistance = true;
      this.displaytextMsg = false;
      this.buttonText = 'Save';
      this.associateWithEquipmentForm.enable();
      this.registerSampleForm.enable();
      this.setNotlistedEnableDisable();
      this.setDropDowns(true, true);
      if (this.userRole === USERTYPE.LAB_ADMIN ||
        this.userRole === USERTYPE.DATA_SCIENTIST ||
        this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
        this.editQRCode = true;
      }
    } else if (this.isEditClick === false) {
      this.onSaveClick();
    }
  }
  OnloadNotlistedEnableDisable() {
    if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y')) {
      this.isDisplayAddToUnitMasterData = false;
    }
    else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = false;

    }
    else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;

    }
    else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
      this.isDisplayAddToUnitMasterData = true;
    }
    else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
      this.isDisplayAddToUnitMasterData = true;
    }
    else if ((this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;
    }
    else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
      this.isDisplayAddToUnitMasterData = true;
    } else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;
    } else if ((this.equipInfo.engineMake = "" && this.equipInfo.engineModel == "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;
    }

    else {
      this.isDisplayAddToUnitMasterData = true;
    }
  }
  setNotlistedEnableDisable() {
    if (this.equipInfo.unitMakeStatus !== 'N' || this.registerSampleForm.controls['unitMake'].value == "") {
      this.registerSampleForm.controls['unitMake'].disable();
    } else {
      this.registerSampleForm.controls['unitMake'].enable();
    }

    if (this.equipInfo.appTypeStatus !== 'N' || this.registerSampleForm.controls['applicationType'].value == '') {
      this.registerSampleForm.controls['applicationType'].disable();
    }
    else {
      this.registerSampleForm.controls['applicationType'].enable();
    }

    if (this.registerSampleForm.controls['engineModel'].value !== '' && this.registerSampleForm.controls['engineMake'].value !== '' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y') {
      this.registerSampleForm.controls['engineModel'].disable();
      this.registerSampleForm.controls['engineMake'].disable();
    } else if (this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N' && this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '') {
      this.registerSampleForm.controls['engineModel'].enable();
      this.registerSampleForm.controls['engineMake'].enable();
    }
    else if (this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N' && this.registerSampleForm.controls['engineModel'].value == '' && this.registerSampleForm.controls['engineMake'].value == '') {
      this.registerSampleForm.controls['engineModel'].enable();
      this.registerSampleForm.controls['engineMake'].enable();
    } else if ((this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
      this.registerSampleForm.controls['engineModel'].disable();
      this.registerSampleForm.controls['engineMake'].enable();
    } else if ((this.registerSampleForm.controls['engineModel'].value != '' && this.registerSampleForm.controls['engineMake'].value != '' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
      this.registerSampleForm.controls['engineModel'].enable();
      this.registerSampleForm.controls['engineMake'].disable();
    }

    this.registerSampleForm.controls['unitModel'].disable();
    if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'Y')) {
      this.isDisplayAddToUnitMasterData = false;
    }
    else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = false;

    }
    else if ((this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;

    }
    else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake != "" && this.equipInfo.engineModel == "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
      this.isDisplayAddToUnitMasterData = true;
    }
    else if (this.equipInfo.unitMake != "" && this.equipInfo.applicationType != "" && this.equipInfo.engineMake == "" && this.equipInfo.engineModel != "" && this.equipInfo.unitMakeStatus == 'Y' && this.equipInfo.appTypeStatus == 'Y' && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N') {
      this.isDisplayAddToUnitMasterData = true;
    }
    else if ((this.equipInfo.engineMake == "" && this.equipInfo.engineModel == "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;
    }
    else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'Y')) {
      this.isDisplayAddToUnitMasterData = true;
    } else if ((this.equipInfo.engineMake != "" && this.equipInfo.engineModel != "" && this.equipInfo.engMakeStatus == 'Y' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;
    } else if ((this.equipInfo.engineMake = "" && this.equipInfo.engineModel == "" && this.equipInfo.engMakeStatus == 'N' && this.equipInfo.engModStatus == 'N')) {
      this.isDisplayAddToUnitMasterData = true;
    }

    else {
      this.isDisplayAddToUnitMasterData = true;
    }

  }
  checkIsFormValid(form: UntypedFormGroup) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    //  console.log('invalid', invalid);
    if (this.analysisType == 'Diesel Fuel') {
      invalid.pop();
      invalid.pop();
    }
    return invalid;
  }

  checkIsRegSampleFormValid(form: UntypedFormGroup) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (name === 'sampledDate') {
        if (form.controls["sampledDate"].value === undefined || form.controls["sampledDate"].value === '') {
          invalid.push(name);
        }
      }
      else if (controls[name].invalid) {
        invalid.push(name);
      }

    }
    if (this.registerSampleForm.controls.isSerRelatedSample.value == true) {
      if (this.registerSampleForm.controls.bmsWorkOrder.value == null || this.registerSampleForm.controls.bmsWorkOrder.value == undefined
        || this.registerSampleForm.controls.bmsWorkOrder.value == '') {
        this.bmsWorkOrderHasError = true;
        this.isFormValid = false;
      } else {
        this.bmsWorkOrderHasError = false;
      }
    } else {
      this.bmsWorkOrderHasError = false;
    }

    //  console.log('invalid', invalid);
    if (this.analysisType == 'Diesel Fuel') {
      invalid.pop();
      invalid.pop();
    }
    return invalid;
  }
  onCancel() {
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.router.navigate(['/registerSample/registerSampleList']);
    } else if (
      this.userRole === USERTYPE.LAB_ADMIN ||
      this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.FLUIDWATCH_MANAGER || this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS) {
      //console.log('Unit distance: '+this.registerSampleForm.controls['unitDistance'].value);
      this.updateReviewFlag();
      if (this.analysisScreenData != undefined && this.analysisScreenData != "") {
        if (this.analysisScreenData.hasOwnProperty("analysisScreen") && this.analysisScreenData['analysisScreen'] === 'true') {
          this.router.navigate(['/analyzeSampleInformation']);
        } else {
          this.router.navigate(['/registerSample/reviewSampleList']);
        }
      } else {
        this.router.navigate(['/registerSample/reviewSampleList']);
      }
    }
  }

  updateReviewFlag() {
    if (this.currentItem.status === 'Submitted') {
      const obj = {
        sampleId: this.currentItem.sampleId,
        qrCodeId: this.currentItem.qrCodeId,
        analysisType: this.currentItem.analysisType,
        underReview: false
      }
      this.registerSampleService.updateReviewFlag(obj).subscribe(
        data => {
          if (data.statusCode === '1') {

          } else {
            this.alertService.error(data.statusMessage);
          }
        }
      );
    }
  }

  /* -------------------------Delete confirmation popup------------------------- */
  openClosePopup(show: boolean) {
    this.openModal = show;
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateClasses() {
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.isEditClick = this.buttonText === 'Edit' ? true : false;
      return { 'disabledButton': this.buttonText == 'Edit', 'primaryButton': this.buttonText == 'Save' };
    } else {
      return { 'disabledButton': this.isEditClick === true, 'primaryButton': this.isEditClick === false };
    }

  }

  getClasses() {
    if(this.currentItem.status !== 'Hold'){
    if (this.associateWithEquipmentForm.invalid) {
      return { 'disabledButton': true, 'primaryButton': false };
    }
  }
    if (this.isDisplayAddToFluidMasterData === true || this.isDisplayAddToFilterMasterData === true || this.isDisplayAddToUnitMasterData === true || this.isDisplayApprove == true) {
      if (this.currentItem.status !== 'Available' && this.currentItem.status !== 'Hold' && this.currentItem.status !== 'Submitted') {
        return { 'disabledButton': true, 'primaryButton': false };
      } else {
        return { 'disabledButton': false, 'primaryButton': true };
      }
    } else {
      return { 'disabledButton': false, 'primaryButton': true };
    }

  }

  getrushsampleClasses() {
    if (!this.rushcheckbox) {
      return ("disabledButton")
    } else {
      return ("primaryButton")
    }
  }

  confirmPopup() {
    this.disabled = false;
    this.openRushedSampleModal = true;
  }

  onRushSample() {
    this.openRushedSampleModal = false;
  }

  closeModal() {
    this.openRushedSampleModal = false;
    this.rushcheckbox = false;
    this.disabled = true;
    // this.registerSampleForm.controls[rushThisFlag].clear();
    // this.registerSampleForm.controls.rushThisFlag.value.clear();
    this.registerSampleForm.patchValue({ ['rushThisFlag']: false });
  }

  selectrushsampleflag(isChecked: boolean) {
    this.rushcheckbox = !this.rushcheckbox;
    // console.log(isChecked);
    if (isChecked == true) {
      this.disabled = false;
      return { 'disabledButton': false, 'primaryButton': true };
    } else {
      this.disabled = true;
      isChecked == false;
      return { 'disabledButton': true, 'primaryButton': false };
    }
  }

  getSuccessMessage() {
    if (this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
      if (this.currentItem.status == 'Available') {
        return 'registered';
      } else {
        return 'approved';
      }
    } else {
      return 'associated';
    }
  }

  calculatefluiddistanceval() {

    if (this.registerSampleForm.controls.unitDistance.value != "") {
      this.unitDistanceHasError = false;
      let fluidDistance = (+this.registerSampleForm.controls.unitDistance.value) - (+this.lastUnitDistanceval);
      this.fluidDistanceval = fluidDistance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // this.registerSampleForm.patchValue({
      //   fluidDistance: this.fluidDistanceval,
      // });
      this.displaytextMsg = true;
      // if( -this.fluidDistanceval >= +this.registerSampleForm.controls.unitDistance.value){ //Neg val
      //   this.FluidDistanceHasErrorneg = true;
      //   this.isFormValid = false;
      // }else{
      //   this.FluidDistanceHasErrorneg = false;

      // }

      // if( +this.fluidDistanceval >= +this.registerSampleForm.controls.unitDistance.value){ //Pos val
      //   this.FluidDistanceHasError = true;
      //   this.isFormValid = false;
      // } 
      // else {
      //   this.FluidDistanceHasError = false;
      // }

      // if(this.registerSampleForm.controls.fluidDistance.value == 0){ // Zero case
      //   this.FluidDistanceHasZeroError = true;
      //   this.isFormValid = false;
      // }
      // else{
      //   this.FluidDistanceHasZeroError = false;

      // }
    } else if (this.registerSampleForm.controls.unitDistance.value == "") {
      this.unitDistanceHasError = true;
    }

  }

  onKeyfluid(value) {
    // console.log(this.registerSampleForm.controls.fluidDistance.value)
    // console.log(value, this.registerSampleForm.controls.unitDistance.value);
    // console.log(typeof(value), typeof( this.registerSampleForm.controls.unitDistance.value));

    if (+value > +this.registerSampleForm.controls.unitDistance.value) { //Pos val
      this.FluidDistanceHasError = true;
      this.isFormValid = false;
    }
    else if (this.registerSampleForm.controls.fluidDistance.value == 0) {
      this.FluidDistanceHasZeroError = true;
      this.isFormValid = false;
    }
    else {
      this.FluidDistanceHasError = false;
    }
    if (-value > +this.registerSampleForm.controls.unitDistance.value) { //Neg val
      this.FluidDistanceHasErrorneg = true;
      this.isFormValid = false;
    }
    else {
      this.FluidDistanceHasErrorneg = false;
    }

    if (this.registerSampleForm.controls.fluidDistance.value == 0) { // Zero case
      this.FluidDistanceHasZeroError = true;
      this.isFormValid = false;
    }
    else {
      this.FluidDistanceHasZeroError = false;

    }
  }

  drainSample(e) {
    if (e.value == "IsDrainYes") {
      this.IsDrainVal = 'Y';
    } else {
      this.IsDrainVal = 'N';
    }
  }

  onHold() {
    this.isOnHold = true;
  }

  closeOnHoldModal() {
    this.isOnHold = false;
  }
  cancelHold(){
    const req = {
      prevStatus: this.currentItem.status, 
      cancelHold: true,
      hold: false,
      userRole: this.getRequestObject(true).userRole,
      userId: this.sharedService.getUserId(),
      sampleId: this.currentItem.sampleId? this.sampleId: '',
      qrCode: this.currentItem.qrCodeId? this.qrCodeId : ''
  }
  this.registerSampleService.holdRequestData(req).subscribe( response => {      

    if (response.statusCode == "1") { 
      this.alertService.success(response.statusMessage); 
      this.currentItem.status = response.status;      
    }else{      
      this.alertService.error(response.statusMessage);
    }
    if(this.userRole === USERTYPE.LAB_ADMIN ||
      this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.FLUIDWATCH_MANAGER) {
      this.router.navigate(['/registerSample/registerSampleList']);      
    }
});
  }
  saveOnHold(){
    this.isFormValid = true;
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.registerSampleForm.controls['bmsWorkOrder'].disable();
    }
    this.registerSampleForm.controls['sampledDate'].markAsTouched();
    for (var i in this.associateWithEquipmentForm.controls) {
      this.associateWithEquipmentForm.controls[i].markAsTouched();
    }    
    if (this.associateWithEquipmentForm.controls.contactEmail.value == null 
      || this.associateWithEquipmentForm.controls.contactName.value == null || this.associateWithEquipmentForm.controls.contactPhoneNumber.value == null || this.associateWithEquipmentForm.controls.contactEmail.value == undefined 
      || this.associateWithEquipmentForm.controls.contactName.value == undefined || this.associateWithEquipmentForm.controls.contactPhoneNumber.value == undefined  || this.associateWithEquipmentForm.controls.contactEmail.value == "" 
      || this.associateWithEquipmentForm.controls.contactName.value == "" || 
      this.associateWithEquipmentForm.controls.contactPhoneNumber.value == ""  
      || this.registerSampleForm.controls.sampledDate.value == null 
      || this.registerSampleForm.controls.sampledDate.value == undefined 
      || this.registerSampleForm.controls.sampledDate.value == "") {
        this.isOnHold = false;
        this.isFormValid = false;
    } else {
      this.isFormValid = true;
    }
    const req = {
      userRole: this.getRequestObject(true).userRole,   
      userId: this.sharedService.getUserId(),
      sampleId: this.currentItem.sampleId? this.sampleId : '',
      companyId: this.currentItem.companyId? this.currentItem.companyId: '',
      qrCode: this.currentItem.qrCodeId? this.qrCodeId : '',
      "URL": environment.holdReportEmailUrl + this.currentItem.qrCodeId,
      unitDistance: this.holdForm.value['unitDistanceCheckBox']? true : false,
      fluidDistance: this.holdForm.value['fluidDistanceCheckBox']? true : false,
      fluidInfo: this.holdForm.value['fluidinfoCheckBox']? true : false,
      filterInfo: this.holdForm.value['filterinfoCheckBox']? true : false,
      unit: this.holdForm.value['unitCheckBox']? true : false,     
      note: this.holdForm.value['note'],   
      emailID: this.associateWithEquipmentForm.value.contactEmail,  
      prevStatus: this.currentItem.status, 
      hold: this.isOnHold = true,
      addSample: this.getRequestObject(true)
      }      
      this.isOnHold = false; 
      if (this.isFormValid === true) {  
        this.registerSampleService.holdRequestData(req).subscribe((response) => {  
            if (response.statusCode == "1") {
              this.alertService.success(response.statusMessage);  
              this.reasonComment = response.reasonComment;
              if(this.userRole === USERTYPE.LAB_ADMIN ||
                this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.FLUIDWATCH_MANAGER) {
                this.router.navigate(['/registerSample/registerSampleList']);      
                }   
            }else{            
              this.alertService.error(response.statusMessage);  
            }    
        }); 
      }else{
        this.holdForm.reset();
        this.alertService.error('Form is invalid. ');
      }
      this.scrollToTop = true;
    }


  addEquip(companyId) {
    var info = {
      mode: 'add',
      companyId: companyId
    }
    this.sharedService.setAddOrEdit(info);
  }

  ngOnDestroy() {
    const removalData = {};
    this.sharedService.setAnalysisScreenCheck(removalData);
    if (this.analysisScreenSubscription) {
      this.analysisScreenSubscription.unsubscribe();
    }
    this.behaviorObs.unsubscribe();
    this.behaviorObsInternal.unsubscribe();
  }

  checkAnalysisScreen() {
    this.analysisScreenSubscription = this.sharedService.getAnalysisScreenCheck().subscribe((data) => {
      if (data != "") {
        this.analysisScreenData = data;
      }
    }
    )
  }

  editMetadata(item) {
    console.log(this.qrCodeId);
    if (this.currentItem.qrCodeId == '' || this.currentItem.qrCodeId == undefined) {
      this.isInputShown = false;
      this.isInputShownForQrcode = true;
      this.group.controls['qrCodeIdCompleteEditValue'].setValidators([Validators.required]);
      this.qrCodeValue = this.group.controls["qrCodeIdCompleteEditValue"].value;
      this.group.patchValue({
        isEdit: true,
        qrCodeIdCompleteEditValue: this.qrCodeValue

      });
    } else {
      if (this.currentItem.qrCodeId.search('-')) {
        this.prevQRCodeIdNew = this.currentItem.qrCodeId;
        this.prevAnalysisTypeNew = this.currentItem.analysisType;
        this.isInputShown = true;
        this.isInputShownForQrcode = false;
        this.group.controls['qrCodeIdEditValue'].setValidators([Validators.required]);
        this.QRSampleData = this.currentItem.qrCodeId.split('-');
        this.serialNumber = this.QRSampleData[0] + "-" + this.QRSampleData[1] + "-" + this.QRSampleData[2];
        this.isFormValid = true;
      }
      this.group.patchValue({
        isEdit: true,
        qrCodeIdEditValue: this.serialNumber,
      });
    }

    console.log(this.group.controls["qrCodeIdEditValue"].value);
  }
  qrCodeIdEdit() {
    this.isFormValid = true;
    console.log('edit validateBmsWoDropdown');
  }
  openSaveQrCode() {
    if (this.isInputShown == false) {
      this.qrCodeSave = this.group.controls["qrCodeIdCompleteEditValue"].value;;
    } else {
      this.qrCodeSave = this.group.controls["qrCodeIdEditValue"].value;
      console.log(this.qrCodeSave);
      // this.qrCodeId = this.qrCodeSave;
    }
    this.testLevelNew = this.currentItem.testLevel;
    this.objData = {
      testLevel: this.testLevelNew,
      functionName: 'ADD',
      analysisType: this.currentItem.analysisType,
      updatedBy: this.sharedService.getUserId(),
      prevQRCodeId: this.prevQRCodeIdNew,
      prevAnalysisType: this.prevAnalysisTypeNew,
      qrCodeId: this.qrCodeSave,
      companyId: this.currentItem.companyId,
      companyName: this.currentItem.companyName,

    }
    // this.obj.userRole = this.sharedService.getIntOrExtUser();
    this.registerSampleService.qrCodeValidation(this.objData).subscribe(response => {     
      if (response.statusCode == "1") {
        this.currentItem.qrCodeId = this.qrCodeSave;
        this.currentItem.testLevel = response.testLevel;
        this.currentItem.analysisType = response.fluidType;
        this.analysisType = response.fluidType;
        this.isFormValid = true;
        this.fluidFilterClear(response);  
      } else {
        this.alertService.error(response.statusMessage);
      }

    });
    this.group.patchValue({
      isEdit: false
    });   
    
  }
  fluidFilterClear(response){
    if(response.fluidType == 'Engine Oil (Liquid Fuel)'){
    response.fluidType = 1;
    } else if(response.fluidType == 'Engine Oil (Gaseous Fuel)'){
      response.fluidType = 2;
    } else if(response.fluidType == 'Coolant'){
      response.fluidType = 3;
    } else if(response.fluidType == 'Diesel Fuel'){
      response.fluidType = 4;
    } else if(response.fluidType == 'Diesel Exhaust Fluid'){
      response.fluidType = 5;
    } else if(response.fluidType == 'Lubricants'){
      response.fluidType = 6;
    }
    this.registerSampleService
    .getDropDownData(this.currentItem.analysisType, response.fluidType)
    .subscribe((res) => {
      this.populateDropDown(res);
      this.setDropDownValidation();
      this.setAddToMasterDatabaseButton();
    });
  }
  cancelMetadataEdit(item) {
    this.group.patchValue({
      isEdit: false
    });
  }


}