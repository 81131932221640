export class RegisterSampleRequestModel {

    
        userRole: string;
        companyId: string;
        pageSize: string;
        currentPage: string;
        columnName: string;
        sortingOrder: string;
        status: string;
        companyName: string
        companyIdList: any[];
}
