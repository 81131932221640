import { Component } from '@angular/core';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { RegisterSampleService } from 'src/app/View/service/register-sample.service';


@Component({
  selector: 'app-hold-date-details',
  templateUrl: './hold-date-details.component.html',
  styleUrls: ['./hold-date-details.component.css']  
})
export class HoldDateDetailsComponent {
  isSave: boolean = false;
  noOfDays: number;
  days:any;
  holdDays: number;
constructor(private registerSampleService: RegisterSampleService,
  private alertService: AlertService,
){
  
}
onClick(val){
  const req = {
    isSave: true,
    noOfDays : val,
  }   
  this.registerSampleService.holdMasterData(req).subscribe((response) => { 
    if (response.statusCode == "1") {         
      this.alertService.success(response.statusMessage);
    } else {
      this.alertService.error(response.statusMessage);
    } 
  });
  } 
}
