import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { common,USERTYPE } from '../constants/constant';
import { Router } from '@angular/router';
import { SharedService } from '../../service/shared.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ApiHandlerService } from '../../handlers/api-handler.service';
import * as  Msal from 'msal';

const batchStatus = "batchStatus";
const contextPath = "/coat";
const Yes = "Y";
const No = "N";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // @Input() userRole: string;
  readonly USERTYPE = USERTYPE;
  firstName: string = "";
  lastName: string = "";
  loginStatus = "";
  account: any;
  ifIE = false;
  userRole: string = '';
  Information = common.browserNotification;
  wwid: any;
  readonlyUser: boolean = true;
  showMenu: boolean = false;
  showSwitchUser: boolean = false;
  

  scrollToTop = false
  messageDropdown: any
  dropdownClass: boolean = false;
  
  constructor(private sharedService: SharedService,private cd: ChangeDetectorRef,
    private router: Router, private cookieService: CookieService,private api: ApiHandlerService) {
  }
  ngOnInit() {
    if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      this.dropdownClass = true;
    }
    this.sharedService.currentChange.subscribe(message=>this.messageDropdown = message);
    if(environment.Req_Server.includes('localhost')){
      this.showSwitchUser = true;
    }      
    this.login();
    this.sharedService.getErrorMessage().subscribe((data) =>{
      if(data != ''){
        this.showMenu = false; 
        this.userRole = ''; 
      }
    });
  }
  /**
   * Authenticate UI user
   * get User login details from Server
   */
  login() {    
    this.sharedService.getUserInfo().subscribe((userInfo)=>{      
      if(userInfo.hasOwnProperty('roleName')){
        this.userRole = userInfo.roleName;
        this.firstName = userInfo.firstName;
        this.lastName = userInfo.lastName;
        this.wwid = userInfo.wwid;
        this.showMenu = true;        
      }
    });    
  }
  /**
   * Logout the logged in user
   */
  logout() {
    this.sharedService.setUserInfo([]);
    this.sharedService.setExternalUserInfo([]);
    localStorage.clear();
    this.cookieService.deleteAll();
    /*if(environment.Req_Server.includes(environment.internalUrl)){
      window.location.href = environment.internalLogoutUrl;
    }else if(environment.Req_Server.includes(environment.externalUrl)){
      window.location.href = environment.externalLogoutUrl;
    }*/
    let config = {
      auth: {
          clientId: environment.clientId,
          redirectUri: "", //defaults to application start page
          postLogoutRedirectUri: ""
      }
    }

    if(environment.Req_Server.includes(environment.internalUrl)){
      config.auth.redirectUri = environment.internalUrl;
      config.auth.postLogoutRedirectUri = environment.internalUrl+'logout';
    }else if(environment.Req_Server.includes(environment.externalUrl)){
      /*config.auth.redirectUri = environment.externalUrl;
      config.auth.postLogoutRedirectUri = environment.externalUrl+'logout'; */
      window.location.href = environment.externalLogoutUrl;
      return false;
    } 
  
    const myMsal = new Msal.UserAgentApplication(config);
  
    myMsal.logout();
    }

    myInvoice() {
      let link=environment.myInvoiceMarketPlace;
      window.open(link,'_blank');
        }

   myAccount() {
      let link=environment.myAccountMarketPlace;
      window.open(link,'_blank');
        }

    myOrder() {
      let link=environment.myOrderMarketPlace;
      window.open(link,'_blank');
       }

    myAddressBook() {
      let link=environment.myAddressBookMarketPlace;
      window.open(link,'_blank');
       }

     purchaseSample()
       {
         let link=environment.purchaseSample;
         window.open(link,'_blank');
       }
}