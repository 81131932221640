import { Injectable } from '@angular/core';
import { ApiHandlerService } from 'src/app/View/handlers/api-handler.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyzeSampleGridService {

  constructor(private api: ApiHandlerService) { }

  getAnalyzeSampleDashboardData(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/getAnalyzeSampleDashboardData', data);
  }

  exportToExcel(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/analyzeSampleExcel', data);
  }

  fetchEmailNotification(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/fetchEmailNotification', data);
  }

  saveEmailNotification(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/saveEmailNotification', data);
  }

  getCompanyList(data: any): Observable<any> {
    return this.api.post(environment.apiUrl + '/insertCompanyDetails', data);
  }  
}
