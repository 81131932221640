import { Component, OnInit, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-customer-aacount-access',
  templateUrl: './customer-aacount-access.component.html',
  styleUrls: ['./customer-aacount-access.component.css']
})
export class CustomerAacountAccessComponent implements OnInit {
  
  // newItemEvent: any;
  
  selectedTab: string = '';

  constructor() { }

  ngOnInit(): void {
    this.selectedTab = 'withaccess';
  }

  addItem(ev) {
    this.selectedTabWithAccess();
  }

  selectedTabWithoutAccess() {
    this.selectedTab = 'withoutaccess';
  }

  selectedTabWithAccess() {
    this.selectedTab = 'withaccess';
  }
}
