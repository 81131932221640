import { AfterViewChecked,ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { SharedService } from 'src/app/View/service/shared.service';
import { DbuToolService } from '../dbu-tool.service';
import { common, warnMessage } from '../../../common/constants/constant';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import * as XLSX from 'xlsx';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-dbu-branch',
  templateUrl: './dbu-branch.component.html',
  styleUrls: ['./dbu-branch.component.css']
})
export class DbuBranchComponent implements OnInit,AfterViewChecked {
  readonly USERTYPE = USERTYPE;

  @ViewChildren(SortComponent) sortComponents;  
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  isSearchDone: boolean = false;    
  searchObj:any; 
  scrollToTop =  false;   
  isNewBranchdata:boolean=false;
  addBranchdataModal = false;
  saveBranchdataModal = false;
  successModal = false;
  displayResult = false;   
  successMessage: string = '';  
  selectedBranchdataGroup:UntypedFormGroup;
  deleteBranchdataModal = false;
  exportToExcelDiv: boolean = false;
  dbuBranchSearchData =[];
  filterObject = {
    branchName: [],
    locId: [],
    region: [],
    cityName: [],
    state: [],
    country: [],
    zipCode: []
  };

  dbubranchColumn = [
    { code: 'branchName', name: 'Branch Name' },        
    { code: 'locId', name: 'LOC ID' },
    { code: 'region', name: 'Region' },
    { code: 'cityName', name: 'City Name' },
    { code: 'stateProvince', name: 'State/Province' },
    { code: 'country', name: 'Country' },
    { code: 'zipCode', name: 'Zip Code' },
    { code: 'action', name: 'Actions' },
   
  ];
  searchForm = new UntypedFormGroup({
    branchName: new UntypedFormControl(''),
    locId: new UntypedFormControl(''),
    region: new UntypedFormControl(''),
    cityName: new UntypedFormControl(''),
    stateProvince: new UntypedFormControl(''),
    country: new UntypedFormControl(''),
    zipCode: new UntypedFormControl(''),
    action:new UntypedFormControl('')
  });

  addBranchdataForm = new UntypedFormGroup({    
    branchName: new UntypedFormControl('', [Validators.required]),
    locId: new UntypedFormControl('', [Validators.required]),
    region: new UntypedFormControl('', [Validators.required]),
    cityName: new UntypedFormControl('', [Validators.required]),
    stateProvince: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    zipCode: new UntypedFormControl('', [Validators.required]),
  });

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize;
  pagevalue: any;
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;  
  dbubranchList;
  branchList=[];
  locIdtoDelete: any;
  branchNametoDelete: any;
  registrationUser:any;
  dbubranchdataFormArray = new UntypedFormArray([]);
  locIdHasListedError:boolean = false;
  locIdList;
  dbubranchNameList;
  myArray = [];
  userRole: string = '';
  column:any;
  exportExcelData: any[] = [];
  constructor(
    private DbuToolService:DbuToolService,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService, private ref: ChangeDetectorRef, private sharedService: SharedService) {     
    this.pagevalue = JSON.parse(localStorage.getItem('dbubranchdetails'));
    if(this.pagevalue !=null){
      this.pageSize = JSON.parse(localStorage.getItem('dbubranchdetails'));
    }else{
      this.pageSize ='10'
    }    
  }
  
  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();
    this.columnName = '';
    this.sortingOrder = 'asc';
    this.searchBranchdata();
    this.getlocIdDropdown();
    this.isNewBranchdata = false;
    this.registrationUser = JSON.parse(localStorage.getItem('userInformation'));
    this.column = this.dbubranchColumn[this.dbubranchColumn.length-1];
    if(this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.LAB_ADMIN || this.userRole === USERTYPE.DBU_BRANCH){
      this.dbubranchColumn = [
        { code: 'branchName', name: 'Branch Name' },        
        { code: 'locId', name: 'LOC ID' },
        { code: 'region', name: 'Region' },
        { code: 'cityName', name: 'City Name' },
        { code: 'stateProvince', name: 'State/Province' },
        { code: 'country', name: 'Country' },
        { code: 'zipCode', name: 'Zip Code' },       
      ];
    }

  }
   getlocIdDropdown(){
    this.myArray = [];
    let _data = {
      "functionName": "DROPDOWN",
    }
    this.DbuToolService.dbuBranchOperations(_data).subscribe(response => {
      for (var i=0; i < response.dbuBranchDTOList.length; i++) {
        this.locIdList = response.dbuBranchDTOList[i].locId;
        this.myArray.push(this.locIdList)
      }
    },err => {
        this.alertService.error(err);        
    });
  }
  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  
  searchBranchdata(){
    // this.isNewBranchdata = false;
    let data = this.searchForm.getRawValue();
    data['pageSize'] = this.pageSize.toString();
    data['currentPage'] = this.currentPage;
    data['columnName'] = this.columnName;
    data['sortingOrder'] = this.sortingOrder;
    data['functionName'] = "SEARCH";
    this.searchObj = data;
    this.DbuToolService.dbuBranchOperations(data).subscribe(response => {
      if (response.statusCode == "1") {
        this.dbuBranchSearchData = response.dbuBranchDTOList;
        this.totalItems = response.dbuBranchDTOList[0].maxRow;
        this.displayResult = true;
        this.exportToExcelDiv = true;
        this.calculateOffset();
         //form Array creation
         this.dbubranchdataFormArray = new UntypedFormArray([]);
         this.dbuBranchSearchData.forEach((row) => {
           
             const formGroup = new UntypedFormGroup({
               branchName: new UntypedFormControl(row.branchName, [Validators.required]),
               locId: new UntypedFormControl(row.locId,[Validators.required]),
               region: new UntypedFormControl(row.region, [Validators.required]),
               cityName: new UntypedFormControl(row.cityName, [Validators.required]),
               stateProvince: new UntypedFormControl(row.stateProvince, [Validators.required]),
               country: new UntypedFormControl(row.country, [Validators.required]),
               zipCode: new UntypedFormControl(row.zipCode, [Validators.required]),
               isEdit: new UntypedFormControl(false),
             });
             this.dbubranchdataFormArray.push(formGroup);
         });         
         
      }else{
        this.dbubranchdataFormArray = new UntypedFormArray([]);
        this.dbuBranchSearchData = [];
        this.displayResult = true;
        this.exportToExcelDiv = false;
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    },err => {
        this.alertService.error(err);     
        this.scrollToTop = true;
   
    });
  }

  addNewBranchdata(){
    this.isNewBranchdata = true;
    this.locIdHasListedError = false;

  }

  hideNewBranchdata(){
    this.isNewBranchdata = false;
    this.locIdHasListedError = false;
    this.addBranchdataForm.reset();
  }

  openAddBranchdataModal(){
    if(!(this.addBranchdataForm.invalid) && this.locIdHasListedError == false){
      this.addBranchdataModal = true;
     }else{
      this.addBranchdataModal = false;      
      Object.keys(this.addBranchdataForm.controls).forEach(field => { 
        const control = this.addBranchdataForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAddBranchdataModal(){
    this.addBranchdataModal = false;
  }

  closeSaveBranchdataModal(){
    this.saveBranchdataModal = false;
  }

  saveBranchdata(){
    let data = this.selectedBranchdataGroup.getRawValue();
    data["functionName"] = "ADD";
    data["userId"] = this.registrationUser.wwid;
    this.DbuToolService.dbuBranchOperations(data).subscribe(response =>{       
        if (response.statusCode == "1") {  
         this.searchBranchdata();
         this.getlocIdDropdown();        
         this.successMessage = response.statusMessage;
         this.successModal = true; 
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.saveBranchdataModal = false; 
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
        this.saveBranchdataModal = false;
      });
  }

  closeDeleteBranchdataModal(){
    this.deleteBranchdataModal = false;
  }

  deleteBranchData(){    
    let _data = {
      "functionName": "DELETE",
      "locId": this.locIdtoDelete      
    }
    if(this.dbuBranchSearchData.length == 1){
      this.searchForm.reset();
    }
    this.DbuToolService.dbuBranchOperations(_data).subscribe(response =>{       
        if (response.statusCode == "1") {         
         this.successMessage = response.statusMessage;
         this.successModal = true; 
         this.searchBranchdata();   
         this.getlocIdDropdown();  
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.deleteBranchdataModal = false; 
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
        this.deleteBranchdataModal = false;
      });
  }  

  addBranchdata(){
    this.addBranchdataForm.patchValue({
      locId : this.addBranchdataForm.controls["locId"].value.toUpperCase()
    })
    let data=this.addBranchdataForm.getRawValue(); 
    data["functionName"] = "ADD";
    data["userId"] = this.registrationUser.wwid;
    this.DbuToolService.dbuBranchOperations(data).subscribe(response => {
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;        
        this.isNewBranchdata = false;
        this.locIdHasListedError =false;
        this.addBranchdataForm.reset();
        this.searchBranchdata();
        this.getlocIdDropdown();
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.addBranchdataModal = false;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addBranchdataModal = false;
    });
  }

  editBranchdata(item){
    item.patchValue({
      isEdit: true
    });
  }

  openBranchdataDeleteModal(item){
    this.locIdtoDelete = item.controls.locId.value;
    this.branchNametoDelete = item.controls.branchName.value;
    this.selectedBranchdataGroup = item;
    this.deleteBranchdataModal = true;
  }

  cancelBranchdataEdit(item,i){
    item.patchValue({
      isEdit:false,
      branchName: this.dbuBranchSearchData[i].branchName,
      locId: this.dbuBranchSearchData[i].locId,
      region: this.dbuBranchSearchData[i].region,
      cityName: this.dbuBranchSearchData[i].cityName,
      stateProvince: this.dbuBranchSearchData[i].stateProvince,
      country: this.dbuBranchSearchData[i].country,
      zipcode: this.dbuBranchSearchData[i].zipcode
    });
  }

  openSaveBranchdataModal(item){
    if(!item.invalid){
      this.selectedBranchdataGroup = item;
      this.saveBranchdataModal = true;
    }else{
      this.saveBranchdataModal = false;   
      Object.keys(item.controls).forEach(field => { 
        const control = item.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  validatelocIdDropdown(){
    let locId;

    locId  = this.myArray.find(i => i == this.addBranchdataForm.controls["locId"].value.toUpperCase()) ;
    if(locId == this.addBranchdataForm.controls["locId"].value.toUpperCase() ){
      this.locIdHasListedError = true;
    }else{
      this.locIdHasListedError = false;
    }
   }
  searchFilter(str) {
    let search = (this.searchForm.value[str]).toLowerCase();
    if(search == '') {
      this.filterObject[str] = [];
      this.searchBranchdata();
    }
    let inputData = this.searchForm.getRawValue();
    inputData['searchColumn'] = str;
    inputData['searchValue'] = this.searchForm.value[str];   
    inputData['functionName'] = "SEARCH";

    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);
    if(inputData['searchValue'].length >= 2){
      this.DbuToolService.dbuBranchOperations(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.filterObject[str] = response.dbuBranchDTOList;
      } else {
        this.filterObject[str] = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
        this.alertService.clear();
        window.sessionStorage.removeItem('dbubranchdetails');
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }
    else if(this.searchForm.value[str].length == 0){
      this.clearSearch(str)
      this.filterObject[str] == [];
      this.filterObject[str].length = 0;

    }
  
  }

  isSearchable(column) {
    if(column == 'branchName' || column == 'locId' || column == 'region' || 
      column == 'cityName' || column == 'stateProvince' || column =='country' || column == 'zipCode'){
      return true;
    }else{
      return false;
    }
  }

  optionSearch(){
    this.currentPage = 1;
    this.searchBranchdata();
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.searchBranchdata();
    }
  }
  clearSearchfilters(){
    this.alertService.clear();
    this.pageSize='10';
    window.sessionStorage.removeItem('dbubranchdetails');       
    this.searchForm.patchValue({branchName:''})
    this.searchForm.patchValue({locId:''})
    this.searchForm.patchValue({region:''})
    this.searchForm.patchValue({cityName:''})
    this.searchForm.patchValue({stateProvince:''})    
    this.searchForm.patchValue({country:''})
    this.searchForm.patchValue({zipCode:''})
    this.searchBranchdata();
    this.loadPage(1);
  }
  exportToExcel() {     
    let _data = {
      'functionName': "EXCEL",
      'columnName' : this.columnName,
      'sortingOrder': this.sortingOrder,
      "branchName":this.searchForm.value['branchName'],
      "locId":this.searchForm.value['locId'],
      "region":this.searchForm.value['region'],
      "cityName":this.searchForm.value['cityName'],
      "stateProvince":this.searchForm.value['stateProvince'],
      "country":this.searchForm.value['country'],
      "zipCode":this.searchForm.value['zipCode'],
    }
 
    this.DbuToolService.dbuBranchOperations(_data).subscribe(response => {
      if (response.hasOwnProperty('errorMessage') && response.errorMessage == 'body size is too long') {
        this.alertService.error('There are too many records to complete this operation. Fine tune the filters and export the samples');
      } else if (response.statusCode == "1") {
        console.log(response)
        this.exportExcelData = this.DbuToolService.convertDataUppercase(response.excelDatas);
        this.exportAsExcelFile(this.exportExcelData, 'Dbu_Branch');
        //console.log(this.exportExcelData + "this is the response");
      } else {
        this.alertService.error(response.statusMessage);
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // this.convertHeaderToUppercase(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // this.wrapAndCenterCell(worksheet.G1);
    const workbook: XLSX.WorkBook = { Sheets: { 'Dbu Branch': worksheet }, SheetNames: ['Dbu Branch'] };
    XLSX.writeFile(workbook, `${excelFileName}_export.xlsx`);
  }

  // Pagination

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;    
    //this.fluidFilterSearchData =[];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('dbubranchdetails', JSON.stringify(this.pageSize));
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {        
        //this.fluidFilterSearchData =[];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

    /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.searchBranchdata();
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.searchBranchdata();    
  }

  getArray(): any[] {
    return Array(this.maxPage);
  }

}
