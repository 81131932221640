import { Directive } from "@angular/core";
import { NG_VALIDATORS, Validator, UntypedFormControl, Validators } from "@angular/forms";

// @Directive({
//     selector: "[requireFile]",
//     providers: [
//         { provide: NG_VALIDATORS, useExisting: FileValidator, multi: true },
//     ]
// })
export class FileValidator implements Validator {
  static validate(c: UntypedFormControl): { [key: string]: any } {
    return c.value == null || c.value.length == 0 ? { "required": true } : null;
  }

  validate(c: UntypedFormControl): { [key: string]: any } {
    return FileValidator.validate(c);
  }
}
export class FileExtensionValidator implements Validators {
  static validate(c: UntypedFormControl): { [key: string]: any } {

    const file = c.value;
    if (file) {
      const extension = file.split('.')[1].toLowerCase();
      if (extension.toLowerCase() != 'xlsx' && extension.toLowerCase() != 'xls' && extension.toLowerCase() != 'csv') {
        return {
          fileformat: true
        };
      }
      return null;
    }
    return null;
  }

  validate(c: UntypedFormControl): { [key: string]: any } {
    return FileExtensionValidator.validate(c);
  }
}