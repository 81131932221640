import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { element } from 'protractor';
import { identity } from 'rxjs';
import { elementAt } from 'rxjs-compat/operator/elementAt';
import { SharedService } from '../../service/shared.service';
import { AlertService } from '../alert/alert.service';
import { CustomerNameDropdownService } from './customer-name-dropdown.service';
import { common,USERTYPE } from '../constants/constant';
import { Router } from '@angular/router';


@Component({
  selector: 'app-customer-name-dropdown',
  templateUrl: './customer-name-dropdown.component.html',
  styleUrls: ['./customer-name-dropdown.component.css']
})
export class CustomerNameDropdownComponent implements OnInit {

  // @Input() userRole: string;
  readonly USERTYPE = USERTYPE;
  userRole: string = '';
  firstName: string = "";
  lastName: string = "";
  wwid: any;

  scrollToTop =  false;

  customerForm = new UntypedFormGroup({
    distributerLocDropdown: new UntypedFormControl(''),
    customerNameDropdown: new UntypedFormControl('')
  });

  dbuBranchList: any;
  dbuBranchListCopy: any;
  customerList: any;
  customerListCopy: any;
  customerMappingList: any;
  customerListSelected = [];

  locIdSelected: any;
  branchNameSelected: any;
  customerIdSelected: any;
  customerNameSelected: any;

  dbuBranchSelected: any;
  customerSelected: any;
  isCustomerNameDisable:boolean = true;

  selectedBranchDetails : any;
  selectedCompanyDetails : any;
  isBranchNotSeen: boolean = false;
  isCompanyNotSeen: boolean = false;

  customerListTSM: any;
  customerListTSMCopy: any;
  customerIdSelectedTSM: any;

  isdisplayresult: boolean = false;
  urlToAvoid: any = [
    '/customerAccount',
    '/accountAccess',
    '/dbuTool',
    '/scatterPlotGrid'
  ];
  companyNamePlaceHolder: string = "Company Name";
  isCustomerAccessOpened: boolean = false;
  currentItem: any;
  

  constructor( 
    private alertService: AlertService,
    private customerNameDropdownService: CustomerNameDropdownService,
    private sharedService: SharedService,
    private location: Location,
    private router: Router
  ) { 
      location.onUrlChange((val) => {
        if ( this.urlToAvoid.includes(val) ) {
          this.isdisplayresult = true
        } else {
          this.isdisplayresult = false
        }

        if(this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER){
          if(val == '/registerSample/addEditReviewSample'){
            this.currentItem = JSON.parse(localStorage.getItem('registerSampleObject'));
            if(this.currentItem['isSampleReg'] == true){
              this.isdisplayresult = false;
            }
            else if(this.currentItem['status'] == 'Hold'){
              this.isdisplayresult = false;
            }
            else{
              this.isdisplayresult = true;
            }
          }else{
            this.isdisplayresult = true;
          }
        }
      })
    }
  
  @Output() event = new EventEmitter<string>()
  
  ngOnInit(): void {
    this.userRole = this.sharedService.getUserRole();
    this.login();
    // console.log(this.wwid);

    if( this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS ){
      this.dbuRole();
    } else if ( this.userRole == USERTYPE.TSM ){
      this.tsmRole();
    }
     else if ( this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER){
      this.companyNamePlaceHolder = "Company Name/ID"
      if(this.router.url != '/registerSample/addEditReviewSample'){
        this.isdisplayresult = true;
      }
      this.internalRole();
    }
    else if ( (this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) && this.currentItem.status == 'Hold' ){
      this.companyNamePlaceHolder = "Company Name/ID"
      if(this.router.url != '/registerSample/addEditReviewSample'){
        this.isdisplayresult = true;
      }
      this.internalRole();
      this.customerForm.reset();
    }

    this.location.onUrlChange((val=>{
      // console.log(val);
      if (this.userRole == USERTYPE.TSM) {
        if (val == '/accountAccess') {
          this.isCustomerAccessOpened = true;        
        } else {
          if (this.isCustomerAccessOpened) {
            this.tsmRole();
            this.isCustomerAccessOpened = false;
          }
        }
      }

      if( this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER){
        if( val == '/registerSample/reviewSampleList'){
          this.customerForm.reset();
        }
      }
    }))
    
  }

  // dbuRole1(){
  //   this.customerForm.controls['customerNameDropdown'].disable()
  //   //calling service
  //   let _data = { "functionName": "DROPDOWN" }
  //   this.customerNameDropdownService.getDropdownData(_data).subscribe(
  //     response => {
  //       // console.log(response.dbuBranchList);
  //       this.dbuBranchList = response.dbuBranchList;
  //       this.dbuBranchListCopy = this.dbuBranchList;
  //       this.customerList = response.customerList;
  //       this.customerMappingList = response.branchCustomerList;

  //       //retriving from local storage
  //       if(localStorage.getItem("branchCustomerDropdown")){
  //         this.customerForm.controls['customerNameDropdown'].enable()
  //         let a  = JSON.parse(localStorage.getItem("branchCustomerDropdown"));
  //         // console.log(a);
  //         this.locIdSelected = a.locId;
  //         this.branchNameSelected = a.branchName;
  //         this.customerIdSelected = a.customerId;
  //         this.customerNameSelected = a.customerName;
  //         // this.customerListSelected.push({customerId: "all", customerName: "All"});
  //         this.customerMappingList.forEach(element => {
  //           if(element.locId == this.locIdSelected){
  //             this.customerListSelected.push(element)
  //           }
  //         });
  //         this.customerListSelected.push({customerId: "notListed", customerName: "Not Listed"})
  //         this.customerListCopy = this.customerListSelected;

  //         this.customerForm.patchValue({
  //           distributerLocDropdown: this.locIdSelected,
  //           customerNameDropdown: this.customerIdSelected
  //         });
  //       }
  //       //finish retriving
  //     }, err => {
  //       this.alertService.error(err);
  //       this.scrollToTop = true;
  //     }
  //   );
  // }

  dbuRole(){
    this.customerForm.controls['customerNameDropdown'].disable();
    let _data = {
      functionName: "SEARCH",
      userRole: this.userRole,
      wwid: this.wwid
      // wwid: 'nq212'
    }
    this.customerNameDropdownService.getDropdownDataforTSM(_data).subscribe(
      response => {
        console.log(response);
        this.dbuBranchList = response.wwidAccessDTOList;
        this.dbuBranchListCopy = response.wwidAccessDTOList;
        if(localStorage.getItem("branchCustomerDropdown")){
          this.customerForm.controls['customerNameDropdown'].enable()
          let a  = JSON.parse(localStorage.getItem("branchCustomerDropdown"));
          // console.log(a);
          this.locIdSelected = a.locId;
          this.branchNameSelected = a.branchName;
          this.customerIdSelected = a.customerId;
          this.customerNameSelected = a.customerName;
          // this.customerListSelected.push({customerId: "all", customerName: "All"});
          
          this.dbuBranchListCopy.forEach(element => {
            if(element.locId == this.locIdSelected){
              this.selectedBranchDetails = element;
              this.isBranchNotSeen = true
              let _data = {
                functionName: "SEARCH",
                locId: this.locIdSelected
              }
              this.customerNameDropdownService.getDropdownData(_data).subscribe(
                response => {
                  console.log(response);
                  if(response.statusCode == '1'){
                    this.customerList = response.branchCustMappingDTOList;
                    this.customerList.push({customerId: "notListed", customerName: "Not Listed"});
                    this.customerListCopy = this.customerList;
                    this.customerListCopy.forEach(element => {
                      if (element.customerId == this.customerIdSelected) {
                        this.customerForm.patchValue({
                          distributerLocDropdown: this.locIdSelected,
                          customerNameDropdown: this.customerIdSelected
                        });
                        this.isCompanyNotSeen = true;
                      }
                    });
                  } else if(response.statusMessage == "No Records Present"){
                    this.customerList = [];
                    this.customerList.push({customerId: "notListed", customerName: "Not Listed"});
                    this.customerListCopy = this.customerList;
                    localStorage.removeItem("branchCustomerDropdown");
                  } else {
                    this.alertService.error(response.statusMessage);
                  }
                  if(!this.isCompanyNotSeen){
                    localStorage.removeItem("branchCustomerDropdown");
                  }
                }, err => {
                  this.alertService.error(err);
                }
              );
            }
          });
          if(!this.isBranchNotSeen){
            localStorage.removeItem("branchCustomerDropdown");
          }
        }
        console.log(this.isBranchNotSeen,this.isCompanyNotSeen);
        
      }
    )
  }

  tsmRole(){
    this.customerForm.controls['customerNameDropdown'].enable();
    let _data = {
      "functionName" : "SEARCH",
      "wwid" : this.wwid,
      // "wwid" : "tf818",
      "userRole": "TSM"
    };
    
    this.customerNameDropdownService.getDropdownDataforTSM(_data).subscribe(response => {
        console.log(response);
        let customerIdFound = false;
        this.customerList = response.wwidAccessDTOList;
        this.customerListCopy = response.wwidAccessDTOList;

        if(localStorage.getItem("customerDropdownTSM")){
          let a  = JSON.parse(localStorage.getItem("customerDropdownTSM"));
          this.customerIdSelectedTSM = a.customerId;
          this.customerListCopy.forEach(element => {
            if(element.customerId == this.customerIdSelectedTSM){
              this.customerForm.patchValue({
                customerNameDropdown: this.customerIdSelectedTSM
              });
              customerIdFound = true;
            }
          });
          if (!customerIdFound) {
            localStorage.removeItem("customerDropdownTSM");
          }
        }
        
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  internalRole(){
    this.customerForm.controls['customerNameDropdown'].enable();
    let _data = {
      userRole: "intUser"
    };
    
    this.customerNameDropdownService.getEquipmentDropdownData(_data).subscribe(response => {
        this.customerList = response.companyIdList;
        this.customerList.push({companyId: "notListed", companyName: "Not Listed"});
        this.customerListCopy = this.customerList;        
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  login() {    
    this.sharedService.getUserInfo().subscribe((userInfo)=>{      
      if(userInfo.hasOwnProperty('roleName')){
        this.userRole = userInfo.roleName;
        this.firstName = userInfo.firstName;
        this.lastName = userInfo.lastName;
        this.wwid = userInfo.wwid; 
      }
    });    
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  customerNameChange(){
    if( this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS ){
      this.customerNameChangeDBU();
    } else if ( this.userRole == USERTYPE.TSM ){
      this.customerNameChangeTSM();
    } else if (this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER){
      this.customerNameChangeInternal();
    }
  }

  // customerNameChangeDBU1(){
  //   this.customerIdSelected = this.customerForm.controls.customerNameDropdown.value;
  //   if (this.customerIdSelected == 'notListed') {
  //     this.customerSelected = {customerId: "notListed", customerName: "Not Listed"}
  //   } else {
  //     this.customerList.forEach(element => {
  //       if(element.customerId == this.customerIdSelected){
  //         this.customerSelected = element;
  //       }
  //     });
  //   }
  //   // console.log(this.customerSelected);
  //   this.customerSelected.branchName = this.branchNameSelected;
  //   this.customerSelected.locId = this.locIdSelected;
  //   // this.event.emit(this.customerSelected);
  //   this.sharedService.dropDownFunction(this.customerSelected)
  //   localStorage.setItem("branchCustomerDropdown", JSON.stringify(this.customerSelected));
  //   // console.log(this.customerSelected);
  // }

  customerNameChangeDBU(){
    this.customerIdSelected = this.customerForm.controls.customerNameDropdown.value;
    if (this.customerIdSelected == 'notListed') {
      this.customerSelected = {
        customerId: "notListed", 
        customerName: "Not Listed",
        locId: this.selectedBranchDetails.locId,
        branchName: this.selectedBranchDetails.branchName
      }
    } else {
      this.customerListCopy.forEach(element => {
        if(element.customerId == this.customerIdSelected){
          this.selectedCompanyDetails = element;
          this.customerSelected = this.selectedCompanyDetails;
        }
      });
    }
    this.customerSelected.branchCity = this.selectedBranchDetails.branchCity;
    this.customerSelected.branchCountry = this.selectedBranchDetails.branchCountry;
    this.customerSelected.branchState = this.selectedBranchDetails.branchState;
    this.customerSelected.branchZipCode = this.selectedBranchDetails.branchZipCode;
    
    this.sharedService.dropDownFunction(this.customerSelected)
    localStorage.setItem("branchCustomerDropdown", JSON.stringify(this.customerSelected));
  }

  customerNameChangeTSM(){
    this.customerIdSelected = this.customerForm.controls.customerNameDropdown.value;
    this.customerList.forEach(element => {
      if(element.customerId == this.customerIdSelected){
        this.customerSelected = element;
      }
    });
    console.log(this.customerSelected);
    
    this.sharedService.dropDownFunctionTSM(this.customerSelected)
    localStorage.setItem("customerDropdownTSM", JSON.stringify(this.customerSelected));
  }

  customerNameChangeInternal(){
    this.customerIdSelected = this.customerForm.controls.customerNameDropdown.value;
    this.customerList.forEach(element => {
      if (element.companyId == this.customerIdSelected) {
        this.customerSelected = element;
      }
    });
    
    this.sharedService.dropDownFunctionInternal(this.customerSelected);
  }

  // distributerLocationChange1(){
  //   this.customerForm.controls['customerNameDropdown'].enable()
  //   this.customerListSelected = [];
  //   this.customerForm.patchValue({
  //     customerNameDropdown : ''
  //   });
  //   console.log(this.customerForm.controls.distributerLocDropdown.value);
  //   this.locIdSelected = this.customerForm.controls.distributerLocDropdown.value;
  //   this.dbuBranchList.forEach(element => {
  //     if(element.locId == this.locIdSelected){
  //       this.branchNameSelected = element.branchName;
  //     }
  //   });

  //   // this.customerListSelected.push({customerId: "all", customerName: "All"});
  //   this.customerMappingList.forEach(element => {
  //     if(element.locId == this.locIdSelected){
  //       this.customerListSelected.push(element)
  //     }
  //   });
  //   this.customerListSelected.push({customerId: "notListed", customerName: "Not Listed"})
  //   this.customerListCopy = this.customerListSelected;
  //   console.log(this.customerListSelected);

  //   this.customerSelected = {}
  //   this.customerSelected.branchName = this.branchNameSelected
  //   this.customerSelected.locId = this.locIdSelected
  //   this.customerSelected.customerId = ""
  //   this.customerSelected.customerName = ""

  //   console.log(this.customerSelected);
  //   this.sharedService.dropDownFunction(this.customerSelected)
  //   localStorage.setItem("branchCustomerDropdown", JSON.stringify(this.customerSelected));
  // }

  distributerLocationChange(){
    this.customerForm.controls['customerNameDropdown'].enable()
    this.customerIdSelected = [];
    this.customerListCopy = [];
    this.customerForm.patchValue({
      customerNameDropdown : ''
    });
    let _data = {
      functionName: "SEARCH",
      // branchName: 
      locId: this.customerForm.controls['distributerLocDropdown'].value
    }
    this.customerNameDropdownService.getDropdownData(_data).subscribe(
      response => {
        console.log(response);
        if(response.statusCode == '1'){
          this.customerList = response.branchCustMappingDTOList;
          this.customerList.push({customerId: "notListed", customerName: "Not Listed"});
          this.customerListCopy = this.customerList;
        } else if(response.statusMessage == "No Records Present"){
          this.customerList = [];
          this.customerList.push({customerId: "notListed", customerName: "Not Listed"});
          this.customerListCopy = this.customerList;
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
    this.dbuBranchListCopy.forEach(element => {
      if(element.locId == this.customerForm.controls.distributerLocDropdown.value){
        this.selectedBranchDetails = element;
      }
    });
    this.customerSelected = this.selectedBranchDetails;

    console.log(this.customerSelected);
    this.sharedService.dropDownFunction(this.customerSelected)
    localStorage.setItem("branchCustomerDropdown", JSON.stringify(this.customerSelected));
  }

  onKey(value) { 
    this.dbuBranchListCopy = [];
    console.log(value)
    this.selectSearch(value);
  }
  selectSearch(value:string){
    let filter = value.toLowerCase();
    for ( let i = 0 ; i < this.dbuBranchList.length; i ++ ) {
      let option = this.dbuBranchList[i].branchName;
      let option1 = this.dbuBranchList[i].locId;
      if (option.toLowerCase().indexOf(filter) >= 0 || option1.toLowerCase().indexOf(filter) >= 0) {
        this.dbuBranchListCopy.push( this.dbuBranchList[i] );
      }
    }
  }
  clearSearch(){
    this.dbuBranchListCopy = this.dbuBranchList;
  }

  onKeyCustomer(value){
    this.customerListCopy = [];
    this.selectSearchCustomer(value);
  }
  selectSearchCustomer(value:string){
    let filter = value.toLowerCase();
    if( this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS ){
      for ( let i = 0 ; i < this.customerList.length; i ++ ) {
        let option = this.customerList[i].customerName;
        if (option.toLowerCase().indexOf(filter) >= 0) {
          this.customerListCopy.push( this.customerList[i] );
        }
      }
    } else if ( this.userRole == USERTYPE.TSM ){
      this.customerList.forEach(element => {
        let option = element.customerName;
        if(option.toLowerCase().indexOf(filter)>=0){
          this.customerListCopy.push(element)
        }
      });
    } else if ( this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.FLUIDWATCH_MANAGER){
      this.customerList.forEach(element => {
        if(element.companyName.toLowerCase().indexOf(filter)>=0 || element.companyId.toLowerCase().indexOf(filter)>=0){
          this.customerListCopy.push(element);
        }
      })
    }
    
  }
  
  clearSearchCustomer(){
    if( this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS ){
      this.customerListCopy = this.customerList;
    } else if ( this.userRole == USERTYPE.TSM ){
      this.customerListCopy = this.customerList
    } else if( this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.FLUIDWATCH_MANAGER ){
      this.customerListCopy = this.customerList;
    }
  }
}