import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dbu-tool',
  templateUrl: './dbu-tool.component.html',
  styleUrls: ['./dbu-tool.component.css']
})

export class DbuToolComponent implements OnInit {

  selectedTab: string = '';
  scrollToTop =  false; 

  constructor() { }

  ngOnInit() {
    this.selectedTab = 'dbuBranch';
  } 

  selectedTabDbuBranch() {
    this.selectedTab = 'dbuBranch';
  }

  selectedTabBranchCustomerMapping() {    
    this.selectedTab = 'branchCustomerMapping'; 
  }

  selectedTabWwidAccess() {    
    this.selectedTab = 'wwidAccess'; 
  }
}