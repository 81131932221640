import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiHandlerService } from '../../handlers/api-handler.service';

@Injectable({
  providedIn: 'root'
})
export class DbuToolService {
 

  constructor(private api: ApiHandlerService, private http: HttpClient) { }

  dbuBranchOperations(data:any) : Observable<any>{
    return this.api.post(environment.apiUrl +'/dbuBranchOperations',data);
  }

  branchCustomerMapping(data:any) : Observable<any>{
    return this.api.post(environment.apiUrl +'/branchCustomerMapping',data);
  }
  
  wwidBranchAccess(data:any) : Observable<any>{
    return this.api.post(environment.apiUrl +'/wwidBranchAccess',data);
  }
  
  convertDataUppercase(excelData: any) {
    let upperCaseArrayData = excelData.map(obj => {
      let newObj = {};
      for(let key in obj) {
        newObj[key] = typeof obj[key] === 'string' ? obj[key].toUpperCase() : obj[key];
      }
      return newObj;
    });
    console.log(upperCaseArrayData);
    return upperCaseArrayData;
  }
}
