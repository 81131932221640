import { Component, OnInit } from '@angular/core';
import { SampleService } from './sample.service';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.css']
})
export class SampleComponent implements OnInit {
  samplename: string = "";
  responsejson: any = undefined;
  constructor(private sampleservice: SampleService) { }

  ngOnInit() {
  }
  search() {
    let _data = {
      "name": this.samplename
    }
    this.responsejson = undefined;
    this.sampleservice.getname(_data).subscribe(response => {
      this.responsejson = response
    });


  }

}
