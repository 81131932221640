import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './View/common/home/home.component';
import { SampleComponent } from './View/core/sample/sample.component';
import { UserTypeSelectionComponent } from './View/core/user-type-selection/user-type-selection.component';
import { ExternalUserLoginComponent } from './View/core/external-user-login/external-user-login.component';
import { ManageFleetComponent } from './View/core/manage-fleet/manage-fleet.component';
import { MoreInformationComponent } from './View/core/manage-fleet/more-information/more-information.component';
import { EditVehicleComponent } from './View/core/edit-vehicle/edit-vehicle.component';
import { AdassertComponent } from './assert/adassert/adassert.component';
import { IamassertComponent } from './assert/iamassert/iamassert.component';
import { AnalyzeSampleGridComponent } from './View/core/analyze-sample/analyze-sample-grid/analyze-sample-grid.component';
import { AnalyzeSampleInformationComponent } from './View/core/analyze-sample/analyze-sample-information/analyze-sample-information.component';
import { PopulationGridComponent } from './View/core/population-management/population-grid/population-grid.component';
import { GridComponent } from './View/core/poc/grid/grid.component';
import { FormComponent } from './View/core/poc/form/form.component';
import { AddPopulationComponent } from './View/core/population-management/add-population/add-population.component';
import { LimitFlaggingComponent } from './View/core/population-management/limit-flagging/limit-flagging.component';
import { ModifyThresholdComponent } from './View/core/population-management/modify-threshold/modify-threshold.component';
import { EditKeyIssuesComponent } from './View/core/population-management/edit-key-issues/edit-key-issues.component';
import { GraphPlotComponent } from './View/core/analyze-sample/graph-plot/graph-plot.component';
import { ViewReportComponent } from './View/core/analyze-sample/view-report/view-report.component';
import { SampleDashboardHomeComponent } from './View/core/sample-dashboard/sample-dashboard-home/sample-dashboard-home.component';
import { ScatterPlotComponent } from './View/core/sample-dashboard/scatterplot/scatter-plot/scatter-plot.component';
import { LineGraphComponent } from './View/core/sample-dashboard/linegraph/line-graph/line-graph.component';
import { LineGraphHomeComponent } from './View/core/sample-dashboard/linegraph/line-graph-home/line-graph-home.component';
import { ScatterPlotGridComponent } from './View/core/sample-dashboard/scatterplot/scatter-plot-grid/scatter-plot-grid.component';
import { AccessDeniedComponent } from './View/common/access-denied/access-denied.component';
import { NotFoundComponent } from './View/common/not-found/not-found.component';
import { LogoutComponent } from './View/common/logout/logout.component';
import { UploadUnitComponent } from './View/core/manage-fleet/upload-unit/upload-unit.component';
import { ExternalLoginComponent } from './View/core/external-login/external-login.component';
import { AdminPortalComponent } from './View/core/admin-portal/admin-portal.component';
import { MasterDataManagementComponent } from './View/core/master-data-management/master-data-management.component';
import { ViewReportHomeComponent } from './View/core/analyze-sample/view-report-home/view-report-home.component';
import { ReportHomeComponent } from './View/core/reports/report-home/report-home.component';
import { FinanceReportComponent } from './View/core/reports/finance-report/finance-report.component';
import { TsmAeReportComponent } from './View/core/reports/tsm-ae-report/tsm-ae-report.component';
import { MetadataInfoComponent } from './View/core/population-management/metadata-info/metadata-info.component';
import { OpsTotReportComponent } from './View/core/reports/ops-tot-report/ops-tot-report.component';
import { OpsKeyissueReportComponent } from './View/core/reports/ops-keyissue-report/ops-keyissue-report.component';
import { OpsCountReportComponent } from './View/core/reports/ops-count-report/ops-count-report.component';
import { OpsReportHomeComponent } from './View/core/reports/ops-report-home/ops-report-home.component';
import { FleetReportHomeComponent } from './View/core/reports/fleet-report-home/fleet-report-home.component';
import { FleetUnitsOdiReportComponent } from './View/core/reports/fleet-units-odi-report/fleet-units-odi-report.component';
import { FleetSampleTotReportComponent } from './View/core/reports/fleet-sample-tot-report/fleet-sample-tot-report.component';
import { FleetUnitIdCountReportComponent } from './View/core/reports/fleet-unit-id-count-report/fleet-unit-id-count-report.component';
import { CalendarComponent } from './View/core/calendar/calendar.component';
import { CustomerAccountComponent } from './View/core/customer-account/customer-account.component';
import { DbuToolComponent } from './View/core/dbu-tools/dbu-tool.component';
import { CustomerAacountAccessComponent } from './View/core/customer-aacount-access/customer-aacount-access.component';
import { AccountGroupManagementComponent } from './View/core/account-group-management/account-group-management.component';
import { AddGroupComponent } from './View/core/account-group-management/add-group/add-group.component';
import { CustomerEmailNotificationComponent } from './View/core/customer-email-notification/customer-email-notification.component';
import { AddSubscriptionComponent } from './View/core/customer-email-notification/add-subscription/add-subscription.component';
import { AddEditVehicleComponent } from './View/core/add-vehicle/add-edit-vehicle.component';
import { RegisterSampleListComponent } from './View/core/register-sample/register-sample-list/register-sample-list.component';
import { RegisterSampleListResolverService } from './View/service/resolvers/register-sample-list-resolver.service';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'adassert', component: AdassertComponent },
  { path: 'iamassert', component: IamassertComponent },
  { path: 'home', component: HomeComponent },
  { path: 'userType', component: UserTypeSelectionComponent },
  { path: 'externalUserLogin', component: ExternalUserLoginComponent },
  { path: 'externalLogin', component: ExternalLoginComponent },
  { path: 'manageFleet', component: ManageFleetComponent },
  { path: 'moreInformation', component: MoreInformationComponent },
  { path: 'editVehicle', component: AddEditVehicleComponent },
  { path: 'addEditVehicle', component: AddEditVehicleComponent },
  { path: 'sample', component: SampleComponent },
  { path: 'registerSample', loadChildren: () => import('./View/core/register-sample/register-sample.module').then(m => m.RegisterSampleModule) },
  { path: 'analyzeSample', component: AnalyzeSampleGridComponent },
  { path: 'analyzeSampleInformation', component: AnalyzeSampleInformationComponent },
  { path: 'populationManagement', component: PopulationGridComponent },
  { path: 'metadataInfo', component: MetadataInfoComponent },
  { path: 'addPopulation', component: AddPopulationComponent },
  { path: 'limitFlag', component: LimitFlaggingComponent },
  { path: 'modifyThreshold', component: ModifyThresholdComponent },
  { path: 'editKeyIssues', component: EditKeyIssuesComponent },
  { path: 'getGraph', component: GraphPlotComponent },
  { path: 'viewReport', component: ViewReportComponent },
  { path: 'emailSampleReport/:sampleId', component: ViewReportComponent },
  { path: 'registerSample/registerSampleList', component: RegisterSampleListComponent, resolve: { data: RegisterSampleListResolverService } },
  { path: 'viewHoldSample/:qrCodeId', component: RegisterSampleListComponent },
  { path: 'pocgrid', component: GridComponent },
  { path: 'pocform', component: FormComponent },
  { path: 'accessDenied', component: AccessDeniedComponent },
  { path: 'sampleDashboardHome', component: SampleDashboardHomeComponent },
  { path: 'lineGraph', component: LineGraphComponent },
  { path: 'lineGraphHome', component: LineGraphHomeComponent },
  { path: 'scatterPlot', component: ScatterPlotComponent },
  { path: 'scatterPlotGrid', component: ScatterPlotGridComponent },
  { path: 'reportHome', component: ReportHomeComponent },
  { path: 'financeReport', component: FinanceReportComponent },
  { path: 'tsmAeReport', component: TsmAeReportComponent },
  { path: 'opsTotReport', component: OpsTotReportComponent },
  { path: 'opsKeyIssueReport', component: OpsKeyissueReportComponent },
  { path: 'opsCountReport', component: OpsCountReportComponent },
  { path: 'opsReportHome', component: OpsReportHomeComponent },  
  { path: 'fleetReportHome', component: FleetReportHomeComponent }, 
  { path: 'fleetUnitsOdiReport', component: FleetUnitsOdiReportComponent },   
  { path: 'fleetSampleTotReport', component: FleetSampleTotReportComponent }, 
  { path: 'fleetUnitIdCountReport', component: FleetUnitIdCountReportComponent },
  { path: 'accountGroupManagement', component: AccountGroupManagementComponent }, 
  { path: 'addEditAccountGroup', component:AddGroupComponent},   
  { path: 'logout', component: LogoutComponent },
  { path: 'uploadUnit',component:UploadUnitComponent},
  { path: 'masterDataManagement', component: MasterDataManagementComponent },
  { path: 'adminPortal', component: AdminPortalComponent }, 
  { path: 'viewReportHome', component: ViewReportHomeComponent },
  { path: 'calendar', component: CalendarComponent},
  { path: 'customerAccount', component: CustomerAccountComponent },
  { path: 'customerEmailNotification', component: CustomerEmailNotificationComponent},
  { path: 'addSubscription', component: AddSubscriptionComponent },
  { path: 'dbuTool', component: DbuToolComponent, loadChildren: () => import('./View/core/dbu-tools/dbu-tool.module').then(m => m.DbuToolModule)  }, 
  { path: 'accountAccess', component: CustomerAacountAccessComponent},
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class ApproutingModule { }

