import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { FileValidator, FileExtensionValidator } from './file-input.validator';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { AddPopulationService } from './add-population.service';
import { fluidType } from 'src/app/View/common/constants/constant';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-add-population',
  templateUrl: './add-population.component.html',
  styleUrls: ['./add-population.component.css']
})
export class AddPopulationComponent implements OnInit {

  populationType: string = '';
  currentItem: any;
  arrayBuffer: any;
  file: File;
  populationName: string = '';
  fluidType: string = '';
  fluidTypeList: any[] = [];
  parametersList: any[] = [];
  arrData = new Array();
  headerData = new Array();
  successMessage: string = '';
  successModal: boolean = false;
  confirmModal: boolean = false;
  replaceSuccessModal: boolean = false;
  replaceConfirmModal: boolean = false;
  cloneSuccessModal: boolean = false;
  updateSuccessModal: boolean = false;
  updateConfirmModal: boolean = false;

  distanceUOMList = [];
  sumpSizeList = [];
  cruiseControlList = [];
  yearsList = [];
  platformList = [];
  applicationList = [];
  dutyCycleList = [];
  oilCesStandardList = [];
  ecmModelList = [];
  fluidGradeList = [];

  scrollToTop = false;

  frm = new UntypedFormGroup({});
  replaceForm = new UntypedFormGroup({});
  cloneForm = new UntypedFormGroup({});
  updateForm = new UntypedFormGroup({});

  platformData = [];
  dutyCycleData = [];
  yearDropdown={
    buildYearStart:[],
    buildYearEnd:[],
    modelYearStart:[],
    modelYearEnd:[],
    chassisModelYearStart:[],
    chassisModelYearEnd:[],
    transmissionYearStart:[],
    transmissionYearEnd:[]
  };
 

  constructor(private router: Router,
    private alertService: AlertService,
    private addPopulationService: AddPopulationService,
    private sharedService: SharedService, private cdr:ChangeDetectorRef) {
    this.buildForm();
    
  }

  private buildForm() {
    this.frm = new UntypedFormGroup({
      populationName: new UntypedFormControl('', [Validators.required]),
      fluidType: new UntypedFormControl('', [Validators.required]),
      file: new UntypedFormControl("", [FileValidator.validate, FileExtensionValidator.validate]),
      platform: new UntypedFormControl(''),
      buildYearStart: new UntypedFormControl(''),
      buildYearEnd: new UntypedFormControl(''),
      modelYearStart: new UntypedFormControl(''),
      modelYearEnd: new UntypedFormControl(''),
      ecmModel: new UntypedFormControl([]),
      chassisModelYearStart: new UntypedFormControl(''),
      chassisModelYearEnd: new UntypedFormControl(''),
      application: new UntypedFormControl([]),
      transmissionYearStart: new UntypedFormControl(''),
      transmissionYearEnd: new UntypedFormControl(''),
      chassisManufacturer: new UntypedFormControl('',[Validators.maxLength(500)]),
      chassisModel: new UntypedFormControl('',[Validators.maxLength(500)]),
      transmissionMake: new UntypedFormControl('',[Validators.maxLength(500)]),
      transmissionModel: new UntypedFormControl('',[Validators.maxLength(500)]),
      dutyCycle: new UntypedFormControl(''),
      sumpSize: new UntypedFormControl('',[Validators.max(999.99)]),
      sumpUOM: new UntypedFormControl(''),
      frOption: new UntypedFormControl('',[Validators.maxLength(9)]),
      horsePowerRating: new UntypedFormControl('',[Validators.maxLength(9)]),      
      peakTorque: new UntypedFormControl('',[Validators.maxLength(9)]),
      rpm: new UntypedFormControl('',[Validators.maxLength(9)]),
      idleBottomValue: new UntypedFormControl('',[Validators.max(99.99)]),
      idleTopValue: new UntypedFormControl('',[Validators.max(99.99)]),
      fuelEconomyBottomValue: new UntypedFormControl('',[Validators.max(99.99)]),
      fuelEconomyTopValue: new UntypedFormControl('',[Validators.max(99.99)]),
      cruiseControl: new UntypedFormControl(''),
      rearAxleRatioStart: new UntypedFormControl('',[Validators.max(99.99)]),
      rearAxleRatioEnd: new UntypedFormControl('',[Validators.max(99.99)]),
      oilCesStandard:new UntypedFormControl([]),
      fluidGradeRange: new UntypedFormControl(''),
      engineDistanceUOM: new UntypedFormControl(''),
      engineDistanceBottom: new UntypedFormControl('',[Validators.maxLength(9)]),
      engineDistanceTop: new UntypedFormControl('',[Validators.maxLength(9)]),
      additionalNotes: new UntypedFormControl('',[Validators.maxLength(500)]),
      userId: new UntypedFormControl(this.sharedService.getUserId()),
      headersList: new UntypedFormControl([]),
      populationDataList: new UntypedFormControl([])
    });

    this.replaceForm = new UntypedFormGroup({
      file: new UntypedFormControl("", [FileValidator.validate, FileExtensionValidator.validate])
    });
    this.cloneForm = new UntypedFormGroup({
      populationName: new UntypedFormControl("", [Validators.required])
    });
    this.updateForm = new UntypedFormGroup({
      file: new UntypedFormControl("", [FileValidator.validate, FileExtensionValidator.validate])
     });

  }

  ngOnInit() {
    this.populationType = JSON.parse(localStorage.getItem('populationType'));
    this.currentItem = JSON.parse(localStorage.getItem('populationData'));
    this.getPageLoadData();   
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getMetaDataDropdownData(){
    if(this.frm.controls.fluidType.value == 4){
      this.frm.controls.file.disable();
    }else{
      this.frm.controls.file.enable();
    }
    let inputData = {
      'fluidType': this.frm.controls.fluidType.value
    };
    this.addPopulationService.getMetaDataDropdownData(inputData).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.distanceUOMList = response.distanceUOMList;
          this.sumpSizeList = response.sumpSizeList;
          this.cruiseControlList = response.cruiseControlList;
          this.yearsList = response.yearsList;
          this.platformList = response.platformList;
          this.applicationList = response.applicationList;
          this.dutyCycleList = response.dutyCycleList;
          this.oilCesStandardList = response.oilCesStandardList;
          this.ecmModelList = response.ecmModelList;
          this.fluidGradeList = response.fluidGradeList;

          Object.keys(this.yearDropdown).forEach(field => {
            this.yearSearchData('',field);
          });
          this.platformSearchData('');
          this.dutycycleSearchData(''); 

        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  getPageLoadData() {
    this.addPopulationService.getPageLoadData().subscribe(
      response => {
        if (response.statusCode == "1") {
          this.fluidTypeList = response.fluidTypeList;
          this.fluidTypeList = this.fluidTypeList.filter(row=>{
            return row.fluidType == 1 || row.fluidType == 2 || row.fluidType == 3 || row.fluidType == 4 || row.fluidType == 5 || row.fluidType == 6
          });
          this.parametersList = response.parametersList;
          //console.log("fluid type List ----->>" + this.fluidTypeList);
          //console.log("parametre List ----->>" + this.parametersList);
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  openConfirmModal() {
    this.confirmModal = true;
  }

  closeConfirmModal() {
    this.confirmModal = false;
  }

  submitData() {
    this.confirmModal = false;
    
    if(this.frm.controls.fluidType.value != 4){
      this.frm.patchValue({
        headersList: this.headerData,
        populationDataList: this.arrData      
      });
    }

    let inputData = this.frm.getRawValue(); 
    inputData['application'] = inputData['application'].toString();
    inputData['ecmModel'] = inputData['ecmModel'].toString();
    inputData['oilCesStandard'] = inputData['oilCesStandard'].toString();
    inputData['fluidTypeId'] = this.frm.controls.fluidType.value;
    this.fluidTypeList.forEach((row) => {
      if(row.fluidType == this.frm.controls.fluidType.value){
        inputData['fluidType'] = row.fluidTypeName;
      }
    });    
    delete inputData['file'];
    
    //console.log("reqData --------->>" + reqData);
    this.addPopulationService.submitData(inputData).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.successModal = true;
          // this.successMessage = response.statusMessage;
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }


  /* ---------------------------File Upload Code start------------------------ */

  incomingfile(event) {
    this.file = event.target.files[0];

    this.frm.controls['file'].setErrors(null);

    this.replaceForm.controls['file'].setErrors(null);

    this.updateForm.controls['file'].setErrors(null);
  }

  upload() {
    if(this.frm.controls.fluidType.value != 4){
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        this.arrData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval:"" });
        this.arrData = this.arrData.map(this.deleteEmptyField);
        this.arrData = this.removeDuplicate();
        this.headerData = this.get_header_row(worksheet);
        this.headerData = this.headerData.filter(this.removeEmptyHeader);
        //console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
        this.getMethodName();
      }
      fileReader.readAsArrayBuffer(this.file);
    }else{
      this.getMethodName();
    }
  }

  removeEmptyHeader(value){
    if(!value.includes('UNKNOWN')){
      return value;
    }
  }

  deleteEmptyField(value, index, array){
    var modValue = value;
    for(var key in value){
      if(key.includes('__EMPTY')){
        delete modValue[key];
      }
    }
    return modValue;
  }

  removeDuplicate(){
    var result=[];
    this.arrData.forEach((item) => {
      if (result.findIndex(i => i.SampleNo == item.SampleNo) === -1) {
          result.push(item)
      }  
    });
    return result;  
  }

  getMethodName() {    
    if (this.populationType === 'add') {
      this.submitData();
    } else if (this.populationType === 'replace') {
      this.replacePopulation();
    }
    else if (this.populationType === 'update') {
      this.updatePopulation();
    }
  }

  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */

      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }
  /* --------------------File Upload Code end----------------------- */

  /* --------------------Replace Population Code starts Here------------------ */
  openReplaceConfirmModal() {
    this.replaceConfirmModal = true;
  }

  closeReplaceConfirmModal() {
    this.replaceConfirmModal = false;
  }
  replacePopulation() {
    this.replaceConfirmModal = false;
    let req = {
      userId: this.sharedService.getUserId(),
      populationName: this.currentItem.populationName,
      populationId: this.currentItem.populationId,
      fluidType: this.currentItem.fluidType,
      fluidTypeId: this.currentItem.fluidTypeId,
      headersList: this.headerData,
      populationDataList: this.arrData
    };
    this.addPopulationService.replacePopulation(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.replaceSuccessModal = true;
          // this.successMessage = response.statusMessage;
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }
/* ---------------------------Update Population-------------------------- */

openUpdateConfirmModal() {
  this.updateConfirmModal = true;
}

closeUpdateConfirmModal() {
  this.updateConfirmModal = false;
}

  updatePopulation() {
    this.updateConfirmModal = false;
    let req = {
      userId: this.sharedService.getUserId(),
      populationName: this.currentItem.populationName,
      populationId: this.currentItem.populationId,
      fluidType: this.currentItem.fluidType,
      fluidTypeId: this.currentItem.fluidTypeId,
      headersList: this.headerData,
      populationDataList: this.arrData
    };
    this.addPopulationService.updatePopulation(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.updateSuccessModal = true;
          // this.successMessage = response.statusMessage;
          this.alertService.success(response.statusMessage);
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  /* ---------------------------Clone Population-------------------------- */

  clonePopulation() {
    let req = {
      userId: this.sharedService.getUserId(),
      populationName: this.cloneForm.value.populationName,
      fluidType: this.currentItem.fluidType,
      populationIdToClone: this.currentItem.populationId
    }
    this.addPopulationService.clonePopulation(req).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.cloneSuccessModal = true;
          // this.alertService.success(response.statusMessage);
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  sumpSizeChange(){
    let sumpSize = this.frm.controls.sumpSize.value;
    let sumpUOM = this.frm.controls.sumpUOM.value;
    if(sumpSize.length > 0 || sumpUOM.length > 0){
      this.frm.controls['sumpSize'].setValidators([Validators.required, Validators.max(999.99)]);
      this.frm.controls['sumpUOM'].setValidators([Validators.required]);
    }else{
      this.frm.controls['sumpSize'].clearValidators();
      this.frm.controls['sumpUOM'].clearValidators();
    }
    this.frm.controls['sumpSize'].updateValueAndValidity();
    this.frm.controls['sumpUOM'].updateValueAndValidity();
  }

  peakTorqueChange(){    
    let peakTorque = this.frm.controls.peakTorque.value;
    let rpm = this.frm.controls.rpm.value;
    if(peakTorque.length > 0 || rpm.length > 0){
      this.frm.controls['peakTorque'].setValidators([Validators.required, Validators.maxLength(9)]);
      this.frm.controls['rpm'].setValidators([Validators.required, Validators.maxLength(9)]);
    }else{
      this.frm.controls['peakTorque'].clearValidators();
      this.frm.controls['rpm'].clearValidators();
    }
    this.frm.controls['peakTorque'].updateValueAndValidity();
    this.frm.controls['rpm'].updateValueAndValidity();
  }

  yearSearchData(value, field){
    let filter = value.toLowerCase();
    this.yearDropdown[field] = [];
    this.yearsList.forEach(row => {
      if (row.toString().indexOf(filter) >= 0) {
        this.yearDropdown[field].push(row);
      }
    });
    if(this.yearDropdown[field].length == 0){
      this.yearDropdown[field].push(0);
    }
  }

  clearYearData(field){
    this.yearDropdown[field] = [];
    this.yearsList.forEach(row => {
      this.yearDropdown[field].push(row);
    });
  }

  platformSearchData(value){
    let filter = value.toLowerCase();
    this.platformData = [];
    this.platformList.forEach(row => {
      if (row.platformName.toLowerCase().indexOf(filter) >= 0) {
        this.platformData.push(row);
      }
    });
    if(this.platformData.length == 0){
      this.platformData.push({"platform":"","platformName":"Select"});
    }
  }

  clearPlatformData(){
    this.platformData = [];
    this.platformList.forEach(row => {
      this.platformData.push(row);
    });
  }

  dutycycleSearchData(value){
    let filter = value.toLowerCase();
    this.dutyCycleData = [];
    this.dutyCycleList.forEach(row => {
      if (row.dutyCycleName.toLowerCase().indexOf(filter) >= 0) {
        this.dutyCycleData.push(row);
      }
    });
    if(this.dutyCycleData.length == 0){
      this.dutyCycleData.push({"dutyCycle":"","dutyCycleName":"Select"});
    }
  }

  clearDutycycleData(){
    this.dutyCycleData = [];
    this.dutyCycleList.forEach(row => {
      this.dutyCycleData.push(row);
    });
  }

}

