import { AfterViewChecked, Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { FileValidator, FileExtensionValidator } from '../../population-management/add-population/file-input.validator';
import { SharedService } from 'src/app/View/service/shared.service';
import { UploadUnitService } from './upload-unit.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Component({
  selector: 'app-upload-unit',
  templateUrl: './upload-unit.component.html',
  styleUrls: ['./upload-unit.component.css']
})
export class UploadUnitComponent implements OnInit,AfterViewChecked {
  fileName: string = 'unitsTemplate';
  readonly USERTYPE = USERTYPE;
  currentItem: any;
  arrayBuffer: any;
  file: File;
  unitName: string = '';
  arrData : any[]
  headerData = new Array();
  successMessage: string = '';
  successModal: boolean = false;
  confirmModal: boolean = false;
  templateHeaders: string[];
  distanceUOM =["MILES","KMs","HOURS"];
  validExcel: boolean = false;
  errorString: string="";
  userRole: string = '';
  uploadForm = new UntypedFormGroup({});
  scrollToTop =  false;
  b64encodedString: string;
  companyIdNameList: any;
  companyId: any;
  companyName: any;
  companyNameIAM:any;

  constructor(private uploadUnit :UploadUnitService,
    private alertService: AlertService, private sharedService: SharedService) {
    this.buildForm();
  }

  private buildForm() {
    this.uploadForm = new UntypedFormGroup({
      // unitName: new FormControl('', [Validators.required]),
      file: new UntypedFormControl("", [FileValidator.validate, FileExtensionValidator.validate])
    });

  }

  ngOnInit() {   
    this.userRole = this.sharedService.getUserRole();
    this.getPageLoadData();
    this.companyNameIAM = JSON.parse(localStorage.getItem('externalUserInformation')).IAM_Account_Application__r.IAM_Account_Name__r.Name;
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getPageLoadData() {
    if (this.userRole == USERTYPE.EXTERNAL) {
      let dropDownData = {
        "userRole": this.userRole
      }
      dropDownData.userRole = this.sharedService.getIntOrExtUser();
      this.uploadUnit.getEquipmentDropdownData(dropDownData).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.companyId = this.sharedService.getCompanyId();
            if(this.userRole == USERTYPE.EXTERNAL){
              this.companyName = this.companyNameIAM;
            }else{
            this.companyIdNameList = response.companyIdList;
            }
            this.companyIdNameList.forEach(element => {
              if (element.companyId == this.companyId) {
                this.companyName = element.companyName;
              }
            });
          } else {
            this.alertService.error(response.statusMessage);
            this.scrollToTop = true;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      )
    }
  }

  openConfirmModal() {
    this.confirmModal = true;
  }

  closeConfirmModal() {
    this.confirmModal = false;
  }

  submitData() {
      this.confirmModal = false;
      let reqData = {
        functionName : 'EXCEL',
        userId: this.sharedService.getUserId(),
        headersList: this.headerData,
        unitList: this.arrData,
        userRole: this.sharedService.getIntOrExtUser()

      }
      if(this.userRole === USERTYPE.EXTERNAL){
        reqData['companyId'] = this.sharedService.getCompanyId();
        // reqData['userRole'] = this.sharedService.getIntOrExtUser();
      }
      //console.log("reqData --------->>" + reqData);
      this.uploadUnit.bulkUpload(reqData).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.successModal = true;
            this.successMessage = response.statusMessage;
          } else {
            this.alertService.error(response.statusMessage);
            this.scrollToTop = true;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }


  unitIdTemplateDownload()
  {
    let reqData = {
       'functionName' : '',
       'unitId' : "RV870",
       'userRole' : this.sharedService.getIntOrExtUser()
    }
    //console.log("reqData --------->>" + reqData);
    this.uploadUnit.bulkUpload(reqData).subscribe(
      response => {
        if (response.statusCode == "1") {
        //  for(let i=0;i<response.length;i++){
        //   this.templateHeaders=response.headerList[i];
        //  }
        //    this.templateHeaders=response.headerList;
        // console.log(" this.templateHeaders", this.templateHeaders)
        // this.exportAsExcelFile(this.templateHeaders,'unitsTemplate');
          this.b64encodedString = response.statusMessage;
          this.downloadFile(this.b64encodedString)
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }
  downloadFile(b64encodedString: string) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString);
      FileSaver.saveAs(blob, this.fileName + EXCEL_EXTENSION);
    }
  }

  public base64ToBlob(b64Data, sliceSize=512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: EXCEL_TYPE});
  }
  // exportAsExcelFile(json: any[], excelFileName: string): void {
    // let  templateToExcel:string[][] = [this.templateHeaders,[]];
    // const worksheet: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet(templateToExcel);
    // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // XLSX.writeFile(workbook,excelFileName+".xlsx");
  //   }
    

  /* ---------------------------File Upload Code start------------------------ */

  incomingfile(event) {
    this.file = event.target.files[0];
    console.log(event);
    this.uploadForm.controls['file'].setErrors(null);

    // event.srcElement.value = null
  }

  clearValues(event)
  {
    event.srcElement.value = null
  }

  upload() {
    
    this.validExcel =false;
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
       console.log(this.arrayBuffer,"this.arrayBuffer")
      var data = new Uint8Array(this.arrayBuffer);
       console.log(data,"data")
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.arrData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval:"" });
      this.arrData = this.arrData.map(this.deleteEmptyField);
      var currentYear=new Date().getFullYear();
      this.userRole = this.sharedService.getUserRole();
      if(this.userRole === USERTYPE.EXTERNAL){
        for(let item of this.arrData){
          item['Company ID'] = this.sharedService.getCompanyId();
          item['Company Name'] = this.companyNameIAM;
        }
      }

     for(let item of this.arrData){        
        // item['Distance UOM'] = item['Distance UOM'].toUpperCase();
        item['Engine Type'] = item['Engine Type'].toUpperCase();
      }

       /*for(let item of this.arrData)
      {
        
        if((item['Company ID'] !="" && item['Unit ID'] && item['Distance UOM'] != "" &&
        item['ESN'] && item['Unit Make'] && item['Unit Model'] != "" && item['Engine Type'] != "" )) 
        {
         
          if(item['Distance UOM'] === "MILES" || item['Distance UOM'] === "HOURS" || item['Distance UOM'] === "KILOMETERS")
          {
          
           
            if(((item['Unit Year'] >= (currentYear-100) && item['Unit Year'] <= (currentYear+1) )) || (item['Unit Year'] === "" ))
            { 
            
 
             if(((item['Engine Year'] >= (currentYear-100) && item['Engine Year'] <= (currentYear+1))) ||  item['Engine Year'] ==="")
             {
               this.validExcel=true;   

             }
             else{
              this.validExcel=false; 
              let val=currentYear+1
              this.errorString="Kindly provide valid data for ENGINE YEAR field (Enter value between the year "+val+" and last 100 years)" 
              break;
              
            }
            }  
            else{
              this.validExcel=false; 
              let val=currentYear+1 
              this.errorString="Kindly provide valid data for UNIT YEAR field (Enter value between the year "+val+" and last 100 years)"
              break;
              
            }           
          }
          else{
             this.validExcel=false; 
             this.errorString="Kindly provide valid data for DISTANCE UOM field (MILES, KILOMETERS, HOURS)";
            break;
            
          }
       }
       else{
        this.validExcel=false;  
         this.errorString="Kindly enter all mandatory field";
        break;
       }

       if(item['Engine Type'] != 'DIESEL ENGINE' && item['Engine Type'] != 'NATURAL GAS ENGINE'){
        this.validExcel=false;  
        this.errorString="Kindly provide valid data for Engine Type field (Diesel Engine, Natural Gas Engine)";
        break;
       }else{
         if(item['Engine Type'] == 'DIESEL ENGINE'){
          item['Engine Type'] = 'Diesel Engine';
         }else if(item['Engine Type'] == 'NATURAL GAS ENGINE'){
          item['Engine Type'] = 'Natural Gas Engine';
         }
       }
    }
  

      if(this.validExcel === true)
      {
        this.headerData = this.get_header_row(worksheet);
        this.headerData = this.headerData.filter(this.removeEmptyHeader);
        if(this.userRole === USERTYPE.EXTERNAL){
          this.headerData.push('Company ID');
        }
        console.log(" this.arrData", this.arrData)
        this.submitData();  
      }
      else
      
      {
        this.confirmModal = false;
        this.alertService.error(this.errorString);
        this.scrollToTop = true;
      }*/
      this.headerData = this.get_header_row(worksheet);
      this.headerData = this.headerData.filter(this.removeEmptyHeader);
      if(this.userRole === USERTYPE.EXTERNAL){
        this.headerData.push('Company ID');
        this.headerData.push('Company Name');
      }
      console.log(" this.arrData", this.arrData);
      let upperCaseArrayData = this.arrData.map(obj => {
        let newObj = {};
        for(let key in obj) {
          newObj[key] = typeof obj[key] === 'string' ? obj[key].toUpperCase() : obj[key];
        }
        return newObj;
      });
      console.log(upperCaseArrayData);
      this.arrData = upperCaseArrayData;
      
      this.submitData();  
     
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  removeEmptyHeader(value){
    if(!value.includes('UNKNOWN')){
      return value;
    }
  }

  deleteEmptyField(value, index, array){
    var modValue = value;
    for(var key in value){
      if(key.includes('__EMPTY')){
        delete modValue[key];
      }
    }
    return modValue;
  }

  // removeDuplicate(){
  //   var result=[];
  //   this.arrData.forEach((item) => {
  //     if (result.findIndex(i => i.unit_ID == item.unit_ID) === -1) {
  //         result.push(item)
  //     }  
  //   });
  //   return result;  
  // }



  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */

      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }
  /* --------------------File Upload Code end----------------------- */

 

}
