export class GraphData {
    // divId: string = "";
    // inputId: string = "";
    // inputName: string = "";
    // delImgId: string = "";
    // inputText: string = "";
    graphName: String = '';
    inputValue: String = '';
    imageData: String = '';


}
