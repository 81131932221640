import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../../handlers/api-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveUpdateService {

  constructor(private api: ApiHandlerService) { }

  getWebsiteLiveDataUpdates() {
    return this.api.post(environment.apiUrl + '/getWebsiteLiveDataUpdates', {})
  }
}
