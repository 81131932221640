import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/filter';
import { Pop, PopType } from './pop';



@Injectable({
  providedIn: 'root'
})
export class PopService {
  private subject = new Subject<Pop>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  // subscribe to alerts
  getAlert(popId?: string): Observable<any> {
    return this.subject.asObservable().filter((x: Pop) => x && x.popId === popId);
  }
  // convenience methods
  browserInfo(message: string) {
    var title="Browser Information"
    this.alert(new Pop({ message, type: PopType.Browser, title :title}));
  }
  batchInfo(message: string) {
    var title="Batch Information";
    this.alert(new Pop({ message, type: PopType.Batch,title :title }));
  }


  // main alert method
  alert(alert: Pop) {
    this.keepAfterRouteChange = alert.keepAfterRouteChange;
    this.subject.next(alert);
  }
  // clear alerts
  clear(popId?: string) {
    this.subject.next(new Pop({ popId }));
  }
}
