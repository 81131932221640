import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerNameDropdownComponent } from './customer-name-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModuleModule } from '../CommonModule.module';
import { RegisterSampleRoutingModule } from '../../core/register-sample/register-sample.routing';
import { MatAutocompleteModule, MatDatepickerModule, MatExpansionModule, MatNativeDateModule, MatRadioModule, MatSelectModule } from '@angular/material';
import { SharedModule } from '../../core/shared/shared-module';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      CommonModuleModule,
      ReactiveFormsModule,
      RegisterSampleRoutingModule,
      MatExpansionModule,
      MatSelectModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule,
      SharedModule,
      MatRadioModule,
    ],
    declarations: [CustomerNameDropdownComponent],
    exports: [CustomerNameDropdownComponent]
  })
  export class CustomerNameDropdownModule { }