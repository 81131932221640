import { Component, OnInit, ViewChildren } from '@angular/core';
import { common, warnMessage } from '../../../common/constants/constant';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SortComponent } from '../../../common/sort/sort.component';
import { MasterDataManagementService } from '../master-data-management.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';

@Component({
  selector: 'app-admin-unit-make',
  templateUrl: './admin-unit-make.component.html',
  styleUrls: ['./admin-unit-make.component.css']
})
export class AdminUnitMakeComponent implements OnInit {
  showRecordsMetadata = 0;
  pageSizeMetadata = '10';  
  currentPageMetadata = 1; 
  changePageMetadata: any = 1;
  pageSizeOptions: any[] = common.pageSizeOptions;
  fleetVal: any = '';
  addUnitMakeInput: boolean = false;
  addMetadataModal = false;
  searchObjMetadata: any;
  columnName: string;
  columnNameMetadata: string;
  unitDisplayColumns: any;
  sortingOrder: string = 'asc';
  successMessage: string = '';
  successModal = false;
  scrollToTop =  false;
  dropdownName: string;
  metadataSearchData = [];
  totalItemsMetadata: any;
  maxPageMetadata = 0;
  selectedMetadataGroup:UntypedFormGroup;
  saveMetadataModal = false;
  deleteMetadataModal = false;

  unitFilterObject = {
    unitMake: []
  }

  addUnitMakeForm = new UntypedFormGroup({
    unitMakeName: new UntypedFormControl('',[Validators.required])
  });

  unitSearchForm = new UntypedFormGroup({
    unitMake: new UntypedFormControl('')
  });

  metadataFormArray = new UntypedFormArray([]);

  constructor(private masterDataManagementService:MasterDataManagementService, private alertService: AlertService, private commonHandler: CommonHandlerService) { }

  
  @ViewChildren(SortComponent) sortComponents;
  
  ngOnInit(): void {
    this.columnName = 'unit_make';
    this.dropdownName = 'UNITMAKE';
    this.searchMetadata();
    this.fetchMetadata();
    this.unitDisplayColumns = [
      { code: "unitMake", name: "Unit Make" }
    ];
  }
    
  sortDataMetadata(columnName) {
    if (this.columnNameMetadata != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnNameMetadata = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.fetchMetadata();
  }
  
  intUserclearSearch(str) {
    if( this.unitSearchForm.controls[str].value != ''){
      this.unitSearchForm.patchValue({ [str]: '' });
      this.unitFilterObject[str] = [];
      this.fetchMetadata();
    } else {
      this.unitFilterObject[str] = [];
    }
  }

  addNewUnitMake() {
    this.addUnitMakeInput = true;
  }

  hideUnitMakeInput() {
    this.addUnitMakeInput = false;
  }

  openAddMetadataModal1() {
    if(!this.addUnitMakeForm.invalid){
      this.addMetadataModal = true;
    }else{
      this.addMetadataModal = false;      
      Object.keys(this.addUnitMakeForm.controls).forEach(field => { 
        const control = this.addUnitMakeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAddMetadataModal() {
    this.addMetadataModal = false;
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.metadataFormArray.controls = [];
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;
    this.fetchMetadata();
  }

  searchMetadata() {
    this.currentPageMetadata = 1;
    this.fetchMetadata();
  }

  fetchMetadata() {
    let data = {};
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['columnName'] = this.columnName;
    data['dropdownName'] = this.dropdownName;
    data['sortingOrder'] = this.sortingOrder;
    data['unitMake'] = this.unitSearchForm.value['unitMake'];

    let _data = {};
    _data['pageSize'] = this.pageSizeMetadata.toString();
    _data['currentPage'] = this.currentPageMetadata;
    _data['columnName'] = 'unitMake';
    _data['dropdownName'] = this.dropdownName;
    _data['sortingOrder'] = this.sortingOrder;
    _data['unitMake'] = this.unitSearchForm.value['unitMake'];

    this.searchObjMetadata = _data;
    this.masterDataManagementService.searchMetadata(data).subscribe(response =>{
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;   
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();
        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {         
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            isEdit: new UntypedFormControl(false),
            unitMake: new UntypedFormControl(row.unitMake, [Validators.required]),
          });
          this.metadataFormArray.push(formGroup);
        });
        
      } else {
        this.alertService.error(response.statusMessage);
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  editMetadata(item) {
    item.patchValue({
      isEdit:true
    });
  }

  openSaveMetadataModal(item) {
    if(!item.invalid) {
      this.selectedMetadataGroup = item;
      this.saveMetadataModal = true;
    } else {
      this.saveMetadataModal = false;   
      Object.keys(item.controls).forEach(field => { 
        const control = item.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeSaveMetadataModal(){
    this.saveMetadataModal = false;
  }

  saveMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    data['dropdownName'] = 'UNITMAKE';
    data['optionName'] = data.unitMake.toUpperCase();

    this.masterDataManagementService.saveMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {  
        this.searchMetadata();        
        this.successMessage = response.statusMessage;
        this.successModal = true; 
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.saveMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.saveMetadataModal = false;
    });
  }

  cancelMetadataEdit(item, i) {
    item.patchValue({
      isEdit:false,
      optionName: this.metadataSearchData[i].optionName
    });
    this.fetchMetadata();
  }

  openMetadataDeleteModal(item) {    
    this.selectedMetadataGroup = item;
    this.deleteMetadataModal = true;
  }

  closeDeleteMetadataModal() {
    this.deleteMetadataModal = false;
  }

  deleteMetadata() {
    let data = this.selectedMetadataGroup.getRawValue();
    data['dropdownName'] = 'UNITMAKE';
    this.masterDataManagementService.deleteMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {         
        this.successMessage = response.statusMessage;
        this.successModal = true; 
        this.searchMetadata();     
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.deleteMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.deleteMetadataModal = false;
    });
  }  

  addMetadata() {
    let data;
    let data1=this.addUnitMakeForm.getRawValue();
    data = {
      dropdownName: 'UNITMAKE',
      optionName: data1.unitMakeName.toUpperCase(),
      activeStatus: 'Y'
    }

    this.masterDataManagementService.addMetadata(data).subscribe(response =>{
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;
        this.addUnitMakeInput = false;
        this.addUnitMakeForm.reset();
        this.fetchMetadata();
      } else {
        this.scrollToTop = true;
        this.alertService.error(response.statusMessage);
      }
      this.addMetadataModal = false;
    },err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addMetadataModal = false;
    });
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {        
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  // ======================== Code for Search Unit Make ==================================---- */
  intUsersearchFilter(str) {
    let search = (this.unitSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.unitFilterObject[str] = [];
      this.fetchMetadata();
    }
    let _data = {
      unitMake: search,
      columnName: "unit_make",
      dropdownName: "UNITMAKE",
      sortingOrder: "asc",
      pageSize: this.pageSizeMetadata.toString(),
      currentPage: 1,
    }
    
    if (this.unitSearchForm.value[str].length >= 2) {
      this.masterDataManagementService.searchMetadata(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.unitFilterObject[str] = response.dropdownOptionList;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }
  }

  searchDashboardDropDownSelect(str) {
    let data = {};
    if (str == 'unitMake') {
      data = {
        unitMake: this.unitSearchForm.value['unitMake'],
        columnName: "unit_make",
        dropdownName: "UNITMAKE",
        sortingOrder: "asc",
        pageSize: this.pageSizeMetadata.toString(),
        currentPage: 1,
      }
    }
    this.masterDataManagementService.searchMetadata(data).subscribe(response => {
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();

        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {
          const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(row.id, [Validators.required]),
            isEdit: new UntypedFormControl(false),
            unitMake: new UntypedFormControl(row.unitMake, [Validators.required]),
          });
          this.metadataFormArray.push(formGroup);
        });
      }
      else if (response.statusCode == "-1") {
        this.metadataFormArray.controls = [];
        this.currentPageMetadata = 0;
        this.changePageMetadata = 0;
        this.showRecordsMetadata = 0;
        this.totalItemsMetadata = 0;
        this.loadPageMetadata(1);
      } else {
        this.alertService.error(response.statusMessage);
        this.metadataSearchData = [];
        this.metadataFormArray.controls = [];
        this.showRecordsMetadata = 0;
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }
}
