import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as d3 from "d3";
import { ScatterPlotService } from '../scatter-plot/scatter-plot.service';
import { DISTANCE_TYPE } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { AnalyzeSampleGridService } from '../../../analyze-sample/analyze-sample-grid/analyze-sample-grid.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE, USERS } from 'src/app/View/common/constants/constant';


@Component({
  selector: 'app-scatter-plot-graph',
  templateUrl: './scatter-plot-graph.component.html',
  styleUrls: ['./scatter-plot-graph.component.css']
})
export class ScatterPlotGraphComponent implements OnInit {
  scatterPlotData: any;
  parameterDetails: any;
  selectedUnits = [];
  legendSelectedUnits = [];
  allUnits = [];
  distanceType: any;
  uom: any;
  userRole: string = '';
  inputDataTemp:any;
  constructor(private scatterService: ScatterPlotService,
    private router: Router,
    private analyzeSampleService: AnalyzeSampleGridService, private sharedService: SharedService) { }

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();

  }

  @Output("noRecordAlert") noRecordAlert: EventEmitter<any> = new EventEmitter();
  @Output("viewReport") viewReport: EventEmitter<any> = new EventEmitter();

  unitColor = ['blue', 'red', 'green', 'orange', 'purple', 'lime', 'olive', 'fuchsia', 'aqua', 'maroon'];
  legendClass = {};
  chartTitle = '';
  yAxisLabel = '';
  xAxisLabel = '';

  showGraph(inputData) {
    this.inputDataTemp = inputData;
    this.resetGraph();
    this.uom = inputData.uom;
    this.parameterDetails = inputData.selectedParameter;
    this.allUnits = inputData.allUnits;
    inputData.unitIds.forEach(e => {
      this.selectedUnits.push({ 'unitId': e.unitId, 'equipId': e.equipId, 'uom': e.distanceUOM });
    });
    if (this.uom == 'HOURS') {
      if (inputData.distanceType == DISTANCE_TYPE.FLUID_DISTANCE) {
        this.distanceType = 'Fluid Hours';
      } else if (inputData.distanceType == DISTANCE_TYPE.UNIT_DISTANCE) {
        this.distanceType = 'Unit Hours';
      }
    } else {
      if (inputData.distanceType == DISTANCE_TYPE.FLUID_DISTANCE) {
        this.distanceType = 'Fluid Distance';
      } else if (inputData.distanceType == DISTANCE_TYPE.UNIT_DISTANCE) {
        this.distanceType = 'Unit Distance';
      }
    }

    var data = {
      "xAxis": inputData.distanceType,
      "yAxis": inputData.selectedParameter.parameterName,
      "paramId": inputData.selectedParameter.parameter,
      "unitsList": this.selectedUnits,
      "fluidType": inputData.fluidType,
      "fluidTypeName": inputData.fluidTypeName
    };
    this.scatterService.getBYOScatterPlotData(data).subscribe((resp) => {
      if (resp.statusCode == '1') {
        this.scatterPlotData = resp.scatterPlotDataMap;
        this.generateScatterPlot();
      }
    }, (error) => {

    });
  }

  resetGraph() {
    d3.select('#scatterPlot').selectAll('svg').remove();
    this.legendClass = {};
    this.chartTitle = '';
    this.selectedUnits = [];
    this.legendSelectedUnits = [];
  }

  generateScatterPlot() {
    if(this.parameterDetails.parameterDesc  == "pH"){
      this.chartTitle = this.parameterDetails.parameterDesc + ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Bromide"){
      this.chartTitle = this.parameterDetails.parameterName + ' (Br\u207B) '+ ' - ' + this.distanceType;

    } else if(this.parameterDetails.parameterName  == "Fluoride"){
      this.chartTitle = this.parameterDetails.parameterName + ' (F\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Glycolate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (C\u2082H\u2083O\u2083\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Chloride"){
      this.chartTitle = this.parameterDetails.parameterName + ' (Cl\u207B) '+ ' - ' + this.distanceType;

    } else if(this.parameterDetails.parameterName  == "Nitrite"){
      this.chartTitle = this.parameterDetails.parameterName + ' (NO\u2082\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Nitrate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (NO\u2083\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Molybdate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (MoO4\u00B2\u207B)'+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Oxalate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (C\u2082O\u2084\u00B2\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Phosphate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (PO\u2084\u00B3\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Formate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (COO\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Sulfate"){
      this.chartTitle = this.parameterDetails.parameterName + ' (SO\u2084\u00B2\u207B) '+ ' - ' + this.distanceType;

    }else if(this.parameterDetails.parameterName  == "Cl+F+Br"){
      this.chartTitle = this.parameterDetails.parameterName + ' - ' + this.distanceType;;
    }else if(this.parameterDetails.parameterName  == "Carboxylate"){
      this.chartTitle = this.parameterDetails.parameterName + ' - ' + this.distanceType;;
    }else if(this.parameterDetails.parameterName  == "SCA"){
      this.chartTitle = this.parameterDetails.parameterName + ' - ' + this.distanceType;;
    }else if(this.parameterDetails.parameterName  == "Glycol"){
      this.chartTitle = this.parameterDetails.parameterName + ' - ' + this.distanceType;;
    }
    else{
    this.chartTitle = this.parameterDetails.parameterDesc + ' (' + this.parameterDetails.parameterName + ') ' + ' - ' + this.distanceType;
    }
    this.setYAxisLabel();
    this.xAxisLabel = this.distanceType;

    var margin = { top: 20, right: 15, bottom: 60, left: 60 }
      , width = 800 - margin.left - margin.right
      , height = 350 - margin.top - margin.bottom;

    this.allUnits.forEach((unit, i) => {
      //this.legendClass.push(this.unitColor[i]+'-background' );
      this.legendClass[unit.equipId] = this.unitColor[i] + '-background';
    });

    let unitArray = [];
    this.legendSelectedUnits = [];
    this.selectedUnits.forEach((unit) => {
      unitArray = unitArray.concat(this.scatterPlotData[unit.equipId]);
      if (this.scatterPlotData[unit.equipId].length > 0) {
        this.legendSelectedUnits.push(unit);
      }
    });
    if (unitArray.length == 0) {
      this.resetGraph();
      this.noRecordAlert.emit();
      return false;
    }

    var x = d3.scaleLinear()
      .domain([0, d3.max((unitArray), function (d) { return d[0]; })])
      .range([0, width]);

    var x1 = d3.scaleLinear()
      .domain([0, d3.max((unitArray), function (d) { return d[0]; }) * 1.60934])
      .range([0, width]);

    //setting up Y-axis data
    var y = d3.scaleLinear()
      .domain([0, d3.max((unitArray), function (d) { return d[1]; })])
      .range([height, 0]);

    var svg = d3.select('#scatterPlot')
      .append('svg')
      .attr('width', width + margin.right + margin.left)
      .attr('height', height + margin.top + margin.bottom);

    // Y-axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - (height / 2) - 10)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(this.yAxisLabel).style("font-weight", "bold").style("font-size", "12px");

    //X-axis label
    svg.append("text")
      .attr("transform",
        "translate(" + (width / 2) + " ," +
        (height + margin.top + 20) + ")")
      .attr("x", 60)
      .attr("y", 20)
      .style("text-anchor", "middle")
      .text(this.xAxisLabel).style("font-weight", "bold").style("font-size", "12px");


    var main = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'main');

    // draw the X-axis
    var xAxis = d3.axisBottom(x).tickSize(-height).tickSize(0);

    var xAxisTopTick = x.ticks();
    xAxisTopTick = xAxisTopTick.map(x => x * 1.60934);
    var xAxisTop = d3.axisTop(x1).tickSize(-height).tickValues(xAxisTopTick).tickSize(0);

    main.append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    if (this.uom != 'HOURS') {
      main.append('g')
        .attr('transform', 'translate(0,' + (height) + ')')
        .call(xAxisTop);
    }

    // draw the Y-axis
    var yAxis = d3.axisLeft(y).tickSize(-width);

    main.append('g')
      .attr('transform', 'translate(0,0)')
      .attr('class', 'main axis date')
      .style("color", "lightgray")
      .call(yAxis).selectAll("text").style("color", "black");

    var g = main.append("g");

    var tooltip;
    var mouseover = function (d) {
      tooltip = d3.select("#scatterPlot")
        .append("div")
        .attr("id", "tool")
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "4px")
        .style("font-size", "10px")
        .style("opacity", 1)
    }

    let self = this;
    this.parameterDetails.parameterDesc + ' (' + this.parameterDetails.parameterUnit + ') ' + ' - ' + this.distanceType;
    var mousemove = function (d) {
      var tempUom = self.convertUomDesc(d[3]);
      if(self.parameterDetails.parameterDesc == 'pH'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterDesc + ': ' + d[1] + ' ')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      } else if(self.parameterDetails.parameterName == 'Molybdate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Phosphate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }
      else if(self.parameterDetails.parameterName == 'Nitrite'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      } else if(self.parameterDetails.parameterName == 'Nitrate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Bromide'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Fluoride'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Chloride'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'oxalate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Formate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Glycolate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }else if(self.parameterDetails.parameterName == 'Sulfate'){
        tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterName + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      }
      else{
      tooltip
        .html('Sample Id: ' + d[2] + '<br/>' + self.distanceType + ': ' + d[4] + ' ' + tempUom + '<br/>' + self.parameterDetails.parameterDesc + ': ' + d[1] + ' (' + self.parameterDetails.parameterUnit + ')')
        .style("left", (d3.mouse(this)[0] + 90) + "px")
        .style("top", (d3.mouse(this)[1] + 10) + "px")
      }
    }

    var mouseleave = function (d) {
      d3.select('#scatterPlot').selectAll('#tool').remove();
    }

    var mouseClick = function (d) {
      self.navigateToViewReport(d[2]);
    }

    //scatter circle placement in graph
    this.selectedUnits.forEach((unit, i) => {
      g.selectAll("scatter-dots")
        .data(this.scatterPlotData[unit.equipId])
        .enter().append("circle")
        .attr("cx", function (d, i) { return x(d[0]); })
        .attr("cy", function (d) { return y(d[1]); })
        .attr("r", 8)
        .style("fill", this.unitColor[this.allUnits.findIndex(x => x.equipId == unit.equipId)])
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)
        .on("click", mouseClick);
    });

    var label = svg.selectAll(".tick")
      .selectAll("text")['_groups']
      .filter(function (d) {
        if (d[0].innerHTML == 0) {
          return true;
        }
      });

    if (this.uom == 'HOURS') {
      if (label != undefined && label.length > 0) {
        label[0][0].innerHTML = 'Hrs';
        label[0][0].setAttribute("x", 8);
      }
    } else {
      if (label != undefined && label.length > 1) {
        label[0][0].innerHTML = 'mi';
        label[0][0].setAttribute("x", 8);
        label[1][0].innerHTML = 'km';
        label[1][0].setAttribute("x", 8);
      }
    }
  }

  setYAxisLabel() {
    console.log(this.parameterDetails)
    this.yAxisLabel = this.parameterDetails.parameterDesc + ' (' + this.parameterDetails.parameterUnit + ')';
    if (
      this.parameterDetails.parameterName == 'FuelDilution' ||
      this.parameterDetails.parameterName == 'TAN' ||
      this.parameterDetails.parameterName == 'TBN' ||
      this.parameterDetails.parameterName == 'Vis40' ||
      this.parameterDetails.parameterName == 'Vis100') {
      this.yAxisLabel = this.parameterDetails.parameterDesc + ' (' + this.parameterDetails.parameterUnit + ')';
    }else if( this.parameterDetails.parameterDesc  == "pH"){
      this.yAxisLabel = this.parameterDetails.parameterDesc ;

    } else if(this.parameterDetails.parameterName.toString() == 'Chloride'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Glycolate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Formate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Sulfate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Fluoride'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Bromide'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Molybdate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Nitrate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Nitrite'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    } else if(this.parameterDetails.parameterName.toString() == 'Oxalate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    }else if(this.parameterDetails.parameterName.toString() == 'Phosphate'){
      this.yAxisLabel = this.parameterDetails.parameterName + ' (' + this.parameterDetails.parameterUnit + ')';
    }else if(this.parameterDetails.parameterName.toString() == 'Cl+F+Br'){
      this.yAxisLabel = this.parameterDetails.parameterDesc + ' (' + this.parameterDetails.parameterUnit + ')';
    }
    else if(this.parameterDetails.parameterDesc == this.parameterDetails.parameterName){
      this.yAxisLabel = this.parameterDetails.parameterDesc + ' (' + this.parameterDetails.parameterUnit + ')';

    }
  }

  navigateToViewReport(sampleId) {
    var data = {
      columnName: 'sampleId',
      companyId: '',
      currentPage: '1',
      pageSize: '10',
      sampleId: sampleId,
      sortingOrder: 'desc',
      status: 'Published',

      userRole: this.userRole,

      //fluidType: 'Engine Oil (Liquid Fuel)'
      fluidType: this.inputDataTemp.fluidTypeName
    }
    if (this.userRole == USERTYPE.EXTERNAL) {
      data['userRole'] = USERS.EXTERNAL;
    }
    this.analyzeSampleService.getAnalyzeSampleDashboardData(data).subscribe(
      response => {
        if (response.statusCode == "1") {
          let sampleData = response.analyseSamplesList;
          if (sampleData.length > 0) {
            localStorage.setItem('viewReportData', JSON.stringify(sampleData[0]));
            //this.router.navigate(['/viewReport']);
            this.viewReport.emit();
          }
        }
      }
    );
  }

  convertUomDesc(value) {
    let retValue;
    if (value == 'MILES') {
      retValue = '(mi)';
    } else if (value == 'KILOMETERS') {
      retValue = '(km)';
    } else if (value == 'HOURS') {
      retValue = '(hr)';
    } else {
      retValue = '';
    }
    return retValue;
  }
}
