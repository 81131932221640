import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../../handlers/api-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddVehicleService {

  constructor(private api: ApiHandlerService) { }

  getEquipmentDropdownData(userRole: any) {
    //console.log("user role", userRole);
    return this.api.post(environment.apiUrl + '/getEquipmentDropdownData', userRole);
  }

  addEquipment(extUserAddVehicleForm) {
    //console.log("form in add equip service", extUserAddVehicleForm); 
    return this.api.post(environment.apiUrl + '/addEquipment', extUserAddVehicleForm)
  }
  getFilterData(data: any) {
    return this.api.post(environment.apiUrl + '/getFilterData', data);
  }

  getFluidAndFilterDetails(reqData: any) {
    return this.api.post(environment.apiUrl + '/coatFluidAndFilterDropdown', reqData)
  }

  getFluidData(data: any) {
    return this.api.post(environment.apiUrl + '/getFluidData', data);
  }

  addDropdownOption(data: any) {
    return this.api.post(environment.apiUrl + '/addDropdownOption', data);
  }

  updateEquipment(data) {
    return this.api.post(environment.apiUrl + '/editEquipment', data);
  }

}
