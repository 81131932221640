import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs/Rx'; import 'rxjs/Rx';
import { OnDestroy } from '@angular/core';
import { Pop, PopType } from './pop';
import { PopService } from './pop.service';



@Component({
  selector: 'app-pop',
  templateUrl: './pop.component.html',
  styleUrls: ['./pop.component.css']
})
export class PopComponent implements OnInit, OnDestroy {
  @Input() id: string;
  public showMsgBox = false;
  private subscription: Subscription;
  private timer: Observable<any>;
  pops: Pop[] = [];
  constructor(private popService: PopService) { }
  ngOnInit() {
    this.popService.getAlert(this.id).subscribe((pop: Pop) => {
      if (!pop.message) {
        this.pops = [];
        return;
      }

      // add alert to array
      this.pops = [];
      this.pops.push(pop);
      this.setTimer();
    });
  }
  removeAlert(pop: Pop) {
    this.pops = this.pops.filter(x => x !== pop);
  }


  public setTimer() {
    // set showloader to true to show loading div on view
    this.showMsgBox = true;
    this.timer = Observable.timer(10000); // 5000 millisecond means 5 seconds
    this.subscription = this.timer.subscribe(() => {
      // set showloader to false to hide loading div from view after 5 seconds
      this.showMsgBox = false;
    });
  }
  ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
    this.pops = [];
  }
}
