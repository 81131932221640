import { Component, OnInit, ViewChildren } from '@angular/core';
import { common, warnMessage } from '../../common/constants/constant';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { element } from 'protractor';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management/master-data-management.service';

@Component({
  selector: 'app-customer-aacount-with-access',
  templateUrl: './customer-aacount-with-access.component.html',
  styleUrls: ['./customer-aacount-with-access.component.css']
})
export class CustomerAacountWithAccessComponent implements OnInit {
  @ViewChildren(SortComponent) sortComponents;
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSizeMetadata = '10';
  currentPageMetadata = 1; 
  changePageMetadata: any = 1;
  totalItemsMetadata: any;
  showRecordsMetadata = 0;
  maxPageMetadata = 0;
  scrollToTop =  false;
  sortingOrder: string = 'asc';
  customerTable: any = [];
  successMessage: string = '';
  successModal = false;
  columnName: string;
  searchObj:any;
  deleteBranchdataModal = false;
  customerIdtoDelete: any;
  customerNametoDelete: any = '';
  displayResult = false;

  // selectedCustomer: any = [];
  // selectedCustomerName: any = [];
  
  actionForm = new UntypedFormGroup({
    justification: new UntypedFormControl('', [Validators.required])
  });

  customerDataColumn = [
    { code: 'customerId', name: 'Customer ID' },        
    { code: 'customerName', name: 'Customer Name' },
    { code: 'customerState', name: 'Customer State' },
    { code: 'customerCountry', name: 'Customer Country' },
    { code: 'customerZipCode', name: 'Customer Zip Code' },
    { code: 'action', name: 'Actions' },
  ];

  searchForm = new UntypedFormGroup({
    customerId: new UntypedFormControl(''),
    customerName: new UntypedFormControl(''),
    customerState: new UntypedFormControl(''),
    customerCountry: new UntypedFormControl(''),
    customerZipCode: new UntypedFormControl(''),
    action:new UntypedFormControl('')
  });

  filterObject = {
    customerId: [],
    customerName: [],
    customerState: [],
    customerCountry: [],
    customerZipCode: []
  };

  constructor(private masterDataManagementService:MasterDataManagementService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService) { }

  ngOnInit(): void {
    this.displayResult = false;
    this.columnName = '';
    this.searchMetadata();
  }

  searchMetadata() {
    this.currentPageMetadata = 1;
    this.fetchMetadata();
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;  
    this.fetchMetadata();    
  }

  fetchMetadata() {
    let data = this.searchForm.getRawValue();
    data['sortingOrder'] = this.sortingOrder;
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['functionName'] = 'SEARCH';
    data['updatedBy'] = this.sharedService.getUserId();
    data['columnName'] = this.columnName;
    this.searchObj = data;

    this.masterDataManagementService.getCustomerListData(data).subscribe(response =>{  
      // console.log(response);
      if (response.statusCode == "1") {
        this.customerTable = response.custAcctWithAccessList;  
        this.displayResult = true;
        // this.selectedCustomer = [];
        // this.selectedCustomerName = [];
        if(response.custAcctWithAccessList.length == 0) {
          // this.alertService.error("There is no customer with access");
          this.totalItemsMetadata = 0;
        } else { 
          this.totalItemsMetadata = response.custAcctWithAccessList[0].maxRow;
        }
        this.calculateOffsetMetadata();
        
      }else {
        this.displayResult = true;
        this.customerTable = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
  }

  /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
  */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.fetchMetadata();
  }

  isSearchable(column) {
    if(column == 'customerId' || column == 'customerName' || column == 'customerState' || column == 'customerCountry' || column == 'customerZipCode'){
      return true;
    }else{
      return false;
    }
  }

  searchFilter(str) {
    if(!this.searchForm.value[str]) {
      this.searchMetadata();
    }
    let inputData = this.searchForm.getRawValue();
    inputData['searchColumn'] = str;
    inputData['searchValue'] = this.searchForm.value[str];   
    inputData['functionName'] = "SEARCH";
    inputData['updatedBy'] = this.sharedService.getUserId();

    this.searchObj = {
      pageSize: this.pageSizeMetadata.toString(),
      currentPage: this.currentPageMetadata.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      updatedBy: this.sharedService.getUserId()
    };

    Object.assign(inputData, this.searchObj);
    if(inputData['searchValue'].length >= 2) {
      this.masterDataManagementService.getCustomerListData(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.filterObject[str] = response.custAcctWithAccessList;
        } else {
          this.filterObject[str] = [];
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    }
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.searchMetadata();
    }
  }
  
  // Pagination for Metadata

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {        
        
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  closeDeleteBranchdataModal() {
    this.deleteBranchdataModal = false;
  }

  openBranchdataDeleteModal(item) {
    this.customerIdtoDelete = item.customerId;
    this.customerNametoDelete = item.customerName;
    this.deleteBranchdataModal = true;
  }

  deleteBranchData() {    
    let data = {
      "functionName": "DELETE",
      "customerId": this.customerIdtoDelete,
      "customerName": this.customerNametoDelete,
      "updatedBy": this.sharedService.getUserId()
    }
    this.masterDataManagementService.getCustomerListData(data).subscribe(response => {       
      if (response.statusCode == "1") {         
        this.successMessage = response.statusMessage;
        this.successModal = true; 
        this.fetchMetadata();     
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.deleteBranchdataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.deleteBranchdataModal = false;
    });
  } 
}
