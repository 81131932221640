import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/View/service/shared.service';
import { MoreInformationService } from './more-information.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { ManageFleetService } from '../manage-fleet.service';

@Component({
  selector: 'app-more-information',
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.css']
})
export class MoreInformationComponent implements OnInit {

  readonly USERTYPE = USERTYPE;
  extUserEquipmentColumns: any;
  intUserEquipmentColumns: any;
  filterColumns: any;
  userRole: string = '';
  companyId: any;
  companyName: any
  unitId: any;
  distanceUOM: any;
  unitMake: any;
  unitModel: any;
  unitYear: any;
  esn: any;
  engineMake: any;
  engineModel: any;
  engineYear: any;
  equipmentId: any;
  unitNo:any;
  oilInfo: any = [];
  coolantInfo: any = [];
  fuelInfo: any = [];
  defInfo: any = [];
  fluidForOil: any = [];
  fluidForCoolant: any = [];
  fluidForFuel: any = [];
  fluidForDef: any = [];
  filterForOil: any = [];
  filterForCoolant: any = [];
  filterForFuel: any = [];

  requesterDashboardList = [
    {
      'unitId': 'Red 502',
      'distanceUOM': 'Miles',
      'esn': '1234435',
      'unitMake': 'Volvo',
      'year': '2019',
      'unitModel': '2014',
      'engineMake': 'Cummins',
      'engineModel': 'ISX 15',
      'engineYear': '2017',
      'expanded': false,
    }
  ];

  filterList = [
    {
      'previous_sample': 'Last Sampled Date',
      'oil': 'Sep-16-2019',
      'fuel': 'Aug-15-2019',
      'coolant': 'Aug-15-2019',
      'diesel_exhaust_fluid': 'Aug-15-2019'
    },
    {
      'previous_sample': 'Unit Distance',
      'oil': '15,000',
      'fuel': '17,953',
      'coolant': '15,000',
      'diesel_exhaust_fluid': '15,000'
    },
    {
      'previous_sample': 'Fluid Distance',
      'oil': '10,000',
      'fuel': '20,000',
      'coolant': '17,500',
      'diesel_exhaust_fluid': '18,000'
    },
    {
      'previous_sample': 'Fluid Brand',
      'oil': 'Valvoline',
      'fuel': 'FleetGaurd',
      'coolant': 'CountryMark',
      'diesel_exhaust_fluid': 'Pilot'
    },
    {
      'previous_sample': 'Fluid Product',
      'oil': 'Premium Blue',
      'fuel': 'ES complete',
      'coolant': 'Diesel 2',
      'diesel_exhaust_fluid': 'Def 1'
    },
    {
      'previous_sample': 'Fluid Grade',
      'oil': '15W-40',
      'fuel': 'OAT',
      'coolant': 'USLD',
      'diesel_exhaust_fluid': '30% Urea'
    },
    {
      'previous_sample': 'Sampled Location',
      'oil': 'Oil Refill',
      'fuel': 'Radiator',
      'coolant': 'Bulk Tank',
      'diesel_exhaust_fluid': 'Trunk Tank'
    },
    {
      'previous_sample': 'Fluid Changed (Y/N)',
      'oil': 'Y',
      'fuel': 'Y',
      'coolant': '',
      'diesel_exhaust_fluid': 'N'
    },
    {
      'previous_sample': 'Fluid Top-Off (Y/N)',
      'oil': 'N',
      'fuel': 'N',
      'coolant': '',
      'diesel_exhaust_fluid': 'Y'
    },
    {
      'previous_sample': 'Top-Off AMT (qrt, gal, L)',
      'oil': '0',
      'fuel': '0',
      'coolant': '',
      'diesel_exhaust_fluid': '1'
    },
    {
      'previous_sample': 'SCA Added (Y/N)',
      'oil': '',
      'fuel': 'Y',
      'coolant': '',
      'diesel_exhaust_fluid': ''
    },
    {
      'previous_sample': '% Biofuel',
      'oil': '',
      'fuel': '',
      'coolant': 'B5',
      'diesel_exhaust_fluid': ''
    },
    {
      'previous_sample': 'Filter Brand',
      'oil': 'Filteration',
      'fuel': '',
      'coolant': 'Filteration',
      'diesel_exhaust_fluid': ''
    },
    {
      'previous_sample': 'Filter Product',
      'oil': 'FP1234-5',
      'fuel': '',
      'coolant': 'FY1234-5',
      'diesel_exhaust_fluid': ''
    }
  ];
  componentDisplayedColumns: { code: string; name: string; }[];
  componentData: any;
  componentCount: any;
  updatedDate: any;
  constructor(private router: Router,
    private alertService: AlertService,
    private sharedService: SharedService,
    private moreInfoService: MoreInformationService,private manageFleetService:ManageFleetService) { }

  ngOnInit() {
    if (this.sharedService.getInformation()) {
      this.companyId = this.sharedService.getInformation().companyId;
      this.companyName = this.sharedService.getInformation().companyName;
      this.unitId = this.sharedService.getInformation().unitId;
      this.distanceUOM = this.sharedService.getInformation().distanceUOM;
      this.unitMake = this.sharedService.getInformation().unitMake;
      this.unitModel = this.sharedService.getInformation().unitModel;
      this.unitYear = this.sharedService.getInformation().unitYear;
      this.esn = this.sharedService.getInformation().esn;
      this.engineMake = this.sharedService.getInformation().engineMake;
      this.engineModel = this.sharedService.getInformation().engineModel;
      this.engineYear = this.sharedService.getInformation().engineYear;
      this.equipmentId = this.sharedService.getInformation().equipmentId;
      this.unitNo = this.sharedService.getInformation().unitNo;
      this.componentCount = this.sharedService.getInformation().componentCount;
      this.updatedDate = this.sharedService.getInformation().updatedDate;
      console.log(this.unitNo)

      localStorage.removeItem('MoreInfoData');
      localStorage.setItem('MoreInfoData', JSON.stringify(this.unitId));
    } else {
      this.unitId = JSON.parse(localStorage.getItem('MoreInfoData'));
    }
    this.userRole = this.sharedService.getUserRole();
    this.extUserEquipmentColumns = [
      { code: "unitId", name: "Unit ID" },
      // { code: "secondaryUnitId", name: "Secondary Unit ID" },
      { code: "unitMake", name: "Unit Make" },
      { code: "unitModel", name: "Unit Model" },
      { code: "unitYear", name: "Unit Year" },
      // { code: "esn", name: "ESN" },
      // { code: "engineMake", name: "Engine Make" },
      // { code: "engineModel", name: "Engine Model" }
      { code: "componentCount", name: "No Of Components" },
      { code: "updatedDate", name: "Date Modified" }
    ];
    this.intUserEquipmentColumns = [
      { code: "companyName", name: "Customer Name" },
      // { code: "customerID", name: "Customer ID" },
      { code: "companyId", name: "Company ID" },
      { code: "unitId", name: "Primary Unit ID" },
      { code: "unitMake", name: "Unit Make" },
      { code: "unitModel", name: "Unit Model" },
      { code: "unitYear", name: "Unit Year" },
      // { code: "esn", name: "ESN" },
      // { code: "engineMake", name: "Engine Make" },
      // { code: "engineModel", name: "Engine Model" }
      { code: "componentCount", name: "No Of Components" },
      { code: "updatedDate", name: "Date Modified" }
    
    ];
    this.componentDisplayedColumns = [
      { code: "componentId", name: "Component ID" },
      { code: "componentType", name: "Component" },
      { code: "secondaryUnitId", name: "Secondary ID" },
      { code: "engineType", name: "Type" },
      { code: "componentMake", name: " Make" },
      { code: "componentModel", name: " Model" },
      { code: "componentYear", name: " Year" },
      { code: "updatedDate", name: "Date Modified" },
      { code: "distanceUom", name: "UOM" }
    
    ];
    this.filterColumns = [
      { code: "previous_sample", name: "" },
      { code: "oil", name: "Oil" },
      { code: "coolant", name: "Coolant" },
      { code: "fuel", name: "Diesel Fuel" },
      { code: "diesel_exhaust_fluid", name: "Diesel Exhaust Fluid" }
    ];
    this.getMoreInfo();
    this.getComponentShowMoreInfo();
    
  }

  getComponentShowMoreInfo(){
    let _data = {
      "unitNo": this.unitNo
    }
      this.manageFleetService.getManageFleetDashboardData(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.componentData = response.componentList;
          } else {
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.alertService.error(err);
        }
      );
  }

  getMoreInfo() {
    let _data = {
      "equipmentId": this.equipmentId,
      "unitNo": this.unitId  
    }
    this.moreInfoService.getMoreInfo(_data).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.oilInfo = response.oilSampleInfo;
          this.coolantInfo = response.coolantSampleInfo;
          this.fuelInfo = response.fuelSampleInfo;
          this.defInfo = response.defSampleInfo;
          this.fluidForOil = response.fluidForOil;
          this.fluidForCoolant = response.fluidForCoolant;
          this.fluidForFuel = response.fluidForFuel;
          this.fluidForDef = response.fluidForDEF;
          this.filterForOil = response.filterForOil;
          this.filterForCoolant = response.filterForCoolant;
          this.filterForFuel = response.filterForFuel;
        } else {
          this.alertService.error(response.statusMessage);
        }
        // const abc = response.manageFleetDTOList;
        // console.log(abc + "this is the response");
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  // editEquipment() {
  //   this.router.navigate(['/editVehicle', 'edit','']);
  // }
  editEquipment(){
    var info = {
      mode : 'edit',
      companyId: ''
    }
    this.sharedService.setAddOrEdit(info);
    this.router.navigate(['/editVehicle']);
  }
}
