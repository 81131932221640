import { AfterViewChecked, Component, OnInit} from '@angular/core';
import { LineGraphHomeService } from './line-graph-home.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import html2canvas from 'html2canvas';
import * as d3 from 'd3';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as  pdfFonts from 'pdfmake/build/vfs_fonts';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/View/service/shared.service';
import { ScatterPlotSharedService } from '../../scatterplot/scatter-plot-grid/scatter-plot-shared.service';
import { AnalyzeSampleGridService } from '../../../analyze-sample/analyze-sample-grid/analyze-sample-grid.service';
// import { indexOf } from 'core-js/core/array'
import { USERTYPE, USERS } from 'src/app/View/common/constants/constant';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-line-graph-home',
  templateUrl: './line-graph-home.component.html',
  styleUrls: ['./line-graph-home.component.css']
})
export class LineGraphHomeComponent implements OnInit, AfterViewChecked {
  selectedFluidType: string
  selectedUnitValue: string;
  selectedKeyValue: string;
  behaviorObsTSM: any;
  behaviorObs: any;

  constructor(private linegraphServ: LineGraphHomeService, private sharedService: SharedService,
    private alertService: AlertService, private router: Router,
    private scatterSharedService: ScatterPlotSharedService,
    private analyzeSampleService: AnalyzeSampleGridService) { }


  //@Output("viewReport") viewReport: EventEmitter<any> = new EventEmitter();
  searchObj: any;
  unitListData: any[];
  keyIssueData: any[];
  dataArray = new Array();
  keyIssueParamsData: any[];
  parameterListData: any[];
  originalParameterListData: any[];
  paramsData: any[];
  todisplayParams: boolean;
  showParameter: boolean;
  equipmentId: string = '';
  lineGraphParamDataY1: any = [];
  lineGraphParamDataY2: any = [];
  sampleIdList: any = [];
  lineGraphDataSet: any = [];
  graphInputReq: any = [];
  parameter: any;
  individualParam: string = '';
  yAxisLabel = '';
  lineGraphImage = '';
  parameterY2: any;
  yAxisLabelY1: any = [];
  yAxisLabelY2: any = [];

  selectedKeyIssuesParam: any = [];
  y1LegendList: any = [];
  y2LegendList: any = [];
  selectedKeyIssuesParamObj: any = {};
  isSelectedParam: boolean = false;
  isExportToPDF: boolean = false;
  noRecordForLineGraph: boolean = false;
  hideViewReport = true;
  fluidTypeList: any[] = [];
  scrollToTop = false;

  userRole: string = '';
  uomLabel:any;

  /*keyIssues = ["Fuel Contamination", "Engine Wear", "Soot Contamination",
    "Degraded Oil", "Coolant Contamination", "Dust Contamination", "Select a Parameter"];*/
  keyIssues = [];  

  keyIssues1 = [{ id: "Fuel Contamination", value: {} },
  { id: "Engine Wear", value: {} },
  { id: "Soot Contamination", value: {} },
  { id: "Degraded Oil", value: {} },]

  companyIdTSM: any = '';
  tsmCustomerNameDropdown: any;
  messageDropdownTsm: any;
  isFirstTime: boolean = true;
  companyIdDBU: any = '';
  dbuCustomerNameDropdown: any;

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();

    if(localStorage.getItem("customerDropdownTSM") && (this.userRole == USERTYPE.TSM)){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")))      
    } else if(localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_FLUIDS)){
      this.recieveMessageDBU(JSON.parse(localStorage.getItem("branchCustomerDropdown")))
    }

    this.todisplayParams = false;
    this.showParameter = false;
    this.isSelectedParam = false;
    this.selectedKeyIssuesParamObj = { colorLegend: '', param: '' };
    this.isExportToPDF = false;
    this.noRecordForLineGraph = false;
    //this.getLineGraphData();
    this.getFluidType();

    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message=>{
      if(this.userRole == USERTYPE.TSM){
        if(this.router.url == '/lineGraphHome'){      
          this.messageDropdownTsm = message;
          if(this.isFirstTime == false){
            this.recieveMessageTSM(this.messageDropdownTsm);
          }
          this.isFirstTime = false;

          this.reset();
        }
      }
    });

    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if(this.userRole == USERTYPE.DBU_FLUIDS){
        if(this.router.url == '/lineGraphHome'){      
          this.messageDropdownTsm = message;
          if(this.isFirstTime == false){
            this.recieveMessageDBU(this.messageDropdownTsm);
          }
          this.isFirstTime = false;

          this.reset();
        }
      }
    });
  }

  ngOnDestroy () {
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
  }

  recieveMessageTSM(event){
    this.tsmCustomerNameDropdown = event;
    // companyName = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
  }

  recieveMessageDBU(event){
    this.dbuCustomerNameDropdown = event;
    this.companyIdDBU = this.dbuCustomerNameDropdown.customerId;
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getFluidType(){
    this.linegraphServ.getFluidType().subscribe(
      response => {
        if (response.statusCode == "1") {
          this.fluidTypeList = response.fluidTypeList;    
          this.fluidTypeList = this.fluidTypeList.filter(row=>{
            return row.fluidType == 1 || row.fluidType == 2 || row.fluidType == 3 || row.fluidType == 4 || row.fluidType == 5 || row.fluidType == 6
          });      
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  fluidTypeChange(){
    if(this.userRole == USERTYPE.TSM && this.companyIdTSM == ''){
      this.alertService.error("Please select Company Name");
    } else if(this.userRole == USERTYPE.DBU_FLUIDS && this.companyIdDBU == ''){
      this.alertService.error("Please select Company Name");
    } else {
      this.getLineGraphData();
      this.selectedUnitValue = null;
      this.selectedKeyValue = null;
      this.todisplayParams = false;
      this.showParameter = false;
      this.isSelectedParam = false;
      this.isExportToPDF = false;
      this.individualParam = null;
      this.dataArray = new Array();
      this.keyIssues = [];  
      d3.selectAll('svg').remove();
      this.alertService.clear();
    }
  }

  getLineGraphData() {
    if( this.userRole == USERTYPE.TSM ){
      this.searchObj = {
        "companyId": this.companyIdTSM,
        "fluidType": this.selectedFluidType,
        "fluidTypeName": this.getFluidTypeName()
      }
    } else if( this.userRole == USERTYPE.DBU_FLUIDS ){
      this.searchObj = {
        "companyId": this.companyIdDBU,
        "fluidType": this.selectedFluidType,
        "fluidTypeName": this.getFluidTypeName()
      }
    } else {
      this.searchObj = {
        "companyId":this.sharedService.getCompanyId(),
        "fluidType": this.selectedFluidType,
        "fluidTypeName": this.getFluidTypeName()
      }
    }

    this.linegraphServ.getLineGraphDataLoad(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {          
          this.keyIssueData = response.keyIssuesList;
          this.keyIssueParamsData = response.keyIssuesParams;
          this.originalParameterListData = JSON.parse(JSON.stringify(response.parametersList));
          this.parameterListData = response.parametersList;         
          this.unitListData = response.unitsList;
          this.dataArray = [];
          this.unitListData.forEach((value) => {
            this.dataArray.push(value.unitId)
          });          
          this.graphInputReq = response.keyIssueInputParams; 
          this.keyIssues = Object.keys(response.keyIssueInputParams);   
          this.keyIssues.push('Select a Parameter'); 

        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  getFluidTypeName(){
    let fluidTypeName = '';
    this.fluidTypeList.forEach(row => {
      if(row.fluidType == this.selectedFluidType){
        fluidTypeName = row.fluidTypeName;
      }
    });

    return fluidTypeName;
  }

  reset() {
    this.selectedFluidType = null;
    this.selectedKeyValue = null;
    this.selectedUnitValue = null;
    this.todisplayParams = false;
    this.showParameter = false;
    this.isSelectedParam = false;
    this.isExportToPDF = false;
    this.individualParam = null;
    this.dataArray = new Array();
    this.keyIssues = [];  
    d3.selectAll('svg').remove();
    this.alertService.clear();

  }

  onKey(value) {
    this.dataArray = [];
    this.selectSearch(value);
    // console.log("value",value)      
  }

  selectSearch(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.unitListData.length; i++) {
      let option = this.unitListData[i].unitId;
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.dataArray.push(option);
      }
    }
  }

  clearSearch() {
    this.selectSearch("");
  }



  displayParams(val: any) {
    this.showParameter = false;
    this.todisplayParams = false;
    this.isSelectedParam = false;
    this.individualParam = null;
    if (val == 'Select a Parameter') {
      this.showParameter = true;
      if(this.showParameter == true){
        this.parameterListData.forEach(element=> {​​​​​​
          let i= this.parameterListData.indexOf(element);
          if(element.parameterName==="Chloride"){​​​​​​
          this.parameterListData[i].parameterDesc ="Cl\u207B";
                  }​​​​​​
          if(element.parameterName==="Fluoride"){​​​​​​
            this.parameterListData[i].parameterDesc  ="F\u207B";
                  }​​​​​​
          else if(element.parameterName==="Bromide"){​​​​​​
            this.parameterListData[i].parameterDesc ="Br\u207B";
                  }​​​​​​
          else if(element.parameterName==="Sulfate"){​​​​​​
            this.parameterListData[i].parameterDesc="SO\u2084\u00B2\u207B";
                  }​​​​​​
          else if(element.parameterName==="Glycolate"){
            this.parameterListData[i].parameterDesc ="C"+'\u2082'+'H'+'\u2083'+'O'+'\u2083'+'\u207B'+"";
          }
          else if(element.parameterName==="Formate"){
            this.parameterListData[i].parameterDesc ="COO"+'\u207B'+"";
    
          } 
          else if(element.parameterName==="Molybdate"){
            this.parameterListData[i].parameterDesc ="MoO4"+'\u00B2'+'\u207B'+"";
    
          }
          else if(element.parameterName==="Nitrate"){
            this.parameterListData[i].parameterDesc ="NO"+'\u2083'+'\u207B'+"";
    
          }
          else if(element.parameterName==="Nitrite"){
            this.parameterListData[i].parameterDesc ="NO"+'\u2082'+'\u207B'+"";
          }   
          else if(element.parameterName==="Oxalate"){
            this.parameterListData[i].parameterDesc ="C"+'\u2082'+'O'+'\u2084'+'\u00B2'+'\u207B'+"";
    
          }
          else if(element.parameterName==="Phosphate"){
            this.parameterListData[i].parameterDesc ="PO"+'\u2084'+'\u00B3'+'\u207B'+"";
    
          }
          else if(element.parameterName==="PToluicAcid"){
            this.parameterListData[i].parameterName ="p-TA";
    
          }
        })
       }
      this.todisplayParams = false;
      this.isSelectedParam = false;    
      }
      else {​​​​​​
      this.showParameter=false;
      this.paramsData=this.keyIssueParamsData[val];

      this.parameterListData = JSON.parse(JSON.stringify(this.originalParameterListData))
      console.log("parameter List before graph show---->", this.paramsData);
      if(this.showParameter==false){
      this.paramsData.forEach(element=> {​​​​​​
      let i= this.paramsData.indexOf(element);
      if(element==="Chloride(Cl)"){​​​​​​
      this.paramsData[i] ="Chloride(Cl"+'\u207B'+")";
              }​​​​​​
      if(element==="Fluoride(F)"){​​​​​​
      this.paramsData[i] ="Fluoride(F"+'\u207B'+")";
              }​​​​​​
      else if(element==="Bromide(Br)"){​​​​​​
      this.paramsData[i] ="Bromide(Br"+'\u207B'+")";
              }​​​​​​
      else if(element==="Sulfate"){​​​​​​
      this.paramsData[i] ="Sulfate(SO"+'\u2084'+'\u00B2'+'\u207B'+")";
              }​​​​​​
      else if(element==="Glycolate"){
        this.paramsData[i] ="Glycolate(C"+'\u2082'+'H'+'\u2083'+'O'+'\u2083'+'\u207B'+")";
      }
      else if(element==="Formate"){
        this.paramsData[i] ="Formate(COO"+'\u207B'+")";

      } else if(element==="Molybdate"){
        this.paramsData[i] ="Molybdate(MoO4"+'\u2082'+'\u207B'+")";

      }
      else if(element==="Nitrate"){
        this.paramsData[i] ="Nitrate(NO"+'\u2083'+'\u207B'+")";

      }
      else if(element==="Nitrite"){
        this.paramsData[i] ="Nitrite(NO"+'\u2082'+'\u207B'+")";

      }
      else if(element==="Oxalate"){
        this.paramsData[i] ="Oxalate(C"+'\u2082'+'O'+'\u2084'+'\u00B2'+'\u207B'+")";

      }
      else if(element==="Phosphate"){
        this.paramsData[i] ="Phosphate(PO"+'\u2084'+'\u00B3'+'\u207B'+")";

      }

      }​​​​​​);
    }
      this.todisplayParams=true;
      this.isSelectedParam=false;
    }​​​​​​
  }
  reqParam = {};

  getDataForLineGraph() {
    this.yAxisLabel = this.selectedKeyValue;
    for (let i = 0; i < this.unitListData.length; i++) {
      if (this.selectedUnitValue == this.unitListData[i].unitId) {
        this.equipmentId = this.unitListData[i].equipmentId;
        if(this.unitListData[i].uom == 'MILES'){
          this.uomLabel = '(mi)';
        }else if(this.unitListData[i].uom == 'KILOMETERS'){
          this.uomLabel = '(km)';
        }else if(this.unitListData[i].uom == 'HOURS'){
          this.uomLabel = '(hr)';
        }
      }
    }
    if (this.selectedKeyValue == "Select a Parameter") {
      if(this.individualParam == null || this.individualParam == '' || this.individualParam == undefined){
        this.alertService.error('Select a parameter to generate a graph');
        this.scrollToTop = true;
        return false;
      }
      this.reqParam = {
        "equipId": this.equipmentId,
        paramList: this.individualParam,
        "fluidType": this.selectedFluidType,
        "keyIssue":this.selectedKeyValue,
        "fluidTypeName": this.getFluidTypeName()
      }
      this.yAxisLabel = this.individualParam;
    } else {
      this.reqParam = {
        "equipId": this.equipmentId,
        paramList: this.graphInputReq[this.selectedKeyValue],
        "fluidType": this.selectedFluidType,
        "keyIssue":this.selectedKeyValue,
        "fluidTypeName": this.getFluidTypeName()
      }
      this.yAxisLabel = this.selectedKeyValue;

    }


    this.linegraphServ.getDataForLineGraph(this.reqParam).subscribe(response => {
      if (response.statusCode == "1") {
        this.lineGraphParamDataY1 = response.lineGraphDataMapY1;
        this.lineGraphParamDataY2 = response.lineGraphDataMapY2;
        this.sampleIdList = response.sampleIdList;
        if (Object.keys(this.lineGraphParamDataY1).length > 0 || Object.keys(this.lineGraphParamDataY2).length > 0) {
          this.generateGraph();

        } else {
          this.noRecordForLineGraph = true;
        }
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }

    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });

  }


  generateGraph() {
    let unitArray = [];
    let unitArrayY2 = [];
    this.yAxisLabelY1 = [];
    this.yAxisLabelY2 = [];
    this.isSelectedParam = true;
    this.todisplayParams = false;
    this.selectedKeyIssuesParam = [];
    this.y1LegendList = [];
    this.y2LegendList = [];
    this.isExportToPDF = true;

    let color = ['teal', 'black', 'red', 'orange'];
    let colorY2 = ['blue', 'purple','#af2f0d','#696006'];

    var margin = { top: 50, right: 60, bottom: 50, left: 75 }
      , width = 800 - margin.left - margin.right
      , height = 500 - margin.top - margin.bottom;

    this.parameter = Object.keys(this.lineGraphParamDataY1);
    console.log("parameter====>", this.parameter);
    this.parameter.forEach(element => {
      unitArray = unitArray.concat(this.lineGraphParamDataY1[element]);

    });
    for (let i = 0; i < this.parameter.length; i++) {
      let keyIssuesObjY1 = { colorLegend: '', paramValue: '' };
      for (let j = 0; j < this.parameterListData.length; j++) {
         if (this.parameter[i].toString().toLowerCase() == this.parameterListData[j].parameterName.toString().toLowerCase() ) {
          if( this.parameterListData[j].parameterName=='pH' ){
            this.yAxisLabelY1.push( ''+this.parameterListData[j].parameterDesc+'');
          }
          else if (this.parameterListData[j].parameterName=='Glycolate' ){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if (this.parameterListData[j].parameterName=='Bromide' ){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if (this.parameterListData[j].parameterName=='Fluoride' ){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if (this.parameterListData[j].parameterName=='Chloride' ){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if (this.parameterListData[j].parameterName=='Nitrite' ){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if (this.parameterListData[j].parameterName=='Nitrate' ){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          } 
          else if(this.parameterListData[j].parameterName == 'Molybdate'){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if(this.parameterListData[j].parameterName == 'Oxalate'){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }else if(this.parameterListData[j].parameterName == 'Phosphate'){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if(this.parameterListData[j].parameterName == 'Formate'){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          else if(this.parameterListData[j].parameterName == 'Sulfate'){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterName + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
        
          else{
          this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          }
          keyIssuesObjY1.colorLegend = color[i];
          if (this.parameterListData[j].parameterName == 'Oxidation' ||
            this.parameterListData[j].parameterName == 'Nitration') {
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc;
          }
          else if (this.parameterListData[j].parameterDesc.toString() == 'Copper'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterName + ')';
          }
          else if (this.parameterListData[j].parameterDesc.toString() == 'pH' || this.parameterListData[j].parameterDesc.toString() == 'SCA' || this.parameterListData[j].parameterDesc.toString() == 'Glycol'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc ;
          }
         
          else if(this.parameterListData[j].parameterName.toString() == 'Bromide'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName+ ' ' + '(Br\u207B)';
          }
          else if(this.parameterListData[j].parameterName.toString() == 'Fluoride'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName+ ' ' + '(F\u207B)';
          }
          
          else if(this.parameterListData[j].parameterName.toString() == 'Glycolate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(C\u2082H\u2083O\u2083\u207B)';
          }
           else if(this.parameterListData[j].parameterName.toString() == 'Chloride'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(Cl\u207B)';
          }
          else if(this.parameterListData[j].parameterName.toString() == 'Nitrite'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(NO\u2082\u207B)';
          }else if(this.parameterListData[j].parameterName.toString() == 'Nitrate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(NO\u2083\u207B)';
          }
         
          else if(this.parameterListData[j].parameterName.toString() == 'Molybdate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(MoO4\u00B2\u207B)';
          }
          else if(this.parameterListData[j].parameterName.toString() == 'Oxalate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(C\u2082O\u2084\u00B2\u207B)';
          } else if(this.parameterListData[j].parameterName.toString() == 'Phosphate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(PO\u2084\u00B3\u207B)';
          }
          else if(this.parameterListData[j].parameterName.toString() == 'Formate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(COO\u207B)';
          }
          else if(this.parameterListData[j].parameterName.toString() == 'Sulfate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterName + ' ' + '(SO\u2084\u00B2\u207B)';
          }
          else if(this.parameterListData[j].parameterDesc.toString() == 'Carboxylate'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc;
          }
          else if(this.parameterListData[j].parameterName == 'Cl+F+Br'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc;
          }else if(this.parameterListData[j].parameterName == 'PToluicAcid'){
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc+ ' ' + '(' + 'p-TA' + ')';   ;
          }
          else {
            keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterName + ')';
          }
          this.selectedKeyIssuesParam.push(keyIssuesObjY1);
          this.y1LegendList.push(keyIssuesObjY1);
        } 
        else if(this.parameterListData[j].parameterName == 'p-TA' && this.parameter[i].toString().toLowerCase() == 'ptoluicacid'){
            this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
             keyIssuesObjY1.colorLegend = color[i];
             keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc + ' ' + '(' + 'p-TA' + ')';   
             this.selectedKeyIssuesParam.push(keyIssuesObjY1);
             this.y1LegendList.push(keyIssuesObjY1);   
         }
        else if (this.parameter[i].toString() == 'FUEL_DILUTION_PERCENT' &&
          this.parameterListData[j].parameterName.toString() == 'FuelDilution') {
          this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          keyIssuesObjY1.colorLegend = color[i];
          keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc;
          this.selectedKeyIssuesParam.push(keyIssuesObjY1);
          this.y1LegendList.push(keyIssuesObjY1);

        } else if (this.parameter[i].toString() == 'SOOT_PERCENT' &&
          this.parameterListData[j].parameterName.toString() == 'Soot') {
          this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          keyIssuesObjY1.colorLegend = color[i];
          keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc;
          this.selectedKeyIssuesParam.push(keyIssuesObjY1);
          this.y1LegendList.push(keyIssuesObjY1);

        } else if (this.parameter[i].toString() == 'WATER_PPM' &&
          this.parameterListData[j].parameterName.toString() == 'Water') {
          this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          keyIssuesObjY1.colorLegend = color[i];
          keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc;
          this.selectedKeyIssuesParam.push(keyIssuesObjY1);
          this.y1LegendList.push(keyIssuesObjY1);

        } else if (this.parameter[i].toString() == 'IND' &&
          this.parameterListData[j].parameterName.toString() == 'In') {
          this.yAxisLabelY1.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');
          keyIssuesObjY1.colorLegend = color[i];
          keyIssuesObjY1.paramValue = this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterName + ')';
          this.selectedKeyIssuesParam.push(keyIssuesObjY1);
          this.y1LegendList.push(keyIssuesObjY1);

        }

      }
    }
    d3.selectAll('svg').remove();

    var xScale = d3.scaleLinear()
      .domain([0, d3.max((unitArray), function (d) { return d[0]; })])
      .range([0, width]);

    var yScale = d3.scaleLinear()
      .domain([0, d3.max((unitArray), function (d) { return d[1]; })])
      .range([height, 0]);

    var line = d3.line()
      .x(function (d) { return xScale(d[0]); })
      .y(function (d) { return yScale(d[1]); });

    var svg = d3.select("#lineGraph").append("svg")
      .attr("width", "auto")
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr('viewBox', '0 0 600 400')
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
    var xAxis = d3.axisBottom(xScale).tickSize(-height);
    
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(xScale)).style('stroke-dasharray', '5,5').style('color', 'lightgray')
      .call(xAxis).selectAll('text').style('color', 'black').attr('x', '-21').attr('y', '0')
      .attr('transform', function (d) {
        return 'rotate(-60)'
      });


    svg.append("g")
      .attr("class", "y axis")
      .call(d3.axisLeft(yScale));

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -49)
      .attr('x', -200)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text(this.yAxisLabelY1).style('font-weight', 'bold').style('font-size', '12px');

    //X-axis label
    svg.append('text')
      .attr('transform',
        'translate(' + (width / 2) + ' ,' +
        (height + margin.top + 20) + ')')
      .attr('x', 5)
      .attr('y', -20)
      .style('text-anchor', 'middle')
      .text('Unit Distance' + this.uomLabel).style('font-weight', 'bold').style('font-size', '12px');


    //label header-start

    svg.append('text')
      .attr('transform',
        'translate(' + (width / 2) + ' ,' +
        (height + margin.top + 20) + ')')
      .attr('x', 5)
      .attr('y', -495)
      .style('text-anchor', 'middle')
      .text('Unit ID - ' + this.selectedUnitValue + ' - ' + this.yAxisLabel+'').style('font-size', '16px');


    //======================================= sample ID mouseover-start========================================
    // let sample = ["2222","3333","4444444","5555555"];
    // let sample = this.sampleIdList;

    // let div = svg.append("div")	
    // .attr("class", "tooltip")				
    // .style("opacity", 0); 

    // svg.selectAll("circle")
    // .data(sample)
    // .on("mouseover", function(d,i){
    //   div.html(sample[i]).style("left", (d3.event.pageX) + "px")		
    //   .style("top", (d3.event.pageY - 28) + "px");	
    //   div.style("opacity", .9);	
    //  // return d[i];
    // }).on("mouseout", function(d){
    //   div.style("opacity",0);
    // });
    //============================================================****************************
    let sample = this.sampleIdList;

    var tooltip;
    var mouseover = function (d) {
      tooltip = d3.select("#lineGraph")
        .append("div")
        .attr("id", "tool")
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "4px")
        .style("font-size", "10px")
        .style("opacity", 1)
    }
    let self = this;
    var mousemove = function (d, i) {   
      if(d[2] == 'pH'){
        tooltip
        .html('Sample Id: ' + sample[i] + '<br/>Unit Distance: ' + d[0] + ' '+ self.uomLabel +' <br>' + d[2] + ': ' + d[1] + ' ')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
      } else{
      tooltip
        .html('Sample Id: ' + sample[i] + '<br/>Unit Distance: ' + d[0] + ' '+ self.uomLabel +' <br>' + d[2] + ': ' + d[1] + ' (' + d[3] + ')')
        .style("left", (d3.mouse(this)[0] + 100) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
    }
    }

    
    var mouseClick = function (d, i) {
      self.navigateToViewReport(sample[i]);
    };

    var mouseleave = function (d) {
      d3.select('#lineGraph').selectAll('#tool').remove();
    };

    //======================================= sample ID mouseover-end========================================



    this.parameter.forEach((element, i) => {
      svg.append("path")
        .datum(this.lineGraphParamDataY1[element])
        .attr("class", "line")
        .attr("d", line).attr("fill", "none").attr("stroke", color[i]);

      this.lineGraphParamDataY1[element].forEach(row => {
        row.push(element);
      });

      svg.append("g").selectAll(".dot")
        .data(this.lineGraphParamDataY1[element], function (d) { return d[i]; })
        .enter().append("circle")
        .attr("class", "dot")
        .attr("cx", function (d) { return xScale(d[0]) })
        .attr("cy", function (d) { return yScale(d[1]) })
        .attr("r", 5).style("fill", color[i])
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)
        .on("click", mouseClick);
    });
    

    // =================================================to create legends=============================================================
    console.log("y1 legend=========================", this.y1LegendList);
    //legend Y1
    var legend = svg.append("g")
      .attr("class", "legend")
      .attr("x", width)
      .attr("y", 35)
      .attr("height", 100)
      .attr("width", 100);

     if(this.y1LegendList.length >2 ){
    legend.selectAll('circle')
      .data(this.y1LegendList)
      .enter().append("circle")
      .attr("cx", function (d, i) { return i * 110 + 10; })
      .attr("cy", -10)
      .attr("r", 5)
      .style("fill", function (d, i) {
        return color[i];
      });
    }else{
      legend.selectAll('circle')
      .data(this.y1LegendList)
      .enter().append("circle")
      .attr("cx", function (d, i) { return i * 100 + 20; })
      .attr("cy", -10)
      .attr("r", 5)
      .style("fill", function (d, i) {
        return color[i];
      });
    }
    if(this.y1LegendList.length >2 ){
    this.y1LegendList.forEach((element, index) => {
      svg.selectAll('.legend').append("text").text(element.paramValue)
        .attr("x", function (d, i) { return index * 110 + 20; })
        .attr("y", -6)
        .style("font-size", "10px");

    });
    }else{
      this.y1LegendList.forEach((element, index) => {
        svg.selectAll('.legend').append("text").text(element.paramValue)
          .attr("x", function (d, i) { return index * 100 + 33; })
          .attr("y", -6)
          .style("font-size", "10px");

      });
    }


    //=================================================dual y axis-start=======================================

    if (this.selectedKeyValue !== ('Engine Wear' || 'Select a Parameter')) {
      //CARBOXYLATE should appear first in the list
      this.parameterY2 = Object.keys(this.lineGraphParamDataY2);
      if(this.parameterY2.includes('CARBOXYLATE')){​​​​​    
            this.parameterY2 = this.parameterY2.filter(item => item !== "CARBOXYLATE");      
          this.parameterY2.unshift("CARBOXYLATE"); 
      }​​​​​
      console.log("parameter Y2====>", this.parameterY2);
      this.parameterY2.forEach(element => {
        unitArrayY2 = unitArrayY2.concat(this.lineGraphParamDataY2[element]);
        //console.log("unit array Y2====>", unitArrayY2);

      });
      for (let i = 0; i < this.parameterY2.length; i++) {
        let keyIssuesObjY2 = { colorLegend: '', paramValue: '' };
        for (let j = 0; j < this.parameterListData.length; j++) {
          if (this.parameterY2[i].toString().toLowerCase() == this.parameterListData[j].parameterName.toString().toLowerCase()) {
            this.yAxisLabelY2.push(' ' + this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterUnit + ')');

            keyIssuesObjY2.colorLegend = colorY2[i];
            if(this.parameterListData[j].parameterName.toString() == 'Chloride'){
              keyIssuesObjY2.paramValue = this.parameterListData[j].parameterName + ' ' + '(Cl\u207B)';
            }
            else if(this.parameterListData[j].parameterDesc.toString() == 'Glycolate'){
              keyIssuesObjY2.paramValue = this.parameterListData[j].parameterName + ' ' + '(C\u2082H\u2083O\u2083\u207B)';
            }
            else if(this.parameterListData[j].parameterName.toString() == 'Formate'){
              keyIssuesObjY2.paramValue = this.parameterListData[j].parameterName + ' ' + '(COO\u207B)';
            }
            else if(this.parameterListData[j].parameterName.toString() == 'Sulfate'){
              keyIssuesObjY2.paramValue = this.parameterListData[j].parameterName + ' ' + '(SO\u2084\u00B2\u207B)';
            }
            else if(this.parameterListData[j].parameterName.toString() == 'Carboxylate'){
              keyIssuesObjY2.paramValue = this.parameterListData[j].parameterName;
            }
            else if(this.parameterListData[j].parameterName.toString()  == 'PToluicAcid'){
              keyIssuesObjY2.paramValue = this.parameterListData[j].parameterDesc+ ' ' + '(' + 'p-TA' + ')';   ;
            }
            else{
            keyIssuesObjY2.paramValue = this.parameterListData[j].parameterDesc + ' ' + '(' + this.parameterListData[j].parameterName + ')';
            }
            this.y2LegendList.push(keyIssuesObjY2);
            this.selectedKeyIssuesParam.push(keyIssuesObjY2);
          }
        }
      }

      var yAxisRight = d3.scaleLinear()
        .domain([0, d3.max((unitArrayY2), function (d) { return d[1]; })])
        .range([height, 0]);


      var y2Scale = d3.scaleLinear()
        .domain([0, d3.max((unitArrayY2), function (d) { return d[1]; })])
        .range([height, 0]);


      var lineY2 = d3.line()
        .x(function (d) { return xScale(d[0]); })
        .y(function (d) { return y2Scale(d[1]); });

      svg.append("g")
        .attr("class", "axisRed")
        .attr("transform", "translate( " + width + ", 0 )")
        .call(d3.axisRight(yAxisRight));

      this.parameterY2.forEach((element, i) => {
        svg.append("path")
          .datum(this.lineGraphParamDataY2[element])
          .attr("class", "line")
          .attr("d", lineY2).attr("fill", "none").attr("stroke", colorY2[i]);


        this.lineGraphParamDataY2[element].forEach(row => {
          row.push(element);
        });
        svg.append("g").selectAll(".dot")
          .data(this.lineGraphParamDataY2[element], function (d, i) { return d[i]; })
          .enter().append("circle")
          .attr("class", "dot")
          .attr("cx", function (d) { return xScale(d[0]) })
          .attr("cy", function (d) { return y2Scale(d[1]) })
          .attr("r", 5).style("fill", colorY2[i])
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave)
          .on("click", mouseClick);


      });
      if(this.yAxisLabelY2.length > 3){
        let test1 = this.yAxisLabelY2.slice(0,2);
        let test2 = this.yAxisLabelY2.slice(2,this.yAxisLabelY2.length);
        svg.append('text')
        .attr("class", "y axis")        
        .attr('transform', 'rotate(-90)')
        .attr('y', 705)
        .attr('x', -200)
        .attr('dy', '1em')   
        .attr('height',200)
        .attr('width',200)
        .style('text-anchor', 'middle')
        .text( test1)
        .style('font-weight', 'bold').style('font-size', '12px');

        svg.append('text')
        .attr("class", "y axis")        
        .attr('transform', 'rotate(-90)')
        .attr('y', 720)
        .attr('x', -200)
        .attr('dy', '1em')   
        .attr('height',200)
        .attr('width',200)
        .style('text-anchor', 'middle')
        .text( test2)
        .style('font-weight', 'bold').style('font-size', '12px') 
      }else{
        svg.append('text')
        .attr("class", "y axis")        
        .attr('transform', 'rotate(-90)')
        .attr('y', 705)
        .attr('x', -200)
        .attr('dy', '1em')   
        .attr('height',200)
        .attr('width',200)
        .style('text-anchor', 'middle')
        .text( this.yAxisLabelY2)
        .style('font-weight', 'bold').style('font-size', '12px');
      }
             
    }  
    
    //======================================legend y2 axis=====================================
    console.log("y2LegendList==========>", this.y2LegendList);
    var legendY2 = svg.append("g")
      .attr("class", "legend")
      .attr("x", width)
      .attr("y", 35)
      .attr("height", 100)
      .attr("width", 100);
    if(this.y2LegendList.length > 3){
    legendY2.selectAll('circle')
      .data(this.y2LegendList)
      .enter().append("circle")
      .attr("cx", function (d, i) { return i * 150 + 210 })
      .attr("cy", -10)
      .attr("r", 5)
      .style("fill", function (d, i) {
        return colorY2[i];
      });
     }
      else{
        legendY2.selectAll('circle')
        .data(this.y2LegendList)
        .enter().append("circle")
        .attr("cx", function (d, i) { return i * 150 + 365 })
        .attr("cy", -10)
        .attr("r", 5)
        .style("fill", function (d, i) {
          return colorY2[i];
        });
      }
      if(this.y2LegendList.length > 3){

      this.y2LegendList.forEach((element, index) => {
        svg.selectAll('.legend').append("text").text(element.paramValue)
          .attr("x", function (d, i) { return index * 150 + 220; })
          .attr("y", -7)
          .style("font-size", "10px");

      });
      }else{
        this.y2LegendList.forEach((element, index) => {
          svg.selectAll('.legend').append("text").text(element.paramValue)
            .attr("x", function (d, i) { return index * 150 + 375; })
            .attr("y", -7)
            .style("font-size", "10px");

        });
      }
    //=================================================dual y axis-end=======================================

    // this.createImage();

  }

  exportToPDF() {
    let self = this;
    html2canvas(document.getElementById('lineGraph')).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');
      self.AddImageAddress(imgData);
      self.scatterSharedService.export(imgData, 'Line Graph');
    });
    // this.alertService.clear();
  }
  AddImageAddress(imageData) {
    this.lineGraphImage = imageData;
    console.log("line graph img data", this.lineGraphImage);
  }

  closeNoRecordPopup() {
    this.noRecordForLineGraph = false;
  }

  navigateToViewReport(sampleId) {
    var data = {
      columnName: 'sampleId',
      companyId: '',
      currentPage: '1',
      pageSize: '10',
      sampleId: sampleId,
      sortingOrder: 'desc',
      status: 'Published',
      userRole: this.userRole,
      //fluidType: 'Engine Oil (Liquid Fuel)'
      fluidType: this.getFluidTypeName()
    }

    if (this.userRole == USERTYPE.EXTERNAL) {
      data['userRole'] = USERS.EXTERNAL;
    }
    this.analyzeSampleService.getAnalyzeSampleDashboardData(data).subscribe(
      response => {
        if (response.statusCode == "1") {
          let sampleData = response.analyseSamplesList;
          if (sampleData.length > 0) {
            localStorage.setItem('viewReportData', JSON.stringify(sampleData[0]));
            //this.router.navigate(['/viewReport']);
            this.viewReport();
          }
        }
      }
    );
  }
  viewReport() {
    this.hideViewReport = false;
  }

  viewGraph() {
    this.hideViewReport = true;
  }


}
