import { Injectable } from '@angular/core';
import { ApiHandlerService } from '../../handlers/api-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageFleetService {

  constructor(private api: ApiHandlerService) { }

  selectedUnit:any;

  getSelectedUnit(){
    return this.selectedUnit;
  }

  setSelectedUnit(selectedUnit){
    this.selectedUnit = selectedUnit;
  }

  getManageFleetDashboardData(data: any) {
    return this.api.post(environment.apiUrl + '/getManageFleetDetails', data);
  }

  getShowMoreInfo(data: any) {
    return this.api.post(environment.apiUrl + '/getFluidAndFilterInfo', data);
  }

  deleteEquipment(data: any) {
    return this.api.post(environment.apiUrl + '/deleteEquipment ', data);
  }

  deleteComponent(data: any) {
    return this.api.post(environment.apiUrl + '/deleteComponent', data);
  }

  fetchEquipmentInfo(data: any) {
    return this.api.post(environment.apiUrl + '/fetchEquipmentInfo', data);
  }
}
