import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iamassert',
  templateUrl: './iamassert.component.html',
  styleUrls: ['./iamassert.component.css']
})
export class IamassertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
