import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  d = new Date();
  currentYear: any;
  constructor() { }

  ngOnInit() {
    this.currentYear = this.d.getFullYear();
  }

}
