import { ModalContainerComponent } from './../directives/modal-container/modal-container.component';
import { SortComponent } from './sort/sort.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ ModalContainerComponent],
  exports: [ ModalContainerComponent]
})
export class CommonModuleModule { }
