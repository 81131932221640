import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScatterPlotSharedService } from '../scatterplot/scatter-plot-grid/scatter-plot-shared.service';

@Component({
  selector: 'app-sample-dashboard-home',
  templateUrl: './sample-dashboard-home.component.html',
  styleUrls: ['./sample-dashboard-home.component.css']
})
export class SampleDashboardHomeComponent implements OnInit {

  constructor(private scatterSharedService: ScatterPlotSharedService,
    private router: Router) { }

  ngOnInit() {
  }

  scatterPlot(){
    this.scatterSharedService.setSelectedFluidType(null);
    this.scatterSharedService.setParameter(null);
    this.scatterSharedService.setDistanceType(null);
    this.scatterSharedService.setUnitIDs([]);
    this.router.navigate(['/scatterPlot']);
  }
}
