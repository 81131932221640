import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDigitDecimal]'
})
export class TwoDigitDecimalDirective {
   private threeDecimalRegex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
   private twoDecimalRegex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
   private oneDecimalRegex: RegExp = new RegExp(/^\d*\.?\d{0,1}$/g);
   private oneDecimalRegexforcloudpoint: RegExp = new RegExp(/^-?[0-9]\d*\.?\d{0,1}$/g);
   private noDecimalRegex: RegExp = new RegExp(/^\d*$/g);
   private regex: RegExp;

   @Input() noOfdecimal: string;
   @Input() noOfdecimalforcloudpoint: string;
  
   private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
 
   constructor(private el: ElementRef) {
   }
   @HostListener('keydown', ['$event'])
   onKeyDown(event: KeyboardEvent) {     
     // Allow Backspace, tab, end, and home keys
     if (this.specialKeys.indexOf(event.key) !== -1) {
       return;
     }
     let current: string = this.el.nativeElement.value;
     const position = this.el.nativeElement.selectionStart;
     const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
     if(this.noOfdecimal == '2'){
      this.regex = this.twoDecimalRegex;
     }else if(this.noOfdecimal == '1'){
      this.regex = this.oneDecimalRegex;
     }else if(this.noOfdecimal == '3'){
      this.regex = this.threeDecimalRegex;
     }else if(this.noOfdecimalforcloudpoint == '1'){
      this.regex = this.oneDecimalRegexforcloudpoint;
     }
     else{
      this.regex = this.noDecimalRegex;
     }
     if (next && !String(next).match(this.regex)) {
       event.preventDefault();
     }
   }

}

