import { RegisterSampleRequestModel } from './../../model/registerSampleRequestModel';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RegisterSampleService } from '../register-sample.service';
import { parallel } from 'async';
import { Subject } from 'rxjs';
import { SharedService } from '../shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class RegisterSampleListResolverService implements Resolve<any>{
  dbuCustomerNameDropdown: any;
  companyIdListArr: any[] = [];
  constructor(private router: Router, private registerSampleService: RegisterSampleService,
    private sharedService: SharedService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const req: RegisterSampleRequestModel = new RegisterSampleRequestModel();
    req.userRole = this.sharedService.getUserRole();
    req.pageSize = '10';
    req.columnName = 'sampleId';
    req.sortingOrder = 'DESC';
    req.currentPage = '1';
    
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    if (req.userRole === USERTYPE.EXTERNAL) {
      req.companyId = '';
      req.companyIdList = this.companyIdListArr;
      req.status = 'Available';
      req.userRole = this.sharedService.getIntOrExtUser();
    } else if (req.userRole === USERTYPE.LAB_ADMIN || req.userRole === USERTYPE.DATA_SCIENTIST || req.userRole === USERTYPE.FLUIDWATCH_MANAGER ) {
      req.companyId = '';
      // req.userRole = 'labAdmin';
      req.status = 'Submitted';
      req.userRole = this.sharedService.getIntOrExtUser();
    } else if(req.userRole === USERTYPE.DBU_BRANCH || req.userRole === USERTYPE.DBU_FLUIDS){
      if(localStorage.getItem("branchCustomerDropdown")){
        this.dbuCustomerNameDropdown = JSON.parse(localStorage.getItem("branchCustomerDropdown"));
        // if(this.dbuCustomerNameDropdown.customerId != 'notListed'){
          req.companyName = this.dbuCustomerNameDropdown.customerName;
          req.companyId = this.dbuCustomerNameDropdown.customerId;
        // } else {
        //   req.companyId = '';
        //   req.companyName = '';
        // }
      } else {
        req.companyId = '';
        req.companyName = '';
      }
      if(req.userRole === USERTYPE.DBU_BRANCH){
        req.userRole = 'dbuUser';
      } else {
        req.userRole = this.sharedService.getIntOrExtUser();
      }
      req.status = '';      
    }
    // req.userRole = this.sharedService.getIntOrExtUser();
    //console.log(req + 'this is resolver req');

    const observer = new Subject<any>();
    parallel(
      {
        registerSampleList: (rsl) => {
          this.registerSampleService.getSampleDashboardData(req).subscribe(res => {
            rsl(null, res);
          }, (error) => {
            rsl(error, null);
          });
        },

      },
      (err: string, results: any) => {
        if (err && err.indexOf('401 OK') !== -1) {
          this.router.navigate(['pages/login']);
          observer.next(results);
          observer.complete();
        }
        observer.next(results);
        observer.complete();
      });
    return observer.asObservable();
  }

}
