import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { AddPopulationService } from '../add-population/add-population.service';
import { PopulationGridService } from '../population-grid/population-grid.service';

@Component({
  selector: 'app-metadata-info',
  templateUrl: './metadata-info.component.html',
  styleUrls: ['./metadata-info.component.css']
})
export class MetadataInfoComponent implements OnInit,AfterViewChecked {

  selectedPopulation:any;
  distanceUOMList = [];
  sumpSizeList = [];
  cruiseControlList = [];
  yearsList = [];
  platformList = [];
  applicationList = [];
  dutyCycleList = [];
  oilCesStandardList = [];
  ecmModelList = [];
  fluidGradeList = [];
  scrollToTop = false;

  platformSearch = '';
  buildYearStartSearch = '';
  buildYearEndSearch = '';
  modelYearStartSearch = '';
  modelYearEndSearch = '';
  chassisModelYearStartSearch = '';
  chassisModelYearEndSearch = '';
  transmissionYearStartSearch = '';
  transmissionYearEndSearch = '';
  dutyCycleSearch = '';

  constructor(private sharedService: SharedService,
    private alertService: AlertService,
    private addPopulationService: AddPopulationService,
    private populationService: PopulationGridService) { }

  frm = new UntypedFormGroup({
    populationId: new UntypedFormControl('', [Validators.required]),
    populationName: new UntypedFormControl('', [Validators.required]),
    fluidType: new UntypedFormControl('', [Validators.required]),  
    fluidTypeName: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),    
    platform: new UntypedFormControl(''),
    buildYearStart: new UntypedFormControl(''),
    buildYearEnd: new UntypedFormControl(''),
    modelYearStart: new UntypedFormControl(''),
    modelYearEnd: new UntypedFormControl(''),
    ecmModel: new UntypedFormControl([]),
    chassisModelYearStart: new UntypedFormControl(''),
    chassisModelYearEnd: new UntypedFormControl(''),
    application: new UntypedFormControl([]),
    transmissionYearStart: new UntypedFormControl(''),
    transmissionYearEnd: new UntypedFormControl(''),
    chassisManufacturer: new UntypedFormControl('',[Validators.maxLength(500)]),
    chassisModel: new UntypedFormControl('',[Validators.maxLength(500)]),
    transmissionMake: new UntypedFormControl('',[Validators.maxLength(500)]),
    transmissionModel: new UntypedFormControl('',[Validators.maxLength(500)]),
    dutyCycle: new UntypedFormControl(''),
    sumpSize: new UntypedFormControl('',[Validators.max(999.99)]),
    sumpUOM: new UntypedFormControl(''),
    frOption: new UntypedFormControl('',[Validators.maxLength(9)]),
    horsePowerRating: new UntypedFormControl('',[Validators.maxLength(9)]),      
    peakTorque: new UntypedFormControl('',[Validators.maxLength(9)]),
    rpm: new UntypedFormControl('',[Validators.maxLength(9)]),
    idleBottomValue: new UntypedFormControl('',[Validators.max(99.99)]),
    idleTopValue: new UntypedFormControl('',[Validators.max(99.99)]),
    fuelEconomyBottomValue: new UntypedFormControl('',[Validators.max(99.99)]),
    fuelEconomyTopValue: new UntypedFormControl('',[Validators.max(99.99)]),
    cruiseControl: new UntypedFormControl(''),
    rearAxleRatioStart: new UntypedFormControl('',[Validators.max(99.99)]),
    rearAxleRatioEnd: new UntypedFormControl('',[Validators.max(99.99)]),
    oilCesStandard:new UntypedFormControl([]),
    fluidGradeRange: new UntypedFormControl(''),
    engineDistanceUOM: new UntypedFormControl(''),
    engineDistanceBottom: new UntypedFormControl('',[Validators.maxLength(9)]),
    engineDistanceTop: new UntypedFormControl('',[Validators.maxLength(9)]),
    additionalNotes: new UntypedFormControl('',[Validators.maxLength(500)]),
    userId: new UntypedFormControl(''),    
    populationDataList: new UntypedFormControl([])
  });

  platformData = [];
  dutyCycleData = [];
  yearDropdown={
    buildYearStart:[],
    buildYearEnd:[],
    modelYearStart:[],
    modelYearEnd:[],
    chassisModelYearStart:[],
    chassisModelYearEnd:[],
    transmissionYearStart:[],
    transmissionYearEnd:[]
  };

  ngOnInit() {
    this.selectedPopulation = this.populationService.getSelectedPopulation();
    forkJoin([
      this.getMetaDataDropdownData(),
      this.getPopulationMetadata()    
    ]).subscribe(allResults => {
      this.setMetaDataDropdownData(allResults[0]),
      this.setPopulationMetadata(allResults[1])     
    });
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }


  getPopulationMetadata():Observable<any>{
    let inputData = {
      'populationId': this.selectedPopulation.populationId
    };
    return this.addPopulationService.getPopulationMetadata(inputData)
  }

  setPopulationMetadata(response){
    if (response.statusCode == "1") {
      this.frm.patchValue({
        populationId: this.selectedPopulation.populationId,
        populationName: this.selectedPopulation.populationName,
        fluidType: this.selectedPopulation.fluidTypeId, 
        fluidTypeName: this.selectedPopulation.fluidType, 
        platform: response.metadata.platform,
        buildYearStart: +response.metadata.buildYearStart,
        buildYearEnd: +response.metadata.buildYearEnd,
        modelYearStart: +response.metadata.modelYearStart,
        modelYearEnd: +response.metadata.modelYearEnd,
        ecmModel: response.metadata.ecmModel,
        chassisModelYearStart: +response.metadata.chassisModelYearStart,
        chassisModelYearEnd: +response.metadata.chassisModelYearEnd,
        application: response.metadata.application,
        transmissionYearStart: +response.metadata.transmissionYearStart,
        transmissionYearEnd: +response.metadata.transmissionYearEnd,
        chassisManufacturer: response.metadata.chassisManufacturer,
        chassisModel: response.metadata.chassisModel,
        transmissionMake: response.metadata.transmissionMake,
        transmissionModel: response.metadata.transmissionModel,
        dutyCycle: response.metadata.dutyCycle,
        sumpSize: response.metadata.sumpSize,
        sumpUOM: response.metadata.sumpUOM,
        frOption: response.metadata.frOption,
        horsePowerRating: response.metadata.horsePowerRating,      
        peakTorque: response.metadata.peakTorque,
        rpm: response.metadata.rpm,
        idleBottomValue: response.metadata.idleBottomValue,
        idleTopValue: response.metadata.idleTopValue,
        fuelEconomyBottomValue: response.metadata.fuelEconomyBottomValue,
        fuelEconomyTopValue: response.metadata.fuelEconomyTopValue,
        cruiseControl: response.metadata.cruiseControl,
        rearAxleRatioStart: response.metadata.rearAxleRatioStart,
        rearAxleRatioEnd: response.metadata.rearAxleRatioEnd,
        oilCesStandard: response.metadata.oilCesStandard,
        fluidGradeRange: response.metadata.fluidGradeRange,
        engineDistanceUOM: response.metadata.engineDistanceUOM,
        engineDistanceBottom: response.metadata.engineDistanceBottom,
        engineDistanceTop: response.metadata.engineDistanceTop,
        additionalNotes: response.metadata.additionalNotes,
        userId: this.sharedService.getUserId()
      });

    } else {
      this.alertService.error(response.statusMessage);
    }
  }

  getMetaDataDropdownData():Observable<any>{
    let inputData = {      
      'fluidType': this.selectedPopulation.fluidTypeId      
    };
    return this.addPopulationService.getMetaDataDropdownData(inputData);
  }

  setMetaDataDropdownData(response){
    if (response.statusCode == "1") {
      this.distanceUOMList = response.distanceUOMList;
      this.sumpSizeList = response.sumpSizeList;
      this.cruiseControlList = response.cruiseControlList;
      this.yearsList = response.yearsList;
      this.platformList = response.platformList;
      this.applicationList = response.applicationList;
      this.dutyCycleList = response.dutyCycleList;
      this.oilCesStandardList = response.oilCesStandardList;
      this.ecmModelList = response.ecmModelList;
      this.fluidGradeList = response.fluidGradeList;

      Object.keys(this.yearDropdown).forEach(field => {
        this.yearSearchData('',field);
      });
      this.platformSearchData('');
      this.dutycycleSearchData('');      
    } else {
      this.alertService.error(response.statusMessage);
    }
  }

  submitData() {
    let inputData = this.frm.getRawValue(); 
    inputData['application'] = inputData['application'].toString();
    inputData['ecmModel'] = inputData['ecmModel'].toString();
    inputData['oilCesStandard'] = inputData['oilCesStandard'].toString();

    this.addPopulationService.updatePopulationMetadata(inputData).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.alertService.success(response.statusMessage);          
        } else {
          this.alertService.error(response.statusMessage);          
        }
        this.scrollToTop = true;
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  sumpSizeChange(){
    let sumpSize = this.frm.controls.sumpSize.value;
    let sumpUOM = this.frm.controls.sumpUOM.value;
    if(sumpSize.length > 0 || sumpUOM.length > 0){
      this.frm.controls['sumpSize'].setValidators([Validators.required, Validators.max(999.99)]);
      this.frm.controls['sumpUOM'].setValidators([Validators.required]);
    }else{
      this.frm.controls['sumpSize'].clearValidators();
      this.frm.controls['sumpUOM'].clearValidators();
    }
    this.frm.controls['sumpSize'].updateValueAndValidity();
    this.frm.controls['sumpUOM'].updateValueAndValidity();
  }

  peakTorqueChange(){    
    let peakTorque = this.frm.controls.peakTorque.value;
    let rpm = this.frm.controls.rpm.value;
    if(peakTorque.length > 0 || rpm.length > 0){
      this.frm.controls['peakTorque'].setValidators([Validators.required, Validators.maxLength(9)]);
      this.frm.controls['rpm'].setValidators([Validators.required, Validators.maxLength(9)]);
    }else{
      this.frm.controls['peakTorque'].clearValidators();
      this.frm.controls['rpm'].clearValidators();
    }
    this.frm.controls['peakTorque'].updateValueAndValidity();
    this.frm.controls['rpm'].updateValueAndValidity();
  }

  yearSearchData(value, field){
    let filter = value.toLowerCase();
    this.yearDropdown[field] = [];
    this.yearsList.forEach(row => {
      if (row.toString().indexOf(filter) >= 0) {
        this.yearDropdown[field].push(row);
      }
    });
    if(this.yearDropdown[field].length == 0){
      this.yearDropdown[field].push(0);
    }
  }

  clearYearData(field){
    this.yearDropdown[field] = [];
    this.yearsList.forEach(row => {
      this.yearDropdown[field].push(row);
    });
  }

  platformSearchData(value){
    let filter = value.toLowerCase();
    this.platformData = [];
    this.platformList.forEach(row => {
      if (row.platformName.toLowerCase().indexOf(filter) >= 0) {
        this.platformData.push(row);
      }
    });
    if(this.platformData.length == 0){
      this.platformData.push({"platform":"","platformName":"Select"});
    }
  }

  clearPlatformData(){
    this.platformData = [];
    this.platformList.forEach(row => {
      this.platformData.push(row);
    });
  }

  dutycycleSearchData(value){
    let filter = value.toLowerCase();
    this.dutyCycleData = [];
    this.dutyCycleList.forEach(row => {
      if (row.dutyCycleName.toLowerCase().indexOf(filter) >= 0) {
        this.dutyCycleData.push(row);
      }
    });
    if(this.dutyCycleData.length == 0){
      this.dutyCycleData.push({"dutyCycle":"","dutyCycleName":"Select"});
    }
  }

  clearDutycycleData(){
    this.dutyCycleData = [];
    this.dutyCycleList.forEach(row => {
      this.dutyCycleData.push(row);
    });
  }

}
