import { AfterViewChecked, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { FleetUnitIdCountReportService } from './fleet-unit-id-count-report.service';
import { TsmaeReportsService } from '../tsm-ae-report/tsmae-reports.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { Router } from '@angular/router';
import { MatOption } from '@angular/material';

@Component({
  selector: 'app-fleet-unit-id-count-report',
  templateUrl: './fleet-unit-id-count-report.component.html',
  styleUrls: ['./fleet-unit-id-count-report.component.css']
})
export class FleetUnitIdCountReportComponent implements OnInit, AfterViewChecked {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChildren(SortComponent) sortComponents;

  readonly USERTYPE = USERTYPE;
  userRole: string = '';  
  isSearchDone: boolean = false;  
  reportData: any = [];
  reportCountData: any = [];
  companyArray= []; 
  companyList : any[];

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  scrollToTop: any;
  searchObj: any;

  filterObject = {
    unitId: [],
    unitMake: [],
    unitModel: [],
    unitYear: [],
    esn: [],
    engineMake: [],
    engineModel: [],
    engineYear: [],
    lastSampledDate: [],
    lastSampleUnitDistance: [],
    lastSampleFluidDistance: [],
    dateUnitCreated: []
  };

  reportCols = [
    { code: 'unitId', value: 'Unit ID' },
    { code: 'unitMake', value: 'Unit Make' },
    { code: 'unitModel', value: 'Unit Model' },
    { code: 'unitYear', value: 'Unit Year' },
    { code: 'esn', value: 'ESN' },
    { code: 'engineMake', value: 'Engine Make' },
    { code: 'engineModel', value: 'Engine Model' },
    { code: 'engineYear', value: 'Engine Year' },
    { code: 'lastSampledDate', value: 'Last Sampled Date' },
    { code: 'lastSampleUnitDistance', value: 'Last Sample Unit Distance' },
    { code: 'lastSampleFluidDistance', value: 'Last Sample Fluid Distance' },
    { code: 'dateUnitCreated', value: 'Date Unit Created' }
  ];

  inputForm = new UntypedFormGroup({
    companyId: new UntypedFormControl('', [Validators.required]),
    companyName: new UntypedFormControl(''),
    companyIdFilter: new UntypedFormControl('')
  });

  searchForm = new UntypedFormGroup({
    unitId: new UntypedFormControl(''),
    unitMake: new UntypedFormControl(''),
    unitModel: new UntypedFormControl(''),
    unitYear: new UntypedFormControl(''),
    esn: new UntypedFormControl(''),
    engineMake: new UntypedFormControl(''),
    engineModel: new UntypedFormControl(''),
    engineYear: new UntypedFormControl(''),
    lastSampledDate: new UntypedFormControl(''),
    lastSampleUnitDistance: new UntypedFormControl(''),
    lastSampleFluidDistance: new UntypedFormControl(''),
    dateUnitCreated: new UntypedFormControl('')
  });

  /*--------TSM CompanyName dropdown---------*/
  companyIdTSM: any = '';
  companyNameTSM: any = '';
  tsmCustomerNameDropdown: any;
  isFirstTime: boolean = true;
  messageDropdownTsm: any;
  isSearchDisable: boolean = false;
  isFirstTimeDbu: boolean = true;
  messageDropdownDbu: any;
  behaviorObsTSM: any;
  behaviorObs: any;
  companyListNewArr: any[]=[];
  companyIdListArr: any[] = [];
  companyListExternal: any[] = [];
  companyListExtForChecked: any[];
  constructor(private commonHandler: CommonHandlerService,
    private alertService: AlertService,
    private tsmAEReportsService: TsmaeReportsService,
    private reportService: FleetUnitIdCountReportService,
    private sharedService: SharedService,
    private router: Router) { }

  ngOnInit() {
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    this.userRole = this.sharedService.getUserRole();    
    this.columnName = 'unitId';
    this.sortingOrder = 'asc';
    if(this.userRole === USERTYPE.EXTERNAL){
      this.inputForm.controls['companyId'].disable();           
    }
    if(this.userRole == USERTYPE.TSM || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
      this.isSearchDisable = true;
    }
    this.getInitialData();

    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message=>{
      if(this.userRole == USERTYPE.TSM && this.isFirstTime == false){
        if(this.router.url == '/fleetUnitIdCountReport'){      
          this.messageDropdownTsm = message;
            this.recieveMessageTSM(this.messageDropdownTsm);
        }
      }
      this.isFirstTime = false;
    });

    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if((this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) && this.isFirstTimeDbu == false){
        if(this.router.url == '/fleetUnitIdCountReport'){      
          this.messageDropdownDbu = message;
            this.recieveMessageTSM(this.messageDropdownDbu);
        }
      }
      this.isFirstTimeDbu = false;
    });
  }

  ngOnDestroy () {
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
  }

  recieveMessageTSM(event){
    this.alertService.clear();
    this.inputForm.patchValue({
      companyId: '',
    });
    this.tsmCustomerNameDropdown = event;
    this.companyNameTSM = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
    if(this.companyIdTSM == ''){
      this.isSearchDisable = true;
      this.alertService.error("Plese select Company Name")
    } else {
      this.companyList.forEach(element => {
        if (element.companyId == this.companyIdTSM && element.companyName == this.companyNameTSM) {
          this.inputForm.patchValue({
            companyId: this.companyIdTSM
          });
          this.isSearchDisable =  false;
        }
      });
      if(this.inputForm.controls['companyId'].value == '') {
        this.isSearchDisable = true;
        this.alertService.error("Selected company has no reports")
      }
    }
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getInitialData(){
    this.tsmAEReportsService.getInitialData().subscribe(response=> {
      if (response.statusCode == "1") {        
        this.companyList = response.companyList;
        this.companyList.sort(function(a, b) {
          const nameA = a.companyName.toUpperCase();
          const nameB = b.companyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if(this.userRole == USERTYPE.TSM){
          this.inputForm.controls['companyId'].disable();
          if(localStorage.getItem("customerDropdownTSM")){
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
          }
          if(this.companyIdTSM == ''){
            this.alertService.error('Please select Company Name');
          }
        } else if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
          this.inputForm.controls['companyId'].disable();
          if(localStorage.getItem("branchCustomerDropdown")){
            this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
          }
          if(this.companyIdTSM == ''){
            this.alertService.error('Please select Company Name');
          }
        }
        this.companySearch('');        
      }else{
        this.alertService.error(response.statusMessage);
      }
    },err=>{
      this.alertService.error(err);
    });
    /*this.companyList = [{'companyId':'C000927434', 'companyName': 'Company 1'},
    {'companyId':'C123457', 'companyName': 'Company 2'},
    {'companyId':'C123458', 'companyName': 'Company 3'},
    {'companyId':'C123459', 'companyName': 'Company 4'},
    {'companyId':'C123450', 'companyName': 'Company 5'}];*/

    this.companyArray = this.companyList;
  }

  search(){    
    this.currentPage = 1;
    this.searchForm.patchValue({
      unitId: '',
      unitMake: '',
      unitModel: '',
      unitYear: '',
      esn: '',
      engineMake: '',
      engineModel: '',
      engineYear: ''
    });
    this.getReportData();
  }

  optionSearch(){
    this.currentPage = 1;
    this.getReportData();
  }

  getReportData() {
    let inputData = this.searchForm.getRawValue();
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);

    inputData['companyId'] = this.inputForm.controls.companyId.value;   
    inputData['companyName'] = this.getCompanyName();
    this.reportService.getFleetUnitIdCountData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.reportData = response.reportList;
        this.reportCountData = response.reportCountList;
        this.totalItems = response.maxRow;
        this.calculateOffset();       
        this.isSearchDone = true;
      } else {
        this.reportData = [];
        this.reportCountData = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );

    //mock data   
    /*this.reportCountData = [{ 'unitId': '6', 'unitMake': '5', 'unitModel': '5', 'unitYear': '2', 'esn': '2', 'engineMake': '6', 'engineModel': '6', 'engineYear': '6', 'lastSampledDate': '6', 'lastSampleUnitDistance': '', 'lastSampleFluidDistance': '', 'dateUnitCreated': '' }];
    this.reportData = [{ 'unitId': 'Unit1', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' },
    { 'unitId': 'Unit2', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' },
    { 'unitId': 'Unit3', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' },
    { 'unitId': 'Unit4', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' },
    { 'unitId': 'Unit5', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' },
    { 'unitId': 'Unit6', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' },
    { 'unitId': 'Unit7', 'unitMake': 'Make1', 'unitModel': 'Model1', 'unitYear': '2021', 'esn': 'ESN1', 'engineMake': 'Make1', 'engineModel': 'Model1', 'engineYear': '2021', 'lastSampledDate': '01/01/2021', 'lastSampleUnitDistance': '160000', 'lastSampleFluidDistance': '320000', 'dateUnitCreated': '01/01/2021' }];

    this.isSearchDone = true;
    this.totalItems = 5;
    this.calculateOffset();*/
  }

  searchFilter(str) {
    let inputData = this.searchForm.getRawValue();
    inputData['searchColumn'] = str;
    inputData['searchValue'] = this.searchForm.value[str];

    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);

    inputData['companyId'] = this.inputForm.controls.companyId.value;    

    if(inputData['searchValue'].length >= 2){
      this.reportService.getFleetUnitIdCountData(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.filterObject[str] = response.distinctValuesList;
        } else {
          this.filterObject[str] = [];
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    }    
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.getReportData();
    }
  }
  exportToExcel() {
    let inputData = this.searchForm.getRawValue();
    this.searchObj = {
      pageSize: 50000,
      currentPage: 1,
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);
    inputData['companyId'] = this.inputForm.controls.companyId.value;    
    inputData['companyName'] = this.getCompanyName();
    inputData['action'] = "EMAIL";
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;  
    inputData['userName'] = this.sharedService.getUserInformation().firstName + " " +
      this.sharedService.getUserInformation().lastName ;    

    this.reportService.getFleetUnitIdCountData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.alertService.success(response.statusMessage);
        this.scrollToTop = true;
      } else {        
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  clearInput(){
    this.inputForm.reset();    
    this.isSearchDone = false;
    if(this.userRole === USERTYPE.TSM){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")));
    } else if(this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("branchCustomerDropdown")));
    }
  }

  companySearch(value:string){
    this.companyArray= []; 
    let filter = value.toLowerCase();
    if (this.userRole == USERTYPE.EXTERNAL) {
      this. companyListNewArr = [];
      this.companyListExternal = this.companyIdListArr;
      for(let i=0;i<this.companyList.length; i++){
        this.companyListExternal.filter(row => {
          if(row == this.companyList[i].companyId){
            this.companyListNewArr.push([{companyId:this.companyList[i].companyId.toUpperCase(),companyName:this.companyList[i].companyName.toUpperCase()}]);
          }
        });                 
      };
      const convertToArray = [].concat(...this.companyListNewArr);      
      this.companyArray = convertToArray;
      let emptyArr = [];
        this.companyArray.forEach(row => {
          emptyArr.push(row.companyId.toUpperCase());
        });    
        this.inputForm.patchValue({
          companyId: emptyArr
        });    
        
    }
    this.companyArray = this.companyList.filter((row) => {
      let companyId = row.companyId.toLowerCase();
      let companyName = row.companyName.toLowerCase();
      if(companyId.indexOf(filter) >= 0 || companyName.indexOf(filter) >= 0){
        return true;
      }else{
        return false;
      }
    });

    if(this.companyArray.length == 0){
      this.companyArray.push({'companyName':'Select', 'companyId': ''});
    }
  }

  clearCompanySearch(){
    this.companyArray= []; 
    this.companyArray= this.companyList;
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.reportData = [];
    this.reportCountData = [];
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.reportData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        // this.scrollToTop = true;
      }
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.getReportData();
  }

  /**
* Calls search method to sort the data based on columnName
* @param columnName 
*/
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';

    this.getReportData();
  }

  isSearchable(column) {
    if(column == 'unitId' || column == 'unitMake' || column == 'unitModel' || 
      column == 'unitYear' || column == 'esn' || column == 'engineMake' || column == 'engineModel' ||
      column == 'engineYear'){
      return true;
    }else{
      return false;
    }
  }

getCompanyName():any{
    let companyNameArr=[];
    let companyName;
    let selectedcompanyDetails= this.inputForm.controls.companyId.value
    for (let i = 0; i < selectedcompanyDetails.length; i++) {
      for (let j = 0; j < this.companyList.length; j++){
      if(this.companyList[j].companyId == selectedcompanyDetails[i]){
        companyName =  this.companyList[j].companyName;
        companyNameArr.push(companyName.toUpperCase())
     }
    }
    }
    return companyNameArr;
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.inputForm.controls.companyId
      .patchValue([...this.companyList.map(item => item.companyId), 0]);
      this.inputForm.controls.companyName
      .patchValue([...this.companyList.map(item => item.companyName), 0]);

    } else {
      this.inputForm.controls.companyId.patchValue([]);
      this.inputForm.controls.companyName.patchValue([]);
    }
  }
}
