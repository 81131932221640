import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LineGraphHomeService } from '../../linegraph/line-graph-home/line-graph-home.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { ScatterPlotSharedService } from '../scatter-plot-grid/scatter-plot-shared.service';
import { ScatterPlotGraphComponent } from '../scatter-plot-graph/scatter-plot-graph.component';
import html2canvas from 'html2canvas';
import { LoaderService } from 'src/app/View/common/preloader/loader.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE, USERS } from 'src/app/View/common/constants/constant';

@Component({
  selector: 'app-scatter-plot',
  templateUrl: './scatter-plot.component.html',
  styleUrls: ['./scatter-plot.component.css']
})
export class ScatterPlotComponent implements OnInit,AfterViewChecked {
  unitIds : any[]=[]
  parameterListData :any[];
  searchObj : any;
  distanceType: any = 'fluidDistance';  
  selectedParameter: any = null;
  checked = false;
  indeterminate = false;  
  disableExportToPDF = true;
  scrollToTop = false;
  hideViewReport = true;
  fluidTypeList: any[] = [];
  selectedFluidType:any;
  userRole: string = '';
  companyIdTSM: any = '';
  tsmCustomerNameDropdown: any;
  messageDropdownTsm: any;
  isFirstTime: boolean = true;
  companyIdDBU: any = '';
  dbuCustomerNameDropdown: any;
  messageDropdownDbu: any;

  
  @ViewChild(ScatterPlotGraphComponent, { static: true }) graphComp: ScatterPlotGraphComponent;  
  behaviorObs: any;
  behaviorObsTSM: any;
  constructor(private router: Router,
    private linegraphhome: LineGraphHomeService , private loader: LoaderService,
    private alertService: AlertService,private sharedScatterService : ScatterPlotSharedService,
    private  scatterSharedService: ScatterPlotSharedService,
    private sharedService : SharedService) { }

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();
    if(localStorage.getItem("customerDropdownTSM") && (this.userRole == USERTYPE.TSM)){
      this.recieveMessageTSM(JSON.parse(localStorage.getItem("customerDropdownTSM")))      
    } else if(localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_FLUIDS)){
      this.recieveMessageDBU(JSON.parse(localStorage.getItem("branchCustomerDropdown")))      
    }

    this.unitIds = Object.assign([], this.sharedScatterService.getUnitIds());
    if(this.scatterSharedService.getDistanceType() != null){
      this.distanceType = this.scatterSharedService.getDistanceType();
    }
    //this.getScatterGraphData();
    this.getFluidType();
    this.setData();

    this.behaviorObsTSM = this.sharedService.currentChangeTSM.subscribe(message=>{
      if(this.userRole == USERTYPE.TSM){
        if(this.router.url == '/scatterPlot'){      
          this.messageDropdownTsm = message;
          if(this.isFirstTime == false){
            this.recieveMessageTSM(this.messageDropdownTsm);
            this.reset();
          }
          this.isFirstTime = false;
        }
      }
    });

    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if(this.userRole == USERTYPE.DBU_FLUIDS){
        if(this.router.url == '/scatterPlot'){      
          this.messageDropdownDbu = message;
          if(this.isFirstTime == false){
            this.recieveMessageDBU(this.messageDropdownDbu);
            this.reset();
          }
          this.isFirstTime = false;
        }
      }
    });
  }

  ngOnDestroy () {
    this.behaviorObs.unsubscribe();
    this.behaviorObsTSM.unsubscribe();
  }

  recieveMessageTSM(event){
    this.tsmCustomerNameDropdown = event;
    // companyName = this.tsmCustomerNameDropdown.customerName;
    this.companyIdTSM = this.tsmCustomerNameDropdown.customerId;
  }

  recieveMessageDBU(event){
    this.dbuCustomerNameDropdown = event;
    this.companyIdDBU = this.dbuCustomerNameDropdown.customerId;
  }

  setData(){
    this.parameterListData = this.scatterSharedService.getParameterListData();
    this.parameterListData.forEach(element=> {​​​​​​
      let i= this.parameterListData.indexOf(element);
      if(element.parameterName==="Chloride"){​​​​​​
      this.parameterListData[i].parameterDesc ="Cl\u207B";
              }​​​​​​
      if(element.parameterName==="Fluoride"){​​​​​​
        this.parameterListData[i].parameterDesc  ="F\u207B";
              }​​​​​​
      else if(element.parameterName==="Bromide"){​​​​​​
        this.parameterListData[i].parameterDesc ="Br\u207B";
              }​​​​​​
      else if(element.parameterName==="Sulfate"){​​​​​​
        this.parameterListData[i].parameterDesc="SO\u2084\u00B2\u207B";
              }​​​​​​
      else if(element.parameterName==="Glycolate"){
        this.parameterListData[i].parameterDesc ="C"+'\u2082'+'H'+'\u2083'+'O'+'\u2083'+'\u207B'+"";
      }
      else if(element.parameterName==="Formate"){
        this.parameterListData[i].parameterDesc ="COO"+'\u207B'+"";

      } else if(element.parameterName==="Molybdate"){
        this.parameterListData[i].parameterDesc ="MoO4"+'\u2082'+'\u207B'+"";

      }
      else if(element.parameterName==="Nitrate"){
        this.parameterListData[i].parameterDesc ="NO"+'\u2083'+'\u207B'+"";

      }
      else if(element.parameterName==="Nitrite"){
        this.parameterListData[i].parameterDesc ="NO"+'\u2082'+'\u207B'+"";
      }   
      else if(element.parameterName==="Oxalate"){
        this.parameterListData[i].parameterDesc ="C"+'\u2082'+'O'+'\u2084'+'\u00B2'+'\u207B'+"";

      }
      else if(element.parameterName==="Phosphate"){
        this.parameterListData[i].parameterDesc  ="PO"+'\u2084'+'\u00B3'+'\u207B'+"";

      }
      else if(element.parameterName==="PToluicAcid"){
        this.parameterListData[i].parameterName ="p-TA";

      }
    })   
    this.selectedParameter = this.scatterSharedService.getParameter();
    this.selectedFluidType = this.scatterSharedService.getSelectedFluidType();

    if(this.scatterSharedService.getParameter() != null){      
      this.parameterListData.forEach((row)=>{
        if(this.selectedParameter == row.parameterName){
          row.checked = true;
        }
      });            
    }    

    if(this.selectedParameter != null && this.scatterSharedService.getDistanceType() != null &&
        this.unitIds.length > 0 && this.sharedScatterService.getShowScatterPlot() == true &&
        this.selectedFluidType != null){
        this.showGraph();
    }
  }

  fluidTypeChange(){
    if(this.userRole == USERTYPE.TSM && this.companyIdTSM == ''){
      this.alertService.error("Please select Company Name");
    } else if(this.userRole == USERTYPE.DBU_FLUIDS && this.companyIdDBU == ''){
      this.alertService.error("Please select Company Name");
    } else {
      this.getScatterGraphData();
      this.unitIds = [];
      this.parameterListData.forEach((row) =>{
        row.checked = false;
      });
      this.distanceType = 'fluidDistance';
      this.graphComp.resetGraph();
      this.sharedScatterService.resetUnitIds();
      this.sharedScatterService.setParameter(null);
      this.sharedScatterService.setDistanceType(null);
      this.sharedScatterService.setShowScatterPlot(false);
      this.selectedParameter = null;    
      this.disableExportToPDF = true;
      this.alertService.clear();
    }
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  onParameterChange(item){
    this.selectedParameter = item.parameterName; 
    this.parameterListData.forEach((row)=>{
      if(item.parameter == row.parameter){
        row.checked = true;
      }else{
        row.checked = false;
      }
    });
    this.sharedScatterService.setParameter(this.selectedParameter);
  }

  onUnitIdChange(unit){
    this.unitIds.forEach((row)=>{
      if(unit.equipId == row.equipId){
        if(row.isChecked){
          row.isChecked = false;
        }else{
          row.isChecked = true;
        }
      }
    });
  }

  onDistanceTypeChange(){    
    this.sharedScatterService.setDistanceType(this.distanceType);
  }

  showGraph(){    
    //1. unit size should be greater than 0  
    let selectedUnitIds = this.unitIds.filter((row)=>{
      if(row.isChecked){
        return row;
      }      
    }); 
    if(this.selectedFluidType == undefined || this.selectedFluidType == null){
      this.alertService.error('Please select Fluid Type to generate a graph');
      this.scrollToTop = true;
      return false;
    }    
    if(selectedUnitIds == undefined || selectedUnitIds.length < 1){
      this.alertService.error('Please select unit to generate a graph');
      this.scrollToTop = true;
      return false;
    }    

    //2. atleast one parameter should be selected
    let selectedParameter = this.parameterListData.find(function(e){
      return e.checked == true;
    });
    if(selectedParameter == undefined || !selectedParameter.hasOwnProperty('parameterName')){
      this.alertService.error('Please select a parameter to generate a graph');
      this.scrollToTop = true;
      return false;
    }

    //3. uom should be either in hrs or km/miles
    let uom = selectedUnitIds[0].distanceUOM;
    let isUOMInvalid = false;
    selectedUnitIds.forEach((item)=>{
      if(uom == 'HOURS'){
        if(item.distanceUOM != 'HOURS'){
          isUOMInvalid = true;
        }
      }else{
        if(item.distanceUOM != 'MILES' && item.distanceUOM != 'KILOMETERS'){
          isUOMInvalid = true;
        }
      }
    });
    if(isUOMInvalid){
      this.alertService.error('Please select all the units which have uom in Miles/Kilometer combination or in Hours');
      this.scrollToTop = true;
      return false;
    }
    
    //select fuild or unit distance
    if(this.distanceType == undefined || this.distanceType == null){
      this.alertService.error('Please select a distance type');
      this.scrollToTop = true;
      return false;
    }
    let inputData={
      "selectedParameter" : selectedParameter,
      "unitIds" : selectedUnitIds,
      "distanceType": this.distanceType,
      "uom": uom,
      "allUnits" : this.unitIds,
      "fluidType": this.selectedFluidType,
      "fluidTypeName": this.getFluidTypeName()
    };
    this.disableExportToPDF = false;
    this.sharedScatterService.setDistanceType(this.distanceType);
    this.sharedScatterService.setShowScatterPlot(true);
    this.alertService.clear();
    this.graphComp.showGraph(inputData);
  }

  reset(){
    this.unitIds = [];
    this.parameterListData.forEach((row) =>{
      row.checked = false;
    });
    this.distanceType = 'fluidDistance';
    this.graphComp.resetGraph();
    this.sharedScatterService.resetUnitIds();
    this.sharedScatterService.setParameter(null);
    this.sharedScatterService.setDistanceType(null);
    this.sharedScatterService.setShowScatterPlot(false);
    this.selectedParameter = null;
    this.selectedFluidType = null;
    this.disableExportToPDF = true;
    this.parameterListData = [];
    this.alertService.clear();
  }

  selectUnit(){
    if(this.userRole == USERTYPE.TSM && this.companyIdTSM == ''){
      this.alertService.error("Please select Company Name")
    } else if(this.userRole == USERTYPE.DBU_FLUIDS && this.companyIdDBU == ''){
      this.alertService.error("Please select Company Name")
    } else {
      if(this.selectedFluidType == null || this.selectedFluidType == undefined){
        this.alertService.error('Kindly select a Fluid Type');
        this.scrollToTop = true;
      }else{
        this.scatterSharedService.setParameterListData(this.parameterListData); 
        this.scatterSharedService.setSelectedFluidType(this.selectedFluidType);
        this.scatterSharedService.setFluidTypeName(this.getFluidTypeName());      
        this.router.navigate(['/scatterPlotGrid']);
      }
    }
  }

  getScatterGraphData(){
    if(this.userRole == USERTYPE.TSM){
      this.searchObj = {
        "companyId": this.companyIdTSM,
        "fluidType": this.selectedFluidType,
        "fluidTypeName": this.getFluidTypeName()
      }
    } else if(this.userRole == USERTYPE.DBU_FLUIDS){
      this.searchObj = {
        "companyId": this.companyIdDBU,
        "fluidType": this.selectedFluidType,
        "fluidTypeName": this.getFluidTypeName()
      }
    } else {
      this.searchObj = {
        "companyId": this.sharedService.getCompanyId(),
        "fluidType": this.selectedFluidType,
        "fluidTypeName": this.getFluidTypeName()
      }
    }    

    this.linegraphhome.getLineGraphDataLoad(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {          
          this.parameterListData = response.parametersList;
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }  

  getFluidType(){
    this.linegraphhome.getFluidType().subscribe(
      response => {
        if (response.statusCode == "1") {
          this.fluidTypeList = response.fluidTypeList;  
          this.fluidTypeList = this.fluidTypeList.filter(row=>{
            return row.fluidType == 1 || row.fluidType == 2 || row.fluidType == 3 || row.fluidType == 4 || row.fluidType == 5 || row.fluidType == 6
          });         
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
      }
    );
  }

  getFluidTypeName(){
    let fluidTypeName = '';
    this.fluidTypeList.forEach(row => {
      if(row.fluidType == this.selectedFluidType){
        fluidTypeName = row.fluidTypeName;
      }
    });

    return fluidTypeName;
  }

  noRecordAlert(){
    this.alertService.warn('No Record present');
    this.scrollToTop = true;
    this.disableExportToPDF = true;
  }

  viewReport(){
    this.hideViewReport = false;
  }

  viewGraph(){
    this.hideViewReport = true;
  }

  exportAsPDF(){
    this.loader.show();
    let self = this;
    html2canvas(document.getElementById('scatterPlotExport')).then(function (canvas) {
      const imageData = canvas.toDataURL('image/png');   
      self.scatterSharedService.export(imageData, 'BYO Scatter Plot Graph');
      self.loader.hide();
    });
    this.alertService.clear();
  }

  onParameterSelect(){
    if(this.selectedFluidType == null || this.selectedFluidType == undefined){
      this.alertService.error('Please select Fluid Type to generate a graph');
      this.scrollToTop = true;
    }
  }
}
