import { AfterViewChecked, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ManageFleetService } from './manage-fleet.service';
import { AlertService } from '../../common/alert/alert.service';
import { SharedService } from '../../service/shared.service';
import { CommonHandlerService } from '../../handlers/common-handler.service';
import { common, warnMessage } from '../../common/constants/constant';
import { SortComponent } from '../../common/sort/sort.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-manage-fleet',
  templateUrl: './manage-fleet.component.html',
  styleUrls: ['./manage-fleet.component.css']
})
export class ManageFleetComponent implements OnInit,AfterViewChecked {
  readonly USERTYPE = USERTYPE;
  extUserDisplayedColumns: any;
  intUserDisplayedColumns: any;
  displayedChildColumns: any;
  samplename: string = "";
  userRole: string = '';
  searchObj: any;
  openModal: boolean;
  unitId: string = '';
  equipId: any ='';
  unitNo: any ='';
  dashboardData: any[] = [];
  oilInfo: any[] = [];
  coolantInfo: any[] = [];
  fuelInfo: any[] = [];
  defInfo: any[] = [];
  fluidForOil: any[] = [];
  fluidForCoolant: any[] = [];
  fluidForFuel: any[] = [];
  fluidForDef: any[] = [];
  filterForOil: any[] = [];
  filterForCoolant: any[] = [];
  filterForFuel: any[] = [];
  scrollToTop =  false;

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize;
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  pagevalue: any;
  /* --------Grid Filter variable declaration------- */
  intUserFilterObject = {
    companyName: [],
    companyId: [],
    unitId: [],
    unitMake: [],
    unitModel: [],
    unitYear: [],
    // esn: [],
    // engineMake: [],
    // engineModel: []
    updatedDate:[],
    componentCount:[]
  }

  extUserFilterObject = {
    unitId: [],
    unitMake: [],
    unitModel: [],
    unitYear: [],
    // esn: [],
    // engineMake: [],
    // engineModel: [],
    updatedDate:[],
    componentCount:[]
  }

  extUsermanageFleetSearchForm = new UntypedFormGroup({
    unitId: new UntypedFormControl(''),
    unitMake: new UntypedFormControl(''),
    unitModel: new UntypedFormControl(''),
    unitYear: new UntypedFormControl(''),
    updatedDate:new UntypedFormControl(''),
    componentCount:new UntypedFormControl('')
    // esn: new UntypedFormControl(''),
    // engineMake: new UntypedFormControl(''),
    // engineModel: new UntypedFormControl('')
  });

  intUsermanageFleetSearchForm = new UntypedFormGroup({
    companyName: new UntypedFormControl(''),
    companyId: new UntypedFormControl(''),
    unitId: new UntypedFormControl(''),
    unitMake: new UntypedFormControl(''),
    unitModel: new UntypedFormControl(''),
    unitYear: new UntypedFormControl(''),
    updatedDate:new UntypedFormControl(''),
    componentCount:new UntypedFormControl('')
    // esn: new UntypedFormControl(''),
    // engineMake: new UntypedFormControl(''),
    // engineModel: new UntypedFormControl('')
  });
  dbuCustomerNameDropdown: any;
  messageDropdown: any;
  isFirstTime = true;
  branchName: any;
  ManageFleet:any;
  currentItem:any;
  behaviorObs:any;
  componentDisplayedColumns:any;
  componentData: any;
  data: { unitNo: string; };
  companyIdListArr: any[] = [];
  constructor(private router: Router,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private sharedService: SharedService,
    private manageFleetService: ManageFleetService) { 
      this.pagevalue = JSON.parse(localStorage.getItem('ManagefleetPageObject'));
      if(this.pagevalue !=null){
        this.pageSize = JSON.parse(localStorage.getItem('ManagefleetPageObject'));
      }else{
        this.pageSize ='10'
      }
    }

  @ViewChildren(SortComponent) sortComponents;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    let sortdata = sessionStorage.getItem('managefleetSortData')
    if(sortdata == 'undefined'){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(sortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      this.columnName = this.currentItem.column;
      this.sortingOrder = this.currentItem.sort;
    }
    else{
      this.sortingOrder = 'desc';
    }
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.columnName = "";
    } else {
      this.columnName = "";
    }

    let ManageFleet = JSON.parse(sessionStorage.getItem('ManagefleetObject'));
    if(ManageFleet !=null){
      if (this.userRole === USERTYPE.EXTERNAL) { 
        this.clearSearchfilters();        
        // this.extUsermanageFleetSearchForm.patchValue({companyId:ManageFleet['companyId']})
        this.extUsermanageFleetSearchForm.patchValue({unitId:ManageFleet['unitId']})
        this.extUsermanageFleetSearchForm.patchValue({unitMake:ManageFleet['unitMake']})
        this.extUsermanageFleetSearchForm.patchValue({unitModel:ManageFleet['unitModel']})
        this.extUsermanageFleetSearchForm.patchValue({unitYear:ManageFleet['unitYear']})
        // this.extUsermanageFleetSearchForm.patchValue({esn:ManageFleet['esn']})
        // this.extUsermanageFleetSearchForm.patchValue({engineMake:ManageFleet['engineMake']})
        // this.extUsermanageFleetSearchForm.patchValue({engineModel:ManageFleet['engineModel']})
        this.extUsermanageFleetSearchForm.patchValue({componentCount:ManageFleet['componentCount']})
        this.extUsermanageFleetSearchForm.patchValue({updatedDate:ManageFleet['updatedDate']})

      }else{           
        this.intUsermanageFleetSearchForm.patchValue({companyName:ManageFleet['companyName']})
        this.intUsermanageFleetSearchForm.patchValue({companyId:ManageFleet['companyId']})
        this.intUsermanageFleetSearchForm.patchValue({unitId:ManageFleet['unitId']})
        this.intUsermanageFleetSearchForm.patchValue({unitMake:ManageFleet['unitMake']})
        this.intUsermanageFleetSearchForm.patchValue({unitModel:ManageFleet['unitModel']})
        this.intUsermanageFleetSearchForm.patchValue({unitYear:ManageFleet['unitYear']})
        // this.intUsermanageFleetSearchForm.patchValue({esn:ManageFleet['esn']})
        // this.intUsermanageFleetSearchForm.patchValue({engineMake:ManageFleet['engineMake']})
        // this.intUsermanageFleetSearchForm.patchValue({engineModel:ManageFleet['engineModel']})
        this.intUsermanageFleetSearchForm.patchValue({componentCount:ManageFleet['componentCount']})
        this.intUsermanageFleetSearchForm.patchValue({updatedDate:ManageFleet['updatedDate']})

      }
    }

    this.extUserDisplayedColumns = [
      { code: "unitId", name: "Primary Unit ID" },
      { code: "unitMake", name: "Unit Make" },
      { code: "unitModel", name: "Unit Model" },
      { code: "unitYear", name: "Unit Year" },
      // { code: "esn", name: "ESN" },
      // { code: "engineMake", name: "Engine Make" },
      // { code: "engineModel", name: "Engine Model" },
      { code: "componentCount", name: "No Of Components" },
      { code: "updatedDate", name: "Date Modified" }
      
     
    ];
    this.intUserDisplayedColumns = [
      { code: "companyName", name: "Company Name" },
      { code: "companyId", name: "Company ID" },
      { code: "unitId", name: "Primary Unit ID" },
      { code: "unitMake", name: "Unit Make" },
      { code: "unitModel", name: "Unit Model" },
      { code: "unitYear", name: "Unit Year" },
      // { code: "esn", name: "ESN" },
      // { code: "engineMake", name: "Engine Make" },
      // { code: "engineModel", name: "Engine Model" },
      { code: "componentCount", name: "No Of Components" },
      { code: "updatedDate", name: "Date Modified" }
    
    ];
    this.componentDisplayedColumns = [
      { code: "componentId", name: "Component ID" },
      { code: "componentType", name: "Component" },
      { code: "secondaryUnitId", name: "Secondary ID" },
      { code: "engineType", name: "Type" },
      { code: "componentMake", name: " Make" },
      { code: "componentModel", name: " Model" },
      { code: "componentYear", name: " Year" },
      { code: "updatedDate", name: "Date Modified" },
      { code: "distanceUom", name: "UOM" }
    
    ];
    this.displayedChildColumns = [
      { code: "previous_sample", name: "" },
      { code: "oil", name: "Oil" },
      { code: "coolant", name: "Coolant" },
      { code: "fuel", name: "Diesel Fuel" },
      { code: "diesel_exhaust_fluid", name: "Diesel Exhaust Fluid" }
    ];

    if(localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)){
      this.recieveMessage(JSON.parse(localStorage.getItem("branchCustomerDropdown")))      
    } else {     
      this.getManageFleetDashboardData();      
    }
    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
        if(this.router.url == '/manageFleet'){      
          this.messageDropdown = message;
          if(this.isFirstTime == false){
            this.recieveMessage(this.messageDropdown);
            // console.log('manageFleet11', this.messageDropdown)
          }
          this.isFirstTime = false;
        }
      }
    });

    // if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){
    //   this.intUsermanageFleetSearchForm.controls['companyId'].disable();
    //   this.intUsermanageFleetSearchForm.controls['companyName'].disable();
    // }
  }
  ngOnDestroy(){
    this.behaviorObs.unsubscribe();
    localStorage.setItem('ManagefleetPageObject', JSON.stringify(this.pageSize));
  }

//   const box = document.getElementById('box');
// if(reviewUnitId = true){
//   box.classList.add('bg-yellow');
// }
  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  /* -------------------------Dashboard data display on page load------------------------- */
  getManageFleetDashboardData() {
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj = {
        companyId: '',
        companyIdList : this.companyIdListArr,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        unitId: this.extUsermanageFleetSearchForm.value['unitId'],
        unitMake: this.extUsermanageFleetSearchForm.value['unitMake'],
        unitModel: this.extUsermanageFleetSearchForm.value['unitModel'],
        unitYear: this.extUsermanageFleetSearchForm.value['unitYear'],
        componentCount: this.extUsermanageFleetSearchForm.value['componentCount'],
        updatedDate: this.extUsermanageFleetSearchForm.value['updatedDate'],

        // esn: this.extUsermanageFleetSearchForm.value['esn'],
        // engineMake: this.extUsermanageFleetSearchForm.value['engineMake'],
        // engineModel: this.extUsermanageFleetSearchForm.value['engineModel'],

        
      }
      this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    } else if (this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST ||
      this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole ==USERTYPE.FLUIDWATCH_MANAGER) {
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        userRole : this.sharedService.getIntOrExtUser(),
        companyName:this.intUsermanageFleetSearchForm.value['companyName'],
        unitId: this.intUsermanageFleetSearchForm.value['unitId'],
        companyId: this.intUsermanageFleetSearchForm.value['companyId'],
        unitMake: this.intUsermanageFleetSearchForm.value['unitMake'],
        unitModel: this.intUsermanageFleetSearchForm.value['unitModel'],
        unitYear: this.intUsermanageFleetSearchForm.value['unitYear'],
        componentCount: this.intUsermanageFleetSearchForm.value['componentCount'],
        updatedDate: this.intUsermanageFleetSearchForm.value['updatedDate'],
        // esn: this.intUsermanageFleetSearchForm.value['esn'],
        // engineMake: this.intUsermanageFleetSearchForm.value['engineMake'],
        // engineModel: this.intUsermanageFleetSearchForm.value['engineModel']
      }
    }

    this.manageFleetService.getManageFleetDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.dashboardData = response.manageFleetDTOList;
          console.log(this.dashboardData)
          this.totalItems = response.manageFleetDTOList[0].maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  extUserclearSearch(str) {
    //console.log(this.extUsermanageFleetSearchForm.value);
    if( this.extUsermanageFleetSearchForm.controls[str].value != ''){
    this.extUserFilterObject[str] = [];
    this.extUsermanageFleetSearchForm.patchValue({ [str]: '' });
      this.searchDashboard();
    }
  }

  intUserclearSearch(str) {
    //console.log(this.intUsermanageFleetSearchForm.value);
    if( this.intUsermanageFleetSearchForm.controls[str].value != ''){
    this.intUsermanageFleetSearchForm.patchValue({ [str]: '' });
    this.intUserFilterObject[str] = [];
    this.searchDashboard();
    }
  }

  /* -------------------------ext User filter value dropdown------------------------- */
  extUsersearchFilter(str) {
    sessionStorage.setItem('ManagefleetObject', JSON.stringify(this.extUsermanageFleetSearchForm.value));
    let search = (this.extUsermanageFleetSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.extUserFilterObject[str] = [];
      this.searchDashboardDropDownSelect();
    }
    let _data = {
      searchColumn: str,
      searchValue: this.extUsermanageFleetSearchForm.value[str],
      companyId: '',
      companyIdList : this.companyIdListArr,
      userRole: this.userRole,
      unitId: this.extUsermanageFleetSearchForm.value['unitId'],
      unitMake: this.extUsermanageFleetSearchForm.value['unitMake'],
      unitModel: this.extUsermanageFleetSearchForm.value['unitModel'],
      unitYear: this.extUsermanageFleetSearchForm.value['unitYear'],
      // esn: this.extUsermanageFleetSearchForm.value['esn'],
      // engineMake: this.extUsermanageFleetSearchForm.value['engineMake'],
      // engineModel: this.extUsermanageFleetSearchForm.value['engineModel']
      componentCount: this.extUsermanageFleetSearchForm.value['componentCount'],
      updatedDate: this.extUsermanageFleetSearchForm.value['updatedDate'],

    }
    _data.userRole = this.sharedService.getIntOrExtUser();

    
    if (this.extUsermanageFleetSearchForm.value[str].length >= 2) {
      this.manageFleetService.getManageFleetDashboardData(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.extUserFilterObject[str] = response.manageFleetDTOList;
            
            if(str === 'engineMake' || str === 'engineModel')
            {
              this.addBlank(str,search,this.extUserFilterObject);
            }
          } 
          else 
          {
            let  value = this.addBlank(str,search,this.extUserFilterObject)
        
         if(value === 'valid')
         {
         console.log('valid')
         }
         else if(value === 'invalid')
         {          
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
          this.extUserFilterObject[str] = [];
          this.alertService.clear();
          window.sessionStorage.removeItem('ManagefleetObject');
         }
         else{
          this.alertService.error(response.statusMessage);          
          this.scrollToTop = true;
         }     
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }
  }
  clearSearchfilters(){
    this.alertService.clear();
    this.pageSize='10';
    window.sessionStorage.removeItem('ManagefleetObject');    
    this.intUsermanageFleetSearchForm.patchValue({unitId:''})
    this.intUsermanageFleetSearchForm.patchValue({companyId:''})
    this.intUsermanageFleetSearchForm.patchValue({unitMake:''})
    this.intUsermanageFleetSearchForm.patchValue({unitModel:''})
    this.intUsermanageFleetSearchForm.patchValue({unitYear:''})    
    this.intUsermanageFleetSearchForm.patchValue({companyName:''})
    this.intUsermanageFleetSearchForm.patchValue({analysisType:''})
    // this.intUsermanageFleetSearchForm.patchValue({esn:''})
    // this.intUsermanageFleetSearchForm.patchValue({engineMake:''});
    // this.intUsermanageFleetSearchForm.patchValue({engineModel:''}); 
    this.extUsermanageFleetSearchForm.patchValue({unitId:''})
    this.extUsermanageFleetSearchForm.patchValue({unitMake:''})
    this.extUsermanageFleetSearchForm.patchValue({unitModel:''})
    this.extUsermanageFleetSearchForm.patchValue({unitYear:''})    
    this.extUsermanageFleetSearchForm.patchValue({analysisType:''})
    // this.extUsermanageFleetSearchForm.patchValue({esn:''})
    // this.extUsermanageFleetSearchForm.patchValue({engineMake:''});
    // this.extUsermanageFleetSearchForm.patchValue({engineModel:''});
    this.extUsermanageFleetSearchForm.patchValue({updatedDate:''});
    this.extUsermanageFleetSearchForm.patchValue({componentCount:''}); 
    this.intUsermanageFleetSearchForm.patchValue({componentCount:''});
    this.intUsermanageFleetSearchForm.patchValue({updatedDate:''});  
    this.searchDashboard();
    this.loadPage(1);
  }

  /* -------------------------int User filter value dropdown------------------------- */
  intUsersearchFilter(str) {
    sessionStorage.setItem('ManagefleetObject', JSON.stringify(this.intUsermanageFleetSearchForm.value));
    let search = (this.intUsermanageFleetSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.intUsermanageFleetSearchForm[str] = [];
      this.searchDashboardDropDownSelect();
    }
    console.log("search",search)
    let _data = {
      searchColumn: str,
      searchValue: this.intUsermanageFleetSearchForm.value[str],
      companyName: this.intUsermanageFleetSearchForm.value['companyName'], 
      companyId: this.intUsermanageFleetSearchForm.value['companyId'],
      unitId: this.intUsermanageFleetSearchForm.value['unitId'],
      unitMake: this.intUsermanageFleetSearchForm.value['unitMake'],
      unitModel: this.intUsermanageFleetSearchForm.value['unitModel'],
      unitYear: this.intUsermanageFleetSearchForm.value['unitYear'],
      componentCount: this.intUsermanageFleetSearchForm.value['componentCount'],
      updatedDate: this.intUsermanageFleetSearchForm.value['updatedDate'],
      // esn: this.intUsermanageFleetSearchForm.value['esn'],
      // engineMake: this.intUsermanageFleetSearchForm.value['engineMake'],
      // engineModel: this.intUsermanageFleetSearchForm.value['engineModel']
    }
    if (this.intUsermanageFleetSearchForm.value[str].length >= 2) {
      this.manageFleetService.getManageFleetDashboardData(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            let search=this.intUsermanageFleetSearchForm.value[str].toLowerCase(); 
            this.intUserFilterObject[str] = response.manageFleetDTOList;
            console.log(this.intUserFilterObject[str]);
           
            if(str === 'engineMake' || str === 'engineModel')
            {
              this.addBlank(str,search,this.intUserFilterObject);
            }          
            console.log("this.intUserFilterObject",this.intUserFilterObject);
 
          } else {    
            
         let  value = this.addBlank(str,search,this.intUserFilterObject)
        
         if(value === 'valid')
         {
         console.log('valid')
         }
         else if(value === 'invalid')
         {
            
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
          this.intUserFilterObject[str] = [];
          this.alertService.clear();
          window.sessionStorage.removeItem('ManagefleetObject');
         }
         else{
          this.alertService.error(response.statusMessage);          
          this.scrollToTop = true;
         }         
          }
         
        }, err => {
         
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    }
    else if(this.intUsermanageFleetSearchForm.value[str].length == 0){
      this.intUserclearSearch(str)
      this.intUserFilterObject[str] == [];
      this.intUserFilterObject[str].length = 0;

    }
  }

  addBlank(searchColumn,searchValue,searchList)
  { 
   let countMake =0;
   let countModel=0;
 
   if((searchColumn === 'engineMake'))
   {
     if((searchValue == 'bl' || searchValue == 'bla' || searchValue == 'blan' || searchValue =='blank') === true)
     {
       // countMake=0;
       for(let val of searchList.engineMake)
       {
             if(val.engineMake === 'Blank')
             {
               countMake++;
             }
       }
       if(countMake === 0)
       {
         searchList.engineMake.push({maxRow: 0, engineMake: "Blank", expanded: false});
         
       }
       return 'valid'
      
     }   
     return 'invalid'        
   }
   if((searchColumn === 'engineModel'))
   {
     if((searchValue == 'bl' || searchValue == 'bla' || searchValue == 'blan' || searchValue =='blank'))
     {
       for(let val of searchList.engineModel)
       {
             if(val.engineModel === 'Blank')
             {
               countModel++;
             }
       }
       if(countModel === 0)
       {
         searchList.engineModel.push({maxRow: 0, engineModel: "Blank", expanded: false});
         
       }
       return 'valid';
     }    
     return 'invalid'       
   }
  }

  /* -------------------------search button for filter value selected------------------------- */
  searchDashboard() {
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj = {
        userRole: this.userRole, 
        companyId: '',
        companyIdList : this.companyIdListArr,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        unitId: this.extUsermanageFleetSearchForm.value['unitId'],
        unitMake: this.extUsermanageFleetSearchForm.value['unitMake'],
        unitModel: this.extUsermanageFleetSearchForm.value['unitModel'],
        unitYear: this.extUsermanageFleetSearchForm.value['unitYear'],
        componentCount: this.extUsermanageFleetSearchForm.value['componentCount'],
        updatedDate: this.extUsermanageFleetSearchForm.value['updatedDate'],

        // esn: this.extUsermanageFleetSearchForm.value['esn'],
        // engineMake: this.extUsermanageFleetSearchForm.value['engineMake'],
        // engineModel: this.extUsermanageFleetSearchForm.value['engineModel']
      }
      this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    } else if (this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST ||
      this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole ==USERTYPE.FLUIDWATCH_MANAGER) {
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        companyName: this.intUsermanageFleetSearchForm.value['companyName'], 
        companyId: this.intUsermanageFleetSearchForm.value['companyId'],
        unitId: this.intUsermanageFleetSearchForm.value['unitId'],
        unitMake: this.intUsermanageFleetSearchForm.value['unitMake'],
        unitModel: this.intUsermanageFleetSearchForm.value['unitModel'],
        unitYear: this.intUsermanageFleetSearchForm.value['unitYear'],
        componentCount: this.intUsermanageFleetSearchForm.value['componentCount'],
        updatedDate: this.intUsermanageFleetSearchForm.value['updatedDate'],
        // esn: this.intUsermanageFleetSearchForm.value['esn'],
        // engineMake: this.intUsermanageFleetSearchForm.value['engineMake'],
        // engineModel: this.intUsermanageFleetSearchForm.value['engineModel']
      }
    }

    this.manageFleetService.getManageFleetDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.dashboardData = response.manageFleetDTOList;
          this.totalItems = response.manageFleetDTOList[0].maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
          this.dashboardData = [];
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );

  }

  searchDashboardDropDownSelect() {
    this.autocomplete.showPanel;
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj = {
        userRole: this.userRole,
        companyId: '',
        companyIdList : this.companyIdListArr,
        pageSize: this.pageSize.toString(),
        currentPage: "1",
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        unitId: this.extUsermanageFleetSearchForm.value['unitId'],
        unitMake: this.extUsermanageFleetSearchForm.value['unitMake'],
        unitModel: this.extUsermanageFleetSearchForm.value['unitModel'],
        unitYear: this.extUsermanageFleetSearchForm.value['unitYear'],
        componentCount: this.extUsermanageFleetSearchForm.value['componentCount'],
        updatedDate: this.extUsermanageFleetSearchForm.value['updatedDate'],

        // esn: this.extUsermanageFleetSearchForm.value['esn'],
        // engineMake: this.extUsermanageFleetSearchForm.value['engineMake'],
        // engineModel: this.extUsermanageFleetSearchForm.value['engineModel']
      }
      this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    } 
    
     else if (this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST ||
      this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole ==USERTYPE.FLUIDWATCH_MANAGER) {
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: "1",
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        companyName:  this.intUsermanageFleetSearchForm.value['companyName'],
        companyId: this.intUsermanageFleetSearchForm.value['companyId'],
        unitId: this.intUsermanageFleetSearchForm.value['unitId'],
        unitMake: this.intUsermanageFleetSearchForm.value['unitMake'],
        unitModel: this.intUsermanageFleetSearchForm.value['unitModel'],
        unitYear: this.intUsermanageFleetSearchForm.value['unitYear'],
        componentCount: this.intUsermanageFleetSearchForm.value['componentCount'],
        updatedDate: this.intUsermanageFleetSearchForm.value['updatedDate'],

        // esn: this.intUsermanageFleetSearchForm.value['esn'],
        // engineMake: this.intUsermanageFleetSearchForm.value['engineMake'],
        // engineModel: this.intUsermanageFleetSearchForm.value['engineModel']
      }
    }

    this.manageFleetService.getManageFleetDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.dashboardData = response.manageFleetDTOList;
          this.totalItems = response.manageFleetDTOList[0].maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
          this.dashboardData = [];
          if(this.userRole == USERTYPE.DBU_BRANCH || USERTYPE.DBU_FLUIDS){
            this.totalItems = 0;          
            this.showRecords = 0;
            this.maxPage = 0;
          }
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );

  }




  intUserReset() {
    this.intUserFilterObject.companyName = [];
    this.intUserFilterObject.companyId = [];
    this.intUserFilterObject.unitId = [];
    this.intUserFilterObject.unitMake = [];
    this.intUserFilterObject.unitModel = [];
    this.intUserFilterObject.unitYear = [];
    this.intUserFilterObject.updatedDate = [];
    this.intUserFilterObject.componentCount = [];
    // this.intUserFilterObject.engineMake = [];
    // this.intUserFilterObject.engineModel = [];
    // this.intUserFilterObject.esn = [];
  }

  extUserReset() {
    this.extUserFilterObject.unitId = [];
    this.extUserFilterObject.unitMake = [];
    this.extUserFilterObject.unitModel = [];
    this.extUserFilterObject.unitYear = [];
    this.extUserFilterObject.updatedDate = [];
    this.extUserFilterObject.componentCount = [];

    // this.extUserFilterObject.engineMake = [];
    // this.extUserFilterObject.engineModel = [];
    // this.extUserFilterObject.esn = [];
  }

  /* -------------------------reset button functionality------------------------- */
  resetDashboard() {
    this.extUsermanageFleetSearchForm.reset();
    this.intUsermanageFleetSearchForm.reset();
    this.intUserReset();
    this.extUserReset();
    this.getManageFleetDashboardData();
  }


  /* -------------------------More Information accordion functionality------------------------- */
  getComponentShowMoreInfo(row, unitNo: string){
      let _data = {
        "unitNo": unitNo
      }
    if (row.expanded === false) {
      this.dashboardData.forEach(element => {
        element.expanded = false;
      });
      this.manageFleetService.getManageFleetDashboardData(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            this.componentData = response.componentList;
            row.expanded = !row.expanded;           
          } else {
            this.alertService.error(response.statusMessage);
            this.scrollToTop = true;
            row.expanded = !row.expanded;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    } else {
      row.expanded = !row.expanded;
    }

  }
  
  getShowMoreInfo(row, equipmentId: string) {
    // row.expanded = !row.expanded;
    let _data = {
      "equipmentId": equipmentId,
      "unitNo": row.unitId
    }
    //console.log(row.expanded + "row value")
    if (row.expanded === false) {
      this.manageFleetService.getShowMoreInfo(_data).subscribe(
        response => {
          if (response.statusCode == "1") {
            /*this.oilInfo = response.oilSampleInfo;
            this.coolantInfo = response.coolantSampleInfo;
            this.fuelInfo = response.fuelSampleInfo;
            this.defInfo = response.defSampleInfo;
            this.fluidForOil = response.fluidForOil;
            this.fluidForCoolant = response.fluidForCoolant;
            this.fluidForFuel = response.fluidForFuel;
            this.fluidForDef = response.fluidForDEF;
            this.filterForOil = response.filterForOil;
            this.filterForFuel = response.filterForFuel;*/
            row['oilInfo'] = response.oilSampleInfo;
            row['coolantInfo'] = response.coolantSampleInfo;
            row['fuelInfo'] = response.fuelSampleInfo;
            row['defInfo'] = response.defSampleInfo;
            row['fluidForOil'] = response.fluidForOil;
            row['fluidForCoolant'] = response.fluidForCoolant;
            row['fluidForFuel'] = response.fluidForFuel;
            row['fluidForDef'] = response.fluidForDEF;
            row['filterForOil'] = response.filterForOil;
            row['filterForCoolant'] = response.filterForCoolant;
            row['filterForFuel'] = response.filterForFuel;
            row.expanded = !row.expanded;
          } else {
            this.alertService.error(response.statusMessage);
            this.scrollToTop = true;
            row.expanded = !row.expanded;
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        }
      );
    } else {
      row.expanded = !row.expanded;
    }

  }

  /* -------------------------Navigation and value passing to Show More Info screen--------------- */
  showMoreInfo(row, unitId: string) {
    var infoData = {
      companyId: row.companyId,
      companyName: row.companyName,
      unitId: row.unitId,
      reviewUnit: row.isReviewUnit,
      secondaryUnitId: row.secondaryUnitId,
      unitMake: row.unitMake,
      unitModel: row.unitModel,
      unitYear: row.unitYear,
      // esn: row.esn,
      gsn: row.gsn,
      // engineMake: row.engineMake,
      applicationType:row.applicationType,
      // engineModel: row.engineModel,
      distanceUOM: row.distanceUOM,
      engineYear: row.engineYear,
      equipmentId: row.equipId,
      engineType: row.engineType,
      unitMakeFlag: row.unitMakeFlag,
      appTypeFlag: row.appTypeFlag,
      engineMakeFlag: row.engineMakeFlag,
      engineModFlag: row.engineModFlag,
      unitNo:row.unitNo,
      componentCount:row.componentCount,
      updatedDate:row.updatedDate,
    }
    this.sharedService.setInformation(infoData);
    this.router.navigateByUrl("/moreInformation");
  }

  goToEdit(row:any) {
    var infoData = {
      companyId: row.companyId,
      companyName: row.companyName,
      unitId: row.unitId,
      unitNo: row.unitNo,
      reviewUnit: row.isReviewUnit,
      secondaryUnitId: row.secondaryUnitId,
      unitMake: row.unitMake,
      unitModel: row.unitModel,
      unitYear: row.unitYear,
      esn: row.esn,
      gsn: row.gsn,
      // engineMake: row.engineMake,
      applicationType:row.applicationType,
      // engineModel: row.engineModel,
      distanceUOM: row.distanceUOM,
      engineYear: row.engineYear,
      componentCount:row.componentCount,
      updatedDate:row.updatedDate,
      equipmentId: row.equipId,
      engineType: row.engineType,
      unitMakeFlag: row.unitMakeFlag,
      appTypeFlag: row.appTypeFlag,
      engineMakeFlag: row.engineMakeFlag,
      engineModFlag: row.engineModFlag
    }
    this.sharedService.setInformation(infoData);
    var info = {
      mode : 'edit',
      companyId: ''
    }
    this.sharedService.setAddOrEdit(info);
    this.router.navigate(['/editVehicle']);
  }

  /* -------------------------Delete button functionality------------------------- */
  deleteEquipment() {
    let _data = {
      "equipmentId": this.equipId,
      "unitNo": this.unitNo
    }
    this.manageFleetService.deleteEquipment(_data).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.closeModal();
          this.getManageFleetDashboardData();
          this.alertService.success(response.statusMessage);
          this.scrollToTop = true;
        } else {
          this.closeModal();
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );
  }

  /* -------------------------Delete confirmation popup------------------------- */
  confirmPopup(unitId: string, equipId: any, unitNo: any) {
    this.openModal = true;
    this.unitId = unitId;
    this.equipId = equipId;
    this.unitNo = unitNo;
  }

  closeModal() {
    this.openModal = false;
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.dashboardData = [];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('ManagefleetPageObject', JSON.stringify(this.pageSize));
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.dashboardData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  searchLengthInt(filterObject:any)
  {
    if(filterObject.companyName.length >0 ||filterObject.companyId.length >0 || filterObject.unitId.length >0 ||filterObject.unitMake.length >0 ||
      filterObject.unitModel.length >0 ||filterObject.unitYear.length >0 ||
      filterObject.componentCount.length >0  ||filterObject.updatedDate.length >0  )
    {
        return true
    }
    else
    {
      return false
    }
  }

  searchLengthExt(filterObject:any)
  {
    if( filterObject.unitId.length >0 ||filterObject.unitMake.length >0 ||
      filterObject.unitModel.length >0 ||filterObject.unitYear.length >0  ||
      filterObject.componentCount.length >0  ||filterObject.updatedDate.length >0  )
    {
        return true
    }
    else
    {
      return false
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    let val;
    if(this.userRole == USERTYPE.EXTERNAL)
    {
       val =this.searchLengthExt(this.extUserFilterObject)
    }
    else{
       val =this.searchLengthInt(this.intUserFilterObject)
    }
  
   
    if(val === true)
    {
      this.searchDashboard();
    }
    else
    {
      this.getManageFleetDashboardData();
    }
  }



  getArray(): any[] {
    return Array(this.maxPage);
  }

  /**
 * Calls search method to sort the data based on columnName
 * @param columnName 
 */
   sortData(columnName) { 
    let existingsortdata= sessionStorage.getItem('analyzesortData');
    if(existingsortdata == 'undefined' || existingsortdata == null || existingsortdata== ""){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(existingsortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      if(this.currentItem.sort == 'asc'){
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      }else{
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
  
      }
    }
    let sortcolumn=  this.sortingOrder != 'asc' ? 'asc' : 'desc';
    let sort ={column : columnName, sort :sortcolumn,direction:sortcolumn};
    sessionStorage.setItem('managefleetSortData', JSON.stringify(sort));
      if (this.columnName != columnName) {
        this.sortingOrder = "";
        this.sortComponents._results.forEach(row => row.direction = true);
      }
      this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      this.columnName = columnName;
      this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
      this.getManageFleetDashboardData();
  }

  hideShowMoreInfo(item){
    item.expanded = false;
  }


  addEquipment(){
    var info = {
      mode : 'add',
      companyId: '',
      
    }
    this.sharedService.setAddOrEdit(info);
  }

  
  //dropdown Customer
  recieveMessage(event){
    // this.intUsermanageFleetSearchForm.controls['companyId'].enable();
    // this.intUsermanageFleetSearchForm.controls['companyName'].enable();
    this.dbuCustomerNameDropdown = event;
    // console.log(event)
    this.branchName = this.dbuCustomerNameDropdown.branchName
    let companyName = this.dbuCustomerNameDropdown.customerName;
    let companyId = this.dbuCustomerNameDropdown.customerId;
    this.intUsermanageFleetSearchForm.patchValue({
      companyName: companyName,
      companyId: companyId
    })
    // if(companyId == 'notListed'){
    //   this.intUsermanageFleetSearchForm.patchValue({
    //     companyName: '',
    //     companyId: ''
    //   })
    // } else {
    //   this.intUsermanageFleetSearchForm.patchValue({
    //     companyName: companyName,
    //     companyId: companyId
    //   })
    // }
    if(this.intUsermanageFleetSearchForm.controls['companyName'].value == ''){
      this.alertService.error("Please select Company Name");
      this.scrollToTop = true;
      this.dashboardData = [];
      this.totalItems = 0;          
      this.showRecords = 0;
      this.maxPage = 0;
      this.intUsermanageFleetSearchForm.disable();
    } else {
      this.intUsermanageFleetSearchForm.enable();
      this.searchDashboardDropDownSelect();
    }
    // this.searchDashboardDropDownSelect();
    // this.intUsermanageFleetSearchForm.controls['companyId'].disable();
    // this.intUsermanageFleetSearchForm.controls['companyName'].disable();
  }
  
}
