import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';

var data = {email: "mock.user@cummins.com",
    endDate: null,
    firstName: "Mock",
    internalUser: true,
    isActive: null,
    lastName: "Data Scientist User",
    role: "2",
    roleName: USERTYPE.DATA_SCIENTIST,
    startDate: null,
    updatedBy: null,
    wwid: "mock1"
  };

@Component({
  selector: 'app-user-type-selection',
  templateUrl: './user-type-selection.component.html',
  styleUrls: ['./user-type-selection.component.css']
})
export class UserTypeSelectionComponent implements OnInit {

  readonly USERTYPE = USERTYPE;

  constructor(public router:Router,
    private sharedService: SharedService) { }

  ngOnInit() {
  }

  extUser() {    
    /*data.roleName = USERTYPE.EXTERNAL;
    data.lastName = 'External User';
    this.sharedService.setUserInfo(data);*/
    this.sharedService.setUserInfo([]);
    this.router.navigate(['/externalUserLogin']);
  }
  labAdminUser() {    
    data.roleName = USERTYPE.LAB_ADMIN;
    data.lastName = 'Lab Admin User';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
  }
  dataScientistUser() {    
    data.roleName = USERTYPE.DATA_SCIENTIST;
    data.lastName = 'Data Scientist User';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
  }


  cmiUser() {
   data.roleName = USERTYPE.CMI_USER;
    data.lastName = 'CMI Engineer';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
  }

  tsmUser() {
    data.roleName = USERTYPE.TSM;
    data.lastName = 'TSM User';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
   }

   aeUser() {
    data.roleName = USERTYPE.AE;
    data.lastName = 'AE user';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
   }

   dbuBranchUser(){
    data.roleName = USERTYPE.DBU_BRANCH;
    data.lastName = 'DBU Branch user';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
   }

   dbuFluidsUser(){
    data.roleName = USERTYPE.DBU_FLUIDS;
    data.lastName = 'DBU Fluids user';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
   }

   fluidWatchManagerUser(){
    data.roleName = USERTYPE.FLUIDWATCH_MANAGER;
    data.lastName = 'FluidWatch Manager user';
    this.sharedService.setUserInfo(data);
    this.sharedService.setCompanyId(null);
   }
}
