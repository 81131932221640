import { Component, OnInit } from '@angular/core';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-fleet-report-home',
  templateUrl: './fleet-report-home.component.html',
  styleUrls: ['./fleet-report-home.component.css']
})
export class FleetReportHomeComponent implements OnInit {

  userRole: string = '';
  readonly USERTYPE = USERTYPE;
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.userRole = this.sharedService.getUserRole();
  }

}
