import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit,AfterViewChecked {

  scrollToTop =  false; 
  isAnalysis =  true;
  populationSearchData = [];
  commentKeySearchData = [];
  populationSearchInput = '';
  commentKeySearchInput = '';

  populationMap:any;
  analysisCommentMap:any;
  actionCommentMap:any;
 
  populationList = [];
  analysisCommentList = [];
  actionCommentList = [];

  fetchedComment:any;
  successMessage: string = '';  
  successModal = false;
  saveCommentModal = false; 
  fluidTypeList = [];
  displayResult = false;
  staticComments = '';
  commentsValue;

  commentsForm = new UntypedFormGroup({
    analysisOrAction: new UntypedFormControl('analysis',[Validators.required]),
    fluidType: new UntypedFormControl('',[Validators.required]),
    populationId: new UntypedFormControl({value: '', disabled: true},[Validators.required]),
    commentsKey: new UntypedFormControl({value: '', disabled: true},[Validators.required]),
    commentsValue: new UntypedFormControl({value: '', disabled: true}),
    commentsId: new UntypedFormControl('')
  });

  constructor(private masterDataManagementService:MasterDataManagementService,
    private alertService: AlertService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.getCommentsDropdownData();
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  //comments
  switchToAnalysis(){
    this.isAnalysis = true;
    this.displayResult = false;
    this.commentsForm.controls['commentsValue'].disable();
    this.commentsForm.patchValue({      
      commentsValue:'',
      commentsKey:''
    });
    this.commentKeySearch('');
  }

  switchToAction(){
    this.isAnalysis = false;
    this.displayResult = false;
    this.commentsForm.controls['commentsValue'].disable();
    this.commentsForm.patchValue({      
      commentsValue:'',
      commentsKey:''
    });
    this.commentKeySearch('');
  }

  getCommentValueData(){
    let data = this.commentsForm.getRawValue();
    if(this.isAnalysis){
      data['analysisComment'] = true;
    }else{
      data['actionComment'] = true;
    }

    this.masterDataManagementService.getCommentValue(data).subscribe(response =>{        
        if (response.statusCode == "1") {  
          this.fetchedComment = response;  
          this.commentsForm.patchValue({
            commentsValue : response.commentValue.commentValue,
            commentsId : response.commentValue.commentId
          });
          this.commentsForm.controls['commentsValue'].enable();
          this.displayResult = true;
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });  

      //set static comments
      this.analysisCommentList.forEach(row => {
        if(row.commentKey == this.commentsForm.controls.commentsKey.value){
            this.staticComments = row.staticComments;
        }
      });
  }

  fluidTypeChange(){
    this.commentsForm.patchValue({
      populationId: '',
      commentsKey: '',
      commentsValue: ''
    });
    let fluidType = this.commentsForm.controls.fluidType.value;
    this.populationList = this.populationMap[fluidType];
    this.analysisCommentList = this.analysisCommentMap[fluidType];
    this.actionCommentList = this.actionCommentMap[fluidType];
    this.populationDataSearch('');
    this.commentKeySearch('');
    this.commentsForm.controls['populationId'].enable();
    this.commentsForm.controls['commentsKey'].enable();
  }

  clearComments(){
    this.commentsForm.reset();
    this.isAnalysis = true;
    this.displayResult = false;
    this.staticComments = '';
    this.commentsForm.patchValue({
      analysisOrAction : 'analysis'
    });
    this.commentsForm.controls['commentsValue'].disable();
    this.populationSearchInput = '';
    this.commentKeySearchInput = '';
    this.clearPopulationData();
    this.clearCommentKey();
    this.commentsForm.controls['populationId'].disable();
    this.commentsForm.controls['commentsKey'].disable();
    this.alertService.clear();
  }

  updateComments(){
    let data = this.commentsForm.getRawValue();
    if(this.isAnalysis){
      data['analysisComment'] = true;
    }else{
      data['actionComment'] = true;
    }
    this.masterDataManagementService.updateCommentValue(data).subscribe(response =>{
        if (response.statusCode == "1") {
          this.successMessage=response.statusMessage;
          this.successModal=true;
          this.fetchedComment.commentValue.commentValue = this.commentsForm.controls['commentsValue'].value;
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.saveCommentModal = false;
      }, err => {
        this.alertService.error(err);
        this.saveCommentModal = false; 
        this.scrollToTop = true;
      });
  }

  getCommentsDropdownData(){
    let data={ 
      "userId": this.sharedService.getUserId()
    }
    this.masterDataManagementService.getCommentDropdown(data).subscribe(response =>{              
        if (response.statusCode == "1"){
          this.populationMap = response.populationMap;
          this.analysisCommentMap = response.analysisCommentMap;
          this.actionCommentMap = response.actionCommentMap;  
          this.fluidTypeList = response.fluidTypeList
           
          this.populationDataSearch('');
          this.commentKeySearch('');
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });  
  }

  getCommentsKeyList(){
    if(this.isAnalysis){
      return this.analysisCommentList;
    }else{
      return this.actionCommentList;
    }
  }

  /*getCommentsKey(item){
    if(this.isAnalysis){
      return item.analysisCommentKey;
    }else{
      return item;
    }
  }*/

  /*getCommentsKeyName(item){
    if(this.isAnalysis){
      return item.analysisCommentKeyName;
    }else{
      return item;
    }
  }  */

  openEditCommentsModal(){   
    this.saveCommentModal = true;
  }

  closeEditCommentsModal(){
    this.saveCommentModal = false;
  }

  commentInputChange(){
    this.displayResult = false;
    this.commentsForm.controls['commentsValue'].disable();
    this.commentsForm.patchValue({
      commentsValue:''
    });
  }

  resetCommentValue(){
    this.commentsForm.patchValue({
      commentsValue : this.fetchedComment.commentValue.commentValue ? this.fetchedComment.commentValue.commentValue.toUpperCase() : this.fetchedComment.commentValue.commentValue
    });
  }

  populationDataSearch(value: string){
    let filter = value.toLowerCase();
    this.populationSearchData = [];
    this.populationList.forEach(row => {
      if (row.populationName.toLowerCase().indexOf(filter) >= 0) {
        this.populationSearchData.push(row);
      }
    });
    if(this.populationSearchData.length == 0){
      this.populationSearchData.push({"populationId":"","populationName":"Select"});
    }
  }

  clearPopulationData(){
    this.populationSearchData = [];
    this.populationList.forEach(row => {      
      this.populationSearchData.push(row);     
    });
  }

  commentKeySearch(value: string){
    let filter = value.toLowerCase();
    this.commentKeySearchData = [];
    this.getCommentsKeyList().forEach(row => {
      if (row.commentKeyName.toLowerCase().indexOf(filter) >= 0) {
        this.commentKeySearchData.push(row);
      }         
    });
    if(this.commentKeySearchData.length == 0){
      this.commentKeySearchData.push({"commentKey":"","commentKeyName":"Select"});
    }
  }

  clearCommentKey(){
    this.commentKeySearchData = [];
    this.getCommentsKeyList().forEach(row => {      
      this.commentKeySearchData.push(row);     
    });
  }
  onKeycomments(val){
    if(val.toLowerCase().indexOf('cl-') >= 0 ){
      this.commentsForm.patchValue({
        commentsValue :val.replace('cl-','Cl\u207B').replace('Cl-','Cl\u207B').replace('CL-','Cl\u207B').replace('cL-','Cl\u207B')
      });
    } 
  
    if (val.toLowerCase().indexOf('f-') >= 0){
      this.commentsForm.patchValue({
      commentsValue : val.replace('f-', 'F\u207B').replace('F-', 'F\u207B')
      });
    }
   
    if (val.toLowerCase().indexOf('br-') >= 0){
      this.commentsForm.patchValue({
      commentsValue : val.replace('br-', 'Br\u207B').replace('Br-', 'Br\u207B').replace('BR-', 'Br\u207B').replace('bR-', 'Br\u207B')
      });
    }
  }
}

