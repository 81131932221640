export class RegisterSampleModel {
  sampleId: string;
  orderId: string;
  analysisType: string;
  testLevel: string;
  status: string;
  recordAdded: boolean = false;
  companyId: any[];
  fluidType: string;
  qrCodeId: string;
  companyName: string;
  userRole:string;  
}
