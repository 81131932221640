import { ChangeDetectionStrategy, Component, OnInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';
import { MatInput } from '@angular/material/input';
import {CalendarService } from './calendar.service';
import { AlertService } from '../../common/alert/alert.service';
import { SharedService } from '../../service/shared.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { common, warnMessage } from '../../common/constants/constant';
import { CommonHandlerService } from '../../handlers/common-handler.service';


@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalendarComponent implements OnInit {

    days: any[];
    daysSelected: any[] = [];
    tableDaySelected: any[] = [];
    storeDayValue: any;
    from = dayjs().startOf('year');
    to = dayjs().endOf('year');
    event: any;
    deleteCalendarModal = false;
    successMessage: string = '';
    scrollToTop =  false; 
    calendarResponseData: any = [];    
    calendarTableResData: any = [];
    loader: boolean;
    holidayList: any = [];
    allHolidayCalendarList: any = [];

       /* ------Pagination variables declaration--------- */
       pageSizeOptions: any[] = common.pageSizeOptions;
       pageSize = "10";
       currentPage = 1;
       showRecords = 0;
       maxPage = 0;
       totalItems: any;
       changePage: any = 1;
       rowDetails: any;
       sortingOrder: string;
       columnName: string;
       searchOption: any;
       
    @ViewChildren(MatInput, { read: ElementRef }) inputs: QueryList<ElementRef>;

    constructor(private datePipe: DatePipe, 
      private commonHandler: CommonHandlerService, 
      private calendarService: CalendarService, 
      private alertService: AlertService,  
      private sharedService: SharedService,
      private ref: ChangeDetectorRef ) {

    }

    
    ngOnInit(): void {
      this.getHolidayCalendar();
    }

    ngAfterViewChecked(){    
      if(this.scrollToTop)  {
        let element = document.querySelector('#alertDiv');
        if(element != null){        
          element.scrollIntoView({behavior: 'smooth', block:'center'});
          element = null;
        }
        this.scrollToTop = false;
      }
    }
    

    onForward(): void {
        this.from = this.from.add(12, 'month');
        this.to = this.to.add(12, 'month');
        this.getHolidayCalendar();
    }

    onBack(): void {
        this.from = this.from.add(-12, 'month');
        this.to = this.to.add(-12, 'month');
        this.getHolidayCalendar();
    }


    getHolidayCalendar(){
      this.daysSelected = [];
      this.tableDaySelected =[];
      this.days = [];

      let req = {
        startDate: this.from.format('YYYY-MM-DD'),
        endDate: this.to.format('YYYY-MM-DD'),
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
      };

      this.calendarService.getHolidayCalendarDetails(req).subscribe(response => {
          if (response.statusCode == "1") {            
            this.calendarResponseData = response.allHolidayCalendarList;
            this.calendarTableResData = response.holidayCalendarList;
           // console.log(this.calendarTableResData, 'holidayres')
            if(this.calendarTableResData.length > 0){
              this.calendarTableResData.forEach(row => {
                this.tableDaySelected.push({
                  'days': dayjs(row.holidayDate).format('MM/DD/YYYY'),                  
                  'description': row.description,
                  'isWeekend': 'N', 
                  'mode': 'read', 
                  'date': dayjs(row.holidayDate), 
                  'dbDate':row.holidayDate 
                })
              });
            }
          //  console.log(this.tableDaySelected.length)
            if(this.calendarResponseData.length > 0){
              this.calendarResponseData.forEach(row => {
                if(row.isWeekend == 'N'){
                  this.daysSelected.push({ 
                  'days': dayjs(row.holidayDate).format('MM/DD/YYYY'),                  
                  'description': row.description, 
                  'isWeekend': 'N', 
                  'mode': 'read', 
                  'date': dayjs(row.holidayDate), 
                  'dbDate':row.holidayDate 
                });

                }
                 
                              
              });
             // console.log(this.daysSelected.length);
              this.totalItems = this.daysSelected.length;
              this.calculateOffset();
              
              this.loadClass(); 
              this.ref.markForCheck();                      
            }  
          } else {
            //this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.alertService.error(err);
        }
      );
    }

    editTableRow(val) {
        if (val.mode == 'read') {
            val.mode = 'edit';
        } else {
            val.mode = 'read';
        }
    }

    onDayClick(event: any) {
        let selectedDate = event.date.$d;
        let newDate = this.datePipe.transform(
            selectedDate, 'MM/dd/yyyy'
        );
      
        let dbDate = event.date.format('YYYY-MM-DD');
        const index = this.daysSelected.findIndex(x => x.days == newDate);
        const dayIndex = this.tableDaySelected.findIndex(x => x.days == newDate);
        if (index < 0) this.daysSelected.push({ 'days': newDate, 'description': '', 'isWeekend': 'N', 'mode': 'read', 'date': event.date, 'dbDate':dbDate });
        else this.daysSelected.splice(index, 1);
        if (dayIndex < 0) this.tableDaySelected.push({ 'days': newDate, 'description': '', 'isWeekend': 'N', 'mode': 'read', 'date': event.date, 'dbDate':dbDate });
        else this.tableDaySelected.splice(dayIndex, 1);
        this.loadClass();        
    }



    openCalendarDeleteModal(day) {
        this.storeDayValue = day;
        this.deleteCalendarModal = true;
    }
    closeDeleteCalendarModal() {
        this.deleteCalendarModal = false;
    }

    deleteCalendarRecord() {
        const index = this.tableDaySelected.findIndex(x => x.days == this.storeDayValue.days);
        const dayIndex = this.daysSelected.findIndex(x =>  x.days == this.storeDayValue.days);
        if (index >= 0) {
            this.tableDaySelected.splice(index, 1);
            this.daysSelected.splice(dayIndex, 1);
        }
        this.loadClass();        
        this.deleteCalendarModal = false;
    }

    loadClass(){
        this.days = [];             
        this.daysSelected.forEach(row => {
            this.days.push({ date: row.date, description: row.description,  class: 'red' });
        });
        this.tableDaySelected.forEach(row => {
          this.days.push({ date: row.date, description: row.description,  class: 'red' });
      });
    }

    // insert data to db
    onSubmit() {
      this.holidayList = [];  
      this.tableDaySelected.forEach(row => {
        this.daysSelected.forEach(dayrow => {
          if(row.days == dayrow.days){
            dayrow.description = row.description;
          }
        });
      });

     // this.tableDaySelected =[];  
      this.daysSelected.forEach(row => {
        this.holidayList.push({holidayDate: row.dbDate, isWeekend: row.isWeekend, description: row.description });
  });
    

      let data = {
          "startDate": this.from.format('YYYY-MM-DD'),
          "endDate": this.to.format('YYYY-MM-DD'),
          "userId": this.sharedService.getUserId(),
          "holidayList": this.holidayList
         // "allHolidayCalendarList": this.holidayList
      }
      // console.log(this.holidayList, 'allHolidayCalendarList');

      this.calendarService.insertHolidayList(data).subscribe(response =>{
        this.getHolidayCalendar();
        if (response.statusCode == "1") {
           this.successMessage=response.statusMessage;
          this.alertService.success(response.statusMessage);
          this.scrollToTop = true;
         
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }    
      },err => {
        this.alertService.error(err);        
        this.scrollToTop = true;
      });
      this.totalItems = this.daysSelected.length;
      this.calculateOffset();
      
    
    }


      // Pagination
  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

    pageSizeChange(val) {
      this.pageSize = val;  
      this.currentPage = 1;
      this.changePage = 1;
      this.loadPage(1);
    }
    jumpToPage() {
      if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
        if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {   
          this.currentPage = this.changePage;
          this.loadPage(this.currentPage);
        } else {
          this.changePage = this.currentPage;
          this.alertService.warn(warnMessage.paginatorNotAvail);
          this.scrollToTop = true;
        }
      }
    }
    
    loadPage(page: any) {
      this.currentPage = page;
      this.changePage = page;  
      this.getHolidayCalendar();    
    }  
  }
    
