import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { DatePipe } from '@angular/common';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { OpsTotReportService } from './ops-tot-report.service';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-ops-tot-report',
  templateUrl: './ops-tot-report.component.html',
  styleUrls: ['./ops-tot-report.component.css']
})
export class OpsTotReportComponent implements OnInit, AfterViewChecked {

  @ViewChildren(SortComponent) sortComponents;

  isSearchDone: boolean = false;
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  reportData: any = [];
  reportavgData: any = [];
  opsReportCountData: any = [];
  reportCountData: any = [];

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  scrollToTop: any;
  searchObj: any;

  filterObject = {
    sampleId: [],
    companyId: [],
    companyName: [],
    unitId: [],
    esn: [],
    sampledDate: [],
    deliveryTime: [],
    shipmentTime: [],
    sampleProcessingTime: [],
    labProcessingTime: [],
    totalSampleProcessingTime: [],
    rerun: []
  };

  reportCols = [
    { code: 'sampleId', value: 'Sample ID' },
    { code: 'companyId', value: 'Company ID' },
    { code: 'companyName', value: 'Company Name' },
    { code: 'unitId', value: 'Unit ID' },
    { code: 'esn', value: 'ESN' },
    { code: 'sampledDate', value: 'Sampled Date' },
    { code: 'deliveryTime', value: 'Delivery Time' },
    { code: 'shipmentTime', value: 'Shipment Time' },
    { code: 'sampleProcessingTime', value: 'Sample Processing Time' },
    { code: 'labProcessingTime', value: 'Laboratory Processing Time' },
    { code: 'totalSampleProcessingTime', value: 'Total Sample Processing Time' },
    { code: 'rerun', value: 'Rerun' }
  ];



  inputForm = new UntypedFormGroup({
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required])
  });

  searchForm = new UntypedFormGroup({
    sampleId: new UntypedFormControl(''),
    companyId: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    unitId: new UntypedFormControl(''),
    esn: new UntypedFormControl(''),
    sampledDate: new UntypedFormControl(''),
    deliveryTime: new UntypedFormControl(''),
    shipmentTime: new UntypedFormControl(''),
    sampleProcessingTime: new UntypedFormControl(''),
    labProcessingTime: new UntypedFormControl(''),
    totalSampleProcessingTime: new UntypedFormControl(''),
    rerun: new UntypedFormControl('')
  });
  rerunList = [{'rerun':'2','rerunName':'Yes'}, {'rerun': '1' ,'rerunName':'No'}];

  constructor(private commonHandler: CommonHandlerService,
    private alertService: AlertService,
    private opsReportService: OpsTotReportService,
    private datePipe: DatePipe,private sharedService: SharedService) { }

  ngOnInit() {
    this.columnName = 'sample_id';
    this.sortingOrder = 'desc';
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    this.searchForm.patchValue({ rerun: 'No' });
    // this.getOpsTotReportData();

  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getOpsTotReportData() {
    let inputData = this.searchForm.getRawValue();
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder,
      "startDate": this.inputForm.value.startDate.toLocaleDateString(),
      "endDate": this.inputForm.value.endDate.toLocaleDateString(),
    };

    Object.assign(inputData, this.searchObj);

    inputData['startDate'] = this.datePipe.transform(this.inputForm.controls.startDate.value, 'yyyy-MM-dd');
    inputData['endDate'] = this.datePipe.transform(this.inputForm.controls.endDate.value, 'yyyy-MM-dd');;
    inputData['columnName'] = this.getSortColumn(inputData['columnName']);    
    this.opsReportService.getOpsTotReportData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.reportData = response.reportListValues;
        this.reportavgData = response.reportAvgList;
        this.opsReportCountData = response.opsReportCountList;
        this.reportCountData = response.opsReportCountList;
        this.totalItems = response.maxRow;
        this.filterObject.rerun = this.rerunList;
        this.calculateOffset();       
        this.isSearchDone = true;
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
    /*this.isSearchDone = true;
    this.totalItems = 5;
    this.calculateOffset();*/
  }

  optionSearch(){
    this.currentPage = 1;
    this.getOpsTotReportData();
  }
  getColumnCode(str) {
    if (str === 'rerun') {
      return 'rerunName';
    } else {
      return str;
    }
  }
  searchFilter(str) {
    let inputData = this.searchForm.getRawValue();
    inputData['searchColumn'] = str;
    inputData['searchValue'] = this.searchForm.value[str];   

    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);

    inputData['startDate'] = this.datePipe.transform(this.inputForm.controls.startDate.value, 'yyyy-MM-dd');
    inputData['endDate'] = this.datePipe.transform(this.inputForm.controls.endDate.value, 'yyyy-MM-dd');;
    inputData['searchColumn'] = this.getSortColumn(str);
    if(inputData['searchValue'].length >= 2){
      this.opsReportService.getOpsTotReportData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.filterObject[str] = response.distinctValuesList;
      } else {
        this.filterObject[str] = [];
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.filterObject.rerun = this.rerunList;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.filterObject.rerun = this.rerunList;
      this.searchForm.patchValue({ [str]: '' });
      this.getOpsTotReportData();
    }
  }

  startDateChange() {
    this.minEndDate = this.inputForm.value.startDate;
  }

  endDateChange() {
    this.maxStartDate = this.inputForm.value.endDate;
  }

  exportToExcel() {
    let inputData = this.searchForm.getRawValue();
    this.searchObj = {
      pageSize: 50000,
      currentPage: 1,
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);
    inputData['startDate'] = this.datePipe.transform(this.inputForm.controls.startDate.value, 'yyyy-MM-dd');
    inputData['endDate'] = this.datePipe.transform(this.inputForm.controls.endDate.value, 'yyyy-MM-dd');;
    inputData['action'] = "EMAIL";
    inputData['userId'] = this.sharedService.getUserId();
    inputData['emailId'] = this.sharedService.getUserInformation().email;  
    inputData['userName'] = this.sharedService.getUserInformation().firstName + " " +
      this.sharedService.getUserInformation().lastName ;    
    this.opsReportService.getOpsTotReportData(inputData).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.alertService.success(response.statusMessage);
          this.scrollToTop = true;

        } else {
          this.alertService.error(response.statusMessage);   
          this.scrollToTop = true;
      
        }     
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );

  }

  clearInput(){
    this.inputForm.reset();
    this.searchForm.reset();
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    this.isSearchDone = false;
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.reportData = [];
    this.reportCountData = [];
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.reportData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        // this.scrollToTop = true;
      }
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.getOpsTotReportData();
  }

  /**
* Calls search method to sort the data based on columnName
* @param columnName 
*/
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';

    this.getOpsTotReportData();
  }
  fluidTypeSelect(fluidTypeName){
    this.searchForm.patchValue({
      fluidTypeName: fluidTypeName,
      status: 'No'
    });
    this.getOpsTotReportData();
  }

  isSearchable(column) {
    if(column == 'sampleId' || column == 'companyId' || column == 'companyName' || 
      column == 'unitId' || column == 'esn' || column =='rerun'){
      return true;
    }else{
      return false;
    }
  }

  getSortColumn(column):any{
    let columnName = "sample_id";
    if(column == 'sampleId'){
      columnName = "sample_id";
    }else if(column == 'unitId'){
      columnName = "unit_id";
    }else if(column == 'companyId'){
      columnName = "company_id";
    }else if(column == 'companyName'){
      columnName = "company_name";
    }else if(column == 'esn'){
      columnName = "esn";
    }else if(column == 'sampledDate'){
      columnName = "sampled_date";
    }else if(column == 'Rerun'){
      columnName = "rerun";
    }
    return columnName;
  }

}
