import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management.service';
import { common, warnMessage } from '../../../common/constants/constant';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';

@Component({
  selector: 'app-fluid-filter-info',
  templateUrl: './fluid-filter-info.component.html',
  styleUrls: ['./fluid-filter-info.component.css']
})
export class FluidFilterInfoComponent implements OnInit,AfterViewChecked {

  @ViewChildren(SortComponent) sortComponents;

  searchObj:any; 
  scrollToTop =  false;   
  isFluid = true;
  isNewData =  false;
  addModal = false;
  editModal = false;
  deleteModal = false;
  successModal = false;
  displayResult = false;   
  successMessage: string = '';  
  selectedGroup:UntypedFormGroup;
  isFuelSelected = false;
  

  fluidColumns = [
    { code: 'fluidType', name: 'Fluid Type' },
    { code: 'fluidBrand', name: 'Fluid Brand' },
    { code: 'fluidProduct', name: 'Fluid Product' },
    { code: 'fluidGrade', name: 'Fluid Grade' },
    { code: 'action', name: 'Actions' }
  ];

  filterColumns = [
    { code: 'fluidType', name: 'Fluid Type' },
    { code: 'filterBrand', name: 'Filter Brand' },
    { code: 'filterProduct', name: 'Filter Product' },
    { code: 'action', name: 'Actions' }
  ];

  fluidTypeData=[
    { name : 'Engine Oil (Liquid Fuel)' , id: 1 },
    { name : 'Engine Oil (Gaseous Fuel)' , id: 2 },
    { name : 'Coolant' , id: 3 },
    { name : 'Diesel Fuel' , id: 4 },
    { name : 'Diesel Exhaust Fluid' , id: 5 },
    { name : 'Lubricants' , id: 6 },

  ];

  filterTypeData=[
    { name : 'Engine Oil (Liquid Fuel)' , id: 1 },
    { name : 'Engine Oil (Gaseous Fuel)' , id: 2 },
    { name : 'Coolant', id: 3},
    { name : 'Diesel Fuel' , id: 4 },
    { name : 'Diesel Exhaust Fluid' , id: 5 },
    { name : 'Lubricants' , id: 6 }
  ];

  fluidFilterForm = new UntypedFormGroup({
    fluidOrFilter: new UntypedFormControl('fluid',[Validators.required]),
    fluidType: new UntypedFormControl('',[Validators.required]),
    fluidBrand: new UntypedFormControl({value: '', disabled: true}),
    fluidProduct: new UntypedFormControl({value: '', disabled: true}),
    fluidGrade: new UntypedFormControl({value: '', disabled: true}),
    filterBrand: new UntypedFormControl({value: '', disabled: true}),
    filterProduct: new UntypedFormControl({value: '', disabled: true}),
    userId: new UntypedFormControl(this.sharedService.getUserId())        
  });

  addFluidFilterForm = new UntypedFormGroup({    
    fluidBrand: new UntypedFormControl(''),
    fluidProduct: new UntypedFormControl(''),
    fluidGrade: new UntypedFormControl(''),
    filterBrand: new UntypedFormControl(''),
    filterProduct: new UntypedFormControl(''),
    userId: new UntypedFormControl(this.sharedService.getUserId())   
  });

  fluidSearchForm = new UntypedFormGroup({
    fluidBrand: new UntypedFormControl(''),
    fluidProduct: new UntypedFormControl(''),
    fluidGrade: new UntypedFormControl('')
  });

  filterSearchForm = new UntypedFormGroup({
    filterBrand: new UntypedFormControl(''),
    filterProduct: new UntypedFormControl('')
  });  

  fluidFilterFormArray = new UntypedFormArray([]);

  fluidTypeList = [];
  fluidBrandList = [];
  fluidProductList = [];
  fluidGradeList = [];
  filterBrandList = [];
  filterProductList = [];
  fluidFilterData:any;
  fluidFilterSearchData =[];
  fluidFilterColumn = [];  

  //dropdown search
  fluidBrandSearchData = [];
  fluidProductSearchData = [];
  fluidGradeSearchData = [];
  filterBrandSearchData = [];
  filterProductSearchData = [];

  fluidBrandSearchInput = '';
  fluidProductSearchInput = '';
  fluidGradeSearchInput = '';
  filterBrandSearchInput = '';
  filterProductSearchInput = '';


  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;  

  constructor(private masterDataManagementService:MasterDataManagementService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService) {     
  }

  ngOnInit() {
    this.fluidTypeList = this.fluidTypeData;
    this.fluidFilterColumn = this.fluidColumns;
    this.columnName = 'fluidBrand';
    this.sortingOrder = 'desc';
    this.getFilterFluidData();
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  getFilterFluidData(){
    let data= {
      "userId": this.sharedService.getUserId()
    }
    this.masterDataManagementService.getFluidFilterData(data).subscribe(response =>{      
      this.fluidFilterData = response; 
      this.inputChange();     
    });
  }

  switchToFluid(){
    this.fluidFilterColumn = this.fluidColumns;
    this.columnName = 'fluidBrand';
    this.fluidTypeList = this.fluidTypeData;
    this.isFluid = true;    
    this.fluidFilterForm.reset();
    this.inputChange();
    this.fluidFilterSearchData = [];
    this.displayResult = false;
    this.fluidFilterForm.controls['fluidBrand'].disable();
    this.fluidFilterForm.controls['fluidProduct'].disable();
    this.fluidFilterForm.controls['fluidGrade'].disable(); 
    this.fluidBrandSearchInput = '';
    this.fluidProductSearchInput = '';
    this.fluidGradeSearchInput = '';  
    this.isFuelSelected = false;    
  }

  switchToFilter(){
    this.fluidFilterColumn = this.filterColumns;
    this.columnName = 'filterBrand';
    this.fluidTypeList = this.filterTypeData;
    this.isFluid = false;    
    this.fluidFilterForm.reset();
    this.inputChange();
    this.fluidFilterSearchData = [];
    this.displayResult = false;
    this.fluidFilterForm.controls['filterBrand'].disable();
    this.fluidFilterForm.controls['filterProduct'].disable();
    this.filterBrandSearchInput = '';
    this.filterProductSearchInput = ''; 
    this.isFuelSelected = false;
  }

  getFluidTypeName():any{
    let fluidName = '';
    this.fluidTypeData.forEach((row) =>{
      if(row.id == this.fluidFilterForm.controls.fluidType.value){
        fluidName = row.name;
      }
    });
    return fluidName;
  }

  inputChange(){    
    let fluidName = '';
    if(this.fluidFilterForm.controls.fluidType.value != '' && this.fluidFilterForm.controls.fluidType.value != null){
      fluidName = this.getFluidTypeName();
    }else{
      return false;
    }
    //Set Fluid Dropdown
    if(this.isFluid){ 
      let fluidResult =[];     
      this.resetFluidList();
      
      let fluidList = this.fluidFilterData.fluidMap[fluidName];
      fluidList.forEach((row) =>{
        if(this.fluidFilterForm.controls.fluidBrand.value == '' || this.fluidFilterForm.controls.fluidBrand.value == row.fluidBrand ||
            this.fluidFilterForm.controls.fluidBrand.value == null){
          if(this.fluidFilterForm.controls.fluidProduct.value == '' || this.fluidFilterForm.controls.fluidProduct.value == row.fluidProduct ||
             this.fluidFilterForm.controls.fluidProduct.value == null){
            if(this.fluidFilterForm.controls.fluidGrade.value == '' || this.fluidFilterForm.controls.fluidGrade.value == row.fluidGrade ||
                this.fluidFilterForm.controls.fluidGrade.value == null){
                  fluidResult.push(row);
            }
          }
        }
      });

      fluidResult.forEach((row) => {
        this.fluidBrandList.push(row.fluidBrand);
        this.fluidProductList.push(row.fluidProduct);
        this.fluidGradeList.push(row.fluidGrade);
      });
      this.fluidBrandList = Array.from(new Set(this.fluidBrandList));
      this.fluidProductList = Array.from(new Set(this.fluidProductList));
      this.fluidGradeList = Array.from(new Set(this.fluidGradeList));

      this.fluidBrandDataSearch('');
      this.fluidProductDataSearch('');
      this.fluidGradeDataSearch('');
      this.clearFluidBrandData();
      this.clearFluidProductData();
      this.clearFluidGradeData();
    }else{
      let filterResult =[];     
      this.resetFilterList();
      
      let filterList = this.fluidFilterData.filterMap[fluidName];
      filterList.forEach((row) =>{
        if(this.fluidFilterForm.controls.filterBrand.value == '' || this.fluidFilterForm.controls.filterBrand.value == row.filterBrand ||
            this.fluidFilterForm.controls.filterBrand.value == null){
          if(this.fluidFilterForm.controls.filterProduct.value == '' || this.fluidFilterForm.controls.filterProduct.value == row.filterProduct ||
              this.fluidFilterForm.controls.filterProduct.value == null){
                filterResult.push(row);
          }
        }
      });

      filterResult.forEach((row) => {
        this.filterBrandList.push(row.filterBrand);
        this.filterProductList.push(row.filterProduct);        
      });
      this.filterBrandList = Array.from(new Set(this.filterBrandList));
      this.filterProductList = Array.from(new Set(this.filterProductList));

      this.filterBrandDataSearch('');
      this.filterProductDataSearch('');
      this.clearFilterBrandData();
      this.clearFilterProductData();
    }
  }

  searchFluidFilter(){
    this.currentPage = 1;
    this.isNewData = false;
    this.searchFluidFilterData();
  }

  searchFluidFilterData(){
    let data = this.fluidFilterForm.getRawValue();
    if(this.isFluid){
      data['fluid'] = true;
    }else{
      data['filter'] = true;
    }
    data['pageSize'] = this.pageSize.toString();
    data['currentPage'] = this.currentPage;
    data['columnName'] = this.columnName;
    data['sortingOrder'] = this.sortingOrder;

    this.searchObj = data;
    
    this.masterDataManagementService.searchFluidFilterData(data).subscribe(response =>{        
        if (response.statusCode == "1") {
          this.fluidFilterSearchData = response.searchData;   
          //set Fluid Type
          let fluidName = this.getFluidTypeName();
          this.fluidFilterSearchData.forEach((row) => {
            row['fluidType'] = fluidName;
            //row['isEdit'] = false;            
          });       
          this.totalItems = response.searchData[0].maxRow;
          this.calculateOffset();
          this.displayResult = true;


          //form Array creation
          this.fluidFilterFormArray = new UntypedFormArray([]);
          this.fluidFilterSearchData.forEach((row) => {
            if(this.isFluid){
              const formGroup = new UntypedFormGroup({
                fluid: new UntypedFormControl(true),
                fluidType: new UntypedFormControl(row.fluidType),
                fluidId: new UntypedFormControl(row.fluidId),
                fluidBrand: new UntypedFormControl(row.fluidBrand,[Validators.required]),
                fluidProduct: new UntypedFormControl(row.fluidProduct,[Validators.required]),
                fluidGrade: new UntypedFormControl(row.fluidGrade,[Validators.required]),
                userId: new UntypedFormControl(this.sharedService.getUserId()),
                isEdit: new UntypedFormControl(false)                
              });
              this.fluidFilterFormArray.push(formGroup);
            }else{
              const formGroup = new UntypedFormGroup({
                filter: new UntypedFormControl(true),
                fluidType: new UntypedFormControl(row.fluidType),
                filterId: new UntypedFormControl(row.filterId),
                filterBrand: new UntypedFormControl(row.filterBrand,[Validators.required]),
                filterProduct: new UntypedFormControl(row.filterProduct,[Validators.required]),
                userId: new UntypedFormControl(this.sharedService.getUserId()),
                isEdit: new UntypedFormControl(false)
              });
              this.fluidFilterFormArray.push(formGroup);
            }            
          });
          
        }else {          
          //this.clearFluidFilterData();
          this.displayResult = true;
          this.fluidFilterSearchData = [];
          this.fluidFilterFormArray = new UntypedFormArray([]);
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
  }

  clearFluidFilterData(){
    this.switchToFluid();    
    this.fluidFilterForm.patchValue({
      fluidOrFilter : 'fluid'
    });    
    this.resetFluidList();
    this.resetFilterList();
    this.fluidFilterSearchData = [];
    this.displayResult = false;
    this.addFluidFilterForm.reset();
    this.fluidFilterForm.controls['fluidBrand'].disable();
    this.fluidFilterForm.controls['fluidProduct'].disable();
    this.fluidFilterForm.controls['fluidGrade'].disable();
    this.fluidFilterForm.controls['filterBrand'].disable();
    this.fluidFilterForm.controls['filterProduct'].disable();
    this.fluidBrandSearchInput = '';
    this.fluidProductSearchInput = '';
    this.fluidGradeSearchInput = '';
    this.filterBrandSearchInput = '';
    this.filterProductSearchInput = '';
    this.isFuelSelected = false;
    this.alertService.clear();
  }

  resetFluidList(){
    this.fluidBrandList = [];
    this.fluidProductList = [];
    this.fluidGradeList = [];
  }

  resetFilterList(){
    this.filterBrandList = [];
    this.filterProductList = [];    
  }

  addNewData(){
    this.isNewData = true;
    if(this.isFluid){
      this.addFluidFilterForm.controls['fluidBrand'].setValidators([Validators.required]);
      this.addFluidFilterForm.controls['fluidProduct'].setValidators([Validators.required]);
      this.addFluidFilterForm.controls['fluidGrade'].setValidators([Validators.required]);

      this.addFluidFilterForm.controls['filterBrand'].clearValidators();
      this.addFluidFilterForm.controls['filterProduct'].clearValidators();
    }else{
      this.addFluidFilterForm.controls['filterBrand'].setValidators([Validators.required]);
      this.addFluidFilterForm.controls['filterProduct'].setValidators([Validators.required]);

      this.addFluidFilterForm.controls['fluidBrand'].clearValidators();
      this.addFluidFilterForm.controls['fluidProduct'].clearValidators();
      this.addFluidFilterForm.controls['fluidGrade'].clearValidators();
    }

    if(this.isFuelSelected){
      this.addFluidFilterForm.controls['fluidBrand'].clearValidators();
      this.addFluidFilterForm.controls['fluidProduct'].clearValidators();
    }
    
  }

  hideNewData(){
    this.isNewData = false;
  }

  openAddModal(){
    if(!this.addFluidFilterForm.invalid){
      this.addModal = true;
    }else{
      this.addModal = false;      
      Object.keys(this.addFluidFilterForm.controls).forEach(field => { 
        const control = this.addFluidFilterForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  closeAddModal(){
    this.addModal = false;
  }

  closeEditModal(){
    this.editModal = false;
  }

  closeDeleteModal(){
    this.deleteModal = false;
  }

  deleteRecord(){    
    let data = {};
    if(this.isFluid){
       data['fluidId'] = this.selectedGroup.controls.fluidId.value;        
    }else{
      data['filterId'] = this.selectedGroup.controls.filterId.value;      
    }
    this.masterDataManagementService.deleteFluidFilterData(data).subscribe(response => {       
        if (response.statusCode == "1"){         
         this.successMessage = response.statusMessage;
         this.successModal = true;
         this.searchFluidFilterData();
         this.getFilterFluidData();        
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.deleteModal = false;
      }, err => {
        this.alertService.error(err);
        this.deleteModal = false;
        this.scrollToTop = true;
      }); 
  }

  saveEdit(){        
    let data = this.selectedGroup.getRawValue();
    this.masterDataManagementService.saveFluidFilterData(data).subscribe(response =>{       
        if (response.statusCode == "1") {         
         this.successMessage = response.statusMessage;
         this.successModal = true; 
         this.selectedGroup.patchValue({
          isEdit: false
          });
         this.getFilterFluidData();          
        }else {
          this.alertService.error(response.statusMessage);
          this.scrollToTop = true;
        }
        this.editModal = false; 
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
  }

  addNewFluidFilterData(){
    let data=this.addFluidFilterForm.getRawValue();

 	if(this.fluidFilterForm.controls.fluidType.value == '4' && this.isFluid == true){
     data['fluidBrand'] = 'NA';
     data['fluidProduct'] = 'NA';
    }

    if(this.isFluid){
      data['fluid'] = true;
    }else{
      data['filter'] = true;
    }
    data['userId'] = this.sharedService.getUserId();
    data['fluidType'] = this.fluidFilterForm.controls['fluidType'].value;
    this.masterDataManagementService.saveFluidFilterData(data).subscribe(response => {
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;        
        this.isNewData = false;
        this.addFluidFilterForm.reset();
        this.searchFluidFilterData();
        this.getFilterFluidData();
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.addModal = false;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addModal = false;
    });
  }

  doEdit(group){
    group.patchValue({
      isEdit: true
    });
  }

  openDeleteModal(group){
    this.selectedGroup = group;
    this.deleteModal = true;
  }

  cancelEdit(group,i){
    if(this.isFluid){
      group.patchValue({
        fluidBrand: this.fluidFilterSearchData[i].fluidBrand,
        fluidProduct: this.fluidFilterSearchData[i].fluidProduct,
        fluidGrade: this.fluidFilterSearchData[i].fluidGrade,
        isEdit: false
      }); 
    }else{
      group.patchValue({
        filterBrand: this.fluidFilterSearchData[i].filterBrand,
        filterProduct: this.fluidFilterSearchData[i].filterProduct,
        isEdit: false
      }); 
    }       
  }

  openEditModal(group){
    if(group.invalid){
      return false;
    }
    this.selectedGroup = group;
    this.editModal = true;
  }

  fluidTypeChange(){
    this.displayResult = false;
    this.fluidFilterForm.patchValue({      
      fluidBrand:'',
      fluidProduct:'',
      fluidGrade:'',
      filterBrand:'',
      filterProduct:''
    });
    this.fluidFilterForm.controls['fluidBrand'].enable();
    this.fluidFilterForm.controls['fluidProduct'].enable();
    this.fluidFilterForm.controls['fluidGrade'].enable();
    this.fluidFilterForm.controls['filterBrand'].enable();
    this.fluidFilterForm.controls['filterProduct'].enable();

     if(this.fluidFilterForm.controls.fluidType.value == '4' && this.isFluid == true){
      this.isFuelSelected = true;
      this.fluidFilterColumn = [
        { code: 'fluidType', name: 'Fluid Type' },        
        { code: 'fluidGrade', name: 'Diesel Fuel' },
        { code: 'action', name: 'Actions' }
      ];      
   
    }else if(!this.isFluid ){
      this.fluidFilterColumn= [
        { code: 'fluidType', name: 'Fluid Type' },
        { code: 'filterBrand', name: 'Filter Brand' },
        { code: 'filterProduct', name: 'Filter Product' },
        { code: 'action', name: 'Actions' }
      ];
    }else{
      this.isFuelSelected = false;
      this.fluidFilterColumn = [
        { code: 'fluidType', name: 'Fluid Type' },
        { code: 'fluidBrand', name: 'Fluid Brand' },
        { code: 'fluidProduct', name: 'Fluid Product' },
        { code: 'fluidGrade', name: 'Fluid Grade' },
        { code: 'action', name: 'Actions' }
      ];      
    }
  }

  fluidBrandChange(){
    this.displayResult = false;
  }

  fluidProductChange(){
    this.displayResult = false;
  }

  fluidGradeChange(){
    this.displayResult = false;
  }

  filterBrandChange(){
    this.displayResult = false;
  }

  filterProductChange(){
    this.displayResult = false;
  }

  // Pagination

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;    
    //this.fluidFilterSearchData =[];
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {        
        //this.fluidFilterSearchData =[];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

    /**
   * Calls search method to sort the data based on columnName
   * @param columnName 
   */
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.searchFluidFilterData();
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;  
    this.searchFluidFilterData();    
  }

  getArray(): any[] {
    return Array(this.maxPage);
  }

  fluidBrandDataSearch(value: string){
    let filter = value.toLowerCase();
    this.fluidBrandSearchData = [];
    this.fluidBrandList.forEach(row => {
      if (row.toLowerCase().indexOf(filter) >= 0) {
        this.fluidBrandSearchData.push(row);
      }
    });
  }

  clearFluidBrandData(){
    this.fluidBrandSearchData = [];
    this.fluidBrandList.forEach(row => {      
      this.fluidBrandSearchData.push(row);     
    });
  }

  fluidProductDataSearch(value: string){
    let filter = value.toLowerCase();
    this.fluidProductSearchData = [];
    this.fluidProductList.forEach(row => {
      if (row.toLowerCase().indexOf(filter) >= 0) {
        this.fluidProductSearchData.push(row);
      }
    });
  }

  clearFluidProductData(){
    this.fluidProductSearchData = [];
    this.fluidProductList.forEach(row => {      
      this.fluidProductSearchData.push(row);     
    });
  }

  fluidGradeDataSearch(value: string){
    let filter = value.toLowerCase();
    this.fluidGradeSearchData = [];
    this.fluidGradeList.forEach(row => {
      if (row.toLowerCase().indexOf(filter) >= 0) {
        this.fluidGradeSearchData.push(row);
      }
    });
  }

  clearFluidGradeData(){
    this.fluidGradeSearchData = [];
    this.fluidGradeList.forEach(row => {      
      this.fluidGradeSearchData.push(row);     
    });
  }

  filterBrandDataSearch(value: string){
    let filter = value.toLowerCase();
    this.filterBrandSearchData = [];
    this.filterBrandList.forEach(row => {
      if (row.toLowerCase().indexOf(filter) >= 0) {
        this.filterBrandSearchData.push(row);
      }
    });
  }

  clearFilterBrandData(){
    this.filterBrandSearchData = [];
    this.filterBrandList.forEach(row => {      
      this.filterBrandSearchData.push(row);     
    });
  }

  filterProductDataSearch(value: string){
    let filter = value.toLowerCase();
    this.filterProductSearchData = [];
    this.filterProductList.forEach(row => {
      if (row.toLowerCase().indexOf(filter) >= 0) {
        this.filterProductSearchData.push(row);
      }
    });
  }

  clearFilterProductData(){
    this.filterProductSearchData = [];
    this.filterProductList.forEach(row => {      
      this.filterProductSearchData.push(row);     
    });
  }

}
