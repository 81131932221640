import { RegisterSampleRequestModel } from './../../../model/registerSampleRequestModel';
import { RegisterSampleService } from './../../../service/register-sample.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SharedService } from 'src/app/View/service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { Result } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { local } from 'd3';
import { MatAutocomplete } from '@angular/material/autocomplete';


@Component({
  selector: 'app-register-sample-list',
  templateUrl: './register-sample-list.component.html',
  styleUrls: ['./register-sample-list.component.css']
})
export class RegisterSampleListComponent implements OnInit,AfterViewChecked,OnDestroy {

  readonly USERTYPE = USERTYPE;
  userRole: string = '';
  searchObj: any;
  infoDataforSearch: any;
  searchObjUserRole: string = '';
  status: string = '';
  statusDate: string = '';
  scanResult:any='';
  constraints: any = { width: { min: 640, ideal: 1920, max: 1920 }, height: { min: 400, ideal: 1080 }, aspectRatio: 1.777777778, frameRate: { max: 60 } };
  confirmModal: boolean = false;
  dataArray =new Array();
  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize;
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string = 'DESC';
  columnName: string = 'sampleId';
  registerSampleColumnsData: any;
  companyId: string = '';
  companyIdList: any[] = [];
  registerSampleColumns = [];
  scrollToTop = false;

  /* --------Grid Filter variable declaration------- */
  intUserFilterObject = {
    qrCodeId: [],
    companyId: [],
    companyName:[],
    sampleId: [],
    orderId: [],
    componentId: [],
    analysisType: [],
    testLevel: [],
    status: [],
    statusDate: []
  }

  extUserFilterObject = {
    qrCodeId: [],
    sampleId: [],
    orderId: [],
    componentId: [],
    analysisType: [],
    testLevel: [],
    status: [],
    statusDate: []
  }

  qrCodeForm = new UntypedFormGroup({
    qrCodeValue: new UntypedFormControl(''),
  })
  qrCodeFormSearch = new UntypedFormGroup({
    qrCodeValueForSearch: new UntypedFormControl(''),
  })

  sampleSearchForm = new UntypedFormGroup({
    qrCodeId: new UntypedFormControl(''),
    companyId: new UntypedFormControl(''),
    companyName:new UntypedFormControl(''),
    sampleId: new UntypedFormControl(''),
    orderId: new UntypedFormControl(''),
    componentId: new UntypedFormControl(''),
    analysisType: new UntypedFormControl(''),
    testLevel: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    statusDate: new UntypedFormControl('')
  });
  dbuCustomerNameDropdown: any
  companyName: any = '';
  messageDropdown: any;
  isFirstTime: boolean = true;
  dbuCompanyID: any = '';
  isPageSizeDisable: boolean = false;
  public qrCodehasValue= false;
  orderDetails: any;
  sampleIdYear: any;
  analysisTestLevelType: any;
  sampleIdFormat: any;
  QRSampleData: any;
  analysisTypeData: string;
  testLevelType: string;
  QrCodeHasError= false;
  serialNumber: any;
  fluidType: number;
  obj: { functionName: string; updatedBy: any; qrCodeId: any; companyId: string; companyName: any;};
  companyNameList: any;
  qrCodeId: any;
  confirmModalForQRCode: boolean = false;
  analysisTypeDataNew: any;
  showPopupForRegSample = true;
  QrCodeSearchHasError = false;
  ReviewSample:any;
  currentItem:any = [];
  behaviorObs:any;
  pagevalue: any;
  companyIdListArr: any[] = [];
  viewHoldSample: string;
  /*  registerSampleColumnsData: any = [
     { sampleId: 'FW20123451', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Submitted' },
     { sampleId: 'FW20123452', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Available' },
     { sampleId: 'FW20123453', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Review' },
     { sampleId: 'FW20123454', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Available' },
     { sampleId: 'FW20123455', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Available' },
     { sampleId: 'FW20123456', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Available' },
     { sampleId: 'FW20123457', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Available' },
     { sampleId: 'FW20123458', orderId: 'FW20123456', analysisType: 'Desiel Engine Oil', testLevel: 'Advance', status: 'Available' },
     { sampleId: 'FW20123460', orderId: 'FW20123457', analysisType: 'Desiel Exhaust Fuids', testLevel: 'Basic', status: 'Available' },
     { sampleId: 'FW20123461', orderId: 'FW20123457', analysisType: 'Desiel Exhaust Fuids', testLevel: 'Basic', status: 'Available' },
     { sampleId: 'FW20123462', orderId: 'FW20123457', analysisType: 'Desiel Exhaust Fuids', testLevel: 'Basic', status: 'Available' },
     { sampleId: 'FW20123463', orderId: 'FW20123457', analysisType: 'Desiel Exhaust Fuids', testLevel: 'Basic', status: 'Available' },
 
   ] */

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private registerSampleService: RegisterSampleService,
    private sharedService : SharedService,) {
    this.pagevalue = JSON.parse(localStorage.getItem('reviewsamplepagesizeObject'));
    if(this.pagevalue !=null){
      this.pageSize = JSON.parse(localStorage.getItem('reviewsamplepagesizeObject'));
    }else{
      this.pageSize ='10'
    }
    if(localStorage.getItem("branchCustomerDropdown")){
      this.dbuCustomerNameDropdown = JSON.parse(localStorage.getItem("branchCustomerDropdown"));
      this.dbuCompanyID = this.dbuCustomerNameDropdown.customerId;
    }
    this.userRole = this.sharedService.getUserRole();
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.companyId = this.sharedService.getCompanyId();
      this.registerSampleColumns = [
        { code: 'qrCodeId', name:'QR Code'},
        { code: 'sampleId', name: 'Sample ID' },
        { code: 'orderId', name: 'Order ID' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'analysisType', name: 'Analysis Type' },
        { code: 'testLevel', name: 'Test Level' },
        { code: 'status', name: 'Status' },
        // { code: 'statusDate', name: 'Status Date' },
        { code: 'action', name: 'Actions' }
      ];
    } else {
      this.companyId = '';
      this.registerSampleColumns = [
        { code: 'qrCodeId', name:'QR Code'},
        { code: 'sampleId', name: 'Sample ID' },
        { code: 'orderId', name: 'Order ID' },
        { code: 'componentId', name: 'Component ID' },
        { code: 'companyId', name: 'Company ID' },
        { code: 'companyName', name: 'Company Name' },
        { code: 'analysisType', name: 'Analysis Type' },
        { code: 'testLevel', name: 'Test Level' },
        { code: 'status', name: 'Status' },
        { code: 'statusDate', name: 'Status Date' },
        { code: 'action', name: 'Actions' }
      ];
    }
    this.activatedRoute.data.subscribe(data => {
      //console.log('data', data);
      this.initData(data.data.registerSampleList);
    });
  }

  @ViewChildren(SortComponent) sortComponents;
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  QrCodeResult(item: any){

    this.qrCodehasValue = true;
  }
  handleQrCodeResult(result:string){
    this.scanResult=result;
    if(this.scanResult.length == '15'){
      this.qrCodehasValue = true;
    }else{
      this.qrCodehasValue = false;
    }
  } 

  handleQrCodeResultForSearch(result:string){
    this.scanResult=result;
    if(this.scanResult.length == '15'){
      this.qrCodehasValue = true;
    }else{
      this.qrCodehasValue = false;
    }
  }

  clearResult(): void {
    this.scanResult = null;
  }

  openConfirmModal() {
    this.confirmModal = true;
  }

  openConfirmModalForQRCode(){
    this.confirmModalForQRCode = true;
  }

  closeConfirmModal() {
    this.scanResult = null;
    this.confirmModal = false;
    this.QrCodeHasError = false;
    this.qrCodeForm.patchValue({ qrCodeValue: '' });
  }

  closeConfirmModalForSearch(){
    this.scanResult = null;
    this.qrCodeFormSearch.patchValue({ qrCodeValueForSearch: '' });
    this.confirmModalForQRCode = false;
    this.QrCodeSearchHasError = false;
    this.showPopupForRegSample = true;

  }
  upload(item: any){
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.companyId = this.sharedService.getCompanyId();

    }
  if (localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)){
    // if(this.dbuCustomerNameDropdown.customerId != 'notListed'){
      
        this.companyId = this.dbuCustomerNameDropdown.customerId;
        this.companyName = this.dbuCustomerNameDropdown.customerName;
      
    // }
  }

      this.obj = {
        functionName : 'ADD',
        updatedBy : this.sharedService.getUserId(),
        qrCodeId: this.scanResult,
        companyId: this.companyId,
        companyName: this.companyName,
      }
     // this.obj.userRole = this.sharedService.getIntOrExtUser();
      this.registerSampleService.qrCodeValidation(this.obj).subscribe( response => {
        // this.registerSampleColumnsData = res.dashboardDataList;
        //this.initData(res);


          if (response.statusCode == "1") {
            this.analysisTypeData = response.fluidType;
            this.testLevelType = response.testLevel;
            if(this.analysisTypeData == 'Engine Oil (Liquid Fuel)'){
              this.fluidType = 1;
            }else if(this.analysisTypeData == 'Engine Oil (Gaseous Fuel)'){
              this.fluidType = 2;            
            }else if(this.analysisTypeData == 'Coolant'){
              this.fluidType = 3;
            }else if(this.analysisTypeData == 'Diesel Fuel' ){
              this.fluidType = 4; 
            }else if(this.analysisTypeData == 'Diesel Exhaust Fluid'){
              this.fluidType = 5; 
            }else if(this.analysisTypeData == 'Lubricants'){
              this.fluidType = 6;
            }
            
            var infoData = {
              qrCodeId:this.scanResult,
              companyId: this.companyId,
              companyName: this.companyName,
              recordAdded: false,
              orderDetails:  this.orderDetails,
              analysisType: this.analysisTypeData, 
              testLevel: this.testLevelType,
              status: 'Available',
              sampleId: '',
              orderId: '',
              componentId: '',
              statusDate: '',
              fluidType : this.fluidType,
              isSampleReg: true
            }
            this.registerSampleService.setItem(infoData);
            localStorage.setItem('registerSampleObject', JSON.stringify(infoData));
            // localStorage.setItem('scanResult', this.scanResult);
            if (this.userRole === USERTYPE.EXTERNAL) {
              this.router.navigate(['registerSample/addEditRegisterSample']);
            } else if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole === USERTYPE.DATA_SCIENTIST ||
              this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS || this.userRole === USERTYPE.FLUIDWATCH_MANAGER) {
              this.router.navigate(['registerSample/addEditReviewSample']);
            }
            
           
          }else{
            this.scanResult = null;
           this.confirmModal = false;
           this.QrCodeHasError = false;
           this.confirmModalForQRCode = false;
           this.QrCodeSearchHasError = false;
           this.showPopupForRegSample = true;
           this.qrCodeForm.patchValue({ qrCodeValue: '' });
           this.qrCodehasValue = false;
            this.alertService.error(response.statusMessage);

          }
        
      });
     
  }

  searchQrCode(item: any){
    this.infoDataforSearch = {
      analysisType: "",
      columnName: "sampleId",
      companyId: "",
      companyName: "",
      currentPage: "1",
      orderId: "",
      componentId: "",
      pageSize: "10",
      qrCodeId:this.scanResult,
      sampleId: "",
      sortingOrder: "desc",
      status: "",
      statusDate: "",
      testLevel: ""
    }

     this.registerSampleService.getSampleDashboardData(this.infoDataforSearch).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.registerSampleColumnsData = response.dashboardDataList;
          this.totalItems = response.dashboardDataList[0].maxRow;
          const newObj  = Object.assign({}, ...this.registerSampleColumnsData);
          this.calculateOffset();
         this.onAssociateClick(newObj)
        } else {
          this.QrCodeSearchHasError =true;
          this.showPopupForRegSample = false;
          this.registerSampleColumnsData = [];
          if(this.userRole == USERTYPE.DBU_BRANCH || USERTYPE.DBU_FLUIDS){
            this.totalItems = 0;          
            this.showRecords = 0;
            this.maxPage = 0;
          }
        }
      }, err => {
        this.alertService.error(err);
      }
    );

  }

  getDashBoardData() {
    const req: RegisterSampleRequestModel = new RegisterSampleRequestModel();
    req.userRole = this.sharedService.getUserRole();
    req.pageSize = this.pageSize.toString();
    req.columnName = this.columnName;
    req.sortingOrder = this.sortingOrder;
    req.currentPage = this.currentPage.toString();
    req.companyId = '';
    req.companyIdList = [];
    
    let reviewSample = JSON.parse(sessionStorage.getItem('ReviewSampleObject'));
    if(reviewSample !=null){
      this.status= this.sampleSearchForm.value['status'];
      this.companyId= this.sampleSearchForm.value['companyId'];
    }else{
      this.status= this.status;
      this.companyId= this.companyId;
    }       
   
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.searchObj = {
        userRole: this.searchObjUserRole,
        companyId: '',
        companyIdList : this.companyIdListArr,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        status: this.status,
        statusDate: this.statusDate
      }
    } else {
      this.searchObj = {
        userRole: this.searchObjUserRole,
        companyId: this.companyId,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        status: this.status,
        statusDate: this.statusDate
      }
    }
    this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    if(this.userRole == USERTYPE.DBU_BRANCH){
      this.searchObj.userRole = 'dbuUser';
    } else if(this.userRole == USERTYPE.DBU_FLUIDS){
      this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    }
    this.registerSampleService.getSampleDashboardData(this.searchObj).subscribe(res => {
      // this.registerSampleColumnsData = res.dashboardDataList;
      this.initData(res);
    });
  }

  ngOnInit() {
    this.companyIdListArr = JSON.parse(localStorage.getItem('companyIdList'));
    let sortdata = JSON.parse(sessionStorage.getItem('registerSortData'));
    this.viewHoldSample = JSON.parse(localStorage.getItem('viewHoldSample'));
    if(this.viewHoldSample != null && this.viewHoldSample !='' && this.viewHoldSample !=undefined){
      if(this.userRole == USERTYPE.EXTERNAL){ 
          this.sampleSearchForm.patchValue({qrCodeId: this.viewHoldSample});
          this.sampleSearchForm.patchValue({ status: 'Hold' });
          this.searchDashboard();
      }
    }
    else if(this.viewHoldSample == null && this.viewHoldSample =="" && this.viewHoldSample ==undefined){
      if(this.userRole == USERTYPE.EXTERNAL ){ 
      this.sampleSearchForm.patchValue({qrCodeId: ''});
      this.searchDashboard();
      }
    }
    if(sortdata == 'undefined'){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(sessionStorage.getItem('SortData'))      
    }
    if(this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      this.columnName =  this.currentItem.column;
      this.sortingOrder =  this.currentItem.sort;
    }
    else{
      this.sortingOrder = 'desc';
      this.columnName = 'sampleId';
    }
     
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.sampleSearchForm.patchValue({ status: 'Available' });
    } 
    else if (this.userRole === USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.sampleSearchForm.patchValue({ status: '' });
      // this.sampleSearchForm.controls['companyId'].disable();
      // this.sampleSearchForm.controls['companyName'].disable();
    } 
    else if(this.userRole === USERTYPE.EXTERNAL && this.viewHoldSample != null){
      this.sampleSearchForm.patchValue({ status: 'Hold' });
    }
    else{
      this.sampleSearchForm.patchValue({ status: 'Submitted' });
    }
   
    //Table Refresh
    let reviewSample = JSON.parse(sessionStorage.getItem('ReviewSampleObject'));
    if(reviewSample !=null){
      if(this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS){       
        if (this.dbuCustomerNameDropdown.customerId == reviewSample.companyId) {
          this.sampleSearchForm.patchValue({qrCodeId:reviewSample['qrCodeId']})
          this.sampleSearchForm.patchValue({companyId:reviewSample['companyId']})
          this.sampleSearchForm.patchValue({companyName:reviewSample['companyName']})
          this.sampleSearchForm.patchValue({sampleId:reviewSample['sampleId']})
          this.sampleSearchForm.patchValue({orderId:reviewSample['orderId']})
          this.sampleSearchForm.patchValue({componentId:reviewSample['componentId']})
          this.sampleSearchForm.patchValue({status:reviewSample['status']})
          this.sampleSearchForm.patchValue({statusDate:reviewSample['statusDate']})
          this.sampleSearchForm.patchValue({analysisType:reviewSample['analysisType']})
          this.sampleSearchForm.patchValue({testLevel:reviewSample['testLevel']})          
          this.searchDashboard();
        }
      } else {    
        this.sampleSearchForm.patchValue({qrCodeId:reviewSample['qrCodeId']})
        this.sampleSearchForm.patchValue({companyId:reviewSample['companyId']})
        this.sampleSearchForm.patchValue({companyName:reviewSample['companyName']})
        this.sampleSearchForm.patchValue({sampleId:reviewSample['sampleId']})
        this.sampleSearchForm.patchValue({orderId:reviewSample['orderId']})
        this.sampleSearchForm.patchValue({componentId:reviewSample['componentId']})
        this.sampleSearchForm.patchValue({status:reviewSample['status']})
        this.sampleSearchForm.patchValue({statusDate:reviewSample['statusDate']})
        this.sampleSearchForm.patchValue({analysisType:reviewSample['analysisType']})
        this.sampleSearchForm.patchValue({testLevel:reviewSample['testLevel']})       
        this.searchDashboard();        
      }
    }
    if (localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)){
      // if(this.dbuCustomerNameDropdown.customerId != 'notListed'){
        this.sampleSearchForm.patchValue({ 
          companyId: this.dbuCustomerNameDropdown.customerId,
          companyName: this.dbuCustomerNameDropdown.customerName,
        });
      // }
    }

    this.behaviorObs = this.sharedService.currentChange.subscribe(message=>{
      if(this.userRole == USERTYPE.DBU_BRANCH){
        if(this.router.url == '/registerSample/reviewSampleList'){      
          this.messageDropdown = message;
          if(this.isFirstTime == false){
            this.recieveMessage(this.messageDropdown);  
          }
          this.isFirstTime = false;
        }
      } else if(this.userRole == USERTYPE.DBU_FLUIDS){
        if(this.router.url == '/registerSample/reviewSampleList'){      
          this.messageDropdown = message;
          if(this.isFirstTime == false){
            this.recieveMessage(this.messageDropdown);  
          }
          this.isFirstTime = false;
        }
      }
    });

  }

  ngOnDestroy () {
    if(this.isFirstTime == false){
      this.behaviorObs.unsubscribe();
    }
    localStorage.setItem('reviewsamplepagesizeObject', JSON.stringify(this.pageSize));
  }





  recieveMessage(event){
    // this.sampleSearchForm.controls['companyId'].enable();
    // this.sampleSearchForm.controls['companyName'].enable();
    this.dbuCustomerNameDropdown = event;
    // console.log(event)
    let companyName = this.dbuCustomerNameDropdown.customerName;
    let companyId = this.dbuCustomerNameDropdown.customerId;
    this.sampleSearchForm.patchValue({
      companyName: companyName,
      companyId: companyId
    })
    // if(companyId == 'notListed'){
    //   this.sampleSearchForm.patchValue({
    //     companyName: '',
    //     companyId: ''
    //   })
    // } else {
    //   this.sampleSearchForm.patchValue({
    //     companyName: companyName,
    //     companyId: companyId
    //   })
    // }
    if(this.sampleSearchForm.controls['companyName'].value == ''){
      this.alertService.error("Please select Company Name");
      this.scrollToTop = true;
      this.registerSampleColumnsData = [];
      this.totalItems = 0;          
      this.showRecords = 0;
      this.maxPage = 0;
      this.sampleSearchForm.disable();
      this.isPageSizeDisable = true;
    } else {
      this.sampleSearchForm.enable();
      this.isPageSizeDisable = false;
      this.searchDashboardDropDownSelect();
    }
    // this.sampleSearchForm.controls['companyId'].disable();
    // this.sampleSearchForm.controls['companyName'].disable();
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  initData(data: any) {
    console.log('data 1 : ' + data);
    //console.log(this.searchObj + '---------searchObj');

    if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole === USERTYPE.DATA_SCIENTIST ||
      this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS || this.userRole === USERTYPE.FLUIDWATCH_MANAGER) {
      this.searchObjUserRole = '';
      this.status = 'Submitted';
      if (localStorage.getItem("branchCustomerDropdown") && (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)) {
        // if(this.dbuCustomerNameDropdown.customerId != 'notListed'){
          this.companyId = this.dbuCustomerNameDropdown.customerId;
          this.companyName = this.dbuCustomerNameDropdown.customerName;
          this.status = '';
        // }
      }
      this.searchObj = {
        userRole: this.searchObjUserRole,
        companyId: this.companyId,
        companyName: this.companyName,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        status: this.status,
        statusDate: this.statusDate
      }
    } else if (this.userRole === USERTYPE.EXTERNAL) {
      this.searchObjUserRole = this.userRole;
      this.status = 'Available';    
      this.searchObj = {
        userRole: this.searchObjUserRole,
        companyId: '',
        companyIdList: this.companyIdListArr,
        companyName: this.companyName,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        status: this.status,
        statusDate: this.statusDate
      }
    } 
    this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    //console.log(this.searchObj + '---------searchObj');
    this.registerSampleColumnsData = data.dashboardDataList;
    console.log('registerSampleColumnsData', this.registerSampleColumnsData);
    this.intUserFilterObject.status = data.statusList;
    // this.intUserFilterObject.qrCodeId = data.qrCodeIdList;
    this.intUserFilterObject.analysisType = data.analysisTypeList;
    this.intUserFilterObject.testLevel = data.testLevelList;
    this.extUserFilterObject.status = data.statusList;
    this.extUserFilterObject.analysisType = data.analysisTypeList;
    this.extUserFilterObject.testLevel = data.testLevelList;
    // this.extUserFilterObject.qrCodeId = data.qrCodeIdList;
    if(data.statusCode == "1"){
      this.totalItems = data.dashboardDataList[0].maxRow;
      this.calculateOffset();
    } else {
      console.log('pppp')
      this.scrollToTop = true;
      this.alertService.error(data.statusMessage);
      this.registerSampleColumnsData = [];
    }
    
    if((this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS)){
      if(this.dbuCompanyID == ''){
        this.alertService.error("Please select Company Name");
        this.scrollToTop = true;
        this.registerSampleColumnsData = [];
        this.totalItems = 0;          
        this.showRecords = 0;
        this.maxPage = 0;
        this.sampleSearchForm.disable();
        this.isPageSizeDisable = true;
      }
    }
    
  }

  getButtonLabel(status: string) {
    if (this.userRole === USERTYPE.EXTERNAL) {
      if (status.toLowerCase() === 'Available'.toLowerCase()) {
        return 'Register';
      } else if (status.toLowerCase() === 'Received'.toLowerCase() 
      || status.toLowerCase() === 'Analyzing'.toLowerCase() || status.toLowerCase() === 'Hold'.toLowerCase() || status.toLowerCase() === 'Rerun'.toLowerCase() || status.toLowerCase() === 'Submitted'.toLowerCase()) {
        return 'Update';
      } else {
        return 'View'
      }
    } else if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole === USERTYPE.DATA_SCIENTIST ||
      this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS || this.userRole === USERTYPE.FLUIDWATCH_MANAGER) {
      if (status.toLowerCase() === 'Available'.toLowerCase()) {
        return 'Register';
      } else if (status.toLowerCase() === 'Submitted'.toLowerCase()) {
        return 'Review';
      } else if (status.toLowerCase() === 'Received'.toLowerCase() || status.toLowerCase() === 'Hold'.toLowerCase() 
      || status.toLowerCase() === 'Analyzing'.toLowerCase() || status.toLowerCase() === 'Rerun'.toLowerCase()) {
        return 'Update';
      } else {
        return 'View'
      }

    }
  }

  onAssociateClick(item: any) {
    
    this.registerSampleService.setItem(item);
    localStorage.setItem('registerSampleObject', JSON.stringify(item));
    sessionStorage.setItem('ReviewSampleObject', JSON.stringify(this.sampleSearchForm.value));
    if (this.userRole === USERTYPE.EXTERNAL) {
      this.router.navigate(['registerSample/addEditRegisterSample']);
    } else if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole === USERTYPE.DATA_SCIENTIST || this.userRole === USERTYPE.FLUIDWATCH_MANAGER ||
      this.userRole === USERTYPE.DBU_BRANCH || this.userRole === USERTYPE.DBU_FLUIDS) {
      this.router.navigate(['registerSample/addEditReviewSample']);
    }
  }

  /* -------------------------ext User filter value dropdown------------------------- */
  extUsersearchFilter(str) {
    let search = (this.sampleSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.extUserFilterObject[str] = [];
      this.searchDashboardDropDownSelect();
    }
    sessionStorage.setItem('ReviewSampleObject', JSON.stringify(this.sampleSearchForm.value));    
    let _data = {
      searchColumn: str,
      searchValue: this.sampleSearchForm.value[str],
      companyId: '',
      companyIdList : this.companyIdListArr,
      userRole: this.userRole,
      qrCodeId: this.sampleSearchForm.value['qrCodeId'],
      sampleId: this.sampleSearchForm.value['sampleId'],
      orderId: this.sampleSearchForm.value['orderId'],
      componentId: this.sampleSearchForm.value['componentId'],
      analysisType: this.sampleSearchForm.value['analysisType'],
      testLevel: this.sampleSearchForm.value['testLevel'],
      status: this.sampleSearchForm.value['status'],
      statusDate: this.sampleSearchForm.value['statusDate']
    }
    _data.userRole = this.sharedService.getIntOrExtUser();
    if (str === 'sampleId' || str === 'componentId' || str === 'qrCodeId' || str === 'statusDate' || str === 'orderId') {
      if (this.sampleSearchForm.value[str].length >= 2) {
        this.registerSampleService.getSampleDashboardData(_data).subscribe(
          response => {
            if (response.statusCode == "1") {
              this.extUserFilterObject[str] = response.distinctValuesList;
            } else {
              this.alertService.error(response.statusMessage);
              this.extUserFilterObject[str] = [];
              this.alertService.clear();
              window.sessionStorage.removeItem('ReviewSampleObject');
            }
          }, err => {
            this.alertService.error(err);
          }
        );
      }
      else if(this.sampleSearchForm.value[str].length == 0){
        this.extUserclearSearch(str)
        this.extUserFilterObject[str] == [];
        this.extUserFilterObject[str].length = 0;
      }
    }
  }
  clearSearchfilters(){    
    this.alertService.clear();
    this.pageSize='10';
    window.sessionStorage.removeItem('ReviewSampleObject');    
    this.sampleSearchForm.patchValue({qrCodeId:''})
    this.sampleSearchForm.patchValue({companyId:''})
    this.sampleSearchForm.patchValue({companyName:''})
    this.sampleSearchForm.patchValue({sampleId:''})
    this.sampleSearchForm.patchValue({orderId:''})
    this.sampleSearchForm.patchValue({componentId:''})
    this.sampleSearchForm.patchValue({status:''})
    this.sampleSearchForm.patchValue({statusDate:''})
    this.sampleSearchForm.patchValue({analysisType:''})
    this.sampleSearchForm.patchValue({testLevel:''})
    this.sampleSearchForm.patchValue({status:'Submitted' });
    this.searchDashboard();
    this.loadPage(1);
  }

  getColumnCode(str) {
    if (str === 'status') {
      return 'statusName';
    } else if (str === 'analysisType') {
      return 'analysisTypeName';
    } else if (str === 'testLevel') {
      return 'testLevelName';
    } else {
      return str;
    }
  }

  /* -------------------------int User filter value dropdown------------------------- */
  intUsersearchFilter(str) {
    let search = (this.sampleSearchForm.value[str]).toLowerCase();
    if(search == '') {
      this.intUserFilterObject[str] = [];
      this.searchDashboardDropDownSelect();      
    }
    sessionStorage.setItem('ReviewSampleObject', JSON.stringify(this.sampleSearchForm.value));
    let _data = {
      searchColumn: str,
      searchValue: this.sampleSearchForm.value[str],
      qrCodeId: this.sampleSearchForm.value['qrCodeId'],
      companyId: this.sampleSearchForm.value['companyId'],
      companyName:this.sampleSearchForm.value['companyName'],
      sampleId: this.sampleSearchForm.value['sampleId'],
      orderId: this.sampleSearchForm.value['orderId '],
      componentId: this.sampleSearchForm.value['componentId'],

      analysisType: this.sampleSearchForm.value['analysisType'],
      testLevel: this.sampleSearchForm.value['testLevel'],
      status: this.sampleSearchForm.value['status'],
      statusDate: this.sampleSearchForm.value['statusDate'],
      userRole: this.sharedService.getIntOrExtUser()
    }
    if(this.userRole == USERTYPE.DBU_BRANCH){
      _data.userRole = 'dbuUser';
    } else if(this.userRole == USERTYPE.DBU_FLUIDS){
      _data.userRole = this.sharedService.getIntOrExtUser();
    }
    if (str === 'sampleId' || str === 'companyId' || str === 'companyName' || str === 'qrCodeId'
      || str ===  'componentId' || str === 'statusDate' || str === 'orderId'
    ) {
      if (this.sampleSearchForm.value[str].length >= 2) {
        this.registerSampleService.getSampleDashboardData(_data).subscribe(
          response => {
            if (response.statusCode == "1") {
              this.intUserFilterObject[str] = response.distinctValuesList;
            } else {
              this.alertService.error(response.statusMessage);
              this.intUserFilterObject[str] = [];
              this.alertService.clear();
              window.sessionStorage.removeItem('ReviewSampleObject');
            }
          }, err => {
            this.alertService.error(err);
          }
        );        
      }
      else if(this.sampleSearchForm.value[str].length == 0){
        this.intUserclearSearch(str)
        this.intUserFilterObject[str] == [];
        this.intUserFilterObject[str].length = 0;
      }
    }
    // else {
    //    this.intUserFilterObject[str] = this.intUserFilterObject[str].filter(obj => { return obj.statusName.toString().contains(this.sampleSearchForm.value[str]) !== -1 })
    // }
  }

  /* -------------------------search button for filter value selected------------------------- */
  searchDashboard() {
    sessionStorage.setItem('ReviewSampleObject', JSON.stringify(this.sampleSearchForm.value));
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj = {
        userRole: this.userRole,
        companyId: '',
        companyIdList : this.companyIdListArr,
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        qrCodeId: this.sampleSearchForm.value['qrCodeId'],
        sampleId: this.sampleSearchForm.value['sampleId'],
        orderId: this.sampleSearchForm.value['orderId'],
        componentId: this.sampleSearchForm.value['componentId'],
        analysisType: this.sampleSearchForm.value['analysisType'],
        testLevel: this.sampleSearchForm.value['testLevel'],
        status: this.sampleSearchForm.value['status'],
        statusDate: this.sampleSearchForm.value['statusDate']
      }
      this.searchObj.userRole = this.sharedService.getIntOrExtUser();    
    } else if (this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST ||
      this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS ||  this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: this.currentPage.toString(),
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        qrCodeId: this.sampleSearchForm.value['qrCodeId'],
        companyId: this.sampleSearchForm.value['companyId'],
        companyName:this.sampleSearchForm.value['companyName'],
        sampleId: this.sampleSearchForm.value['sampleId'],
        orderId: this.sampleSearchForm.value['orderId'],
        componentId: this.sampleSearchForm.value['componentId'],
        analysisType: this.sampleSearchForm.value['analysisType'],
        testLevel: this.sampleSearchForm.value['testLevel'],
        status: this.sampleSearchForm.value['status'],
        statusDate: this.sampleSearchForm.value['statusDate']
      }
      if(this.userRole == USERTYPE.DBU_BRANCH){
        this.searchObj.userRole = 'dbuUser';
      } else if(this.userRole == USERTYPE.DBU_FLUIDS){
        this.searchObj.userRole = this.sharedService.getIntOrExtUser();
      }
    }

    this.registerSampleService.getSampleDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.registerSampleColumnsData = response.dashboardDataList;
          this.totalItems = response.dashboardDataList[0].maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
         // this.registerSampleColumnsData.qrCodeId = 'MS22-11-0000002';
          this.registerSampleColumnsData = [];
       
        }
      }, err => {
        this.scrollToTop = true;
        this.alertService.error(err);
      }
    );

  }

  searchDashboardDropDownSelect() { 
    this.autocomplete.showPanel;    
    sessionStorage.setItem('ReviewSampleObject', JSON.stringify(this.sampleSearchForm.value));
    this.alertService.clear();
    if (this.userRole == USERTYPE.EXTERNAL) {
      this.searchObj = {
        userRole: this.userRole,
        companyId: '',
        companyIdList : this.companyIdListArr,
        pageSize: this.pageSize.toString(),
        currentPage: "1",
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        qrCodeId: this.sampleSearchForm.value['qrCodeId'],
        sampleId: this.sampleSearchForm.value['sampleId'],
        orderId: this.sampleSearchForm.value['orderId'],
        componentId: this.sampleSearchForm.value['componentId'],
        analysisType: this.sampleSearchForm.value['analysisType'],
        testLevel: this.sampleSearchForm.value['testLevel'],
        status: this.sampleSearchForm.value['status'],
        statusDate: this.sampleSearchForm.value['statusDate']
      }
      this.searchObj.userRole = this.sharedService.getIntOrExtUser();
    } else if (this.userRole == USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.FLUIDWATCH_MANAGER || 
      this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.searchObj = {
        pageSize: this.pageSize.toString(),
        currentPage: "1",
        columnName: this.columnName,
        sortingOrder: this.sortingOrder,
        qrCodeId: this.sampleSearchForm.value['qrCodeId'],
        companyId: this.sampleSearchForm.value['companyId'],
        companyName:this.sampleSearchForm.value['companyName'],
        sampleId: this.sampleSearchForm.value['sampleId'],
        orderId: this.sampleSearchForm.value['orderId'],
        componentId: this.sampleSearchForm.value['componentId'],
        analysisType: this.sampleSearchForm.value['analysisType'],
        testLevel: this.sampleSearchForm.value['testLevel'],
        status: this.sampleSearchForm.value['status'],
        statusDate: this.sampleSearchForm.value['statusDate']
      }
      if(this.userRole == USERTYPE.DBU_BRANCH){
        this.searchObj.userRole = 'dbuUser';
        console.log('helllo');
      } else if(this.userRole == USERTYPE.DBU_FLUIDS){
        this.searchObj.userRole = this.sharedService.getIntOrExtUser();
      }
    }

    this.registerSampleService.getSampleDashboardData(this.searchObj).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.registerSampleColumnsData = response.dashboardDataList;
          this.totalItems = response.dashboardDataList[0].maxRow;
          this.calculateOffset();
          //console.log(this.totalItems + "this is the response");
        } else {
          this.alertService.error(response.statusMessage);
          this.registerSampleColumnsData = [];
          if(this.userRole == USERTYPE.DBU_BRANCH || USERTYPE.DBU_FLUIDS){
            this.totalItems = 0;          
            this.showRecords = 0;
            this.maxPage = 0;
          }
        }
      }, err => {
        this.alertService.error(err);
      }
    );

  }

  /* -------------------- Reset Search Filter------------- */
  extUserclearSearch(str) {
    //console.log(this.sampleSearchForm.value);
    this.alertService.clear();
    if( this.sampleSearchForm.controls[str].value != ''){
    
    this.sampleSearchForm.patchValue({ [str]: '' });
    if (str === 'sampleId' || str === 'qrCodeId' || str === 'componentId' || str === 'statusDate' || str === 'orderId') {
      this.extUserFilterObject[str] = [];
    }
    this.searchDashboard();
  }
  }

  intUserclearSearch(str) {
    //console.log(this.sampleSearchForm.value);
    this.alertService.clear();
    if( this.sampleSearchForm.controls[str].value != ''){
    
    this.sampleSearchForm.patchValue({ [str]: '' });
    if (str === 'sampleId' || str === 'companyId' || str === 'componentId' || str === 'qrCodeId' || str === 'statusDate' || str === 'orderId') {
      this.intUserFilterObject[str] = [];
    }
    this.searchDashboard();
  }
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.registerSampleColumnsData = [];
    this.currentPage = 1;
    this.changePage = 1;
    localStorage.setItem('reviewsamplepagesizeObject', JSON.stringify(this.pageSize));
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.registerSampleColumnsData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }

  searchLengthInt(filterObject:any)
  {
    if(filterObject.companyId.length >0 || filterObject.companyName.length >0 ||  filterObject.sampleId.length >0 ||
      filterObject.analysisType.length >0 ||filterObject.testLevel.length >0 || filterObject.status.length >0 || filterObject.componentId.length >0 || filterObject.qrCodeId.length >0
      || filterObject.statusDate.length >0 ||filterObject.orderId.length >0)
    {
        return true
    }
    else
    {
      return false
    }
  }

  searchLengthExt(filterObject:any)
  {
    if( filterObject.sampleId.length >0  || filterObject.qrCodeId.length > 0  ||
      filterObject.analysisType.length >0 ||filterObject.testLevel.length >0 || filterObject.status.length >0 || filterObject.componentId.length >0
      || filterObject.qrCodeId.length >0 || filterObject.statusDate.length >0 ||filterObject.orderId.length >0)
    {
        return true
    }
    else
    {
      return false
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    let val;
    if(this.userRole == USERTYPE.EXTERNAL)
    {
       val =this.searchLengthExt(this.extUserFilterObject)
    }
    else{
       val =this.searchLengthInt(this.intUserFilterObject)
    }
  
   
    if(val === true)
    {
      this.searchDashboard();
    }
    else
    {
      this.getDashBoardData();
    }
  }


  getArray(): any[] {
    return Array(this.maxPage);
  }

  /**
 * Calls search method to sort the data based on columnName
 * @param columnName 
 */
   sortData(columnName) {
    let existingsortdata= sessionStorage.getItem('analyzesortData');
    if(existingsortdata == 'undefined' || existingsortdata == null || existingsortdata== ""){
      this.currentItem = undefined;
    }else{
      this.currentItem = JSON.parse(existingsortdata)      
    }
    if( this.currentItem != "" && this.currentItem !=  null && this.currentItem != undefined){
      if(this.currentItem.sort == 'asc'){
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
      }else{
        this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);

      }
    }
    let sortcolumn=  this.sortingOrder != 'asc' ? 'asc' : 'desc';
    let reviewcolumnsort ={column : columnName, sort :sortcolumn,direction:sortcolumn,active:columnName};
    sessionStorage.setItem('registerSortData', JSON.stringify(reviewcolumnsort));
    if (this.columnName !== columnName) {
      this.sortingOrder = '';
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder !== 'asc' ? 'asc' : 'desc';
    //this.getDashBoardData();
    this.searchDashboard();
  }

}

