import { AfterViewChecked, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { common, warnMessage } from 'src/app/View/common/constants/constant';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { DatePipe } from '@angular/common';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { OpsKeyissueReportService } from './ops-keyissue-report.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { SharedService } from 'src/app/View/service/shared.service';

@Component({
  selector: 'app-ops-keyissue-report',
  templateUrl: './ops-keyissue-report.component.html',
  styleUrls: ['./ops-keyissue-report.component.css']
})
export class OpsKeyissueReportComponent implements OnInit, AfterViewChecked {

  @ViewChildren(SortComponent) sortComponents;

  readonly USERTYPE = USERTYPE;
  userRole: string = ''; 
  isSearchDone: boolean = false;
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  reportData: any = [];
  reportCountData: any = {};

  /* ------Pagination variables declaration--------- */
  pageSizeOptions: any[] = common.pageSizeOptions;
  pageSize = "10";
  currentPage = 1;
  showRecords = 0;
  maxPage = 0;
  totalItems: any;
  changePage: any = 1;
  rowDetails: any;
  sortingOrder: string;
  columnName: string;
  searchOption: any;
  scrollToTop: any;
  searchObj: any;

  filterObject = {
    sampleId: [],
    companyId: [],
    companyName: [],
    unitId: [],
    esn: []
  };

  reportCols = [];
  reportDieselEngineCols = [
    { code: 'sampleId', value: 'Sample ID' },
    { code: 'companyId', value: 'Company ID' },
    { code: 'companyName', value: 'Company Name' },
    { code: 'unitId', value: 'Unit ID' },
    { code: 'esn', value: 'ESN' },
    { code: 'lastSampledDate', value: 'Last Status Date' },
    { code: 'scSeverityName', value: 'Soot Contamination Severity' },
    { code: 'fcSeverityName', value: 'Fuel Contamination Severity' },
    { code: 'ccSeverityName', value: 'Coolant Contamination Severity' },
    { code: 'doSeverityName', value: 'Degraded Oil Contamination Severity' },
    { code: 'ewSeverityName', value: 'Engine Wear Contamination Severity' },
    { code: 'dcSeverityName', value: 'Dust Contamination Severity' }
  ];

  reportNatGasCols = [
    { code: 'sampleId', value: 'Sample ID' },
    { code: 'companyId', value: 'Company ID' },
    { code: 'companyName', value: 'Company Name' },
    { code: 'unitId', value: 'Unit ID' },
    { code: 'esn', value: 'ESN' },
    { code: 'lastSampledDate', value: 'Last Status Date' },   
    { code: 'ccSeverityName', value: 'Coolant Contamination Severity' },
    { code: 'doSeverityName', value: 'Degraded Oil Contamination Severity' },
    { code: 'ewSeverityName', value: 'Engine Wear Contamination Severity' },
    { code: 'dcSeverityName', value: 'Dust Contamination Severity' },
    { code: 'wcSeverityName', value: 'Water Contamination Severity' }
  ];

  fluidTypeList = [{ 'fluidType': 1, 'fluidTypeName': 'Engine Oil (Liquid Fuel)' },
  { 'fluidType': 2, 'fluidTypeName': 'Engine Oil (Gaseous Fuel)' },
  /*{ 'fluidType': 3, 'fluidTypeName': 'Coolant' },
  { 'fluidType': 4, 'fluidTypeName': 'Diesel Fuel' },
  { 'fluidType': 5, 'fluidTypeName': 'Diesel Exhaust Fluid' }*/
  ];

  inputForm = new UntypedFormGroup({
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
    fluidType: new UntypedFormControl('', [Validators.required]),
  });

  searchForm = new UntypedFormGroup({
    sampleId: new UntypedFormControl(''),
    companyId: new UntypedFormControl(''),
    companyName: new UntypedFormControl(''),
    unitId: new UntypedFormControl(''),
    esn: new UntypedFormControl('')
  });

  constructor(private commonHandler: CommonHandlerService,
    private alertService: AlertService,
    private opsReportService: OpsKeyissueReportService,
    private sharedService: SharedService,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.columnName = 'sampleId';
    this.userRole = this.sharedService.getUserRole();  
    this.minStartDate = new Date(2021, 0, 1); 
    this.minEndDate = new Date(2021, 0, 1); 
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
    if(this.userRole === USERTYPE.EXTERNAL){
      this.inputForm.patchValue({
        companyId: this.sharedService.getCompanyId()    
      });      
    }    
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }  

  getOpsKeyIssueReportData() {
    let inputData = this.getInputData(); 

    if(inputData.fluidType == 'Engine Oil (Liquid Fuel)'){
      this.reportCols = this.reportDieselEngineCols;
    }else if(inputData.fluidType == 'Engine Oil (Gaseous Fuel)'){
      this.reportCols = this.reportNatGasCols;
    }
    this.opsReportService.getOpsKeyIssueReportData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.reportData = response.reportList;
        this.reportCountData = response.countData;
        this.totalItems = response.maxRow;
        this.calculateOffset();       
        this.isSearchDone = true;
      } else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );

    //mock data   
    /*this.reportCountData = {};
    this.reportData = [{ 'sampleId': 'FW20000001', 'companyId': 'C11111111', 'companyName': 'Cummins1', 'unitId': 'Unit1', 'esn': 'esn1', 'lastStatusDate': '4/28/2020', 'scSeverity': 'Normal', 'fcSeverity': 'Caution', 'ccSeverity': 'Watch', 'doSeverity': 'Warning', 'ewSeverity': 'Normal', 'dcSeverity': 'Normal', 'wcSeverity': 'Normal' },
    { 'sampleId': 'FW20000002', 'companyId': 'C11111111', 'companyName': 'Cummins1', 'unitId': 'Unit1', 'esn': 'esn1', 'lastStatusDate': '4/28/2020', 'scSeverity': 'Normal', 'fcSeverity': 'Caution', 'ccSeverity': 'Watch', 'doSeverity': 'Warning', 'ewSeverity': 'Normal', 'dcSeverity': 'Normal', 'wcSeverity': 'Normal' },
    { 'sampleId': 'FW20000003', 'companyId': 'C11111111', 'companyName': 'Cummins1', 'unitId': 'Unit1', 'esn': 'esn1', 'lastStatusDate': '4/28/2020', 'scSeverity': 'Normal', 'fcSeverity': 'Caution', 'ccSeverity': 'Watch', 'doSeverity': 'Warning', 'ewSeverity': 'Normal', 'dcSeverity': 'Normal', 'wcSeverity': 'Normal' },
    { 'sampleId': 'FW20000004', 'companyId': 'C11111111', 'companyName': 'Cummins1', 'unitId': 'Unit1', 'esn': 'esn1', 'lastStatusDate': '4/28/2020', 'scSeverity': 'Normal', 'fcSeverity': 'Caution', 'ccSeverity': 'Watch', 'doSeverity': 'Warning', 'ewSeverity': 'Normal', 'dcSeverity': 'Normal', 'wcSeverity': 'Normal' },
    { 'sampleId': 'FW20000005', 'companyId': 'C11111111', 'companyName': 'Cummins1', 'unitId': 'Unit1', 'esn': 'esn1', 'lastStatusDate': '4/28/2020', 'scSeverity': 'Normal', 'fcSeverity': 'Caution', 'ccSeverity': 'Watch', 'doSeverity': 'Warning', 'ewSeverity': 'Normal', 'dcSeverity': 'Normal', 'wcSeverity': 'Normal' },
    { 'sampleId': 'FW20000006', 'companyId': 'C11111111', 'companyName': 'Cummins1', 'unitId': 'Unit1', 'esn': 'esn1', 'lastStatusDate': '4/28/2020', 'scSeverity': 'Normal', 'fcSeverity': 'Caution', 'ccSeverity': 'Watch', 'doSeverity': 'Warning', 'ewSeverity': 'Normal', 'dcSeverity': 'Normal', 'wcSeverity': 'Normal' }];

    this.reportCountData = { 'sampleId': '5', 'companyId': '4', 'companyName': '4', 'unitId': '2', 'esn': '2' };

    this.isSearchDone = true;
    this.totalItems = 5;
    this.calculateOffset();*/
  }

  searchFilter(str) {
    let inputData = this.getInputData(); 
    inputData['searchColumn'] = this.getSortColumn(str);
    inputData['searchValue'] = this.searchForm.value[str];  

    if(inputData['searchValue'].length >= 2){
      this.opsReportService.getOpsKeyIssueReportData(inputData).subscribe(response => {
        if (response.statusCode == "1") {
          this.filterObject[str] = response.distinctValuesList;
        } else {
          this.filterObject[str] = [];
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
      );
    }
  }

  clearSearch(str) {
    if (this.searchForm.controls[str].value != '') {
      this.filterObject[str] = [];
      this.searchForm.patchValue({ [str]: '' });
      this.getOpsKeyIssueReportData();
    }
  }

  startDateChange() {
    this.minEndDate = this.inputForm.value.startDate;
  }

  endDateChange() {
    this.maxStartDate = this.inputForm.value.endDate;
  }

  fluidTypeChange() {
    this.isSearchDone = false;
  }

  exportToExcel() {
    let inputData = this.getInputData(); 
    inputData['action'] = "EMAIL";
    inputData['pageSize'] = 5000;     

    this.opsReportService.getOpsKeyIssueReportData(inputData).subscribe(response => {
      if (response.statusCode == "1") {
        this.alertService.success(response.statusMessage);
        this.scrollToTop = true;
      } else {        
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
    }, err => {

      this.alertService.error(err);
      this.scrollToTop = true;
    }
    );
  }

  clearInput(){
    this.inputForm.reset();    
    this.isSearchDone = false;
    this.minStartDate = new Date(2021, 0, 1); 
    this.minEndDate = new Date(2021, 0, 1); 
    this.maxStartDate = new Date();
    this.maxEndDate = new Date();
  }

  /* -------------------- Pagination and Sorting Code------ starts here------- */

  calculateOffset() {
    const result = this.commonHandler.calculatePagers(
      this.totalItems,
      Number(this.pageSize),
      this.currentPage
    );
    this.showRecords = result[0];
    this.maxPage = result[1];
  }

  pageSizeChange(val) {
    this.pageSize = val;
    this.reportData = [];
    this.reportCountData = {};
    this.currentPage = 1;
    this.changePage = 1;
    this.loadPage(1);
  }

  jumpToPage() {
    if (this.changePage != '' && this.changePage != null && this.changePage !== this.currentPage) {
      if (Number(this.changePage) <= this.maxPage && this.changePage != 0) {
        this.reportData = [];
        this.currentPage = this.changePage;
        this.loadPage(this.currentPage);
      } else {
        this.changePage = this.currentPage;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        // this.scrollToTop = true;
      }
    }
  }

  loadPage(page: any) {
    this.currentPage = page;
    this.changePage = page;
    this.getOpsKeyIssueReportData();
  }

  /**
* Calls search method to sort the data based on columnName
* @param columnName 
*/
  sortData(columnName) {
    if (this.columnName != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnName = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';

    this.getOpsKeyIssueReportData();
  }

  isSearchable(column) {
    if(column == 'sampleId' || column == 'companyId' || column == 'companyName' || 
      column == 'esn' || column == 'unitId'){
      return true;
    }else{
      return false;
    }
  }

  getSortColumn(column):any{
    let columnName = "sample_id";
    if(column == 'sampleId'){
      columnName = "sample_id";
    }else if(column == 'companyId'){
      columnName = "company_id";
    }else if(column == 'companyName'){
      columnName = "company_name";
    }else if(column == 'unitId'){
      columnName = "unit_id";
    }else if(column == 'esn'){
      columnName = "esn";
    }else if(column == 'lastSampledDate'){
      columnName = "sampled_date";
    }else if(column == 'scSeverityName'){
      columnName = "sc_severity_name";
    }else if(column == 'fcSeverityName'){
      columnName = "fc_severity_name";
    }else if(column == 'ccSeverityName'){
      columnName = "cc_severity_name";
    }else if(column == 'doSeverityName'){
      columnName = "do_severity_name";
    }else if(column == 'ewSeverityName'){
      columnName = "ew_severity_name";
    }else if(column == 'dcSeverityName'){
      columnName = "dc_severity_name";
    }else if(column == 'wcSeverityName'){
      columnName = "wc_severity_name";
    } 
    return columnName;
  }

  getInputData():any{
    let inputData = this.searchForm.getRawValue();
    this.searchObj = {
      pageSize: this.pageSize.toString(),
      currentPage: this.currentPage.toString(),
      columnName: this.columnName,
      sortingOrder: this.sortingOrder
    };

    Object.assign(inputData, this.searchObj);

    inputData['startDate'] = this.datePipe.transform(this.inputForm.controls.startDate.value, 'yyyy-MM-dd');
    inputData['endDate'] = this.datePipe.transform(this.inputForm.controls.endDate.value, 'yyyy-MM-dd');
    inputData['fluidType'] = this.inputForm.controls.fluidType.value;
    inputData['columnName'] = this.getSortColumn(inputData['columnName']);

    return inputData;
  }

  search(){    
    this.currentPage = 1;
    this.searchForm.patchValue({
      sampleId: '',
      companyId: '',
      companyName: '',
      unitId: '',
      esn: ''
    });
    this.getOpsKeyIssueReportData();
  }

  optionSearch(){
    this.currentPage = 1;
    this.getOpsKeyIssueReportData();
  }

}
