import { map, catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class BaseServiceService {
  constructor(public httpClient: HttpClient) { }

  protected httpPost(url: string, param?: any): Observable<any> {
    let serviceUrl = '';
 
      serviceUrl = environment.apiUrl + url;
    // console.log('param', param);
    // console.log('environment.apiUrlIp + url', environment.apiUrlIp + url);
    return this.httpClient.post(serviceUrl, param)
      .pipe(map(res => res)
        , catchError(error => this.handleError(error)));
  }

  // https://api.myjson.com/bins/1d6n6i
  protected httpGet(url: string): Observable<any> {
    return this.httpClient.get(url)
      .pipe(map(res => res)
        , catchError(error => this.handleError(error)));
  }

  // createAuthorizationHeader(headers: HttpHeaders) {
  //   headers.append('x-api-key', 'E9EK64QkHB4W6POjgylBU6PIIty3VTBE2fni9YV1');
  //   headers.append('accept', 'application/hal+json');
  // }

  // protected httpGetWithHeader(url: string): Observable<any> {

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'x-api-key': 'E9EK64QkHB4W6POjgylBU6PIIty3VTBE2fni9YV1',
  //       'accept': 'application/hal+json'
  //     })
  //   };
  //   return this.httpClient.get(url, httpOptions);
  // }

  // public handleError(error: any) {
  // 	return Observable.throw(error.error.message);
  // }

  public handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? '${error.status} - ${error.statusText}' : 'Server error';
    return throwError(errMsg);
  }

}
