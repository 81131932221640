import { AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/View/common/alert/alert.service';
import { SortComponent } from 'src/app/View/common/sort/sort.component';
import { CommonHandlerService } from 'src/app/View/handlers/common-handler.service';
import { SharedService } from 'src/app/View/service/shared.service';
import { MasterDataManagementService } from '../master-data-management.service';
import { common, warnMessage } from '../../../common/constants/constant';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.css']
})
export class MetadataComponent implements OnInit,AfterViewChecked,AfterContentChecked {

  @ViewChildren(SortComponent) sortComponents;
  
  //metadata changes
  scrollToTop =  false;
  displayMetadataResult = false;
  isNewMetadata = false;
  pageSizeMetadata = '10';  
  currentPageMetadata = 1;
  showRecordsMetadata = 0;
  maxPageMetadata = 0;
  columnNameMetadata: string;
  totalItemsMetadata: any;
  changePageMetadata: any = 1;
  metadataSearchData = [];
  searchObjMetadata:any;
  addMetadataModal = false;
  saveMetadataModal = false;
  deleteMetadataModal = false;
  selectedMetadataGroup:UntypedFormGroup;
  sortingOrder: string;
  columnName: string;
  searchOption: any;  
  successMessage: string = '';  
  successModal = false;
  pageSizeOptions: any[] = common.pageSizeOptions;

  metadataForm = new UntypedFormGroup({
    dropdownName: new UntypedFormControl('',[Validators.required]),
    fluidType: new UntypedFormControl('')
  });

  metadataDropdownNameList = [    
    {code: 'APPLICATION', name: 'Application'},
    {code: 'CESSTANDARD', name: 'CES Standard'},
    {code: 'DUTYCYCLE', name: 'Duty Cycle'},
    {code: 'ECMMODEL', name: 'ECM Model'},
    {code: 'PLATFORM', name: 'Platform'}    
  ];

  metadataColumn = [
    { code: 'dropdownName', name: 'Dropdown Name' },
    { code: 'fluidType', name: 'Fluid Type' },
    { code: 'optionName', name: 'Option Name' },
    { code: 'action', name: 'Actions' }
  ];

  fluidTypeData=[
    { name : 'Engine Oil (Liquid Fuel)' , id: 1 },
    { name : 'Engine Oil (Gaseous Fuel)' , id: 2 },
    { name : 'Coolant' , id: 3 },
    { name : 'Diesel Fuel' , id: 4 },
    { name : 'Diesel Exhaust Fluid' , id: 5 },
    { name : 'Lubricants' , id: 6 }    
  ];

  addMetadataForm = new UntypedFormGroup({
    dropdownName: new UntypedFormControl('',[Validators.required]),
    fluidType: new UntypedFormControl(''),
    optionName: new UntypedFormControl('',[Validators.required])
  });

  metadataFormArray = new UntypedFormArray([]);

  constructor(private masterDataManagementService:MasterDataManagementService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private commonHandler: CommonHandlerService,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.columnName = 'optionName';
  }

  ngAfterViewChecked(){    
    if(this.scrollToTop)  {
      let element = document.querySelector('#alertDiv');
      if(element != null){        
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  
  metadataFluidTypeChange(){
    this.displayMetadataResult = false;    
  }

  dropdownNameChange(){
    this.displayMetadataResult = false;
    if(this.metadataForm.controls.dropdownName.value == 'CESSTANDARD'){
      this.addMetadataForm.controls.fluidType.setValidators([Validators.required]);
      this.metadataForm.controls.fluidType.setValidators([Validators.required]);
    }else{
      this.addMetadataForm.controls.fluidType.clearValidators();
      this.metadataForm.controls.fluidType.clearValidators();
    }
    Object.keys(this.metadataForm.controls).forEach(field => { 
      const control = this.metadataForm.get(field);
      control.updateValueAndValidity();
    });  
    Object.keys(this.addMetadataForm.controls).forEach(field => { 
      const control = this.addMetadataForm.get(field);
      control.updateValueAndValidity();
    });  
  }

  searchMetadata(){
    this.currentPageMetadata = 1;
    this.isNewMetadata = false;
    this.fetchMetadata();
  }

  fetchMetadata(){
    let data = this.metadataForm.getRawValue();
    data['pageSize'] = this.pageSizeMetadata.toString();
    data['currentPage'] = this.currentPageMetadata;
    data['columnName'] = this.columnName;
    data['sortingOrder'] = this.sortingOrder;

    this.searchObjMetadata = data;
    this.masterDataManagementService.searchMetadata(data).subscribe(response =>{        
      if (response.statusCode == "1") {
        this.metadataSearchData = response.dropdownOptionList;   
        this.totalItemsMetadata = response.dropdownOptionList[0].maxRow;
        this.calculateOffsetMetadata();
        this.displayMetadataResult = true;

        //form Array creation
        this.metadataFormArray = new UntypedFormArray([]);
        this.metadataSearchData.forEach((row) => {          
            const formGroup = new UntypedFormGroup({
              id: new UntypedFormControl(row.id, [Validators.required]),
              dropdownName: new UntypedFormControl(row.dropdownName, [Validators.required]),
              fluidType: new UntypedFormControl(row.fluidType),
              optionName: new UntypedFormControl(row.optionName, [Validators.required]),
              isEdit: new UntypedFormControl(false),
            });
            this.metadataFormArray.push(formGroup);
        });
        console.log(this.metadataFormArray);
        
      }else {
        this.alertService.error(response.statusMessage);
        this.metadataSearchData = [];
        this.displayMetadataResult = true;
        //this.clearMetadata();
        this.scrollToTop = true;
      }
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
    });
    /*this.metadataSearchData = [{dropdownName:'Platform', fluidType: 'Engine Oil (Liquid Fuel)', optionName:'Option 1'},
    {dropdownName:'Platform', fluidType:'Engine Oil (Liquid Fuel)', optionName:'Option 2'},
    {dropdownName:'Platform', fluidType:'Engine Oil (Liquid Fuel)', optionName:'Option 3'}];

    this.metadataFormArray = new FormArray([]);
    this.metadataSearchData.forEach((row) => {          
        const formGroup = new FormGroup({
          dropdownName: new FormControl(row.dropdownName, [Validators.required]),
          fluidType: new FormControl(row.fluidType, [Validators.required]),
          optionName: new FormControl(row.optionName, [Validators.required]),
          isEdit: new FormControl(false),
        });
        this.metadataFormArray.push(formGroup);
    });
    this.displayMetadataResult = true;*/
  }

  clearMetadata(){
    this.metadataForm.reset();
    this.displayMetadataResult = false;
    this.isNewMetadata = false;
    if(this.metadataForm.controls.dropdownName.value == 'CESSTANDARD'){
      this.addMetadataForm.controls.fluidType.setValidators([Validators.required]);
      this.metadataForm.controls.fluidType.setValidators([Validators.required]);
    }else{
      this.addMetadataForm.controls.fluidType.clearValidators();
      this.metadataForm.controls.fluidType.clearValidators();
    }
    Object.keys(this.metadataForm.controls).forEach(field => { 
      const control = this.metadataForm.get(field);
      control.updateValueAndValidity();
    });    
  }

  addNewMetadata(){
    this.addMetadataForm.patchValue({
      dropdownName: this.metadataForm.controls.dropdownName.value,
      fluidType: this.metadataForm.controls.fluidType.value 
    });
    this.isNewMetadata = true;
  }

  hideNewMetadata(){
    this.isNewMetadata = false;
    this.addMetadataForm.reset();
  }

  getMetadataDropdownName(){
    let dropdownName = '';
    this.metadataDropdownNameList.forEach((row) =>{
      if(row.code == this.metadataForm.controls.dropdownName.value){
        dropdownName = row.name;
      }
    });
    return dropdownName;
  }

  getMetadataFluidType(){
    let fluidName = '';
    this.fluidTypeData.forEach((row) =>{
      if(row.id == this.metadataForm.controls.fluidType.value){
        fluidName = row.name;
      }
    });
    return fluidName;
  }

  openAddMetadataModal(){
    if(!this.addMetadataForm.invalid){
      this.addMetadataModal = true;
    }else{
      this.addMetadataModal = false;      
      Object.keys(this.addMetadataForm.controls).forEach(field => { 
        const control = this.addMetadataForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  editMetadata(item){
    item.patchValue({
      isEdit:true
    });
  }

  openSaveMetadataModal(item){
    if(!item.invalid){
      this.selectedMetadataGroup = item;
      this.saveMetadataModal = true;
    }else{
      this.saveMetadataModal = false;   
      Object.keys(item.controls).forEach(field => { 
        const control = item.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  cancelMetadataEdit(item, i){
    item.patchValue({
      isEdit:false,
      optionName: this.metadataSearchData[i].optionName
    });
  }

  openMetadataDeleteModal(item){    
    this.selectedMetadataGroup = item;
    this.deleteMetadataModal = true;
  }

  
  // Pagination for Metadata

  calculateOffsetMetadata() {
    const result = this.commonHandler.calculatePagers(
      this.totalItemsMetadata,
      Number(this.pageSizeMetadata),
      this.currentPageMetadata
    );
    this.showRecordsMetadata = result[0];
    this.maxPageMetadata = result[1];
  }

  pageSizeChangeMetadata(val) {
    this.pageSizeMetadata = val;
    this.currentPageMetadata = 1;
    this.changePageMetadata = 1;
    this.loadPageMetadata(1);
  }

  jumpToPageMetadata() {
    if (this.changePageMetadata != '' && this.changePageMetadata != null && this.changePageMetadata !== this.currentPageMetadata) {
      if (Number(this.changePageMetadata) <= this.maxPageMetadata && this.changePageMetadata != 0) {        
        
        this.currentPageMetadata = this.changePageMetadata;
        this.loadPageMetadata(this.currentPageMetadata);
      } else {
        this.changePageMetadata = this.currentPageMetadata;
        this.alertService.warn(warnMessage.paginatorNotAvail);
        this.scrollToTop = true;
      }
    }
  }
    
  sortDataMetadata(columnName) {
    if (this.columnNameMetadata != columnName) {
      this.sortingOrder = "";
      this.sortComponents._results.forEach(row => row.direction = true);
    }
    this.sortComponents._results.forEach(row => row.column === columnName ? row.activeColumn = true : row.activeColumn = false);
    this.columnNameMetadata = columnName;
    this.sortingOrder = this.sortingOrder != 'asc' ? 'asc' : 'desc';
    this.fetchMetadata();
  }

  loadPageMetadata(page: any) {
    this.currentPageMetadata = page;
    this.changePageMetadata = page;  
    this.fetchMetadata();    
  }

  closeAddMetadataModal(){
    this.addMetadataModal = false;
  }

  addMetadata(){
    let data=this.addMetadataForm.getRawValue(); 
    this.metadataDropdownNameList.forEach(row =>{
      if(row.name == data.dropdownName){
        data['dropdownName'] = row.code;
      }
    });
    
    this.masterDataManagementService.addMetadata(data).subscribe(response => {
      if (response.statusCode == "1") {
        this.successMessage = response.statusMessage;
        this.successModal = true;        
        this.isNewMetadata = false;
        this.addMetadataForm.reset();
        this.searchMetadata();
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.addMetadataModal = false;
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.addMetadataModal = false;
    });
  }

  closeSaveMetadataModal(){
    this.saveMetadataModal = false;
  }

  saveMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    this.metadataDropdownNameList.forEach(row =>{
      if(row.name == data.dropdownName){
        data['dropdownName'] = row.code;
      }
    });
    console.log(data);
    this.masterDataManagementService.saveMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {  
        this.searchMetadata();        
        this.successMessage = response.statusMessage;
        this.successModal = true; 
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.saveMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.saveMetadataModal = false;
    });
  }

  closeDeleteMetadataModal(){
    this.deleteMetadataModal = false;
  }

  deleteMetadata(){
    let data = this.selectedMetadataGroup.getRawValue();
    this.metadataDropdownNameList.forEach(row =>{
      if(row.name == data.dropdownName){
        data['dropdownName'] = row.code;
      }
    });
    this.masterDataManagementService.deleteMetadata(data).subscribe(response =>{       
      if (response.statusCode == "1") {         
        this.successMessage = response.statusMessage;
        this.successModal = true; 
        this.searchMetadata();     
      }else {
        this.alertService.error(response.statusMessage);
        this.scrollToTop = true;
      }
      this.deleteMetadataModal = false; 
    }, err => {
      this.alertService.error(err);
      this.scrollToTop = true;
      this.deleteMetadataModal = false;
    });
  }  

}
